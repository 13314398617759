import { useMemo } from 'react';
import useOutline from '../../../useOutline';
import { useSlideData } from 'Presentation/Slides/Slide/SlideData';
import BackgroundDefs from 'Presentation/Slides/Slide/Background/BackgroundDefs';

type LineProps = {
  ln: Presentation.Data.Outline | undefined;
  fill: Presentation.Data.Common.FillType | undefined;
  type: 'line' | 'square' | 'circle';
};

const Line = ({ ln, type, fill }: LineProps) => {
  const { color } = useSlideData();

  const outline = useOutline(ln);

  const strokeWidth = useMemo(() => {
    const maxStrokeWidth = 8;
    return Math.min(ln?.w ?? maxStrokeWidth, maxStrokeWidth);
  }, [ln]);

  const width = useMemo(() => {
    switch (type) {
      case 'line': {
        return 32;
      }
      case 'circle':
      case 'square':
      default: {
        return 8;
      }
    }
  }, [type]);

  const height = useMemo(() => {
    if (type === 'square') {
      return width;
    }
    if (type === 'circle') {
      return width;
    }
    return strokeWidth + 1;
  }, [strokeWidth, width]);

  const lnFillId = useMemo(() => {
    return 'ln-fill';
  }, []);

  const lnFill = useMemo(() => {
    return ln?.fill
      ? ln?.fill?.type === 'solid'
        ? color(ln.fill.color)
        : `url(#${lnFillId})`
      : '';
  }, [ln, type]);

  const bgFillId = useMemo(() => {
    return 'bg-fill';
  }, []);

  const bgFill = useMemo(() => {
    return fill ? (fill?.type === 'solid' ? color(fill.color) : `url(#${bgFillId})`) : '';
  }, [ln, type]);

  return (
    <svg width={width} height={height}>
      <g>
        <defs>
          <BackgroundDefs fill={fill} id={bgFillId} />
          <BackgroundDefs fill={ln?.fill} id={lnFillId} />
        </defs>
        {type === 'line' && (
          <line
            x1={height / 2}
            y1={height / 2}
            x2={width}
            y2={'49.9%'}
            {...outline}
            stroke={lnFill}
            strokeWidth={strokeWidth}
          />
        )}
        {type === 'square' && (
          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            {...outline}
            stroke={`${lnFill}`}
            fill={`${bgFill}`}
          />
        )}
        {type === 'circle' && (
          <circle
            cx="50%"
            cy="50%"
            r="50%"
            {...outline}
            stroke={lnFill}
            fill={`${bgFill}`}
            strokeWidth={strokeWidth}
          />
        )}
      </g>
    </svg>
  );
};

export default Line;
