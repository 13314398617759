import { ActionContext } from '../../ActionContext';
import { JsonRange } from 'Editor/services/_Common/Selection';
import { Command } from '../Command';

export class CutCommand extends Command {
  event: ClipboardEvent;

  constructor(context: Editor.Edition.ICommandArgs, event: ClipboardEvent) {
    super(context);

    this.event = event;
  }

  async exec(): Promise<Editor.Edition.ICommand> {
    if (!this.context.DataManager || !this.context.DataManager.selection) {
      return this;
    }

    const rangeData = this.context.DataManager.selection.current;
    const jsonRange = JsonRange.buildFromRangeData(rangeData[0]);

    if (!jsonRange) {
      return this;
    }

    let ctx: Editor.Edition.ActionContext = new ActionContext(jsonRange);

    const baseModel = this.context.DataManager.nodes.getNodeModelById(jsonRange.start.b);

    const baseData = baseModel?.selectedData();

    if (!baseModel || !baseData || !baseData.id) {
      return this;
    }

    // check if element is editable
    if (!this.context.DataManager.nodes.isNodeEditable(baseModel.id)) {
      return this;
    }

    // handle collapsed selection
    if (ctx.range.collapsed) {
      return this;
    }

    this.context.clipboard?.handleCut(this.event);

    this.context.contentManipulator?.removeContent(ctx);

    // handle create suggestions???
    this.handleSuggestionsUpdate(ctx);

    // apply new selection
    if (this.context.DataManager?.selection) {
      // TEMP: flag last selection
      // this.context.DataManager.selection.history.flag('debounce');
      this.context.DataManager.selection.setUserSelection([ctx.range.serializeToRangeData()]);
    }

    // create patch
    this.context.DataManager?.history.createPatch();

    return this;
  }
}
