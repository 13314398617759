import { NodeUtils } from 'Editor/services/DataManager';
import { Command } from '../Command';
import { JsonRange, SelectionFixer } from 'Editor/services/_Common/Selection';
import { ActionContext } from '../../ActionContext';

export class DeleteHyperlinkCommand extends Command {
  async exec(): Promise<Editor.Edition.ICommand> {
    if (
      !this.context.DataManager ||
      !this.context.DataManager.selection ||
      !this.context.contentManipulator
    ) {
      return this;
    }
    const rangeData = this.context.DataManager.selection.current;
    const jsonRange = JsonRange.buildFromRangeData(rangeData[0]);

    if (!jsonRange) {
      return this;
    }

    const baseModel = this.context.DataManager.nodes.getNodeModelById(jsonRange.start.b);
    const baseData = baseModel?.selectedData();

    if (!baseModel || !baseData) {
      return this;
    }

    let ctx: Editor.Edition.ActionContext = new ActionContext(jsonRange, baseModel, baseData);

    const link = NodeUtils.firstOfTypeByPath(baseData, jsonRange.start.p, 'link');

    if (link && link.path) {
      const length = link.data.childNodes?.length || 0;

      let clonedData: Editor.Data.Node.Data[] = [];

      if (length) {
        clonedData = NodeUtils.cloneData(
          baseData,
          [...link.path, 'childNodes', 0],
          [...link.path, 'childNodes', length],
        );
      }

      let startLink = [...link.path];
      let endLink = [...link.path];
      let offset = Number(endLink[endLink.length - 1]);
      if (!isNaN(offset)) {
        endLink[endLink.length - 1] = offset + 1;
      }

      ctx.range.updateRangePositions(
        { b: ctx.range.start.b, p: startLink },
        { b: ctx.range.end.b, p: endLink },
      );

      this.context.contentManipulator.removeContent(ctx, {
        selectionDirection: 'forward',
        mergeText: false,
      });

      if (clonedData.length) {
        ctx.refreshBaseData();

        // normalize text selection
        SelectionFixer.collapsedTextSelection(
          ctx.range,
          {
            suggestionMode: this.context.editionMode === 'SUGGESTIONS',
          },
          this.context.DataManager,
        );

        for (let i = 0; i < clonedData.length; i++) {
          this.context.contentManipulator.insertContent(ctx, ctx.range.start.p, clonedData[i]);
        }
      }
    }

    // handle create suggestions???
    this.handleSuggestionsUpdate(ctx);

    // apply new selection
    if (this.context.DataManager?.selection) {
      // TEMP: flag last selection
      this.context.DataManager.selection.history.flag('debounce');
      this.context.DataManager.selection.setUserSelection([ctx.range.serializeToRangeData()]);
    }

    // create patch
    this.context.DataManager?.history.createPatch();

    return this;
  }
}
