import { useSelector } from '_common/hooks';

const useTotalTasksFilters = () => {
  const filters = useSelector((state) => state.filters.reviewerTaskPanel);

  const filtersNames: (keyof typeof filters)[] = ['reviewerTaskStatus', 'assignedUser'];
  const results = filtersNames.map((name) => filters[name]);
  let count = 0;

  for (let i = 0; i < results.length; i++) {
    const result = results[i];
    if (results && result && Array.isArray(result) && result?.length > 0) {
      count = count + result?.length;
    }
    if (
      results &&
      result != null &&
      !Array.isArray(result) &&
      (typeof result === 'string' || typeof result === 'object')
    ) {
      count = count + 1;
    }

    if (results && typeof result === 'boolean' && result === true) {
      count = count + 1;
    }
  }
  return count;
};

export default useTotalTasksFilters;
