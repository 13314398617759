import { Logger } from '_common/services';
import { Command } from '../Command';
import { JsonRange, SelectionFixer } from 'Editor/services/_Common/Selection';
import { ActionContext } from '../../ActionContext';
import { NodeUtils } from 'Editor/services/DataManager';
import { notify } from '_common/components/ToastSystem';

export class InsertBlockCommand extends Command {
  private options: Editor.Edition.InsertBlockOptions;

  constructor(
    context: Editor.Edition.ICommandArgs,
    options: Editor.Edition.InsertBlockOptions = {},
  ) {
    super(context);
    this.options = options;
  }

  protected handleCollapsedSelection(
    ctx: Editor.Edition.ActionContext,
    blockData: Editor.Data.Node.Data,
  ): boolean {
    if (
      !this.context.DataManager ||
      !this.context.contentManipulator ||
      !ctx.baseModel ||
      !ctx.baseData
    ) {
      return false;
    }

    const result = NodeUtils.closestOfTypeByPath(ctx.baseData, ctx.range.start.p, [
      ...NodeUtils.BLOCK_TEXT_TYPES,
    ]);

    if (result?.data) {
      // check if element is editable
      if (!this.context.DataManager.nodes.isNodeEditable(ctx.baseModel.id)) {
        return false;
      }

      // normalize text selection
      SelectionFixer.collapsedTextSelection(
        ctx.range,
        {
          suggestionMode: this.context.editionMode === 'SUGGESTIONS',
        },
        this.context.DataManager,
      );

      return (
        this.context.contentManipulator.splitBlockContent(ctx, ctx.range.start.p, {
          checkEmpty: true,
        }) && this.context.contentManipulator.insertBlock(ctx, blockData, 'AFTER', this.options)
      );
    } else {
      // insert new node after
      return this.context.contentManipulator.insertBlock(ctx, blockData, 'AFTER', this.options);
    }
  }

  protected handleNonCollapsedSelection(ctx: Editor.Edition.ActionContext): boolean {
    // remove content
    if (this.context.contentManipulator) {
      return this.context.contentManipulator.removeContent(ctx);
    }

    return false;
  }

  async exec(blockData?: Editor.Data.Node.Data): Promise<Editor.Edition.ICommand> {
    if (this.debug) {
      Logger.trace('InsertBlockCommand exec', this, blockData);
    }

    if (!this.context.DataManager || !this.context.DataManager.selection || !blockData) {
      return this;
    }

    const rangeData = this.context.DataManager.selection.current;
    const jsonRange = JsonRange.buildFromRangeData(rangeData[0]);

    if (!jsonRange) {
      return this;
    }

    const baseModel = this.context.DataManager.nodes.getNodeModelById(jsonRange.start.b);

    const baseData = baseModel?.selectedData();
    if (!baseModel || !baseData) {
      return this;
    }

    let ctx: Editor.Edition.ActionContext = new ActionContext(jsonRange, baseModel, baseData);

    // check if insertion is allowed
    if (
      !NodeUtils.isBlockInsertionAllowed(baseData, jsonRange.getCommonAncestorPath(), blockData)
    ) {
      Logger.warn('Block insertion not allowed!', blockData);
      // TODO: throw error? notify?
      notify({
        type: 'error',
        title: 'global.error',
        message: 'ERROR.ERROR_INSERTING_ELEMENT',
      });
      return this;
    }

    if (!ctx.range.collapsed) {
      // handle non collapsed selection
      if (!this.handleNonCollapsedSelection(ctx)) {
        return this;
      }
    }

    // handle collapsed selection
    if (ctx.range.collapsed) {
      if (!this.handleCollapsedSelection(ctx, blockData)) {
        return this;
      }
    }

    // handle create suggestions???
    this.handleSuggestionsUpdate(ctx);

    // apply new selection
    if (this.context.DataManager?.selection) {
      // TEMP: flag last selection
      this.context.DataManager.selection.history.flag('debounce');
      this.context.DataManager.selection.setUserSelection([ctx.range.serializeToRangeData()]);
    }

    // create patch
    this.context.DataManager?.history.createPatch();

    return this;
  }
}
