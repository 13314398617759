/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Doc } from 'sharedb';
import {
  RealtimeObject,
  PresentationDocument,
  UndoManager,
  RealtimeFragment,
} from '_common/services/Realtime';
import {
  Comments,
  NotesMaster,
  NotesSlide,
  Slide,
  SlideComments,
  SlideLayout,
  SlideMaster,
  SlideTasks,
  Structure,
  Tasks,
  Theme,
} from '../../models';
import { BaseController } from '../BaseController';

export const TYPE_NAME = {
  DOCUMENT: 'DOCUMENT',
  STRUCTURE: 'STRUCTURE',
  SLIDE: 'SLIDE',
  SLIDE_MASTER: 'SLIDE_MASTER',
  SLIDE_LAYOUT: 'SLIDE_LAYOUT',
  THEME: 'THEME',
  COMMENTS: 'COMMENTS',
  SLIDE_COMMENTS: 'SLIDE_COMMENTS',
  SLIDE_TASKS: 'SLIDE_TASKS',
  TASKS: 'TASKS',
  NOTES_MASTER: 'NOTES_MASTER',
  NOTES_SLIDE: 'NOTES_SLIDE',
} as const;

export interface Types {
  [TYPE_NAME.DOCUMENT]: PresentationDocument;
  [TYPE_NAME.STRUCTURE]: Structure;
  [TYPE_NAME.SLIDE]: Slide;
  [TYPE_NAME.SLIDE_MASTER]: SlideMaster;
  [TYPE_NAME.SLIDE_LAYOUT]: SlideLayout;
  [TYPE_NAME.THEME]: Theme;
  [TYPE_NAME.COMMENTS]: Comments;
  [TYPE_NAME.SLIDE_COMMENTS]: SlideComments;
  [TYPE_NAME.SLIDE_TASKS]: SlideTasks;
  [TYPE_NAME.TASKS]: Tasks;
  [TYPE_NAME.NOTES_MASTER]: NotesMaster;
  [TYPE_NAME.NOTES_SLIDE]: NotesSlide;
}
export type TypeName = keyof Types;

type ModelsState = {
  [index in TypeName]: {
    [x: string]: Types[index];
  };
};

export class ModelsController extends BaseController {
  protected models: ModelsState;
  undoManager?: Realtime.Core.UndoManager;
  constructor(Data: Presentation.Data.State) {
    super(Data);
    this.models = {
      [TYPE_NAME.DOCUMENT]: {},
      [TYPE_NAME.STRUCTURE]: {},
      [TYPE_NAME.SLIDE]: {},
      [TYPE_NAME.SLIDE_MASTER]: {},
      [TYPE_NAME.SLIDE_LAYOUT]: {},
      [TYPE_NAME.THEME]: {},
      [TYPE_NAME.COMMENTS]: {},
      [TYPE_NAME.SLIDE_COMMENTS]: {},
      [TYPE_NAME.SLIDE_TASKS]: {},
      [TYPE_NAME.TASKS]: {},
      [TYPE_NAME.NOTES_MASTER]: {},
      [TYPE_NAME.NOTES_SLIDE]: {},
    };
  }

  start(): void {
    this.undoManager = new UndoManager({
      autoPatch: true,
    });
  }

  stop(): void {}

  destroy(): void {
    // dispose models
    const modelKeys = Object.keys(this.models);
    for (let i = 0; i < modelKeys.length; i++) {
      const modelType: TypeName = modelKeys[i] as TypeName;

      const idKeys = Object.keys(this.models[modelType]);
      for (let j = 0; j < idKeys.length; j++) {
        const id: string = idKeys[j];

        this.models[modelType][id]?.dispose();
        delete this.models[modelType][id];
      }
    }
  }

  get TYPE_NAME() {
    return TYPE_NAME;
  }

  private fetchModel<T extends TypeName, R extends Types[T]>(
    type: T,
    id: Realtime.Core.RealtimeObjectId,
    ...args: unknown[]
  ): R {
    let model;
    switch (type) {
      case TYPE_NAME.DOCUMENT:
        model = new PresentationDocument(
          this.Data.transport,
          id as string,
          args[0] as Realtime.Core.Document.Data,
        ) as R;
        model.fetch();
        break;
      case TYPE_NAME.STRUCTURE:
        model = new Structure(this.Data.transport, id) as R;
        break;
      case TYPE_NAME.SLIDE:
        model = new Slide(this.Data.transport, id) as R;
        break;
      case TYPE_NAME.SLIDE_MASTER:
        model = new SlideMaster(this.Data.transport, id) as R;
        break;
      case TYPE_NAME.THEME:
        model = new Theme(this.Data.transport, id) as R;
        break;
      case TYPE_NAME.SLIDE_LAYOUT:
        model = new SlideLayout(this.Data.transport, id) as R;
        break;
      case TYPE_NAME.COMMENTS:
        model = new Comments(this.Data.transport, id) as R;
        break;
      case TYPE_NAME.SLIDE_COMMENTS:
        model = new SlideComments(this.Data.transport, id, args[0] as string) as R;
        break;
      case TYPE_NAME.SLIDE_TASKS:
        model = new SlideTasks(this.Data.transport, id, args[0] as string) as R;
        break;
      case TYPE_NAME.TASKS:
        model = new Tasks(this.Data.transport, id) as R;
        break;
      case TYPE_NAME.NOTES_MASTER:
        model = new NotesMaster(this.Data.transport, id) as R;
        break;
      case TYPE_NAME.NOTES_SLIDE:
        model = new NotesSlide(this.Data.transport, id) as R;
        break;
      default:
        throw new Error(`Unsupported model type : ${type}`);
    }
    if (model instanceof RealtimeObject || model instanceof RealtimeFragment) {
      model.subscribe();
    }
    return model as R;
  }

  get<T extends TypeName, R extends Types[T]>(
    type: T,
    id?: Realtime.Core.RealtimeObjectId,
    ...args: unknown[]
  ): R {
    let _id: string = '';
    if (id == null) {
      throw new Error('Invalid id value: ' + id);
    }
    if (typeof id === 'string') {
      _id = id;
    } else if (!(id instanceof String) && (id as Doc).id) {
      _id = (id as Doc).id;
    }
    let cacheKey = _id;
    if (type === 'SLIDE_COMMENTS' || type === 'SLIDE_TASKS') {
      // should compound cache key
      cacheKey = `${_id}:${args[0]}`;
    }
    if (!this.models[type][cacheKey]) {
      // @ts-ignore
      this.models[type][cacheKey] = this.fetchModel(type, id, ...args);
    }
    return this.models[type][cacheKey] as R;
  }

  setModelsVersion(version: ApiSchemas['VersionsSchema'] | null) {
    const processing = [];
    const slideTasks = Object.keys(this.models.SLIDE_TASKS);
    for (let index = 0; index < slideTasks.length; index++) {
      processing.push(this.models.SLIDE_TASKS[slideTasks[index]].setVersion(version));
    }
    const tasks = Object.keys(this.models.TASKS);
    for (let index = 0; index < tasks.length; index++) {
      processing.push(this.models.TASKS[tasks[index]].setVersion(version));
    }
    const slideComments = Object.keys(this.models.SLIDE_COMMENTS);
    for (let index = 0; index < slideComments.length; index++) {
      processing.push(this.models.SLIDE_COMMENTS[slideComments[index]].setVersion(version));
    }
    const comments = Object.keys(this.models.COMMENTS);
    for (let index = 0; index < comments.length; index++) {
      processing.push(this.models.COMMENTS[comments[index]].setVersion(version));
    }
    const slideNotes = Object.keys(this.models.NOTES_SLIDE);
    for (let index = 0; index < slideNotes.length; index++) {
      processing.push(this.models.NOTES_SLIDE[slideNotes[index]].setVersion(version));
    }
    return Promise.all(processing);
  }

  disposeModel<T extends TypeName>(type: T, id: string | undefined) {
    if (id != null && this.models[type][id]) {
      this.models[type][id].dispose();
      delete this.models[type][id];
    }
  }
}
