/**
 * Explanation of constant value (3cd4):
 *  Context: "Units are in 60'000 of a degree" AND Constant value of 3cd4 is 16'200'000
 *  Process to convert constant value to degree:
 *    60'000 * 360 = 21'600'000
 *    16'200'000 ÷ 21'600'000 = 0.75
 *    0.75 * 360 = 270 (degrees)
 *
 *  This process will be required to obtain degree angle to use in arcTo
 *  Ex: Given an angle with value of -10'800'000:
 *    -10'800'000 ÷ 21'600'000 = -0.5
 *    -0.5 * 360 = -180 (degrees)
 *
 * Source: Page 2988 of Ecma Office Open XML Part 1 - Fundamentals And Markup Language Reference.pdf
 */

const SHAPE_CONST = {
  '3cd4': 270, // 3/4 of circle
  '3cd8': 135, // 3/8 of circle
  '5cd8': 225, // 5/8 of circle
  '7cd8': 315, // 7/8 of circle
  cd2: 180, // 1/2 of circle
  cd4: 90, // 1/4 of circle
  cd8: 45, //1/8 of circle
} as const;

export default SHAPE_CONST;
