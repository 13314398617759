import { JsonRange } from 'Editor/services/_Common/Selection';
import { Command } from '../Command';
import { ActionContext } from '../../ActionContext';
import { NodeDataBuilder, NodeUtils } from 'Editor/services/DataManager';
import { notify } from '_common/components/ToastSystem';

export class EditCaptionCommand extends Command {
  private options: Editor.Edition.CaptionCommandOptions;

  constructor(context: Editor.Edition.ICommandArgs, options: Editor.Edition.CaptionCommandOptions) {
    super(context);
    this.options = options;
  }

  async exec(...args: any[]): Promise<Editor.Edition.ICommand> {
    if (
      !this.context.DataManager ||
      !this.context.DataManager.selection ||
      !this.context.contentManipulator
    ) {
      return this;
    }

    const rangeData = this.context.DataManager.selection.current;
    const jsonRange = JsonRange.buildFromRangeData(rangeData[0]);

    if (!jsonRange) {
      return this;
    }

    const baseModel = this.context.DataManager.nodes.getNodeModelById(jsonRange.start.b);
    const baseData = baseModel?.selectedData();

    if (!baseModel || !baseData) {
      return this;
    }

    let ctx: Editor.Edition.ActionContext = new ActionContext(jsonRange, baseModel, baseData);

    const captionDefenition = this.context.DataManager.captions.caption(this.options.label);

    const captionData: Partial<Editor.Data.Structure.CaptionData> = {};

    let shouldUpdate = false;
    if (this.options.numberingType && this.options.numberingType !== captionDefenition.numbering) {
      captionData.nf = this.options.numberingType;
      shouldUpdate = true;
    }

    if (this.options.chapterNumbering) {
      shouldUpdate = true;
      captionData.c = this.options.chapterNumbering.chapterType;

      let newSeparator = this.options.chapterNumbering.separator;
      if (newSeparator) {
        if (captionDefenition.chapter) {
          if (newSeparator !== captionDefenition.separator) {
            if (this.options.force) {
              captionData.s = newSeparator;
            } else {
              const closestField = NodeUtils.closestOfTypeByPath(baseData, jsonRange.start.p, [
                'f',
              ]);
              if (closestField) {
                const previousSibling = NodeUtils.getPreviousSibling(baseData, closestField.path);

                if (
                  previousSibling &&
                  NodeUtils.isTextData(previousSibling.data) &&
                  previousSibling.data.content !== newSeparator
                ) {
                  baseModel.set([...previousSibling.path, 'content'], newSeparator, {
                    source: 'LOCAL_RENDER',
                  });
                } else {
                  const textData = new NodeDataBuilder('text').setContent(newSeparator).build();
                  if (textData) {
                    this.context.contentManipulator.insertContent(ctx, closestField.path, textData);
                  }
                }
                notify({
                  type: 'warning',
                  title: 'CAPTIONS_SEPARATOR_NOT_UPDATED',
                  message: 'CAPTIONS_SEPARATOR_NOT_UPDATED_MESSAGE',
                  messageValues: { captionLabel: captionDefenition.label },
                });
              }
            }
          } else {
            captionData.s = captionDefenition.separator;
          }
        } else {
          captionData.s = this.options.chapterNumbering.separator;
        }
      }
    }

    if (shouldUpdate) {
      this.context.DataManager.captions.updateCaption(this.options.label, captionData);
    }

    // handle create suggestions???
    this.handleSuggestionsUpdate(ctx);

    // apply new selection
    if (this.context.DataManager?.selection) {
      // TEMP: flag last selection
      this.context.DataManager.selection.history.flag('debounce');
      this.context.DataManager.selection.setUserSelection([ctx.range.serializeToRangeData()]);
    }

    // create patch
    this.context.DataManager?.history.createPatch();

    return this;
  }
}
