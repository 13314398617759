import {
  CollapsedSelectionFixer,
  TextSelectionNormalizer,
  NonCollapsedSelectionFixer,
  JSONSelectionNormalizer,
} from './Visitors/Fixers';

export class SelectionFixer {
  // TODO: move collpased and non collapsed difference to TextSelectionNormalizer

  static collapsedTextSelection(
    range: Editor.Selection.EditorRange | Editor.Selection.JsonRange,
    options: Partial<Editor.Selection.FixerOptions>,
    DataManager?: Editor.Data.API,
  ) {
    range.accept(new TextSelectionNormalizer(options, DataManager));
  }

  static nonCollapsedTextSelection(
    range: Editor.Selection.EditorRange | Editor.Selection.JsonRange,
    options: Partial<Editor.Selection.FixerOptions>,
    DataManager?: Editor.Data.API,
  ) {
    range.accept(
      new TextSelectionNormalizer(
        {
          ...options,
          containerPosition: 'start',
        },
        DataManager,
      ),
    );
    range.accept(
      new TextSelectionNormalizer(
        {
          ...options,
          containerPosition: 'end',
        },
        DataManager,
      ),
    );
  }

  static fixSelection(
    range: DoDOCSelection.DoDOCRange,
    options: Partial<Editor.Selection.FixerOptions>,
  ) {
    let fixer: Editor.Selection.Range.IEditorRangeVisitor;
    if (range.collapsed) {
      fixer = new CollapsedSelectionFixer(options);
    } else {
      fixer = new NonCollapsedSelectionFixer(options);
    }
    range.accept(fixer);
    return fixer.updated;
  }

  static normalizeJsonPath(
    baseData: Editor.Data.Node.Data,
    path: Editor.Selection.Path,
    options: Partial<Editor.Selection.FixerOptions> = {},
  ) {
    return new JSONSelectionNormalizer(options).normalize(baseData, path);
  }
}
