import { JsonRange } from 'Editor/services/_Common/Selection';
import { Command } from '../Command';
import { NodeDataBuilder, NodeUtils } from 'Editor/services/DataManager';

export class EditCrossReferenceCommand extends Command {
  private options: Editor.Data.CrossReferences.PresentationTextOptionsType | undefined;

  constructor(
    context: Editor.Edition.ICommandArgs,
    options?: Editor.Data.CrossReferences.PresentationTextOptionsType,
  ) {
    super(context);
    this.options = options;
  }

  async exec(...args: any[]): Promise<Editor.Edition.ICommand> {
    if (
      !this.context.DataManager ||
      !this.context.DataManager.selection ||
      !this.context.contentManipulator
    ) {
      return this;
    }

    const rangeData = this.context.DataManager.selection.current;
    const jsonRange = JsonRange.buildFromRangeData(rangeData[0]);

    if (!jsonRange) {
      return this;
    }

    const baseModel = this.context.DataManager.nodes.getNodeModelById(jsonRange.start.b);
    const baseData = baseModel?.selectedData();

    if (!baseModel || !baseData) {
      return this;
    }

    const closestField = NodeUtils.closestOfTypeByPath(baseData, jsonRange.start.p, 'f');

    if (
      closestField &&
      NodeUtils.isFieldData(closestField.data) &&
      closestField.data.properties.t === 'cr'
    ) {
      if (this.options) {
        if (this.options.format != null) {
          baseModel.set([...closestField.path, 'properties', 'f'], this.options.format, {
            source: 'LOCAL_RENDER',
          });
        }

        if (this.options.link != null) {
          baseModel.set([...closestField.path, 'properties', 'l'], this.options.link, {
            source: 'LOCAL_RENDER',
          });
        }

        if (this.options.target != null) {
          baseModel.set([...closestField.path, 'properties', 'r'], this.options.target, {
            source: 'LOCAL_RENDER',
          });
        }
      } else {
        this.options = {
          target: closestField.data.properties
            .r as Editor.Data.CrossReferences.PresentationTextOptionsType['target'],
          format: closestField.data.properties.f as Editor.Data.CrossReferences.PresentationFormat,
        };
      }

      this.options.origin = baseModel.id;

      const text =
        await this.context.DataManager.crossReferences.getAsyncCrossReferencePresentationText(
          this.options,
        );

      if (text) {
        const textData = new NodeDataBuilder('text').setContent(text).build();
        if (textData) {
          baseModel.set([...closestField.path, 'childNodes'], [textData], {
            source: 'LOCAL_RENDER',
          });
        }
      }

      jsonRange.updateRangePositions({
        b: baseModel.id,
        p: [...closestField.path, 'childNodes', 0],
      });

      this.context.DataManager.selection.setUserSelection([jsonRange.serializeToRangeData()]);

      // create patch
      this.context.DataManager?.history.createPatch();
    }

    return this;
  }
}
