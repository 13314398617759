import { useChartColor, useChartOutline, useDataLabel, useStacked100 } from '../hooks';

const useBarSeries = () => {
  const { chartColor } = useChartColor();
  const { chartOutline } = useChartOutline();
  const { stacked100 } = useStacked100();
  const { dataLabel } = useDataLabel();

  const barSeries = ({
    chart,
    yAxisId,
  }: {
    chart: Presentation.Data.BarChart;
    yAxisId: echarts.BarSeriesOption['yAxisId'];
  }): echarts.BarSeriesOption[] => {
    const seriesInfo = chart.ser.map((el) => {
      if (el.val?.numRef?.numCache?.pt) {
        return el.val?.numRef?.numCache?.pt?.map((value) => +value.v * 10);
      }
      return [0];
    });

    const stack = chart.grouping === 'stacked';
    const stack100Percentage = chart.grouping === 'percentStacked';
    const percentageData = stacked100(seriesInfo);

    return chart.ser.map((serie, index) => {
      const gap = chart?.overlap ? -chart?.overlap : undefined;
      //TODO:CHARTS find the right way to calculate gapeWidth
      const gapWidth = chart?.gapWidth ? (chart?.gapWidth * 67) / 500 : 0;

      const option: echarts.BarSeriesOption = {
        type: chart.type,
        id: serie.idx,
        yAxisId,
        name: serie.tx?.strRef?.strCache?.pt[0].v,
        itemStyle: {
          //@ts-expect-error CHARTS:LIMITATION doenst support picture
          color:
            serie.properties?.fill?.type === 'none'
              ? 'transparent'
              : chartColor(serie?.properties?.fill),
          ...chartOutline(serie.properties?.ln),
          shadowBlur:
            serie.properties?.effects?.[0]?.rad ?? serie.properties?.effects?.[0]?.blurRad,
          //@ts-expect-error CHARTS:LIMITATION color only supports string (solid color)
          shadowColor: chartColor(serie.properties?.effects?.[0]?.color),
        },
        stack: stack || stack100Percentage ? 'stack' : undefined,
        silent: true,
        barWidth: stack || stack100Percentage ? '50%' : undefined,
        barGap: gap ? `${gap}%` : '0%',
        barCategoryGap: gapWidth === 0 ? 0 : `${gapWidth}%`,
        data: serie.val?.numRef?.numCache.pt.map((numVal, j) => {
          const idx = numVal.idx;
          const cat =
            serie.cat?.strRef?.strCache?.pt?.find((pt) => pt.idx === idx) ??
            serie.cat?.numRef?.numCache?.pt?.find((pt) => pt.idx === idx) ??
            serie.cat?.strLit?.pt?.find((pt) => pt.idx === idx) ??
            serie.cat?.numLit?.pt?.find((pt) => pt.idx === idx);
          const label = dataLabel({
            dLbls: serie.dLbls,
            val: numVal.v,
            cat: cat?.v,
            idx,
            defaultPosition: 'top',
          });
          return {
            value: stack100Percentage ? percentageData[index][j] : numVal.v,
            label,
          };
        }),
      };

      return option;
    });
  };

  return { barSeries };
};

export default useBarSeries;
