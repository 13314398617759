export const shapeArc = (
  cX: number,
  cY: number,
  rX: number,
  rY: number,
  stAng: number,
  endAng: number,
  isClose: boolean,
  position?: Presentation.Data.Common.Position,
) => {
  let dData;
  let angle = stAng;
  if (endAng >= stAng) {
    while (angle <= endAng) {
      const radians = angle * (Math.PI / 180); // convert degree to radians
      const x = cX + Math.cos(radians) * rX;
      const y = cY + Math.sin(radians) * rY;
      const left = position?.left ?? 0;
      const top = position?.top ?? 0;
      if (angle === stAng) {
        dData = ' M' + (x + left) + ' ' + (y + top);
      }
      dData += ' L' + (x + left) + ' ' + (y + top);
      angle++;
    }
  } else {
    while (angle > endAng) {
      const radians = angle * (Math.PI / 180); // convert degree to radians
      const x = cX + Math.cos(radians) * rX;
      const y = cY + Math.sin(radians) * rY;
      const left = position?.left ?? 0;
      const top = position?.top ?? 0;
      if (angle === stAng) {
        dData = ' M ' + (x + left) + ' ' + (y + top);
      }
      dData += ' L ' + (x + left) + ' ' + (y + top);
      angle--;
    }
  }
  dData += isClose ? ' z' : '';
  return dData ?? '';
};

//TODO:PRESENTATION Understand how to calculate scaleFactor without having it as an arbitrary number
const getScaleFactorByAng = (angle: number) => {
  switch (Math.abs(angle)) {
    case 360:
    case 180:
      return 2;
    case 90:
      return 1.41421;
    default:
      return 1.41421;
  }
};

export const arcTo = (
  wR: number,
  hR: number,
  stAng: number,
  swAng: number,
  startX: number,
  startY: number,
  scaleFactor?: number,
) => {
  const endAng = stAng + swAng;
  const inversedStAng = swAng < 0 ? stAng - 180 : stAng + 180;
  const half = Math.abs(inversedStAng - endAng) / 2;
  const endPointRotation = swAng < 0 ? inversedStAng + half : inversedStAng - half;
  const radRot = (endPointRotation * Math.PI) / 180;

  const endX = startX + wR * (scaleFactor || getScaleFactorByAng(swAng)) * Math.cos(radRot);
  //Sin is inversed because the [0, 0] point in a svg is Top-Left instead of Bottom-Left (comparing with a cartesian plane)
  const endY = startY + hR * (scaleFactor || getScaleFactorByAng(swAng)) * Math.sin(radRot);

  return { d: `A${wR} ${hR} 0 0 ${swAng < 0 ? '0' : '1'} ${endX} ${endY}`, x: endX, y: endY };
};

/**
 * pin 0 adj1 100000 (=) pin X Y Z (=) Math.max(Math.min(Z, Y), X)
 *
 * Source: Page 2897 of Ecma Office Open XML Part 1 - Fundamentals And Markup Language Reference.pdf
 */
export const pin = (x: number, y: number, z: number) => {
  return Math.max(Math.min(z, y), x);
};

/**
 * Context: "Units are in 60'000 of a degree"
 * Source: Page 2988 of Ecma Office Open XML Part 1 - Fundamentals And Markup Language Reference.pdf
 */
export const constantToRad = (constant: number) => {
  return ((constant / 60000) * Math.PI) / 180;
};
