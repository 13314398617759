import { FC } from "react";
import ChartBase from "../ChartBase/ChartBase";
import useSunburstSeries from "../series/useSunburstSeries";
import { useChartPlotArea } from "../hooks";

type SunburstChartProps = {
  shape: Presentation.Data.ChartShape;
};

const useSeries = ({
  shape,
}: {
  shape: Presentation.Data.ChartShape;
}): echarts.SunburstSeriesOption[] | undefined => {
  const { sunburstSeries } = useSunburstSeries();

  const series: echarts.SunburstSeriesOption[] = [];

  //@ts-expect-error this isn't supported yet
  shape.chartSpace.chart.plotArea.plotAreaRegion.series.forEach((chart) => {
    if (chart.layoutId === 'sunburst') {
      const chartSeries = sunburstSeries({ chart: shape.chartSpace });
      series.push(chartSeries[0]);
    }
  });
  return series;
};

const SunburstChart: FC<SunburstChartProps> = ({ shape }) => {
  const series = useSeries({ shape });
  //@ts-expect-error this isn't supported yet
  const grid = useChartPlotArea(shape.chartSpace.chart.plotArea?.plotAreaRegion?.plotSurface);

  return (
    <ChartBase
      shape={shape} chartOptions={{
        //@ts-expect-error echarts types
        grid: {
          ...grid,
        }, series
      }} />
  )
};

export default SunburstChart