// import { CSSProperties, useMemo } from 'react';
import { useTableStyle } from 'Presentation/PresentationData';
import TableDataProvider from './TableData';
import ShapeForeignObject from '../ShapeForeignObject';
// import { useSlideData } from '../../SlideData';
// import useSize from '../useSize';
import TableCell from './TableCell';
import useStyle from './useStyle';

type TableShapeProps = {
  shape: Presentation.Data.TableShape;
};

const TableShape = ({ shape }: TableShapeProps) => {
  const tblStyle = useTableStyle(shape.tbl);
  // const { color } = useSlideData();
  // const size = useSize(shape);
  console.log('Table', shape);
  console.log('Table style', tblStyle);

  // TODO: Abstract this from here
  // const style = useMemo(() => {
  //   const style: Record<string, string | undefined> = {};
  //   if (tblStyle?.wholeTbl) {
  //     style['--color'] = tblStyle.wholeTbl?.tcTxStyle?.color
  //       ? color(tblStyle.wholeTbl.tcTxStyle.color)
  //       : undefined;
  //   }

  //   if (shape.tbl.tblPr.effects) {
  //     for (let i = 0; i < shape.tbl.tblPr.effects.length; i++) {
  //       const effect = shape.tbl.tblPr.effects[i];
  //       if (effect.type === 'outerShadow') {
  //         const sx = (Math.max(effect.sx - 100, 0) / 100) * size.height;
  //         const sy = (Math.max(effect.sy - 100, 0) / 100) * size.width;
  //         style.boxShadow = `${sx}px ${sy}px ${effect.blurRad}px ${color(effect.color)}`;
  //       }
  //     }
  //   }
  //   return style as CSSProperties;
  // }, [tblStyle]);
  const style = useStyle(tblStyle?.wholeTbl);

  return (
    <TableDataProvider shape={shape}>
      <g id={shape.id}>
        <ShapeForeignObject>
          <table style={style}>
            <thead>
              <tr>
                {shape.tbl.tblGrid.map(({ w }) => (
                  <th style={{ width: w }} />
                ))}
              </tr>
            </thead>
            <tbody>
              {shape.tbl.tr.map((tr, rowIndex) => {
                return (
                  <tr style={{ height: tr.h }} key={tr.id}>
                    {tr.cells.map((tc, colIndex) => {
                      if (!tc.hMerge && !tc.vMerge) {
                        return (
                          <TableCell
                            key={tc.id}
                            tc={tc}
                            tblStyle={tblStyle}
                            rowIndex={rowIndex}
                            colIndex={colIndex}
                          />
                        );
                      }
                      return null;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </ShapeForeignObject>
      </g>
    </TableDataProvider>
  );
};

export default TableShape;
