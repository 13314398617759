import { ELEMENT_TYPES } from 'Editor/services/consts';
import { TabulationUtils } from 'Editor/services/_Common';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { EditorRange } from 'Editor/services/_Common/Selection';
import { BlockViewModel } from '../ViewModels/BlockViewModel/BlockViewModel';
import { DOMUtils } from '_common/utils';

const DEFAULT_TAB_STOP = 36; // pt

export class Tabulator {
  private Data: Editor.Data.API;
  private Visualizer: Editor.Visualizer.State;
  private selection?: {
    modifiers?: Editor.Selection.SelectionModifier;
  };

  constructor(
    dataManager: Editor.Data.API,
    Visualizer: Editor.Visualizer.State,
    selection?: {
      modifiers?: Editor.Selection.SelectionModifier;
    },
  ) {
    this.Data = dataManager;
    this.Visualizer = Visualizer;
    this.selection = selection;
  }

  get defaultTabStop() {
    const dts = this.Data.structure.getDefaultTabStop();
    if (dts !== null) {
      return EditorDOMUtils.convertUnitTo(dts, 'pt', 'px', 3) as number;
    } else {
      return EditorDOMUtils.convertUnitTo(DEFAULT_TAB_STOP, 'pt', 'px', 3) as number;
    }
  }

  private getContentWidth(range: EditorRange) {
    const rects = EditorDOMUtils.filterAndAdjustDOMRectList(range.getClientRects());
    let width = 0;
    for (let index = 0; index < rects.length; index++) {
      width += rects[index].width;
    }
    return width;
  }

  private getTabElementLeft(tabElement: HTMLElement, limit: number) {
    let leftValue = 0;
    const tabElementLeft = tabElement.getBoundingClientRect().left;
    const closestContainer = EditorDOMUtils.closestThatMatch(
      tabElement,
      'td, [data-header="true"], [data-footer="true"], section-element, [ispagenode="true"]',
    ) as HTMLElement;
    if (closestContainer) {
      leftValue = +(
        tabElementLeft - (closestContainer?.getBoundingClientRect()?.left || 0)
      ).toFixed(3);
    } else {
      leftValue = +tabElement.offsetLeft.toFixed(3);
    }
    if (leftValue >= limit) {
      return 0;
    }
    return leftValue;
  }

  private isAbsoluteTabElement(view: HTMLElement) {
    return view.dataset.st === 'a';
  }

  private getAbsoluteTabstopFromTab(view: HTMLElement, limit: number): Editor.Data.TabStop {
    const alignment = (view?.dataset.a as Editor.Data.TabStop['t']) || 'r';
    let value;
    if (alignment === 'l') {
      value = 0;
    } else if (alignment === 'c') {
      value = limit / 2;
    } else {
      value = limit - 0.5;
    }
    return {
      t: alignment,
      l: (view?.dataset.l as Editor.Data.TabStop['l']) || 'n',
      v: value,
    };
  }

  private getTabstopsFromView(view: HTMLElement) {
    if (!view.dataset.tabs) {
      return [];
    }
    return JSON.parse(view.dataset.tabs);
  }

  private mapTabStops(tabstops: Editor.Data.TabStop[]): Editor.Data.TabStop[] {
    if (!tabstops) {
      return [];
    }
    return tabstops.map((value: Editor.Data.TabStop) => {
      return {
        ...value,
        v: EditorDOMUtils.convertUnitTo(value.v as number, 'pt', 'px', 3),
      };
    });
  }

  private getTabStopsByType(
    tabstops: Editor.Data.TabStop[],
    type: Editor.Data.TabStop['t'],
  ): Editor.Data.TabStop[] {
    if (!tabstops) {
      return [];
    }
    return tabstops.reduce((previous: Editor.Data.TabStop[], value: Editor.Data.TabStop) => {
      if (value.t === type) {
        previous.push(value);
      }
      return previous;
    }, []);
  }

  private getTabStopsExceptType(
    tabstops: Editor.Data.TabStop[],
    type: Editor.Data.TabStop['t'],
  ): Editor.Data.TabStop[] {
    if (!tabstops) {
      return [];
    }
    return tabstops.reduce((previous: Editor.Data.TabStop[], value: Editor.Data.TabStop) => {
      if (value.t !== type) {
        previous.push(value);
      }
      return previous;
    }, []);
  }

  getTabStops(view: Editor.Visualizer.BaseView) {
    const blockTabsStops = this.getTabstopsFromView(view) || [];
    const elementStyle = view.dataset.styleId || 'p';
    const styleTabStops = this.Data.styles.getDocumentStyleFromId(elementStyle)?.tabs || [];
    let listStyleTabStops: Editor.Data.TabStop[] = [];
    let listLevel = this.Data.numbering.getLevelDefinitionFromBlock(view.id);
    if (listLevel && listLevel.tabs) {
      listStyleTabStops = listLevel.tabs;
    }
    let tabstops = TabulationUtils.mergeTabStops(styleTabStops, listStyleTabStops, blockTabsStops); // add style tabstops
    return tabstops;
  }

  private findNextDefaultTabStop(value: number, limit: number): Editor.Data.TabStop | undefined {
    let nextTS = Math.ceil(value / this.defaultTabStop) * this.defaultTabStop;
    if (nextTS.toFixed(1) === value.toFixed(1)) {
      nextTS += this.defaultTabStop;
    }
    if (nextTS > limit) {
      return undefined;
    }
    if (nextTS === 0) {
      nextTS = this.defaultTabStop;
    }
    return {
      t: 'l',
      v: nextTS,
      l: 'n',
    };
  }

  private findProperTabStop(left: number, tabstops: Editor.Data.TabStop[], limit: number) {
    let tsValue;
    let length = tabstops.length - 1;
    for (var i = 0; i <= length; ++i) {
      tsValue = tabstops[i].v;
      if (tsValue && left < tsValue) {
        return tabstops[i];
      }
    }
    return this.findNextDefaultTabStop(left, limit);
  }

  private drawBarsIfNeeded(
    tabstops: Editor.Data.TabStop[],
    view: Editor.Elements.ParagraphElement,
  ) {
    const barTabStops = this.getTabStopsByType(tabstops, 'b');
    if (barTabStops.length === 0) {
      this.Visualizer.widgets?.removeWidget('tabulations', view.id);
      return;
    }
    this.Visualizer.widgets?.addWidget('tabulations', view, {
      tabPos: barTabStops.map((value) => value.v),
    });
    // const computedStyles = window.getComputedStyle(view);
    // let barTop;
    // let barBottom;
    // if (computedStyles) {
    //   barTop = `${-1 - DOMUtils.convertUnitTo(computedStyles.marginTop, null, 'px', 2)}px`;
    //   barBottom = `${-1 - DOMUtils.convertUnitTo(computedStyles.marginBottom, null, 'px', 2)}px`;
    // }
    // for (let index = 0; index < barTabStops.length; index++) {
    //   const barStop = barTabStops[index];
    //   let bar = document.createElement('div');
    //   bar.style.width = '1px';
    //   bar.style.position = 'absolute';
    //   bar.style.left = `${barStop.v}px`;
    //   if (barTop) {
    //     bar.style.top = barTop;
    //   }
    //   if (barBottom) {
    //     bar.style.bottom = barBottom;
    //   }
    //   bar.style.backgroundColor = 'black';
    //   // TODO: append bar somewhere
    // }
  }

  private tabstopAlignLeft(
    tabStop: Editor.Data.TabStop,
    tabElement: HTMLElement,
    nextTabElement: HTMLElement,
    view: HTMLElement,
    limit: number,
  ) {
    const left = this.getTabElementLeft(tabElement, limit);
    var width = tabStop.v - left;
    if (tabElement instanceof HTMLElement) {
      // tabElement.style.width = width + 'px';
      tabElement.dataset.width = String(width);
      if (tabStop.l) {
        tabElement.dataset.leading = tabStop.l;
      } else {
        tabElement.dataset.leading = 'none';
      }
    }
  }

  private tabstopAlignRight(
    tabStop: Editor.Data.TabStop,
    tabElement: HTMLElement,
    nextTabElement: HTMLElement,
    view: HTMLElement,
    limit: number,
  ) {
    const left = this.getTabElementLeft(tabElement, limit);
    const range = new EditorRange();
    range.setStartAfter(tabElement);
    if (nextTabElement) {
      range.setEndBefore(nextTabElement);
    } else {
      range.setEndAfter(view.lastChild as Node);
    }
    const contentWidth = this.getContentWidth(range);
    let width = tabStop.v - left - contentWidth;
    if (width < 0) {
      width = 0;
    }
    if (tabElement instanceof HTMLElement) {
      // tabElement.style.width = width + 'px';
      tabElement.dataset.width = String(width);
      if (tabStop.l) {
        tabElement.dataset.leading = tabStop.l;
      } else {
        tabElement.dataset.leading = 'none';
      }
    }
  }

  private tabstopAlignCenter(
    tabStop: Editor.Data.TabStop,
    tabElement: HTMLElement,
    nextTabElement: HTMLElement,
    view: HTMLElement,
    limit: number,
  ) {
    const left = this.getTabElementLeft(tabElement, limit);
    let range = new EditorRange();
    range.setStartAfter(tabElement);
    if (nextTabElement) {
      range.setEndBefore(nextTabElement);
    } else {
      range.setEndAfter(view.lastChild as Node);
    }
    const contentWidth = this.getContentWidth(range);
    let width = tabStop.v - left - contentWidth / 2;
    if (width < 0) {
      width = 0;
    }
    if (tabElement instanceof HTMLElement) {
      // tabElement.style.width = width + 'px';
      tabElement.dataset.width = String(width);
      if (tabStop.l) {
        tabElement.dataset.leading = tabStop.l;
      } else {
        tabElement.dataset.leading = 'none';
      }
    }
  }

  private tabstopAlignBar(
    tabStop: Editor.Data.TabStop,
    tabElement: HTMLElement,
    nextTabElement: HTMLElement,
    view: HTMLElement,
    limit: number,
  ) {
    const left = this.getTabElementLeft(tabElement, limit);
    const nextDefault = this.findNextDefaultTabStop(
      left,
      EditorDOMUtils.convertUnitTo(window.getComputedStyle(view).width, undefined, 'px', 3),
    );
    if (nextDefault) {
      this.tabstopAlignLeft(nextDefault, tabElement, nextTabElement, view, limit);
    }
    const computedStyles = window.getComputedStyle(view);
    let bar = document.createElement('div');
    bar.style.width = '1px';
    bar.style.position = 'absolute';
    bar.style.left = `${tabStop.v}px`;
    if (computedStyles) {
      bar.style.top = `${
        -1 - DOMUtils.convertUnitTo(computedStyles.marginTop, undefined, 'px', 2)
      }px`;
      bar.style.bottom = `${
        -1 - DOMUtils.convertUnitTo(computedStyles.marginBottom, undefined, 'px', 2)
      }px`;
    }
    bar.style.backgroundColor = 'black';
    tabElement.innerHTML = '';
    tabElement.appendChild(bar);
    // append bar
  }

  private tabstopAlignDecimal(
    tabStop: Editor.Data.TabStop,
    tabElement: HTMLElement,
    nextTabElement: HTMLElement,
    view: HTMLElement,
    limit: number,
  ) {
    const left = this.getTabElementLeft(tabElement, limit);
    let range = new EditorRange();
    range.setStartAfter(tabElement);
    if (nextTabElement) {
      range.setEndBefore(nextTabElement);
    } else {
      range.setEndAfter(view.lastChild as Node);
    }
    let elementWidth;
    let rangeContent = range.toString();
    const foundDecimal = rangeContent.match(/\d+\.\d*/);
    if (foundDecimal) {
      let newRange = new EditorRange();
      newRange.setStart(range.startContainer, range.startOffset);
      newRange.setEnd(range.startContainer, range.startOffset);
      const offset = foundDecimal[0].indexOf('.');
      this.selection?.modifiers?.modify(
        newRange,
        'expand',
        'character',
        'forward',
        (foundDecimal.index || 0) + offset,
      );
      elementWidth = this.getContentWidth(newRange);
    } else {
      elementWidth = this.getContentWidth(range);
    }
    let width = tabStop.v - left - elementWidth;
    if (width < 0) {
      width = 0;
    }
    if (tabElement instanceof HTMLElement) {
      // tabElement.style.width = width + 'px';
      tabElement.dataset.width = String(width);
      if (tabStop.l) {
        tabElement.dataset.leading = tabStop.l;
      } else {
        tabElement.dataset.leading = 'none';
      }
    }
  }

  private applyTabStop(
    tabStop: Editor.Data.TabStop,
    tabElement: HTMLElement,
    nextTabElement: HTMLElement,
    view: HTMLElement,
    limit: number,
  ) {
    switch (tabStop.t) {
      case 'l':
        this.tabstopAlignLeft(tabStop, tabElement, nextTabElement, view, limit);
        break;
      case 'd':
        this.tabstopAlignDecimal(tabStop, tabElement, nextTabElement, view, limit);
        break;
      case 'r':
        this.tabstopAlignRight(tabStop, tabElement, nextTabElement, view, limit);
        break;
      case 'c':
        this.tabstopAlignCenter(tabStop, tabElement, nextTabElement, view, limit);
        break;
      case 'b':
        this.tabstopAlignBar(tabStop, tabElement, nextTabElement, view, limit);
        break;
      default:
        break;
    }
  }

  private indentBlockView(view: Editor.Visualizer.BaseView, tabstops: Editor.Data.TabStop[] = []) {
    const levelDef = this.Data.numbering.getLevelDefinitionFromBlock(view.id);
    if (!levelDef || levelDef.fnw === 'spc' || levelDef.fnw === 'not') {
      return;
    }
    view.style.setProperty('--beforeMargin', `0px`);
    let range = new EditorRange();
    range.setRangeStart(view, 'INSIDE_START');
    range.setRangeEnd(view, 'INSIDE_START');
    const rects = range.getClientRects();
    if (!rects[0]) {
      return;
    }
    const elementLeft = view.getClientRects()[0].left || 0;
    const textIndentAt = rects[0].left - elementLeft;
    const textShouldIndentAt = EditorDOMUtils.convertUnitTo(
      window.getComputedStyle(view).getPropertyValue('--hanging'),
      undefined,
      'px',
      2,
    );
    if (textIndentAt <= textShouldIndentAt) {
      view.style.setProperty('--beforeMargin', `${textShouldIndentAt - textIndentAt}px`);
      return;
    }
    const tabStop = this.findProperTabStop(textIndentAt, tabstops, view?.clientWidth || 0);
    if (!tabStop) {
      return;
    }
    view.style.setProperty('--beforeMargin', `${tabStop.v - textIndentAt}px`);
  }

  tabulateBlockView(view: Editor.Visualizer.BaseView) {
    let tabstops = this.getTabStops(view);
    tabstops = this.mapTabStops(tabstops);

    if (EditorDOMElements.isParagraphElement(view)) {
      this.drawBarsIfNeeded(tabstops, view);
    }

    tabstops = this.getTabStopsExceptType(tabstops, 'b');
    this.indentBlockView(view, tabstops);
    const tabElements = Array.from(view?.querySelectorAll('tab-element') || []) as HTMLElement[];

    if (!tabElements.length) {
      return;
    }

    let tabElement;
    let tabStop;
    let left;
    let limit = EditorDOMUtils.convertUnitTo(
      window.getComputedStyle(view).width,
      undefined,
      'px',
      3,
    );
    for (let tabIterator = 0; tabIterator < tabElements.length; tabIterator++) {
      tabElement = tabElements[tabIterator];
      // tabElement.style.width = '0px';
      tabElement.dataset.width = String(0);
      left = this.getTabElementLeft(tabElement, limit);
      if (this.isAbsoluteTabElement(tabElement)) {
        tabStop = this.getAbsoluteTabstopFromTab(tabElement, limit || 0);
      } else {
        tabStop = this.findProperTabStop(left, tabstops, limit || 0);
      }
      if (!tabStop) {
        continue;
      }
      this.applyTabStop(
        tabStop,
        tabElement,
        tabElements[tabIterator + 1],
        view as HTMLElement,
        limit,
      );
    }
  }

  private tabulateTable(vm: BlockViewModel) {
    let view;
    for (let viewIndex = 0; viewIndex < vm.splitViews.length; viewIndex++) {
      view = vm.getRootView(viewIndex);
      if (!view) {
        continue;
      }
      const blocks = Array.from(view.querySelectorAll('paragraph-element') || []) as HTMLElement[];
      for (let index = 0; index < blocks.length; index++) {
        this.tabulateBlockView(blocks[index]);
      }
    }
  }

  tabulate(vm: BlockViewModel) {
    const nodeModel = vm.getModel();
    if (nodeModel.loaded) {
      if (
        nodeModel.type === ELEMENT_TYPES.TableElement ||
        nodeModel.type === ELEMENT_TYPES.TableOfContentsElement ||
        nodeModel.type === ELEMENT_TYPES.TableOfLabelsElement
      ) {
        return this.tabulateTable(vm);
      }
      let view;
      for (let viewIndex = 0; viewIndex < vm.splitViews.length; viewIndex++) {
        view = vm.getRootView(viewIndex);
        if (!view) {
          continue;
        }
        this.tabulateBlockView(view);
      }
    }
  }
}
