import { useSlideData } from '../../../SlideData';
import { parseOutline } from '../../useOutline';
import useChartColor from './useChartColor';

const useChartPlotArea = (
  plotArea: Presentation.Data.ChartShape['chartSpace']['chart']['plotArea'],
) => {
  const { chartColor } = useChartColor();
  const { color } = useSlideData();
  if (plotArea) {
    const values = parseOutline(plotArea.properties.ln, color);

    return {
      show: plotArea.properties.fill?.type === 'none' ? false : true,
      backgroundColor: chartColor(plotArea.properties.fill),
      borderColor: chartColor(plotArea.properties?.ln?.fill),
      borderWidth: values.strokeWidth,
      borderType: values.strokeDasharray?.split(',').map((value) => +value),
      cap: values.strokeLinecap,
      join: values.strokeLinejoin,
      miterLimit: values.strokeMiterlimit,
    };
  }
  return null;
};

export default useChartPlotArea;
