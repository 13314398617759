import { CSSProperties, useMemo } from 'react';
import { useSlideData } from '../../SlideData';

const useStyle = (
  styleProperties?: Presentation.Data.Table.Cell.Style,
  tcPr?: Presentation.Data.Table.CellProperties,
) => {
  const { color } = useSlideData();
  return useMemo(() => {
    const style: CSSProperties = {};
    if (styleProperties) {
      if (styleProperties.tcTxStyle?.color) {
        // @ts-expect-error CSSProperties type does not allow css variables
        style['--color'] = color(styleProperties.tcTxStyle.color);
      }
      if (styleProperties.tcTxStyle?.b) {
        style.fontWeight = 'bold';
      }
    }
    if (tcPr) {
      if (tcPr.marT) {
        // @ts-expect-error css variable not supported by type
        style['--marT'] = `${tcPr.marT}px`;
      }
      if (tcPr.marR) {
        // @ts-expect-error css variable not supported by type
        style['--marR'] = `${tcPr.marR}px`;
      }
      if (tcPr.marB) {
        // @ts-expect-error css variable not supported by type
        style['--marB'] = `${tcPr.marB}px`;
      }
      if (tcPr.marL) {
        // @ts-expect-error css variable not supported by type
        style['--marL'] = `${tcPr.marL}px`;
      }
      switch (tcPr.anchor) {
        case 'b':
          style.verticalAlign = 'bottom';
          break;
        case 'ctr':
          style.verticalAlign = 'center';
          break;
        case 't':
          style.verticalAlign = 'top';
          break;
        case 'dist':
        case 'just':
          console.warn('tcPr.anchor', tcPr.anchor, 'TBI');
      }
    }
    return style;
  }, [styleProperties, tcPr]);
};

export default useStyle;
