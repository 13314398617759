import { FC } from 'react';
import * as echarts from 'echarts';

import { useSlideData } from 'Presentation/Slides/Slide/SlideData';
import {
  useChartAxis,
  useChartColor,
  useChartOutline,
  useChartPlotArea,
  useDataLabel,
} from '../hooks';
import { markerSymbol } from '../svg';

import ChartBase from '../ChartBase/ChartBase';

type ScatterChartProps = {
  shape: Presentation.Data.ChartShape;
};

const LIB_SYMBOLS: Partial<Record<Presentation.Data.Marker['symbol'], string | null>> = {
  circle: 'circle',
  square: 'rect',
  diamond: 'diamond',
  triangle: 'triangle',
  none: null,
};

const useSeries = ({
  shape,
}: {
  shape: Presentation.Data.ChartShape;
}): echarts.ScatterSeriesOption[] | undefined => {
  const { color } = useSlideData();
  const { chartColor } = useChartColor();
  const { chartOutline } = useChartOutline();
  const { dataLabel } = useDataLabel();
  const chartTypeData = shape.chartSpace.chart.plotArea.chartTypes[0];

  if (chartTypeData.type !== 'scatter') {
    return undefined;
  }

  const generateSerieLines = (serie: Presentation.Data.ScatterSer) => {
    /*
       * Generate a line that will pass by all points of the serie
       * The lib only supports lines constituted by 2 points, so it will be rendered multiple lines:
       * A Line is made by 2 points 
       * A point is constituted by two coordinates of [x, y]
       * For every 2 points, a new line will be created with first coordinate as the previous line last coordinate, this way the lines will be connected
       * Example that will create 2 lines: 
          [
              //Line 1
              [
                {
                  coord: [1, 3], //Point A
                },
                {
                  coord: [2, 3], //Point B
                },
              ],
              //Line 2
              [
                {
                  coord: [2, 3], //Point A (Previous line Point B)
                },
                {
                  coord: [5, 3], //Point B
                },
              ],
            ]
      */

    const lines: { coord: number[] }[][] = [];

    serie?.xVal?.numRef?.numCache?.pt?.forEach((numVal, i) => {
      let x = numVal.v ?? 0;
      let y = serie?.yVal?.numRef?.numCache?.pt[i].v ?? 0;

      if (isNaN(+x)) {
        x = '0';
      }
      if (isNaN(+y)) {
        y = '0';
      }

      const coord = { coord: [+x, +y] };

      if (i % 2 === 0) {
        if (i === 0) {
          lines.push([coord]);
        } else {
          const prevCoord = lines[i - 2][1];
          lines.push([prevCoord, coord]);
        }
      } else {
        lines[i - 1].push(coord);
      }
    });

    return lines;
  };

  return chartTypeData.ser.map((serie: Presentation.Data.ScatterSer) => {
    const marker = serie.marker;
    const lineOutline = chartOutline(serie.properties?.ln);

    const option: echarts.ScatterSeriesOption = {
      type: 'scatter',
      id: serie.idx,
      silent: true,
      itemStyle: {
        opacity: 1,
        borderColor: 'red',
      },
      /*
       * CHARTS:LIMITATION lineStyle 'color' doesnt support image
       * TODO:CHARTS try to type better the return of generateSerieLines.
       */
      //@ts-expect-error TODO:CHARTS comments above
      markLine:
        //@ts-expect-error TODO:CHARTS check the type. fill 'type' isnt supporting 'none', but the this property can come as 'none'
        serie.properties?.ln?.fill?.type !== 'none'
          ? {
              lineStyle: {
                color: lineOutline?.borderColor,
                width: lineOutline?.borderWidth,
                type: lineOutline?.borderType ?? 'solid',
                join: lineOutline?.borderJoin,
                cap: lineOutline?.borderCap,
                miterLimit: lineOutline?.borderMiterLimit,
              },
              symbol: ['none', 'none'],
              data: generateSerieLines(serie),
            }
          : undefined,

      data: serie?.xVal?.numRef?.numCache?.pt?.map((numVal, index: number) => {
        let itemStyle = undefined;

        //Marker properties
        if (marker?.properties) {
          const outline = chartOutline(marker.properties.ln);
          itemStyle = {
            ...outline,
            color: chartColor(marker?.properties?.fill),
          };
        }

        //Marker symbol
        let symbolBase64: string | undefined = undefined;
        if (marker?.symbol) {
          if (!LIB_SYMBOLS[marker?.symbol]) {
            const symbolSVG = markerSymbol({
              symbol: marker?.symbol,
              properties: marker?.properties,
              height: marker?.size ?? 0,
              width: marker?.size ?? 0,
              color,
            });

            if (symbolSVG) {
              symbolBase64 = window.btoa(symbolSVG);
            }
          }
        }

        const idx = numVal.idx;
        const label = dataLabel({
          dLbls: serie.dLbls,
          val: serie?.yVal?.numRef?.numCache?.pt[index].v,
          cat: numVal.v,
          idx,
          defaultPosition: 'right',
        });

        //@ts-expect-error "symbol" cant be null, but this is a hack to display data labels without symbol.
        const data: Exclude<echarts.ScatterSeriesOption['data'], undefined>[number] = {
          value: [numVal.v, serie?.yVal?.numRef?.numCache?.pt[index].v],
          label,
          symbol: marker?.symbol
            ? symbolBase64
              ? `image://data:image/svg+xml;base64,${symbolBase64}`
              : LIB_SYMBOLS[marker.symbol]
            : null,
          symbolSize: marker?.size ?? 0,
          itemStyle,
        };

        return data;
      }),
    };

    return option;
  });
};

const ScatterChart: FC<ScatterChartProps> = ({ shape }) => {
  const axis = useChartAxis(shape);
  const grid = useChartPlotArea(shape.chartSpace.chart.plotArea);
  const series = useSeries({ shape });

  return (
    <ChartBase
      shape={shape}
      chartOptions={{
        //@ts-expect-error echarts types
        grid: {
          ...grid,
        },
        xAxis: axis.xAxis,
        yAxis: axis.yAxis,
        series,
      }}
    />
  );
};

export default ScatterChart;
