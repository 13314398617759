import { IntlErrorBoundary } from '_common/components';
import Note from './Note';
import styles from './Note.module.scss';
import NoteDataProvider from './NoteData';

const NoteBoundary = () => {
  return (
    <div className={styles.note}>
      <IntlErrorBoundary size="small" margin="auto 2rem">
        <NoteDataProvider>
          <Note />
        </NoteDataProvider>
      </IntlErrorBoundary>
    </div>
  );
};

export default NoteBoundary;
