/* eslint-disable camelcase */
import { createSelector } from '@reduxjs/toolkit';
import { FILE_TYPE_FROM_MIME } from '_common/consts';
import { selectElementStatusList } from '_common/services/api/elementStatusApi';

export type Action =
  | 'rename'
  | 'remove'
  | 'download'
  | 'downloadOriginalFile'
  | 'move'
  | 'copy'
  | 'checkOut'
  | 'update'
  | 'checkIn'
  | 'cancelCheckOut'
  | 'copyLink'
  | 'changeStatus'
  | 'exportDocumentTo'
  | 'exportVeeva'
  | 'exportDesktop'
  | 'convertTo'
  | 'openElement'
  | 'preview'
  | 'wopiView'
  | 'wopiEdit';

export type ActionsToShow = { [key in Action]: boolean };

const getData = (state: RootState) => state.app.data;
const getTriggerPage = (state: RootState) => state.search.triggerPage;
const getCurrentUserId = (state: RootState) => state.auth.userId;
export const getSelectedData = (state: RootState, { selected }: { selected: ObjectId[] }) =>
  selected.filter((id: ObjectId) => state.app.data[id]).map((id) => state.app.data[id]);
const getCurrentData = (state: RootState, { current }: { current?: { id: ObjectId } }) =>
  !current?.id ? null : state.app.data[current.id];
const getOptionsToShow = (_: RootState, { optionsToShow }: { optionsToShow: ActionsToShow }) =>
  optionsToShow;
const getCurrentFolder = (state: RootState) => state.storage.current;

/**
 *  User can rename if:
 *    -> only one object is selected
 *    -> it isn't a personal space
 *    -> status allows to edit
 *    -> if a file and currently checked out
 *      . must be the user who set as checked out
 *    -> it is inside any object except personal space and nothing is selected
 */
const rename = createSelector(
  [getOptionsToShow, getSelectedData, getCurrentUserId, selectElementStatusList],
  (show, selected, userId, statuses) => {
    if (!show || !show.rename) {
      return false;
    }
    const ALLOWED_PERMISSIONS = ['admin', 'owner', 'edit'];
    if (selected.length !== 1) {
      return false;
    }
    const status = statuses.find(({ id }) => selected[0].status === id);

    if (
      status?.allow_edit !== true ||
      selected[0].status === 'broken' ||
      selected[0].status === 'processing'
    ) {
      return false;
    }
    if (
      ALLOWED_PERMISSIONS.some((permission) => selected[0].user_permissions.includes(permission))
    ) {
      if (selected[0].status === 'checked_out') {
        // ! TODO: Review if allow admin or owner to rename
        return (
          selected[0].lifecycle.checkouts[selected[0].lifecycle.checkouts.length - 1].creator ===
          userId
        );
      }
      return true;
    }
    return false;
  },
);

/**
 *  User can delete if:
 *    -> there is at least one object selected
 *    -> it has owner or delete permissons
 *    -> status is not checked_out and allows to edit
 */
const remove = createSelector(
  [getOptionsToShow, getSelectedData, selectElementStatusList, getTriggerPage],
  (show, selected, statuses, page) => {
    if (page === 'spaces') {
      const conditionsToEnableShare = ['admin', 'owner'];

      return (
        !selected[0]?.personal ||
        !conditionsToEnableShare.some(
          (permission) => selected[0] && selected[0].user_permissions.includes(permission),
        )
      );
    }

    if (page === 'shared') {
      return false;
    }
    if (!show || !show.remove) {
      return false;
    }
    const ALLOWED_PERMISSIONS = ['admin', 'owner', 'delete'];
    if (selected.length < 1) {
      return false;
    }
    return selected.every((object) => {
      const status = statuses.find(({ id }) => object.status === id);
      if (object.status === 'checked_out' || status?.allow_delete !== true) {
        return false;
      }
      return ALLOWED_PERMISSIONS.some((permission) => object.user_permissions.includes(permission));
    });
  },
);

/**
 *  User can download if:
 *    -> at least 1 file is selected
 *    -> only files can be selected
 *    -> they must not be in a broken state
 */
const download = createSelector([getOptionsToShow, getSelectedData], (show, selected) => {
  if (!show || !show.download) {
    return false;
  }
  const ALLOWED_PERMISSIONS = ['admin', 'owner', 'access'];
  if (selected.length < 1) {
    return false;
  }
  return selected.every((object) => {
    if (object.type !== 'file' || object.status === 'broken') {
      return false;
    }
    return ALLOWED_PERMISSIONS.some((permission) => object.user_permissions.includes(permission));
  });
});

/**
 *  User can download original file if:
 *    -> only 1 .ddc document or .pdf file is selected
 *    -> only .ddc documents or .pdf files can be selected
 *    -> document must have source
 *    -> must not be in broken state
 *    -> must have access or admin/owner permission
 */
const downloadOriginalFile = createSelector(
  [getOptionsToShow, getSelectedData],
  (show, selected) => {
    if (!show || !show.downloadOriginalFile) {
      return false;
    }
    const ALLOWED_PERMISSIONS = ['admin', 'owner', 'access'];
    if (selected.length !== 1) {
      return false;
    }

    return selected.every((object) => {
      if (
        (object.type !== 'document' && object.type !== 'dopdf' && object.type !== 'presentation') ||
        object.has_source === false ||
        object.status === 'broken'
      ) {
        return false;
      }
      return ALLOWED_PERMISSIONS.some((permission) => object.user_permissions.includes(permission));
    });
  },
);

/**
 *  User can move the selected items if:
 *    -> isAdmin
 *    -> has owner creator or edit permissions
 *    -> parent isn't approved
 */
const move = createSelector(
  [getOptionsToShow, getSelectedData, getCurrentData, getTriggerPage, selectElementStatusList],
  (show, selected, current, page, statuses) => {
    if (!page?.includes('storage')) {
      return false;
    }
    if (!show || !show.move) {
      return false;
    }
    const ALLOWED_PERMISSIONS = ['admin', 'owner', 'edit'];
    if (selected.length < 1) {
      return false;
    }
    if (!current || current.status === 'approved') {
      return false;
    }
    return selected.every((object) => {
      const status = statuses.find(({ id }) => object.status === id);
      if (
        object.status === 'checked_out' ||
        object.status === 'processing' ||
        object.status === 'broken' ||
        status?.allow_move !== true
      ) {
        return false;
      }
      return ALLOWED_PERMISSIONS.some((permission) => object.user_permissions.includes(permission));
    });
  },
);

/**
 *  User can copy the selected items if:
 *    -> isAdmin
 *    -> has owner creator or edit permissions????
 */
const copy = createSelector(
  [getOptionsToShow, getSelectedData, getTriggerPage],
  (show, selected, page) => {
    if (!page?.includes('storage')) {
      return false;
    }
    if (!show || !show.copy) {
      return false;
    }
    const ALLOWED_PERMISSIONS = ['admin', 'owner', 'access'];
    if (selected.length < 1) {
      return false;
    }
    return selected.every((node) => {
      if (node.status === 'broken' || node.status === 'processing') {
        return false;
      }
      return ALLOWED_PERMISSIONS.some((permission) => node.user_permissions.includes(permission));
    });
  },
);

/**
 *  User can enter check out a file if:
 *    -> the object is of type file
 *    -> it is the creator or has owner or edit permissions
 *    -> the file status allows to edit
 */
const checkOut = createSelector(
  [getOptionsToShow, getSelectedData, selectElementStatusList],
  (show, selected, statuses) => {
    if (!show || !show.checkOut) {
      return false;
    }
    const ALLOWED_PERMISSIONS = ['admin', 'owner', 'edit'];
    if (selected.length !== 1 || selected[0].status === 'broken') {
      return false;
    }
    const status = statuses.find(({ id }) => selected[0].status === id);
    return (
      selected[0].status !== 'checked_out' &&
      selected[0].type === 'file' &&
      status?.allow_edit === true &&
      ALLOWED_PERMISSIONS.some((permission) => selected[0].user_permissions.includes(permission))
    );
  },
);

/**
 *  User can update a file if:
 *    -> the object is of type file
 *    -> it is the creator or has owner or edit permissions
 *    -> the file status allows to edit
 */
const update = createSelector(
  [getOptionsToShow, getSelectedData, selectElementStatusList],
  (show, selected, statuses) => {
    if (!show || !show.update) {
      return false;
    }
    const ALLOWED_PERMISSIONS = ['admin', 'owner', 'edit'];
    if (selected.length !== 1 || selected[0].status === 'broken') {
      return false;
    }
    const status = statuses.find(({ id }) => selected[0].status === id);
    return (
      selected[0].type === 'file' &&
      status?.allow_edit === true &&
      ALLOWED_PERMISSIONS.some((permission) => selected[0].user_permissions.includes(permission))
    );
  },
);

/**
 *  User can check in a file if:
 *    -> the object is of type file
 *    -> it is the creator or has owner or edit permissions????
 *    -> the file is CHECKED OUT??
 */
const checkIn = createSelector(
  [getOptionsToShow, getSelectedData, getCurrentUserId],
  (show, selected, userId) => {
    if (!show || !show.checkIn) {
      return false;
    }
    if (selected.length !== 1) {
      return false;
    }
    if (
      selected[0].type !== 'file' ||
      selected[0].status !== 'checked_out' ||
      selected[0].status === 'broken'
    ) {
      return false;
    }
    if (
      selected[0].user_permissions.includes('admin') ||
      selected[0].user_permissions.includes('owner')
    ) {
      return true;
    }
    if (
      selected[0].user_permissions.includes('edit') &&
      selected[0].lifecycle &&
      selected[0].lifecycle.checkouts &&
      selected[0].lifecycle.checkouts[selected[0].lifecycle.checkouts.length - 1].creator === userId
    ) {
      return true;
    }
    return false;
  },
);

/**
 *  User can cancel a check out a file if:
 *    -> the object is of type file
 *    -> it is the creator or has owner or edit permissions????
 *    -> the file is CHECKED OUT??
 */
const cancelCheckOut = createSelector(
  [getOptionsToShow, getSelectedData, getCurrentUserId],
  (show, selected, userId) => {
    if (!show || !show.cancelCheckOut) {
      return false;
    }
    if (selected.length !== 1) {
      return false;
    }
    if (
      selected[0].type !== 'file' ||
      selected[0].status !== 'checked_out' ||
      selected[0].status === 'broken'
    ) {
      return false;
    }
    if (
      selected[0].user_permissions.includes('admin') ||
      selected[0].user_permissions.includes('owner')
    ) {
      return true;
    }
    if (
      selected[0].user_permissions.includes('edit') &&
      selected[0].lifecycle &&
      selected[0].lifecycle.checkouts &&
      selected[0].lifecycle.checkouts[selected[0].lifecycle.checkouts.length - 1].creator === userId
    ) {
      return true;
    }

    return false;
  },
);

const copyLink = createSelector([getOptionsToShow, getSelectedData], (show, selected) => {
  if (!show || !show.copyLink) {
    return false;
  }
  if (selected.length !== 1) {
    return false;
  }
  if (selected[0].type !== 'file' || selected[0].status === 'broken') {
    return false;
  }

  return true;
});

const changeStatus = createSelector(
  [getOptionsToShow, getSelectedData, selectElementStatusList, getCurrentFolder, getData],
  (show, selected, statuses, currentId, data) => {
    if (!currentId) {
      return false;
    }

    const currentObj = data[currentId];
    // Can't change status of element with an edit = false status
    if (currentObj?.status) {
      const currentFolderStatus = statuses.find(({ id }) => currentObj.status === id);
      if (!currentFolderStatus?.allow_edit) {
        return false;
      }
    }
    if (!show || !show.changeStatus) {
      return false;
    }
    const ALLOWED_PERMISSIONS = ['admin', 'owner'];
    if (selected.length !== 1) {
      return false;
    }
    const status = statuses.find(({ id }) => selected[0].status === id);
    if (status?.allow_change !== true) {
      return false;
    }
    return ALLOWED_PERMISSIONS.some((permission) =>
      selected[0].user_permissions.includes(permission),
    );
  },
);

const exportDocumentTo = createSelector([getOptionsToShow, getSelectedData], (show, selected) => {
  const ALLOWED_PERMISSIONS = ['admin', 'owner'];
  if (
    selected[0]?.type !== 'document' &&
    selected[0]?.type !== 'dopdf' &&
    selected[0]?.type !== 'presentation'
  ) {
    return false;
  }

  if (selected.length !== 1) {
    return false;
  }
  return ALLOWED_PERMISSIONS.some((permission) =>
    selected[0].user_permissions.includes(permission),
  );
});

const convertTo = createSelector([getOptionsToShow, getSelectedData], (show, selected) => {
  if (!show || !show.convertTo) {
    return false;
  }
  const ALLOWED_PERMISSIONS = ['admin', 'owner'];
  if (selected.length === 0) {
    return false;
  }

  const firstSelected = selected[0];

  return (
    // @ts-expect-error type mismatch here
    FILE_TYPE_FROM_MIME[firstSelected.mime?.type] &&
    selected.every((obj) => {
      return (
        obj.status !== 'broken' &&
        obj.type === 'file' &&
        obj.mime?.type === firstSelected.mime?.type &&
        ALLOWED_PERMISSIONS.some((permission) => obj.user_permissions.includes(permission))
      );
    })
  );
});

const openElement = createSelector([getOptionsToShow, getSelectedData], (show, selected) => {
  if (!show || !show.openElement) {
    return false;
  }

  if (selected.length !== 1) {
    return false;
  }

  const allowedElements = ['document', 'dopdf', 'presentation'];

  return selected.some((obj) => {
    return (
      obj.status !== 'broken' && obj.status !== 'processing' && allowedElements.includes(obj.type)
    );
  });
});

const preview = createSelector([getOptionsToShow, getSelectedData], (show, selected) => {
  if (!show || show.preview) {
    return false;
  }

  if (selected.length !== 1) {
    return false;
  }

  const object = selected[0];
  if (object.type !== 'file') {
    return false;
  }

  if (object.mime?.type) {
    switch (object.mime.type) {
      case 'image/png':
      case 'image/jpeg':
      case 'image/jpg':
      case 'application/pdf':
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return true;
    }
  }

  return false;
});

const wopiView = createSelector([getOptionsToShow, getSelectedData], (show, selected) => {
  if (selected.length !== 1) {
    return false;
  }
  const file = selected[0] as doDOC.File;
  const wopiActions = file.wopi?.actions;
  if (wopiActions) {
    return wopiActions.some((action) => action.name === 'view');
  }
  return false;
});

const wopiEdit = createSelector([getOptionsToShow, getSelectedData], (show, selected) => {
  if (selected.length !== 1) {
    return false;
  }
  const file = selected[0] as doDOC.File;
  const wopiActions = file.wopi?.actions;
  if (wopiActions) {
    return wopiActions.some((action) => action.name === 'edit');
  }
  return false;
});

const selectAvailableActions = createSelector(
  [
    rename,
    remove,
    download,
    downloadOriginalFile,
    move,
    copy,
    checkOut,
    update,
    checkIn,
    cancelCheckOut,
    copyLink,
    changeStatus,
    exportDocumentTo,
    convertTo,
    openElement,
    preview,
    wopiView,
    wopiEdit,
  ],
  (
    rename,
    remove,
    download,
    downloadOriginalFile,
    move,
    copy,
    checkOut,
    update,
    checkIn,
    cancelCheckOut,
    copyLink,
    changeStatus,
    exportDocumentTo,
    convertTo,
    openElement,
    preview,
    wopiView,
    wopiEdit,
  ) =>
    ({
      rename,
      remove,
      download,
      downloadOriginalFile,
      move,
      copy,
      checkOut,
      update,
      checkIn,
      cancelCheckOut,
      copyLink,
      changeStatus,
      exportDocumentTo,
      convertTo,
      openElement,
      preview,
      wopiView,
      wopiEdit,
    } as ActionsToShow),
);

export default selectAvailableActions;
