import { createContext, memo, ReactNode, useContext } from 'react';
import usePosition from '../usePosition';

type GroupContextValue = {
  position: Presentation.Data.Common.Position;
};

const GroupContext = createContext<GroupContextValue | undefined>(undefined);

type GroupDataProps = {
  children: ReactNode;
  shape: Presentation.Data.GroupShape;
};

const GroupDataProvider = ({ children, shape }: GroupDataProps) => {
  const position = usePosition(shape);
  return <GroupContext.Provider value={{ position }}>{children}</GroupContext.Provider>;
};

export const useGroupData = () => {
  return useContext(GroupContext);
};

export default memo(GroupDataProvider);
