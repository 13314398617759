import { DOMUtils } from '_common/utils';

export type ObjectHighlightRect<T> = {
  object: T;
  rect: Rect;
};

export const getHighlightRects = <T extends any>({
  object,
  anchor,
  zoom,
  negativeOffset,
}: {
  object: T;
  anchor: Presentation.Common.PresentationAnchor;
  zoom: number;
  negativeOffset?: Rect;
}) => {
  const highlights: ObjectHighlightRect<T>[] = [];
  const location = anchor?.[2];

  if (!location) {
    return highlights;
  }
  const range = document.createRange();
  const start = document.getElementById(location.start.b);
  const end = document.getElementById(location.end.b);

  if (!start || !end) {
    return highlights;
  }

  const startIndex = location.start.p[location.start.p.length - 3];
  const startOffset = location.start.p[location.start.p.length - 1];
  const endIndex = location.end.p[location.end.p.length - 3];
  const endOffset = location.end.p[location.end.p.length - 1];

  if (
    typeof startIndex !== 'number' ||
    typeof startOffset !== 'number' ||
    typeof endIndex !== 'number' ||
    typeof endOffset !== 'number'
  ) {
    return highlights;
  }

  const startNodes = Array.from(start.childNodes).filter(
    (child) => (child as HTMLElement).getAttribute('data-type') === 'text',
  );
  const endNodes = Array.from(end.childNodes).filter(
    (child) => (child as HTMLElement).getAttribute('data-type') === 'text',
  );

  const startNode = startNodes[startIndex] as HTMLElement | undefined;
  const endNode = endNodes[endIndex] as HTMLElement | undefined;

  if (startNode?.firstChild && endNode?.firstChild) {
    range.setStart(startNode.firstChild, startOffset);
    range.setEnd(endNode.firstChild, endOffset);

    const rects = DOMUtils.filterAndAdjustDOMRectList(range.getClientRects());

    rects.forEach((rect) => {
      highlights.push({
        object,
        rect: {
          ...rect,
          top: (rect.top - (negativeOffset?.top ?? 0)) / zoom,
          width: rect.width / zoom,
          height: rect.height / zoom,
          left: (rect.left - (negativeOffset?.left ?? 0)) / zoom,
        },
      });
    });
  }

  range.collapse();

  return highlights;
};
