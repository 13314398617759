import { merge } from 'lodash';
import cx from 'classnames';
import { TEXT_ALIGN_MAP } from 'Presentation/consts';
import { ReactNode, useMemo, useState } from 'react';
import { useSlideData } from 'Presentation/Slides/Slide/SlideData';
import { useDebounce, useSelector } from '_common/hooks';
import { useSlideId } from 'Presentation/hooks';
import { HighlightMarker } from '_common/suite/components';
import {
  ObjectHighlightRect,
  getHighlightRects,
} from 'Presentation/Slides/Slide/InlineCards/utils';

import styles from './TextParagraph.module.scss';
import { useShapeData } from '../../ShapeData';
import { cloneObject } from 'Presentation/utils';

const useListStyle = (
  listStyles: Presentation.Data.TextBody['listStyle'] | undefined,
  paragraph: Presentation.Data.ParagraphShape,
  fontScale: number,
) => {
  const { color } = useSlideData();

  let paragraphProperties: Presentation.Data.ParagraphProperties | undefined = undefined;
  if (listStyles) {
    if (listStyles.defPr) {
      paragraphProperties = cloneObject(listStyles.defPr);
    } else if (paragraph.properties.lvl) {
      const lvl = `${paragraph.properties.lvl + 1}`;
      if (lvl in listStyles) {
        paragraphProperties = cloneObject(listStyles[lvl]);
      }
    } else if (listStyles['1']) {
      paragraphProperties = cloneObject(listStyles['1']);
    }
  }

  paragraphProperties = merge(paragraphProperties ?? {}, paragraph.properties);

  if (paragraphProperties) {
    const isHanging = paragraphProperties.indent != null && paragraphProperties.indent <= 0;
    const fill = paragraphProperties.inlineProperties?.fill;
    return {
      textAlign: paragraphProperties.algn ? TEXT_ALIGN_MAP[paragraphProperties.algn] : undefined,
      lineHeight: paragraphProperties.lnSpc,
      marginTop: paragraphProperties.spcBef,
      marginBottom: paragraphProperties.spcAft,
      paddingLeft:
        paragraphProperties.marL != null
          ? paragraphProperties.indent != null
            ? isHanging
              ? Math.max(Math.abs(paragraphProperties.indent), paragraphProperties.marL) //hanging
              : paragraphProperties.marL //first line
            : paragraphProperties.marL
          : undefined,
      marginRight: paragraphProperties.marR,
      textIndent: isHanging ? paragraphProperties.indent : undefined,
      fontSize: paragraphProperties.inlineProperties?.size
        ? paragraphProperties.inlineProperties?.size * fontScale
        : undefined,
      color: fill && fill.type === 'solid' ? color(fill.color) : undefined,
    };
  }
};

type TextParagraphProps = {
  children: ReactNode;
  listStyles: Presentation.Data.TextBody['listStyle'];
  paragraph: Presentation.Data.ParagraphShape;
  fontScale: number;
};

const TextParagraph = ({ children, listStyles, paragraph, fontScale }: TextParagraphProps) => {
  const style = useListStyle(listStyles, paragraph, fontScale);
  const { shape } = useShapeData();

  const { isThumbnailSlide } = useSlideData();
  const currentSlideId = useSlideId();
  const findInstances = useSelector((state) => state.presentation.find.instances);
  const selectedInstance = useSelector((state) => state.presentation.find.selectedInstance);
  const zoom = useSelector((state) => state.presentation.general.zoom);
  const selectedShape = useSelector((state) => state.presentation.general.selectedShape);

  const [shapesRendered, setShapesRendered] = useState<boolean>(false);
  /**
   * When zoom changes UI will rerender
   * Debouncing zoom value and using it on the useMemo below will make sure we are getting the updated elements rects
   */
  const debouncedZoom = useDebounce(zoom, 0);

  const paragraphRect = useMemo(() => {
    if (!shapesRendered) {
      setTimeout(() => setShapesRendered(true), 0);
      return undefined;
    }

    const paragraphNode = document.getElementById(paragraph.id);
    if (!paragraphNode) {
      return undefined;
    }
    return paragraphNode.getBoundingClientRect();
  }, [shapesRendered, zoom]);

  const findHighlights = useMemo<ObjectHighlightRect<number>[] | undefined>(() => {
    if (!shapesRendered) {
      setTimeout(() => setShapesRendered(true), 0);
      return undefined;
    }

    if (isThumbnailSlide || !paragraphRect) {
      return undefined;
    }

    return findInstances
      ? findInstances
          .filter(
            (instance) =>
              instance.location[0].id === currentSlideId &&
              instance.location[2]?.start.b === paragraph.id,
          )
          .reduce<ObjectHighlightRect<number>[]>((highlights, instance) => {
            highlights.push(
              ...getHighlightRects({
                object: instance.id,
                anchor: instance.location,
                negativeOffset: paragraphRect,
                zoom,
              }),
            );
            return highlights;
          }, [])
      : [];
  }, [shapesRendered, findInstances, currentSlideId, debouncedZoom, isThumbnailSlide]);

  return (
    <div
      style={{ ...style, position: 'relative' }}
      className={cx(styles.root, { [styles.selected]: selectedShape === shape.id })}
      id={isThumbnailSlide ? `thumbnail-${paragraph.id}` : paragraph.id}
      data-type="p"
    >
      {children}
      {findHighlights?.map(({ object, rect }, i) => {
        return (
          <HighlightMarker
            key={i}
            isActive={object === selectedInstance}
            defaultBackground="rgba(233, 241, 254)"
            activeBackground="rgba(189, 212, 252, 0.8)"
            rect={rect}
          />
        );
      })}
    </div>
  );
};

export default TextParagraph;
