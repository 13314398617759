import { FC } from 'react';
import CommonShape from './CommonShape';
import GroupShape from './Group/GroupShape';
import TableShape from './Table/TableShape';
import ChartShape from './Chart/ChartShape';

type ShapeProps = {
  shape: Presentation.Data.Shape;
};

const Shape: FC<ShapeProps> = ({ shape }) => {
  switch (shape.type) {
    case 'group_shape':
      return <GroupShape key={shape.id} shape={shape} />;
    case 'table':
      return <TableShape key={shape.id} shape={shape} />;
    //@ts-expect-error this isn't supported yet
    case 'chartex':
    case 'chart':
      return <ChartShape key={shape.id} shape={shape} />;
    case 'shape':
    default:
      return <CommonShape key={shape.id} shape={shape} />;
  }
};

export default Shape;
