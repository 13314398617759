import { useSlideData } from 'Presentation/Slides/Slide/SlideData';
import { parseOutline } from '../../useOutline';
import useChartColor from './useChartColor';

const useChartOutline = () => {
  const { color } = useSlideData();
  const { chartColor } = useChartColor();

  const chartOutline = (ln: Presentation.Data.Outline | undefined) => {
    if (!ln) {
      return undefined;
    }
    const shapeOutline = parseOutline(ln, color);

    return {
      borderType:
        shapeOutline.strokeDasharray !== 'none'
          ? shapeOutline.strokeDasharray?.split(',').map((value) => +value)
          : undefined,
      borderWidth: shapeOutline.strokeWidth,
      borderColor: chartColor(ln.fill),
      borderCap: shapeOutline.strokeLinecap,
      borderJoin: shapeOutline.strokeLinejoin,
      borderMiterLimit: shapeOutline.strokeMiterlimit,
    };
  };
  return { chartOutline };
};

export default useChartOutline;
