import GradientBackground from './GradientBackground';
import PatternBackground from './PatternBackground';
import PictureBackground from './PictureBackground';
import SolidBackground from './SolidBackground';

type BackgroundProps = {
  fill?: Presentation.Data.Slide.Background['fill'];
  size: Presentation.Data.Common.Size;
  position: Presentation.Data.Common.Position;
};

const Background = ({ fill, size, position }: BackgroundProps) => {
  if (fill) {
    switch (fill?.type) {
      case 'solid':
        return <SolidBackground background={fill.color} size={size} position={position} />;
      case 'gradient':
        return <GradientBackground background={fill} size={size} />;
      case 'pattern':
        return <PatternBackground background={fill} size={size} />;
      case 'picture':
        return <PictureBackground background={fill} size={size} position={position} />;
    }
  }
  return null;
};

export default Background;
