import { MouseEventHandler } from 'react';

import { useDispatch, useSelector } from '_common/hooks';
import { setSelectedShape } from 'Presentation/PresentationSlice';

import TextBody from './TextBody/TextBody';
import ShapeDataProvider from './ShapeData';
import ShapeBackground from './ShapeBackground';
import ShapeForeignObject from './ShapeForeignObject';
import { usePresentationManager } from 'Presentation/PresentationManager';

type ShapeProps = {
  shape: Presentation.Data.Shape;
};

const CommonShape = ({ shape }: ShapeProps) => {
  const dispatch = useDispatch();
  const manager = usePresentationManager();

  const selectedShape = useSelector((state) => state.presentation.general.selectedShape);

  const handleSelectShape: MouseEventHandler<SVGGElement> = (e) => {
    e.stopPropagation();
    if (selectedShape !== shape.id) {
      manager.clearSelection();
      dispatch(setSelectedShape(shape.id));
    }
  };

  return (
    <ShapeDataProvider shape={shape}>
      <g id={shape.id} data-type="shape" onMouseDown={handleSelectShape}>
        <ShapeBackground />
        {shape.text && (
          <ShapeForeignObject>
            <TextBody text={shape.text} />
          </ShapeForeignObject>
        )}
      </g>
    </ShapeDataProvider>
  );
};

export default CommonShape;
