import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useIntl } from 'react-intl';
import { OnboardingIntegrationCards, Pulse, Box } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { dayjs } from 'utils';
import {
  selectPDFZoom,
  setCursorMode,
  setLeftPanel,
  setRightPanel,
  setZoomValue,
} from 'PDF/redux/PDFGeneralSlice';

import { OnboardingOverlay, WelcomeScreen } from '_common/components';

import {
  PDFActions,
  PDFInteractions,
  activateOnboarding,
  completeActionList,
  resetCompletedActions,
  setCurrentDocument,
  setInteractions,
  setPulseData,
  stopOnboarding,
} from 'App/redux/onboardingSlice';
import usePDFData from 'PDF/hooks/usePDFData';
import {
  OnBoardingCarBoxProps,
  OnboardingHandler,
  OnboardingIntegrationCardsProps,
} from 'dodoc-design-system/build/types/Components/OnboardingIntegrationCards/OnboardingIntegrationCards';
import { useOnboardingStatusMutation } from 'App/redux/onboardingApi';
import { usePDFContext } from 'PDF/PDFContext';
import {
  selectAllPDFAnnotations,
  selectAnnotation,
  selectPDFTasks,
  setCreating,
  setEditingAnnotation,
} from 'PDF/redux/PDFAnnotationsSlice';
import { GHOST_USERS } from '_common/services/api/publicProfilesApi';
import { getBoxFromAnnotation } from 'PDF/Content/AnnotationsLayer/Annotation/Utils';
import { useGetCurrentUserQuery } from '_common/services/api/authority';

type VisibleSpace = OnboardingIntegrationCardsProps['cards'][number]['visibleSpace'];

type Position = {
  top?: string;
  bottom?: string;
  right?: string;
  left?: string;
};

type Rect = {
  top: number;
  left: number;
  height: number;
  width: number;
};

type AnnotationCardId =
  | 'annotation_location'
  | 'annotation_description'
  | 'annotation_create'
  | 'annotation_commentAnnotation'
  | 'annotation_save'
  | 'annotation_highlight_location'
  | 'annotation_highlight_description'
  | 'annotation_representation'
  | 'annotation_completed';

type CommentCardId =
  | 'comment_description'
  | 'comment_create'
  | 'comment_save'
  | 'comment_replyMention'
  | 'comment_representation'
  | 'comment_completed';

type TaskCardId =
  | 'task_description'
  | 'task_create'
  | 'task_fillTask'
  | 'task_representation'
  | 'task_completed';

type CardId = 'beginning' | AnnotationCardId | CommentCardId | TaskCardId | 'ending';

type PulseTarget = (
  | 'annotation'
  | 'contextMenu_task'
  | 'annotationCard'
  | 'commentReply'
  | 'commentCard'
  | 'taskDescription'
  | 'taskAssigned'
  | 'taskDueDate'
  | 'toolbar_highlight'
  | 'toolbar_comment'
  | 'toolbar_task'
  | 'document_highlight'
  | 'document_comment'
  | 'document_task'
)[];

type BoxTarget = (
  | 'toolbar'
  | 'toolbar_annotations'
  | 'toolbar_highlight'
  | 'toolbar_comment'
  | 'toolbar_task'
)[];

type ExtendedCard<Id extends CardId = CardId> = OnboardingIntegrationCardsProps['cards'][number] & {
  id: Id;
  deps?: PDFActions[];
  interactions?: PDFInteractions[];
  pulseTarget?: PulseTarget;
  boxTarget?: BoxTarget;
  //Action to be executed when a refresh is made and this card is skipped
  autoAction?: () => void;
  //Action to be executed as soon as this card is rendered
  onRender?: () => void;
};

type Journey = 'reviewerUser' | 'generalUser';

const OnboardingPDF = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const onboardingRef = useRef<OnboardingHandler>(null);

  const pdfManager = usePDFContext();
  const currentPdf = usePDFData();

  const [sendOnboardingStatus] = useOnboardingStatusMutation();
  const { isSuccess: userLoaded } = useGetCurrentUserQuery();

  const initialPhase = useSelector((state) => state.onboarding.initialPhase.dopdf);
  const initialJourney = useSelector((state) => state.onboarding.initialJourney.dopdf);
  const originalDoc = useSelector((state) => state.onboarding.currentDocument.dopdf);
  const actionsCompleted = useSelector((state) => state.onboarding.actionsCompleted);
  const active = useSelector((state) => state.onboarding.active.dopdf);
  const started = useSelector((state) => state.onboarding.started.dopdf);
  const pulseData = useSelector((state) => state.onboarding.pulseData);
  const cursorMode = useSelector((state) => state.pdf.general.cursorMode);
  const zoom = useSelector(selectPDFZoom);
  const annotations = useSelector(selectAllPDFAnnotations);
  const tasks = useSelector(selectPDFTasks);

  const [createdAnnotations, setCreatedAnnotations] =
    useState<{ [I in CardId]?: PDF.Annotation.Base['id'] }>();
  const [initialCardIndex, setInitialCardIndex] = useState<number>();

  const [activeCard, setActiveCard] = useState(initialCardIndex ?? 0);
  const [skipping, setSkipping] = useState(false);
  const [currentJourney, setCurrentJourney] = useState<Journey | undefined>(initialJourney);
  const [pulsePosition, setPulsePosition] = useState<{
    position: Position;
    boundary: HTMLElement | null | undefined;
  }>();
  const [boxPosition, setBoxPosition] = useState<{
    position: OnBoardingCarBoxProps;
    boundary: HTMLElement | null;
  }>();

  const pollInterval = useRef<NodeJS.Timer | null>(null);

  const visibleSpace: Record<string, NonNullable<VisibleSpace>[number]> = useMemo(
    () => ({
      toolbar: {
        top: '63px',
        height: `48px`,
        left: '0px',
        width: '100%',
      },
      all: {
        top: '0px',
        height: '100%',
        left: '0px',
        width: '100%',
      },
    }),
    [],
  );

  const onboardingIsReady = useMemo(() => {
    return (started || active) && (currentPdf?.id !== originalDoc?.id || originalDoc?.isOnboarding);
  }, [started, active, currentPdf, originalDoc]);

  const skipProps = useMemo(() => {
    return {
      skipPreviousLabel: intl.formatMessage({ id: 'NO_CONTINUE_ONBOARDING' }),
      skipFinishLabel: intl.formatMessage({ id: 'YES_SKIP' }),
      skipHeader: intl.formatMessage({ id: 'SKIP_THE_ONBOARDING_QUESTION' }),
      skipContent: intl.formatMessage({ id: 'ONBOARDING_PDF_SKIP_CONFIRMATION' }),
    };
  }, [intl]);

  const pollElementById = (
    elementId: string,
    elementFoundCallback: (element: HTMLElement) => void,
    interval: number = 100,
  ) => {
    if (pollInterval.current) {
      clearInterval(pollInterval.current);
    }

    pollInterval.current = setInterval(() => {
      const element = document.getElementById(elementId);
      if (element) {
        elementFoundCallback(element);
        clearInterval(pollInterval.current!);
      }
    }, interval);
  };

  const openAnnotationReplyList = (annotationId: PDF.Annotation.Base['id']) => {
    setTimeout(() => {
      pollElementById(`annotation-${annotationId}-replyToggle`, (replyToggle) => {
        if (replyToggle) {
          const toggleState = replyToggle.getAttribute('data-value');
          if (!toggleState || toggleState === 'false') {
            replyToggle.click();
          }
        }
      });
    }, 0);
  };

  const cards = useMemo<ExtendedCard[]>(() => {
    const initialCards: ExtendedCard[] = [
      {
        id: 'beginning',
        title: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' }),
        description: intl.formatMessage({ id: 'ONBOARDING_STANDALONE_PDF_STARTED' }),
        progress: 2.33,
        currentPhase: 0,
        interactions: ['pdf_allow_zoom'],
      },
    ];
    const endingCards: ExtendedCard[] = [
      {
        id: 'ending',
        title: intl.formatMessage({ id: 'AND_THATS_JUST_THE_BEGINNING' }),
        description: intl.formatMessage({ id: 'ONBOARDING_PDF_COMPLETED' }, { br: <br /> }),
        showPhases: false,
        progress: 100,
        currentPhase: 0,
        nextLabel: intl.formatMessage({ id: 'GO_TO_PDF' }),
        interactions: ['pdf_allow_zoom'],
      },
    ];

    const generateAnnotationsCards = () => {
      if (currentJourney) {
        const journeys: Record<
          Journey,
          {
            [I in AnnotationCardId]?: {
              description: TranslationMessage;
              phaseTitle?: TranslationMessage;
              pulseTarget?: PulseTarget;
            };
          }
        > = {
          reviewerUser: {
            annotation_location: {
              description: { id: 'ONBOARDING_PDF_ANNOTATIONS_LOCATION' },
            },
            annotation_description: {
              description: {
                id: 'ONBOARDING_PDF_ANNOTATIONS_DESCRIPTION',
              },
            },
            annotation_create: {
              description: {
                id: 'ONBOARDING_PDF_ANNOTATIONS_CREATE_ANNOTATION',
              },
            },
            annotation_commentAnnotation: {
              description: {
                id: 'ONBOARDING_PDF_ANNOTATIONS_COMMENT_ANNOTATION',
              },
            },
            annotation_save: {
              description: {
                id: 'TO_FINISH_SELECT_SAVE',
              },
            },
            annotation_completed: {
              description: {
                id: 'ONBOARDING_PDF_ANNOTATIONS_COMPLETED',
              },
              phaseTitle: {
                id: 'EDIT_ANNOTATION',
              },
            },
          },
          generalUser: {
            annotation_location: {
              description: { id: 'ONBOARDING_STANDALONE_PDF_ANNOTATIONS_LOCATION_GENERAL' },
            },
            annotation_description: {
              description: {
                id: 'ONBOARDING_STANDALONE_PDF_ANNOTATIONS_DESCRIPTION_GENERAL',
              },
            },
            annotation_highlight_location: {
              description: {
                id: 'ONBOARDING_STANDALONE_PDF_ANNOTATIONS_HIGHLIGHT_LOCATION',
              },
            },
            annotation_highlight_description: {
              description: {
                id: 'ONBOARDING_STANDALONE_PDF_ANNOTATIONS_HIGHLIGHT_DESCRIPTION',
              },
            },
            annotation_representation: {
              description: {
                id: 'ONBOARDING_STANDALONE_PDF_ANNOTATIONS_REPRESENTATION',
              },
            },
            annotation_completed: {
              description: {
                id: 'ONBOARDING_STANDALONE_PDF_ANNOTAITONS_COMPLETED_GENERAL',
              },
              phaseTitle: {
                id: 'VISUALIZE_ANNOTATION_REPRESENTATION',
              },
            },
          },
        };

        const cards: ExtendedCard<AnnotationCardId>[] = [
          {
            id: 'annotation_location',
            description: intl.formatMessage(
              {
                id: journeys[currentJourney]['annotation_location']?.description.id ?? ' ',
              },
              journeys[currentJourney]['annotation_location']?.description.values,
            ),
            progress: 2.33,
            currentPhase: 1,
            boxTarget: ['toolbar'],
            visibleSpace: [visibleSpace.toolbar],
            interactions: ['pdf_allow_zoom', 'pdf_toggles_layer'],
          },
          {
            id: 'annotation_description',
            boxTarget: ['toolbar_annotations'],
            visibleSpace: [visibleSpace.toolbar],
            description: intl.formatMessage(
              {
                id: journeys[currentJourney]['annotation_description']?.description.id ?? ' ',
              },
              journeys[currentJourney]['annotation_description']?.description.values,
            ),
            progress: 2.33,
            currentPhase: 1,
            interactions: ['pdf_allow_zoom', 'pdf_toggles_layer'],
          },
          {
            id: 'annotation_create',
            visibleSpace: [visibleSpace.all],
            title: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' }),
            description: intl.formatMessage(
              {
                id: journeys[currentJourney]['annotation_create']?.description.id ?? ' ',
              },
              journeys[currentJourney]['annotation_create']?.description.values,
            ),
            tipValue: intl.formatMessage({
              id: 'ONBOARDING_PDF_ANNOTATIONS_CREATE_ANNOTATION_TIP',
            }),
            showPhases: false,
            progress: 2.33,
            currentPhase: 1,
            subPhase: 1,
            phaseTitle: intl.formatMessage({ id: 'CREATE_ANNOTATION' }),
            deps: ['pdf_annotations_highlight'],
            pulseTarget: ['toolbar_highlight', 'document_highlight'],
            interactions: [
              'pdf_allow_zoom',
              'pdf_toolbar_highlight',
              'pdf_text_layer',
              'pdf_toggles_layer',
            ],
          },
          {
            id: 'annotation_commentAnnotation',
            visibleSpace: [visibleSpace.all],
            title: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' }),
            description: intl.formatMessage(
              {
                id: journeys[currentJourney]['annotation_commentAnnotation']?.description.id ?? ' ',
              },
              journeys[currentJourney]['annotation_commentAnnotation']?.description.values,
            ),
            tipValue: intl.formatMessage({
              id: 'ONBOARDING_PDF_ANNOTATIONS_COMMENT_ANNOTATION_TIP',
            }),
            showPhases: false,
            progress: 18.6,
            currentPhase: 1,
            subPhase: 2,
            phaseTitle: intl.formatMessage({ id: 'EDIT_ANNOTATION' }),
            deps: ['pdf_annotations_openAnnotation'],
            pulseTarget: ['annotation', 'contextMenu_task'],
            interactions: [
              'pdf_allow_zoom',
              'pdf_annotation_edit',
              'pdf_text_layer',
              'pdf_toggles_layer',
            ],
          },
          {
            id: 'annotation_save',
            visibleSpace: [visibleSpace.all],
            title: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' }),
            description: intl.formatMessage(
              {
                id: journeys[currentJourney]['annotation_save']?.description.id ?? ' ',
              },
              journeys[currentJourney]['annotation_save']?.description.values,
            ),
            tipValue: intl.formatMessage({
              id: 'ONBOARDING_PDF_ANNOTATIONS_COMMENT_ANNOTATION_TIP',
            }),
            showPhases: false,
            progress: 18.6,
            currentPhase: 1,
            subPhase: 2,
            phaseTitle: intl.formatMessage({ id: 'EDIT_ANNOTATION' }),
            deps: ['pdf_annotations_commentHighlight'],
            pulseTarget: ['annotationCard'],
            interactions: ['pdf_allow_zoom', 'pdf_annotation_save', 'pdf_toggles_layer'],
          },
          {
            id: 'annotation_highlight_location',
            visibleSpace: [visibleSpace.all],
            title: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' }),
            description: intl.formatMessage(
              {
                id:
                  journeys[currentJourney]['annotation_highlight_location']?.description.id ?? ' ',
              },
              journeys[currentJourney]['annotation_highlight_location']?.description.values,
            ),
            boxTarget: ['toolbar_highlight'],
            showPhases: false,
            progress: 2.33,
            currentPhase: 1,
            subPhase: 1,
            phaseTitle: intl.formatMessage({ id: 'UNDERSTANDING_ANNOTATIONS' }),
            interactions: ['pdf_allow_zoom'],
          },
          {
            id: 'annotation_highlight_description',
            visibleSpace: [visibleSpace.all],
            title: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' }),
            description: intl.formatMessage(
              {
                id:
                  journeys[currentJourney]['annotation_highlight_description']?.description.id ??
                  ' ',
              },
              journeys[currentJourney]['annotation_highlight_description']?.description.values,
            ),
            boxTarget: ['toolbar_highlight'],
            showPhases: false,
            progress: 2.33,
            currentPhase: 1,
            subPhase: 1,
            phaseTitle: intl.formatMessage({ id: 'UNDERSTANDING_ANNOTATIONS' }),
            interactions: ['pdf_allow_zoom'],
            onRender: () => {
              dispatch(setCursorMode('Highlight'));
            },
          },
          {
            id: 'annotation_representation',
            visibleSpace: [visibleSpace.all],
            title: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' }),
            description: intl.formatMessage(
              {
                id: journeys[currentJourney]['annotation_representation']?.description.id ?? ' ',
              },
              journeys[currentJourney]['annotation_representation']?.description.values,
            ),
            showPhases: false,
            progress: 18.6,
            currentPhase: 1,
            subPhase: 2,
            phaseTitle: intl.formatMessage({ id: 'VISUALIZE_ANNOTATION_REPRESENTATION' }),
            interactions: ['pdf_allow_zoom'],
            onRender: () => {
              if (!createdAnnotations?.annotation_representation) {
                const textElements = document.querySelectorAll('[data-elementtype="text"]');

                if (textElements) {
                  const element = textElements[65];

                  if (element) {
                    const range = new Range();
                    const selection = window.getSelection();

                    if (range && selection) {
                      range.setStartBefore(element);
                      range.setEndAfter(element);
                      selection.removeAllRanges();
                      selection.addRange(range);

                      const id = pdfManager.createTextMarkupAnnotation('Highlight', [
                        {
                          type: 'paragraph',
                          children: [
                            {
                              text: 'This is an example of a comment attached to a annotation.',
                            },
                          ],
                        },
                      ]);

                      if (id) {
                        dispatch(selectAnnotation(id));
                        setCreatedAnnotations({
                          ...createdAnnotations,
                          annotation_representation: id,
                        });
                      }

                      range?.collapse();
                    }
                  }
                }
              } else {
                dispatch(selectAnnotation(createdAnnotations.annotation_representation));
              }
            },
          },
          {
            id: 'annotation_completed',
            title: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' }),
            description: intl.formatMessage(
              {
                id: journeys[currentJourney]['annotation_completed']?.description.id ?? ' ',
              },
              journeys[currentJourney]['annotation_completed']?.description.values,
            ),
            showPhases: true,
            progress: 33,
            currentPhase: 1,
            subPhase: 2,
            phaseTitle: intl.formatMessage(
              {
                id: journeys[currentJourney]['annotation_completed']?.phaseTitle?.id ?? ' ',
              },
              journeys[currentJourney]['annotation_completed']?.phaseTitle?.values,
            ) as string,
            phaseCompleted: 1,
            interactions: ['pdf_allow_zoom'],
            onRender: () => {
              if (createdAnnotations?.annotation_representation) {
                dispatch(selectAnnotation(createdAnnotations.annotation_representation));
              }
            },
            autoAction: () => {
              if (createdAnnotations?.annotation_create) {
                dispatch(selectAnnotation(createdAnnotations.annotation_create));
              }
            },
          },
        ];

        return cards.filter((card) => {
          return Object.typedKeys(journeys[currentJourney]).find((cardId) => {
            return card.id === cardId;
          });
        });
      }
    };

    const generateCommentsCards = () => {
      if (currentJourney) {
        const journeys: Record<
          Journey,
          {
            [Id in CommentCardId]?: {
              description: TranslationMessage;
              phaseTitle?: TranslationMessage;
              subPhase?: number;
            };
          }
        > = {
          reviewerUser: {
            comment_description: {
              description: { id: 'ONBOARDING_PDF_COMMENTS_DESCRIPTION', values: { br: <br /> } },
            },
            comment_create: {
              description: {
                id: 'ONBOARDING_PDF_COMMENTS_CREATE_COMMENT',
              },
            },
            comment_save: {
              description: {
                id: 'ONBOARDING_STANDALONE_PDF_COMMENTS_SAVE',
              },
            },
            comment_replyMention: {
              description: {
                id: 'ONBOARDING_PDF_COMMENTS_REPLY_COMMENT',
              },
            },
            comment_completed: {
              description: {
                id: 'ONBOARDING_PDF_COMMENTS_COMPLETED',
              },
              phaseTitle: {
                id: 'ADD_A_REPLY',
              },
              subPhase: 2,
            },
          },
          generalUser: {
            comment_description: {
              description: { id: 'ONBOARDING_STANDALONE_PDF_COMMENTS_DESCRIPTION_GENERAL' },
            },
            comment_representation: {
              description: {
                id: 'ONBOARDING_STANDALONE_PDF_COMMENTS_REPRESENTATION',
              },
            },
            comment_completed: {
              description: {
                id: 'ONBOARDING_PDF_COMMENTS_COMPLETED',
              },
              phaseTitle: {
                id: 'VISUALIZE_COMMENT_REPRESENTATION',
              },
              subPhase: 1,
            },
          },
        };

        const cards: ExtendedCard<CommentCardId>[] = [
          {
            id: 'comment_description',
            visibleSpace: [visibleSpace.toolbar],
            boxTarget: ['toolbar_comment'],
            title: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' }),
            description: intl.formatMessage(
              {
                id: journeys[currentJourney]['comment_description']?.description.id ?? ' ',
              },
              journeys[currentJourney]['comment_description']?.description.values,
            ),
            showPhases: true,
            progress: 36.6,
            currentPhase: 2,
            interactions: ['pdf_allow_zoom', 'pdf_toggles_layer'],
            onRender: () => {
              // dispatch(clearSelection());
            },
            autoAction: () => {
              const id = createdAnnotations?.comment_create;
              if (id) {
                dispatch(selectAnnotation(id));
              }
            },
          },
          {
            id: 'comment_create',
            visibleSpace: [visibleSpace.all],
            title: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' }),
            description: intl.formatMessage(
              {
                id: journeys[currentJourney]['comment_create']?.description.id ?? ' ',
              },
              journeys[currentJourney]['comment_create']?.description.values,
            ),
            showPhases: false,
            phaseTitle: intl.formatMessage({ id: 'CREATE_COMMENT' }),
            progress: 36.6,
            currentPhase: 2,
            subPhase: 1,
            deps: ['pdf_comments_openComment'],
            pulseTarget: ['document_comment', 'toolbar_comment'],
            interactions: ['pdf_allow_zoom', 'pdf_toolbar_comment', 'pdf_toggles_layer'],
          },
          {
            id: 'comment_save',
            visibleSpace: [visibleSpace.all],
            title: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' }),
            description: intl.formatMessage(
              {
                id: journeys[currentJourney]['comment_save']?.description.id ?? ' ',
              },
              journeys[currentJourney]['comment_save']?.description.values,
            ),
            showPhases: false,
            progress: 36.6,
            currentPhase: 2,
            subPhase: 1,
            phaseTitle: intl.formatMessage({ id: 'CREATE_COMMENT' }),
            deps: ['pdf_comments_createComment'],
            pulseTarget: ['document_comment', 'commentCard'],
            interactions: ['pdf_allow_zoom', 'pdf_annotation_save', 'pdf_toggles_layer'],
          },
          {
            id: 'comment_replyMention',
            visibleSpace: [visibleSpace.all],
            title: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' }),
            description: intl.formatMessage(
              {
                id: journeys[currentJourney]['comment_replyMention']?.description.id ?? ' ',
              },
              journeys[currentJourney]['comment_replyMention']?.description.values,
            ),
            tipValue: intl.formatMessage({ id: 'ONBOARDING_PDF_COMMENTS_REPLY_COMMENT_TIP' }),
            showPhases: false,
            progress: 50,
            currentPhase: 2,
            phaseTitle: intl.formatMessage({ id: 'ADD_A_REPLY' }),
            subPhase: 2,
            deps: ['pdf_comments_mentionInCommentReply'],
            pulseTarget: ['commentReply'],
            interactions: ['pdf_comment_reply', 'pdf_allow_zoom', 'pdf_toggles_layer'],
          },
          {
            id: 'comment_representation',
            visibleSpace: [visibleSpace.all],
            title: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' }),
            description: intl.formatMessage(
              {
                id: journeys[currentJourney]['comment_representation']?.description.id ?? ' ',
              },
              journeys[currentJourney]['comment_representation']?.description.values,
            ),
            showPhases: false,
            progress: 50,
            currentPhase: 2,
            subPhase: 1,
            phaseTitle: intl.formatMessage({ id: 'VISUALIZE_COMMENT_REPRESENTATION' }),
            interactions: ['pdf_allow_zoom'],
            onRender: () => {
              if (!createdAnnotations?.comment_representation) {
                const textElements = document.querySelectorAll('[data-elementtype="text"]');

                if (textElements) {
                  const element = textElements[67];
                  if (element) {
                    const x = element.getBoundingClientRect().x + 7;
                    const y = element.getBoundingClientRect().y;

                    const id = pdfManager.createNoteAnnotation(new DOMRect(x, y, 32, 32), 1, [
                      {
                        type: 'paragraph',
                        children: [
                          {
                            text: 'This is an example of a comment.',
                          },
                        ],
                      },
                    ]);
                    setTimeout(() => {
                      if (id) {
                        dispatch(selectAnnotation(id));

                        dispatch(setPulseData({ commentId: id }));
                        pdfManager.replyToAnnotation(1, id, [
                          {
                            type: 'paragraph',
                            children: [
                              {
                                text: 'This is a reply with a tag ',
                              },
                              {
                                type: 'mention',
                                userId: GHOST_USERS.davidBean.id,
                                children: [
                                  {
                                    text: ' ',
                                  },
                                ],
                              },
                              {
                                text: '',
                              },
                            ],
                          },
                        ]);

                        openAnnotationReplyList(id);

                        setCreatedAnnotations({
                          ...createdAnnotations,
                          comment_representation: id,
                        });
                      }
                    }, 0);
                  }
                }
              } else {
                const id = createdAnnotations.comment_representation;
                dispatch(selectAnnotation(id));
                openAnnotationReplyList(id);
              }
            },
          },
          {
            id: 'comment_completed',
            title: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' }),
            description: intl.formatMessage(
              {
                id: journeys[currentJourney]['comment_completed']?.description.id ?? ' ',
              },
              journeys[currentJourney]['comment_completed']?.description.values,
            ),
            progress: 66.6,
            currentPhase: 2,
            subPhase: journeys[currentJourney]['comment_completed']?.subPhase,
            phaseTitle: intl.formatMessage(
              {
                id: journeys[currentJourney]['comment_completed']?.phaseTitle?.id ?? ' ',
              },
              journeys[currentJourney]['comment_completed']?.phaseTitle?.values,
            ) as string,
            phaseCompleted: 2,
            interactions: ['pdf_allow_zoom'],
            onRender: () => {
              const id = createdAnnotations?.comment_representation;
              if (id) {
                dispatch(selectAnnotation(id));
                openAnnotationReplyList(id);
              }
            },
            autoAction: () => {
              const id = createdAnnotations?.comment_create;
              if (id) {
                dispatch(selectAnnotation(id));
                openAnnotationReplyList(id);
              }
            },
          },
        ];

        return cards.filter((card) => {
          return Object.typedKeys(journeys[currentJourney]).find((cardId) => {
            return card.id === cardId;
          });
        });
      }
    };

    const generateTasksCards = () => {
      if (currentJourney) {
        const journeys: Record<
          Journey,
          {
            [Id in TaskCardId]?: {
              description: TranslationMessage;
              phaseTitle?: TranslationMessage;
              subPhase?: number;
            };
          }
        > = {
          reviewerUser: {
            task_description: {
              description: { id: 'ONBOARDING_PDF_TASKS_DESCRIPTION' },
            },
            task_create: {
              description: {
                id: 'ONBOARDING_PDF_TASKS_CREATE_TASK',
              },
            },
            task_fillTask: {
              description: {
                id: 'ONBOARDING_STANDALONE_PDF_TASKS_FILL',
              },
            },
            task_completed: {
              description: {
                id: 'ONBOARDING_PDF_TASKS_COMPLETED',
              },
              phaseTitle: {
                id: 'CREATE_TASK',
              },
            },
          },
          generalUser: {
            task_description: {
              description: { id: 'ONBOARDING_STANDALONE_PDF_TASKS_DESCRIPTION_GENERAL' },
            },
            task_representation: {
              description: {
                id: 'ONBOARDING_STANDALONE_PDF_TASKS_REPRESENTATION',
              },
            },
            task_completed: {
              description: {
                id: 'ONBOARDING_PDF_STANDLONE_TASKS_COMPLETED_GENERAL',
              },
              phaseTitle: {
                id: 'VISUALIZE_TASK_REPRESENTATION',
              },
            },
          },
        };

        const cards: ExtendedCard<TaskCardId>[] = [
          {
            id: 'task_description',
            visibleSpace: [visibleSpace.toolbar],
            boxTarget: ['toolbar_task'],
            title: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' }),
            description: intl.formatMessage(
              {
                id: journeys[currentJourney]['task_description']?.description.id ?? ' ',
              },
              journeys[currentJourney]['task_description']?.description.values,
            ),
            progress: 69.99,
            currentPhase: 3,
            interactions: ['pdf_allow_zoom', 'pdf_toggles_layer'],
            onRender: () => {
              // dispatch(clearSelection());
            },
            autoAction: () => {
              if (createdAnnotations?.task_representation) {
                dispatch(selectAnnotation(createdAnnotations.task_representation));
              }
            },
          },
          {
            id: 'task_create',
            visibleSpace: [visibleSpace.all],
            title: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' }),
            description: intl.formatMessage(
              {
                id: journeys[currentJourney]['task_create']?.description.id ?? ' ',
              },
              journeys[currentJourney]['task_create']?.description.values,
            ),
            showPhases: false,
            progress: 69.99,
            currentPhase: 3,
            subPhase: 1,
            phaseTitle: intl.formatMessage({ id: 'CREATE_TASK' }),
            deps: ['pdf_tasks_openTask'],
            pulseTarget: ['toolbar_task', 'document_task'],
            interactions: ['pdf_toolbar_task', 'pdf_allow_zoom', 'pdf_toggles_layer'],
          },
          {
            id: 'task_fillTask',
            visibleSpace: [visibleSpace.all],
            title: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' }),
            description: intl.formatMessage(
              {
                id: journeys[currentJourney]['task_fillTask']?.description.id ?? ' ',
              },
              journeys[currentJourney]['task_fillTask']?.description.values,
            ),
            showPhases: false,
            progress: 69.99,
            currentPhase: 3,
            subPhase: 1,
            phaseTitle: intl.formatMessage({ id: 'CREATE_TASK' }),
            deps: ['pdf_tasks_createTask'],
            pulseTarget: ['taskDescription', 'taskAssigned', 'taskDueDate'],
            interactions: ['pdf_task_fill', 'pdf_allow_zoom', 'pdf_toggles_layer'],
          },
          {
            id: 'task_representation',
            visibleSpace: [visibleSpace.all],
            title: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' }),
            description: intl.formatMessage(
              {
                id: journeys[currentJourney]['task_representation']?.description.id ?? ' ',
              },
              journeys[currentJourney]['task_representation']?.description.values,
            ),
            showPhases: false,
            progress: 69.99,
            currentPhase: 3,
            subPhase: 1,
            phaseTitle: intl.formatMessage({ id: 'VISUALIZE_TASK_REPRESENTATION' }),
            interactions: ['pdf_toolbar_task', 'pdf_task_fill', 'pdf_allow_zoom'],
            onRender: () => {
              if (!createdAnnotations?.task_representation) {
                const textElements = document.querySelectorAll('[data-elementtype="text"]');

                if (textElements) {
                  const element = textElements[66];

                  if (element) {
                    const rects = element.getBoundingClientRect();
                    if (rects) {
                      const x = rects.x + rects.width;
                      const y = rects.y;
                      const boundingRect = new DOMRect(x, y, 32, 32);
                      const pageNum = 1;
                      const page = pdfManager.getPageFromNumber(pageNum);

                      if (page && page.dataset.pageNum && page.dataset.viewportScale) {
                        const id = pdfManager.createAnnotation({
                          subtype: 'Task',
                          pageNumber: +page.dataset.pageNum,
                          pageRect: page.getBoundingClientRect(),
                          boundingRect,
                          viewportScale: +page.dataset.viewportScale,
                          isFreestyle: true,
                          content: [
                            {
                              type: 'paragraph',
                              children: [
                                {
                                  text: 'This is a brief description',
                                },
                              ],
                            },
                          ],
                          task: {
                            assignee: GHOST_USERS.davidBean.id,
                            dueDate: dayjs().add(1, 'day').toISOString(),
                            status: 'td',
                            watchers: [],
                          },
                        });

                        if (id) {
                          dispatch(selectAnnotation(id));
                          setCreatedAnnotations({
                            ...createdAnnotations,
                            task_representation: id,
                          });
                        }
                      }
                    }
                  }
                }
              } else {
                dispatch(selectAnnotation(createdAnnotations.task_representation));
              }
            },
          },
          {
            id: 'task_completed',
            title: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' }),
            description: intl.formatMessage(
              {
                id: journeys[currentJourney]['task_completed']?.description.id ?? ' ',
              },
              journeys[currentJourney]['task_completed']?.description.values,
            ),
            progress: 100,
            currentPhase: 3,
            subPhase: 1,
            phaseTitle: intl.formatMessage(
              {
                id: journeys[currentJourney]['task_completed']?.phaseTitle?.id ?? ' ',
              },
              journeys[currentJourney]['task_completed']?.phaseTitle?.values,
            ) as string,
            phaseCompleted: 3,
            interactions: ['pdf_allow_zoom'],
            onRender: () => {
              if (createdAnnotations?.task_representation) {
                dispatch(selectAnnotation(createdAnnotations.task_representation));
              }
            },
          },
        ];

        return cards.filter((card) => {
          return Object.typedKeys(journeys[currentJourney]).find((cardId) => {
            return card.id === cardId;
          });
        });
      }
    };

    return [
      ...initialCards,
      ...(generateAnnotationsCards() ?? []),
      ...(generateCommentsCards() ?? []),
      ...(generateTasksCards() ?? []),
      ...endingCards,
    ];
  }, [currentJourney, createdAnnotations]);

  const getPositionInDocument = useCallback(
    (defaultTop: number, defaultLeft: number) => {
      const defaultHeight = 24 / 1.33;
      const pointerRadius = 12;

      const top = defaultTop * zoom;
      const left = defaultLeft * zoom;
      const height = defaultHeight * zoom;

      return {
        top: `${top + height / 2 - pointerRadius}px`,
        left: `${left}px`,
      };
    },
    [zoom],
  );

  //Auto next
  useEffect(() => {
    const currentCard = cards[activeCard];
    if (currentCard.deps?.length && currentCard.deps.every((dep) => actionsCompleted[dep])) {
      onboardingRef.current?.next();
    }
  }, [actionsCompleted]);

  //Cards actions and interactions
  useEffect(() => {
    dispatch(setInteractions(cards[activeCard].interactions ?? []));

    cards[activeCard].onRender?.();
  }, [activeCard, cards]);

  //Cards' pulses
  useEffect(() => {
    const processPulse = () => {
      if (cards[activeCard]?.pulseTarget) {
        const pointerRadius = 12;
        const pointerDiameter = pointerRadius * 2;

        cards[activeCard].pulseTarget?.forEach(async (target) => {
          switch (target) {
            case 'annotation': {
              const {
                annotationId,
                contextMenuTaskItemRect,
                annotationCardCtaRect,
                annotationCardReplyRect,
              } = pulseData;
              if (annotationId) {
                const annotation = annotations.find((annotation) => annotation.id === annotationId);
                if (annotation) {
                  const pageId = `page_${annotation.pageNumber}`;
                  const pdfPage = await pdfManager.getPage(annotation.pageNumber);
                  const viewport = pdfPage?.getViewport({ scale: zoom });
                  if (viewport) {
                    if (
                      !contextMenuTaskItemRect &&
                      !annotationCardCtaRect &&
                      !annotationCardReplyRect
                    ) {
                      if (!actionsCompleted.pdf_annotations_commentHighlight) {
                        const box = getBoxFromAnnotation(annotation, viewport.scale);

                        setPulsePosition({
                          position: {
                            bottom: `${Math.ceil(box.bottom)}px`,
                            left: `${Math.ceil(box.left + box.width / 2)}px`,
                          },
                          boundary: document.getElementById(pageId),
                        });
                      } else {
                        setPulsePosition(undefined);
                      }
                    }
                  }
                }
              }
              break;
            }
            case 'contextMenu_task': {
              pollElementById('AnnotationContextMenu', (contextMenu) => {
                const editAnnotationItem = document.getElementById('EditAnnotationItem');

                if (editAnnotationItem) {
                  const rect = {
                    top: editAnnotationItem.offsetTop,
                    left: editAnnotationItem.offsetLeft,
                    height: editAnnotationItem.offsetHeight,
                    width: editAnnotationItem.offsetWidth,
                  };

                  setPulsePosition({
                    position: {
                      top: `${Math.ceil(rect.top + rect.height / 2 - 12)}px`,
                      left: `${Math.ceil(rect.left)}px`,
                    },
                    boundary: contextMenu,
                  });
                }
              });

              break;
            }
            case 'annotationCard':
            case 'commentCard': {
              const { annotationId, commentId } = pulseData;

              let id = annotationId;
              if (target === 'commentCard') {
                id = commentId;
              }

              const cardElement = document.getElementById(`annotation-card-${id}`);
              const ctaButton = document.getElementById(`annotation-${id}-cta-button`);

              if (cardElement && ctaButton) {
                const rects = {
                  top: ctaButton.offsetTop,
                  left: ctaButton.offsetLeft,
                  height: ctaButton.offsetHeight,
                  width: ctaButton.offsetWidth,
                };

                setPulsePosition({
                  position: {
                    top: `${Math.ceil(
                      rects.top + rects.height / 2 + pointerDiameter / 3, //One third of pointer diameter
                    )}px`,
                    left: `${Math.ceil(rects.left - pointerRadius + rects.width / 2)}px`,
                  },
                  boundary: cardElement,
                });
              }

              break;
            }
            case 'commentReply': {
              const { commentId, annotationRepliesToggled } = pulseData;

              pollElementById(`annotation-card-${commentId}`, (commentCard) => {
                const replyToggle = document.getElementById(`annotation-${commentId}-replyToggle`);

                if (replyToggle) {
                  if (
                    !annotationRepliesToggled &&
                    !actionsCompleted.pdf_comments_mentionInCommentReply
                  ) {
                    const rect = {
                      top: replyToggle.offsetTop,
                      left: replyToggle.offsetLeft,
                      height: replyToggle.offsetHeight,
                      width: replyToggle.offsetWidth,
                    };

                    setPulsePosition({
                      position: {
                        top: `${Math.ceil(
                          rect.top + rect.height - pointerDiameter / 3, //One third of pointer diameter,
                        )}px`,
                        left: `${Math.ceil(rect.left + rect.width / 2 - pointerRadius)}px`,
                      },
                      boundary: commentCard,
                    });
                  } else {
                    setPulsePosition(undefined);
                  }
                } else {
                  setPulsePosition(undefined);
                }
              });

              break;
            }
            case 'taskDescription':
            case 'taskAssigned':
            case 'taskDueDate': {
              const temporaryTaskCard = document.getElementById('temporaryTaskCard');

              if (temporaryTaskCard) {
                const descriptionElement = document.getElementById('temporaryTaskDescription');
                const assignedToElement = document.getElementById('temporaryTaskAssignedTo');
                const dueDateElement = document.getElementById('temporaryTaskDueDate');
                const ctaButtonElement = document.getElementById('temporaryTaskCTA');

                if (descriptionElement && assignedToElement && dueDateElement && ctaButtonElement) {
                  const { taskHasAssignee, taskHasDescription, taskHasDueDate } = pulseData;
                  let rect: Rect = { top: 0, left: 0, height: 0, width: 0 };

                  if (!taskHasDescription) {
                    rect = {
                      top: descriptionElement.offsetTop,
                      left: descriptionElement.offsetLeft,
                      height: descriptionElement.offsetHeight,
                      width: descriptionElement.offsetWidth,
                    };
                  } else if (!taskHasAssignee) {
                    rect = {
                      top: assignedToElement.offsetTop,
                      left: assignedToElement.offsetLeft,
                      height: assignedToElement.offsetHeight,
                      width: assignedToElement.offsetWidth,
                    };
                  } else if (!taskHasDueDate) {
                    rect = {
                      top: dueDateElement.offsetTop,
                      left: dueDateElement.offsetLeft,
                      height: dueDateElement.offsetHeight,
                      width: dueDateElement.offsetWidth,
                    };
                  } else {
                    rect = {
                      top: ctaButtonElement.offsetTop,
                      left: ctaButtonElement.offsetLeft,
                      height: ctaButtonElement.offsetHeight,
                      width: ctaButtonElement.offsetWidth,
                    };
                  }

                  if (!taskHasDescription || !taskHasAssignee || !taskHasDueDate) {
                    setPulsePosition({
                      position: {
                        top: `${Math.ceil(
                          rect.top +
                            rect.height -
                            (pointerDiameter / 3) * 2 /*Two thirds of pointer diameter */,
                        )}px`,
                        left: `${Math.ceil(
                          rect.left + rect.width - pointerDiameter - 4 /*Right padding*/,
                        )}px`,
                      },
                      boundary: document.getElementById(`temporaryTaskCard`),
                    });
                  } else {
                    setPulsePosition({
                      position: {
                        top: `${Math.ceil(
                          rect.top + rect.height / 2 + pointerDiameter / 3, //One third of pointer diameter
                        )}px`,
                        left: `${Math.ceil(rect.left - pointerRadius + rect.width / 2)}px`,
                      },
                      boundary: document.getElementById(`temporaryTaskCard`),
                    });
                  }
                }
              }

              break;
            }
            case 'toolbar_highlight': {
              const { annotationId } = pulseData;
              if (!annotationId && cursorMode !== 'Highlight') {
                const target = document.getElementById('pdf-toolbar-highlight');
                if (target) {
                  const targetRect = target.getBoundingClientRect();
                  setPulsePosition({
                    position: {
                      top: `${targetRect.height - pointerRadius / 2}px`,
                      left: `2px`,
                    },
                    boundary: target,
                  });
                }
              }
              break;
            }
            case 'toolbar_comment': {
              const { commentId, annotationCardCtaRect } = pulseData;
              if (!annotationCardCtaRect && cursorMode !== 'Note') {
                if (!commentId) {
                  const target = document.getElementById('pdf-toolbar-comment');
                  if (target) {
                    const targetRect = target.getBoundingClientRect();
                    setPulsePosition({
                      position: {
                        top: `${targetRect.height - pointerRadius / 2}px`,
                        left: `${targetRect.width / 2 - pointerRadius}px`,
                      },
                      boundary: target,
                    });
                  }
                }
              }
              break;
            }
            case 'toolbar_task': {
              const { taskId } = pulseData;
              if (!taskId) {
                const target = document.getElementById('pdf-toolbar-task');
                if (target) {
                  const targetRect = target.getBoundingClientRect();
                  setPulsePosition({
                    position: {
                      top: `${targetRect.height - pointerRadius / 2}px`,
                      left: `${targetRect.width / 2 - pointerRadius}px`,
                    },
                    boundary: target,
                  });
                }
              }
              break;
            }
            case 'document_highlight': {
              const { annotationId } = pulseData;
              if (!annotationId && cursorMode === 'Highlight') {
                const defaultTop = 49;
                const defaultLeft = 690;

                setPulsePosition({
                  position: getPositionInDocument(defaultTop, defaultLeft),
                  boundary: document.getElementById(`page_1`),
                });
              } else if (annotationId) {
                setPulsePosition(undefined);
              }
              break;
            }
            case 'document_comment': {
              const { commentId } = pulseData;
              if (!commentId && cursorMode === 'Note') {
                const defaultTop = 48;
                const defaultLeft = 1225;

                setPulsePosition({
                  position: getPositionInDocument(defaultTop, defaultLeft),
                  boundary: document.getElementById(`page_1`),
                });
              } else if (commentId) {
                setPulsePosition(undefined);
              }
              break;
            }
            case 'document_task': {
              const { taskId } = pulseData;
              if (!taskId && cursorMode === 'Task') {
                const defaultTop = 422;
                const defaultLeft = 520;

                setPulsePosition({
                  position: getPositionInDocument(defaultTop, defaultLeft),
                  boundary: document.getElementById(`page_1`),
                });
              } else if (taskId) {
                setPulsePosition(undefined);
              }
              break;
            }

            default: {
              // clearInterval(pollInterval.current!);
              setPulsePosition(undefined);
              break;
            }
          }
        });
      } else {
        clearInterval(pollInterval.current!);
        setPulsePosition(undefined);
      }
    };

    processPulse();
  }, [activeCard, cards, pulseData, cursorMode, actionsCompleted]);

  //Cards' box
  useEffect(() => {
    if (cards[activeCard]?.boxTarget) {
      cards[activeCard].boxTarget?.forEach(async (target) => {
        switch (target) {
          case 'toolbar': {
            const target = document.getElementById('pdf-toolbar');
            if (target) {
              const rect = target.getBoundingClientRect();
              setBoxPosition({
                position: {
                  top: `4px`,
                  left: `4px`,
                  width: `calc(100vw - 1rem)`,
                  height: `${rect.height - 8}px`,
                },
                boundary: target,
              });
            }
            break;
          }
          case 'toolbar_annotations': {
            const toolbar = document.getElementById('pdf-toolbar');
            const target = document.getElementById('pdf-toolbar-section-2');
            if (target && toolbar) {
              const toolbarRect = toolbar.getBoundingClientRect();
              const targetRect = target.getBoundingClientRect();
              const topPadding = targetRect.y - toolbarRect.y;

              setBoxPosition({
                position: {
                  top: `-${topPadding - 4}px`,
                  left: `-4px`,
                  width: `${targetRect.width + 10}px`,
                  height: `${targetRect.height + topPadding}px`,
                },
                boundary: target,
              });
            }
            break;
          }
          case 'toolbar_highlight': {
            const toolbar = document.getElementById('pdf-toolbar');
            const target = document.getElementById('pdf-toolbar-highlight');
            if (target && toolbar) {
              const toolbarRect = toolbar.getBoundingClientRect();
              const targetRect = target.getBoundingClientRect();
              const topPadding = targetRect.y - toolbarRect.y;

              setBoxPosition({
                position: {
                  top: `-${topPadding - 4}px`,
                  left: `-8px`,
                  width: `${targetRect.width + 8}px`,
                  height: `${targetRect.height + topPadding}px`,
                },
                boundary: target,
              });
            }
            break;
          }
          case 'toolbar_comment': {
            const toolbar = document.getElementById('pdf-toolbar');
            const target = document.getElementById('pdf-toolbar-comment');
            if (target && toolbar) {
              const toolbarRect = toolbar.getBoundingClientRect();
              const targetRect = target.getBoundingClientRect();
              const topPadding = targetRect.y - toolbarRect.y;

              setBoxPosition({
                position: {
                  top: `-${topPadding - 4}px`,
                  left: `-4px`,
                  width: `${targetRect.width + 8}px`,
                  height: `${targetRect.height + topPadding}px`,
                },
                boundary: target,
              });
            }
            break;
          }
          case 'toolbar_task': {
            const toolbar = document.getElementById('pdf-toolbar');
            const target = document.getElementById('pdf-toolbar-task');
            if (target && toolbar) {
              const toolbarRect = toolbar.getBoundingClientRect();
              const targetRect = target.getBoundingClientRect();
              const topPadding = targetRect.y - toolbarRect.y;

              setBoxPosition({
                position: {
                  top: `-${topPadding - 4}px`,
                  left: `-4px`,
                  width: `${targetRect.width + 8}px`,
                  height: `${targetRect.height + topPadding}px`,
                },
                boundary: target,
              });
            }
            break;
          }
          default: {
            setBoxPosition(undefined);
            break;
          }
        }
      });
    } else {
      setBoxPosition(undefined);
    }
  }, [activeCard, cards]);

  //On journey changed
  useEffect(() => {
    if (onboardingIsReady) {
      //Delete all created annotations
      annotations.forEach((annotation) => {
        pdfManager.deleteAnnotation(1, annotation.id);
      });

      tasks.forEach((task) => {
        console.log('GOING TO DELETE BRO');
        pdfManager.deleteAnnotation(1, task.id);
      });

      setCreatedAnnotations(undefined);

      //Reset redux
      dispatch(setPulseData({ annotationId: undefined, commentId: undefined, taskId: undefined }));
      dispatch(resetCompletedActions());

      //Reset pulse
      setPulsePosition(undefined);

      //Reset the current card
      setInitialCardIndex(0);
      setActiveCard(0);

      //Close temporary cards
      dispatch(setCreating());

      //Reset cursor
      dispatch(setCursorMode('normal'));
    }
  }, [currentJourney, onboardingIsReady]);

  //Identify the created annotations
  useEffect(() => {
    const { annotationId, commentId, taskId } = pulseData;

    if (actionsCompleted.pdf_annotations_highlight && annotationId) {
      setCreatedAnnotations({
        ...createdAnnotations,
        annotation_create: annotationId,
      });
    }

    if (actionsCompleted.pdf_comments_createComment && commentId) {
      setCreatedAnnotations({
        ...createdAnnotations,
        comment_create: commentId,
      });
    }

    if (actionsCompleted.pdf_tasks_createTask && taskId) {
      setCreatedAnnotations({
        ...createdAnnotations,
        task_create: taskId,
      });
    }
  }, [actionsCompleted, pulseData]);

  //Set the current card accordingly to the api step
  useEffect(() => {
    if (!userLoaded) {
      return;
    }

    /**
     * Shouldn't be necessary but added as a safeguard.
     *
     * Ignore initialPhase behaviour in the following states
     */
    const inactiveStates = ['beginning', 'ending'];
    if (initialPhase && !inactiveStates.includes(initialPhase)) {
      const initialCardIndex = cards.findIndex((card) => card.id === initialPhase);
      if (initialCardIndex > 0) {
        setActiveCard(initialCardIndex);
        setInitialCardIndex(initialCardIndex);

        const completedActions = cards
          .slice(0, initialCardIndex)
          .reduce<PDFActions[]>((allActions, card) => {
            if (card.deps) {
              allActions = [...allActions, ...card.deps];
            }

            return allActions;
          }, []);

        dispatch(completeActionList(completedActions));

        for (let i = initialCardIndex; i >= 0; i--) {
          const card = cards[i];

          if (card.autoAction) {
            card.autoAction();

            break;
          }
        }
      }
    }
  }, [userLoaded]);

  //Set the annotations data in redux after a refresh
  useEffect(() => {
    const { annotationId, commentId, taskId } = pulseData;

    const annotation = annotations.find(
      (annotation) => annotation.state !== 'Cancelled' && annotation.subtype === 'Highlight',
    );

    const comment = annotations.find(
      (annotation) => annotation.state !== 'Cancelled' && annotation.subtype === 'Note',
    );
    const task = tasks.find((task) => task.state !== 'Cancelled');

    const annotationsToCreate = { ...createdAnnotations };

    if (annotation && !annotationId) {
      dispatch(setPulseData({ annotationId: annotation.id }));
      annotationsToCreate.annotation_representation = annotation.id;
    }
    if (comment && !commentId) {
      dispatch(setPulseData({ commentId: comment.id }));
      annotationsToCreate.comment_representation = comment.id;
    }
    if (task && !taskId) {
      dispatch(setPulseData({ taskId: task.id }));
      annotationsToCreate.task_representation = task.id;
    }

    setCreatedAnnotations({ ...annotationsToCreate });
  }, [annotations, tasks, pulseData]);

  //Interactions with alot of dependencies when the card changes
  useEffect(() => {
    if (actionsCompleted.pdf_annotations_highlight) {
      dispatch(setCursorMode('normal'));
    }
    switch (cards[activeCard].id) {
      case 'annotation_commentAnnotation': {
        const { annotationId } = pulseData;
        if (annotationId) {
          const intervalId = setInterval(() => {
            const annotationCard = document.getElementById(`annotation-card-${annotationId}`);

            if (annotationCard) {
              (document.activeElement as HTMLInputElement | undefined)?.blur?.();
              clearInterval(intervalId);
            }
          }, 100);
        }
        break;
      }
      case 'comment_create': {
        const { commentId } = pulseData;
        if (commentId) {
          const intervalId = setInterval(() => {
            const annotationCard = document.getElementById(`annotation-card-${commentId}`);

            if (annotationCard) {
              (document.activeElement as HTMLInputElement | undefined)?.blur?.();
              clearInterval(intervalId);
            }
          }, 100);
        }
        break;
      }
      case 'task_create': {
        const intervalId = setInterval(() => {
          const temporaryCard = document.getElementById(`temporaryTaskCard`);

          if (temporaryCard) {
            (document.activeElement as HTMLInputElement | undefined)?.blur?.();
            clearInterval(intervalId);
          }
        }, 100);

        break;
      }
      case 'annotation_save': {
        if (!actionsCompleted.pdf_annotations_commentHighlight) {
          const { annotationId } = pulseData;

          if (annotationId) {
            dispatch(setEditingAnnotation({ id: annotationId, isTask: false }));
          }
        }
        break;
      }

      case 'comment_save': {
        if (!actionsCompleted.pdf_comments_createComment) {
          const { commentId } = pulseData;

          if (commentId) {
            dispatch(setEditingAnnotation({ id: commentId, isTask: false }));
          }
        }
        break;
      }
      default: {
        break;
      }
    }
  }, [actionsCompleted, activeCard, cards, pulseData]);

  const handleNext = (currentCard: number) => {
    clearInterval(pollInterval.current!);
    cards[currentCard].autoAction?.();
    setActiveCard(currentCard);
    dispatch(setCursorMode('normal'));
    if (cards[currentCard].id !== 'ending') {
      sendOnboardingStatus({
        target: 'dopdf',
        step: cards[currentCard].id,
        journey: currentJourney,
      });
    }
  };

  const handleBack = (currentCard: number) => {
    clearInterval(pollInterval.current!);
    cards[currentCard].autoAction?.();
    if (cards[activeCard].id === 'beginning') {
      dispatch(stopOnboarding('dopdf'));
      dispatch(activateOnboarding('dopdf'));
    }
    setActiveCard(currentCard);
    dispatch(setCursorMode('normal'));
  };

  const exitOnboarding = (skip?: boolean) => {
    const id = !originalDoc?.id || originalDoc.id === '' ? currentPdf?.id : originalDoc.id;
    sendOnboardingStatus({ target: 'dopdf', step: 'ending', skip: !!skip, journey: undefined });
    setSkipping(false);
    dispatch(setZoomValue(originalDoc?.zoom ?? 1));
    setCurrentJourney(undefined);
    setActiveCard(0);
    dispatch(stopOnboarding('dopdf'));
    dispatch(setCurrentDocument({ target: 'dopdf' }));
    window.open(`/pdf/${id}`, '_self');
  };

  const handleSkip = () => {
    exitOnboarding(true);
  };

  const handleEnding = () => {
    exitOnboarding();
  };

  const onCancelSkip = () => {
    setSkipping(false);
  };

  useEffect(() => {
    if (onboardingIsReady) {
      dispatch(setRightPanel(undefined));
      dispatch(setLeftPanel(''));
    }
  }, [onboardingIsReady]);

  if (
    (!started && !active) ||
    !originalDoc ||
    (!originalDoc?.isOnboarding && (originalDoc?.id === '' || currentPdf?.id === originalDoc?.id))
  ) {
    return null;
  }

  return (
    <>
      {(active || (started && skipping)) && (
        <WelcomeScreen
          location="dopdf"
          onSkip={setSkipping}
          labelsId={{
            title: 'WELCOME_TO_THE_PDF_REVIEWER',
            description: 'WELCOME_PDF_DESCRIPTION_STANDALONE',
            cta: 'LETS_BEGIN',
          }}
          hiddenElements={['pdfContainer']}
          showCurrentUser={false}
          questionnaire={{
            isOpen:
              active && !!currentJourney && initialPhase !== 'beginning' && initialPhase != null,
            question: { id: 'WHAT_IS_YOUR_OBJECTIVE_WHEN_USING_THE_PDF_REVIEWER' },
            answers: {
              generalUser: { id: 'READ_DOCUMENTS' },
              reviewerUser: { id: 'COLLABORATE_WITH_OTHERS_AND_SHARE_FEEDBACK' },
            },
            defaultAnswer:
              initialPhase !== 'beginning' && initialPhase != null ? currentJourney : undefined,
            setAnswer: (answerId) => {
              setCurrentJourney(answerId);
            },
          }}
        />
      )}
      {((started && currentJourney) || skipping) && (
        <OnboardingOverlay environment="dopdf">
          <div style={skipping || started ? undefined : { display: 'none' }}>
            <OnboardingIntegrationCards
              ref={onboardingRef}
              title={intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' })}
              nextDisabled={
                cards[activeCard].deps
                  ? cards[activeCard].deps?.some((dep) => !actionsCompleted[dep]) ?? false
                  : false
              }
              nextDisabledTooltip={intl.formatMessage({ id: 'ONBOARDING_PENDING_ACTION_TOOLTIP' })}
              tip={intl.formatMessage({ id: 'TIP_COLON' })}
              previousLabel={intl.formatMessage({ id: 'BACK' })}
              next={intl.formatMessage({ id: 'NEXT' })}
              onNext={handleNext}
              onBack={handleBack}
              skipButtonLabel={intl.formatMessage({ id: 'SKIP_ONBOARDING' })}
              cards={cards}
              phases={{
                annotations: {
                  order: 1,
                  label: intl.formatMessage({ id: 'ONBOARDING_CARD_PDF_5_HEADER' }),
                },
                comments: { order: 2, label: intl.formatMessage({ id: 'COMMENTS' }) },
                tasks: { order: 3, label: intl.formatMessage({ id: 'TASKS' }) },
              }}
              onExit={handleSkip}
              onFinalExit={handleEnding}
              {...skipProps}
              size="medium"
              onCancel={() => {}}
              skip={skipping}
              cancelSkip={skipping ? onCancelSkip : undefined}
              initialCard={initialCardIndex}
              pulse={
                pulsePosition?.position &&
                (pulsePosition.boundary ? (
                  ReactDOM.createPortal(
                    <Pulse position={pulsePosition.position} testId="pdf" />,
                    pulsePosition.boundary,
                  )
                ) : (
                  <Pulse position={pulsePosition.position} testId="pdf" />
                ))
              }
              box={
                boxPosition?.position &&
                (boxPosition.boundary ? (
                  ReactDOM.createPortal(
                    <Box box={boxPosition.position} testId="editor" />,
                    boxPosition.boundary,
                  )
                ) : (
                  <Box box={boxPosition.position} testId="editor" />
                ))
              }
              testId="pdf"
            />
          </div>
        </OnboardingOverlay>
      )}
    </>
  );
};

export default OnboardingPDF;
