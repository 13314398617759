import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type CommentsSliceState = {
  searchBy?: string;
};

const initialState: CommentsSliceState = {};

const CommentsSlice = createSlice({
  name: 'presentation_comments',
  initialState,
  reducers: {
    setCommentsSearchBy: (state, action: PayloadAction<CommentsSliceState['searchBy']>) => {
      state.searchBy = action.payload;
    },
  },
});

export default CommentsSlice;

export const { setCommentsSearchBy } = CommentsSlice.actions;
