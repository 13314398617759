import { ReactNode, useMemo, CSSProperties, useCallback } from 'react';
import cx from 'classnames';

import {
  OnboardingEnvironments,
  ExplorerActions,
  EditorActions,
  PDFActions,
  ExplorerInteractions,
  EditorInteractions,
  PDFInteractions,
  PresentationInteractions,
  PresentationActions,
} from 'App/redux/onboardingSlice';

import styles from './InteractionController.module.scss';
import { useSelector } from '_common/hooks';

export type InteractionRule = {
  /** Interactions are enabled when permitted through redux state */
  interaction:
    | ExplorerInteractions
    | EditorInteractions
    | PDFInteractions
    | PresentationInteractions;
  /**
   * Interactions will be enabled as long as any of the actions aren't completed
   *
   * If no actions given, remains enabled as long as interaction is permitted
   */
  actions?: (ExplorerActions | EditorActions | PDFActions | PresentationActions)[];
  /**
   * Interactions will only be enabled when all actions are completed
   */
  preconditions?: (ExplorerActions | EditorActions | PDFActions | PresentationActions)[];
};

export type InteractionControllerProps = {
  environment: OnboardingEnvironments;
  /**
   * Set multiple rules for when the interaction should be enabled
   *
   * If no rules given and onboarding is active, all interactions will be disabled
   */
  rules?: InteractionRule[];
  children: ReactNode;
  style?: CSSProperties;
};

const InteractionController = ({
  environment,
  rules,
  children,
  style,
}: InteractionControllerProps) => {
  const isOnboardingActive = useSelector(
    (state) => state.onboarding.active[environment] || state.onboarding.started[environment],
  );
  const actionsCompleted = useSelector((state) => state.onboarding.actionsCompleted);
  const interactions = useSelector((state) => state.onboarding.interactions);

  const areAllActionsCompleted = useCallback(
    (actions: NonNullable<InteractionControllerProps['rules']>[number]['actions']) => {
      if (!actions) {
        return true;
      }

      return !actions.some((action) => !actionsCompleted[action]);
    },
    [actionsCompleted],
  );

  const isEnabled = useMemo(() => {
    if (!rules) {
      return false;
    }

    return rules.some(
      (rule) =>
        interactions[rule.interaction] &&
        (!rule.actions || !areAllActionsCompleted(rule.actions)) &&
        areAllActionsCompleted(rule.preconditions),
    );
  }, [rules, interactions, areAllActionsCompleted]);

  if (!isOnboardingActive) {
    return <>{children}</>;
  }

  if (!rules) {
    return (
      <span className={`${styles.root} ${styles.disabledInteraction}`} style={style}>
        {children}
      </span>
    );
  }

  return (
    <span
      className={cx(styles.root, {
        [styles.enabledInteraction]: isEnabled,
        [styles.disabledInteraction]: !isEnabled,
      })}
      style={style}
    >
      {children}
    </span>
  );
};

export default InteractionController;
