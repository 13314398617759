import { Command } from '../Command';
import { JsonRange } from 'Editor/services/_Common/Selection';
import { UpdateRowHeightsOperation } from '../../Operations/TableOperations';

export class UpdateRowHeigthsCommand extends Command {
  tableId: string;
  rowHeights: number[];

  constructor(context: Editor.Edition.ICommandArgs, tableId: string, rowHeights: number[]) {
    super(context);

    this.tableId = tableId;
    this.rowHeights = rowHeights;
  }

  async exec(): Promise<Editor.Edition.ICommand> {
    if (
      !this.askUserAboutThis() ||
      !this.context.DataManager ||
      !this.context.DataManager.selection
    ) {
      return this;
    }

    const rangeData = this.context.DataManager.selection.current;
    const jsonRange = rangeData[0] ? JsonRange.buildFromRangeData(rangeData[0]) : null;

    if (!jsonRange) {
      return this;
    }

    // TODO FIND ANOTHER SOLUTION TO GET NODE MODEL
    const baseModel = this.context.DataManager.nodes.getNodeModelById(jsonRange.start.b);
    const baseData = baseModel?.selectedData();

    if (
      !baseModel ||
      !baseData ||
      !baseData.id ||
      !(baseData.id === this.tableId || baseModel.getChildInfoById(this.tableId))
    ) {
      return this;
    }

    // check if element is editable
    if (!this.context.DataManager.nodes.isNodeEditable(baseModel.id)) {
      return this;
    }

    const operation = this.getOperation(baseModel);
    operation.apply();

    // create patch
    this.context.DataManager?.history.createPatch();

    return this;
  }

  private getOperation(baseModel: Editor.Data.Node.Model) {
    return new UpdateRowHeightsOperation(baseModel, this.tableId, this.rowHeights);
  }
}
