import { FC, useMemo } from 'react';

import ChartDataProvider from './ChartData';

import BarChart from './ChartTypes/BarChart';
import BubbleChart from './ChartTypes/BubbleChart';
import AreaChart from './ChartTypes/AreaChart';
import ScatterChart from './ChartTypes/ScatterChart';
import LineChart from './ChartTypes/LineChart';
import PieChart from './ChartTypes/PieChart';
import RadarChart from './ChartTypes/RadarChart';
import StockChart from './ChartTypes/StockChart';
import SunburstChart from './ChartTypes/SunburstChart';

type ChartShapeProps = {
  shape: Presentation.Data.ChartShape;
};

const ChartShape: FC<ChartShapeProps> = ({ shape }) => {
  //TODO:CHARTS Handle combo charts

  const chart = useMemo(() => {
    const chartTypes = shape.chartSpace.chart.plotArea.chartTypes;
    //@ts-expect-error this isn't supported yet
    const chartexTypes = shape.chartSpace.chart.plotArea?.plotAreaRegion?.series?.[0]?.layoutId;

    if (chartTypes?.find((chart) => chart?.type === 'stock')) {
      return <StockChart shape={shape} />;
    }

    if (chartexTypes) {
      switch (chartexTypes) {
        case 'sunburst':
          return <SunburstChart shape={shape} />
      }
    }

    const chartType = chartTypes?.[0]?.type;

    switch (chartType) {
      case 'bar':
        return <BarChart shape={shape} />;
      case 'bubble':
        return <BubbleChart shape={shape} />;
      case 'area':
        return <AreaChart shape={shape} />;
      case 'scatter':
        return <ScatterChart shape={shape} />;
      case 'line':
        return <LineChart shape={shape} />;
      case 'pie':
      case 'doughnut':
        return <PieChart shape={shape} />;
      case 'radar':
        return <RadarChart shape={shape} />;
      default:
        return null;
    }
  }, [shape]);

  return <ChartDataProvider shape={shape}>{chart}</ChartDataProvider>;
};

export default ChartShape;
