import { useComments } from 'Presentation/SyncStore';
import { useSelector } from '_common/hooks';
import { dayjs } from 'utils';
import useSearchComments from './useSearchComments';

const useCommentsList = () => {
  const comments = useComments();
  const filters = useSelector((state) => state.filters.reviewerCommentPanel);
  const list = useSearchComments();
  const filterValues = {
    commentStatus: filters.commentStatus?.map((filterValue) => filterValue.value),
    cardPriority: filters.cardPriority?.map((filterValue) => filterValue.value),
    author: filters.author?.map((filterValue) => filterValue.value),
    reviewerCreationDate: filters.reviewerCreationDate?.map((filterValue) => filterValue),
  };

  const filterComment = (id: string) => {
    const comment = comments?.data[id];

    if (!comment) {
      return false;
    }

    //Status
    if (
      (!filterValues?.commentStatus && comment.status === 'REJECTED') ||
      (filterValues.commentStatus &&
        filterValues.commentStatus.length > 0 &&
        !filterValues.commentStatus.includes(comment.status))
    ) {
      return false;
    }

    //Priority
    if (
      filterValues.cardPriority &&
      filterValues.cardPriority.length > 0 &&
      comment &&
      !filterValues.cardPriority.includes(comment.priority)
    ) {
      return false;
    }

    //Users
    if (
      filterValues.author &&
      filterValues.author.length > 0 &&
      filterValues.author.every((user) => {
        return user !== comment.author;
      })
    ) {
      return false;
    }

    //Creation date
    if (
      filterValues.reviewerCreationDate &&
      !filterValues.reviewerCreationDate[0].value.includes('specific_date_range')
    ) {
      const creationDate = dayjs(comment.time);
      if (
        filterValues.reviewerCreationDate[0].value.includes('last_week') &&
        dayjs().diff(creationDate, 'week') === 0
      ) {
        return true;
      }
      if (
        filterValues.reviewerCreationDate[0].value.includes('last_month') &&
        dayjs().diff(creationDate, 'month') === 0
      ) {
        return true;
      }
      if (
        filterValues.reviewerCreationDate[0].value.includes('3_month_ago') &&
        dayjs().diff(creationDate, 'month') <= 2
      ) {
        return true;
      }
      if (
        filterValues.reviewerCreationDate[0].value.includes('6_month_ago') &&
        dayjs().diff(creationDate, 'month') <= 5
      ) {
        return true;
      }
      return false;
    }

    //Specific date range
    if (
      filterValues.reviewerCreationDate &&
      filterValues.reviewerCreationDate[0].value?.includes('specific_date_range') &&
      //@ts-expect-error
      filterValues.reviewerCreationDate[0]?.dateValue !== null
    ) {
      const creationDate = dayjs(comment.time);
      let valid = true;
      if (
        filterValues.reviewerCreationDate &&
        //@ts-expect-error
        filterValues.reviewerCreationDate[0]?.dateValue?.startDate
      ) {
        valid =
          valid &&
          creationDate.isSameOrAfter(
            //@ts-expect-error
            dayjs(filterValues.reviewerCreationDate[0]?.dateValue?.startDate),
            'day',
          );
      }
      if (
        filterValues.reviewerCreationDate &&
        //@ts-expect-error
        filterValues.reviewerCreationDate[0]?.dateValue?.endDate
      ) {
        valid =
          valid &&
          creationDate.isSameOrBefore(
            //@ts-expect-error
            dayjs(filterValues.reviewerCreationDate[0]?.dateValue?.endDate),
            'day',
          );
      }
      return valid;
    }

    return true;
  };

  const filteredComment = list?.filter(filterComment);

  return {
    list: filteredComment,
  };
};

export default useCommentsList;
