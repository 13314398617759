type PatternRect = { width: number; height: number; x: number; y: number };

type PatternInfo = {
  width: number;
  height: number;
  reverseColors: boolean;
  rects: PatternRect[];
};

const PATTERNS: { [pattern in Presentation.Data.Common.Pattern]: PatternInfo } = {
  // 1
  pct5: {
    width: 8,
    height: 8,
    reverseColors: false,
    rects: [
      { width: 1, height: 1, x: 0, y: 0 },
      { width: 1, height: 1, x: 4, y: 4 },
    ],
  },
  // 2
  pct10: {
    width: 8,
    height: 4,
    reverseColors: false,
    rects: [
      { width: 1, height: 1, x: 0, y: 0 },
      { width: 1, height: 1, x: 4, y: 2 },
    ],
  },
  // 3
  pct20: {
    width: 4,
    height: 4,
    reverseColors: false,
    rects: [
      { width: 1, height: 1, x: 0, y: 0 },
      { width: 1, height: 1, x: 2, y: 2 },
    ],
  },
  // 4
  pct25: {
    width: 4,
    height: 2,
    reverseColors: false,
    rects: [
      { width: 1, height: 1, x: 0, y: 0 },
      { width: 1, height: 1, x: 2, y: 1 },
    ],
  },
  // 5
  pct30: {
    width: 4,
    height: 4,
    reverseColors: false,
    rects: [
      { width: 1, height: 1, x: 0, y: 0 },
      { width: 1, height: 1, x: 2, y: 0 },

      { width: 1, height: 1, x: 1, y: 1 },

      { width: 1, height: 1, x: 0, y: 2 },
      { width: 1, height: 1, x: 2, y: 2 },

      { width: 1, height: 1, x: 3, y: 3 },
    ],
  },
  // 6
  pct40: {
    width: 8,
    height: 8,
    reverseColors: false,
    rects: [
      { width: 1, height: 1, x: 0, y: 0 },
      { width: 1, height: 1, x: 2, y: 0 },
      { width: 1, height: 1, x: 4, y: 0 },
      { width: 1, height: 1, x: 6, y: 0 },

      { width: 1, height: 1, x: 1, y: 1 },
      { width: 1, height: 1, x: 3, y: 1 },
      { width: 1, height: 1, x: 5, y: 1 },
      { width: 1, height: 1, x: 7, y: 1 },

      { width: 1, height: 1, x: 0, y: 2 },
      { width: 1, height: 1, x: 2, y: 2 },
      { width: 1, height: 1, x: 4, y: 2 },
      { width: 1, height: 1, x: 6, y: 2 },

      { width: 1, height: 1, x: 3, y: 3 },
      { width: 1, height: 1, x: 5, y: 3 },
      { width: 1, height: 1, x: 7, y: 3 },

      { width: 1, height: 1, x: 0, y: 4 },
      { width: 1, height: 1, x: 2, y: 4 },
      { width: 1, height: 1, x: 4, y: 4 },
      { width: 1, height: 1, x: 6, y: 4 },

      { width: 1, height: 1, x: 1, y: 5 },
      { width: 1, height: 1, x: 3, y: 5 },
      { width: 1, height: 1, x: 5, y: 5 },
      { width: 1, height: 1, x: 7, y: 5 },

      { width: 1, height: 1, x: 0, y: 6 },
      { width: 1, height: 1, x: 2, y: 6 },
      { width: 1, height: 1, x: 4, y: 6 },
      { width: 1, height: 1, x: 6, y: 6 },

      { width: 1, height: 1, x: 1, y: 7 },
      { width: 1, height: 1, x: 3, y: 7 },
      { width: 1, height: 1, x: 7, y: 7 },
    ],
  },
  // 7
  pct50: {
    width: 2,
    height: 2,
    reverseColors: false,
    rects: [
      { width: 1, height: 1, x: 0, y: 0 },
      { width: 1, height: 1, x: 1, y: 1 },
    ],
  },
  // 8
  pct60: {
    width: 4,
    height: 4,
    reverseColors: false,
    rects: [
      { width: 3, height: 1, x: 0, y: 0 },

      { width: 1, height: 1, x: 1, y: 1 },
      { width: 1, height: 1, x: 3, y: 1 },

      { width: 1, height: 1, x: 0, y: 2 },
      { width: 2, height: 1, x: 2, y: 2 },

      { width: 1, height: 1, x: 1, y: 3 },
      { width: 1, height: 1, x: 3, y: 3 },
    ],
  },
  // 9
  pct70: {
    width: 4,
    height: 2,
    reverseColors: false,
    rects: [
      { width: 3, height: 1, x: 1, y: 0 },

      { width: 2, height: 1, x: 0, y: 1 },
      { width: 1, height: 1, x: 3, y: 1 },
    ],
  },
  // 10
  pct75: {
    width: 4,
    height: 4,
    reverseColors: true,
    rects: [
      { width: 1, height: 1, x: 0, y: 0 },
      { width: 1, height: 1, x: 2, y: 2 },
    ],
  },
  // 11
  pct80: {
    width: 8,
    height: 4,
    reverseColors: true,
    rects: [
      { width: 1, height: 1, x: 0, y: 0 },
      { width: 1, height: 1, x: 4, y: 2 },
    ],
  },
  // 12
  pct90: {
    width: 8,
    height: 8,
    reverseColors: true,
    rects: [
      { width: 1, height: 1, x: 4, y: 0 },
      { width: 1, height: 1, x: 0, y: 4 },
    ],
  },
  // 13
  ltDnDiag: {
    width: 4,
    height: 4,
    reverseColors: false,
    rects: [
      { width: 1, height: 1, x: 0, y: 0 },
      { width: 1, height: 1, x: 1, y: 1 },
      { width: 1, height: 1, x: 2, y: 2 },
      { width: 1, height: 1, x: 3, y: 3 },
    ],
  },
  // 14
  ltUpDiag: {
    width: 4,
    height: 4,
    reverseColors: false,
    rects: [
      { width: 1, height: 1, x: 0, y: 3 },
      { width: 1, height: 1, x: 1, y: 2 },
      { width: 1, height: 1, x: 2, y: 1 },
      { width: 1, height: 1, x: 3, y: 0 },
    ],
  },
  // 15
  dkDnDiag: {
    width: 4,
    height: 4,
    reverseColors: false,
    rects: [
      { width: 2, height: 1, x: 0, y: 0 },

      { width: 2, height: 1, x: 1, y: 1 },

      { width: 2, height: 1, x: 2, y: 2 },

      { width: 1, height: 1, x: 0, y: 3 },
      { width: 1, height: 1, x: 3, y: 3 },
    ],
  },
  // 16
  dkUpDiag: {
    width: 4,
    height: 4,
    reverseColors: false,
    rects: [
      { width: 2, height: 1, x: 2, y: 0 },

      { width: 2, height: 1, x: 1, y: 1 },

      { width: 2, height: 1, x: 0, y: 2 },

      { width: 1, height: 1, x: 0, y: 3 },
      { width: 1, height: 1, x: 3, y: 3 },
    ],
  },
  // 17 balnk
  wdDnDiag: {
    width: 8,
    height: 8,
    reverseColors: false,
    rects: [
      { width: 2, height: 1, x: 0, y: 0 },
      { width: 1, height: 1, x: 7, y: 0 },

      { width: 3, height: 1, x: 0, y: 1 },
      { width: 3, height: 1, x: 1, y: 2 },
      { width: 3, height: 1, x: 2, y: 3 },
      { width: 3, height: 1, x: 3, y: 4 },
      { width: 3, height: 1, x: 4, y: 5 },
      { width: 3, height: 1, x: 5, y: 6 },

      { width: 1, height: 1, x: 0, y: 7 },
      { width: 2, height: 1, x: 6, y: 7 },
    ],
  },
  // 18 blank
  wdUpDiag: {
    width: 8,
    height: 8,
    reverseColors: false,
    rects: [
      { width: 1, height: 1, x: 0, y: 0 },
      { width: 2, height: 1, x: 6, y: 0 },

      { width: 3, height: 1, x: 5, y: 1 },
      { width: 3, height: 1, x: 4, y: 2 },
      { width: 3, height: 1, x: 3, y: 3 },
      { width: 3, height: 1, x: 2, y: 4 },
      { width: 3, height: 1, x: 1, y: 5 },
      { width: 3, height: 1, x: 0, y: 6 },

      { width: 2, height: 1, x: 0, y: 7 },
      { width: 1, height: 1, x: 7, y: 7 },
    ],
  },
  // 19
  ltVert: {
    width: 4,
    height: 1,
    reverseColors: false,
    rects: [{ width: 1, height: 1, x: 0, y: 0 }],
  },
  // 20
  ltHorz: {
    width: 1,
    height: 4,
    reverseColors: false,
    rects: [{ width: 1, height: 1, x: 0, y: 0 }],
  },
  // 21
  narVert: {
    width: 2,
    height: 1,
    reverseColors: false,
    rects: [{ width: 1, height: 1, x: 1, y: 0 }],
  },
  // 22 blank
  narHorz: {
    width: 1,
    height: 2,
    reverseColors: false,
    rects: [{ width: 1, height: 1, x: 0, y: 1 }],
  },
  // 23
  dkVert: {
    width: 4,
    height: 1,
    reverseColors: false,
    rects: [{ width: 2, height: 1, x: 0, y: 0 }],
  },
  // 24
  dkHorz: {
    width: 1,
    height: 4,
    reverseColors: false,
    rects: [{ width: 1, height: 2, x: 0, y: 0 }],
  },
  // 25
  dashDnDiag: {
    width: 4,
    height: 8,
    reverseColors: false,
    rects: [
      { width: 1, height: 1, x: 0, y: 2 },
      { width: 1, height: 1, x: 1, y: 3 },
      { width: 1, height: 1, x: 2, y: 4 },
      { width: 1, height: 1, x: 3, y: 5 },
    ],
  },
  // 26 blank
  dashUpDiag: {
    width: 4,
    height: 8,
    reverseColors: false,
    rects: [
      { width: 1, height: 1, x: 3, y: 2 },
      { width: 1, height: 1, x: 2, y: 3 },
      { width: 1, height: 1, x: 1, y: 4 },
      { width: 1, height: 1, x: 0, y: 5 },
    ],
  },
  // 27
  dashHorz: {
    width: 8,
    height: 8,
    reverseColors: false,
    rects: [
      { width: 4, height: 1, x: 0, y: 0 },
      { width: 4, height: 1, x: 4, y: 4 },
    ],
  },
  // 28
  dashVert: {
    width: 8,
    height: 8,
    reverseColors: false,
    rects: [
      { width: 1, height: 4, x: 0, y: 0 },
      { width: 1, height: 4, x: 4, y: 4 },
    ],
  },
  // 29
  smConfetti: {
    width: 8,
    height: 8,
    reverseColors: false,
    rects: [
      { width: 1, height: 1, x: 0, y: 0 },
      { width: 1, height: 1, x: 4, y: 1 },
      { width: 1, height: 1, x: 1, y: 2 },
      { width: 1, height: 1, x: 6, y: 3 },
      { width: 1, height: 1, x: 3, y: 4 },
      { width: 1, height: 1, x: 7, y: 5 },
      { width: 1, height: 1, x: 2, y: 6 },
      { width: 1, height: 1, x: 5, y: 7 },
    ],
  },
  // 30
  lgConfetti: {
    width: 8,
    height: 8,
    reverseColors: false,
    rects: [
      { width: 1, height: 1, x: 0, y: 0 },
      { width: 2, height: 2, x: 2, y: 0 },
      { width: 1, height: 1, x: 7, y: 0 },
      { width: 2, height: 2, x: 0, y: 4 },
      { width: 2, height: 2, x: 3, y: 3 },
      { width: 2, height: 2, x: 6, y: 2 },
      { width: 1, height: 1, x: 0, y: 7 },
      { width: 2, height: 2, x: 4, y: 6 },
      { width: 1, height: 1, x: 7, y: 7 },
    ],
  },
  // // 31 blank
  zigZag: {
    width: 8,
    height: 4,
    reverseColors: false,
    rects: [
      { width: 1, height: 1, x: 0, y: 0 },
      { width: 1, height: 1, x: 1, y: 1 },
      { width: 1, height: 1, x: 2, y: 2 },
      { width: 1, height: 1, x: 3, y: 3 },
      { width: 1, height: 1, x: 4, y: 3 },
      { width: 1, height: 1, x: 5, y: 2 },
      { width: 1, height: 1, x: 6, y: 1 },
      { width: 1, height: 1, x: 7, y: 0 },
    ],
  },
  // // 32 blank
  wave: {
    width: 8,
    height: 4,
    reverseColors: false,
    rects: [
      { width: 2, height: 1, x: 3, y: 1 },
      { width: 1, height: 1, x: 2, y: 2 },
      { width: 1, height: 1, x: 5, y: 2 },
      { width: 1, height: 1, x: 7, y: 2 },
      { width: 2, height: 1, x: 0, y: 3 },
    ],
  },
  // 33
  diagBrick: {
    width: 8,
    height: 8,
    reverseColors: false,
    rects: [
      { width: 1, height: 1, x: 7, y: 0 },
      { width: 1, height: 1, x: 6, y: 1 },
      { width: 1, height: 1, x: 5, y: 2 },
      { width: 1, height: 1, x: 4, y: 3 },
      { width: 1, height: 1, x: 4, y: 4 },
      { width: 1, height: 1, x: 3, y: 4 },
      { width: 1, height: 1, x: 5, y: 5 },
      { width: 1, height: 1, x: 2, y: 5 },
      { width: 1, height: 1, x: 6, y: 6 },
      { width: 1, height: 1, x: 1, y: 6 },
      { width: 1, height: 1, x: 7, y: 7 },
      { width: 1, height: 1, x: 0, y: 7 },
    ],
  },
  // 34
  horzBrick: {
    width: 8,
    height: 8,
    reverseColors: false,
    rects: [
      { width: 8, height: 1, x: 0, y: 0 },
      { width: 1, height: 5, x: 0, y: 0 },
      { width: 8, height: 1, x: 0, y: 4 },
      { width: 1, height: 4, x: 4, y: 4 },
    ],
  },
  // // 35 blank
  weave: {
    width: 8,
    height: 8,
    reverseColors: false,
    rects: [
      { width: 1, height: 1, x: 0, y: 0 },
      { width: 1, height: 1, x: 4, y: 0 },
      { width: 1, height: 1, x: 1, y: 1 },
      { width: 1, height: 1, x: 3, y: 1 },
      { width: 1, height: 1, x: 5, y: 1 },
      { width: 1, height: 1, x: 2, y: 2 },
      { width: 1, height: 1, x: 6, y: 2 },
      { width: 1, height: 1, x: 1, y: 3 },
      { width: 1, height: 1, x: 5, y: 3 },
      { width: 1, height: 1, x: 7, y: 3 },
      { width: 1, height: 1, x: 0, y: 4 },
      { width: 1, height: 1, x: 4, y: 4 },
      { width: 1, height: 1, x: 3, y: 5 },
      { width: 1, height: 1, x: 5, y: 5 },
      { width: 1, height: 1, x: 2, y: 6 },
      { width: 1, height: 1, x: 6, y: 6 },
      { width: 1, height: 1, x: 1, y: 7 },
      { width: 1, height: 1, x: 3, y: 7 },
      { width: 1, height: 1, x: 7, y: 7 },
    ],
  },
  // 36
  plaid: {
    width: 8,
    height: 8,
    reverseColors: false,
    rects: [
      { width: 1, height: 1, x: 0, y: 0 },
      { width: 1, height: 1, x: 2, y: 0 },
      { width: 1, height: 1, x: 4, y: 0 },
      { width: 1, height: 1, x: 6, y: 0 },
      { width: 1, height: 1, x: 1, y: 1 },
      { width: 1, height: 1, x: 3, y: 1 },
      { width: 1, height: 1, x: 5, y: 1 },
      { width: 1, height: 1, x: 7, y: 1 },
      { width: 1, height: 1, x: 0, y: 2 },
      { width: 1, height: 1, x: 2, y: 2 },
      { width: 1, height: 1, x: 4, y: 2 },
      { width: 1, height: 1, x: 6, y: 2 },
      { width: 1, height: 1, x: 1, y: 3 },
      { width: 1, height: 1, x: 3, y: 3 },
      { width: 1, height: 1, x: 5, y: 3 },
      { width: 1, height: 1, x: 7, y: 3 },
      { width: 4, height: 4, x: 0, y: 4 },
    ],
  },
  // 37
  divot: {
    width: 8,
    height: 8,
    reverseColors: false,
    rects: [
      { width: 1, height: 1, x: 3, y: 1 },
      { width: 1, height: 1, x: 4, y: 2 },
      { width: 1, height: 1, x: 3, y: 3 },
      { width: 1, height: 1, x: 0, y: 5 },
      { width: 1, height: 1, x: 7, y: 6 },
      { width: 1, height: 1, x: 0, y: 7 },
    ],
  },
  // 38
  dotGrid: {
    width: 8,
    height: 8,
    reverseColors: false,
    rects: [
      { width: 1, height: 1, x: 0, y: 0 },
      { width: 1, height: 1, x: 2, y: 0 },
      { width: 1, height: 1, x: 4, y: 0 },
      { width: 1, height: 1, x: 6, y: 0 },
      { width: 1, height: 1, x: 0, y: 2 },
      { width: 1, height: 1, x: 0, y: 4 },
      { width: 1, height: 1, x: 0, y: 6 },
    ],
  },
  // 39
  dotDmnd: {
    width: 8,
    height: 8,
    reverseColors: false,
    rects: [
      { width: 1, height: 1, x: 0, y: 0 },
      { width: 1, height: 1, x: 2, y: 2 },
      { width: 1, height: 1, x: 6, y: 2 },
      { width: 1, height: 1, x: 4, y: 4 },
      { width: 1, height: 1, x: 2, y: 6 },
      { width: 1, height: 1, x: 6, y: 6 },
    ],
  },
  // 40
  shingle: {
    width: 8,
    height: 8,
    reverseColors: false,
    rects: [
      { width: 2, height: 1, x: 6, y: 0 },
      { width: 1, height: 1, x: 0, y: 1 },
      { width: 1, height: 1, x: 5, y: 1 },
      { width: 1, height: 1, x: 1, y: 2 },
      { width: 1, height: 1, x: 4, y: 2 },
      { width: 2, height: 1, x: 2, y: 3 },
      { width: 2, height: 1, x: 4, y: 4 },
      { width: 1, height: 1, x: 6, y: 5 },
      { width: 1, height: 2, x: 7, y: 6 },
    ],
  },
  // 41
  trellis: {
    width: 4,
    height: 4,
    reverseColors: true,
    rects: [
      { width: 2, height: 1, x: 0, y: 1 },
      { width: 2, height: 1, x: 2, y: 3 },
    ],
  },
  // 42
  sphere: {
    width: 8,
    height: 8,
    reverseColors: true,
    rects: [
      { width: 1, height: 1, x: 1, y: 0 },
      { width: 1, height: 1, x: 5, y: 0 },
      { width: 1, height: 3, x: 0, y: 1 },
      { width: 2, height: 1, x: 2, y: 1 },
      { width: 2, height: 3, x: 6, y: 1 },
      { width: 1, height: 1, x: 1, y: 4 },
      { width: 1, height: 1, x: 5, y: 4 },
      { width: 3, height: 3, x: 2, y: 45 },
      { width: 2, height: 1, x: 6, y: 45 },
    ],
  },
  // 43
  smGrid: {
    width: 8,
    height: 8,
    reverseColors: false,
    rects: [
      { width: 8, height: 1, x: 0, y: 0 },
      { width: 1, height: 8, x: 0, y: 0 },
      { width: 1, height: 8, x: 4, y: 0 },
      { width: 8, height: 1, x: 0, y: 4 },
    ],
  },
  // 44
  lgGrid: {
    width: 8,
    height: 8,
    reverseColors: false,
    rects: [
      { width: 8, height: 1, x: 0, y: 0 },
      { width: 1, height: 8, x: 0, y: 0 },
    ],
  },
  // 45
  smCheck: {
    width: 8,
    height: 8,
    reverseColors: false,
    rects: [
      { width: 1, height: 1, x: 0, y: 0 },
      { width: 2, height: 1, x: 3, y: 0 },
      { width: 1, height: 1, x: 7, y: 0 },
      { width: 2, height: 2, x: 1, y: 1 },
      { width: 2, height: 2, x: 5, y: 1 },
      { width: 1, height: 2, x: 0, y: 3 },
      { width: 2, height: 2, x: 3, y: 3 },
      { width: 1, height: 2, x: 7, y: 3 },
      { width: 2, height: 2, x: 1, y: 5 },
      { width: 2, height: 2, x: 5, y: 5 },
      { width: 1, height: 1, x: 0, y: 7 },
      { width: 2, height: 1, x: 3, y: 7 },
      { width: 1, height: 1, x: 7, y: 7 },
    ],
  },
  // 46
  lgCheck: {
    width: 8,
    height: 8,
    reverseColors: false,
    rects: [
      { width: 4, height: 4, x: 0, y: 0 },
      { width: 4, height: 4, x: 4, y: 4 },
    ],
  },
  //47
  openDmnd: {
    width: 8,
    height: 8,
    reverseColors: false,
    rects: [
      { width: 1, height: 1, x: 0, y: 0 },
      { width: 1, height: 1, x: 1, y: 1 },
      { width: 1, height: 1, x: 3, y: 3 },
      { width: 1, height: 1, x: 4, y: 4 },
      { width: 1, height: 1, x: 5, y: 5 },
      { width: 1, height: 1, x: 6, y: 6 },
      { width: 1, height: 1, x: 7, y: 7 },
      { width: 1, height: 1, x: 6, y: 0 },
      { width: 1, height: 1, x: 5, y: 1 },
      { width: 1, height: 1, x: 4, y: 2 },
      { width: 1, height: 1, x: 2, y: 4 },
      { width: 1, height: 1, x: 1, y: 5 },
      { width: 1, height: 1, x: 0, y: 6 },
    ],
  },
  // 48
  solidDmnd: {
    width: 8,
    height: 8,
    reverseColors: false,
    rects: [
      { width: 1, height: 1, x: 3, y: 0 },
      { width: 3, height: 1, x: 2, y: 1 },
      { width: 5, height: 3, x: 1, y: 2 },
      { width: 1, height: 1, x: 0, y: 3 },
      { width: 1, height: 1, x: 6, y: 3 },
      { width: 3, height: 1, x: 2, y: 5 },
      { width: 1, height: 1, x: 3, y: 6 },
    ],
  },
};

type PatternProps = {
  id: string;
  pattern: Presentation.Data.Common.Pattern;
  backgroundColor: string;
  foregroundColor: string;
};

const Pattern = ({ id, pattern, backgroundColor, foregroundColor }: PatternProps) => {
  if (pattern in PATTERNS) {
    const { width, height, rects, reverseColors } = PATTERNS[pattern];
    return (
      <pattern id={id} patternUnits="userSpaceOnUse" width={width} height={height}>
        <rect
          fill={reverseColors ? foregroundColor : backgroundColor}
          x="0"
          y="0"
          width="100%"
          height="100%"
        />
        {rects.map(({ height, width, x, y }) => (
          <rect
            key={'' + width + height + x + y}
            fill={reverseColors ? backgroundColor : foregroundColor}
            x={x}
            y={y}
            width={width}
            height={height}
          />
        ))}
      </pattern>
    );
  }
  return null;
};

export default Pattern;
