import { useSlideData } from 'Presentation/Slides/Slide/SlideData';
import useChartOutline from './useChartOutline';
import { useChartData } from '../ChartData';

const useDataLabel = () => {
  const { chartShape } = useChartData();
  const { color, getFontFamily } = useSlideData();
  const { chartOutline } = useChartOutline();

  const dataLabelStyle = ({
    dLbls,
    idx,
    defaultPosition,
  }: {
    dLbls: Presentation.Data.DLbls | undefined;
    idx: number;
    defaultPosition: Exclude<echarts.LineSeriesOption['label'], undefined>['position'];
  }): echarts.LineSeriesOption['label'] => {
    //TODO:CHARTS proccess styles as rich

    /*
     * First the general properties of the label will be proccessed. These are inherited properties from dbLbsl
     * Then the specific properties of the label will be proccessed. There are properties from dLbls.dLbl[number]
     */

    if (!dLbls) {
      return undefined;
    }

    const TEXT_ALIGN = {
      l: 'left',
      ctr: 'center',
      r: 'right',
      just: undefined,
      justLow: undefined,
      dist: undefined,
      thaiDist: undefined,
    } as const;

    const labelParentProperties = dLbls.text?.childNodes?.[0].properties;
    const labelParentOutline = chartOutline(labelParentProperties?.inlineProperties?.ln);

    const labelParentStyle: echarts.LineSeriesOption['label'] = {
      //TODO:CHARTS Font family
      fontFamily:
        getFontFamily({ font: labelParentProperties?.inlineProperties?.latin?.font }) ??
        getFontFamily({ font: chartShape.chartStyle.dataLabel?.fontRef }),
      fontSize: labelParentProperties?.inlineProperties?.size,
      fontWeight: labelParentProperties?.inlineProperties?.b ? 'bold' : undefined,
      fontStyle: labelParentProperties?.inlineProperties?.i ? 'italic' : undefined,
      //CHARTS:LIMITATION color only supports string (solid color)
      color:
        labelParentProperties?.inlineProperties?.fill?.type === 'solid'
          ? color(labelParentProperties?.inlineProperties?.fill.color)
          : undefined,
      backgroundColor: labelParentProperties?.inlineProperties?.highlight
        ? color(labelParentProperties?.inlineProperties?.highlight)
        : undefined,
      //CHARTS:LIMITATION textBorderColor only supports string (solid color)
      textBorderColor:
        labelParentProperties?.inlineProperties?.ln?.fill?.type === 'solid'
          ? color(labelParentProperties?.inlineProperties?.ln?.fill?.color)
          : undefined,
      textBorderWidth: labelParentOutline?.borderWidth,
      textBorderType: labelParentOutline?.borderType,

      align: labelParentProperties?.algn ? TEXT_ALIGN[labelParentProperties.algn] : undefined,
    };

    let labelStyle: echarts.LineSeriesOption['label'] = { ...labelParentStyle };

    let labelPosition: Exclude<echarts.LineSeriesOption['label'], undefined>['position'] =
      undefined;

    switch (dLbls?.dLblPos) {
      case 'ctr': {
        labelPosition = 'inside';
        break;
      }
      case 'r': {
        labelPosition = 'right';
        break;
      }
      case 'l': {
        labelPosition = 'left';
        break;
      }
      case 't': {
        labelPosition = 'top';
        break;
      }
      case 'b': {
        labelPosition = 'bottom';
        break;
      }
      case 'inEnd': {
        labelPosition = 'inside'; // TODO:CHARTS Check if insideRight works in other charts (doesn't work in pie)
        break;
      }
      case 'outEnd': {
        labelPosition = 'right';
        break;
      }
      case 'inBase': {
        labelPosition = 'insideLeft';
        break;
      }

      case 'bestFit':
      default: {
        labelPosition = defaultPosition;
        console.warn('Chart data label position ', dLbls?.dLblPos, 'TBI');
      }
    }

    //Proccess specific individual label properties
    if (dLbls?.dLbl?.length) {
      const label = dLbls.dLbl.find((lbl) => lbl.idx === idx);
      if (label) {
        const labelProperties =
          label.text?.childNodes?.[0]?.childNodes?.[0]?.properties ??
          label.text?.childNodes?.[0].properties.inlineProperties;
        const labelOutline = chartOutline(labelProperties?.ln);

        labelStyle = {
          fontFamily:
            getFontFamily({ font: labelProperties?.latin?.font }) ?? labelParentStyle.fontFamily,
          fontSize: labelProperties?.size ?? labelParentStyle?.fontSize,
          fontWeight: labelProperties?.b ? 'bold' : labelParentStyle?.fontWeight,
          fontStyle: labelProperties?.i ? 'italic' : labelParentStyle?.fontStyle,
          //CHARTS:LIMITATION color only suppports string (solid color)
          color:
            labelProperties?.fill?.type === 'solid'
              ? color(labelProperties?.fill.color) ?? labelParentStyle?.color
              : labelParentStyle?.color,
          backgroundColor: labelProperties?.highlight
            ? color(labelProperties.highlight) ?? labelParentStyle?.backgroundColor
            : labelParentStyle?.backgroundColor,
          //CHARTS:LIMITATION textBorderColor only supports string (solid color)
          textBorderColor:
            labelProperties?.ln?.fill?.type === 'solid'
              ? color(labelProperties?.ln?.fill.color) ?? labelParentStyle.textBorderColor
              : labelParentStyle.textBorderColor,
          textBorderWidth: labelOutline?.borderWidth ?? labelParentStyle.textBorderWidth,
          textBorderType: labelOutline?.borderType ?? labelParentStyle.textBorderType,
        };

        if (label.layout?.type === 'manual') {
          //TODO:CHARTS
        }
      }
    }

    return {
      position: labelPosition,
      ...labelStyle,
    };
  };

  const dataLabelContent = ({
    dLbls,
    idx,
    val,
    cat,
  }: {
    dLbls: Presentation.Data.DLbls | undefined;
    idx: number;
    val: string | undefined;
    cat: string | undefined;
  }): string => {
    if (!dLbls) {
      return '';
    }

    let formatCode: string = dLbls?.numFmt?.formatCode ?? 'General'; //default is "General"
    let separator = ','; //default is ","
    let parsedVal = val ?? ''; //y
    let parsedCat = cat ?? ''; //x
    let value = '';
    let showVal = dLbls.showVal;
    let showCatName = dLbls.showCatName;
    let showSerName = dLbls.showSerName;

    if (dLbls?.dLbl?.length) {
      const label = dLbls.dLbl.find((lbl) => lbl.idx === idx);
      if (label) {
        let labelShowVal = false;
        let labelShowCat = false;

        //TODO:CHARTS proccess all types of child.type

        if (label.text?.childNodes?.[0].childNodes?.length) {
          label.text?.childNodes?.[0].childNodes?.forEach((child) => {
            const content = child?.content;

            if (child.type === 'text') {
              value += content;
            } else if (child.type === 'tx_field') {
              if (child.tx_field_type === 'XVALUE') {
                labelShowCat = true;
              } else if (child.tx_field_type === 'YVALUE') {
                labelShowVal = true;
              }
            }
          });

          if (labelShowVal && labelShowCat) {
            value = `${dLbls.serieValue}${value}${parsedVal}`;
          } else if (labelShowVal) {
            value = `${value}${parsedVal}`;
          } else if (labelShowCat) {
            value = `${parsedCat}${value}`;
          }

          return value;
        } else {
          showVal = label.showVal;
          showCatName = label.showCatName;
        }
      }
    }
    switch (formatCode) {
      case 'General': {
        if (!isNaN(+parsedVal)) {
          parsedVal = `${Math.round(+parsedVal * 10) / 10}`;
        }

        if (!isNaN(+parsedCat)) {
          parsedCat = `${Math.round(+parsedCat * 10) / 10}`;
        }

        break;
      }
      default: {
        //TODO:CHARTS proccess formatCode

        break;
      }
    }

    if (showVal && showCatName && showSerName) {
      value = `${dLbls.serieValue}${separator}\n${parsedCat}${separator}\n ${parsedVal}`;
    } else if (showSerName && showCatName) {
      value = `${dLbls.serieValue}${separator}\n${parsedCat}`;
    } else if (showVal && showCatName) {
      value = `${parsedCat}${separator} ${parsedVal}`;
    } else if (showVal) {
      value = `${parsedVal}`;
    } else if (showCatName) {
      value = `${parsedCat}`;
    }

    return value;
  };

  const dataLabel = ({
    dLbls,
    idx,
    val,
    cat,
    defaultPosition,
  }: Parameters<typeof dataLabelStyle>[0] &
    Parameters<typeof dataLabelContent>[0]): echarts.LineSeriesOption['label'] => {
    let show = dLbls?.showVal || dLbls?.showCatName || dLbls?.showLegendKey;
    const style = dataLabelStyle({ dLbls, idx, defaultPosition });
    const content = dataLabelContent({ dLbls: dLbls, idx, val, cat });

    if (dLbls?.dLbl?.length) {
      const label = dLbls?.dLbl.find((lbl) => lbl.idx === idx);
      if (label) {
        show = label.showVal || label.showCatName || label.showLegendKey;
      }
    }

    return {
      show,
      ...style,
      formatter: (_) => {
        return content;
      },
    };
  };

  return { dataLabel };
};

export default useDataLabel;
