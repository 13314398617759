import { JsonRange } from 'Editor/services/_Common/Selection';
import { NodeDataBuilder } from 'Editor/services/DataManager';
import { InsertBlockCommand } from '../GenericCommands';
import { ELEMENTS } from 'Editor/services/consts';
import { TableCellElement } from 'Editor/services/VisualizerManager';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM/EditorDOMUtils';

export class InsertTableCommand extends InsertBlockCommand {
  rows: number;
  columns: number;

  constructor(context: Editor.Edition.ICommandArgs, rows: number, columns: number) {
    super(context, { pathAfterInsert: 'START' });

    this.rows = rows;
    this.columns = columns;
  }

  async exec(): Promise<Editor.Edition.ICommand> {
    if (!this.context.DataManager || !this.context.DataManager.selection) {
      return this;
    }

    const rangeData = this.context.DataManager.selection.current;
    const jsonRange = rangeData[0] ? JsonRange.buildFromRangeData(rangeData[0]) : null;

    if (!jsonRange) {
      return this;
    }

    const baseModel = this.context.DataManager.nodes.getNodeModelById(jsonRange.start.b);
    const baseData = baseModel?.selectedData();

    if (!baseModel || !baseData || !baseData.id) {
      return this;
    }

    let tableWidth: number = 0;

    let range = jsonRange.serializeToDOMRange();

    const closestCell = EditorDOMUtils.closest(range.startContainer, [
      ELEMENTS.TableCellElement.TAG,
    ]) as TableCellElement;

    if (closestCell) {
      tableWidth =
        EditorDOMUtils.convertUnitTo(getComputedStyle(closestCell).width, 'px', 'pt') || 0;
      tableWidth -= 5;
    } else {
      tableWidth = this.context.DataManager?.sections.getPageWidthForBlockId(baseData.id);
    }

    if (tableWidth != null) {
      const headerRow = !!this.context.DataManager.templates.getHeaderRow();
      const table = NodeDataBuilder.buildTable({
        headerRow: headerRow,
        tableWidth: tableWidth,
        rows: this.rows,
        cells: this.columns,
      });

      if (table && table.id) {
        super.exec(table);
      }
    }

    return this;
  }
}
