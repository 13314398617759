import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import ReactDOM from 'react-dom';
import { OnboardingIntegrationCards, Pulse, Box } from 'dodoc-design-system';

import type {
  OnBoardingCarBoxProps,
  OnboardingHandler,
  OnBoardingCardPulse,
} from 'dodoc-design-system/build/types/Components/OnboardingIntegrationCards/OnboardingIntegrationCards';

import { OnboardingOverlay } from '_common/components';
import { useDispatch, useSelector } from '_common/hooks';

import useCards from './useCards';
import {
  PresentationActions,
  activateOnboarding,
  completeAction,
  completeActionList,
  resetAction,
  setCurrentDocument,
  setInteractions,
  setPulseData,
  stopOnboarding,
} from 'App/redux/onboardingSlice';
import { useActiveComments, useActiveTasks, useSlideId } from 'Presentation/hooks';
import { useGetCurrentUserQuery } from '_common/services/api/authority';
import { selectCard, setCreating } from 'Presentation/PresentationSlice';
import { useOnboardingStatusMutation } from 'App/redux/onboardingApi';
import { usePresentationManager } from 'Presentation/PresentationManager';

type OnboardingCardsProps = {
  skipping: boolean;
  setSkipping: (payload: boolean) => void;
};

const OnboardingCards = ({ skipping, setSkipping }: OnboardingCardsProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const manager = usePresentationManager();

  const [sendOnboardingStatus] = useOnboardingStatusMutation();
  const currentSlideId = useSlideId();
  const slideComments = useActiveComments(currentSlideId);
  const slideTasks = useActiveTasks(currentSlideId);
  const { isSuccess: userLoaded } = useGetCurrentUserQuery();
  const cards = useCards();

  const onboardingRef = useRef<OnboardingHandler>(null);
  const pollInterval = useRef<NodeJS.Timer | null>(null);

  const started = useSelector((state) => state.onboarding.started.presentation);
  const initialPhase = useSelector((state) => state.onboarding.initialPhase.presentation);
  const actionsCompleted = useSelector((state) => state.onboarding.actionsCompleted);
  const originalDoc = useSelector((state) => state.onboarding.currentDocument.presentation);
  const currentPresentationId = useSelector((state) => state.presentation.general.presentationId);
  const pulseData = useSelector((state) => state.onboarding.pulseData);
  const selectedShape = useSelector((state) => state.presentation.general.selectedShape);

  const [initialCardIndex, setInitialCardIndex] = useState<number>();
  const [activeCard, setActiveCard] = useState(cards[initialCardIndex ?? 0]);
  const [furthestCard, setFurthestCard] = useState<number>(0);
  const [pulsePosition, setPulsePosition] = useState<{
    position: OnBoardingCardPulse;
    boundary: HTMLElement | null | undefined;
  }>();
  const [boxPosition, setBoxPosition] = useState<{
    position: OnBoardingCarBoxProps;
    boundary: HTMLElement | null;
  }>();

  //#region Utils methods
  const toggleAnnotationReplyList = (commentId: string, open: boolean) => {
    const intervalId = setInterval(() => {
      const commentCardReplyToggle = document.getElementById(
        `comment-card-${commentId}-reply-toggle`,
      );

      if (commentCardReplyToggle) {
        const toggleState = commentCardReplyToggle.getAttribute('data-value');
        if (open) {
          if (!toggleState || toggleState === 'false') {
            commentCardReplyToggle.click();
          }
        } else {
          if (toggleState === 'true') {
            commentCardReplyToggle.click();
          }
        }
        clearInterval(intervalId);
      }
    }, 100);
  };

  const pollElementById = (
    elementId: string,
    elementFoundCallback: (element: HTMLElement) => void,
    interval: number = 100,
  ) => {
    if (pollInterval.current) {
      clearInterval(pollInterval.current);
    }

    pollInterval.current = setInterval(() => {
      console.log('yo');
      const element = document.getElementById(elementId);
      if (element) {
        elementFoundCallback(element);
        clearInterval(pollInterval.current!);
      }
    }, interval);
  };
  //#endregion

  //#region Cards effects
  useEffect(() => {
    if (!pulseData.commentId && slideComments.length) {
      dispatch(setPulseData({ commentId: slideComments[0].id }));
    }
    if (!pulseData.taskId && slideTasks.length) {
      dispatch(setPulseData({ taskId: slideTasks[0].id }));
    }
  }, [pulseData, slideComments, slideTasks]);

  /**
   * Continue previous state after refresh
   * Will auto-complete actions of previous cards
   * Will execute callbacks of previous cards in order to update the view
   */
  useEffect(() => {
    if (!userLoaded) {
      return;
    }

    /**
     * Shouldn't be necessary but added as a safeguard.
     *
     * Ignore initialPhase behaviour in the following states
     */
    const inactiveStates = ['beginning', 'ending'];
    if (initialPhase && !inactiveStates.includes(initialPhase)) {
      const initialCardIndex = cards.findIndex((card) => card.id === initialPhase);
      if (initialCardIndex > 0) {
        setActiveCard(cards[initialCardIndex]);
        setInitialCardIndex(initialCardIndex);

        const completedActions = cards
          .slice(0, initialCardIndex)
          .reduce<PresentationActions[]>((allActions, card) => {
            if (card.deps) {
              allActions = [...allActions, ...card.deps];
            }

            return allActions;
          }, []);

        dispatch(completeActionList(completedActions));

        for (let i = initialCardIndex; i >= 0; i--) {
          const card = cards[i];
          if (card.onSkip) {
            card.onSkip();
            break;
          }
        }
      }
    }
  }, [userLoaded]);

  //Enable interactions defined in the new active card
  useEffect(() => {
    dispatch(setInteractions(activeCard.interactions ?? []));
  }, [activeCard]);

  //Auto next
  useEffect(() => {
    if (activeCard.deps?.length && activeCard.deps.every((dep) => actionsCompleted[dep])) {
      onboardingRef.current?.next();
    }
  }, [actionsCompleted]);

  //Cards' box
  useEffect(() => {
    if (activeCard?.boxTarget) {
      activeCard.boxTarget?.forEach(async (target) => {
        switch (target) {
          case 'toolbar': {
            const target = document.getElementById('presentation-toolbar');
            if (target) {
              const rect = target.getBoundingClientRect();
              setBoxPosition({
                position: {
                  top: `4px`,
                  left: `4px`,
                  width: `calc(100vw - 1rem)`,
                  height: `${rect.height - 8}px`,
                },
                boundary: target,
              });
            }
            break;
          }

          case 'toolbar_comment':
          case 'toolbar_task': {
            let element: HTMLElement | null = null;
            if (target === 'toolbar_comment') {
              element = document.getElementById('presentation-toolbar-comment');
            } else {
              element = document.getElementById('presentation-toolbar-task');
            }
            const parent = document.getElementById('presentation-toolbar');

            if (parent && element) {
              const toolbarRect = parent.getBoundingClientRect();
              const targetRect = element.getBoundingClientRect();
              const topPadding = targetRect.y - toolbarRect.y;

              setBoxPosition({
                position: {
                  top: `-${topPadding - 4}px`,
                  left: `-4px`,
                  width: `${targetRect.width + 8}px`,
                  height: `${targetRect.height + topPadding}px`,
                },
                boundary: element,
              });
            }
            break;
          }

          case 'footer': {
            const target = document.getElementById('presentation-footer');
            if (target) {
              const rect = target.getBoundingClientRect();
              setBoxPosition({
                position: {
                  top: `8px`,
                  left: `6px`,
                  width: `calc(100vw - 12px)`,
                  height: `${rect.height - 16}px`,
                },
                boundary: target,
              });
            }
            break;
          }

          case 'footer_notes': {
            const parent = document.getElementById('presentation-footer');
            const target = document.getElementById('presentation-footer-notes-toggle');
            if (target && parent) {
              const parentRect = parent.getBoundingClientRect();
              const targetRect = target.getBoundingClientRect();
              const topPadding = targetRect.y - parentRect.y;
              const leftPadding = targetRect.x - parentRect.x;

              setBoxPosition({
                position: {
                  top: `${topPadding}px`,
                  left: `${leftPadding}px`,
                  width: `${targetRect.width}px`,
                  height: `${targetRect.height}px`,
                },
                boundary: target,
              });
            }
            break;
          }
          default: {
            setBoxPosition(undefined);
            break;
          }
        }
      });
    } else {
      clearInterval(pollInterval.current!);
      setBoxPosition(undefined);
    }
  }, [activeCard]);

  //Cards' pulses
  useEffect(() => {
    const processPulse = () => {
      if (activeCard?.pulseTarget) {
        const pointerRadius = 12;
        const pointerDiameter = pointerRadius * 2;

        activeCard.pulseTarget?.forEach(async (target) => {
          switch (target) {
            case 'commentReply': {
              const { commentId, annotationRepliesToggled } = pulseData;

              const commentCard = document.getElementById(`comment-card-${commentId}`);

              if (!commentCard) {
                setPulsePosition(undefined);
                break;
              }
              const replyToggle = document.getElementById(`comment-card-${commentId}-reply-toggle`);

              if (replyToggle) {
                if (
                  !annotationRepliesToggled &&
                  !actionsCompleted.presentation_comments_openReplies
                ) {
                  const rect = {
                    top: replyToggle.offsetTop,
                    left: replyToggle.offsetLeft,
                    height: replyToggle.offsetHeight,
                    width: replyToggle.offsetWidth,
                  };

                  setPulsePosition({
                    position: {
                      top: `${Math.ceil(
                        rect.top + rect.height - pointerDiameter / 3, //One third of pointer diameter,
                      )}px`,
                      left: `${Math.ceil(rect.left + rect.width / 2 - pointerRadius)}px`,
                    },
                    boundary: commentCard,
                  });
                } else {
                  setPulsePosition(undefined);
                }
              } else {
                setPulsePosition(undefined);
              }

              break;
            }

            case 'toolbar_comment': {
              const { commentId, annotationCardCtaRect } = pulseData;
              if (!annotationCardCtaRect && !actionsCompleted.presentation_comments_openComment) {
                if (!commentId) {
                  const target = document.getElementById('presentation-toolbar-comment');
                  if (target) {
                    const targetRect = target.getBoundingClientRect();
                    setPulsePosition({
                      position: {
                        top: `${targetRect.height - pointerRadius / 2}px`,
                        left: `${targetRect.width / 2 - pointerRadius}px`,
                      },
                      boundary: target,
                    });
                  }
                }
              }
              break;
            }

            case 'contextMenu_task': {
              const { contextMenuIsOpen } = pulseData;

              if (contextMenuIsOpen) {
                const range = manager.getRange();

                if (selectedShape || !range?.collapsed) {
                  pollElementById('slide-context-menu', (contextMenu) => {
                    const editAnnotationItem = document.getElementById(
                      'slide-contextMenu-new-task-item',
                    );

                    if (editAnnotationItem) {
                      const rect = {
                        top: editAnnotationItem.offsetTop,
                        left: editAnnotationItem.offsetLeft,
                        height: editAnnotationItem.offsetHeight,
                        width: editAnnotationItem.offsetWidth,
                      };

                      setPulsePosition({
                        position: {
                          top: `${Math.ceil(rect.top + rect.height / 2 - 12)}px`,
                          left: `${Math.ceil(rect.width - 12)}px`,
                        },
                        boundary: contextMenu,
                      });
                    }
                  });

                  dispatch(completeAction('presentation_tasks_selection'));
                } else {
                  dispatch(resetAction('presentation_tasks_selection'));
                }
              }
              break;
            }

            case 'taskDescription':
            case 'taskAssigned':
            case 'taskDueDate': {
              const temporaryTaskCard = document.getElementById('editable-task-card-new');

              if (temporaryTaskCard) {
                const descriptionElement = document.getElementById(
                  'editable-task-card-description',
                );
                const assignedToElement = document.getElementById('editable-task-card-assignee');
                const dueDateElement = document.getElementById('editable-task-card-dueDate');
                const ctaButtonElement = document.getElementById('editable-task-card-ctaButton');

                if (descriptionElement && assignedToElement && dueDateElement && ctaButtonElement) {
                  const { taskHasAssignee, taskHasDescription, taskHasDueDate } = pulseData;
                  let rect = { top: 0, left: 0, height: 0, width: 0 };

                  if (!taskHasDescription) {
                    rect = {
                      top: descriptionElement.offsetTop,
                      left: descriptionElement.offsetLeft,
                      height: descriptionElement.offsetHeight,
                      width: descriptionElement.offsetWidth,
                    };
                  } else if (!taskHasAssignee) {
                    rect = {
                      top: assignedToElement.offsetTop,
                      left: assignedToElement.offsetLeft,
                      height: assignedToElement.offsetHeight,
                      width: assignedToElement.offsetWidth,
                    };
                  } else if (!taskHasDueDate) {
                    rect = {
                      top: dueDateElement.offsetTop,
                      left: dueDateElement.offsetLeft,
                      height: dueDateElement.offsetHeight,
                      width: dueDateElement.offsetWidth,
                    };
                  } else {
                    rect = {
                      top: ctaButtonElement.offsetTop,
                      left: ctaButtonElement.offsetLeft,
                      height: ctaButtonElement.offsetHeight,
                      width: ctaButtonElement.offsetWidth,
                    };
                  }

                  if (!taskHasDescription || !taskHasAssignee || !taskHasDueDate) {
                    setPulsePosition({
                      position: {
                        top: `${Math.ceil(
                          rect.top +
                            rect.height -
                            (pointerDiameter / 3) * 2 /*Two thirds of pointer diameter */,
                        )}px`,
                        left: `${Math.ceil(
                          rect.left + rect.width - pointerDiameter - 4 /*Right padding*/,
                        )}px`,
                      },
                      boundary: temporaryTaskCard,
                    });
                  } else {
                    setPulsePosition({
                      position: {
                        top: `${Math.ceil(
                          rect.top + rect.height / 2 + pointerDiameter / 3, //One third of pointer diameter
                        )}px`,
                        left: `${Math.ceil(rect.left - pointerRadius + rect.width / 2)}px`,
                      },
                      boundary: temporaryTaskCard,
                    });
                  }
                }
              }

              break;
            }

            default: {
              setPulsePosition(undefined);
              break;
            }
          }
        });
      } else {
        setPulsePosition(undefined);
      }
    };

    processPulse();
  }, [activeCard, pulseData, actionsCompleted, selectedShape]);

  //Effects to always happen with the active card (also happen when refresh)
  useEffect(() => {
    switch (activeCard.id) {
      case 'comment_completed': {
        const { commentId } = pulseData;
        if (commentId) {
          dispatch(selectCard(commentId));
          toggleAnnotationReplyList(commentId, true);
        }
        break;
      }
      case 'comment_save': {
        if (!actionsCompleted.presentation_comments_createComment) {
          dispatch(setCreating({ slide: true, type: 'comment' }));
        } else {
          const { commentId } = pulseData;
          if (commentId) {
            dispatch(selectCard(commentId));
            toggleAnnotationReplyList(commentId, false);
          }
        }
        break;
      }
      case 'comment_replyMention': {
        const { commentId } = pulseData;
        if (commentId) {
          dispatch(selectCard(commentId));
          if (actionsCompleted.presentation_comments_mentionInCommentReply) {
            toggleAnnotationReplyList(commentId, true);
          }
        }
        break;
      }
      case 'task_fillTask': {
        if (!actionsCompleted.presentation_tasks_createTask) {
          dispatch(resetAction('presentation_tasks_openTask'));
        } else {
          const { taskId } = pulseData;
          if (taskId) {
            dispatch(selectCard(taskId));
          }
        }
        break;
      }
      case 'task_completed': {
        const { taskId } = pulseData;
        if (taskId) {
          dispatch(selectCard(taskId));
        }
        break;
      }
    }
  }, [activeCard, pulseData, actionsCompleted]);

  //#endregion

  //#region Cards props
  const skipProps = useMemo(() => {
    return {
      skipPreviousLabel: intl.formatMessage({ id: 'NO_CONTINUE_ONBOARDING' }),
      skipFinishLabel: intl.formatMessage({ id: 'YES_SKIP' }),
      skipHeader: intl.formatMessage({ id: 'SKIP_THE_ONBOARDING_QUESTION' }),
      skipContent: intl.formatMessage({ id: 'ONBOARDING_PDF_SKIP_CONFIRMATION' }),
    };
  }, [intl]);

  const exitOnboarding = (skip?: boolean) => {
    const id = !originalDoc?.id || originalDoc.id === '' ? currentPresentationId : originalDoc.id;
    sendOnboardingStatus({
      target: 'presentation',
      step: 'ending',
      skip: !!skip,
      journey: undefined,
    });
    setSkipping(false);
    // setJourney(undefined);
    setActiveCard(cards[0]);
    dispatch(stopOnboarding('presentation'));
    dispatch(setCurrentDocument({ target: 'presentation' }));
    window.open(`/presentation/${id}`, '_self');
  };

  const handleNext = (newActiveCard: number) => {
    clearInterval(pollInterval.current!);
    if (newActiveCard > furthestCard) {
      cards[newActiveCard].onFirstRender?.();
    }
    cards[newActiveCard].before?.();

    if (cards[newActiveCard].id !== 'ending') {
      if (newActiveCard > furthestCard) {
        setFurthestCard(newActiveCard);
      }

      sendOnboardingStatus({
        target: 'presentation',
        step: cards[newActiveCard].id,
      });
    }
    setActiveCard(cards[newActiveCard]);
  };

  const handleBack = (newActiveCard: number) => {
    clearInterval(pollInterval.current!);
    cards[newActiveCard].before?.();

    if (activeCard.id === 'beginning') {
      dispatch(stopOnboarding('presentation'));
      dispatch(activateOnboarding('presentation'));
    } else {
      setActiveCard(cards[newActiveCard]);
    }
  };

  const handleSkip = () => {
    exitOnboarding(true);
  };

  const handleEnding = () => {
    exitOnboarding();
  };

  const onCancelSkip = () => {
    setSkipping(false);
  };

  //#endregion

  return (
    <OnboardingOverlay environment="presentation">
      <div style={skipping || started ? undefined : { display: 'none' }}>
        <OnboardingIntegrationCards
          ref={onboardingRef}
          title={intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' })}
          nextDisabled={
            activeCard.deps
              ? activeCard.deps?.some((dep) => !actionsCompleted[dep]) ?? false
              : false
          }
          nextDisabledTooltip={intl.formatMessage({ id: 'ONBOARDING_PENDING_ACTION_TOOLTIP' })}
          tip={intl.formatMessage({ id: 'TIP_COLON' })}
          previousLabel={intl.formatMessage({ id: 'BACK' })}
          next={intl.formatMessage({ id: 'NEXT' })}
          onNext={handleNext}
          onBack={handleBack}
          skipButtonLabel={intl.formatMessage({ id: 'SKIP_ONBOARDING' })}
          cards={cards}
          phases={{
            comments: {
              order: 1,
              label: intl.formatMessage({ id: 'COMMENTS' }),
            },
            tasks: { order: 2, label: intl.formatMessage({ id: 'TASKS' }) },
            notes: { order: 3, label: intl.formatMessage({ id: 'NOTES_PANEL_TITLE' }) },
          }}
          onExit={handleSkip}
          onFinalExit={handleEnding}
          {...skipProps}
          size="medium"
          onCancel={() => {}}
          skip={skipping}
          cancelSkip={skipping ? onCancelSkip : undefined}
          initialCard={initialCardIndex}
          pulse={
            pulsePosition?.position &&
            (pulsePosition.boundary ? (
              ReactDOM.createPortal(
                <Pulse position={pulsePosition.position} testId="presentation" />,
                pulsePosition.boundary,
              )
            ) : (
              <Pulse position={pulsePosition.position} testId="presentation" />
            ))
          }
          box={
            boxPosition?.position &&
            (boxPosition.boundary ? (
              ReactDOM.createPortal(
                <Box box={boxPosition.position} testId="presentation" />,
                boxPosition.boundary,
              )
            ) : (
              <Box box={boxPosition.position} testId="presentation" />
            ))
          }
          testId="presentation"
        />
      </div>
    </OnboardingOverlay>
  );
};

export default OnboardingCards;
