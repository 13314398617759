import { Command } from '../Command';
import { JsonRange, PathUtils, SelectionFixer } from 'Editor/services/_Common/Selection';
import { ReduxInterface } from 'Editor/services';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { NodeUtils } from 'Editor/services/DataManager';

export class AddTemporaryCommentCommand extends Command {
  async exec(): Promise<Editor.Edition.ICommand> {
    try {
      let allowCreateComment = true;

      if (
        !this.context.DataManager ||
        !this.context.DataManager.selection ||
        !this.context.selection?.modifiers
      ) {
        return this;
      }

      const rangeData = this.context.DataManager.selection.current;
      const jsonRange = JsonRange.buildFromRangeData(rangeData[0]);

      const baseModel = this.context.DataManager.nodes.getNodeModelById(jsonRange.start.b);
      const baseData = baseModel?.selectedData();

      if (!baseModel || !baseData || !baseData.id) {
        return this;
      }

      allowCreateComment = this.context.DataManager?.permissions.canUserPerform(
        baseData.id,
        'comment',
      );

      if (jsonRange.collapsed) {
        this.context.selection.modifiers.modify(jsonRange, 'move', 'word', 'backward');
        this.context.selection.modifiers.modify(jsonRange, 'expand', 'word', 'forward');
      } else {
        // fix non collapse selection
        SelectionFixer.nonCollapsedTextSelection(jsonRange, {}, this.context.DataManager);
      }

      // get blocks from range

      if (allowCreateComment) {
        const tempCommentReference = EditorDOMUtils.generateUUID();

        const jsonRanges: Editor.Selection.RangeData[] = JsonRange.splitRangeByTypes(
          this.context.DataManager,
          jsonRange,
          NodeUtils.BLOCK_TEXT_TYPES,
        );

        if (jsonRanges.length) {
          await this.context.DataManager?.comments?.addTemporaryComment(
            tempCommentReference,
            jsonRanges,
          );

          const lastRange = jsonRanges[jsonRanges.length - 1];

          setTimeout(() => {
            const blockModel = this.context.DataManager?.nodes.getNodeModelById(lastRange.end.b);
            const baseData = blockModel?.selectedData();

            if (!blockModel || !baseData) {
              return this;
            }

            let commentsData = NodeUtils.querySelectorInData(baseData, 'comment');
            let lastComment;
            for (let i = commentsData.length - 1; i >= 0; i--) {
              if (commentsData[i].data.properties?.element_reference === tempCommentReference) {
                lastComment = commentsData[i];
              }
            }

            if (lastComment) {
              let path = [...lastComment.path];
              path.push('childNodes');
              path.push(lastComment.data.childNodes?.length || 0);

              if (PathUtils.isValidSelectionPath(path)) {
                const jsonRange = new JsonRange({ b: blockModel.id, p: path });

                // apply new selection
                if (this.context.DataManager?.selection) {
                  this.context.DataManager.selection.setUserSelection([
                    jsonRange.serializeToRangeData(),
                  ]);
                }
              }
            }
          }, 0);
        }

        ReduxInterface.openTemporaryCommentCard({
          reference: tempCommentReference,
          level0: baseData.id,
        });
      } else {
        throw new Error('User has no permissions to comment selection');
      }
    } catch (error) {
      logger.captureException(error);
      throw error;
    }
    return this;
  }
}
