import { Logger } from '_common/services';
import { Command } from '../Command';
import { JsonRange, SelectionFixer } from 'Editor/services/_Common/Selection';
import { ActionContext } from '../../ActionContext';
import { NodeUtils } from 'Editor/services/DataManager';

export class InsertInlineCommand extends Command {
  // constructor(context: Editor.Edition.ICommandArgs) {
  //   super(context);
  //   // this.elementData = elementData;
  // }

  protected handleTextElement(
    ctx: Editor.Edition.ActionContext,
    elementToInsert: Editor.Data.Node.Data,
    options: Editor.Edition.InsertElementOptions = {},
  ): boolean {
    if (!ctx.baseModel || !ctx.baseData) {
      return false;
    }

    // TODO: some of this code needs to be moved to manipulator

    // normalize text selection
    SelectionFixer.collapsedTextSelection(
      ctx.range,
      {
        forceTextAsWrap: !!options.forceTextAsWrap,
        suggestionMode: this.context.editionMode === 'SUGGESTIONS',
      },
      this.context.DataManager,
    );

    let pathToInsert: Editor.Selection.Path = ctx.range.start.p;
    let pathToUpdate: Editor.Selection.Path | null = null;

    let parentChildData = NodeUtils.getParentChildInfoByPath(ctx.baseData, pathToInsert);

    if (!parentChildData?.parentData) {
      return false;
    }

    let isAtStart =
      parentChildData.contentIndex === 0 ||
      (parentChildData.childIndex === 0 && isNaN(parentChildData.contentIndex));

    let isAtEnd =
      (NodeUtils.isTextData(parentChildData.childData) &&
        parentChildData.contentIndex === parentChildData.childData.content.length) ||
      (parentChildData.parentData.childNodes &&
        parentChildData.childIndex === parentChildData.parentData.childNodes.length &&
        isNaN(parentChildData.contentIndex));

    if (NodeUtils.isCitationData(elementToInsert)) {
      // adjust path for citations
      let closestAncestor = NodeUtils.closestOfTypeByPath(ctx.baseData, ctx.range.start.p, [
        'citations-group',
      ]);

      if (!closestAncestor && (isAtStart || isAtEnd)) {
        closestAncestor = NodeUtils.closestSiblingAncestorOfType(ctx.baseData, ctx.range.start.p, [
          'citations-group',
        ]);
      }

      if (closestAncestor && NodeUtils.isCitationsGroupData(closestAncestor.data)) {
        pathToInsert = [
          ...closestAncestor.path,
          'childNodes',
          closestAncestor.data.childNodes?.length || 0,
        ];

        pathToUpdate = closestAncestor.path;
        let childOffset = Number(pathToUpdate[pathToUpdate.length - 1]);
        if (!isNaN(childOffset)) {
          pathToUpdate[pathToUpdate.length - 1] = childOffset + 1;
        }
      }
    }

    parentChildData = NodeUtils.getParentChildInfoByPath(ctx.baseData, pathToInsert);

    if (!parentChildData?.parentData) {
      return false;
    }

    // is insertion allowed and element restrictions
    if (
      !NodeUtils.isAllowedUnder(parentChildData.parentData.type, elementToInsert.type) ||
      NodeUtils.isRestrictedUnder(ctx.baseData.type, elementToInsert.type)
    ) {
      throw new Error('Element insertion not allowed!!');
    }

    if (this.context.contentManipulator) {
      let insertContentOptions: Editor.Edition.InsertContentOptions = {};

      if (options.forceBlockSplit) {
        // force block split
        if (!this.context.contentManipulator.splitBlockContent(ctx, pathToInsert)) {
          return false;
        }
      } else if (options.forceInlineSplit) {
        // force inline styles split
        let closestText = NodeUtils.closestOfTypeByPath(
          ctx.baseData,
          ctx.range.start.p,
          NodeUtils.BLOCK_TEXT_TYPES,
        );

        if (closestText) {
          pathToInsert = this.context.contentManipulator.splitInlineContent(
            ctx.baseModel,
            closestText.data,
            closestText.path,
            ctx.range.start.p,
          );
        }
      }

      if (
        this.context.contentManipulator.insertContent(
          ctx,
          pathToInsert,
          elementToInsert,
          insertContentOptions,
        )
      ) {
        if (pathToUpdate) {
          ctx.range.updateRangePositions({
            b: ctx.range.start.b,
            p: pathToUpdate,
          });
        }
        return true;
      }
    }

    return false;
  }

  protected handleCollapsedSelection(
    ctx: Editor.Edition.ActionContext,
    elementToInsert: Editor.Data.Node.Data,
    options: Editor.Edition.InsertElementOptions = {},
  ): boolean {
    if (!this.context.DataManager) {
      return false;
    }

    const baseModel = this.context.DataManager.nodes.getNodeModelById(ctx.range.start.b);

    const baseData = baseModel?.selectedData();
    if (!baseModel || !baseData) {
      return false;
    }

    // check if element is editable
    if (!this.context.DataManager.nodes.isNodeEditable(baseModel.id)) {
      return false;
    }

    ctx.setModelAndData(baseModel, baseData);

    const result = NodeUtils.closestOfTypeByPath(baseData, ctx.range.start.p, [
      ...NodeUtils.BLOCK_EDITABLE_TYPES,
    ]);

    if (result && NodeUtils.isBlockTextData(result.data)) {
      // text elements

      return this.handleTextElement(ctx, elementToInsert, options);
    }

    return false;
  }

  protected handleNonCollapsedSelection(ctx: Editor.Edition.ActionContext): boolean {
    SelectionFixer.nonCollapsedTextSelection(
      ctx.range,
      {
        suggestionMode: this.context.editionMode === 'SUGGESTIONS',
      },
      this.context.DataManager,
    );

    // remove content
    if (this.context.contentManipulator) {
      return this.context.contentManipulator.removeContent(ctx);
    }

    return false;
  }

  async exec(
    elementToInsert?: Editor.Data.Node.Data,
    options: Editor.Edition.InsertElementOptions = {},
    ctx?: Editor.Edition.ActionContext,
  ): Promise<Editor.Edition.ICommand> {
    if (this.debug) {
      Logger.trace('InsertInlineCommand exec', this);
    }

    if (!this.context.DataManager || !this.context.DataManager.selection || !elementToInsert) {
      return this;
    }

    if (!ctx) {
      const rangeData = this.context.DataManager.selection.current;
      const jsonRange = JsonRange.buildFromRangeData(rangeData[0]);

      if (!jsonRange) {
        return this;
      }

      ctx = new ActionContext(jsonRange);
    }

    // handle non collapsed selection
    if (!ctx.range.collapsed) {
      if (!this.handleNonCollapsedSelection(ctx)) {
        return this;
      }
    }

    // handle collapsed selection
    if (ctx.range.collapsed) {
      if (!this.handleCollapsedSelection(ctx, elementToInsert, options)) {
        return this;
      }
    }

    // handle create suggestions???
    this.handleSuggestionsUpdate(ctx);

    // apply new selection
    if (this.context.DataManager?.selection) {
      // TEMP: flag last selection
      this.context.DataManager.selection.history.flag('debounce');
      this.context.DataManager.selection.setUserSelection([ctx.range.serializeToRangeData()]);
    }

    // create patch
    if (!ctx.avoidCreatePatch) {
      this.context.DataManager?.history.createPatch();
    }

    return this;
  }
}
