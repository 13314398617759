import { Command } from '../Command';
import { Descendant } from 'slate';
import { ReduxInterface } from 'Editor/services';
import { JsonRange, PathUtils } from 'Editor/services/_Common/Selection';
import { NodeUtils } from 'Editor/services/DataManager';

export class AddCommentCommand extends Command {
  protected comment: Descendant[];

  constructor(context: Editor.Edition.ICommandArgs, comment: Descendant[]) {
    super(context);

    this.comment = comment;
  }

  async exec(): Promise<Editor.Edition.ICommand> {
    if (
      !this.context.DataManager ||
      !this.context.DataManager.comments ||
      !this.context.DataManager.selection
    ) {
      return this;
    }

    try {
      const tempCommentData = ReduxInterface.getEditorState().comments.insert;

      const result: any = await this.context.DataManager.comments.addComment(
        this.comment,
        tempCommentData.reference,
      );

      ReduxInterface.cancelTemporaryComment();

      setTimeout(() => {
        if (!tempCommentData.level0) {
          return this;
        }
        const blockModel = this.context.DataManager?.nodes.getNodeModelById(tempCommentData.level0);
        const baseData = blockModel?.selectedData();

        if (!blockModel || !baseData) {
          return this;
        }

        let commentsData = NodeUtils.querySelectorInData(baseData, 'comment');
        let lastComment;
        for (let i = commentsData.length - 1; i >= 0; i--) {
          if (commentsData[i].data.properties?.element_reference === result.id) {
            lastComment = commentsData[i];
          }
        }

        if (lastComment) {
          let path = [...lastComment.path];
          path.push('childNodes');
          path.push(lastComment.data.childNodes?.length || 0);

          if (PathUtils.isValidSelectionPath(path)) {
            const jsonRange = new JsonRange({ b: blockModel.id, p: path });

            // apply new selection
            if (this.context.DataManager?.selection) {
              this.context.DataManager.selection.setUserSelection([
                jsonRange.serializeToRangeData(),
              ]);
            }
          }
        }
      }, 0);
    } catch (error) {
      ReduxInterface.cancelTemporaryComment();
      throw error;
    }
    return this;
  }
}
