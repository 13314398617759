import { useState } from 'react';

import EditorManager from 'Editor/services/EditorManager/EditorManager';

import { InteractionController } from '_common/components';
import { Footer } from '_common/suite/components';

import Language from './Language/Language';
import Zoom from './Zoom/Zoom';
import Divider from './Divider/Divider';
import Layout from './Layout/Layout';
import WordCount from './WordCount/WordCount';

const EditorFooter = () => {
  // TODO: TEMP button state
  const [version, setVersion] = useState(EditorManager.getInstance().getEditionVersion());

  const onClick = () => {
    switch (version) {
      case 1:
        EditorManager.getInstance().setEditionVersion(2);
        setVersion(2);
        break;
      case 2:
        EditorManager.getInstance().setEditionVersion(1);
        setVersion(1);
        break;
      default:
        EditorManager.getInstance().setEditionVersion(1);
        setVersion(1);
        break;
    }
  };

  //TODO temp button
  return (
    <>
      <Footer.Left>
        <InteractionController environment="editor">
          <WordCount />
          <Language />
        </InteractionController>
      </Footer.Left>
      <Footer.Right>
        <button
          style={{
            background: '#cd4b87',
            color: 'white',
            borderColor: 'white',
            borderRadius: '5px',
            marginRight: '10px',
          }}
          onClick={onClick}
        >
          Change Edition ({version})
        </button>
        <InteractionController environment="editor">
          <Layout />
          <Divider />
        </InteractionController>
        <Zoom />
      </Footer.Right>
    </>
  );
};

export default EditorFooter;
