import { JsonRange, SelectionFixer } from 'Editor/services/_Common/Selection';
import { Command } from '../Command';
import { ActionContext } from '../../ActionContext';
import { NodeDataBuilder, NodeUtils } from 'Editor/services/DataManager';
import { ELEMENTS } from 'Editor/services/consts';

export class InsertCaptionCommand extends Command {
  private options: Editor.Edition.CaptionCommandOptions;

  constructor(context: Editor.Edition.ICommandArgs, options: Editor.Edition.CaptionCommandOptions) {
    super(context);
    this.options = options;
  }

  private handleInsertCaptionInText(
    ctx: Editor.Edition.ActionContext,
    closestText: Editor.Data.Node.DataPathInfo,
    labelStyle: string = ELEMENTS.ParagraphElement.BASE_STYLES.FIGURE_CAPTION,
  ) {
    if (
      !this.context.DataManager ||
      !this.context.DataManager.selection ||
      !this.context.contentManipulator ||
      !ctx.baseModel ||
      !ctx.baseData ||
      !NodeUtils.isBlockTextData(closestText.data)
    ) {
      return false;
    }

    if (!this.context.DataManager.styles.getDocumentStyleFromId(labelStyle)) {
      labelStyle = ELEMENTS.ParagraphElement.BASE_STYLES.PARAGRAPH;
    }

    if (ctx.range.collapsed) {
      const captionElements = NodeDataBuilder.buildCaptionElements(
        this.options.label,
        this.options.chapterNumbering,
        this.options.text,
      );

      // normalize text selection
      SelectionFixer.collapsedTextSelection(
        ctx.range,
        {
          suggestionMode: this.context.editionMode === 'SUGGESTIONS',
        },
        this.context.DataManager,
      );

      for (let i = 0; i < captionElements.length; i++) {
        this.context.contentManipulator.insertContent(ctx, ctx.range.start.p, captionElements[i]);
      }

      ctx.baseModel.set([...closestText.path, 'properties', 's'], labelStyle, {
        source: 'LOCAL_RENDER',
      });
    } else {
      if (this.options.position === 'above') {
        ctx.range.collapseToStart();
      } else {
        ctx.range.collapseToEnd();
      }

      // normalize text selection
      SelectionFixer.nonCollapsedTextSelection(
        ctx.range,
        {
          suggestionMode: this.context.editionMode === 'SUGGESTIONS',
        },
        this.context.DataManager,
      );

      if (this.context.contentManipulator.splitBlockContent(ctx, ctx.range.start.p)) {
        return this.handleInsertCaptionAsBlock(ctx, closestText, labelStyle, 'AFTER');
      }
    }
  }

  private handleInsertCaptionAsBlock(
    ctx: Editor.Edition.ActionContext,
    closestElement: Editor.Data.Node.DataPathInfo,
    labelStyle: string = ELEMENTS.ParagraphElement.BASE_STYLES.FIGURE_CAPTION,
    position: 'BEFORE' | 'AFTER' = 'AFTER',
  ) {
    if (
      !this.context.DataManager ||
      !this.context.DataManager.selection ||
      !this.context.contentManipulator ||
      !ctx.baseData
    ) {
      return false;
    }

    if (!this.context.DataManager.styles.getDocumentStyleFromId(labelStyle)) {
      labelStyle = ELEMENTS.ParagraphElement.BASE_STYLES.PARAGRAPH;
    }

    // path fixes
    if (NodeUtils.isTableData(closestElement.data)) {
      ctx.range.updateRangePositions({
        b: ctx.range.start.b,
        p: [...closestElement.path, 'childNodes', 0],
      });
    } else if (this.options.label === 'Table') {
      const closestTable = NodeUtils.closestOfTypeByPath(ctx.baseData, closestElement.path, [
        'tbl',
      ]);
      if (closestTable) {
        ctx.range.updateRangePositions({
          b: ctx.range.start.b,
          p: [...closestTable.path, 'childNodes', 0],
        });
      }
    }

    const paragraphData = new NodeDataBuilder('p')
      .addProperty('s', labelStyle)
      .setChildNodes(
        NodeDataBuilder.buildCaptionElements(
          this.options.label,
          this.options.chapterNumbering,
          this.options.text,
        ),
      )
      .build();

    if (paragraphData) {
      return this.context.contentManipulator.insertBlock(ctx, paragraphData, position);
    }
  }

  async exec(...args: any[]): Promise<Editor.Edition.ICommand> {
    if (
      !this.context.DataManager ||
      !this.context.DataManager.selection ||
      !this.context.contentManipulator
    ) {
      return this;
    }

    const rangeData = this.context.DataManager.selection.current;
    const jsonRange = JsonRange.buildFromRangeData(rangeData[0]);

    if (!jsonRange) {
      return this;
    }

    const baseModel = this.context.DataManager.nodes.getNodeModelById(jsonRange.start.b);
    const baseData = baseModel?.selectedData();

    if (!baseModel || !baseData) {
      return this;
    }

    // create / update label data
    const captionDefenition = this.context.DataManager.captions.caption(this.options.label);

    const captionData: Partial<Editor.Data.Structure.CaptionData> = {};

    if (this.options.numberingType) {
      captionData.nf = this.options.numberingType;
    }

    if (this.options.chapterNumbering?.chapterType) {
      captionData.c = this.options.chapterNumbering.chapterType;
    }

    if (this.options.chapterNumbering?.separator) {
      captionData.s = this.options.chapterNumbering.separator;
    }

    if (this.options.position) {
      captionData.p = this.options.position;
    }

    const labelStyle =
      captionDefenition.labelStyle || ELEMENTS.ParagraphElement.BASE_STYLES.PARAGRAPH;

    if (!captionDefenition) {
      await this.context.DataManager.captions.createCaption(this.options.label, captionData);
    } else {
      captionData.ls = labelStyle;

      this.context.DataManager.captions.updateCaption(this.options.label, captionData);
    }

    let ctx: Editor.Edition.ActionContext = new ActionContext(jsonRange, baseModel, baseData);

    const closest = NodeUtils.closestOfTypeByPath(baseData, jsonRange.start.p, ['tbl', 'figure']);

    if (closest) {
      this.handleInsertCaptionAsBlock(
        ctx,
        closest,
        labelStyle,
        this.options.position === 'above' ? 'BEFORE' : 'AFTER',
      );
    } else {
      const closestParagraph = NodeUtils.closestOfTypeByPath(baseData, jsonRange.start.p, ['p']);

      if (closestParagraph) {
        this.handleInsertCaptionInText(ctx, closestParagraph, labelStyle);
      }
    }

    // handle create suggestions???
    this.handleSuggestionsUpdate(ctx);

    // apply new selection
    if (this.context.DataManager?.selection) {
      // TEMP: flag last selection
      this.context.DataManager.selection.history.flag('debounce');
      this.context.DataManager.selection.setUserSelection([ctx.range.serializeToRangeData()]);
    }

    // create patch
    this.context.DataManager?.history.createPatch();

    return this;
  }
}
