import { JsonRange } from 'Editor/services/_Common/Selection';
import { NodeUtils } from 'Editor/services/DataManager/models';
import { notify } from '_common/components/ToastSystem';
import { NodeDataBuilder } from 'Editor/services/DataManager';
import { InsertInlineCommand } from '../GenericCommands';

export class InsertCitationCommand extends InsertInlineCommand {
  citationId: string;

  constructor(context: Editor.Edition.ICommandArgs, citationId: string) {
    super(context);

    this.citationId = citationId;
  }

  async exec(): Promise<Editor.Edition.ICommand> {
    if (
      !this.context.DataManager ||
      !this.context.DataManager.selection ||
      !this.context.commandFactory
    ) {
      return this;
    }

    const rangeData = this.context.DataManager.selection.current;
    let jsonRange = JsonRange.buildFromRangeData(rangeData[0]);

    if (!jsonRange) {
      return this;
    }

    const baseModel = this.context.DataManager.nodes.getNodeModelById(jsonRange.start.b);
    const baseData = baseModel?.selectedData();

    if (!baseModel || !baseData) {
      return this;
    }

    const closest = NodeUtils.closestSiblingAncestorOfType(baseData, jsonRange.start.p, [
      'citations-group',
    ]);

    if (
      closest &&
      NodeUtils.checkPropertyValueExists(closest.data, 'element_reference', this.citationId)
    ) {
      notify({
        type: 'warning',
        title: 'global.warning',
        message: 'editor.warnings.insert',
      });
    } else {
      try {
        const citation = NodeDataBuilder.buildData({
          type: 'citation',
          properties: {
            element_reference: this.citationId,
          },
        });

        if (citation) {
          await this.context.DataManager?.citations.addCitationToDocument(this.citationId);

          let elementToInsert: Editor.Data.Node.Data | undefined = citation;

          if (!closest) {
            // if no citation group found
            elementToInsert = NodeDataBuilder.buildData({
              type: 'citations-group',
              childNodes: [citation],
              properties: {},
            });
          }

          if (elementToInsert) {
            super.exec(elementToInsert);
          }
        }
      } catch (e) {
        throw e;
      }
    }

    return this;
  }
}
