import getConfig from 'dodoc.config';
import { ReduxInterface } from './ReduxInterface';
import { DataManager } from './DataManager';
import { BaseTypedEmitter } from '_common/services/Realtime';
import { Transport } from '_common/services/Realtime/Transport';
import { Logger } from '_common/services';
import { SelectionManager } from './SelectionManager';
import { ShortcutsManager } from './ShortcutsManager';
import { NavigationService } from './NavigationService';
import { DOMUtils } from '_common/utils';
import { notify } from '_common/components/ToastSystem';
import { SelectionUtils } from '_common/DoDOCSelection';

export const COLORS_HEX_MAP = {
  none: undefined,
  black: '#000000',
  yellow: '#FFBE00',
  green: '#5CBE6C',
  red: '#ED596D',
  blue: '#2F77F6',
} as const;

export class PDFManager extends BaseTypedEmitter<PDF.PDFManagerEvents> implements PDF.IPDFManager {
  private context: PDF.PDFManagerContext;
  protected _reloadTimeout: NodeJS.Timeout | null = null;

  constructor() {
    super();
    this.context = {
      status: 'NOT_READY',
    };
    /* develblock:start */
    global.pdfManager = this;
    /* develblock:end */

    this.saveVersion = this.saveVersion.bind(this);
    this.editVersionDescription = this.editVersionDescription.bind(this);
    this.loadVersion = this.loadVersion.bind(this);
    this.undo = this.undo.bind(this);
    this.redo = this.redo.bind(this);
  }

  get status(): PDF.PDFManagerStatus {
    return this.context.status;
  }

  get navigation() {
    return this.context.navigation;
  }

  destroy(error?: Error): void {
    if (this.context.status !== 'DESTROYING' && this.context.status !== 'DESTROYED') {
      console.trace('DESTROY PDFManager');
      this.context.status = 'DESTROYING';
      this.emit('STATUS_CHANGED', error, 'DESTROYING');
      this.destroyCoreServices();
      super.destroy();
      this.context.status = 'DESTROYED';
      this.emit('STATUS_CHANGED', error, 'DESTROYED');
    }
  }

  connect(documentId: string, user: Realtime.Core.User) {
    if (this.context.transport != null && this.context.transport.isConnected()) {
      // transport is already connected
      this.context.transport.checkConnectionStrength().then((result: number) => {
        this.context.status = 'INITIALIZED';
        this.emit('STATUS_CHANGED', null, 'INITIALIZED');
        Logger.debug(`TRANSPORT ALREADY CONNECTED : ${result}`);
        this.initializeCoreServices();
      });
    } else {
      this.context.document = {
        id: documentId,
      };
      this.context.user = user;
      this.context.transport = new Transport(getConfig());
      this.context.status = 'INITIALIZING';
      this.emit('STATUS_CHANGED', null, 'INITIALIZING');
      this.context.transport
        .on('TS_CONNECTED', () => {
          this.context.transport?.checkConnectionStrength().then((result: number) => {
            this.context.status = 'INITIALIZED';
            this.emit('STATUS_CHANGED', null, 'INITIALIZED');
            Logger.debug(`TRANSPORT CONNECTION : ${result}`);
            this.initializeCoreServices();
          });
        })
        .on('TS_DESTROYED', () => {
          this.destroy();
        })
        .on('TS_DISCONNECTED', () => {
          this.destroy(new Error('DISCONNECTED'));
        })
        .on('TS_ERROR', (event: any, error: any) => {
          this.destroy(new Error(event));
        });
      if (this.context.document.id && this.context.user.token) {
        this.context.transport.connect(this.context.document.id, this.context.user.token);
      }
    }
  }

  reloadPDFManager() {
    if (this._reloadTimeout) {
      clearTimeout(this._reloadTimeout);
    }

    this._reloadTimeout = setTimeout(() => {
      try {
        this.destroy();
        this.initializeCoreServices();
      } catch (error) {
        Logger.captureException(error);
        this.destroy();
        throw error;
      }
    }, 0);
  }

  private destroyCoreServices() {
    if (this.context.selection) {
      this.context.selection.destroy();
      delete this.context.selection;
    }

    if (this.context.data) {
      this.context.data.destroy();
      delete this.context.data;
    }

    if (this.context.transport) {
      this.context.transport.destroy();
      delete this.context.transport;
    }
  }

  private async initializeCoreServices() {
    try {
      await this.initializeDataManager();
      this.initializeNavigation();
      this.initializeSelectionManager();

      this.context.status = 'READY';
      this.emit('STATUS_CHANGED', null, 'READY');
    } catch (error) {
      this.context.status = 'DESTROYING';
      this.emit('STATUS_CHANGED', error, 'DESTROYING');
    }
  }

  private async initializeDataManager() {
    if (this.context.transport && this.context.document?.id) {
      this.context.data = DataManager(this.context.transport);
      this.context.data.on('FORCE_REMOTE_RELOAD', () => {
        this.reloadPDFManager();
      });
      this.context.data.on('LOAD_USERS_ONLINE', (users: any) => {
        ReduxInterface.usersOnline(users);
      });
      this.context.data.on('USER_JOINED', (user: any) => {
        ReduxInterface.userJoined(user);
      });
      this.context.data.on('USER_LEFT', (user: any) => {
        ReduxInterface.userLeft(user);
      });

      this.context.data.on('LOAD_ANNOTATIONS', ReduxInterface.loadAnnotations);
      this.context.data.on('DOCUMENT_UPDATED', () => {
        ReduxInterface.invalidateApiTags([{ type: 'Object', id: this.context.document?.id }]);
      });
      this.context.data.on('UPDATE_OBJECT_STATUS', () => {
        ReduxInterface.invalidateApiTags([{ type: 'Object', id: this.context.document?.id }]);
      });
      await this.context.data.start(this.context.document.id, this.context.user);
    }
  }

  private initializeNavigation() {
    if (this.context.transport && this.context.document?.id) {
      this.context.navigation = new NavigationService(this.context);
      this.context.navigation.on('BEFORE_CHANGING_CURRENT_PAGE', (current) => {
        this.emit('BEFORE_CHANGING_CURRENT_PAGE', current);
      });
      this.context.navigation.on('CHANGED_CURRENT_PAGE', (current) => {
        ReduxInterface.setCurrentPage(current);
        this.emit('CHANGED_CURRENT_PAGE', current);
      });
      this.context.navigation.on('AFTER_CHANGING_CURRENT_PAGE', (current) => {
        this.emit('AFTER_CHANGING_CURRENT_PAGE', current);
      });
      this.context.navigation.on('SCROLL_TO_PAGE', (current) => {
        this.emit('SCROLL_TO_PAGE', current);
      });
      this.context.navigation.start();
    }
  }

  private initializeSelectionManager() {
    this.context.selection = new SelectionManager();
    this.context.selection.start();
  }

  initializeShortcutsManager() {
    this.context.shortcuts = new ShortcutsManager(ReduxInterface.getPlatform());
    this.context.shortcuts.on('ZOOM_IN', ReduxInterface.zoomIn);
    this.context.shortcuts.on('ZOOM_OUT', ReduxInterface.zoomOut);
    this.context.shortcuts.on('UNDO', this.undo);
    this.context.shortcuts.on('REDO', this.redo);
    this.context.shortcuts.start();
  }

  destroyShortcutsManager() {
    if (this.context.shortcuts) {
      this.context.shortcuts.destroy();
      delete this.context.shortcuts;
    }
  }

  reRenderPage(pageNum: number) {
    this.emit('RE_RENDER_PAGE', pageNum);
  }

  getData() {
    return this.context.data;
  }

  getNumPages() {
    return this.context.navigation?.numPages || 0;
  }

  getAnnotations(pageNum: number) {
    return this.context.data?.annotations.getAnnotations(pageNum);
  }

  getPage(pageNum: number) {
    return this.context.data?.documentController.getPage(pageNum) || null;
  }

  getPageHeight(pageNum: number) {
    return this.context.data?.structureController.getPageHeight(pageNum);
  }

  getPageWidth(pageNum: number) {
    return this.context.data?.structureController.getPageWidth(pageNum);
  }

  //* FIND *//

  find(params: PDF.Find.FindParams) {
    return this.context.data?.find.find(params);
  }

  resetFind() {
    return this.context.data?.find.reset();
  }

  isFindRunning() {
    return !!this.context.data?.find.running;
  }

  // * * //

  createTextMarkupAnnotation(
    subtype: PDF.Annotation.TextMarkup['subtype'],
    content?: PDF.Annotation.ContentsType['content'],
  ) {
    const selectionRects = this.context.selection?.getSelectionDOMRects();
    const page = this.context.selection?.getPageFromSelection();
    if (selectionRects && page && page.dataset.pageNum && page.dataset.viewportScale) {
      const { bounding, list } = selectionRects;
      const { roiColor } = ReduxInterface.getAnnotationCreationValues();
      const annotationId = this.createAnnotation({
        subtype,
        pageNumber: +page.dataset.pageNum,
        pageRect: page.getBoundingClientRect(),
        boundingRect: bounding,
        rects: list,
        viewportScale: +page.dataset.viewportScale,
        color: {
          stroke: COLORS_HEX_MAP[roiColor],
        },
        content,
      });
      if (subtype === 'Highlight' && annotationId) {
        ReduxInterface.completeAction('pdf_annotations_highlight');
        ReduxInterface.setPulseData({ annotationId });
      }
      return annotationId;
    }
    return null;
  }

  createShapeAnnotation(
    subtype: PDF.Annotation.ShapeType,
    pageNum: number,
    start: PDF.Annotation.Point,
    end: PDF.Annotation.Point,
    inkLists: PDF.Annotation.Point[][],
  ) {
    const page = this.getPageFromNumber(pageNum);
    const { stroke, fill, strokeWidth } = ReduxInterface.getAnnotationCreationValues();
    if (page && page.dataset.viewportScale) {
      const type = subtype === 'Arrow' ? 'Line' : subtype;
      const pageRect = page.getBoundingClientRect();
      const viewportScale = +page.dataset.viewportScale;
      let boundingRect;
      if (type !== 'Ink') {
        boundingRect = new DOMRect(
          Math.min(start.x, end.x),
          Math.min(start.y, end.y),
          Math.abs(end.x - start.x),
          Math.abs(end.y - start.y),
        );
      } else {
        let minX, maxX, minY, maxY;

        for (let i = 0; i < inkLists.length; i++) {
          for (let j = 0; j < inkLists[i].length; j++) {
            const { x, y } = inkLists[i][j];
            minX = minX ? Math.min(minX, x) : x;
            maxX = maxX ? Math.max(maxX, x) : x;
            minY = minY ? Math.min(minY, y) : y;
            maxY = maxY ? Math.max(maxY, y) : y;
          }
        }

        if (minX && maxX && minY && maxY) {
          boundingRect = new DOMRect(minX, minY, maxX - minX, maxY - minY);
        }
      }
      if (boundingRect) {
        return this.createAnnotation({
          subtype: type,
          pageNumber: pageNum,
          pageRect,
          boundingRect,
          viewportScale,
          color: {
            stroke: stroke !== 'none' ? COLORS_HEX_MAP[stroke] : undefined,
            fill: fill !== 'none' && type !== 'Ink' ? COLORS_HEX_MAP[fill] : undefined,
          },
          border: {
            style: 'S',
            width: strokeWidth,
          },

          lineEndings: { start: 'None', end: subtype === 'Arrow' ? 'OpenArrow' : 'None' },
          lineCoordinates: { start, end },
          inkList: inkLists,
        });
      }
    }
  }

  createNoteAnnotation(
    rect: PDF.Annotation.Rect,
    pageNum: number,
    content?: PDF.Annotation.ContentsType['content'],
  ) {
    const page = this.getPageFromNumber(pageNum);
    if (page && page.dataset.pageNum && page.dataset.viewportScale) {
      return this.createAnnotation({
        subtype: 'Note',
        pageNumber: +page.dataset.pageNum,
        pageRect: page.getBoundingClientRect(),
        boundingRect: rect,
        viewportScale: +page.dataset.viewportScale,
        isFreestyle: true,
        content,
      });
    }

    return null;
  }

  startCreatingInlineTask() {
    const selectionRects = this.context.selection?.getSelectionDOMRects();
    const page = this.context.selection?.getPageFromSelection();
    if (selectionRects && page && page.dataset.pageNum && page.dataset.viewportScale) {
      ReduxInterface.setCreating({
        subtype: 'Task',
        pageNumber: +page.dataset.pageNum,
        pageRect: page.getBoundingClientRect(),
        boundingRect: selectionRects.bounding,
        viewportScale: +page.dataset.viewportScale,
        isFreestyle: false,
        task: {
          assignee: '',
          dueDate: '',
          status: 'td',
          watchers: [],
        },
        rects: DOMUtils.filterAndAdjustDOMRectList(selectionRects.list),
      });
    }
  }

  startCreatingFreestyleTask(boundingRect: PDF.Annotation.Rect, pageNum: number) {
    const page = this.context.selection?.getPageFromNumber(pageNum);
    if (page && page.dataset.pageNum && page.dataset.viewportScale)
      ReduxInterface.setCreating({
        subtype: 'Task',
        pageNumber: +page.dataset.pageNum,
        pageRect: page.getBoundingClientRect(),
        boundingRect,
        viewportScale: +page.dataset.viewportScale,
        isFreestyle: true,
        task: {
          assignee: '',
          dueDate: '',
          status: 'td',
          watchers: [],
        },
      });
  }

  selectAnnotation(annotationId: PDF.Annotation['id']) {
    ReduxInterface.selectAnnotation(annotationId);
    const annotation = ReduxInterface.getStore().getState().pdf.annotations.byId[annotationId];
    this.context.navigation?.goTo(annotation.pageNumber);
  }

  createAnnotation(creationData: PDF.AnnotationCreationData) {
    return this.context.data?.annotations.createNewAnnotation(creationData);
  }

  editAnnotationContent(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    content: PDF.Annotation.ContentsType['content'],
  ) {
    return this.context.data?.annotations.editAnnotationContent(pageNumber, id, content);
  }

  resolveAnnotation(pageNumber: PDF.Annotation['pageNumber'], id: PDF.Annotation['id']) {
    return this.context.data?.annotations.resolveAnnotation(pageNumber, id);
  }

  deleteAnnotation(pageNumber: PDF.Annotation['pageNumber'], id: PDF.Annotation['id']) {
    return this.context.data?.annotations.deleteAnnotation(pageNumber, id);
  }

  replyToAnnotation(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    replyContent: PDF.Annotation.ContentsType['content'],
  ) {
    return this.context.data?.annotations.replyToAnnotation(pageNumber, id, replyContent);
  }

  voteReplyToAnnotation(
    pageNumber: PDF.Annotation['pageNumber'],
    annotationId: PDF.Annotation['id'],
    replyId: PDF.Annotation.Reply['id'],
    value: PDF.Annotation.Vote['value'],
  ) {
    return this.context.data?.annotations.voteReplyToAnnotation(
      pageNumber,
      annotationId,
      replyId,
      value,
    );
  }

  editReplyAnnotation(
    pageNumber: PDF.Annotation['pageNumber'],
    annotationId: PDF.Annotation['id'],
    replyId: PDF.Annotation.Reply['id'],
    content: PDF.Annotation.ContentsType['content'],
  ) {
    return this.context.data?.annotations.editReplyAnnotation(
      pageNumber,
      annotationId,
      replyId,
      content,
    );
  }

  deleteReplyAnnotation(
    pageNumber: PDF.Annotation['pageNumber'],
    annotationId: PDF.Annotation['id'],
    replyId: PDF.Annotation.Reply['id'],
  ) {
    return this.context.data?.annotations.deleteReplyAnnotation(pageNumber, annotationId, replyId);
  }

  voteAnnotation(
    pageNumber: PDF.Annotation['pageNumber'],
    annotationId: PDF.Annotation['id'],
    value: PDF.Annotation.Vote['value'],
  ) {
    return this.context.data?.annotations.voteAnnotation(pageNumber, annotationId, value);
  }

  editAnnotationRect(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    boundingRect: PDF.AnnotationCreationData['boundingRect'],
    pageRect: PDF.AnnotationCreationData['pageRect'],
    viewportScale: PDF.AnnotationCreationData['viewportScale'],
  ) {
    return this.context.data?.annotations.editAnnotationRect(
      pageNumber,
      id,
      boundingRect,
      pageRect,
      viewportScale,
    );
  }

  editAnnotationColor(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    newColor: PDF.Annotation['color'],
  ) {
    return this.context.data?.annotations.editAnnotationColor(pageNumber, id, newColor);
  }

  editAnnotationBorder(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    newBorder: PDF.Annotation['border'],
  ) {
    return this.context.data?.annotations.editAnnotationBorder(pageNumber, id, newBorder);
  }

  moveAnnotation(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    delta: { x: number; y: number },
  ) {
    ReduxInterface.selectAnnotation(id);
    return this.context.data?.annotations.moveAnnotation(pageNumber, id, delta);
  }

  resizeAnnotation(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    delta: { width: number; height: number; left: number; bottom: number },
  ) {
    ReduxInterface.selectAnnotation(id);
    return this.context.data?.annotations.resizeAnnotation(pageNumber, id, delta);
  }

  moveLineAnnotation(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    position: 'start' | 'end',
    delta: { x: number; y: number },
  ) {
    return this.context.data?.annotations.moveLineAnnotation(pageNumber, id, position, delta);
  }

  changeAnnotationPriority(pageNumber: number, id: string, priority: PDF.Annotation['priority']) {
    return this.context.data?.annotations.changeAnnotationPriority(pageNumber, id, priority);
  }

  changeTaskStatus(pageNumber: number, id: string, status: PDF.Annotation.TaskStatus) {
    return this.context.data?.annotations.changeTaskStatus(pageNumber, id, status);
  }

  async editTask(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    data: PDF.Annotation.EditTaskData,
  ) {
    return this.context.data?.annotations.editTask(pageNumber, id, data);
  }

  watchTask(pageNumber: PDF.Annotation['pageNumber'], id: PDF.Annotation['id'], watcherId: string) {
    return this.context.data?.annotations.watchTask(pageNumber, id, watcherId);
  }

  removeWatchFromTask(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    watcherId: string,
  ) {
    return this.context.data?.annotations.removeWatchFromTask(pageNumber, id, watcherId);
  }

  //----------------------------------------------------------------------
  //                              Selection
  //----------------------------------------------------------------------
  restoreSelection() {
    if (this.context.selection) {
      this.context.selection.restoreRange();
    }
    return null;
  }

  getRange(): Range | null {
    if (this.context.selection) {
      return this.context.selection.getRange();
    }
    return null;
  }

  getPageFromSelection() {
    if (this.context.selection) {
      return this.context.selection.getPageFromSelection();
    }
    return null;
  }

  getPageFromNumber(pageNum: number) {
    if (this.context.selection) {
      return this.context.selection.getPageFromNumber(pageNum);
    }
    return null;
  }

  isSelectionInPDF() {
    if (this.context.selection) {
      return this.context.selection.isSelectionInPDF();
    }
    return null;
  }

  isSelectionCollapsed() {
    if (this.context.selection) {
      return SelectionUtils.isSelectionCollapsed();
    }
    return null;
  }

  //----------------------------------------------------------------------
  //                              Versions
  //----------------------------------------------------------------------

  saveVersion(description: string) {
    return this.context.data?.versions.saveVersion(description);
  }

  async loadVersion(index: number | null) {
    try {
      const res = await this.context.data?.versions.loadVersion(index);
      ReduxInterface.setLoadedVersion(index);
      return res;
    } catch (e) {
      throw e;
    }
  }
  async restoreVersion(index: number) {
    try {
      const res = await this.context.data?.versions.restoreVersion(index);
      await this.loadVersion(null);
      return res;
    } catch (e) {
      throw e;
    }
  }

  editVersionDescription(description: string, index: number) {
    return this.context.data?.versions.editVersionDescription(description, index);
  }

  //----------------------------------------------------------------------
  //                              Undo/Redo
  //----------------------------------------------------------------------

  canUndo() {
    return this.context.data?.history.canUndo() || false;
  }

  canRedo() {
    return this.context.data?.history.canRedo() || false;
  }

  undo() {
    if (this.context.data?.history.canUndo()) {
      return this.context.data?.history.undo();
    } else {
      notify({
        id: 'editorUndo',
        type: 'warning',
        title: 'UNDO_NOT_AVAILABLE',
        message: 'editor.errors.undo.noActions',
      });
    }
  }

  redo() {
    if (this.context.data?.history.canRedo()) {
      return this.context.data?.history.redo();
    } else {
      notify({
        id: 'editorRedo',
        type: 'warning',
        title: 'REDO_NOT_AVAILABLE',
        message: 'editor.errors.redo.noActions',
      });
    }
  }
}
