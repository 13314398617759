/* eslint-disable class-methods-use-this */
import { v4 as uuid } from 'uuid';
import { RealtimeObject, RealtimeOpsBuilder } from '_common/services/Realtime';
import { Transport } from '_common/services/Realtime/Transport';
import { PageAnnotationsCollection } from '.';

type AnnotationsData = {
  _id: string;
  a: Record<string, PageAnnotationsCollection>;
};

export class Annotations extends RealtimeObject<AnnotationsData> {
  constructor(
    transport: Transport,
    id: Realtime.Core.RealtimeObjectId,
    undoManager?: Realtime.Core.UndoManager,
  ) {
    super(transport, id, 'annotations', undoManager);
  }

  getAll() {
    logger.info('Annotations getAll');
    let annots = [];
    const thisData = this.get(['a']);
    if (thisData) {
      const pageNums = Object.keys(thisData);
      let pageNum;
      let pageData;
      let pageAnnotsIds;
      for (let index = 0; index < pageNums.length; index++) {
        pageNum = pageNums[index];
        pageData = thisData[pageNum] || {};
        pageAnnotsIds = Object.keys(pageData);
        for (let j = 0; j < pageAnnotsIds.length; j++) {
          annots.push(pageData[pageAnnotsIds[j]]);
        }
      }
    }
    return annots;
  }

  protected getUndoableOps(ops: Realtime.Core.RealtimeOps): Realtime.Core.RealtimeOps {
    return ops.filter((op) => {
      if (op.p.length >= 4 && (op.p[3] === 'replies' || op.p[3] === 'votes')) {
        return false;
      }
      return true;
    });
  }

  getAnnotations(pageNumber: PDF.Annotation['pageNumber']) {
    const data = this.selectedData();
    return data?.a[pageNumber];
  }

  getAnnotation(pageNumber: PDF.Annotation['pageNumber'], id: PDF.Annotation['id']) {
    return this.getAnnotations(pageNumber)?.[id];
  }

  handleLoad(): void {
    //
  }

  handlePreBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }

  handleBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    logger.info('Annotations handleBatchOperations', ops, source);
    const data = super.get() as AnnotationsData;
    this.emit('UPDATED', data, ops, source);
  }

  handleOperations(ops: Realtime.Core.RealtimeOps, source: Realtime.Core.RealtimeSourceType): void {
    //
  }

  handlePreOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }

  createWithNativeData(nativeData: any) {
    // nativeData.id = this.id;
    return super.create({
      ...nativeData,
    });
  }

  createNewAnnotation(annotation: PDF.Annotation | void) {
    if (annotation) {
      const ops: Realtime.Core.RealtimeOps = [];
      if (!this.data?.a[annotation.pageNumber]) {
        ops.push(RealtimeOpsBuilder.objectInsert({}, ['a', annotation.pageNumber]));
      }
      ops.push(
        RealtimeOpsBuilder.objectInsert(annotation, ['a', annotation.pageNumber, annotation.id]),
      );

      this.apply(ops);
    }
    return annotation?.id;
  }

  editAnnotation(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    data: Partial<PDF.Annotation>,
  ) {
    const annotation = this.getAnnotation(pageNumber, id);

    if (annotation) {
      const ops = [];
      let props = Object.keys(data) as (keyof PDF.Annotation)[];

      for (let index = 0; index < props.length; index++) {
        const prop = props[index];
        if (annotation[prop] !== undefined) {
          if (data[prop] === null) {
            ops.push(
              RealtimeOpsBuilder.objectDelete(annotation[prop], ['a', pageNumber, id, prop]),
            );
          } else {
            ops.push(
              RealtimeOpsBuilder.objectReplace(annotation[prop], data[prop], [
                'a',
                pageNumber,
                id,
                prop,
              ]),
            );
          }
        } else {
          if (data[prop] !== null) {
            ops.push(RealtimeOpsBuilder.objectInsert(data[prop], ['a', pageNumber, id, prop]));
          }
        }
      }

      if (ops.length) {
        ops.push({
          od: annotation.modificationDate,
          oi: new Date(),
          p: ['a', pageNumber, id, 'modificationDate'],
        });
        return this.apply(ops);
      }
    }
  }

  editAnnotationContent(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    content: PDF.Annotation.ContentsType['content'],
  ) {
    const annotation = this.getAnnotation(pageNumber, id);

    if (annotation) {
      const ops = [];
      if (content !== annotation.content?.content) {
        ops.push({
          od: annotation.content?.content,
          oi: content,
          p: ['a', pageNumber, id, 'content', 'content'],
        });
        ops.push({
          od: annotation.modificationDate,
          oi: new Date(),
          p: ['a', pageNumber, id, 'modificationDate'],
        });
      }

      if (ops.length) {
        return this.apply(ops);
      }
    }
  }

  deleteAnnotation(pageNumber: PDF.Annotation['pageNumber'], id: PDF.Annotation['id']) {
    const annotation = this.getAnnotation(pageNumber, id);
    if (annotation) {
      return this.apply([
        RealtimeOpsBuilder.objectReplace(annotation.stateModel, 'Review', [
          'a',
          pageNumber,
          id,
          'stateModel',
        ]),
        RealtimeOpsBuilder.objectReplace(annotation.state, 'Cancelled', [
          'a',
          pageNumber,
          id,
          'state',
        ]),
      ]);
    }
  }

  replyToAnnotation(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    authorId: PDF.Annotation.Reply['authorId'],
    replyContent: PDF.Annotation.ContentsType['content'],
  ) {
    const annotation = this.getAnnotation(pageNumber, id);
    if (annotation) {
      const ops = [];
      const currentDate = new Date().toISOString();
      const reply: PDF.Annotation.Reply = {
        id: uuid(),
        authorId: authorId,
        content: {
          content: replyContent,
          dir: 'ltr',
        },
        creationDate: currentDate,
        modificationDate: currentDate,
        votes: [],
      };

      if (!annotation.replies) {
        ops.push(RealtimeOpsBuilder.objectInsert([reply], ['a', pageNumber, id, 'replies']));
      } else {
        ops.push(
          RealtimeOpsBuilder.listInsert(reply, [
            'a',
            pageNumber,
            id,
            'replies',
            annotation.replies.length,
          ]),
        );
      }

      if (ops.length) {
        return this.apply(ops);
      }
    }
  }

  editReplyAnnotation(
    pageNumber: PDF.Annotation['pageNumber'],
    annotationId: PDF.Annotation['id'],
    replyId: PDF.Annotation.Reply['id'],
    content: PDF.Annotation.ContentsType['content'],
  ) {
    const annotation = this.getAnnotation(pageNumber, annotationId);
    if (annotation?.replies) {
      const replyIndex = annotation.replies.findIndex((reply) => reply.id === replyId);
      const reply = annotation.replies[replyIndex];
      if (reply) {
        return this.apply([
          RealtimeOpsBuilder.objectReplace(reply.content.content, content, [
            'a',
            pageNumber,
            annotationId,
            'replies',
            replyIndex,
            'content',
            'content',
          ]),
        ]);
      }
    }
  }

  deleteReplyAnnotation(
    pageNumber: PDF.Annotation['pageNumber'],
    annotationId: PDF.Annotation['id'],
    replyId: PDF.Annotation.Reply['id'],
  ) {
    const annotation = this.getAnnotation(pageNumber, annotationId);
    if (annotation?.replies) {
      const replyIndex = annotation.replies.findIndex((reply) => reply.id === replyId);
      const reply = annotation.replies[replyIndex];
      if (reply) {
        return this.apply([
          RealtimeOpsBuilder.listDelete(reply, [
            'a',
            pageNumber,
            annotationId,
            'replies',
            replyIndex,
          ]),
        ]);
      }
    }
  }

  voteReplyToAnnotation(
    pageNumber: PDF.Annotation['pageNumber'],
    annotationId: PDF.Annotation['id'],
    replyId: PDF.Annotation.Reply['id'],
    user: PDF.Annotation.Vote['user'],
    value: PDF.Annotation.Vote['value'],
  ) {
    const annotation = this.getAnnotation(pageNumber, annotationId);
    if (annotation?.replies) {
      const replyIndex = annotation.replies.findIndex((reply) => reply.id === replyId);
      const reply = annotation.replies[replyIndex];
      if (reply) {
        const ops = [];
        const vote: PDF.Annotation.Vote = {
          user: user,
          value: value,
          time: new Date().toISOString(),
        };
        if (!reply.votes) {
          if (value !== 0) {
            ops.push(
              RealtimeOpsBuilder.objectInsert(
                [vote],
                ['a', pageNumber, annotationId, 'replies', replyIndex, 'votes'],
              ),
            );
          }
        } else {
          const voteIndex = reply.votes.findIndex((vote) => vote.user === user);
          if (reply.votes[voteIndex]) {
            if (value === 0) {
              ops.push(
                RealtimeOpsBuilder.listDelete(reply.votes[voteIndex], [
                  'a',
                  pageNumber,
                  annotationId,
                  'replies',
                  replyIndex,
                  'votes',
                  voteIndex,
                ]),
              );
            } else if (reply.votes[voteIndex].value !== value) {
              ops.push(
                RealtimeOpsBuilder.listReplace(reply.votes[voteIndex], vote, [
                  'a',
                  pageNumber,
                  annotationId,
                  'replies',
                  replyIndex,
                  'votes',
                  voteIndex,
                ]),
              );
            }
          } else if (value !== 0) {
            ops.push(
              RealtimeOpsBuilder.listInsert(vote, [
                'a',
                pageNumber,
                annotationId,
                'replies',
                replyIndex,
                'votes',
                reply.votes.length,
              ]),
            );
          }
        }

        if (ops.length) {
          return this.apply(ops);
        }
      }
    }
  }

  voteAnnotation(
    pageNumber: PDF.Annotation['pageNumber'],
    annotationId: PDF.Annotation['id'],
    user: PDF.Annotation.Vote['user'],
    value: PDF.Annotation.Vote['value'],
  ) {
    const annotation = this.getAnnotation(pageNumber, annotationId);
    if (annotation) {
      const ops = [];
      const vote: PDF.Annotation.Vote = {
        user: user,
        value: value,
        time: new Date().toISOString(),
      };
      if (!annotation.votes) {
        if (value !== 0) {
          ops.push(
            RealtimeOpsBuilder.objectInsert([vote], ['a', pageNumber, annotationId, 'votes']),
          );
        }
      } else {
        const voteIndex = annotation.votes.findIndex((vote) => vote.user === user);
        if (annotation.votes[voteIndex]) {
          if (value === 0) {
            ops.push(
              RealtimeOpsBuilder.listDelete(annotation.votes[voteIndex], [
                'a',
                pageNumber,
                annotationId,
                'votes',
                voteIndex,
              ]),
            );
          } else if (annotation.votes[voteIndex].value !== value) {
            ops.push(
              RealtimeOpsBuilder.listReplace(annotation.votes[voteIndex], vote, [
                'a',
                pageNumber,
                annotationId,
                'votes',
                voteIndex,
              ]),
            );
          }
        } else if (value !== 0) {
          ops.push(
            RealtimeOpsBuilder.listInsert(vote, [
              'a',
              pageNumber,
              annotationId,
              'votes',
              annotation.votes.length,
            ]),
          );
        }
      }

      if (ops.length) {
        return this.apply(ops);
      }
    }
  }

  resolveAnnotation(pageNumber: PDF.Annotation['pageNumber'], id: PDF.Annotation['id']) {
    const annotation = this.getAnnotation(pageNumber, id);
    if (annotation) {
      return this.apply([
        RealtimeOpsBuilder.objectReplace(annotation.stateModel, 'Review', [
          'a',
          pageNumber,
          id,
          'stateModel',
        ]),
        RealtimeOpsBuilder.objectReplace(annotation.state, 'Completed', [
          'a',
          pageNumber,
          id,
          'state',
        ]),
      ]);
    }
  }

  changeAnnotationPriority(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    priority: PDF.Annotation['priority'],
  ) {
    const annotation = this.getAnnotation(pageNumber, id);
    if (annotation && annotation.priority !== priority) {
      return this.apply([
        RealtimeOpsBuilder.objectReplace(annotation.priority, priority, [
          'a',
          pageNumber,
          id,
          'priority',
        ]),
      ]);
    }
  }

  editAnnotationRect(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    rect: PDF.Annotation.Rect,
  ) {
    const annotation = this.getAnnotation(pageNumber, id);
    if (annotation) {
      return this.apply([
        RealtimeOpsBuilder.objectReplace(annotation.rect, rect, ['a', pageNumber, id, 'rect']),
      ]);
    }
  }

  changeTaskStatus(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    status: PDF.Annotation.Task['status'],
  ) {
    const annotation = this.getAnnotation(pageNumber, id);
    if (status) {
      if (annotation && annotation.subtype === 'Task' && annotation.status !== status) {
        return this.apply([
          RealtimeOpsBuilder.objectReplace(annotation.status, status, [
            'a',
            pageNumber,
            id,
            'status',
          ]),
        ]);
      }
    }
  }

  async editTask(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    data: PDF.Annotation.EditTaskData,
  ) {
    const current = this.getAnnotation(pageNumber, id);
    if (current && current.subtype === 'Task') {
      const ops = [];
      if (data.dueDate !== current.dueDate) {
        if (data.dueDate !== undefined) {
          ops.push(
            RealtimeOpsBuilder.objectReplace(current.dueDate, data.dueDate, [
              'a',
              pageNumber,
              id,
              'dueDate',
            ]),
          );
        }
      }
      if (data.status !== current.status) {
        if (data.status) {
          ops.push(
            RealtimeOpsBuilder.objectReplace(current.status, data.status, [
              'a',
              pageNumber,
              id,
              'status',
            ]),
          );
        }
      }
      if (data.assignee !== current.assignee) {
        if (data.assignee !== undefined) {
          ops.push(
            RealtimeOpsBuilder.objectReplace(current.assignee, data.assignee, [
              'a',
              pageNumber,
              id,
              'assignee',
            ]),
          );
        }
      }
      if (data.content !== current.content?.content) {
        if (data.content) {
          ops.push(
            RealtimeOpsBuilder.objectReplace(current.content?.content, data.content, [
              'a',
              pageNumber,
              id,
              'content',
              'content',
            ]),
          );
        }
      }
      if (ops.length) {
        await this.apply(ops);
        return;
      }
    }
  }

  watchTask(pageNumber: PDF.Annotation['pageNumber'], id: PDF.Annotation['id'], watcherId: string) {
    const annotation = this.getAnnotation(pageNumber, id);
    if (annotation && annotation.subtype === 'Task') {
      const watcherIndex = annotation.watchers.findIndex((watcher) => watcher === watcherId);
      if (watcherIndex < 0) {
        return this.apply([
          RealtimeOpsBuilder.listInsert(watcherId, [
            'a',
            pageNumber,
            id,
            'watchers',
            annotation.watchers.length,
          ]),
        ]);
      }
    }
  }

  removeWatchFromTask(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    watcherId: string,
  ) {
    const annotation = this.getAnnotation(pageNumber, id);
    if (annotation && annotation.subtype === 'Task') {
      const watcherIndex = annotation.watchers.findIndex((watcher) => watcher === watcherId);
      if (watcherIndex >= 0) {
        return this.apply([
          RealtimeOpsBuilder.listDelete(watcherId, ['a', pageNumber, id, 'watchers', watcherIndex]),
        ]);
      }
    }
  }

  protected applyDeltaToAnnotation(annotation: PDF.Annotation, delta: { x: number; y: number }) {
    let ops: Realtime.Core.RealtimeOps = [];
    if (annotation.rect) {
      const rect: PDF.Annotation.Rect = {
        ...annotation.rect,
      };
      rect.left += delta.x;
      rect.right += delta.x;
      rect.top += delta.y;
      rect.bottom += delta.y;
      ops.push({
        na: delta.x,
        p: ['a', annotation.pageNumber, annotation.id, 'rect', 'left'],
      });
      ops.push({
        na: delta.x,
        p: ['a', annotation.pageNumber, annotation.id, 'rect', 'right'],
      });
      ops.push({
        na: delta.y,
        p: ['a', annotation.pageNumber, annotation.id, 'rect', 'top'],
      });
      ops.push({
        na: delta.y,
        p: ['a', annotation.pageNumber, annotation.id, 'rect', 'bottom'],
      });
    }
    if (annotation.subtype === 'Ink' && annotation.inkLists) {
      const inkLists = annotation.inkLists.map((element) => {
        return element.map((_element) => {
          return {
            x: _element.x + delta.x,
            y: _element.y + delta.y,
          };
        });
      });
      ops.push(
        RealtimeOpsBuilder.objectReplace(annotation.inkLists, inkLists, [
          'a',
          annotation.pageNumber,
          annotation.id,
          'inkLists',
        ]),
      );
    }
    if (annotation.subtype === 'Line' && annotation.lineCoordinates) {
      const newCoordinates = {
        start: {
          x: annotation.lineCoordinates.start.x + delta.x,
          y: annotation.lineCoordinates.start.y + delta.y,
        },
        end: {
          x: annotation.lineCoordinates.end.x + delta.x,
          y: annotation.lineCoordinates.end.y + delta.y,
        },
      };
      ops.push(
        RealtimeOpsBuilder.objectReplace(annotation.lineCoordinates, newCoordinates, [
          'a',
          annotation.pageNumber,
          annotation.id,
          'lineCoordinates',
        ]),
      );
    }
    return ops;
  }

  protected applyDeltaToLine(
    annotation: PDF.Annotation,
    position: 'start' | 'end',
    delta: { x: number; y: number },
  ) {
    let ops: Realtime.Core.RealtimeOps = [];
    if (annotation.subtype === 'Line' && annotation.lineCoordinates) {
      const newLineCoordinates = {
        ...annotation.lineCoordinates,
        [position]: {
          x: annotation.lineCoordinates[position].x + delta.x,
          y: annotation.lineCoordinates[position].y + delta.y,
        },
      };

      const left = Math.min(newLineCoordinates.start.x, newLineCoordinates.end.x);
      const bottom = Math.min(newLineCoordinates.start.y, newLineCoordinates.end.y);

      const width = Math.abs(newLineCoordinates.start.x - newLineCoordinates.end.x);
      const height = Math.abs(newLineCoordinates.start.y - newLineCoordinates.end.y);

      const right = left + width;
      const top = bottom + height;

      const newRect = { left, bottom, width, height, right, top };

      ops.push(
        RealtimeOpsBuilder.objectReplace(annotation.lineCoordinates, newLineCoordinates, [
          'a',
          annotation.pageNumber,
          annotation.id,
          'lineCoordinates',
        ]),
      );
      ops.push(
        RealtimeOpsBuilder.objectReplace(annotation.rect, newRect, [
          'a',
          annotation.pageNumber,
          annotation.id,
          'rect',
        ]),
      );
    }
    return ops;
  }

  /**
   *
   * @param annotation PDF.Annotation
   * @param delta.width
   * @returns
   */
  protected applyResizeDeltaToAnnotation(
    annotation: PDF.Annotation,
    delta: { width: number; height: number; left: number; bottom: number },
  ) {
    let ops: Realtime.Core.RealtimeOps = [];
    if (annotation.rect) {
      ops.push({
        na: delta.left,
        p: ['a', annotation.pageNumber, annotation.id, 'rect', 'left'],
      });
      ops.push({
        na: delta.left + delta.width,
        p: ['a', annotation.pageNumber, annotation.id, 'rect', 'right'],
      });
      ops.push({
        na: delta.bottom + delta.height,
        p: ['a', annotation.pageNumber, annotation.id, 'rect', 'top'],
      });
      ops.push({
        na: delta.bottom,
        p: ['a', annotation.pageNumber, annotation.id, 'rect', 'bottom'],
      });
      ops.push({
        na: delta.width,
        p: ['a', annotation.pageNumber, annotation.id, 'rect', 'width'],
      });
      ops.push({
        na: delta.height,
        p: ['a', annotation.pageNumber, annotation.id, 'rect', 'height'],
      });

      const widthRatio = (annotation.rect.width + delta.width) / annotation.rect.width;
      const heightRatio = (annotation.rect.height + delta.height) / annotation.rect.height;

      if (annotation.subtype === 'Ink' && annotation.inkLists) {
        const inkLists = annotation.inkLists.map((element) => {
          return element.map((_element) => {
            return {
              x:
                annotation.rect.left +
                (_element.x - annotation.rect.left) * widthRatio +
                delta.left,
              y:
                annotation.rect.bottom +
                (_element.y - annotation.rect.bottom) * heightRatio +
                delta.bottom,
            };
          });
        });
        ops.push(
          RealtimeOpsBuilder.objectReplace(annotation.inkLists, inkLists, [
            'a',
            annotation.pageNumber,
            annotation.id,
            'inkLists',
          ]),
        );
      }
      if (annotation.subtype === 'Line' && annotation.lineCoordinates) {
        const newCoordinates = {
          start: {
            x:
              annotation.rect.left +
              (annotation.lineCoordinates.start.x - annotation.rect.left) * widthRatio -
              delta.left,
            y:
              annotation.rect.bottom +
              (annotation.lineCoordinates.start.y - annotation.rect.bottom) * heightRatio -
              delta.bottom,
          },
          end: {
            x:
              annotation.rect.left +
              (annotation.lineCoordinates.end.x - annotation.rect.left) * widthRatio -
              delta.left,
            y:
              annotation.rect.bottom +
              (annotation.lineCoordinates.end.y - annotation.rect.bottom) * heightRatio -
              delta.bottom,
          },
        };
        ops.push(
          RealtimeOpsBuilder.objectReplace(annotation.lineCoordinates, newCoordinates, [
            'a',
            annotation.pageNumber,
            annotation.id,
            'lineCoordinates',
          ]),
        );
      }
    }
    return ops;
  }

  moveAnnotation(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    delta: { x: number; y: number },
  ) {
    const annotation = this.getAnnotation(pageNumber, id);
    if (annotation) {
      return this.apply(this.applyDeltaToAnnotation(annotation, delta));
    }
    return Promise.resolve(this);
  }

  moveLineAnnotation(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    position: 'start' | 'end',
    delta: { x: number; y: number },
  ) {
    const annotation = this.getAnnotation(pageNumber, id);
    if (annotation) {
      return this.apply(this.applyDeltaToLine(annotation, position, delta));
    }
    return Promise.resolve(this);
  }

  resizeAnnotation(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    delta: { width: number; height: number; left: number; bottom: number },
  ) {
    const annotation = this.getAnnotation(pageNumber, id);
    if (annotation) {
      return this.apply(this.applyResizeDeltaToAnnotation(annotation, delta));
    }
    return Promise.resolve(this);
  }

  editAnnotationColor(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    newColor: PDF.Annotation['color'],
  ) {
    const annotation = this.getAnnotation(pageNumber, id);
    if (annotation) {
      return this.apply([
        RealtimeOpsBuilder.objectReplace(annotation.color, newColor, [
          'a',
          pageNumber,
          id,
          'color',
        ]),
      ]);
    }
    return Promise.resolve(this);
  }

  editAnnotationBorder(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    newBorder: PDF.Annotation['border'],
  ) {
    const annotation = this.getAnnotation(pageNumber, id);
    if (annotation) {
      return this.apply([
        RealtimeOpsBuilder.objectReplace(annotation.border, newBorder, [
          'a',
          pageNumber,
          id,
          'border',
        ]),
      ]);
    }
    return Promise.resolve(this);
  }
}
