import { useIntl } from 'react-intl';
import { useParams } from 'react-router';

import { useGetObjectQuery } from 'App/redux/objectApi';
import { useGetMetadataListQuery } from 'App/redux/MetadataApi';

import { Permissions, TabMenu, MenuItem, AuditLog } from '_common/components';
import Metadata from '_common/components/Metadata/Metadata';

import SharedSpaceSettings from './SharedSpaceSettings/SharedSpaceSettings';
import PersonalSpaceSettings from './PersonalSpaceSettings/PersonalSpaceSettings';
import GeneralSettings from './GeneralSettings/GeneralSettings';
import PublicLinkView from './PublicLinkView/PublicLinkView';
import { useGetCurrentUserQuery } from '_common/services/api/authority';
import { ModalData } from 'App/ModalContext/ModalContextTypes';
import { useDispatch } from '_common/hooks';
import { setNodeToFocus } from 'Editor/redux/EditorStatusSlice';
import { setAnnotationToFocus } from 'PDF/redux/PDFGeneralSlice';
import { navigateToEditor, navigateToPDF } from 'router/history';

const Body = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { id: objectId, type: objectType }: { id: ObjectId; type: ObjectType } = useParams();

  const { data: object } = useGetObjectQuery(
    { objectId, objectType },
    { skip: !objectId || !objectType },
  );
  const { data: currentUser } = useGetCurrentUserQuery();
  const { data: metadata } = useGetMetadataListQuery(undefined, {
    selectFromResult: (result) => ({ ...result, data: result.data ?? {} }),
  });

  const navigateToObject: ModalData['DocumentAuditLogModal']['navigateToObject'] = ({
    objectType,
    objectId,
    documentId,
  }) => {
    if (!documentId) {
      return;
    }
    if (object?.type === 'document') {
      dispatch(setNodeToFocus({ objectType, objectId, documentId }));
      navigateToEditor(documentId);
    } else if (object?.type === 'dopdf') {
      if (objectType === 'task') {
        dispatch(setAnnotationToFocus({ objectId, documentId }));
      }
      navigateToPDF(documentId);
    }
    //TODO:PRESENTATION add navigation to presentation node
  };

  switch (object?.type) {
    case 'space':
      if (object.personal) {
        return (
          <TabMenu menuId="objectSettingsPage_space_personal">
            <MenuItem id="general" text={intl.formatMessage({ id: 'settings.tabs.general' })}>
              <PersonalSpaceSettings />
              <div />
            </MenuItem>
          </TabMenu>
        );
      }
      return (
        <TabMenu menuId="objectSettingsPage_space">
          <MenuItem id="general" text={intl.formatMessage({ id: 'settings.tabs.general' })}>
            <SharedSpaceSettings />
          </MenuItem>
          <MenuItem text={intl.formatMessage({ id: 'COLLABORATORS' })} id="permissions">
            <Permissions />
          </MenuItem>
        </TabMenu>
      );
    case 'presentation':
    case 'dopdf':
      return (
        <TabMenu menuId="objectSettingsPage_folder">
          <MenuItem id="general" text={intl.formatMessage({ id: 'settings.tabs.general' })}>
            <GeneralSettings />
          </MenuItem>
          <MenuItem id="permissions" text={intl.formatMessage({ id: 'COLLABORATORS' })}>
            <Permissions />
          </MenuItem>
          <MenuItem
            text={intl.formatMessage({ id: 'AUDIT_LOG' })}
            id="auditlog"
            hidden={!object.user_permissions.includes('owner')}
          >
            <AuditLog
              objectId={object.id}
              objectType={object.type}
              margin="2rem 0 0 0"
              navigateToObject={navigateToObject}
            />
          </MenuItem>
        </TabMenu>
      );
    case 'folder':
      return (
        <TabMenu menuId="objectSettingsPage_folder">
          <MenuItem id="general" text={intl.formatMessage({ id: 'settings.tabs.general' })}>
            <GeneralSettings />
          </MenuItem>
          <MenuItem id="permissions" text={intl.formatMessage({ id: 'COLLABORATORS' })}>
            <Permissions />
          </MenuItem>
        </TabMenu>
      );
    case 'document':
      return (
        <TabMenu menuId="objectSettingsPage_document">
          <MenuItem id="general" text={intl.formatMessage({ id: 'settings.tabs.general' })}>
            <GeneralSettings />
          </MenuItem>
          <MenuItem id="permissions" text={intl.formatMessage({ id: 'COLLABORATORS' })}>
            <Permissions />
          </MenuItem>
          <MenuItem
            id="metadat"
            text={intl.formatMessage({ id: 'METADATA' })}
            hidden={Object.keys(metadata).length <= 0}
          >
            <Metadata id={object.id} setting />
          </MenuItem>
          <MenuItem
            text={intl.formatMessage({ id: 'AUDIT_LOG' })}
            id="auditlog"
            hidden={!object.user_permissions.includes('owner') && !currentUser?.is_superuser}
          >
            <AuditLog
              objectId={object.id}
              objectType={object.type}
              margin="2rem 0 0 0"
              navigateToObject={navigateToObject}
            />
          </MenuItem>
        </TabMenu>
      );
    case 'file':
      return (
        <TabMenu menuId="objectSettingsPage_file">
          <MenuItem id="general" text={intl.formatMessage({ id: 'settings.tabs.general' })}>
            <GeneralSettings />
          </MenuItem>
          <MenuItem id="permissions" text={intl.formatMessage({ id: 'COLLABORATORS' })}>
            <Permissions />
          </MenuItem>
          {object.user_permissions.includes('owner') && (
            <MenuItem
              id="public-link"
              text={intl.formatMessage({ id: 'settings.publicLink.publicLink' })}
            >
              <PublicLinkView />
            </MenuItem>
          )}
        </TabMenu>
      );
  }

  return null;
};

export default Body;
