import { Ref, forwardRef, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { notify } from '_common/components/ToastSystem';
import { openModal } from 'App/ModalContext/utils';
import { useDispatch, useSelector } from '_common/hooks';
import { usePresentationCollaborators, useSlideId, useTasksOrder } from 'Presentation/hooks';
import { usePresentationManager } from 'Presentation/PresentationManager';
import { selectCard } from 'Presentation/PresentationSlice';

import {
  MinifiedCard,
  AssigneePresentation,
  TaskStatus,
  UserPresentation,
  TaskOptions,
  ViewRichTextEditor,
  WatchToggle,
  ReplyToggle,
  ReplyList,
} from '_common/suite/components/Card';
import { Card } from '_common/suite/components';
import { useSuitePermissions } from '_common/suite';

import type { LikeToggleProps } from '_common/suite/components/Card/LikeToggle/LikeToggle';
import { stringToRichText } from 'utils';
import { InteractionController } from '_common/components';

type ViewTaskCardProps = {
  task: Presentation.Data.Task;
  sidebar?: boolean;
  testId: string;
  setEditMode: (payload: boolean) => void;
};

const ViewTaskCard = forwardRef(
  ({ task, sidebar, setEditMode, testId }: ViewTaskCardProps, ref: Ref<HTMLDivElement>) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const manager = usePresentationManager();
    const slideId = useSlideId();
    const collaborators = usePresentationCollaborators();
    const tasksOrder = useTasksOrder();

    const currentUserId = useSelector((state) => state.auth.userId);
    const isListMode = useSelector((state) => state.presentation.tasks.isListMode);
    const selected = useSelector((state) => state.presentation.general.selectedCard);
    const {
      canComment,
      canWatchTask,
      canEditTask,
      canChangeTaskStatus,
      canDeleteTask,
      canEditTaskReply,
      canDeleteTaskReply,
    } = useSuitePermissions();

    const [showReplies, setShowReplies] = useState(false);

    const isSelected = useMemo(() => {
      return task.id === selected;
    }, [selected, task]);

    const taskState = useMemo(() => {
      return task.deletionDate ? 'Cancelled' : undefined;
    }, [task]);

    const order = useMemo(() => {
      return tasksOrder[task.id];
    }, [task, tasksOrder]);

    const headerBackgroundColor = useMemo(() => {
      if (taskState === 'Cancelled') {
        return 'red';
      } else if (task.status === 'd') {
        return 'green';
      }
    }, [taskState, task]);

    //#region Permissions
    const canEditReply = (replyId: string) => {
      const reply = task.replies?.find((reply) => reply.id === replyId);
      return !!reply && canEditTaskReply(reply);
    };
    const canDeleteReply = (replyId: string) => {
      const reply = task.replies.find((reply) => reply.id === replyId);
      return !!reply && canDeleteTaskReply(reply);
    };
    const canVoteReply = () => {
      return canComment;
    };
    //#endregion

    //#region Card methods
    const handleSelectTask = () => {
      dispatch(selectCard(task.id));
      manager.focusTask(task);
    };

    const handleToggleReplies = () => {
      setShowReplies(!showReplies);
    };
    //#endregion

    //#region Task methods
    const handleEditTask = () => {
      setEditMode(true);
    };

    const handleDeleteTask = () => {
      openModal({
        modal: 'ConfirmationModal',
        data: {
          title: 'DELETE_TASK',
          message: 'DELETE_TASK_MESSAGE',
          messageValues: { value: order },
          cancelButtonTextId: 'global.cancel',
          confirmButtonType: 'danger',
          confirmButtonTextId: 'global.delete',
          headerType: 'error',
          modalWidth: '60rem',
          onConfirm: async () => {
            try {
              await manager.deleteTask(slideId, task.id);

              notify({
                type: 'success',
                title: 'TASK_DELETED',
                message: 'THE_TASK_WAS_SUCCESSFULLY_DELETED',
              });
            } catch (e) {
              throw e;
            }
          },
        },
      });
    };

    const handleChangeStatus = (newStatus: Card.Task.Status) => {
      manager.changeTaskStatus(task.anchor[0].id, task.id, newStatus);
    };

    const handleAddWatcher = (watcherId: string) => {
      manager.addWatcherToTask(task.anchor[0].id, task.id, watcherId);
    };

    const handleRemoveWatcher = (watcherId: string) => {
      manager.removeWatcherFromTask(task.anchor[0].id, task.id, watcherId);
    };

    const handleToggleWatch = () => {
      if (!task.watchers?.includes(currentUserId)) {
        handleAddWatcher(currentUserId);
      } else {
        handleRemoveWatcher(currentUserId);
      }
    };

    const handleOpenWatchModal = () => {
      openModal({
        modal: 'TaskWatchersModal',
        data: {
          authorId: task.authorId ?? '',
          assigneeId: task.assignee ?? undefined,
          watchers: task.watchers,
          collaborators,
          canEditWatch: canComment,
          addWatcher: handleAddWatcher,
          removeWatcher: handleRemoveWatcher,
        },
      });
    };

    const handleReplyToTask = (reply: string) => {
      manager.replyToTask(task.anchor[0].id, task.id, stringToRichText(reply));
    };

    const handleEditReply = ({ replyId, newContent }: { replyId: string; newContent: string }) => {
      // @ts-expect-error Not yet implemented
      manager.editReply(task.anchor[0].id, task.id, replyId, stringToRichText(newContent));
    };

    const handleDeleteReply = (replyId: string) => {
      // @ts-expect-error Not yet implemented
      manager.deleteReply(task.anchor[0].id, task.id, replyId);
    };

    const handleVoteReply: LikeToggleProps['onVote'] = ({ replyId, currentUserLiked }) => {
      if (replyId) {
        manager.voteReplyToTask(task.anchor[0].id, task.id, replyId, currentUserLiked ? 0 : 1);
      }
    };
    //#endregion

    if (sidebar && isListMode && !isSelected) {
      return (
        <MinifiedCard
          status={task.status}
          state={taskState}
          content={task.content.content}
          order={order}
          assignee={task.assignee ?? undefined}
          onClick={handleSelectTask}
          testId={testId}
        />
      );
    }

    return (
      <InteractionController environment="presentation">
        <Card
          sidebar={sidebar}
          selected={isSelected}
          onClick={handleSelectTask}
          testId={testId}
          ref={ref}
        >
          <Card.Header size="large">
            <Card.Header.Left>
              <AssigneePresentation userId={task.assignee ?? undefined} />
            </Card.Header.Left>
            <Card.Header.Right>
              <TaskStatus taskState={taskState} taskStatus={task.status} />
            </Card.Header.Right>
          </Card.Header>
          <Card.DueDateBanner
            dueDate={task.dueDate}
            divider={headerBackgroundColor && !task.dueDate}
          />
          <Card.Body>
            <Card.Body.Header>
              <Card.Header.Left>
                <UserPresentation
                  userId={task.authorId ?? ''}
                  title={intl.formatMessage({ id: 'REPORTER' })}
                />
              </Card.Header.Left>
              <Card.Header.Right>
                <TaskOptions
                  editTask={{ onClick: handleEditTask, disabled: !canEditTask(task) }}
                  deleteTask={{
                    onClick: handleDeleteTask,
                    disabled: !canDeleteTask(task),
                  }}
                  changeStatus={{
                    onClick: handleChangeStatus,
                    disabled: !canChangeTaskStatus,
                  }}
                  watchOptions={{ onClick: handleOpenWatchModal, disabled: false }}
                  testId={testId}
                />
              </Card.Header.Right>
            </Card.Body.Header>
            <ViewRichTextEditor
              initialValue={task.content.content && JSON.stringify(task.content.content)}
              dependencies={[task.content.content]}
              testId={`${testId}-rich-text-editor`}
            />
          </Card.Body>
          <Card.Footer>
            <Card.Footer.Left>
              <WatchToggle
                watchers={task.watchers}
                disabled={!canWatchTask}
                onWatch={handleToggleWatch}
                openWatchModal={handleOpenWatchModal}
                testId={testId}
              />
              <ReplyToggle
                repliesCount={task.replies?.length ?? 0}
                isToggled={showReplies}
                onToggleReplies={handleToggleReplies}
                canComment={canComment}
                id={`${task.id}-replyToggle`}
                testId={testId}
              />
            </Card.Footer.Left>
          </Card.Footer>
          {showReplies && (
            <ReplyList
              replies={task.replies}
              testId={`${testId}-replyList`}
              commentId={task.id}
              collaborators={collaborators}
              newReplyInteraction={{
                environment: 'presentation',
                style: { flexDirection: 'column' },
              }}
              createReply={handleReplyToTask}
              editReply={handleEditReply}
              deleteReply={handleDeleteReply}
              voteReply={handleVoteReply}
              canComment={canComment}
              canEditReply={canEditReply}
              canDeleteReply={canDeleteReply}
              canVoteReply={canVoteReply}
            />
          )}
        </Card>
      </InteractionController>
    );
  },
);

export default ViewTaskCard;
