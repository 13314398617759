export class ActionContext {
  event?: Event;
  actionType?: Editor.Edition.ActionType;
  range: Editor.Selection.JsonRange;
  baseModel?: Editor.Data.Node.Model;
  baseData?: Editor.Data.Node.Data;

  avoidNextNonCollapsedAction: boolean = false;
  avoidCreatePatch: boolean = false;

  // blockNode?: Node | null;
  suggestions: Editor.Edition.SuggestionToUpdate;

  constructor(
    range: Editor.Selection.JsonRange,
    baseModel?: Editor.Data.Node.Model,
    baseData?: Editor.Data.Node.Data,
    event?: Event,
    action?: Editor.Edition.ActionType,
  ) {
    this.baseModel = baseModel;
    this.baseData = baseData;
    this.range = range;
    this.event = event;

    if (action) {
      this.setActionType(action);
    }

    this.suggestions = {};
  }

  setRange(range: Editor.Selection.JsonRange) {
    this.range = range;
    // if (this.baseRange) {
    //   this.blockNode = this.baseRange.blockNode;
    // }
  }

  setModelAndData(baseModel: Editor.Data.Node.Model, baseData?: Editor.Data.Node.Data) {
    this.baseModel = baseModel;
    if (baseData) {
      this.baseData = baseData;
    } else {
      this.baseData = baseModel.selectedData() || undefined;
    }
  }

  refreshBaseData() {
    if (this.baseModel) {
      this.baseData = this.baseModel.selectedData() || undefined;
    }
  }

  setActionType(action: Editor.Edition.ActionType) {
    this.actionType = action;
  }

  addSuggestionToUpdate(
    refId: string,
    insertedContent?: Editor.Data.Suggestions.SuggestionContentData,
    deletedContent?: Editor.Data.Suggestions.SuggestionContentData,
    type?: Editor.Data.Suggestions.SuggestionDataType,
  ) {
    if (!this.suggestions[refId]) {
      this.suggestions[refId] = {
        type: 'INSERT',
      };
    }

    if (insertedContent) {
      const currentContent = this.suggestions[refId].insertedContent;
      if (currentContent != null) {
        if (
          currentContent.type === 'text' &&
          insertedContent.type === 'text' &&
          currentContent.value &&
          insertedContent.value
        ) {
          currentContent.value += insertedContent.value;
        } else if (
          currentContent.type !== 'text' &&
          insertedContent.type === 'text' &&
          insertedContent.value
        ) {
          this.suggestions[refId].insertedContent = insertedContent;
        }
      } else {
        this.suggestions[refId].insertedContent = insertedContent;
      }
    }

    if (deletedContent) {
      const currentContent = this.suggestions[refId].deletedContent;
      if (currentContent != null) {
        if (
          currentContent.type === 'text' &&
          deletedContent.type === 'text' &&
          currentContent.value &&
          deletedContent.value
        ) {
          currentContent.value += deletedContent.value;
        } else if (
          currentContent.type !== 'text' &&
          deletedContent.type === 'text' &&
          deletedContent.value
        ) {
          this.suggestions[refId].deletedContent = deletedContent;
        }
      } else {
        this.suggestions[refId].deletedContent = deletedContent;
      }
    }

    if (type) {
      this.suggestions[refId].type = type;
    } else {
      if (this.suggestions[refId].insertedContent && this.suggestions[refId].deletedContent) {
        this.suggestions[refId].type = 'REPLACE';
      } else if (this.suggestions[refId].insertedContent) {
        this.suggestions[refId].type = 'INSERT';
      } else if (this.suggestions[refId].deletedContent) {
        this.suggestions[refId].type = 'DELETE';
      }
    }
  }

  getSuggestionData(refId: string) {
    if (this.suggestions[refId]) {
      return this.suggestions[refId];
    } else {
      return undefined;
    }
  }
}
