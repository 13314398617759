export const THUMBNAIL_WIDTH = 200;
export const THUMBNAIL_LABEL_GAP = 16;
export const THUMBNAIL_LABEL_HEIGHT = 8;
export const THUMBNAIL_BACKGROUND_PADDING = 8;

export const OUTLINE_CAP = {
  rnd: 'round',
  sq: 'square',
  flat: 'butt',
} as const;

export const OUTLINE_DASHARRAY = {
  solid: 'none',
  dash: [3, 4], //check
  dot: [0, 4],
  dashDot: [3, 4, 1, 4], //check
  lgDash: [7, 4], //check
  lgDashDot: [7, 4, 1, 4], //check
  lgDashDotDot: [7, 4, 1, 4, 1, 4], //check
  sysDot: [1, 2], //check
  sysDash: [2, 2], //check
  sysDashDot: [6, 2, 1, 2],
  sysDashDotDot: [6, 2, 1, 2, 1, 2],
};

export const TEXT_ALIGN_MAP = {
  l: 'left',
  ctr: 'center',
  r: 'right',
  just: 'justify',
  justLow: 'justify',
  dist: 'justify',
  thaiDist: 'justify',
} as const;

export const TEXT_DECORATION_MAP = {
  none: {
    textDecoration: 'none',
  },
  words: {
    textDecoration: 'underline',
  },
  sng: {
    textDecoration: 'solid',
  },
  dbl: {
    textDecoration: 'double',
  },
  heavy: {
    borderBottom: '2px solid',
  },
  dotted: {
    textDecoration: 'dotted',
  },
  dottedHeavy: {
    borderBottom: '1px dotted',
  },
  dash: {
    textDecoration: 'dashed',
  },
  dashHeavy: {
    borderBottom: '1px dashed',
  },
  dashLong: {
    textDecoration: 'underline',
    textDecorationStyle: 'dashed',
  },
  dashLongHeavy: {
    borderBottom: '1px dashed',
    textDecoration: 'underline',
    textDecorationStyle: 'dashed',
  },
  dotDash: {
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
  },
  dotDashHeavy: {
    borderBottom: '1px dotted',
    textDecoration: 'underline',
    textDecorationStyle: 'dashed',
  },
  dotDotDash: {
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
    textDecorationLine: 'overline',
  },
  dotDotDashHeavy: {
    borderBottom: '1px dotted',
    textDecoration: 'underline',
    textDecorationStyle: 'dashed',
    textDecorationLine: 'overline',
  },
  wavy: {
    textDecoration: 'wavy',
  },
  wavyHeavy: {
    borderBottom: '1px wavy',
  },
  wavyDbl: {
    textDecoration: 'double',
    textDecorationStyle: 'wavy',
  },
} as const;

export const MAX_HSL_VALUES = {
  h: 360,
  s: 100,
  l: 100,
};
