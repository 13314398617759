import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { WidgetViewModel } from './WidgetViewModel';
import { PasteOptionsWidgetView } from '../../Views/ViewWidgets/PasteOptionsWidgetView';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';

export class PasteOptionsWidgetModel extends WidgetViewModel<'pasteOptions'> {
  constructor(
    Visualizer: Editor.Visualizer.State,
    refView: Editor.Visualizer.WidgetDataMapper['pasteOptions']['view'],
    props: Editor.Visualizer.WidgetDataMapper['pasteOptions']['props'],
  ) {
    super(Visualizer, refView, props);

    this.container.dataset.type = 'pasteOptions';
  }

  render() {
    let elementToCheck: Node | Range = this.props.range.endContainer;

    const closest = EditorDOMUtils.closest(
      this.props.range.commonAncestorContainer,
      EditorDOMElements.BLOCK_ELEMENTS,
    );

    let offsets;
    if (!EditorDOMElements.isNodeBlockTextElement(closest) && closest instanceof Node) {
      elementToCheck = closest;
    } else {
      let endContainer = this.props.range.endContainer;
      let endOffset = this.props.range.endOffset;
      if (endContainer instanceof Element) {
        let childNode: Node;
        if (endContainer.childNodes.length === endOffset) {
          childNode = endContainer.childNodes[endOffset - 1];
        } else {
          childNode = endContainer.childNodes[endOffset];
        }

        if (childNode instanceof Element) {
          elementToCheck = childNode;
        } else if (childNode instanceof Text) {
          const length = childNode.length;
          elementToCheck = EditorSelectionUtils.createNewRange();
          elementToCheck.setStart(childNode, length);
          elementToCheck.setEnd(childNode, length);
        }
      } else if (endContainer instanceof Text) {
        elementToCheck = EditorSelectionUtils.createNewRange();
        elementToCheck.setStart(endContainer, endOffset);
        elementToCheck.setEnd(endContainer, endOffset);
      }
    }

    if (elementToCheck instanceof Node) {
      offsets = EditorDOMUtils.getOffsets(elementToCheck);
      if (offsets) {
        offsets.left = offsets.left + offsets.width;
        offsets.top = offsets.top + offsets.height;
      }
    } else {
      offsets = EditorDOMUtils.getOffsets(elementToCheck);
      if (offsets && this.container.parentElement) {
        offsets.left =
          offsets.left -
          EditorDOMUtils.convertUnitTo(
            this.container.parentElement.style.marginLeft,
            'px',
            'px',
            2,
          );
      }
    }

    if (offsets) {
      this.container.style.position = 'absolute';
      this.container.style.top = `${offsets.top}px`;

      if (this.Visualizer.getLayoutType?.() === 'PAGE') {
        this.container.style.left = `calc(50% - ${offsets.width / 2}px)`;
      } else {
        this.container.style.left = `${offsets.left}px`;
      }

      this.container.style.height = `${offsets.height}px`;
    }

    logger.trace('PasteOptionsWidgetModel', this.refView, this.props, offsets);

    this.renderReactComponent(<PasteOptionsWidgetView offsets={{ top: 5, right: 0 }} />);
  }
}
