import { memo, Suspense, useEffect } from 'react';
import { usePopper } from 'dodoc-design-system';
import cx from 'classnames';

import { useSelector, useDispatch } from '_common/hooks';
import { usePresentationData } from 'Presentation/PresentationData';
import { selectCard, setCreating } from 'Presentation/PresentationSlice';

import SlideDataProvider from './Slide/SlideData';
import Slide from './Slide/Slide';
import SlideContextMenu from './Slide/SlideContextMenu';
import Comments from './Comments/Comments';

import styles from './Slides.module.scss';
import { InteractionController } from '_common/components';

const Slides = () => {
  const dispatch = useDispatch();
  const { structure, size } = usePresentationData();

  const zoom = useSelector((state) => state.presentation.general.zoom);
  const currentSlide = useSelector((state) => state.presentation.general.currentSlide) - 1;
  const creating = useSelector((state) => state.presentation.general.creating);
  const rightPanelIsOpen = useSelector((state) => state.presentation.general.rightPanel);
  const cursorMode = useSelector((state) => state.presentation.general.cursorMode);

  const { popperProps, referenceProps } = usePopper({ trigger: 'contextmenu' });

  useEffect(() => {
    if (cursorMode === 'comment' || cursorMode === 'task') {
      dispatch(selectCard(null));
    }
  }, [cursorMode]);

  const slideOnClick = () => {
    if (creating && !rightPanelIsOpen) {
      dispatch(setCreating(null));
    }
  };

  return (
    <Suspense fallback={<div>loading slide…</div>}>
      {structure.sldIdLst[currentSlide] && (
        <SlideDataProvider slideId={structure.sldIdLst[currentSlide]}>
          <div
            style={{ position: 'relative', margin: '0 auto' }}
            className={cx({ [styles.creating]: creating?.slide })}
          >
            <InteractionController
              environment="presentation"
              rules={[{ interaction: 'presentation_slide_layer' }]}
            >
              <div
                ref={referenceProps.ref}
                onContextMenuCapture={referenceProps.onContextMenuCapture}
                onClick={slideOnClick}
                style={{ height: size.height * zoom, width: size.width * zoom }}
                id={structure.sldIdLst[currentSlide]}
                data-type="slide"
              >
                <Slide key={structure.sldIdLst[currentSlide]} />
              </div>
            </InteractionController>
            <InteractionController
              environment="presentation"
              rules={[{ interaction: 'presentation_slide_comments_layer' }]}
            >
              <Comments slideId={structure.sldIdLst[currentSlide]} />
            </InteractionController>
          </div>
          <InteractionController environment="presentation" style={{ width: '100%' }}>
            <SlideContextMenu popperProps={popperProps} />
          </InteractionController>
        </SlideDataProvider>
      )}
    </Suspense>
  );
};

export default memo(Slides);
