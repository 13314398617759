import { getNodeContent } from 'PDF/redux/PDFAnnotationsSlice';
import { useComments } from 'Presentation/SyncStore';
import { useSelector } from '_common/hooks';

const useSearchComments = () => {
  const comments = useComments();
  const searchBy = useSelector((state) => state.presentation.comments.searchBy);
  const state = useSelector((state) => state);

  const filterComment = (commentId: Presentation.Data.Comment['id'], value: string) => {
    const comment = comments?.data[commentId];

    if (comment?.content?.content) {
      const content = comment.content.content
        .map((node) => {
          return getNodeContent(node, state);
        })
        .join(' ');
      return content.toLowerCase().includes(value.toLowerCase());
    }

    return false;
  };

  if (searchBy) {
    return comments?.list.filter((id) => filterComment(id, searchBy));
  }

  return comments?.list;
};

export default useSearchComments;
