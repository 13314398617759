import { forwardRef, Ref, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'dodoc-design-system';

import { useDispatch, usePublicProfile, useSelector } from '_common/hooks';
import { usePresentationManager } from 'Presentation/PresentationManager';
import { usePresentationCollaborators, useSlideId } from 'Presentation/hooks';
import { selectCard, setCreating } from 'Presentation/PresentationSlice';

import {
  AssigneeField,
  DescriptionField,
  DueDateField,
  UserPresentation,
} from '_common/suite/components/Card';
import { UserOption } from '_common/components/SearchUser/SearchUser';
import { Card } from '_common/suite/components';
import { notify } from '_common/components/ToastSystem';
import { dayjs } from 'utils';
import { completeAction, setPulseData } from 'App/redux/onboardingSlice';
import { InteractionController } from '_common/components';

export type EditableTaskCardProps = {
  sidebar?: boolean;
  testId: string;
  setEditMode?: (payload: boolean) => void;
} & (
  | {
      mode: 'create';
      task?: undefined;
    }
  | {
      mode: 'edit';
      task: Presentation.Data.Task;
    }
);

const EditableTaskCard = forwardRef(
  (
    { mode, task, sidebar, setEditMode, testId }: EditableTaskCardProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const dispatch = useDispatch();
    const manager = usePresentationManager();

    const collaborators = usePresentationCollaborators();
    const currentSlideId = useSlideId();
    const assigneeProfile = usePublicProfile(task?.assignee ?? '');

    const currentUserId = useSelector((state) => state.auth.userId);
    const creating = useSelector((state) => state.presentation.general.creating);

    const [description, setDescription] = useState<string | undefined>(
      task?.content.content.length ? JSON.stringify(task.content.content) : undefined,
    );
    const [assignee, setAssignee] = useState<UserOption>();
    const [dueDate, setDueDate] = useState<ISODate>();

    //Onboarding
    useEffect(() => {
      if (assignee) {
        dispatch(setPulseData({ taskHasAssignee: true }));
        dispatch(completeAction('presentation_tasks_assignee'));
      }
      if (dueDate) {
        dispatch(setPulseData({ taskHasDueDate: true }));
        dispatch(completeAction('presentation_tasks_dueDate'));
      }
      if (description) {
        dispatch(setPulseData({ taskHasDescription: true }));
      } else {
        dispatch(setPulseData({ taskHasDescription: false }));
      }
    }, [assignee, dueDate, description]);

    useEffect(() => {
      if (mode === 'edit' && task) {
        if (task.content.content.length) {
          setDescription(JSON.stringify(task.content.content));
        }
        if (task.dueDate) {
          setDueDate(task.dueDate);
        }
        if (task.assignee) {
          const assigneeValue: UserOption = {
            value: task.assignee,
            label: task.assignee ? assigneeProfile.name : '',
            avatar: '',
            description: '',
            username: '',
            name: '',
            is_superuser: false,
            type: 'user',
          };
          setAssignee(assigneeValue);
        }
      }
    }, [task, mode]);

    const usersList: UserOption[] = collaborators.map(
      (id) =>
        ({
          id: id,
          type: 'user',
        } as UserOption),
    );

    const handleApply = async () => {
      const payload: Pick<Presentation.Data.Task, 'anchor' | 'content' | 'assignee' | 'dueDate'> = {
        anchor: creating?.anchor ?? [{ id: currentSlideId }],
        content: { dir: 'ltr', content: description ? JSON.parse(description) : [] },
        assignee: assignee?.value ?? '',
        dueDate: dueDate ?? '',
      };

      if (mode === 'create') {
        try {
          const taskId = await manager.addTask({
            ...payload,
            authorId: currentUserId,
          });
          dispatch(selectCard(taskId ?? null));
          dispatch(completeAction('presentation_tasks_createTask'));
        } catch (e) {
          throw e;
        }
      } else if (mode === 'edit' && task) {
        try {
          await manager.editTask({ ...payload, id: task.id });

          notify({
            type: 'success',
            title: 'TASK_EDITED',
            message: 'THE_TASK_WAS_SUCCESSFULLY_EDITED',
          });
          setEditMode?.(false);
        } catch (e) {
          throw e;
        }
      }

      dispatch(setCreating(null));
    };

    const handleCancel = () => {
      if (mode === 'create') {
        dispatch(setCreating(null));
      } else {
        setEditMode?.(false);
      }
    };

    const handleDueDateChange = (newDueDate: Date | null) => {
      setDueDate(newDueDate ? newDueDate.toISOString() : '');
    };

    return (
      <InteractionController environment="presentation">
        <Card
          sidebar={sidebar}
          testId={testId}
          ref={ref}
          id={mode === 'create' ? 'editable-task-card-new' : `editable-task-card-${task.id}`}
        >
          <Card.Header>
            <Card.Header.Left>
              <UserPresentation userId={task?.authorId || currentUserId} />
            </Card.Header.Left>
          </Card.Header>
          <Card.Body>
            <InteractionController
              environment="presentation"
              rules={[{ interaction: 'presentation_task_fill' }]}
              style={{ flexDirection: 'column' }}
            >
              <DescriptionField
                initialValue={description}
                collaborators={collaborators}
                onChange={setDescription}
                testId={testId}
                id="editable-task-card-description"
              />
              <AssigneeField
                collaborators={usersList}
                assignee={assignee}
                onChange={setAssignee}
                testId={testId}
                id="editable-task-card-assignee"
              />
              <DueDateField
                dueDate={dueDate ? dayjs(dueDate).toDate() : undefined}
                onChange={handleDueDateChange}
                testId={testId}
                id="editable-task-card-dueDate"
              />
            </InteractionController>
          </Card.Body>
          <Card.Footer>
            <Card.Footer.Right>
              <Button
                size="small"
                variant="link"
                onClick={handleCancel}
                testId={`${testId}-cancel-button`}
              >
                <FormattedMessage id="global.cancel" />
              </Button>
              <InteractionController
                environment="presentation"
                rules={[{ interaction: 'presentation_task_fill' }]}
              >
                <Button
                  size="small"
                  variant="primary"
                  margin="0 0 0 1rem"
                  disabled={!description}
                  onClick={handleApply}
                  testId={`${testId}-submit-button`}
                  id="editable-task-card-ctaButton"
                >
                  {mode === 'create' ? (
                    <FormattedMessage id="global.create" />
                  ) : (
                    <FormattedMessage id="SAVE_CHANGES" />
                  )}
                </Button>
              </InteractionController>
            </Card.Footer.Right>
          </Card.Footer>
        </Card>
      </InteractionController>
    );
  },
);

export default EditableTaskCard;
