import { pin, shapeArc } from './utils';

type StarProps = {
  size: Presentation.Data.Common.Size;
  strAdjst: Record<string, string> | undefined;
  type:
    | 'star4'
    | 'star5'
    | 'star6'
    | 'star7'
    | 'star8'
    | 'star10'
    | 'star12'
    | 'star16'
    | 'star24'
    | 'star32'
    | 'irregularSeal1'
    | 'irregularSeal2'
    | 'ribbon2'
    | 'ribbon'
    | 'doubleWave'
    | 'wave'
    | 'horizontalScroll'
    | 'verticalScroll'
    | 'ellipseRibbon'
    | 'ellipseRibbon2';
};
//@ts-expect-error
const DEFAULT_ADJST: Record<
  StarProps['type'],
  number | { adj1?: number; adj2?: number; adj3?: number }
> = {
  star4: {
    adj1: 19098,
  },
  star5: {
    adj1: 19098,
  },
  star6: { adj1: 28868 },
  star7: { adj1: 34601 },
  star8: { adj1: 37500 },
  star10: { adj1: 42533 },
  star12: { adj1: 37500 },
  star16: { adj1: 37500 },
  star24: { adj1: 37500 },
  star32: { adj1: 37500 },
  ribbon2: {
    adj1: 16667,
    adj2: 50000,
  },
  ribbon: {
    adj1: 16667,
    adj2: 50000,
  },
  ellipseRibbon: {
    adj1: 25000,
    adj2: 50000,
    adj3: 12500,
  },
  ellipseRibbon2: {
    adj1: 25000,
    adj2: 50000,
    adj3: 12500,
  },
  horizontalScroll: {
    adj1: 12500,
  },
  verticalScroll: {
    adj1: 12500,
  },
  wave: {
    adj1: 12500,
    adj2: 0,
  },
  doubleWave: {
    adj1: 6250,
    adj2: 0,
  },
};

const generateStarPath = ({
  size,
  strAdjst,
  type,
}: StarProps): Presentation.Data.ParsedGeometry => {
  const w = size.width;
  const h = size.height;

  const wd2 = w / 2; //Width / 2
  const hd2 = h / 2; //Height / 2
  const wd4 = w / 4; //Width / 4
  const hd4 = h / 4; //Height / 4

  const t = 0;
  const r = w;
  const b = h;
  const l = 0;
  const hc = wd2; //Horizontal center
  const vc = hd2; //Vertical center

  const maxAdjst = 50000;

  if (strAdjst) {
    strAdjst.adj1 = strAdjst.adj || strAdjst.adj1;
  }

  //@ts-expect-error
  const adj1 = strAdjst?.adj1 ? +strAdjst?.adj1 : DEFAULT_ADJST[type]?.adj1;
  //@ts-expect-error
  const adj2 = strAdjst?.adj2 ? +strAdjst?.adj2 : DEFAULT_ADJST[type]?.adj2;
  //@ts-expect-error
  const adj3 = strAdjst?.adj3 ? +strAdjst?.adj3 : DEFAULT_ADJST[type]?.adj3;

  const a = Math.max(Math.min(maxAdjst, adj1), 0);

  switch (type) {
    case 'star4': {
      const iwd2 = (wd2 * a) / 50000;
      const ihd2 = (hd2 * a) / 50000;
      const sdx = iwd2 * Math.cos(0.7853981634); //cd8 = 2700000; (45°) =>
      const sdy = ihd2 * Math.sin(0.7853981634);
      const sx1 = hc - sdx;
      const sx2 = hc + sdx;
      const sy1 = vc - sdy;
      const sy2 = vc + sdy;

      const d =
        `M ${l} ${vc}` +
        `L ${sx1} ${sy1}` +
        `L ${hc} ${t}` +
        `L ${sx2} ${sy1}` +
        `L ${r} ${vc}` +
        `L ${sx2} ${sy2}` +
        `L ${hc} ${b}` +
        `L ${sx1} ${sy2}` +
        ' z';

      return { paths: [{ d }] };
    }
    case 'star5': {
      const hf = 105146;
      const vf = 110557;

      const swd2 = (wd2 * hf) / 100000;
      const shd2 = (hd2 * vf) / 100000;
      const svc = (vc * vf) / 100000;
      const dx1 = swd2 * Math.cos(0.31415926536);
      const dx2 = swd2 * Math.cos(5.3407075111);
      const dy1 = shd2 * Math.sin(0.31415926536);
      const dy2 = shd2 * Math.sin(5.3407075111);
      const x1 = hc - dx1;
      const x2 = hc - dx2;
      const x3 = hc + dx2;
      const x4 = hc + dx1;
      const y1 = svc - dy1;
      const y2 = svc - dy2;
      const iwd2 = (swd2 * a) / maxAdjst;
      const ihd2 = (shd2 * a) / maxAdjst;
      const sdx1 = iwd2 * Math.cos(5.9690260418);
      const sdx2 = iwd2 * Math.cos(0.94247779608);
      const sdy1 = ihd2 * Math.sin(0.94247779608);
      const sdy2 = ihd2 * Math.sin(5.9690260418);
      const sx1 = hc - sdx1;
      const sx2 = hc - sdx2;
      const sx3 = hc + sdx2;
      const sx4 = hc + sdx1;
      const sy1 = svc - sdy1;
      const sy2 = svc - sdy2;
      const sy3 = svc + ihd2;

      const d =
        `M ${x1} ${y1}` +
        `L ${sx2} ${sy1}` +
        `L ${hc} ${t}` +
        `L ${sx3} ${sy1}` +
        `L ${x4} ${y1}` +
        `L ${sx4} ${sy2}` +
        `L ${x3} ${y2}` +
        `L ${hc} ${sy3}` +
        `L ${x2} ${y2}` +
        `L ${sx1} ${sy2}` +
        ' z';

      return { paths: [{ d }] };
    }
    case 'star6': {
      const hf = 115470;

      const swd2 = (wd2 * hf) / 100000;
      const dx1 = swd2 * Math.cos(0.5235987756);
      const x1 = hc - dx1;
      const x2 = hc + dx1;
      const y2 = vc + hd4;
      const iwd2 = (swd2 * a) / maxAdjst;
      const ihd2 = (hd2 * a) / maxAdjst;
      const sdx2 = iwd2 / 2;
      const sx1 = hc - iwd2;
      const sx2 = hc - sdx2;
      const sx3 = hc + sdx2;
      const sx4 = hc + iwd2;
      const sdy1 = ihd2 * Math.sin(1.0471975512);
      const sy1 = vc - sdy1;
      const sy2 = vc + sdy1;

      const d =
        `M ${x1} ${hd4}` +
        `L ${sx2} ${sy1}` +
        `L ${hc} ${t}` +
        `L ${sx3} ${sy1}` +
        `L ${x2} ${hd4}` +
        `L ${sx4} ${vc}` +
        `L ${x2} ${y2}` +
        `L ${sx3} ${sy2}` +
        `L ${hc} ${b}` +
        `L ${sx2} ${sy2}` +
        `L ${x1} ${y2}` +
        `L ${sx1} ${vc}` +
        ' z';

      return { paths: [{ d }] };
    }
    case 'star7': {
      const hf = 102572;
      const vf = 105210;

      const swd2 = (wd2 * hf) / 100000;
      const shd2 = (hd2 * vf) / 100000;
      const svc = (vc * 102572) / 100000;
      const dx1 = (swd2 * 97493) / 100000;
      const dx2 = (swd2 * 78183) / 100000;
      const dx3 = (swd2 * 43388) / 100000;
      const dy1 = (shd2 * 62349) / 100000;
      const dy2 = (shd2 * 22252) / 100000;
      const dy3 = (shd2 * 90097) / 100000;
      const x1 = hc - dx1;
      const x2 = hc - dx2;
      const x3 = hc - dx3;
      const x4 = hc + dx3;
      const x5 = hc + dx2;
      const x6 = hc + dx1;
      const y1 = svc - dy1;
      const y2 = svc + dy2;
      const y3 = svc + dy3;
      const iwd2 = (swd2 * a) / maxAdjst;
      const ihd2 = (shd2 * a) / maxAdjst;
      const sdx1 = (iwd2 * 97493) / 100000;
      const sdx2 = (iwd2 * 78183) / 100000;
      const sdx3 = (iwd2 * 43388) / 100000;
      const sx1 = hc - sdx1;
      const sx2 = hc - sdx2;
      const sx3 = hc - sdx3;
      const sx4 = hc + sdx3;
      const sx5 = hc + sdx2;
      const sx6 = hc + sdx1;
      const sdy1 = (ihd2 * 90097) / 100000;
      const sdy2 = (ihd2 * 22252) / 100000;
      const sdy3 = (ihd2 * 62349) / 100000;
      const sy1 = svc - sdy1;
      const sy2 = svc - sdy2;
      const sy3 = svc + sdy3;
      const sy4 = svc + ihd2;

      //Star has weird offset
      const d =
        `M ${x1} ${y2}` +
        `L ${sx1} ${sy2}` +
        `L ${x2} ${y1}` +
        `L ${sx3} ${sy1}` +
        `L ${hc} ${t}` +
        `L ${sx4} ${sy1}` +
        `L ${x5} ${y1}` +
        `L ${sx6} ${sy2}` +
        `L ${x6} ${y2}` +
        `L ${sx5} ${sy3}` +
        `L ${x4} ${y3}` +
        `L ${hc} ${sy4}` +
        `L ${x3} ${y3}` +
        `L ${sx2} ${sy3}` +
        ' z';

      return { paths: [{ d }] };
    }
    case 'star8': {
      const dx1 = wd2 * Math.cos(0.7853981634); //2700000
      const x1 = hc - dx1;
      const x2 = hc + dx1;
      const dy1 = hd2 * Math.sin(0.7853981634); //2700000
      const y1 = vc - dy1;
      const y2 = vc + dy1;
      const iwd2 = (wd2 * a) / maxAdjst;
      const ihd2 = (hd2 * a) / maxAdjst;
      const sdx1 = (iwd2 * 92388) / 100000;
      const sdx2 = (iwd2 * 38268) / 100000;
      const sdy1 = (ihd2 * 92388) / 100000;
      const sdy2 = (ihd2 * 38268) / 100000;
      const sx1 = hc - sdx1;
      const sx2 = hc - sdx2;
      const sx3 = hc + sdx2;
      const sx4 = hc + sdx1;
      const sy1 = vc - sdy1;
      const sy2 = vc - sdy2;
      const sy3 = vc + sdy2;
      const sy4 = vc + sdy1;

      const d =
        `M ${l} ${vc}` +
        `L ${sx1} ${sy2}` +
        `L ${x1} ${y1}` +
        `L ${sx2} ${sy1}` +
        `L ${hc} ${t}` +
        `L ${sx3} ${sy1}` +
        `L ${x2} ${y1}` +
        `L ${sx4} ${sy2}` +
        `L ${r} ${vc}` +
        `L ${sx4} ${sy3}` +
        `L ${x2} ${y2}` +
        `L ${sx3} ${sy4}` +
        `L ${hc} ${b}` +
        `L ${sx2} ${sy4}` +
        `L ${x1} ${y2}` +
        `L ${sx1} ${sy3}` +
        ' z';

      return { paths: [{ d }] };
    }
    case 'star10': {
      const swd2 = (wd2 * 105146) / 100000;
      const dx1 = (swd2 * 95106) / 100000;
      const dx2 = (swd2 * 58779) / 100000;
      const x1 = hc - dx1;
      const x2 = hc - dx2;
      const x3 = hc + dx2;
      const x4 = hc + dx1;
      const dy1 = (hd2 * 80902) / 100000;
      const dy2 = (hd2 * 30902) / 100000;
      const y1 = vc - dy1;
      const y2 = vc - dy2;
      const y3 = vc + dy2;
      const y4 = vc + dy1;
      const iwd2 = (swd2 * a) / maxAdjst;
      const ihd2 = (hd2 * a) / maxAdjst;
      const sdx1 = (iwd2 * 80902) / 100000;
      const sdx2 = (iwd2 * 30902) / 100000;
      const sdy1 = (ihd2 * 95106) / 100000;
      const sdy2 = (ihd2 * 58779) / 100000;
      const sx1 = hc - iwd2;
      const sx2 = hc - sdx1;
      const sx3 = hc - sdx2;
      const sx4 = hc + sdx2;
      const sx5 = hc + sdx1;
      const sx6 = hc + iwd2;
      const sy1 = vc - sdy1;
      const sy2 = vc - sdy2;
      const sy3 = vc + sdy2;
      const sy4 = vc + sdy1;

      const d =
        `M ${x1} ${y2}` +
        `L ${sx2} ${sy2}` +
        `L ${x2} ${y1}` +
        `L ${sx3} ${sy1}` +
        `L ${hc} ${t}` +
        `L ${sx4} ${sy1}` +
        `L ${x3} ${y1}` +
        `L ${sx5} ${sy2}` +
        `L ${x4} ${y2}` +
        `L ${sx6} ${vc}` +
        `L ${x4} ${y3}` +
        `L ${sx5} ${sy3}` +
        `L ${x3} ${y4}` +
        `L ${sx4} ${sy4}` +
        `L ${hc} ${b}` +
        `L ${sx3} ${sy4}` +
        `L ${x2} ${y4}` +
        `L ${sx2} ${sy3}` +
        `L ${x1} ${y3}` +
        `L ${sx1} ${vc}` +
        ' z';

      return { paths: [{ d }] };
    }
    case 'star12': {
      const dx1 = wd2 * Math.cos(0.5235987756);
      const dy1 = hd2 * Math.sin(1.0471975512);
      const x1 = hc - dx1;
      const x3 = r - wd4;
      const x4 = hc + dx1;
      const y1 = vc - dy1;
      const y3 = b - hd4;
      const y4 = vc + dy1;
      const iwd2 = (wd2 * a) / maxAdjst;
      const ihd2 = (hd2 * a) / maxAdjst;
      const sdx1 = iwd2 * Math.cos(0.2617993878); //900000->15
      const sdx2 = iwd2 * Math.cos(0.7853981634);
      const sdx3 = iwd2 * Math.cos(1.308996939); //4500000->75
      const sdy1 = ihd2 * Math.sin(1.308996939);
      const sdy2 = ihd2 * Math.sin(0.7853981634);
      const sdy3 = ihd2 * Math.sin(0.2617993878);
      const sx1 = hc - sdx1;
      const sx2 = hc - sdx2;
      const sx3 = hc - sdx3;
      const sx4 = hc + sdx3;
      const sx5 = hc + sdx2;
      const sx6 = hc + sdx1;
      const sy1 = vc - sdy1;
      const sy2 = vc - sdy2;
      const sy3 = vc - sdy3;
      const sy4 = vc + sdy3;
      const sy5 = vc + sdy2;
      const sy6 = vc + sdy1;

      const d =
        `M ${l} ${vc}` +
        `L ${sx1} ${sy3}` +
        `L ${x1} ${hd4}` +
        `L ${sx2} ${sy2}` +
        `L ${wd4} ${y1}` +
        `L ${sx3} ${sy1}` +
        `L ${hc} ${t}` +
        `L ${sx4} ${sy1}` +
        `L ${x3} ${y1}` +
        `L ${sx5} ${sy2}` +
        `L ${x4} ${hd4}` +
        `L ${sx6} ${sy3}` +
        `L ${r} ${vc}` +
        `L ${sx6} ${sy4}` +
        `L ${x4} ${y3}` +
        `L ${sx5} ${sy5}` +
        `L ${x3} ${y4}` +
        `L ${sx4} ${sy6}` +
        `L ${hc} ${b}` +
        `L ${sx3} ${sy6}` +
        `L ${wd4} ${y4}` +
        `L ${sx2} ${sy5}` +
        `L ${x1} ${y3}` +
        `L ${sx1} ${sy4}` +
        ' z';

      return { paths: [{ d }] };
    }
    case 'star16': {
      const dx1 = (wd2 * 92388) / 100000;
      const dx2 = (wd2 * 70711) / 100000;
      const dx3 = (wd2 * 38268) / 100000;
      const dy1 = (hd2 * 92388) / 100000;
      const dy2 = (hd2 * 70711) / 100000;
      const dy3 = (hd2 * 38268) / 100000;
      const x1 = hc - dx1;
      const x2 = hc - dx2;
      const x3 = hc - dx3;
      const x4 = hc + dx3;
      const x5 = hc + dx2;
      const x6 = hc + dx1;
      const y1 = vc - dy1;
      const y2 = vc - dy2;
      const y3 = vc - dy3;
      const y4 = vc + dy3;
      const y5 = vc + dy2;
      const y6 = vc + dy1;
      const iwd2 = (wd2 * a) / maxAdjst;
      const ihd2 = (hd2 * a) / maxAdjst;
      const sdx1 = (iwd2 * 98079) / 100000;
      const sdx2 = (iwd2 * 83147) / 100000;
      const sdx3 = (iwd2 * 55557) / 100000;
      const sdx4 = (iwd2 * 19509) / 100000;
      const sdy1 = (ihd2 * 98079) / 100000;
      const sdy2 = (ihd2 * 83147) / 100000;
      const sdy3 = (ihd2 * 55557) / 100000;
      const sdy4 = (ihd2 * 19509) / 100000;
      const sx1 = hc - sdx1;
      const sx2 = hc - sdx2;
      const sx3 = hc - sdx3;
      const sx4 = hc - sdx4;
      const sx5 = hc + sdx4;
      const sx6 = hc + sdx3;
      const sx7 = hc + sdx2;
      const sx8 = hc + sdx1;
      const sy1 = vc - sdy1;
      const sy2 = vc - sdy2;
      const sy3 = vc - sdy3;
      const sy4 = vc - sdy4;
      const sy5 = vc + sdy4;
      const sy6 = vc + sdy3;
      const sy7 = vc + sdy2;
      const sy8 = vc + sdy1;

      const d =
        `M ${l} ${vc}` +
        `L ${sx1} ${sy4}` +
        `L ${x1} ${y3}` +
        `L ${sx2} ${sy3}` +
        `L ${x2} ${y2}` +
        `L ${sx3} ${sy2}` +
        `L ${x3} ${y1}` +
        `L ${sx4} ${sy1}` +
        `L ${hc} ${t}` +
        `L ${sx5} ${sy1}` +
        `L ${x4} ${y1}` +
        `L ${sx6} ${sy2}` +
        `L ${x5} ${y2}` +
        `L ${sx7} ${sy3}` +
        `L ${x6} ${y3}` +
        `L ${sx8} ${sy4}` +
        `L ${r} ${vc}` +
        `L ${sx8} ${sy5}` +
        `L ${x6} ${y4}` +
        `L ${sx7} ${sy6}` +
        `L ${x5} ${y5}` +
        `L ${sx6} ${sy7}` +
        `L ${x4} ${y6}` +
        `L ${sx5} ${sy8}` +
        `L ${hc} ${b}` +
        `L ${sx4} ${sy8}` +
        `L ${x3} ${y6}` +
        `L ${sx3} ${sy7}` +
        `L ${x2} ${y5}` +
        `L ${sx2} ${sy6}` +
        `L ${x1} ${y4}` +
        `L ${sx1} ${sy5}` +
        ' z';

      return { paths: [{ d }] };
    }
    case 'star24': {
      const dx1 = wd2 * Math.cos(0.2617993878);
      const dx2 = wd2 * Math.cos(0.5235987756);
      const dx3 = wd2 * Math.cos(0.7853981634);
      const dx4 = wd4;
      const dx5 = wd2 * Math.cos(1.308996939);
      const dy1 = hd2 * Math.sin(1.308996939);
      const dy2 = hd2 * Math.sin(1.0471975512);
      const dy3 = hd2 * Math.sin(0.7853981634);
      const dy4 = hd4;
      const dy5 = hd2 * Math.sin(0.2617993878);
      const x1 = hc - dx1;
      const x2 = hc - dx2;
      const x3 = hc - dx3;
      const x4 = hc - dx4;
      const x5 = hc - dx5;
      const x6 = hc + dx5;
      const x7 = hc + dx4;
      const x8 = hc + dx3;
      const x9 = hc + dx2;
      const x10 = hc + dx1;
      const y1 = vc - dy1;
      const y2 = vc - dy2;
      const y3 = vc - dy3;
      const y4 = vc - dy4;
      const y5 = vc - dy5;
      const y6 = vc + dy5;
      const y7 = vc + dy4;
      const y8 = vc + dy3;
      const y9 = vc + dy2;
      const y10 = vc + dy1;
      const iwd2 = (wd2 * a) / maxAdjst;
      const ihd2 = (hd2 * a) / maxAdjst;
      const sdx1 = (iwd2 * 99144) / 100000;
      const sdx2 = (iwd2 * 92388) / 100000;
      const sdx3 = (iwd2 * 79335) / 100000;
      const sdx4 = (iwd2 * 60876) / 100000;
      const sdx5 = (iwd2 * 38268) / 100000;
      const sdx6 = (iwd2 * 13053) / 100000;
      const sdy1 = (ihd2 * 99144) / 100000;
      const sdy2 = (ihd2 * 92388) / 100000;
      const sdy3 = (ihd2 * 79335) / 100000;
      const sdy4 = (ihd2 * 60876) / 100000;
      const sdy5 = (ihd2 * 38268) / 100000;
      const sdy6 = (ihd2 * 13053) / 100000;
      const sx1 = hc - sdx1;
      const sx2 = hc - sdx2;
      const sx3 = hc - sdx3;
      const sx4 = hc - sdx4;
      const sx5 = hc - sdx5;
      const sx6 = hc - sdx6;
      const sx7 = hc + sdx6;
      const sx8 = hc + sdx5;
      const sx9 = hc + sdx4;
      const sx10 = hc + sdx3;
      const sx11 = hc + sdx2;
      const sx12 = hc + sdx1;
      const sy1 = vc - sdy1;
      const sy2 = vc - sdy2;
      const sy3 = vc - sdy3;
      const sy4 = vc - sdy4;
      const sy5 = vc - sdy5;
      const sy6 = vc - sdy6;
      const sy7 = vc + sdy6;
      const sy8 = vc + sdy5;
      const sy9 = vc + sdy4;
      const sy10 = vc + sdy3;
      const sy11 = vc + sdy2;
      const sy12 = vc + sdy1;

      const d =
        `M ${l} ${vc}` +
        `L ${sx1} ${sy6}` +
        `L ${x1} ${y5}` +
        `L ${sx2} ${sy5}` +
        `L ${x2} ${y4}` +
        `L ${sx3} ${sy4}` +
        `L ${x3} ${y3}` +
        `L ${sx4} ${sy3}` +
        `L ${x4} ${y2}` +
        `L ${sx5} ${sy2}` +
        `L ${x5} ${y1}` +
        `L ${sx6} ${sy1}` +
        `L ${hc} ${t}` +
        `L ${sx7} ${sy1}` +
        `L ${x6} ${y1}` +
        `L ${sx8} ${sy2}` +
        `L ${x7} ${y2}` +
        `L ${sx9} ${sy3}` +
        `L ${x8} ${y3}` +
        `L ${sx10} ${sy4}` +
        `L ${x9} ${y4}` +
        `L ${sx11} ${sy5}` +
        `L ${x10} ${y5}` +
        `L ${sx12} ${sy6}` +
        `L ${r} ${vc}` +
        `L ${sx12} ${sy7}` +
        `L ${x10} ${y6}` +
        `L ${sx11} ${sy8}` +
        `L ${x9} ${y7}` +
        `L ${sx10} ${sy9}` +
        `L ${x8} ${y8}` +
        `L ${sx9} ${sy10}` +
        `L ${x7} ${y9}` +
        `L ${sx8} ${sy11}` +
        `L ${x6} ${y10}` +
        `L ${sx7} ${sy12}` +
        `L ${hc} ${b}` +
        `L ${sx6} ${sy12}` +
        `L ${x5} ${y10}` +
        `L ${sx5} ${sy11}` +
        `L ${x4} ${y9}` +
        `L ${sx4} ${sy10}` +
        `L ${x3} ${y8}` +
        `L ${sx3} ${sy9}` +
        `L ${x2} ${y7}` +
        `L ${sx2} ${sy8}` +
        `L ${x1} ${y6}` +
        `L ${sx1} ${sy7}` +
        ' z';

      return { paths: [{ d }] };
    }

    case 'star32': {
      const dx1 = (wd2 * 98079) / 100000;
      const dx2 = (wd2 * 92388) / 100000;
      const dx3 = (wd2 * 83147) / 100000;
      const dx4 = wd2 * Math.cos(0.7853981634);
      const dx5 = (wd2 * 55557) / 100000;
      const dx6 = (wd2 * 38268) / 100000;
      const dx7 = (wd2 * 19509) / 100000;
      const dy1 = (hd2 * 98079) / 100000;
      const dy2 = (hd2 * 92388) / 100000;
      const dy3 = (hd2 * 83147) / 100000;
      const dy4 = hd2 * Math.sin(0.7853981634);
      const dy5 = (hd2 * 55557) / 100000;
      const dy6 = (hd2 * 38268) / 100000;
      const dy7 = (hd2 * 19509) / 100000;
      const x1 = hc - dx1;
      const x2 = hc - dx2;
      const x3 = hc - dx3;
      const x4 = hc - dx4;
      const x5 = hc - dx5;
      const x6 = hc - dx6;
      const x7 = hc - dx7;
      const x8 = hc + dx7;
      const x9 = hc + dx6;
      const x10 = hc + dx5;
      const x11 = hc + dx4;
      const x12 = hc + dx3;
      const x13 = hc + dx2;
      const x14 = hc + dx1;
      const y1 = vc - dy1;
      const y2 = vc - dy2;
      const y3 = vc - dy3;
      const y4 = vc - dy4;
      const y5 = vc - dy5;
      const y6 = vc - dy6;
      const y7 = vc - dy7;
      const y8 = vc + dy7;
      const y9 = vc + dy6;
      const y10 = vc + dy5;
      const y11 = vc + dy4;
      const y12 = vc + dy3;
      const y13 = vc + dy2;
      const y14 = vc + dy1;
      const iwd2 = (wd2 * a) / maxAdjst;
      const ihd2 = (hd2 * a) / maxAdjst;
      const sdx1 = (iwd2 * 99518) / 100000;
      const sdx2 = (iwd2 * 95694) / 100000;
      const sdx3 = (iwd2 * 88192) / 100000;
      const sdx4 = (iwd2 * 77301) / 100000;
      const sdx5 = (iwd2 * 63439) / 100000;
      const sdx6 = (iwd2 * 47140) / 100000;
      const sdx7 = (iwd2 * 29028) / 100000;
      const sdx8 = (iwd2 * 9802) / 100000;
      const sdy1 = (ihd2 * 99518) / 100000;
      const sdy2 = (ihd2 * 95694) / 100000;
      const sdy3 = (ihd2 * 88192) / 100000;
      const sdy4 = (ihd2 * 77301) / 100000;
      const sdy5 = (ihd2 * 63439) / 100000;
      const sdy6 = (ihd2 * 47140) / 100000;
      const sdy7 = (ihd2 * 29028) / 100000;
      const sdy8 = (ihd2 * 9802) / 100000;
      const sx1 = hc - sdx1;
      const sx2 = hc - sdx2;
      const sx3 = hc - sdx3;
      const sx4 = hc - sdx4;
      const sx5 = hc - sdx5;
      const sx6 = hc - sdx6;
      const sx7 = hc - sdx7;
      const sx8 = hc - sdx8;
      const sx9 = hc + sdx8;
      const sx10 = hc + sdx7;
      const sx11 = hc + sdx6;
      const sx12 = hc + sdx5;
      const sx13 = hc + sdx4;
      const sx14 = hc + sdx3;
      const sx15 = hc + sdx2;
      const sx16 = hc + sdx1;
      const sy1 = vc - sdy1;
      const sy2 = vc - sdy2;
      const sy3 = vc - sdy3;
      const sy4 = vc - sdy4;
      const sy5 = vc - sdy5;
      const sy6 = vc - sdy6;
      const sy7 = vc - sdy7;
      const sy8 = vc - sdy8;
      const sy9 = vc + sdy8;
      const sy10 = vc + sdy7;
      const sy11 = vc + sdy6;
      const sy12 = vc + sdy5;
      const sy13 = vc + sdy4;
      const sy14 = vc + sdy3;
      const sy15 = vc + sdy2;
      const sy16 = vc + sdy1;

      const d =
        `M ${l} ${vc}` +
        `L ${sx1} ${sy8}` +
        `L ${x1} ${y7}` +
        `L ${sx2} ${sy7}` +
        `L ${x2} ${y6}` +
        `L ${sx3} ${sy6}` +
        `L ${x3} ${y5}` +
        `L ${sx4} ${sy5}` +
        `L ${x4} ${y4}` +
        `L ${sx5} ${sy4}` +
        `L ${x5} ${y3}` +
        `L ${sx6} ${sy3}` +
        `L ${x6} ${y2}` +
        `L ${sx7} ${sy2}` +
        `L ${x7} ${y1}` +
        `L ${sx8} ${sy1}` +
        `L ${hc} ${t}` +
        `L ${sx9} ${sy1}` +
        `L ${x8} ${y1}` +
        `L ${sx10} ${sy2}` +
        `L ${x9} ${y2}` +
        `L ${sx11} ${sy3}` +
        `L ${x10} ${y3}` +
        `L ${sx12} ${sy4}` +
        `L ${x11} ${y4}` +
        `L ${sx13} ${sy5}` +
        `L ${x12} ${y5}` +
        `L ${sx14} ${sy6}` +
        `L ${x13} ${y6}` +
        `L ${sx15} ${sy7}` +
        `L ${x14} ${y7}` +
        `L ${sx16} ${sy8}` +
        `L ${r} ${vc}` +
        `L ${sx16} ${sy9}` +
        `L ${x14} ${y8}` +
        `L ${sx15} ${sy10}` +
        `L ${x13} ${y9}` +
        `L ${sx14} ${sy11}` +
        `L ${x12} ${y10}` +
        `L ${sx13} ${sy12}` +
        `L ${x11} ${y11}` +
        `L ${sx12} ${sy13}` +
        `L ${x10} ${y12}` +
        `L ${sx11} ${sy14}` +
        `L ${x9} ${y13}` +
        `L ${sx10} ${sy15}` +
        `L ${x8} ${y14}` +
        `L ${sx9} ${sy16}` +
        `L ${hc} ${b}` +
        `L ${sx8} ${sy16}` +
        `L ${x7} ${y14}` +
        `L ${sx7} ${sy15}` +
        `L ${x6} ${y13}` +
        `L ${sx6} ${sy14}` +
        `L ${x5} ${y12}` +
        `L ${sx5} ${sy13}` +
        `L ${x4} ${y11}` +
        `L ${sx4} ${sy12}` +
        `L ${x3} ${y10}` +
        `L ${sx3} ${sy11}` +
        `L ${x2} ${y9}` +
        `L ${sx2} ${sy10}` +
        `L ${x1} ${y8}` +
        `L ${sx1} ${sy9}` +
        ' z';

      return { paths: [{ d }] };
    }
    case 'irregularSeal1': {
      const value = 21600;

      const d = `M
        ${(w * 10800) / value} 
        ${(h * 5800) / value}
        L ${(w * 14522) / value} 
        ${t} 
        L ${(w * 14155) / value} 
        ${(h * 5325) / value} 
        L ${(w * 18380) / value} 
        ${(h * 4457) / value} 
        L ${(w * 16702) / value} 
        ${(h * 7315) / value} 
        L ${(w * 21097) / value} 
        ${(h * 8137) / value} 
        L ${(w * 17607) / value} 
        ${(h * 10475) / value} 
        L ${w} 
        ${(h * 13290) / value} 
        L ${(w * 16837) / value} 
        ${(h * 12942) / value} 
        L ${(w * 18145) / value} 
        ${(h * 18095) / value} 
        L ${(w * 14020) / value} 
        ${(h * 14457) / value} 
        L ${(w * 13247) / value} 
        ${(h * 19737) / value} 
        L ${(w * 10532) / value} 
        ${(h * 14935) / value} 
        L ${(w * 8485) / value} 
        ${h} 
        L ${(w * 7715) / value} 
        ${(h * 15627) / value} 
        L ${(w * 4762) / value} 
        ${(h * 17617) / value} 
        L ${(w * 5667) / value} 
        ${(h * 13937) / value} 
        L ${(w * 135) / value} 
        ${(h * 14587) / value} 
        L ${(w * 3722) / value} 
        ${(h * 11775) / value} 
        L ${l} 
        ${(h * 8615) / value} 
        L ${(w * 4627) / value} 
        ${(h * 7617) / value} 
        L ${(w * 370) / value} 
        ${(h * 2295) / value} 
        L ${(w * 7312) / value} 
        ${(h * 6320) / value} 
        L ${(w * 8352) / value} 
        ${(h * 2295) / value} 
         z`;

      return { paths: [{ d }] };
    }
    case 'irregularSeal2': {
      const value = 21600;
      const d =
        `M
        ${(w * 11462) / value}
        ${(h * 4342) / value}` +
        `L${(w * 14790) / value} ${t}` +
        ` L
        ${(w * 14525) / value} 
        ${(h * 5777) / value}` +
        ` L
        ${(w * 18007) / value}
        ${(h * 3172) / value}` +
        `L
        ${(w * 16380) / value} 
        ${(h * 6532) / value}` +
        `L${w} 
        ${(h * 6645) / value}` +
        `L
        ${(w * 16985) / value} 
        ${(h * 9402) / value}` +
        `L
        ${(w * 18270) / value} 
        ${(h * 11290) / value}` +
        `L
        ${(w * 16380) / value} 
        ${(h * 12310) / value}` +
        `L
        ${(w * 18877) / value} 
        ${(h * 15632) / value}` +
        `L
        ${(w * 14640) / value} 
        ${(h * 14350) / value}` +
        `L
        ${(w * 14942) / value} 
        ${(h * 17370) / value}` +
        `L
        ${(w * 12180) / value} 
        ${(h * 15935) / value}` +
        `L
        ${(w * 11612) / value}
        ${(h * 18842) / value}` +
        `L
        ${(w * 9872) / value} 
        ${(h * 17370) / value}` +
        `L
        ${(w * 8700) / value}
        ${(h * 19712) / value}` +
        `L
        ${(w * 7527) / value} 
        ${(h * 18125) / value}` +
        `L
        ${(w * 4917) / value}
        ${h}` +
        `L
        ${(w * 4805) / value} 
        ${(h * 18240) / value}` +
        `L
        ${(w * 1285) / value} 
        ${(h * 17825) / value}` +
        `L
        ${(w * 3330) / value}
        ${(h * 15370) / value}` +
        ` L
        ${l}
        ${(h * 12877) / value}` +
        `L
        ${(w * 3935) / value}
        ${(h * 11592) / value}` +
        `L
        ${(w * 1172) / value}
        ${(h * 8270) / value}` +
        `L
        ${(w * 5372) / value} 
        ${(h * 7817) / value}` +
        `L
        ${(w * 4502) / value} 
        ${(h * 3625) / value}` +
        `L
        ${(w * 8550) / value} 
        ${(h * 6382) / value}` +
        `L
        ${(w * 9722) / value} 
        ${(h * 1887) / value}` +
        ` z`;

      return { paths: [{ d }] };
    }
    case 'ribbon2': {
      const cnstVal1 = 25000;
      const cnstVal2 = 33333;
      const cnstVal3 = 75000;
      const cnstVal4 = 100000;
      const cnstVal5 = 200000;
      const cnstVal6 = 400000;
      const wd8 = w / 8;
      const wd32 = w / 32;
      const hc = w / 2;
      const a1 = pin(0, adj1, cnstVal2);
      const a2 = pin(cnstVal1, adj2, cnstVal3);
      const x10 = w - wd8;
      const dx2 = (w * a2) / cnstVal5;
      const x2 = hc - dx2;
      const x9 = hc + dx2;
      const x3 = x2 + wd32;
      const x8 = x9 - wd32;
      const x5 = x2 + wd8;
      const x6 = x9 - wd8;
      const x4 = x5 - wd32;
      const x7 = x6 + wd32;
      const hR = (h * a1) / cnstVal6;

      const dy1 = (h * a1) / cnstVal5;
      const y1 = h - dy1;
      const dy2 = (h * a1) / cnstVal4;
      const y2 = h - dy2;
      const y4 = dy2;
      const y3 = (y4 + h) / 2;
      const y6 = h - hR;
      const y7 = y1 - hR;

      const d =
        `M
        ${l} 
       ${h}` +
        `L
       ${wd8} 
       ${y3}` +
        `L
        ${l} 
       ${y4}` +
        `L
       ${x2} 
       ${y4}` +
        `L
       ${x2} 
       ${hR}` +
        shapeArc(x3, hR, wd32, hR, 180, 270, false).replace('M', 'L') +
        `L
       ${x8} 
        ${t}` +
        shapeArc(x8, hR, wd32, hR, 270, 360, false).replace('M', 'L') +
        `L
       ${x9} 
       ${y4}` +
        `L
       ${x9} 
       ${y4}` +
        `L
       ${w}
       ${y4}` +
        `L
       ${x10} 
       ${y3}` +
        `L
       ${w}
       ${h}` +
        `L
       ${x7} 
       ${h}` +
        shapeArc(x7, y6, wd32, hR, 90, 270, false).replace('M', 'L') +
        `L
       ${x8} 
       ${y1}` +
        shapeArc(x8, y7, wd32, hR, 90, -90, false).replace('M', 'L') +
        `L
       ${x3} 
       ${y2}` +
        shapeArc(x3, y7, wd32, hR, 270, 90, false).replace('M', 'L') +
        `L
       ${x4} 
       ${y1}` +
        shapeArc(x4, y6, wd32, hR, 270, 450, false).replace('M', 'L') +
        `z` +
        `M
       ${x5} 
       ${y2}` +
        `L
       ${x5} 
       ${y6}` +
        `M
       ${x6} 
       ${y6}` +
        `L
       ${x6} 
       ${y2}` +
        `M
       ${x2} 
       ${y7}` +
        `L
       ${x2} 
       ${y4}` +
        `M
       ${x9} 
       ${y4}` +
        `L
       ${x9} 
       ${y7}`;
      return { paths: [{ d }] };
    }
    case 'ribbon': {
      const cnstVal1 = 25000;
      const cnstVal2 = 33333;
      const cnstVal3 = 75000;
      const cnstVal4 = 100000;
      const cnstVal5 = 200000;
      const cnstVal6 = 400000;
      const wd8 = w / 8;
      const wd32 = w / 32;
      const a1 = pin(0, adj1, cnstVal2);
      const a2 = pin(cnstVal1, adj2, cnstVal3);
      const x10 = w - wd8;
      const dx2 = (w * a2) / cnstVal5;
      const x2 = hc - dx2;
      const x9 = hc + dx2;
      const x3 = x2 + wd32;
      const x8 = x9 - wd32;
      const x5 = x2 + wd8;
      const x6 = x9 - wd8;
      const x4 = x5 - wd32;
      const x7 = x6 + wd32;
      const y1 = (h * a1) / cnstVal5;
      const y2 = (h * a1) / cnstVal4;
      const y4 = h - y2;
      const y3 = y4 / 2;
      const hR = (h * a1) / cnstVal6;
      const y5 = h - hR;
      const y6 = y2 - hR;

      const d =
        `M
        ${l} 
        ${t}` +
        ` L
        ${x4}
        ${t}` +
        shapeArc(x4, hR, wd32, hR, 270, 450, false).replace('M', 'L') +
        ` L
        ${x3} 
        ${y1}` +
        shapeArc(x3, y6, wd32, hR, 270, 90, false).replace('M', 'L') +
        ` L
        ${x8} 
        ${y2}` +
        shapeArc(x8, y6, wd32, hR, 90, -90, false).replace('M', 'L') +
        ` L
        ${x7} 
        ${y1}` +
        shapeArc(x7, hR, wd32, hR, 90, 270, false).replace('M', 'L') +
        ` L
        ${w} 
        ${t}` +
        ` L
        ${x10} 
        ${y3} ` +
        ` L
        ${w} 
        ${y4}` +
        ` L
        ${x9}
        ${y4}` +
        ` L
        ${x9} 
        ${y5}` +
        shapeArc(x8, y5, wd32, hR, 0, 90, false).replace('M', 'L') +
        ` L 
        ${x3} 
        ${h}` +
        shapeArc(x3, y5, wd32, hR, 90, 180, false).replace('M', 'L') +
        ` L
        ${x2} 
        ${y4}` +
        ` L
        ${l} 
        ${y4}` +
        ` L
        ${wd8} 
        ${y3}` +
        ` z` +
        ` M
        ${x5} 
        ${hR}` +
        ` L
        ${x5} 
        ${y2}` +
        `M
        ${x6} 
        ${y2}` +
        ` L
        ${x6} 
        ${hR}` +
        `M
        ${x2}
        ${y4}` +
        ` L
        ${x2}
        ${y6}` +
        `M
        ${x9}
        ${y6}` +
        ` L
        ${x9} 
        ${y4}`;

      return { paths: [{ d }] };
    }
    case 'ellipseRibbon': {
      const a1 = pin(0, adj1, 100000);
      const a2 = pin(25000, adj2, 75000);
      const q10 = 100000 - a1;
      const q11 = q10 / 2;
      const q12 = a1 - q11;
      const minAdj3 = 0 > q12 ? 0 : q12;
      const a3 = pin(minAdj3, adj3, a1);
      const hc = w / 2;
      const b = h;
      const r = w;
      const wd8 = w / 8;
      let q1;
      const dx2 = (w * a2) / 200000;
      const x2 = hc - dx2;
      const x3 = x2 + wd8;
      const x4 = r - x3;
      const x5 = r - x2;
      const x6 = r - wd8;
      const dy1 = (h * a3) / 100000;
      const f1 = (4 * dy1) / w;
      q1 = (x3 * x3) / w;
      const q2 = x3 - q1;
      const cx1 = x3 / 2;
      const cx2 = r - cx1;
      q1 = (h * a1) / 100000;
      const dy3 = q1 - dy1;
      const q3 = (x2 * x2) / w;
      const q4 = x2 - q3;
      const q5 = f1 * q4;
      const rh = b - q1;
      const q8 = (dy1 * 14) / 16;
      const cx4 = x2 / 2;
      const q9 = f1 * cx4;
      const cx5 = r - cx4;
      const y1 = f1 * q2;
      const cy1 = f1 * cx1;
      const y3 = q5 + dy3;
      const q6 = dy1 + dy3 - y3;
      const q7 = q6 + dy1;
      const cy3 = q7 + dy3;
      const y2 = (q8 + rh) / 2;
      const y5 = q5 + rh;
      const y6 = y3 + rh;
      const cy4 = q9 + rh;
      const cy6 = cy3 + rh;
      const y7 = y1 + dy3;

      const d =
        `M
        ${l}
        ${t}` +
        `Q
        ${cx1}
        ${cy1}
        ${x3}
        ${y1}` +
        `L
        ${x2}
        ${y3}` +
        `Q
        ${hc}
        ${cy3}
        ${x5}
        ${y3}` +
        `L
        ${x4}
        ${y1} ` +
        `Q
        ${cx2}
        ${cy1} 
        ${w}
        ${t}` +
        `L
        ${x6}
        ${y2}` +
        `L
        ${w}
        ${rh}` +
        `Q
        ${cx5}
        ${cy4}
        ${x5}
        ${y5}` +
        `L
        ${x5}
        ${y6}` +
        `Q
        ${hc}
        ${cy6}
        ${x2}
        ${y6}` +
        `L
        ${x2}
        ${y5}` +
        `Q
        ${cx4}
        ${cy4}
        ${l}
        ${rh}` +
        `L
        ${wd8}
        ${y2}` +
        `z` +
        `M
        ${x2}
        ${y5}` +
        `L
        ${x2}
        ${y3}` +
        `M
        ${x5}
        ${y3}` +
        `L
        ${x5}
        ${y5}` +
        `M
        ${x3}
        ${y1}` +
        `L
        ${x3}
        ${y7} ` +
        `M
        ${x4}
        ${y7}` +
        `L
        ${x4}
        ${y1}`;

      return { paths: [{ d }] };
    }
    case 'ellipseRibbon2': {
      const a1 = pin(0, adj1, 100000);
      const a2 = pin(25000, adj2, 75000);
      const q10 = 100000 - a1;
      const q11 = q10 / 2;
      const q12 = a1 - q11;
      const minAdj3 = 0 > q12 ? 0 : q12;
      const a3 = pin(minAdj3, adj3, a1);
      const hc = w / 2;
      const wd8 = w / 8;
      let q1;
      const dx2 = (w * a2) / 200000;
      const x2 = hc - dx2;
      const x3 = x2 + wd8;
      const x4 = w - x3;
      const x5 = w - x2;
      const x6 = w - wd8;
      const dy1 = (h * a3) / 100000;
      const f1 = (4 * dy1) / w;
      q1 = (x3 * x3) / w;
      const q2 = x3 - q1;
      const cx1 = x3 / 2;
      const cx2 = w - cx1;
      q1 = (h * a1) / 100000;
      const dy3 = q1 - dy1;
      const q3 = (x2 * x2) / w;
      const q4 = x2 - q3;
      const q5 = f1 * q4;
      const rh = h - q1;
      const q8 = (dy1 * 14) / 16;
      const cx4 = x2 / 2;
      const q9 = f1 * cx4;
      const cx5 = w - cx4;

      const u1 = f1 * q2;
      const y1 = h - u1;
      const cu1 = f1 * cx1;
      const cy1 = h - cu1;
      const u3 = q5 + dy3;
      const y3 = h - u3;
      const q6 = dy1 + dy3 - u3;
      const q7 = q6 + dy1;
      const cu3 = q7 + dy3;
      const cy3 = h - cu3;
      const u2 = (q8 + rh) / 2;
      const y2 = h - u2;
      const u5 = q5 + rh;
      const y5 = h - u5;
      const u6 = u3 + rh;
      const y6 = h - u6;
      const cu4 = q9 + rh;
      const cy4 = h - cu4;
      const cu6 = cu3 + rh;
      const cy6 = h - cu6;
      const u7 = u1 + dy3;
      const y7 = h - u7;

      const d =
        `M
        ${l}
        ${h}` +
        `L
        ${wd8}
        ${y2} ` +
        `L
        ${l}
        ${q1} ` +
        `Q
        ${cx4}
        ${cy4}
        ${x2}
        ${y5}` +
        `L
        ${x2}
        ${y6}` +
        `Q
        ${hc}
        ${cy6}
        ${x5}
        ${y6}` +
        `L
        ${x5} 
        ${y5} ` +
        `Q
        ${cx5}
        ${cy4}
        ${w}
        ${q1}` +
        `L
        ${x6}
        ${y2}` +
        `L
        ${w}
        ${h}` +
        `Q
        ${cx2}
        ${cy1}
        ${x4}
        ${y1}` +
        `L
        ${x5}
        ${y3}` +
        `Q
        ${hc}
        ${cy3}
        ${x2}
        ${y3}` +
        `L
        ${x3}
        ${y1}` +
        `Q
        ${cx1}
        ${cy1} 
        ${l}
        ${h}` +
        `z` +
        `M
        ${x2}
        ${y3}` +
        `L
        ${x2}
        ${y5}` +
        `M
        ${x5}
        ${y5}` +
        `L
        ${x5}
        ${y3}` +
        `M
        ${x3}
        ${y7}` +
        `L
        ${x3}
        ${y1}` +
        `M
        ${x4}
        ${y1}` +
        `L
        ${x4}
        ${y7}`;

      return { paths: [{ d }] };
    }
    case 'horizontalScroll': {
      const cnstVal1 = 25000;
      const cnstVal2 = 100000;
      const ss = Math.min(w, h);
      const a = adj1 < 0 ? 0 : adj1 > cnstVal1 ? cnstVal1 : adj1;
      const ch = (ss * a) / cnstVal2;
      const ch2 = ch / 2;
      const ch4 = ch / 4;

      const y3 = ch + ch2;
      const y4 = ch + ch;
      const y6 = h - ch;
      const y7 = h - ch2;
      const y5 = y6 - ch2;
      const x3 = w - ch;
      const x4 = w - ch2;

      const d =
        `M
        ${l}
        ${y3}` +
        shapeArc(ch2, y3, ch2, ch2, 180, 270, false).replace('M', 'L') +
        `L
        ${x3}
        ${ch}` +
        `L
        ${x3}
        ${ch2}` +
        shapeArc(x4, ch2, ch2, ch2, 180, 360, false).replace('M', 'L') +
        `L
        ${w}
        ${y5}` +
        shapeArc(x4, y5, ch2, ch2, 0, 90, false).replace('M', 'L') +
        `L
        ${ch}
        ${y6}` +
        `L
        ${ch}
        ${y7}` +
        shapeArc(ch2, y7, ch2, ch2, 0, 180, false).replace('M', 'L') +
        `z` +
        `M
        ${x4}
        ${ch2}` +
        shapeArc(x4, ch2, ch2, ch2, 90, -180, false).replace('M', 'L') +
        shapeArc((x3 + x4) / 2, ch2, ch4, ch4, 180, 0, false).replace('M', 'L') +
        `z` +
        `M
        ${x4}
        ${ch}` +
        `L
        ${x3}
        ${ch}` +
        `M
        ${ch2}
        ${y4}` +
        `L
        ${ch2}
        ${y3}` +
        shapeArc(y3 / 2, y3, ch4, ch4, 180, 360, false).replace('M', 'L') +
        shapeArc(ch2, y3, ch2, ch2, 0, 180, false).replace('M', 'L') +
        `M
        ${ch}
        ${y3}` +
        `L
        ${ch}
        ${y6}`;

      return { paths: [{ d }] };
    }
    case 'verticalScroll': {
      const cnstVal1 = 25000;
      const cnstVal2 = 100000;
      const ss = Math.min(w, h);
      const a1 = adj1 < 0 ? 0 : adj1 > cnstVal1 ? cnstVal1 : adj1;
      const ch = (ss * a1) / cnstVal2;
      const ch2 = ch / 2;
      const ch4 = ch / 4;

      const x3 = ch + ch2;
      const x4 = ch + ch;
      const x6 = w - ch;
      const x7 = w - ch2;
      const x5 = x6 - ch2;
      const y3 = h - ch;
      const y4 = h - ch2;

      const d =
        `M
        ${ch}
        ${y3}` +
        `L
        ${ch}
        ${ch2}` +
        shapeArc(x3, ch2, ch2, ch2, 180, 270, false).replace('M', 'L') +
        `L
        ${x7}
        ${t}` +
        shapeArc(x7, ch2, ch2, ch2, 270, 450, false).replace('M', 'L') +
        `L
        ${x6}
        ${ch}` +
        `L
        ${x6}
        ${y4}` +
        shapeArc(x5, y4, ch2, ch2, 0, 90, false).replace('M', 'L') +
        `L
        ${ch2}
        ${h}` +
        shapeArc(ch2, y4, ch2, ch2, 90, 270, false).replace('M', 'L') +
        `z` +
        `M
        ${x3}
        ${t}` +
        shapeArc(x3, ch2, ch2, ch2, 270, 450, false).replace('M', 'L') +
        shapeArc(x3, x3 / 2, ch4, ch4, 90, 270, false).replace('M', 'L') +
        `L
        ${x4}
        ${ch2}` +
        `M
        ${x6}
        ${ch}` +
        `L
        ${x3}
        ${ch}` +
        `M
        ${ch}
        ${y4}` +
        shapeArc(ch2, y4, ch2, ch2, 0, 270, false).replace('M', 'L') +
        shapeArc(ch2, (y4 + y3) / 2, ch4, ch4, 270, 450, false).replace('M', 'L') +
        `z` +
        `M
        ${ch}
        ${y4}` +
        `L
        ${ch}
        ${y3}`;
      return { paths: [{ d }] };
    }
    case 'wave': {
      const a1 = pin(0, adj1, 20000);
      const a2 = pin(-10000, adj2, 10000);
      const y1 = (h * a1) / 100000;
      const dy2 = (y1 * 10) / 3;
      const y2 = y1 - dy2;
      const y3 = y1 + dy2;
      const y4 = h - y1;
      const y5 = y4 - dy2;
      const y6 = y4 + dy2;
      const of2 = (w * a2) / 50000;
      const dx2 = of2 > 0 ? 0 : of2;
      const x2 = dx2;
      const dx5 = of2 > 0 ? 0 : of2;
      const x5 = w - dx5;
      const dx3 = (dx2 + x5) / 3;
      const x3 = x2 + dx3;
      const x4 = x3 + x5 / 2;
      const x6 = dx5;
      const x10 = w + dx2;
      const x7 = x6 + dx3;
      const x8 = (x7 + x10) / 2;

      const d =
        `M
        ${x2}
        ${y1}` +
        `C
        ${x3}
        ${y2}
        ${x4}
        ${y3}
        ${x5}
        ${y1}` +
        `L
        ${x10}
        ${y4}` +
        `C
        ${x8}
        ${y6} 
        ${x7}
        ${y5}
        ${x6}
        ${y4}` +
        `z`;

      return { paths: [{ d }] };
    }
    case 'doubleWave': {
      const a1 = pin(0, adj1, 12500);
      const a2 = pin(-10000, adj2, 10000);
      const y1 = (h * a1) / 100000;
      const dy2 = (y1 * 10) / 3;
      const y2 = y1 - dy2;
      const y3 = y1 + dy2;
      const y4 = h - y1;
      const y5 = y4 - dy2;
      const y6 = y4 + dy2;
      const of2 = (w * a2) / 50000;
      const dx2 = of2 > 0 ? 0 : of2;
      const x2 = dx2;
      const dx8 = of2 > 0 ? of2 : 0;
      const x8 = w - dx8;
      const dx3 = (dx2 + x8) / 6;
      const x3 = x2 + dx3;
      const dx4 = (dx2 + x8) / 3;
      const x4 = x2 + dx4;
      const x5 = (x2 + x8) / 2;
      const x6 = x5 + dx3;
      const x7 = (x6 + x8) / 2;
      const x9 = dx8;
      const x15 = w + dx2;
      const x10 = x9 + dx3;
      const x11 = x9 + dx4;
      const x12 = (x9 + x15) / 2;
      const x13 = x12 + dx3;
      const x14 = (x13 + x15) / 2;

      const d =
        `M
        ${x2} 
        ${y1}` +
        `C
        ${x3} 
        ${y2} 
        ${x4} 
        ${y3} 
        ${x5} 
        ${y1}` +
        `C
        ${x6} 
        ${y2} 
        ${x7} 
        ${y3} 
        ${x8} 
        ${y1}` +
        `L
        ${x15} 
        ${y4}` +
        `C
        ${x14} 
        ${y6} 
        ${x13} 
        ${y5} 
        ${x12} 
        ${y4} ` +
        `C
        ${x11} 
        ${y6} 
        ${x10} 
        ${y5} 
        ${x9} 
        ${y4}` +
        `z`;

      return { paths: [{ d }] };
    }

    default:
      return { paths: [{ d: '' }] };
  }
};

export default generateStarPath;
