import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from '_common/hooks';
import { Modal, Button, Icon } from 'dodoc-design-system';
import { useDebounce } from '_common/hooks';
import { Logger } from '_common/services';
import { closeModal } from '_common/modals/ModalsSlice';
import { getOutlineList } from 'Editor/redux/listStylesSlice';
import EditorManager from 'Editor/services/EditorManager';
import { ELEMENTS } from 'Editor/services/consts';
import NumberingUtils from '_common/utils/NumberingUtils';

import CaptionPreview from './CaptionPreview/CaptionPreview';
import EditZone from './EditZone/EditZone';

import type { CaptionPreviewProps } from './CaptionPreview/CaptionPreview';
import type { EditZoneProps } from './EditZone/EditZone';
import { notify } from '_common/components/ToastSystem';
import styles from './CaptionsModal.module.scss';

const CaptionsModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open.CaptionsModal);
  const editMode = useSelector((state) => state.modals.CaptionsModal.editMode);
  const selection = useSelector((state) => state.editor.status.selection);
  const paragraphStyles = useSelector((state) => state.editor.styles);
  const outlineLists = useSelector(getOutlineList);

  // CONSTS TO SELECT OPTIONS
  const NUMBERING_TYPES = [
    { value: NumberingUtils.TYPE.DECIMAL, label: intl.formatMessage({ id: 'DECIMAL' }) },
    {
      value: NumberingUtils.TYPE.LOWER_ALPHA,
      label: intl.formatMessage({ id: 'LOWER_ALPHA' }),
    },
    {
      value: NumberingUtils.TYPE.LOWER_ROMAN,
      label: intl.formatMessage({ id: 'LOWER_ROMAN' }),
    },
    {
      value: NumberingUtils.TYPE.UPPER_ALPHA,
      label: intl.formatMessage({ id: 'UPPER_ALPHA' }),
    },
    {
      value: NumberingUtils.TYPE.UPPER_ROMAN,
      label: intl.formatMessage({ id: 'UPPER_ROMAN' }),
    },
  ];
  const SEPARATORS = [
    { value: 'hyphen', char: '-', label: `-  (${intl.formatMessage({ id: 'HYPHEN' })})` },
    { value: 'fullStop', char: '.', label: `.  (${intl.formatMessage({ id: 'FULL_STOP' })})` },
    { value: 'colon', char: ':', label: `:  (${intl.formatMessage({ id: 'COLON' })})` },
    { value: 'emDash', char: '—', label: `—  (${intl.formatMessage({ id: 'EM_DASH' })})` },
    { value: 'enDash', char: '–⁠', label: `–⁠  (${intl.formatMessage({ id: 'EN_DASH' })})` },
  ];
  const POSITIONS = [
    { value: 'above', label: intl.formatMessage({ id: 'ABOVE_SELECTED_ITEM' }) },
    { value: 'below', label: intl.formatMessage({ id: 'BELOW_SELECTED_ITEM' }) },
  ];
  const NUMBERING_DICT = {
    [NumberingUtils.TYPE.DECIMAL]: intl.formatMessage({ id: 'DECIMAL' }),
    [NumberingUtils.TYPE.LOWER_ALPHA]: intl.formatMessage({ id: 'LOWER_ALPHA' }),
    [NumberingUtils.TYPE.LOWER_ROMAN]: intl.formatMessage({ id: 'LOWER_ROMAN' }),
    [NumberingUtils.TYPE.UPPER_ALPHA]: intl.formatMessage({ id: 'UPPER_ALPHA' }),
    [NumberingUtils.TYPE.UPPER_ROMAN]: intl.formatMessage({ id: 'UPPER_ROMAN' }),
  };
  const SEPARATORS_DICT = {
    45: { value: 'hyphen', label: `-  (${intl.formatMessage({ id: 'HYPHEN' })})` },
    46: { value: 'fullStop', label: `.  (${intl.formatMessage({ id: 'FULL_STOP' })})` },
    58: { value: 'colon', label: `:  (${intl.formatMessage({ id: 'COLON' })})` },
    8212: { value: 'emDash', label: `—  (${intl.formatMessage({ id: 'EM_DASH' })})` },
    8211: { value: 'enDash', label: `–⁠  (${intl.formatMessage({ id: 'EN_DASH' })})` },
  };
  const POSITIONS_DICT = {
    above: intl.formatMessage({ id: 'ABOVE_SELECTED_ITEM' }),
    below: intl.formatMessage({ id: 'BELOW_SELECTED_ITEM' }),
  };

  // HOOKS
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [includeChapter, setIncludeChapter] =
    useState<CaptionPreviewProps['includeChapter']>('unchecked');
  const [description, setDescription] = useState('');
  const [chapterStyles, setChapterStyles] = useState<
    Parameters<EditZoneProps['setChapterStylesValue']>[0][]
  >([]);
  const [captionLabelValue, setCaptionLabelValue] = useState<{ value: string; label: string }>({
    value: '',
    label: '',
  });
  const [captionNumberingValue, setCaptionNumberingValue] = useState<
    Parameters<EditZoneProps['setCaptionNumberingValue']>[0]
  >({ value: '', label: '' });
  const [chapterStylesValue, setChapterStylesValue] = useState<
    Parameters<EditZoneProps['setChapterStylesValue']>[0]
  >({ value: '', label: '' });
  const [separatorsValue, setSeparatorsValue] = useState<
    Parameters<EditZoneProps['setSeparatorsValue']>[0]
  >({ value: '', label: '', char: '' });
  const [captionPositionValue, setCaptionPositionValue] = useState<
    Parameters<EditZoneProps['setCaptionPositionValue']>[0]
  >({ value: '', label: '' });
  const [descriptionError, setDescriptionError] = useState('');
  const [oldCaptionNumberingValue, setOldCaptionNumberingValue] = useState({
    value: '',
    label: '',
  });
  const availableLabels = EditorManager.getInstance().getAvailableCaptions() || [];
  const defaultLabels = availableLabels.reduce<{ value: string; label: string }[]>((acc, item) => {
    acc.push({ value: item, label: item });
    return acc;
  }, []);
  const [forceSeparatorUpdate, setForceSeparatorUpdate] =
    useState<EditZoneProps['forceSeparatorUpdate']>('unchecked');
  // CUSTOM HOOKS
  const debouncedDescription = useDebounce(description, 100);

  useEffect(() => {
    setIsCollapsed(EditorManager.getInstance().isSelectionCollapsed());
  }, [isOpen]);

  useEffect(() => {
    if (description && description.length > 4096) {
      setDescriptionError(intl.formatMessage({ id: 'CAPTION_DESC_ERROR' }));
    } else {
      setDescriptionError('');
    }
  }, [debouncedDescription]);

  useEffect(() => {
    if (includeChapter === 'unchecked') {
      setForceSeparatorUpdate('unchecked');
    }
  }, [includeChapter, setForceSeparatorUpdate]);

  useEffect(() => {
    if (captionLabelValue.value && captionLabelValue.label) {
      const editorManager = EditorManager.getInstance();
      const captionInfo = editorManager.getCaption(captionLabelValue.label);
      let numbering = { value: '', label: '' };

      const { list, data } = paragraphStyles;
      const parsedStyles = list.reduce<
        {
          value: (typeof data)[keyof typeof data]['id'];
          label: (typeof data)[keyof typeof data]['n'];
        }[]
      >((acc, current) => {
        const currentItem = data[current];

        if (
          !Object.values(ELEMENTS.ParagraphElement.HEADING_TYPES).includes(
            currentItem.t as Editor.Elements.ParagraphHeadingsType,
          )
        ) {
          return acc;
        }
        return [...acc, { value: currentItem.id, label: currentItem.n }];
      }, []);

      // DEFAULT VALUES
      let chapter = parsedStyles[0];
      let separator = SEPARATORS[0];
      let position = captionLabelValue.label === 'Figure' ? POSITIONS[1] : POSITIONS[0];
      numbering = captionLabelValue.label === 'Table' ? NUMBERING_TYPES[4] : NUMBERING_TYPES[0];

      if (captionInfo) {
        if (captionInfo.numbering) {
          numbering = {
            value: captionInfo.numbering,
            label: NUMBERING_DICT[captionInfo.numbering],
          };
        }
        if (captionInfo.chapter && data[captionInfo.chapter]) {
          chapter = { value: captionInfo.chapter, label: data[captionInfo.chapter].n };
        }
        if (captionInfo.separator) {
          const separatorCode = captionInfo.separator.charCodeAt(0);
          //@ts-expect-error EditorManager.getCaption not typed
          if (SEPARATORS_DICT[separatorCode]) {
            separator = {
              //@ts-expect-error EditorManager.getCaption not typed
              value: SEPARATORS_DICT[separatorCode].value,
              //@ts-expect-error EditorManager.getCaption not typed
              label: SEPARATORS_DICT[separatorCode].label,
              char: captionInfo.separator,
            };
          } else {
            Logger.captureMessage('[TODO] Captions Modal separator character not supported', {
              level: 'info',
              extra: { separator: captionInfo.separator, charCode: separatorCode },
            });
          }
        }
        if (captionInfo.position) {
          position = { value: captionInfo.position, label: POSITIONS_DICT[captionInfo.position] };
        }

        if (captionInfo.separator && captionInfo.chapter) {
          setIncludeChapter('checked');
        } else {
          setIncludeChapter('unchecked');
        }
      }
      setChapterStyles(parsedStyles);
      setChapterStylesValue(chapter);
      setSeparatorsValue(separator);
      setCaptionPositionValue(position);
      setOldCaptionNumberingValue(numbering);
      setCaptionNumberingValue(numbering);
    }
  }, [captionLabelValue, isOpen]);

  useEffect(() => {
    let element = '';
    if (editMode) {
      element = selection.FIELD.label;
    } else {
      switch (true) {
        case selection.EQUATION && !!selection.EQUATION.id:
          element = 'Equation';
          break;
        case selection.IMAGE && !!selection.IMAGE.id:
          element = 'Figure';
          break;
        case selection.TABLE && !!selection.TABLE.id:
          element = 'Table';
          break;
        case selection.TEXT:
          element = 'Figure';
          break;
        default:
          break;
      }
    }
    setCaptionLabelValue({ value: element, label: element });
  }, [selection]);

  const close = () => {
    setDescription('');
    dispatch(closeModal('CaptionsModal'));
  };

  const handleOnSubmit = () => {
    const options: {
      label: string;
      numberingType: string;
      position: string;
      text?: string;
      chapterNumbering?: { chapterType?: string; separator?: string };
      force?: typeof forceSeparatorUpdate;
    } = {
      label: captionLabelValue.value,
      numberingType: captionNumberingValue.value,
      position: captionPositionValue.value,
    };

    if (!editMode) {
      options.text = description;
    }
    if (includeChapter === 'checked') {
      options.chapterNumbering = {
        chapterType: chapterStylesValue.value,
        separator: separatorsValue.char,
      };
    }
    if (forceSeparatorUpdate === 'checked') {
      options.force = forceSeparatorUpdate;
    }

    close();

    const editorManager = EditorManager.getInstance();

    if (editorManager) {
      if (editMode) {
        editorManager.editCaption(options);
        if (oldCaptionNumberingValue.value !== captionNumberingValue.value) {
          notify({
            type: 'success',
            title: 'NUMBERING_FORMAT_EDITED_TITLE',
            message: 'NUMBERING_FORMAT_EDITED_MESSAGE',
            messageValues: { value: captionLabelValue.value },
          });
        }
      } else {
        //@ts-expect-error
        editorManager.insertCaption(options);
      }
    }
  };

  const validateIncludeChapter = () => {
    if (includeChapter === 'checked') {
      if (EditorManager.getInstance().hasStyleAList(chapterStylesValue?.value)) {
        return false;
      }
      return true;
    }
    return false;
  };

  return (
    <Modal
      open={!!isOpen}
      onClose={close}
      width="70rem"
      testId={`${editMode ? 'edit-caption' : 'insert-caption'}`}
    >
      <Modal.Header onClose={close}>
        <FormattedMessage id={editMode ? 'EDIT_CAPTION' : 'INSERT_CAPTION'} />
      </Modal.Header>
      <Modal.Body className={styles.modalRoot}>
        {!editMode && (
          <CaptionPreview
            captionLabelValue={captionLabelValue}
            captionNumberingValue={captionNumberingValue}
            includeChapter={includeChapter}
            separatorsValue={separatorsValue}
            chapterStylesValue={chapterStylesValue}
            descriptionError={descriptionError}
            setDescription={setDescription}
            outlineLists={outlineLists}
          />
        )}
        <EditZone
          editMode={editMode}
          isCollapsed={isCollapsed}
          selection={selection}
          chapterStyles={chapterStyles}
          separators={SEPARATORS}
          numberingTypes={NUMBERING_TYPES}
          labelValues={defaultLabels}
          captionPositions={POSITIONS}
          includeChapter={includeChapter}
          setIncludeChapter={setIncludeChapter}
          captionLabelValue={captionLabelValue}
          setCaptionLabelValue={setCaptionLabelValue}
          captionNumberingValue={captionNumberingValue}
          setCaptionNumberingValue={setCaptionNumberingValue}
          chapterStylesValue={chapterStylesValue}
          setChapterStylesValue={setChapterStylesValue}
          separatorsValue={separatorsValue}
          setSeparatorsValue={setSeparatorsValue}
          captionPositionValue={captionPositionValue}
          setCaptionPositionValue={setCaptionPositionValue}
          forceSeparatorUpdate={forceSeparatorUpdate}
          setForceSeparatorUpdate={setForceSeparatorUpdate}
        />
      </Modal.Body>
      <Modal.Footer alignment="flex-end">
        {validateIncludeChapter() && (
          <div className={editMode ? styles.warningDivEdit : styles.warningDiv}>
            <Icon icon="Warning" size={32} />
            <div className={styles.warningLabel}>
              {intl.formatMessage({ id: 'NO_TEXT_OF_SPECIFIED_STYLE_IN_DOCUMENT' })}
            </div>
          </div>
        )}
        <Button
          size="medium"
          onClick={close}
          testId={`${editMode ? 'edit-caption' : 'insert-caption'}-cancel-button`}
        >
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          disabled={!!descriptionError}
          onClick={handleOnSubmit}
          testId={`${editMode ? 'edit-caption' : 'insert-caption'}-submit-button`}
        >
          <FormattedMessage id={editMode ? 'UPDATE' : 'global.insert'} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CaptionsModal;
