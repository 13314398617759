import { faker } from '@faker-js/faker';

export function createMockedActionsList({
  objectId,
  actions,
}: {
  objectId: ObjectId;
  actions?: Partial<
    Omit<ApiSchemas['ActionSchema'], 'extra' | 'id'> & {
      _id: string;
      extra?: any; //Partial<ApiSchemas['ActionSchema']['extra']>;
    }
  >[];
}): ApiSchemas['ActionSchema'][] {
  const actionLogTemplate = {
    collection: 'objects',
    target: objectId,
    time: 'Tue, 01 May 2022 00:00:00 GMT',
    user: '1',
  } as const;

  const baseActions: NonNullable<typeof actions> = [
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'suggestion_accepted',
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'suggestion_accepted',
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'comment_created', // New
      extra: {
        in_document: true,
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'comment_created', // New
      extra: {
        in_document: false,
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'comment_resolved', // Status update (?)
      extra: {
        in_document: true,
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'comment_reply', // Replies
      extra: {
        in_document: true,
        comment: 'commentId',
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'comment_deleted', // Deletions
      extra: {
        in_document: true,
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'task_created', // New
      extra: {
        in_document: true,
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'task_status_changed', // Status update TODO
      extra: {
        new_status: 'td',
        in_document: true,
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'task_status_changed', // Status update PROGRESS
      extra: {
        new_status: 'pr',
        in_document: true,
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'task_status_changed', // Status update DONE
      extra: {
        new_status: 'd',
        in_document: true,
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'task_replied', // Replies
      extra: {
        in_document: true,
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'task_deleted', // Deletions
      extra: {
        in_document: true,
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'suggestion_accepted', // Acceptance
      extra: {
        in_document: true,
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'suggestion_rejected', // Rejection
      extra: {
        in_document: true,
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'suggestion_replied', // Replies
      extra: {
        in_document: true,
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'permission_added', // Add permission | Add group permission
      extra: {
        to: 'users',
        role: '1',
        subject: '1',
        code: 'add_permission',
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'permission_removed', // Remove permission | Remove group permission
      extra: {
        to: 'users',
        role: '1',
        subject: '1',
        code: 'remove_permission',
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'permission_removed', // Remove all permissions
      extra: {
        to: 'users',
        subject: '1',
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'role_added', // Add role | Add group role
      extra: {
        to: 'users',
        role: '1',
        subject: '1',
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'role_removed', // Remove role | Remove group role
      extra: {
        to: 'users',
        role: '1',
        subject: '1',
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'node_approved', // Approve content
      extra: {
        in_document: true,
        nodes: ['nodeId'],
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'node_reopened', // Reopen content
      extra: {
        in_document: true,
        nodes: ['nodeId'],
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'node_permission_added', // Add content permissions
      extra: {
        code: 'access',
        subject: '1',
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'node_permission_removed', // Remove content permissions
      extra: {
        code: 'access',
        subject: '1',
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'lock_unlock_suggestions', // Track changes Lock On
      extra: { state: true, lock: true },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'lock_unlock_suggestions', // Track changes Lock Off
      extra: { state: false, lock: true },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'lock_unlock_suggestions', // Track changes Unlock
      extra: {},
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'saved_version', // Versioning Save a version
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'restored_version', // Versioning Restore a version
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'new_document_version', // Versioning Upload a new version
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'created', // Created element
      extra: {},
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'downloaded', // Download file
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'edited', //Edited Name
      extra: {
        fields: [{ field: 'name', old: 'oldName', new: 'newName' }],
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'edited', //Edit Description
      extra: {
        fields: [{ field: 'description', new: 'New description' }],
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'edited', //Edited DueDate with (edited)
      extra: {
        fields: [
          {
            field: 'events.due',
            new: 'Wed, 09 Nov 2023 00:00:00 GMT',
            old: 'Wed, 08 Nov 2023 00:00:00 GMT',
          },
        ],
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'edited', //Edited DueDate (added)
      extra: {
        fields: [
          {
            field: 'events.due',
            new: 'Wed, 09 Nov 2023 00:00:00 GMT',
            old: null,
          },
        ],
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'edited', //Edited DueDate (removed)
      extra: {
        fields: [
          {
            field: 'events.due',
            new: null,
            old: 'Wed, 08 Nov 2023 00:00:00 GMT',
          },
        ],
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'edited', //Edited Warning (added)
      extra: {
        fields: [
          {
            field: 'events.warning',
            new: ['Wed, 09 Nov 2023 00:00:00 GMT'],
            old: [],
          },
        ],
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'edited', //Edited Warning (removed)
      extra: {
        fields: [
          {
            field: 'events.warning',
            new: [],
            old: ['Wed, 08 Nov 2023 00:00:00 GMT'],
          },
        ],
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'status_changed', // Approve
      extra: {
        new_status: 'New Status',
        old_status: 'Old Status',
        in_document: true,
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'moved', // Explorer related Move
      extra: {
        destination: {
          parent: '123',
          space: '123',
        },
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'copied', // Explorer related Copy
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'tag_added', // Tag Add
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'tag_removed', // Tag Remove
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'template_changed', // Apply styling template
      extra: {
        template: 'New Template',
        old_template: 'Old Template',
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'exported', // Export document
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'task_assignee_updated', // Add Assignee
      extra: {
        in_document: true,
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'created', // Converted element
      extra: {
        source: 'sourceId',
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'imported', // Imported document
      extra: {
        type: 'document',
      },
    },
    {
      ...actionLogTemplate,
      _id: faker.seed().toString(),
      action: 'imported', // Imported dopdf
      extra: {
        type: 'dopdf',
      },
    },
  ];

  return [
    ...baseActions,
    ...(actions?.map((action) => {
      return { ...actionLogTemplate, ...action, _id: faker.seed().toString() };
    }) ?? []),
  ];
}
