import { NodeDataBuilder, NodeUtils } from 'Editor/services/DataManager';
import { JsonRange, PathUtils } from 'Editor/services/_Common/Selection';
import { ELEMENTS } from 'Editor/services/consts';
import { Logger } from '_common/services';
import { RemoveBlockOperation } from '../../Operations/StructureOperations';
import {
  InsertElementOperation,
  InsertTextOperation,
  RemoveContentOperation,
} from '../../Operations';

export class BaseManipulator {
  protected editionContext: Editor.Edition.Context;

  constructor(ctx: Editor.Edition.Context) {
    this.editionContext = ctx;
  }

  protected getInsertOperation(
    baseModel: Editor.Data.Node.Model,
    path: Editor.Selection.Path,
    dataToAdd: Editor.Data.Node.Data | string,
    options?: Editor.Edition.InsertContentOptions,
  ) {
    if (typeof dataToAdd === 'string') {
      return new InsertTextOperation(baseModel, path, dataToAdd);
    } else {
      return new InsertElementOperation(baseModel, path, dataToAdd, options);
    }
  }

  protected isUserAuthor(data: Editor.Data.Node.TrackedData): boolean {
    try {
      const author = data.properties.author;

      if (author != null && author === this.editionContext.DataManager?.users.loggedUserId) {
        return true;
      }
    } catch (error) {
      Logger.captureException(error);
    }
    return false;
  }

  protected getSuggestionContent(
    suggestionData: Editor.Data.Node.Data[],
  ): Editor.Data.Suggestions.SuggestionContentData | undefined {
    let result: Editor.Data.Suggestions.SuggestionContentData | undefined = undefined;

    for (let i = 0; i < suggestionData.length; i++) {
      let content: string = '';
      if (NodeUtils.isParagraphMarker(suggestionData[i])) {
        content = '\n';
      } else {
        content = NodeUtils.getContentFromData(suggestionData[i], [
          'tracked-insert',
          'tracked-delete',
        ]);
      }

      if (content.length) {
        if (!result || result.type !== 'text') {
          result = {
            type: 'text',
            value: content,
          };
        } else if (result.type === 'text') {
          result.value += content;
        }
      } else {
        const queryResult = NodeUtils.querySelectorInData(suggestionData[i], [
          ELEMENTS.TableElement.ELEMENT_TYPE,
          ELEMENTS.FigureElement.ELEMENT_TYPE,
          ELEMENTS.ImageElement.ELEMENT_TYPE,
          ELEMENTS.CitationsGroupElement.ELEMENT_TYPE,
          ELEMENTS.NoteElement.ELEMENT_TYPE,
          ELEMENTS.EquationElement.ELEMENT_TYPE,
          ELEMENTS.ImageElement.ELEMENT_TYPE,
        ]);

        if (queryResult[0]) {
          if (
            queryResult[0].data.type === 'figure' ||
            queryResult[0].data.type === 'image-element' ||
            queryResult[0].data.type === 'img'
          ) {
            result = {
              type: 'figure',
              value: '',
            };
          } else {
            result = {
              type: queryResult[0].data.type,
              value: '',
            };
          }
        }
      }
    }

    return result;
  }

  protected updateSuggestionContent(
    ctx: Editor.Edition.ActionContext,
    baseModel: Editor.Data.Node.Model,
    pathToElement: Editor.Selection.Path,
  ) {
    if (!this.editionContext.DataManager || !baseModel) {
      return false;
    }

    // important
    const baseData = baseModel.selectedData();
    if (!baseData) {
      return false;
    }

    const trackedData = NodeUtils.closestOfTypeByPath(baseData, pathToElement, [
      ELEMENTS.TrackInsertElement.ELEMENT_TYPE,
      ELEMENTS.TrackDeleteElement.ELEMENT_TYPE,
    ]);

    if (trackedData && NodeUtils.isTrackedData(trackedData.data)) {
      const trackedInsert: Editor.Data.Node.Data[] = [];
      const trackedDelete: Editor.Data.Node.Data[] = [];

      // get suggestion locations
      const suggestionLocations =
        this.editionContext.DataManager.suggestions?.getTrackedActionLocation(
          trackedData.data.properties.element_reference,
        ) || [];

      // filter block Ids
      const blockIds: string[] = [];
      for (let s = 0; s < suggestionLocations.length; s++) {
        if (!blockIds.includes(suggestionLocations[s].level0)) {
          blockIds.push(suggestionLocations[s].level0);
        }
      }

      if (!blockIds.includes(baseModel.id)) {
        blockIds.push(baseModel.id);
      }

      // filter inserted and deleted suggestions
      for (let b = 0; b < blockIds.length; b++) {
        const data = this.editionContext.DataManager.nodes
          .getNodeModelById(blockIds[b])
          ?.selectedData();

        if (data) {
          const result = NodeUtils.querySelectorInData(data, ['tracked-insert', 'tracked-delete'], {
            element_reference: trackedData.data.properties.element_reference,
          });

          for (let i = 0; i < result.length; i++) {
            if (result[i].data.type === 'tracked-insert') {
              trackedInsert.push(result[i].data);
            }

            if (result[i].data.type === 'tracked-delete') {
              trackedDelete.push(result[i].data);
            }
          }
        }
      }

      let insertedContent = this.getSuggestionContent(trackedInsert);
      let deletedContent = this.getSuggestionContent(trackedDelete);

      // add suggestions for update
      ctx.addSuggestionToUpdate(
        trackedData.data.properties.element_reference,
        insertedContent,
        deletedContent,
      );
    }
  }

  protected getRemoveBlockOperation(
    baseModel: Editor.Data.Node.Model,
    blockData: Editor.Data.Node.Data,
    blockPath: Editor.Selection.Path,
  ): Editor.Edition.IOperationBuilder | undefined {
    if (!this.editionContext.DataManager) {
      return undefined;
    }

    let structureModel = this.editionContext.DataManager.structure.structureModel;
    if (!structureModel) {
      return undefined;
    }

    if (baseModel.id === blockData.id) {
      return new RemoveBlockOperation(structureModel, baseModel.id);
    } else {
      const startPath = [...blockPath];
      const endPath = [...blockPath];
      const offset = Number(endPath[endPath.length - 1]);
      if (!isNaN(offset)) {
        endPath[endPath.length - 1] = offset + 1;
      }

      return new RemoveContentOperation(baseModel, startPath, endPath);
    }
  }

  protected removeTrackedParagraphMarkers(
    ctx: Editor.Edition.ActionContext,
    checkAuthor: boolean = false,
    useSelectedCells: boolean = false,
  ) {
    if (!this.editionContext.DataManager) {
      return;
    }

    // TODO
    // check ranges for patagraph markers
    // delete marker and join content
    // update range

    let ranges: Editor.Selection.JsonRange[] = JsonRange.splitRangeByTypes(
      this.editionContext.DataManager,
      ctx.range,
      [...NodeUtils.BLOCK_TEXT_TYPES, ...NodeUtils.BLOCK_NON_TEXT_TYPES],
      {
        onlyContainerLevel: true,
        useSelectedCells: useSelectedCells,
      },
    );

    for (let i = ranges.length - 1; i >= 0; i--) {
      const range = ranges[i];

      const baseModel = this.editionContext.DataManager.nodes.getNodeModelById(range.start.b);

      const baseData = baseModel?.selectedData();
      if (!baseModel || !baseData) {
        continue;
      }

      const closestBlock = NodeUtils.closestOfTypeByPath(baseData, range.getCommonAncestorPath(), [
        ...NodeUtils.BLOCK_TEXT_TYPES,
        // ...NodeUtils.BLOCK_NON_TEXT_TYPES,
      ]);

      // TODO remove track insert elements

      if (
        closestBlock &&
        NodeUtils.isBlockTextData(closestBlock.data) &&
        closestBlock.data.childNodes
      ) {
        const subEndPath = range.end.p.slice(closestBlock.path.length);
        const lastChildIndex = closestBlock.data.childNodes.length - 1;
        const lastChild = closestBlock.data.childNodes[lastChildIndex];
        if (
          NodeUtils.isPathAtContentEnd(closestBlock.data, subEndPath) &&
          NodeUtils.isParagraphMarker(lastChild) &&
          NodeUtils.isTrackInsertData(lastChild) &&
          (!checkAuthor || this.isUserAuthor(lastChild))
        ) {
          const markerStartPath: Editor.Selection.Path = [
            ...closestBlock.path,
            'childNodes',
            lastChildIndex,
          ];
          const markerEndPath: Editor.Selection.Path = [
            ...closestBlock.path,
            'childNodes',
            lastChildIndex + 1,
          ];

          let nextModel: Editor.Data.Node.Model | undefined;
          let nextData: Editor.Data.Node.Data | undefined | null;
          let nextPath: Editor.Selection.Path | undefined;

          // TODO
          // improve this when the replacewith is not next element
          // Example:
          //      Paragraph(marker)
          //      Table
          //      Paragraph
          if (closestBlock.data.id === baseModel.id) {
            let replacewith = lastChild.properties.replacewith;
            // level 0
            nextModel = this.editionContext.DataManager.nodes.getNextModelById(baseModel.id);
            if (nextModel && nextModel.id === replacewith) {
              nextData = nextModel.selectedData();
              nextPath = [];
            }
          } else {
            let replacewithsibling = lastChild.properties.replacewithsibling;
            // inside container
            nextModel = baseModel;
            const next = NodeUtils.getNextSibling(baseData, closestBlock.path);
            if (next && next.data.id === replacewithsibling) {
              nextData = next.data;
              nextPath = next.path;
            }
          }

          if (nextModel && nextData && nextPath) {
            // join content
            let pathToMerge: Editor.Selection.Path | undefined = markerEndPath;

            let cloneStartPath: Editor.Selection.Path = ['childNodes', 0];
            let cloneEndPath: Editor.Selection.Path = [
              'childNodes',
              nextData.childNodes?.length || 0,
            ];

            const clonedNodes = NodeUtils.cloneData(nextData, cloneStartPath, cloneEndPath, true);

            for (let i = 0; i < clonedNodes.length; i++) {
              if (pathToMerge) {
                let insertOp: InsertElementOperation = new InsertElementOperation(
                  baseModel,
                  pathToMerge,
                  clonedNodes[i],
                  { mergeText: false, allowAll: true },
                );
                if (insertOp.hasOpsToApply()) {
                  insertOp.apply();
                  pathToMerge = insertOp.getAdjustedPath();
                }
              }
            }

            // remove block element
            let removeBlockOp = this.getRemoveBlockOperation(nextModel, nextData, nextPath);
            if (removeBlockOp) {
              removeBlockOp.apply();
            }

            // get path to update range
            let splitPointPath = markerStartPath;
            let previousSibling = NodeUtils.getPreviousSibling(baseData, markerStartPath);
            if (previousSibling) {
              if (NodeUtils.isTextData(previousSibling.data)) {
                splitPointPath = [
                  ...previousSibling.path,
                  'content',
                  previousSibling.data.content.length,
                ];
              } else {
                splitPointPath = [
                  ...previousSibling.path,
                  'childNodes',
                  previousSibling.data.childNodes?.length || 0,
                ];
              }
            }

            // remove tracked marker
            const removeMarkerOp = new RemoveContentOperation(
              baseModel,
              markerStartPath,
              markerEndPath,
              { mergeText: false },
            );
            removeMarkerOp.apply();

            // update range
            let nextRange = ranges[i + 1];
            if (
              nextRange &&
              ctx.range.end.b === nextModel.id &&
              ctx.range.end.b === nextRange.end.b
            ) {
              if (PathUtils.isChildPath(nextPath, ctx.range.end.p)) {
                // WARN:
                // problem with merged text nodes after last remove
                // forcing text nodes to not be merged

                // merge only paths within blocks
                const subSPath = markerStartPath.slice(closestBlock.path.length);
                const subEPath = ctx.range.end.p.slice(nextPath.length);

                let newEndPath = PathUtils.transformPath(subEPath, subSPath, false);
                if (newEndPath) {
                  if (PathUtils.comparePath(ctx.range.start.p, splitPointPath) > 0) {
                    ctx.range.updateStartPosition({
                      b: baseModel.id,
                      p: [...splitPointPath],
                    });
                  }

                  ctx.range.updateEndPosition({
                    b: baseModel.id,
                    p: [...closestBlock.path, ...newEndPath],
                  });
                }
              } else {
                if (PathUtils.comparePath(ctx.range.start.p, splitPointPath) > 0) {
                  ctx.range.updateRangePositions({
                    b: baseModel.id,
                    p: [...splitPointPath],
                  });
                } else {
                  ctx.range.updateEndPosition({
                    b: baseModel.id,
                    p: [...splitPointPath],
                  });
                }
              }
            } else if (ctx.range.start.b === ctx.range.end.b) {
              if (PathUtils.comparePath(ctx.range.start.p, splitPointPath) > 0) {
                ctx.range.updateRangePositions({
                  b: baseModel.id,
                  p: [...splitPointPath],
                });
              } else {
                ctx.range.updateEndPosition({
                  b: baseModel.id,
                  p: [...splitPointPath],
                });
              }
            }
          }
        }
      }
    }
  }

  protected handleInsertSplitMarker(
    previousModel: Editor.Data.Node.Model,
    previousPath: Editor.Selection.Path,
    currentModel: Editor.Data.Node.Model,
    currentPath: Editor.Selection.Path,
    refId: string | undefined,
  ) {
    if (!this.editionContext.DataManager) {
      return false;
    }

    const previousData = previousModel.selectedData();
    if (!previousData) {
      return false;
    }

    const currentData = currentModel.selectedData();
    if (!currentData) {
      return false;
    }

    const previousBlock = NodeUtils.closestOfTypeByPath(previousData, previousPath, [
      ...NodeUtils.BLOCK_TEXT_TYPES,
    ]);

    const currentBlock = NodeUtils.closestOfTypeByPath(currentData, currentPath, [
      ...NodeUtils.BLOCK_TEXT_TYPES,
    ]);

    if (
      previousBlock &&
      currentBlock &&
      NodeUtils.isBlockTextData(previousBlock.data) &&
      NodeUtils.isBlockTextData(currentBlock.data)
    ) {
      let previousLastChild: Editor.Data.Node.Data | undefined;

      let length = previousBlock.data.childNodes?.length || 0;
      previousLastChild = previousBlock.data.childNodes?.[length - 1];

      if (!NodeUtils.isParagraphMarker(previousLastChild)) {
        const loggedUserId = this.editionContext.DataManager.users.loggedUserId;

        if (refId && loggedUserId) {
          const markerBuilder = new NodeDataBuilder(ELEMENTS.TrackInsertElement.ELEMENT_TYPE)
            .addProperty('element_reference', refId)
            .addProperty('author', loggedUserId);

          if (previousModel.id === currentModel.id && previousModel.id !== previousData.id) {
            // container element
            markerBuilder.addProperty('replacewithsibling', currentBlock.data.id);
          } else {
            markerBuilder.addProperty('replacewith', currentBlock.data.id);
          }

          const markerData = markerBuilder.build();

          if (markerData) {
            let pathToInsert: Editor.Selection.Path = [
              ...previousBlock.path,
              'childNodes',
              previousBlock.data.childNodes?.length || 0,
            ];
            let op = new InsertElementOperation(previousModel, pathToInsert, markerData);
            op.apply();
          }
        }
      }
    }
  }

  protected getSuggestionRef(
    baseData: Editor.Data.Node.Data | null,
    range: Editor.Selection.JsonRange,
  ): string | undefined {
    if (!baseData) {
      return undefined;
    }

    // check closes start and end
    const closestStartTracked = NodeUtils.closestOfTypeByPath(baseData, range.start.p, [
      ELEMENTS.TrackDeleteElement.ELEMENT_TYPE,
      ELEMENTS.TrackInsertElement.ELEMENT_TYPE,
    ]);

    const closestEndTracked = NodeUtils.closestOfTypeByPath(baseData, range.start.p, [
      ELEMENTS.TrackDeleteElement.ELEMENT_TYPE,
      ELEMENTS.TrackInsertElement.ELEMENT_TYPE,
    ]);

    if (
      closestStartTracked &&
      NodeUtils.isTrackedData(closestStartTracked.data) &&
      this.isUserAuthor(closestStartTracked.data)
    ) {
      return closestStartTracked.data.properties.element_reference;
    }

    if (
      closestEndTracked &&
      NodeUtils.isTrackedData(closestEndTracked.data) &&
      this.isUserAuthor(closestEndTracked.data)
    ) {
      return closestEndTracked.data.properties.element_reference;
    }

    // check ancestors
    const previousAncestor = NodeUtils.getPreviousAncertor(baseData, range.start.p);
    const nextAncestor = NodeUtils.getNextAncestor(baseData, range.end.p);

    let currentAncestor: Editor.Data.Node.DataPathInfo | null = null;
    let currentSubPath: Editor.Selection.Path = [];
    if (previousAncestor) {
      currentAncestor = NodeUtils.getNextSibling(baseData, previousAncestor.path);
      if (currentAncestor) {
        currentSubPath = range.start.p.slice(currentAncestor.path.length);
      }
    } else if (nextAncestor) {
      currentAncestor = NodeUtils.getPreviousSibling(baseData, nextAncestor.path);
      if (currentAncestor) {
        currentSubPath = range.end.p.slice(currentAncestor.path.length);
      }
    }

    if (
      currentAncestor &&
      NodeUtils.isPathAtContentStart(currentAncestor.data, currentSubPath) &&
      previousAncestor
    ) {
      // check previous ancestor
      if (
        NodeUtils.isTrackedData(previousAncestor.data) &&
        this.isUserAuthor(previousAncestor.data)
      ) {
        return previousAncestor.data.properties.element_reference;
      }

      // check last child elements inside previous ancestor
      let length = previousAncestor.data.childNodes?.length || 0;
      let lastChild = previousAncestor.data.childNodes?.[length - 1];
      while (lastChild) {
        if (NodeUtils.isTrackedData(lastChild) && this.isUserAuthor(lastChild)) {
          return lastChild.properties.element_reference;
        }
        length = lastChild.childNodes?.length || 0;
        lastChild = lastChild.childNodes?.[length - 1];
      }
    }

    if (
      currentAncestor &&
      NodeUtils.isPathAtContentEnd(currentAncestor.data, currentSubPath) &&
      nextAncestor
    ) {
      // check next ancestor
      if (NodeUtils.isTrackedData(nextAncestor.data) && this.isUserAuthor(nextAncestor.data)) {
        return nextAncestor.data.properties.element_reference;
      }

      // check first child elements inside previous ancestor
      let firstChild = nextAncestor.data.childNodes?.[0];
      while (firstChild) {
        if (NodeUtils.isTrackedData(firstChild) && this.isUserAuthor(firstChild)) {
          return firstChild.properties.element_reference;
        }
        firstChild = firstChild.childNodes?.[0];
      }
    }
    return NodeUtils.generateUUID();
  }

  protected validateCaptionsOnRange(ctx: Editor.Edition.ActionContext) {
    if (!this.editionContext.DataManager) {
      return false;
    }

    let fieldsData = ctx.range.getNodes(this.editionContext.DataManager, ['f']);

    if (fieldsData) {
      for (let i = 0; i < fieldsData.length; i++) {
        if (NodeUtils.isFieldCaptionData(fieldsData[i].childData)) {
          return true;
        }
      }
    }
    return false;
  }
}
