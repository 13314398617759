type BulletSymbol = Presentation.Data.Bullet['symbol'];

export const isNoBullet = (symbol: BulletSymbol): symbol is Presentation.Data.NoBullet => {
  return (symbol as Presentation.Data.NoBullet).noBullet != null;
};

export const isCharBullet = (symbol: BulletSymbol): symbol is Presentation.Data.CharBullet => {
  return (symbol as Presentation.Data.CharBullet).char != null;
};

export const isNumberedBullet = (
  symbol: BulletSymbol,
): symbol is Presentation.Data.NumberedBullet => {
  return (symbol as Presentation.Data.NumberedBullet).numberingFormat != null;
};

export const isPictureBullet = (
  symbol: BulletSymbol,
): symbol is Presentation.Data.PictureBullet => {
  return (symbol as Presentation.Data.PictureBullet).source != null;
};

export const getBulletType = (symbol: BulletSymbol | undefined) => {
  if (!symbol) {
    return undefined;
  }

  if (symbol.none) {
    return 'none';
  } else if (isNoBullet(symbol)) {
    return 'noBullet';
  } else if (isCharBullet(symbol)) {
    return 'charBullet';
  } else if (isNumberedBullet(symbol)) {
    return 'numberedBullet';
  } else if (isPictureBullet(symbol)) {
    return 'pictureBullet';
  }
};

export const bulletTypeEquals = (a: BulletSymbol | undefined, b: BulletSymbol | undefined) => {
  return getBulletType(a) === getBulletType(b);
};
