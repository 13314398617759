import { BaseRangeFixer } from '../BaseRangeFixer';
import { HTMLSelectionNormalizer, JSONSelectionNormalizer } from '.';

export class TextSelectionNormalizer extends BaseRangeFixer {
  protected options: Partial<Editor.Selection.FixerOptions>;
  protected DataManager?: Editor.Data.API;

  constructor(args: Partial<Editor.Selection.FixerOptions>, DataManager?: Editor.Data.API) {
    super();
    this.DataManager = DataManager;
    this.options = args;
  }

  visitJsonRange(range: Editor.Selection.JsonRange): void {
    if (!this.DataManager) {
      throw new Error('No DataManager provided!');
    }

    let baseModel: Editor.Data.Node.Model | undefined;
    let pathToCheck: Editor.Selection.Path;

    if (this.options.containerPosition === 'end') {
      baseModel = this.DataManager.nodes.getNodeModelById(range.end.b);
      pathToCheck = range.end.p;
    } else {
      baseModel = this.DataManager.nodes.getNodeModelById(range.start.b);
      pathToCheck = range.start.p;
    }

    const baseData = baseModel?.selectedData();

    if (baseData) {
      const fixedPath = new JSONSelectionNormalizer(this.options).normalize(baseData, pathToCheck);

      if (fixedPath) {
        if (this.options.containerPosition === 'end') {
          range.end.p = fixedPath;
        } else if (this.options.containerPosition === 'start') {
          range.start.p = fixedPath;
        } else {
          range.start.p = fixedPath;
          range.end.p = fixedPath;
        }
      }
    }
  }

  visitDoDOCRange(range: Editor.Selection.EditorRange): void {
    new HTMLSelectionNormalizer(this.options).normalize(range);
  }
}
