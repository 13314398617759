import { useGetImageQuery } from 'Presentation/api';
import { useSuiteObject } from '_common/suite';

type PictureBackgroundProps = {
  background: Presentation.Data.Common.PictureFillType;
  size: Presentation.Data.Common.Size;
  position: Presentation.Data.Common.Position;
};

const PictureBackground = ({ background, size, position }: PictureBackgroundProps) => {
  const { id } = useSuiteObject();
  const { data } = useGetImageQuery({ presentation_id: id, reference_id: background.source });
  if (background.tile) {
    return (
      <>
        <defs>
          <pattern
            id={background.source}
            x="0"
            y="0"
            width={background.tile.sx}
            height={background.tile.sy}
            patternUnits="userSpaceOnUse"
          >
            <image href={data} width={background.tile.sx} height={background.tile.sy} />
          </pattern>
        </defs>
        <rect width={size.width} height={size.height} fill={`url(#${background.source})`} />
      </>
    );
  }
  return (
    <>
      <defs>
        <pattern id={background.source} x="0" y="0" width="1" height="1">
          <image href={data} preserveAspectRatio="xMidYMid slice" width="100%" height="100%" />
        </pattern>
      </defs>
      <rect
        x={position.left}
        y={position.top}
        width={size.width}
        height={size.height}
        fill={`url(#${background.source})`}
      />
    </>
  );
};

export default PictureBackground;
