import { useSuiteObject } from '_common/suite';
import { NotificationsCenter } from '_common/components';
import { useComments, useTasks } from './SyncStore';
import { useDispatch, useSelector } from '_common/hooks';
import { notify } from '_common/components/ToastSystem';
import { selectCard } from './PresentationSlice';

const PresentationNotificationsCenter = () => {
  const dispatch = useDispatch();
  const object = useSuiteObject();
  const presentation = useSelector((state) => state.presentation.general);
  const presentationId = presentation?.presentationId ?? '';
  const comments = useComments();
  const tasks = useTasks();

  const handleNavigateToObject = ({
    objectType,
    objectId,
  }: {
    objectType: 'comment' | 'suggestion' | 'task' | 'node' | 'annotation';
    objectId: ObjectId;
    documentId: ObjectId;
  }) => {
    if (presentation && presentationId) {
      let objectNotFound = false;
      if (objectType === 'comment') {
        if (comments?.list.includes(objectId)) {
          dispatch(selectCard(objectId));
        } else {
          objectNotFound = true;
        }
      } else if (objectType === 'task') {
        if (tasks?.list.includes(objectId)) {
          dispatch(selectCard(objectId));
        } else {
          objectNotFound = true;
        }
      }
      if (objectNotFound) {
        notify({
          type: 'error',
          title: 'CARD_TYPE_X_NOT_ACCESSIBLE',
          titleValues: { cardTpe: objectType.charAt(0).toUpperCase() + objectType.slice(1) },
          message: 'LINK_CANNOT_BE_OPENED_BECAUSE_CARD_TYPE_X_DOESNT_EXIST',
          messageValues: { cardType: objectType },
        });
      }
    }
  };

  return (
    <NotificationsCenter
      object={object}
      margin="0 0 0 2rem"
      navigateToObject={handleNavigateToObject}
    />
  );
};

export default PresentationNotificationsCenter;
