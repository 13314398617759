import { MouseEventHandler } from 'react';

import { useDispatch, useSelector } from '_common/hooks';
import { selectCard } from 'Presentation/PresentationSlice';

import CardToggle from 'Presentation/CardToggle/CardToggle';

import styles from './CardHighlight.module.scss';

export type CardHighlightProps = {
  rect: Rect;
} & (
  | {
      object: Presentation.Data.Comment | Presentation.Data.Task;
      type: 'task' | 'comment';
      creating?: false;
    }
  | {
      object?: never;
      type?: never;
      creating: true;
    }
);

const CardHighlight = ({ object, type, rect, creating }: CardHighlightProps) => {
  const dispatch = useDispatch();

  const selectedCard = useSelector((state) => state.presentation.general.selectedCard);
  const editingCardId = useSelector((state) => state.presentation.general.editing);
  const creatingParams = useSelector((state) => state.presentation.general.creating);
  const isOpen = !creating && object.id === selectedCard;

  const handleClick: MouseEventHandler<HTMLSpanElement> = (e) => {
    e.stopPropagation();
    if (!creating) {
      dispatch(selectCard(object.id));
    }
  };

  return (
    <>
      {/* border & card toggle */}
      {isOpen || creating ? (
        <>
          <span
            className={styles.borderRoot}
            style={{
              top: rect.top,
              left: rect.left,
              width: rect.width,
              height: rect.height,
            }}
          />
          <span style={{ position: 'absolute', top: rect.top, left: rect.left + rect.width + 16 }}>
            {creating ? (
              creatingParams && (
                <CardToggle
                  isOpen={true}
                  type={creatingParams.type}
                  mode="create"
                  placement="right-start"
                />
              )
            ) : (
              <CardToggle
                isOpen={isOpen}
                object={object}
                type={type}
                mode={editingCardId === object.id ? 'edit' : undefined}
                placement="right-start"
              />
            )}
          </span>
        </>
      ) : null}
      {/* highlights */}
      <span
        className={styles.highlighRoot}
        style={{
          top: rect.top,
          left: rect.left,
          width: rect.width,
          height: rect.height,
        }}
        onClick={handleClick}
      />
    </>
  );
};

export default CardHighlight;
