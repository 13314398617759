import { MouseEventHandler, memo, useRef } from 'react';

import { useDispatch, useSelector } from '_common/hooks';
import { selectCard, setSelectedShape } from 'Presentation/PresentationSlice';
import { usePresentationData } from 'Presentation/PresentationData';
import { useSlideData } from './SlideData';
import ShapeDataProvider from './Shapes/ShapeData';

import SlideBackground from './SlideBackground';
import Shape from './Shapes/Shape';
import SlideInlineCards from './InlineCards/SlideInlineCard';

import styles from './Slide.module.scss';

const Slide = () => {
  const dispatch = useDispatch();
  const slideRef = useRef<SVGSVGElement>(null);
  const { size } = usePresentationData();
  const { slide } = useSlideData();

  const selectedShape = useSelector((state) => state.presentation.general.selectedShape);
  const selectedCard = useSelector((state) => state.presentation.general.selectedCard);

  // const { slide, slideLayout, slideMaster } = useSlideData();
  // console.log('slide', slide);
  // console.log('slideLayout', slideLayout);
  // console.log('slideMaster', slideMaster);

  const handleSlideClick: MouseEventHandler<SVGSVGElement> = (e) => {
    //Only accept direct clicks on slide
    if (e.currentTarget !== e.target) {
      return;
    }

    if (selectedShape) {
      dispatch(setSelectedShape(null));
    }

    if (selectedCard) {
      dispatch(selectCard(null));
    }
  };

  if (slide) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        width="100%"
        height="100%"
        className={styles.slide}
        viewBox={`0 0 ${size.width} ${size.height}`}
        ref={slideRef}
        onMouseDown={handleSlideClick}
      >
        <g id="slide-backround">
          <SlideBackground />
        </g>
        {slide.spTree.shapes.length > 0 &&
          slide.spTree.shapes.map((shape) => {
            return (
              <ShapeDataProvider key={shape.id} shape={shape}>
                <Shape shape={shape} />
              </ShapeDataProvider>
            );
          })}
        <SlideInlineCards slideRef={slideRef} />
      </svg>
    );
  }
  return null;
};

export default memo(Slide);
