import { useMemo } from 'react';
import { useGroupData } from './Group/GroupData';

const usePosition = (shape: Presentation.Data.Shape) => {
  const parentGroupPosition = useGroupData();
  return useMemo(() => {
    const position = { left: 0, top: 0 };
    if (shape.properties.xfrm?.off) {
      position.left += shape.properties.xfrm.off.x;
      position.top += shape.properties.xfrm.off.y;
    }
    if (shape.properties.xfrm?.chOff) {
      position.left -= shape.properties.xfrm.chOff.x;
      position.top -= shape.properties.xfrm.chOff.y;
    }
    if (parentGroupPosition) {
      position.left += parentGroupPosition.position.left;
      position.top += parentGroupPosition.position.top;
    }
    return position;
  }, [shape, parentGroupPosition]);
};

export default usePosition;
