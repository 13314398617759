import { merge } from 'lodash';
import { MAX_HSL_VALUES } from 'Presentation/consts';
import { usePresentationData } from 'Presentation/PresentationData';
import { useSlideLayout, useSlideMaster, useSlideSync } from 'Presentation/SyncStore';
import { cloneObject } from 'Presentation/utils';
import { createContext, memo, ReactNode, useCallback, useContext, useMemo } from 'react';

type SlideContextValue = {
  slide: Presentation.Data.Slide;
  slideLayout: Presentation.Data.SlideLayout;
  slideMaster: Presentation.Data.SlideMaster;
  color: (color: Presentation.Data.Common.Color) => string;
  getFontFamily: ({
    font,
  }: {
    font: Presentation.Data.FontStyleRef | string | undefined;
  }) => string | undefined;
  getDefaultFontFamily: ({ shape }: { shape: Presentation.Data.Shape }) => string | undefined;
  isThumbnailSlide?: boolean;
};

const SlideContext = createContext<SlideContextValue | undefined>(undefined);

type SlideDataProviderProps = {
  children: ReactNode;
  slideId: string;
  isThumbnailSlide?: boolean;
};

const SlideDataProvider = ({ children, slideId, isThumbnailSlide }: SlideDataProviderProps) => {
  const { theme, structure } = usePresentationData();
  const slide = useSlideSync(slideId);
  const slideLayout = useSlideLayout(slide?.sldLayout);
  const slideMaster = useSlideMaster(slideLayout?.sldMaster);

  const colorMap = useMemo(() => {
    if (slide?.clrMap) {
      return slide.clrMap;
    }
    if (slideLayout?.overrideClrMap) {
      return slideLayout.overrideClrMap;
    }
    if (slideMaster) {
      return slideMaster.clrMap;
    }
    return {};
  }, [slide, slideLayout, slideMaster]);

  const color = useCallback(
    (color: Presentation.Data.Common.Color) => {
      if ('reference' in color) {
        const mappedColor = colorMap[color.reference] ?? color.reference;
        if (theme.colorScheme[mappedColor]) {
          return `#${applyColorModifications(
            `#${theme.colorScheme[mappedColor].base}`,
            color.mods,
          )}`;
          // return `#${theme.colorScheme[mappedColor].base}`;
        }
      }
      if ('base' in color) {
        return `#${applyColorModifications(`#${color.base}`, color.mods)}`;
        // return `#${color.base}`;
      }
      return '#000000';
    },
    [theme, colorMap],
  );

  const getFontFamily = useCallback(
    ({ font }: { font: Presentation.Data.FontStyleRef | string | undefined }) => {
      if (typeof font === 'string') {
        switch (font) {
          case '+mn-cs':
          case '+mn-ea':
          case '+mn-lt': {
            return theme.fontScheme.minorFont.latin.font;
          }
          case '+mj-cs':
          case '+mj-ea':
          case '+mj-lt': {
            return theme.fontScheme.majorFont.latin.font;
          }
          default: {
            return font;
          }
        }
      } else {
        switch (font?.reference) {
          case 'major': {
            return theme.fontScheme.majorFont.latin.font;
          }
          case 'minor': {
            return theme.fontScheme?.minorFont.latin.font;
          }
          default: {
            return undefined;
          }
        }
      }
    },
    [theme],
  );

  const getDefaultFontFamily = useCallback(
    ({ shape }: { shape: Presentation.Data.Shape }) => {
      const shapeListStyleFont = shape?.text?.listStyle?.['1']?.inlineProperties?.latin?.font;
      const slideMasterTitleFont =
        slideMaster?.txStyles?.title?.['1']?.inlineProperties?.latin?.font;
      const slideMasterBodyFont = slideMaster?.txStyles?.body?.['1']?.inlineProperties?.latin?.font;
      let fontFamily: string | undefined = undefined;

      //Check for shape style font
      if (shape.style?.fontRef) {
        fontFamily = getFontFamily({ font: shape.style?.fontRef });
      }
      //Check for shape list style font
      else if (
        shapeListStyleFont &&
        shapeListStyleFont !== '+mj-lt' &&
        shapeListStyleFont !== '+mn-lt'
      ) {
        fontFamily = shapeListStyleFont;
      }
      //Check for master slide or theme font
      else {
        let fontSource: string | undefined = undefined;
        switch (shape.nvProperties?.ph?.type) {
          case 'title':
          case 'ctrTitle': {
            fontSource = slideMasterTitleFont;
            break;
          }
          default: {
            fontSource = slideMasterBodyFont;
            break;
          }
        }
        fontFamily = getFontFamily({ font: fontSource });
      }

      return fontFamily;
    },
    [theme, slideMaster],
  );

  const mergeListStyle = useCallback(
    (destination: Parameters<typeof merge>[0], source: Parameters<typeof merge>[1]) => {
      Object.typedKeys(source).forEach((styleIdx) => {
        merge(destination[styleIdx], source[styleIdx]);

        //Make a shallow copy instead of a deep copy
        if (source[styleIdx]?.bullet) {
          destination[styleIdx].bullet = {
            ...destination[styleIdx].bullet,
            ...source[styleIdx].bullet,
          };
        } else {
          destination[styleIdx].bullet = undefined; //not sure if 100% correct (without this footer has extra bullets)
        }
      });

      return destination;
    },
    [],
  );

  const shapes:
    | []
    | {
        slide: Presentation.Data.Shape[];
        slideMaster: Presentation.Data.Shape[];
        slideLayout: Presentation.Data.Shape[];
      } = useMemo(() => {
    if (slide && slideLayout && slideMaster) {
      return {
        slide: slide.spTree.shapes.map((slideShape) => {
          let properties: Presentation.Data.ShapeProperties = {};
          let listStyle: Presentation.Data.ListStyles = merge({}, structure.txStyles);

          let bodyPr: Presentation.Data.TextBodyProperties = {};
          const ph = slideShape.nvProperties?.ph;

          if (slideMaster.txStyles) {
            let styles = undefined;
            switch (ph?.type) {
              case 'title':
                styles = slideMaster.txStyles.title;
                break;
              case 'body':
              case 'subTitle':
              case 'chart':
              case 'tbl':
              case 'pic':
              case 'media':
              case 'dt':
              case 'ftr':
              case 'sldNum':
              case 'hdr':
                styles = slideMaster.txStyles.body;
                break;
              case 'clipArt':
              case 'ctrTitle':
              case 'dgm':
              case 'obj':
              case 'sldImg':
                styles = slideMaster.txStyles.other;
                break;
              case undefined:
                if (ph) {
                  styles = slideMaster.txStyles.body;
                } else {
                  styles = slideMaster.txStyles.other;
                }
            }

            if (styles) {
              listStyle = mergeListStyle(cloneObject(listStyle), styles);
            }
          }

          if (ph) {
            let masterShape: Presentation.Data.Shape | undefined = undefined;
            let layoutShape: Presentation.Data.Shape | undefined = undefined;

            if (ph.type && ph.idx) {
              masterShape = slideMaster.spTree.shapes.find(
                (shape) =>
                  shape.nvProperties?.ph?.type === ph.type &&
                  shape.nvProperties?.ph?.idx === ph.idx,
              );
              layoutShape = slideLayout.spTree.shapes.find(
                (shape) =>
                  shape.nvProperties?.ph?.type === ph.type &&
                  shape.nvProperties?.ph?.idx === ph.idx,
              );

              if (!masterShape) {
                masterShape = slideMaster.spTree.shapes.find(
                  (shape) => shape.nvProperties?.ph?.idx === ph.idx,
                );
                if (!masterShape) {
                  masterShape = slideMaster.spTree.shapes.find(
                    (shape) => shape.nvProperties?.ph?.type === ph.type,
                  );
                }
              }

              if (!layoutShape) {
                layoutShape = slideLayout.spTree.shapes.find(
                  (shape) => shape.nvProperties?.ph?.idx === ph.idx,
                );
                if (!layoutShape) {
                  layoutShape = slideLayout.spTree.shapes.find(
                    (shape) => shape.nvProperties?.ph?.type === ph.type,
                  );
                }
              }
            } else if (ph.idx) {
              masterShape = slideMaster.spTree.shapes.find(
                (shape) => shape.nvProperties?.ph?.idx === ph.idx,
              );
              layoutShape = slideLayout.spTree.shapes.find(
                (shape) => shape.nvProperties?.ph?.idx === ph.idx,
              );
            } else if (ph.type) {
              masterShape = slideMaster.spTree.shapes.find(
                (shape) => shape.nvProperties?.ph?.type === ph.type,
              );
              layoutShape = slideLayout.spTree.shapes.find(
                (shape) => shape.nvProperties?.ph?.type === ph.type,
              );
            }

            if (masterShape) {
              properties = merge(cloneObject(properties), masterShape.properties);
              listStyle = mergeListStyle(cloneObject(listStyle), masterShape.text?.listStyle);
              bodyPr = merge(cloneObject(bodyPr), masterShape.text?.bodyPr);
            }

            if (layoutShape) {
              properties = merge(cloneObject(properties), layoutShape.properties);
              listStyle = mergeListStyle(cloneObject(listStyle), layoutShape.text?.listStyle);
              bodyPr = merge(cloneObject(bodyPr), layoutShape.text?.bodyPr);
            }
          }
          properties = merge(cloneObject(properties), slideShape.properties);
          listStyle = merge(cloneObject(listStyle), slideShape.text?.listStyle);
          bodyPr = merge(cloneObject(bodyPr), slideShape.text?.bodyPr);

          return {
            ...slideShape,
            properties,
            text: slideShape.text ? { ...slideShape.text, listStyle, bodyPr } : undefined,
          };
        }),
        /**
         * Id can be repeated between slides shapes, slideMaster shapes and layout shapes.
         * Identifying origin to provide unique id
         */
        slideMaster: slideMaster.spTree.shapes
          .filter((slideShape) => !slideShape.nvProperties?.ph?.type)
          .map((slideShape) => ({ ...slideShape, id: `master-${slideShape.id}` })),
        /**
         * Id can be repeated between slides shapes, slideMaster shapes and layout shapes.
         * Identifying origin to provide unique id
         */
        slideLayout: slideLayout.spTree.shapes
          .filter(
            (slideShape) => !slideShape.nvProperties?.ph?.type && !slideShape.nvProperties?.ph?.idx,
          )
          .map((slideShape) => ({ ...slideShape, id: `layout-${slideShape.id}` })),
      };
    }
    return [];
  }, [slideId, slide, slideLayout, slideMaster, structure]);

  if (slide && slideLayout && slideMaster) {
    return (
      <SlideContext.Provider
        value={{
          slide: {
            ...slide,
            spTree: {
              ...slide.spTree,
              //@ts-expect-error
              shapes: [...shapes.slideMaster, ...shapes.slideLayout, ...shapes.slide],
            },
          },
          slideLayout,
          slideMaster,
          color,
          getFontFamily,
          getDefaultFontFamily,
          isThumbnailSlide,
        }}
      >
        {children}
      </SlideContext.Provider>
    );
  }
  return null;
};

export const useSlideData = () => {
  const context = useContext(SlideContext);
  if (context === undefined) {
    throw new Error('useSlideData can only be used in a SlideData');
  }
  return context;
};

function applyColorModifications(
  color: string,
  modifications: Presentation.Data.Common.Color['mods'],
) {
  // // console.log('applyColorModifications', color, modifications);
  let modifiedColor = color;

  if (modifications) {
    for (let i = 0; i < modifications.length; i++) {
      const modification = modifications[i];
      const type = modification.type;
      const value = modification.val;

      switch (type) {
        case 'lumOff':
          modifiedColor = applyOff(modifiedColor, value, 'l');
          break;
        case 'lumMod':
          modifiedColor = applyMod(modifiedColor, value, 'l');
          break;
        case 'satOff':
          modifiedColor = applyOff(modifiedColor, value, 's');
          break;
        case 'satMod':
          modifiedColor = applyMod(modifiedColor, value, 's');
          break;
        case 'hueOff':
          modifiedColor = applyOff(modifiedColor, value, 'h');
          break;
        case 'hueMod':
          modifiedColor = applyMod(modifiedColor, value, 'h');
          break;
        case 'shade':
          modifiedColor = applyTintOrShade(modifiedColor, 'shade', value);
          break;
        case 'tint':
          modifiedColor = applyTintOrShade(modifiedColor, 'tint', value);
          break;
        case 'alpha':
          modifiedColor = applyAlpha(modifiedColor, value);
          break;
        default:
          console.error('Invalid color modification type:', type, value);
          break;
      }
    }
  }

  return modifiedColor.replace('#', '');
}

function applyOff(color: string, offset: number, value: keyof HSL) {
  // // console.log('Applying offset to', value, color, offset);
  const hexColor = color.replace('#', '');
  const hsl = hexToHsl(hexColor);

  const modifiedValue = Math.max(0, Math.min(MAX_HSL_VALUES[value], hsl[value] + offset));
  // // console.log('result', { ...hsl, [value]: modifiedValue });
  return hslToHex({ ...hsl, [value]: modifiedValue });
}

function applyMod(color: string, factor: number, value: keyof HSL) {
  // // console.log('Applying mod to', value, color, factor);
  const hexColor = color.replace('#', '');
  const hsl = hexToHsl(hexColor);

  const modifiedValue = Math.max(
    0,
    Math.min(MAX_HSL_VALUES[value], Math.round(hsl[value] * factor * 0.01)),
  );

  // // console.log('result', { ...hsl, [value]: modifiedValue });
  return hslToHex({ ...hsl, [value]: modifiedValue });
}

// function applyShade(color: string, value: number) {
//   const hexColor = color.replace('#', '');
//   const hsl = hexToHsl(hexColor);

//   const modifiedLightness = Math.max(
//     0,
//     Math.min(MAX_HSL_VALUES.l, Math.round(hsl.l - hsl.l * value * 0.01)),
//   );

// //   // console.log('result', { ...hsl, [value]: modifiedValue });
//   return hslToHex({ ...hsl, l: modifiedLightness });
// }

// function applyTint(color: string, value: number) {
//   const hexColor = color.replace('#', '');
//   const hsl = hexToHsl(hexColor);

//   const modifiedLightness = Math.max(
//     0,
//     Math.min(MAX_HSL_VALUES.l, Math.round(hsl.l + hsl.l * value * 0.01)),
//   );

// //   // console.log('result', { ...hsl, [value]: modifiedValue });
//   return hslToHex({ ...hsl, l: modifiedLightness });
// }

function applyTintOrShade(color: string, type: 'tint' | 'shade', value: number) {
  const hexColor = color.replace('#', '');
  const hsl = hexToHsl(hexColor);

  // if (type === 'tint') {
  //   // Increasing lightness to create a tint
  //   hsl.l = Math.min(100, hsl.l + hsl.l * (validValue / 100));
  // } else if (type === 'shade') {
  //   // Decreasing lightness to create a shade
  //   hsl.l = Math.max(0, hsl.l - hsl.l * (validValue / 100));
  // }

  if (type === 'tint') {
    hsl.l += value;
  } else {
    hsl.l -= value * 0.1;
  }

  return hslToHex(hsl);
}

function applyAlpha(color: string, value: number) {
  // Ensure alpha is within the valid range
  const clampedAlpha = Math.max(0, Math.min(100, value));

  // Convert alpha to a value between 0 and 255
  const alphaValue = Math.round((clampedAlpha / 100) * 255);

  // Convert alpha value to hex format
  const hexValue = alphaValue.toString(16).toUpperCase();

  // Pad the hex value with leading zeros if necessary
  const paddedHexValue = hexValue.length < 2 ? `0${hexValue}` : hexValue;

  // Return the hex value

  return color + paddedHexValue;
}

function hexToHsl(hexColor: string) {
  // Remove the '#' symbol if present
  if (hexColor.startsWith('#')) {
    hexColor = hexColor.slice(1);
  }

  // Convert the hex color to RGB
  const rgb: number[] = hexColor.match(/\w{2}/g)?.map((x) => parseInt(x, 16)) ?? [];
  const [r, g, b] = rgb.map((x) => x / 255);

  // Find the maximum and minimum values for RGB
  const maxVal = Math.max(r, g, b);
  const minVal = Math.min(r, g, b);

  // Calculate the hue
  let h: number;
  if (maxVal === minVal) {
    h = 0; // Hue is undefined for achromatic colors
  } else if (maxVal === r) {
    h = ((60 * (g - b)) / (maxVal - minVal) + 360) % 360;
  } else if (maxVal === g) {
    h = ((60 * (b - r)) / (maxVal - minVal) + 120) % 360;
  } else {
    h = ((60 * (r - g)) / (maxVal - minVal) + 240) % 360;
  }

  // Calculate the lightness
  const l = (maxVal + minVal) / 2;

  // Calculate the saturation
  let s: number;
  if (maxVal === minVal) {
    s = 0; // Saturation is 0 for achromatic colors
  } else if (l <= 0.5) {
    s = (maxVal - minVal) / (2 * l);
  } else {
    s = (maxVal - minVal) / (2 - 2 * l);
  }

  // Return the HSL values
  return { h: Math.round(h), s: Math.round(s * 100), l: Math.round(l * 100) };
}

function hslToHex({ h, s, l }: HSL) {
  // Ensure hue is within 0-360 range
  h = (h + 360) % 360;

  // Ensure saturation and lightness are within 0-100 range
  s = Math.max(0, Math.min(100, s));
  l = Math.max(0, Math.min(100, l));

  // Convert saturation and lightness to values between 0 and 1
  s /= 100;
  l /= 100;

  // Calculate RGB values
  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = l - c / 2;

  let r = 0,
    g = 0,
    b = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
  } else if (120 <= h && h < 180) {
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    b = x;
  }

  // Convert RGB values to 8-bit integers
  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  // Convert RGB to hexadecimal string
  const hexR = r.toString(16).padStart(2, '0');
  const hexG = g.toString(16).padStart(2, '0');
  const hexB = b.toString(16).padStart(2, '0');

  return `${hexR}${hexG}${hexB}`;
}

export default memo(SlideDataProvider);
