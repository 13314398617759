import { useTasks } from 'Presentation/SyncStore';

const useActiveTasks = (slideId?: Presentation.Data.Slide['_id']) => {
  const data = useTasks()?.data;
  const tasks = data ? Object.values(data) : [];

  const filterTask = (task: Presentation.Data.Task) => {
    if (!task || task.deletionDate !== '') {
      return false;
    }

    if (slideId) {
      return task.anchor[0].id === slideId;
    }

    return true;
  };

  return tasks.filter(filterTask);
};

export default useActiveTasks;
