import { Command } from '../Command';
import { JsonRange } from 'Editor/services/_Common/Selection';
import { ActionContext } from '../../ActionContext';
import { NodeUtils } from 'Editor/services/DataManager';

export class DeleteTableCommand extends Command {
  async exec(): Promise<Editor.Edition.ICommand> {
    if (
      !this.context.DataManager ||
      !this.context.DataManager.selection ||
      !this.context.contentManipulator
    ) {
      return this;
    }

    const rangeData = this.context.DataManager.selection.current;
    const jsonRange = rangeData[0] ? JsonRange.buildFromRangeData(rangeData[0]) : null;

    if (!jsonRange) {
      return this;
    }

    const baseModel = this.context.DataManager.nodes.getNodeModelById(jsonRange.start.b);
    const baseData = baseModel?.selectedData();

    if (!baseModel || !baseData || !baseData.id) {
      return this;
    }

    // check if element is editable
    if (!this.context.DataManager.nodes.isNodeEditable(baseModel.id)) {
      return this;
    }

    const closestTable = NodeUtils.closestOfTypeByPath(baseData, jsonRange.start.p, 'tbl');

    if (closestTable && closestTable.data.id) {
      this.context.VisualizerManager?.getWidgetsManager()?.removeAllWidgetsForView(
        closestTable.data.id,
      );

      // select table
      jsonRange.updateRangePositions(
        {
          b: jsonRange.start.b,
          p: [...closestTable.path, 'childNodes', 0],
        },
        {
          b: jsonRange.start.b,
          p: [...closestTable.path, 'childNodes', closestTable.data.childNodes?.length || 0],
        },
      );

      let ctx: Editor.Edition.ActionContext = new ActionContext(jsonRange);

      this.context.contentManipulator?.removeContent(ctx);

      // handle create suggestions???
      this.handleSuggestionsUpdate(ctx);

      // apply new selection
      if (this.context.DataManager?.selection) {
        // TEMP: flag last selection
        this.context.DataManager.selection.history.flag('debounce');
        this.context.DataManager.selection.setUserSelection([ctx.range.serializeToRangeData()]);
      }

      // create patch
      this.context.DataManager?.history.createPatch();
    }

    return this;
  }
}
