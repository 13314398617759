import { TableOfContents } from '../../models';
import BaseController from '../BaseController';

export class TableOfContentsController extends BaseController {
  private toc?: TableOfContents;
  constructor(data: Editor.Data.State) {
    super(data);
    this.handleTableOfContentsUpdate = this.handleTableOfContentsUpdate.bind(this);
  }

  start(documentId: string): void {
    if (this.Data.models) {
      this.toc = this.Data.models?.get(this.Data?.models.TYPE_NAME.TOC, `TOC${documentId}`);
      this.toc.on('LOADED', this.handleTableOfContentsUpdate);
      this.toc.on('UPDATED', this.handleTableOfContentsUpdate);
    }
  }

  private getParamsFromOptions(
    options?: any,
  ): TableOfContents.TOCSectionProperties | TableOfContents.TOLSectionProperties | undefined {
    if (!options) {
      return;
    }
    if (!options.rl && options.cpt) {
      return {
        label: options.cpt,
        leader: options.tabs?.[0]?.l,
        showPageNumber: options.spn,
        alignNumberRight: options.anr,
        useLink: options.l,
      };
    }
    if (options.rl && !options.cpt) {
      return {
        representingLevels: options.rl,
        leader: options.tabs?.[0]?.l,
        showPageNumber: options.spn,
        alignNumberRight: options.anr,
        useLink: options.l,
      };
    }
  }

  private getTOCOptionsFromParams(options?: TableOfContents.TOCSectionProperties) {
    if (!options) {
      return;
    }
    return {
      rl: options.representingLevels,
      tabs: [
        {
          t: 'r',
          l: options.leader || 'n',
        },
      ],
      spn: options.showPageNumber,
      anr: options.alignNumberRight,
      l: options.useLink,
    };
  }

  private getTOLOptionsFromParams(options?: TableOfContents.TOLSectionProperties) {
    if (!options) {
      return;
    }
    return {
      cpt: options.label,
      tabs: [
        {
          t: 'r',
          l: options.leader || 'n',
        },
      ],
      spn: options.showPageNumber,
      anr: options.alignNumberRight,
      l: options.useLink,
    };
  }

  private handleTableOfContentsUpdate(data: any) {
    this.Data.events?.emit('LOAD_TABLE_OF_CONTENTS', data?.toc || { list: [], data: {} });
  }

  getDocumentMainTitle(force: boolean) {
    return this.toc?.getDocumentMainTitle();
  }

  updateTOCSection(nodeId: string, options?: TableOfContents.TOCSectionProperties) {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'UPDATE:TOC:SECTION',
        {
          nodeId,
          options: this.getTOCOptionsFromParams(options),
        },
        (response: Realtime.Transport.RealtimeResponse) => {
          if (response.success) {
            resolve(true);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  updateLabelSection(nodeId: string, options?: TableOfContents.TOLSectionProperties) {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'UPDATE:LABEL:SECTION',
        {
          nodeId,
          options: this.getTOLOptionsFromParams(options),
        },
        (response: Realtime.Transport.RealtimeResponse) => {
          if (response.success) {
            resolve(true);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  getDefaultTocDefinition() {
    return this.getParamsFromOptions(this.Data.templates?.getTocDefinition()?.properties);
  }

  getDefaultTolDefinition(label: string) {
    return this.getParamsFromOptions(this.Data.templates?.getTolDefinition(label)?.properties);
  }

  getPropertiesFromTOCSection(nodeId: string): TableOfContents.TOCSectionProperties | null {
    const tocSection = this.Data.models?.get(this.Data?.models.TYPE_NAME.NODE, nodeId);
    if (!tocSection) {
      return null;
    }
    const data = tocSection.get();
    return {
      representingLevels: data.properties.rl || [],
      showPageNumber: data.properties.spn || false,
      alignNumberRight: data.properties.anr || false,
      leader: data.properties.tabs?.[0]?.l || 'n',
      useLink: data.properties.l || false,
    };
  }

  getPropertiesFromTOLSection(nodeId: string): TableOfContents.TOLSectionProperties | null {
    const tolSection = this.Data.models?.get(this.Data?.models.TYPE_NAME.NODE, nodeId);
    if (!tolSection) {
      return null;
    }
    const data = tolSection.get();
    if (data.type === 'tof') {
      const templateDef =
        this.getParamsFromOptions(this.Data.templates?.getTolDefinition('Figure')?.properties) ||
        {};
      return {
        label: 'Figure',
        showPageNumber: data.properties.spn || false,
        alignNumberRight: data.properties.anr || false,
        leader: data.properties.tabs?.[0]?.l || 'n',
        useLink: data.properties.l || false,
        ...templateDef,
      };
    }
    if (data.type === 'tot') {
      const templateDef =
        this.getParamsFromOptions(this.Data.templates?.getTolDefinition('Table')?.properties) || {};
      return {
        label: 'Table',
        showPageNumber: data.properties.spn || false,
        alignNumberRight: data.properties.anr || false,
        leader: data.properties.tabs?.[0]?.l || 'n',
        useLink: data.properties.l || false,
        ...templateDef,
      };
    }
    return {
      label: data.properties.lbl,
      showPageNumber: data.properties.spn || false,
      alignNumberRight: data.properties.anr || false,
      leader: data.properties.tabs?.[0]?.l || 'n',
      useLink: data.properties.l || false,
    };
  }

  stop(): void {}

  destroy(): void {
    this.stop();
  }
}
