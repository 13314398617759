import SHAPE_CONST from './consts';
import { arcTo } from './utils';

type FlowchartProps = {
  size: Presentation.Data.Common.Size;
  type:
    | 'flowChartProcess'
    | 'flowChartAlternateProcess'
    | 'flowChartDecision'
    | 'flowChartInputOutput'
    | 'flowChartPredefinedProcess'
    | 'flowChartInternalStorage'
    | 'flowChartDocument'
    | 'flowChartMultidocument'
    | 'flowChartTerminator'
    | 'flowChartPreparation'
    | 'flowChartManualInput'
    | 'flowChartManualOperation'
    | 'flowChartConnector'
    | 'flowChartOffpageConnector'
    | 'flowChartPunchedCard'
    | 'flowChartPunchedTape'
    | 'flowChartSummingJunction'
    | 'flowChartOr'
    | 'flowChartCollate'
    | 'flowChartSort'
    | 'flowChartExtract'
    | 'flowChartMerge'
    | 'flowChartOnlineStorage'
    | 'flowChartDelay'
    | 'flowChartMagneticTape'
    | 'flowChartMagneticDisk'
    | 'flowChartMagneticDrum'
    | 'flowChartDisplay';
  // | 'flowChartOfflineStorage'; // Unsure what this shape is (29th shape and powerpoint only has 28)
};

const generateFlowchartPath = ({
  size,
  type,
}: FlowchartProps): Presentation.Data.ParsedGeometry => {
  const w = size.width;
  const h = size.height;
  const wd2 = w / 2; //Width / 2
  const hd2 = h / 2; //Height / 2
  const wd4 = w / 4; //Width / 4
  const wd5 = w / 5; //Width / 5
  const hd5 = h / 5; //Height / 5
  const wd6 = w / 6; //Width / 6
  const hd6 = h / 6; //Height / 6
  const wd8 = w / 8; //Width / 8
  const hd8 = h / 8; //Height / 8
  const hd10 = h / 10; //Height / 10

  const t = 0;
  const r = w;
  const b = h;
  const l = 0;
  const hc = wd2; //Horizontal center
  const vc = hd2; //Vertical center

  const ssd6 = Math.min(w, h) / 6; //Shortest Side Divided by 6

  switch (type) {
    case 'flowChartProcess': {
      const d = `M ${l} ${t}L ${r} ${t}L ${r} ${b}L ${l} ${b} z`;

      return {
        paths: [{ d }],
      };
    }
    case 'flowChartAlternateProcess': {
      const x2 = r - ssd6;
      const y2 = b - ssd6;

      const d =
        `M ${l} ${ssd6}` +
        arcTo(ssd6, ssd6, SHAPE_CONST.cd2, SHAPE_CONST.cd4, l, ssd6).d +
        `L ${x2} ${t}` +
        arcTo(ssd6, ssd6, SHAPE_CONST['3cd4'], SHAPE_CONST.cd4, x2, t).d +
        `L ${r} ${y2}` +
        arcTo(ssd6, ssd6, 0, SHAPE_CONST.cd4, r, y2).d +
        `L ${ssd6} ${b}` +
        arcTo(ssd6, ssd6, SHAPE_CONST.cd4, SHAPE_CONST.cd4, ssd6, b).d +
        ' z';

      return {
        paths: [{ d }],
      };
    }
    case 'flowChartDecision': {
      const d = `M ${l} ${hd2}L ${wd2} ${t}L ${r} ${hd2}L ${wd2} ${b} z`;

      return {
        paths: [{ d }],
      };
    }
    case 'flowChartInputOutput': {
      const d = `M ${l} ${b}L ${wd5} ${t}L ${r} ${t}L ${w * 0.8 /* 4/5 of width */} ${b} z`;

      return {
        paths: [{ d }],
      };
    }
    case 'flowChartPredefinedProcess': {
      //Based on definitions, this shape will be drawn with a ratio of 8x8
      const dLeft = `M ${l} ${t}L ${wd8} ${t}L ${wd8} ${b}L ${l} ${b} z`;

      const dCenter = `M ${wd8} ${t}L ${r - wd8} ${t}L ${r - wd8} ${b}L ${wd8} ${b} z`;

      const dRight = `M ${r - wd8} ${t}L ${r} ${t}L ${r} ${b}L ${r - wd8} ${b} z`;

      return {
        paths: [{ d: dLeft }, { d: dCenter }, { d: dRight }],
      };
    }
    case 'flowChartInternalStorage': {
      //Based on definitions, this shape will be drawn with a ratio of 8x8
      const dTopLeft = `M ${l} ${t}L ${wd8} ${t}L ${wd8} ${hd8}L ${l} ${hd8} z`;

      const dTop = `M ${wd8} ${t}L ${r} ${t}L ${r} ${hd8}L ${wd8} ${hd8} z`;

      const dLeft = `M ${l} ${hd8}L ${wd8} ${hd8}L ${wd8} ${b}L ${l} ${b} z`;

      const dMain = `M ${wd8} ${hd8}L ${r} ${hd8}L ${r} ${b}L ${wd8} ${b} z`;

      return {
        paths: [{ d: dTopLeft }, { d: dTop }, { d: dLeft }, { d: dMain }],
      };
    }
    case 'flowChartDocument': {
      /** Based on definitions
       * w = h = 21600
       * 17322 =/= 17322 / 21600 = 0.8019
       * 23922 =/= 23922 / 21600 = 1.1075
       * 20172 =/= 20172 / 21600 = 0.9339
       */

      const d =
        `M ${l} ${t}` +
        `L ${r} ${t}` +
        `L ${r} ${h * 0.8019}` +
        `C ${wd2} ${h * 0.8019},` +
        ` ${wd2} ${h * 1.1075},` +
        ` ${l} ${h * 0.9339}` +
        ' z';

      return {
        paths: [{ d }],
      };
    }
    case 'flowChartMultidocument': {
      /** Based on definitions
       * w = h = 21600
       * 1532 =/= 1532 / 21600 = 0.0709
       * 1815 =/= 1815 / 21600 = 0.0840
       * 2972 =/= 2972 / 21600 = 0.1376
       * 3675 =/= 3675 / 21600 = 0.1701
       * 9298 =/= 9298 / 21600 = 0.4305
       * 14392 =/= 14392 / 21600 = 0.6663
       * 14467 =/= 14467 / 21600 = 0.6698
       * 16252 =/= 16252 / 21600 = 0.7524
       * 16352 =/= 16352 / 21600 = 0.7570
       * 18022 =/= 18022 / 21600 = 0.8344
       * 18595 =/= 18595 / 21600 = 0.8609
       * 19298 =/= 19298 / 21600 = 0.8934
       * 20000 =/= 20000 / 21600 = 0.9259
       * 20800 =/= 20800 / 21600 = 0.9630
       * 20782 =/= 20782 / 21600 = 0.9621
       * 23542 =/= 23542 / 21600 = 1.0899
       */

      const d =
        `M ${l} ${h * 0.9621}` +
        `C ${w * 0.4305} ${h * 1.0899},` +
        `  ${w * 0.4305} ${h * 0.8344},` +
        `  ${w * 0.8609} ${h * 0.8344}` +
        `L ${w * 0.8609} ${h * 0.1701}` +
        `L ${l} ${h * 0.1701}` +
        ' z' +
        `M ${w * 0.0709} ${h * 0.1701}` +
        `L ${w * 0.0709} ${h * 0.084}` +
        `L ${w * 0.9259} ${h * 0.084}` +
        `L ${w * 0.9259} ${h * 0.7524}` +
        `C ${w * 0.8934} ${h * 0.7524},` +
        `  ${w * 0.8609} ${h * 0.757},` +
        `  ${w * 0.8609} ${h * 0.757}` +
        `L ${w * 0.8609} ${h * 0.1701}` +
        ' z' +
        `M ${w * 0.1376} ${h * 0.084}` +
        `L ${w * 0.1376} ${t}` +
        `L ${w} ${t}` +
        `L ${w} ${h * 0.6663}` +
        `C ${w * 0.963} ${h * 0.6663},` +
        `  ${w * 0.9259} ${h * 0.6698},` +
        `  ${w * 0.9259} ${h * 0.6698}` +
        `L ${w * 0.9259} ${h * 0.084}` +
        ' z';

      return {
        paths: [{ d }],
      };
    }
    case 'flowChartTerminator': {
      /** Based on definitions
       * w = h = 21600
       * 3475 =/= 3475 / 21600 = 0.1609
       * 10800 =/= 10800 / 21600 = 0.5 (w or h /2)
       * 18125 =/= 18125 / 21600 = 0.8391
       */

      const d =
        `M ${w * 0.1609} ${t}` +
        `L ${w * 0.8391} ${t}` +
        arcTo(w * 0.1609, hd2, SHAPE_CONST['3cd4'], SHAPE_CONST.cd2, w * 0.8391, t).d +
        `L ${w * 0.1609} ${h}` +
        arcTo(w * 0.1609, hd2, SHAPE_CONST.cd4, SHAPE_CONST.cd2, w * 0.1609, h).d +
        ' z';

      return {
        paths: [{ d }],
      };
    }
    case 'flowChartPreparation': {
      //Based on definitions, this shape will be drawn with a ratio of 10x10
      const d =
        `M ${l} ${hd2}` +
        `L ${wd5} ${t}` +
        `L ${r - wd5} ${t}` +
        `L ${r} ${hd2}` +
        `L ${r - wd5} ${b}` +
        `L ${wd5} ${b}` +
        ` z`;

      return {
        paths: [{ d }],
      };
    }
    case 'flowChartManualInput': {
      //Based on definitions, this shape will be drawn with a ratio of 5x5
      const d = `M ${l} ${hd5}L ${r} ${t}L ${r} ${b}L ${l} ${b} z`;

      return {
        paths: [{ d }],
      };
    }
    case 'flowChartManualOperation': {
      //Based on definitions, this shape will be drawn with a ratio of 5x5
      const d = `M ${l} ${t}L ${r} ${t}L ${r - wd5} ${b}L ${wd5} ${b} z`;

      return {
        paths: [{ d }],
      };
    }
    case 'flowChartConnector': {
      const topLeftArc = arcTo(wd2, hd2, SHAPE_CONST.cd2, SHAPE_CONST.cd4, l, vc);
      const topRightArc = arcTo(
        wd2,
        hd2,
        SHAPE_CONST['3cd4'],
        SHAPE_CONST.cd4,
        topLeftArc.x,
        topLeftArc.y,
      );
      const bottomRightArc = arcTo(wd2, hd2, 0, SHAPE_CONST.cd4, topRightArc.x, topRightArc.y);
      const bottomLeftArc = arcTo(
        wd2,
        hd2,
        SHAPE_CONST.cd4,
        SHAPE_CONST.cd4,
        bottomRightArc.x,
        bottomRightArc.y,
      );

      const d =
        `M ${l} ${vc}` + topLeftArc.d + topRightArc.d + bottomRightArc.d + bottomLeftArc.d + ' z';

      return {
        paths: [{ d }],
      };
    }
    case 'flowChartOffpageConnector': {
      //Based on definitions, this shape will be drawn with a ratio of 10x10
      const d =
        `M ${l} ${t}` +
        `L ${r} ${t}` +
        `L ${r} ${b - hd5}` +
        `L ${wd2} ${b}` +
        `L ${l} ${b - hd5}` +
        ` z`;

      return {
        paths: [{ d }],
      };
    }
    case 'flowChartPunchedCard': {
      //Based on definitions, this shape will be drawn with a ratio of 5x5
      const d = `M ${l} ${hd5} L ${wd5} ${t} L ${r} ${t} L ${r} ${b} L ${l} ${b} z`;

      return {
        paths: [{ d }],
      };
    }
    case 'flowChartPunchedTape': {
      /** Based on definitions and documentation of SHAPE_CONST
       * -10800000 = -180 (degrees)
       *
       * This shape will be drawn with a ratio of 20x20
       */

      const topLeftArc = arcTo(wd4, hd10, SHAPE_CONST.cd2, -180, l, hd10);
      const topRightArc = arcTo(
        wd4,
        hd10,
        SHAPE_CONST.cd2,
        SHAPE_CONST.cd2,
        topLeftArc.x,
        topLeftArc.y,
      );

      const bottomRightArc = arcTo(wd4, hd10, 0, -180, r, b - hd10);
      const bottomLeftArc = arcTo(
        wd4,
        hd10,
        0,
        SHAPE_CONST.cd2,
        bottomRightArc.x,
        bottomRightArc.y,
      );

      const d =
        `M ${l} ${hd10}` +
        topLeftArc.d +
        topRightArc.d +
        `L ${r} ${b - hd10}` +
        bottomRightArc.d +
        bottomLeftArc.d +
        ' z';

      return {
        paths: [{ d }],
      };
    }
    case 'flowChartSummingJunction': {
      const idx = wd2 * Math.cos(2700000);
      const idy = hd2 * Math.sin(2700000);
      const il = hc - idx;
      const ir = hc + idx;
      const it = vc - idy;
      const ib = vc + idy;

      const topLeftArc = arcTo(wd2, hd2, SHAPE_CONST.cd2, SHAPE_CONST.cd4, l, vc);
      const topRightArc = arcTo(
        wd2,
        hd2,
        SHAPE_CONST['3cd4'],
        SHAPE_CONST.cd4,
        topLeftArc.x,
        topLeftArc.y,
      );
      const bottomRightArc = arcTo(wd2, hd2, 0, SHAPE_CONST.cd4, topRightArc.x, topRightArc.y);
      const bottomLeftArc = arcTo(
        wd2,
        hd2,
        SHAPE_CONST.cd4,
        SHAPE_CONST.cd4,
        bottomRightArc.x,
        bottomRightArc.y,
      );

      const d =
        `M ${l} ${vc}` +
        topLeftArc.d +
        topRightArc.d +
        bottomRightArc.d +
        bottomLeftArc.d +
        ` z ` +
        `M ${il} ${it}` +
        `L ${ir} ${ib}` +
        `M ${ir} ${it}` +
        `L ${il} ${ib}`;

      return {
        paths: [{ d }],
      };
    }
    case 'flowChartOr': {
      const topLeftArc = arcTo(wd2, hd2, SHAPE_CONST.cd2, SHAPE_CONST.cd4, l, vc);
      const topRightArc = arcTo(
        wd2,
        hd2,
        SHAPE_CONST['3cd4'],
        SHAPE_CONST.cd4,
        topLeftArc.x,
        topLeftArc.y,
      );
      const bottomRightArc = arcTo(wd2, hd2, 0, SHAPE_CONST.cd4, topRightArc.x, topRightArc.y);
      const bottomLeftArc = arcTo(
        wd2,
        hd2,
        SHAPE_CONST.cd4,
        SHAPE_CONST.cd4,
        bottomRightArc.x,
        bottomRightArc.y,
      );

      const d =
        `M ${l} ${vc}` +
        topLeftArc.d +
        topRightArc.d +
        bottomRightArc.d +
        bottomLeftArc.d +
        ` z ` +
        `M ${hc} ${t}` +
        `L ${hc} ${b}` +
        `M ${l} ${vc}` +
        `L ${r} ${vc}`;

      return {
        paths: [{ d }],
      };
    }
    case 'flowChartCollate': {
      //Based on definitions, this shape will be drawn with a ratio of 2x2
      const d =
        `M ${l} ${t}` +
        `L ${r} ${t}` +
        `L ${hc} ${vc}` +
        `L ${r} ${b}` +
        `L ${l} ${b}` +
        `L ${hc} ${vc}` +
        ` z`;

      return {
        paths: [{ d }],
      };
    }
    case 'flowChartSort': {
      //Based on definitions, this shape will be drawn with a ratio of 2x2
      const d =
        `M ${l} ${vc}` +
        `L ${hc} ${t}` +
        `L ${r} ${vc}` +
        `L ${hc} ${b}` +
        ` z ` +
        `M ${l} ${vc}` +
        `L ${r} ${vc}`;

      return {
        paths: [{ d }],
      };
    }
    case 'flowChartExtract': {
      //Based on definitions, this shape will be drawn with a ratio of 2x2
      const d = `M ${l} ${b} L ${hc} ${t} L ${r} ${b}  z`;

      return {
        paths: [{ d }],
      };
    }
    case 'flowChartMerge': {
      //Based on definitions, this shape will be drawn with a ratio of 2x2
      const d = `M ${l} ${t} L ${r} ${t} L ${hc} ${b}  z`;

      return {
        paths: [{ d }],
      };
    }
    case 'flowChartOnlineStorage': {
      //Based on definitions, this shape will be drawn with a ratio of 6x6
      const d =
        `M ${wd6} ${t}` +
        `L ${r} ${t}` +
        arcTo(wd6, hd2, SHAPE_CONST['3cd4'], -180, r, t).d +
        `L ${wd6} ${b}` +
        arcTo(wd6, hd2, SHAPE_CONST.cd4, SHAPE_CONST.cd2, wd6, b).d +
        ` z`;

      return {
        paths: [{ d }],
      };
    }
    case 'flowChartDelay': {
      //Based on definitions, this shape will be drawn with a ratio of 6x6
      const d =
        `M ${l} ${t}` +
        `L ${hc} ${t}` +
        arcTo(wd2, hd2, SHAPE_CONST['3cd4'], SHAPE_CONST.cd2, hc, t).d +
        `L ${l} ${b}` +
        ` z`;

      return {
        paths: [{ d }],
      };
    }
    case 'flowChartMagneticTape': {
      const idy = hd2 * Math.sin(2700000);
      const ib = vc + idy;

      const bottomLeftArc = arcTo(wd2, hd2, SHAPE_CONST.cd4, SHAPE_CONST.cd4, hc, b);
      const topLeftArc = arcTo(
        wd2,
        hd2,
        SHAPE_CONST.cd2,
        SHAPE_CONST.cd4,
        bottomLeftArc.x,
        bottomLeftArc.y,
      );
      const topRightArc = arcTo(
        wd2,
        hd2,
        SHAPE_CONST['3cd4'],
        SHAPE_CONST.cd4,
        topLeftArc.x,
        topLeftArc.y,
      );
      // const bottomRightArc = arcTo(wd2, hd2, 0, 40, topRightArc.x, topRightArc.y);

      const d =
        `M ${hc} ${b}` +
        bottomLeftArc.d +
        topLeftArc.d +
        topRightArc.d +
        // bottomRightArc.d + //TODO:PRESENTATION Check how do draw this arc correctly
        `L ${r} ${ib}` +
        `L ${r} ${b}` +
        ` z`;

      return {
        paths: [{ d }],
      };
    }
    case 'flowChartMagneticDisk': {
      //Based on definitions, this shape will be drawn with a ratio of 6x6
      const d =
        `M ${l} ${hd6}` +
        arcTo(wd2, hd6, SHAPE_CONST.cd2, SHAPE_CONST.cd2, l, hd6).d +
        `L ${r} ${b - hd6}` +
        arcTo(wd2, hd6, 0, SHAPE_CONST.cd2, r, b - hd6).d +
        ` z` +
        `M ${r} ${hd6}` +
        arcTo(wd2, hd6, 0, SHAPE_CONST.cd2, r, hd6).d;

      return {
        paths: [{ d }],
      };
    }
    case 'flowChartMagneticDrum': {
      //Based on definitions, this shape will be drawn with a ratio of 6x6
      const d =
        `M ${wd6} ${t}` +
        `L ${r - wd6} ${t}` +
        arcTo(wd6, hd2, SHAPE_CONST['3cd4'], SHAPE_CONST.cd2, r - wd6, t).d +
        `L ${wd6} ${b}` +
        arcTo(wd6, hd2, SHAPE_CONST.cd4, SHAPE_CONST.cd2, wd6, b).d +
        ` z` +
        `M ${r - wd6} ${b}` +
        arcTo(wd6, hd2, SHAPE_CONST.cd4, SHAPE_CONST.cd2, r - wd6, b).d;

      return {
        paths: [{ d }],
      };
    }
    case 'flowChartDisplay': {
      //Based on definitions, this shape will be drawn with a ratio of 6x6
      const d =
        `M ${l} ${hd2}` +
        `L ${wd6} ${t}` +
        `L ${r - wd6} ${t}` +
        arcTo(wd6, hd2, SHAPE_CONST['3cd4'], SHAPE_CONST.cd2, r - wd6, t).d +
        `L ${wd6} ${b}` +
        ` z`;

      return {
        paths: [{ d }],
      };
    }
  }
};

export default generateFlowchartPath;
