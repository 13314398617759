import { Command } from '../Command';
import { JsonRange, PathUtils } from 'Editor/services/_Common/Selection';
import { NodeUtils } from 'Editor/services/DataManager/models';
import { UpdateImageSizeOperation } from '../../Operations/ImageOperations/UpdateImageSizeOperation';

export class UpdateImageSizeCommand extends Command {
  imageId: string;
  height: number;
  width: number;

  constructor(
    context: Editor.Edition.ICommandArgs,
    imageId: string,
    height: number,
    width: number,
  ) {
    super(context);

    this.imageId = imageId;
    this.height = height;
    this.width = width;
  }

  async exec(): Promise<Editor.Edition.ICommand> {
    if (
      !this.askUserAboutThis() ||
      !this.context.DataManager ||
      !this.context.DataManager.selection
    ) {
      return this;
    }

    const rangeData = this.context.DataManager.selection.current;
    const jsonRange = JsonRange.buildFromRangeData(rangeData[0]);

    if (!jsonRange) {
      return this;
    }

    const baseModel = this.context.DataManager.nodes.getNodeModelById(jsonRange.start.b);
    const baseData = baseModel?.selectedData();

    if (!baseModel || !baseData) {
      return this;
    }

    // check if element is editable
    if (!this.context.DataManager.nodes.isNodeEditable(baseModel.id)) {
      return this;
    }

    const imageInfo = baseModel.getChildInfoById(this.imageId);

    if (PathUtils.isValidSelectionPath(imageInfo.path)) {
      if (NodeUtils.isImageData(imageInfo.data)) {
        this.applyOperations(baseModel, imageInfo.path, imageInfo.data);
      }
    }

    // create patch
    this.context.DataManager?.history.createPatch();

    return this;
  }

  applyOperations(
    baseModel: Editor.Data.Node.Model,
    path: Editor.Selection.Path,
    imageData: Editor.Data.Node.ImageData,
  ) {
    const operation = new UpdateImageSizeOperation(
      baseModel,
      path,
      imageData,
      this.height,
      this.width,
    );

    operation.apply();
  }
}
