import { useComments } from 'Presentation/SyncStore';
import { EmptyFilteredState, FilterDisplay } from '_common/components';
import { usePublicProfiles, useSelector } from '_common/hooks';
import { EmptyCardListState } from 'dodoc-design-system';
import { Comments } from '_common/suite/components';
import { FormattedMessage } from 'react-intl';
import CommentCard from './CommentCard';
import styles from './CommentsPanel.module.scss';
import Search from '_common/suite/components/Panel/Comments/Search/Search';
import { setCommentsSearchBy } from 'Presentation/CommentsSlice';
import { useMemo } from 'react';
import { useCommentsAuthors, useFilteredComments, useActiveComments } from 'Presentation/hooks';
import { selectHasFilters } from '_common/components/Filters/FilterSlice';

const CommentsPanel = () => {
  const comments = useComments();
  const allComments = comments && Object.values(comments?.data);
  const commentsFilteredList = useFilteredComments();
  const commentsList = useActiveComments();
  const users = useCommentsAuthors();
  const creating = useSelector((state) => state.presentation.general.creating);
  const searchBy = useSelector((state) => state.presentation.comments.searchBy);
  const { profiles } = usePublicProfiles(users?.map((user) => user.id));
  const hasActiveFilters = useSelector((state) =>
    selectHasFilters(state, state.filters.reviewerCommentPanel),
  );

  const userOptions = useMemo(() => {
    const options: { label: string; id: string; email?: string; value: string }[] = [];

    users?.forEach((user) => {
      const option: { label: string; id: string; email?: string; value: string } = {
        label: '',
        id: '',
        value: '',
      };
      if (user.imported) {
        option.label = user.id;
        option.id = 'IMPORTED_USER';
      } else {
        option.label = profiles[user.id]?.name;
        option.email = profiles[user.id]?.email;
        option.id = user.id;
      }
      option.value = user.id;
      options.push(option);
    });

    return options;
  }, [users, profiles]);

  const renderEmptyState = () => {
    if (!creating) {
      if (searchBy) {
        return (
          <div className={styles.emptyStateSearchBy} data-testid="comments-empty-search">
            <FormattedMessage id="NO_MATCHES_FOUND" />
          </div>
        );
      }

      return <EmptyFilteredState identity="reviewerCommentPanel" size="medium" />;
    }
  };

  return (
    <Comments
      commentsCategories={{
        commentStatus: {
          options: [
            {
              value: 'RESOLVED',
              label: 'editor.sidebar.review.filter.status.resolved',
              labelNumber: allComments?.filter((comment) => comment.status === 'ACCEPTED').length,
            },
            {
              value: 'REJECTED',
              label: 'editor.sidebar.review.filter.status.deleted',
              labelNumber: allComments?.filter((comment) => comment.status === 'REJECTED').length,
            },
          ],
        },
        cardPriority: {
          options: [
            { value: 'LOW', label: 'editor.sidebar.review.filter.priority.low' },
            { value: 'MEDIUM', label: 'editor.sidebar.review.filter.priority.medium' },
            { value: 'HIGH', label: 'editor.sidebar.review.filter.priority.high' },
          ],
        },
        author: { options: userOptions, settings: { editorAvatar: true } },
        reviewerCreationDate: {
          options: [
            { value: 'last_week', label: 'LAST_WEEK' },
            { value: 'last_month', label: 'LAST_MONTH' },
            { value: '3_month_ago', label: 'NUMBER_MONTH_AGO', labelValue: 3 },
            { value: '6_month_ago', label: 'NUMBER_MONTH_AGO', labelValue: 6 },
            { value: 'specific_date_range', label: 'SPECIFIC_DATE_RANGE' },
          ],
        },
      }}
    >
      {commentsList && commentsList.length === 0 && !hasActiveFilters && !creating ? (
        <div className={styles.emptyState} data-testid="no-comments-yet">
          <EmptyCardListState size="medium" testId="no-comments-empty-card-list-state">
            <FormattedMessage id="NO_COMMENTS_YET" />
          </EmptyCardListState>
        </div>
      ) : (
        <>
          <Search margin="1rem 0 2rem 0" search={setCommentsSearchBy} />
          <FilterDisplay
            identity="reviewerCommentPanel"
            direction="column"
            margin="0 0 2rem 1rem"
          />
          <div data-testid="comments-list" className={styles.commentsList}>
            {creating?.type === 'comment' && (
              <div style={{ marginBottom: '1rem' }}>
                <CommentCard isTemporary sidebar />
              </div>
            )}
            {commentsFilteredList &&
            comments &&
            commentsFilteredList.list &&
            commentsFilteredList.list?.length > 0
              ? commentsFilteredList?.list.map((id: string | number) => {
                  return <CommentCard sidebar comment={comments?.data[id]} />;
                })
              : renderEmptyState()}
          </div>
        </>
      )}
    </Comments>
  );
};

export default CommentsPanel;
