import { NodeDataBuilder } from 'Editor/services/DataManager';
import { JsonRange } from 'Editor/services/_Common/Selection';
import { NodeUtils } from 'Editor/services/DataManager/models';
import { Command } from '../Command';
import { ActionContext } from '../../ActionContext';

const IMAGE_MAX_SIZE = 500;

export class InsertImageCommand extends Command {
  image: File;

  constructor(context: Editor.Edition.ICommandArgs, image: File) {
    super(context);

    this.image = image;
  }

  async exec(): Promise<Editor.Edition.ICommand> {
    this.uploadImage(this.image, (imageData) => {
      if (!this.context.DataManager || !this.context.DataManager.selection) {
        return this;
      }

      const rangeData = this.context.DataManager.selection.current;

      const jsonRange = rangeData[0] ? JsonRange.buildFromRangeData(rangeData[0]) : null;

      if (!jsonRange) {
        return this;
      }

      const baseModel = this.context.DataManager.nodes.getNodeModelById(jsonRange.start.b);
      const baseData = baseModel?.selectedData();

      if (!baseModel || !baseData) {
        return this;
      }

      let width: number = 500;
      let height: number = 500;
      if (imageData.dimensions.width >= imageData.dimensions.height) {
        width = Math.min(IMAGE_MAX_SIZE, imageData.dimensions.width);
        height =
          width === imageData.dimensions.width
            ? imageData.dimensions.height
            : (imageData.dimensions.height * IMAGE_MAX_SIZE) / imageData.dimensions.width;
      } else {
        height = Math.min(IMAGE_MAX_SIZE, imageData.dimensions.height);
        width =
          height === imageData.dimensions.height
            ? imageData.dimensions.width
            : (imageData.dimensions.width * IMAGE_MAX_SIZE) / imageData.dimensions.height;
      }

      const image = NodeDataBuilder.buildData({
        type: 'img',
        properties: {
          w: width,
          h: height,
          es: imageData.reference,
        },
      });

      if (image) {
        const result = NodeUtils.closestOfTypeByPath(baseData, jsonRange.start.p, ['p']);
        const closest = result?.data;

        if (closest) {
          this.insertInline(image);
        } else {
          const builder = new NodeDataBuilder('p');
          builder.addChildData(image);
          const paragraph = builder.build();

          let ctx: Editor.Edition.ActionContext = new ActionContext(jsonRange);

          if (paragraph && paragraph.id && this.context.contentManipulator) {
            this.context.contentManipulator.insertBlock(ctx, paragraph, 'AFTER');

            // handle create suggestions???
            this.handleSuggestionsUpdate(ctx);

            // apply new selection
            if (this.context.DataManager?.selection) {
              // TEMP: flag last selection
              this.context.DataManager.selection.history.flag('debounce');
              this.context.DataManager.selection.setUserSelection([
                ctx.range.serializeToRangeData(),
              ]);
            }

            // create patch
            this.context.DataManager?.history.createPatch();
          }
        }
      }
    });

    return this;
  }

  insertInline(image: Editor.Data.Node.Data) {
    if (this.context.commandFactory) {
      let command = this.context.commandFactory.getCommand('INSERT_INLINE');
      if (command) {
        command.exec(image);
      }
    }
  }
}
