import { useSelector } from '_common/hooks';
import { Details, Panel } from '_common/suite/components';
import CommentsPanel from './CommentsPanel/CommentsPanel';
import Tasks from 'Presentation/Sidebar/Tasks/Tasks';
import PresentationVersionHistory from './PresentationVersionHistory';

const SidebarPanel = () => {
  const rightPanel = useSelector((state) => state.presentation.general.rightPanel);

  const renderPanel = () => {
    switch (rightPanel) {
      case 'details':
        return <Details />;
      case 'annotations':
        return <CommentsPanel />;
      case 'tasks':
        return <Tasks />;
      case 'versionhistory':
        return <PresentationVersionHistory />;
      default:
        return null;
    }
  };

  return (
    <Panel side="right" width="49rem" key={`sidebar-panel-${rightPanel}`}>
      {renderPanel()}
    </Panel>
  );
};

export default SidebarPanel;
