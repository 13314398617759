import { JsonRange } from '../../../JsonRange';
import { BaseModifier } from '../BaseModifier';
import { NodeUtils } from 'Editor/services/DataManager';

export class ExpandTextModifier extends BaseModifier {
  protected incrementeOffset?: number;

  constructor(
    Data: Editor.Data.API,
    granularity: Extract<Editor.Selection.ModifierGranularity, 'character' | 'word'>,
    direction: Editor.Selection.ModifierDirection,
    incrementOffset?: number,
  ) {
    super(Data, 'expand', granularity, direction);

    this.incrementeOffset = incrementOffset;
  }

  private expandForward(
    range: Editor.Selection.JsonRange,
    modifiersData: Editor.Data.Selection.Modifiers,
  ) {
    if (modifiersData?.expandingDirection === 'backward') {
      const baseModel = this.Data.nodes.getNodeModelById(range.start.b);

      const baseData = baseModel?.selectedData();
      if (!baseModel || !baseData) {
        return false;
      }

      if (NodeUtils.isPathAtContentEnd(baseData, range.start.p)) {
        const nextModel = this.Data.nodes.getNextModelById(range.start.b);
        if (nextModel) {
          const path = nextModel.getPathToFirstContent();
          range.updateStartPosition({
            b: nextModel.id,
            p: path,
          });
        }
      } else {
        if (baseModel?.navigationData) {
          const iterator = this.getPositionIterator(
            baseModel.navigationData,
            range.start.p,
            this.incrementeOffset,
          );
          if (iterator) {
            let nextPath = iterator.next();

            range.start.p = nextPath;
          }
        }
      }
    } else {
      modifiersData.expandingDirection = 'forward';

      const baseModel = this.Data.nodes.getNodeModelById(range.end.b);

      const baseData = baseModel?.selectedData();
      if (!baseModel || !baseData) {
        return false;
      }

      if (NodeUtils.isPathAtContentEnd(baseData, range.end.p)) {
        const nextModel = this.Data.nodes.getNextModelById(range.end.b);
        if (nextModel) {
          const path = nextModel.getPathToFirstContent();
          range.updateEndPosition({
            b: nextModel.id,
            p: path,
          });
        }
      } else {
        if (baseModel?.navigationData) {
          const iterator = this.getPositionIterator(
            baseModel.navigationData,
            range.end.p,
            this.incrementeOffset,
          );
          if (iterator) {
            let nextPath = iterator.next();

            range.end.p = nextPath;
          }
        }
      }
    }
  }

  private expandBackward(
    range: Editor.Selection.JsonRange,
    modifiersData: Editor.Data.Selection.Modifiers,
  ) {
    if (modifiersData?.expandingDirection === 'forward') {
      const baseModel = this.Data.nodes.getNodeModelById(range.end.b);

      const baseData = baseModel?.selectedData();
      if (!baseModel || !baseData) {
        return false;
      }

      if (NodeUtils.isPathAtContentStart(baseData, range.end.p)) {
        const previousModel = this.Data.nodes.getPreviousModelById(range.end.b);
        if (previousModel) {
          const path = previousModel.getPathToLastContent();
          range.updateEndPosition({
            b: previousModel.id,
            p: path,
          });
        }
      } else {
        if (baseModel?.navigationData) {
          const iterator = this.getPositionIterator(
            baseModel.navigationData,
            range.end.p,
            this.incrementeOffset,
          );
          if (iterator) {
            let previousPath = iterator.previous();

            range.end.p = previousPath;
          }
        }
      }
    } else {
      modifiersData.expandingDirection = 'backward';

      const baseModel = this.Data.nodes.getNodeModelById(range.start.b);

      const baseData = baseModel?.selectedData();
      if (!baseModel || !baseData) {
        return false;
      }

      if (NodeUtils.isPathAtContentStart(baseData, range.start.p)) {
        const previousModel = this.Data.nodes.getPreviousModelById(range.start.b);
        if (previousModel) {
          const path = previousModel.getPathToLastContent();
          range.updateStartPosition({
            b: previousModel.id,
            p: path,
          });
        }
      } else {
        if (baseModel?.navigationData) {
          const iterator = this.getPositionIterator(
            baseModel.navigationData,
            range.start.p,
            this.incrementeOffset,
          );
          if (iterator) {
            let previousPath = iterator.previous();

            range.start.p = previousPath;
          }
        }
      }
    }
  }

  visitDoDOCRange(range: Editor.Selection.EditorRange): void {
    const jsonRange = JsonRange.buildFromDOMRange(range);
    this.visitJsonRange(jsonRange);
    range.updateFromJsonRange(jsonRange);
  }

  visitJsonRange(range: Editor.Selection.JsonRange): void {
    const modifiersData: Editor.Data.Selection.Modifiers = this.Data.selection?.modifiersData || {};

    modifiersData.direction = this.direction;

    //check modifers data
    const editorRange = range.serializeToDOMRange();
    if (range.isCollapsed()) {
      modifiersData.expandingDirection = null;
      modifiersData.cellSelection = false;
    } else if (!modifiersData.px) {
      const clientRects = editorRange.getClientRects();
      if (modifiersData.expandingDirection === 'forward') {
        modifiersData.px =
          clientRects.length > 0 ? clientRects[clientRects.length - 1].right : null;
      } else if (modifiersData?.expandingDirection === 'backward') {
        modifiersData.px = clientRects.length > 0 ? clientRects[0].left : null;
      }
    }

    switch (this.direction) {
      case 'forward':
        this.expandForward(range, modifiersData);
        break;
      case 'backward':
        this.expandBackward(range, modifiersData);
        break;
      default:
        break;
    }

    editorRange.updateFromJsonRange(range);
    const clientRects = editorRange.getClientRects();
    if (modifiersData.expandingDirection === 'backward') {
      if (clientRects.length > 0) {
        modifiersData.px = clientRects[0].left;
      }
    } else {
      if (clientRects.length > 0) {
        modifiersData.px = clientRects[clientRects.length - 1].right;
      }
    }

    // update range modifiers
    this.Data.selection?.updateModifiers(modifiersData);
  }
}
