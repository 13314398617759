import { useSuiteObject } from '_common/suite';
import PresentationApi, { useGetImageQuery } from 'Presentation/api';
import { useSlideData } from '../../../SlideData';
import * as echarts from 'echarts';
import { useDispatch } from '_common/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

const useChartColor = (fill?: Presentation.Data.Common.FillType | undefined) => {
  const { color } = useSlideData();
  const [responseData, setResponseData] = useState<string | undefined>(undefined); // State to store the response data
  const dispatch = useDispatch();
  const { id } = useSuiteObject();
  const [value, setValue] = useState<Presentation.Data.Common.PictureFillType>();
  const { data } = useGetImageQuery(
    {
      presentation_id: id,
      reference_id: value?.source ?? '',
    },
    { skip: !value },
  );

  useEffect(() => {
    const fetchData = async () => {
      let data;
      try {
        if (value?.type === 'picture') {
          const { data: objectData } = await dispatch(
            PresentationApi.endpoints.getImage.initiate({
              presentation_id: id,
              reference_id: value?.source,
            }),
          );
          data = objectData;
        }
      } catch (e) {
        throw e;
      }
      setResponseData(data);
    };

    fetchData();
  }, [value]);

  const heart = useMemo(() => {
    const you = responseData;
    return you;
  }, [responseData]);

  const chartColor = useCallback(
    (fill: Presentation.Data.Common.FillType | undefined) => {
      if (fill) {
        if (fill.type === 'gradient') {
          const stopValues = fill.stops.map((stop) => {
            return {
              offset: stop.pos / 100,
              color: color(stop.color),
            };
          });
          return new echarts.graphic.LinearGradient(
            0,
            0,
            0,
            1,
            stopValues.sort((a, b) => a.offset - b.offset),
          );
        } else if (fill.type === 'picture') {
          setValue(fill);
          const img = new Image();
          //@ts-expect-error
          img.src = data;
          return { image: img, repeat: 'repeat' };
        } else if (fill.type === 'solid') {
          return fill?.color ? color(fill.color) : 'transparent';
        } else {
          return undefined;
        }
      }
      return undefined;
    },
    [heart],
  );

  return { chartColor };
};

export default useChartColor;
