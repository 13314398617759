import { Logger } from '_common/services';
import { BaseManipulator } from '../Common/Base';
import { JsonRange } from 'Editor/services/_Common/Selection';
import { NodeUtils } from 'Editor/services/DataManager';
import { InsertElementOperation, RemoveContentOperation } from '../../Operations';
import ReduxInterface from 'Editor/services/ReduxInterface';

export class RemoveManipulator
  extends BaseManipulator
  implements Editor.Edition.IRemoveManipulator
{
  removeContent(
    ctx: Editor.Edition.ActionContext,
    opts: Editor.Edition.RemoveContentOptions = {},
  ): boolean {
    if (this.editionContext.debug) {
      Logger.trace('NormalManipulator removeContent', ctx);
    }
    if (!this.editionContext.DataManager) {
      return false;
    }

    let options: Editor.Edition.RemoveContentOptions = {
      selectionDirection: 'forward',
      useSelectedCells: false,
      ...opts,
    };

    // TODO:
    // check range positions
    // check if path is valid
    // is selection editable

    // check whole selection is editable
    if (!JsonRange.isSelectionEditable(this.editionContext.DataManager, ctx.range)) {
      Logger.error('Selection is not editable!');
      return false;
    }

    if (!opts.confirmDeleteCaption && this.validateCaptionsOnRange(ctx)) {
      ctx.avoidNextNonCollapsedAction = true;

      // trigger confirmation modal
      ReduxInterface.openDeleteCaptionConfirmationModal();

      return false;
    }

    this.removeTrackedParagraphMarkers(ctx, false, options.useSelectedCells);

    let startModel = this.editionContext.DataManager.nodes.getNodeModelById(ctx.range.start.b);
    if (!startModel) {
      return false;
    }

    // get base level ranges
    let rangesToRemove: Editor.Selection.JsonRange[] = JsonRange.splitRangeByTypes(
      this.editionContext.DataManager,
      ctx.range,
      [...NodeUtils.BLOCK_TEXT_TYPES, ...NodeUtils.BLOCK_NON_TEXT_TYPES],
      {
        onlyContainerLevel: true,
        useSelectedCells: options.useSelectedCells,
      },
    );

    let resultPath: Editor.Selection.Path | undefined;

    let removeBlockOps: Editor.Edition.IOperationBuilder[] = [];

    let startBlockInfo: Editor.Data.Node.DataPathInfo | undefined;

    for (let i = 0; i < rangesToRemove.length; i++) {
      const range = rangesToRemove[i];

      const baseModel = this.editionContext.DataManager.nodes.getNodeModelById(range.start.b);

      const baseData = baseModel?.selectedData();
      if (!baseModel || !baseData) {
        continue;
      }

      const closestBlock = NodeUtils.closestOfTypeByPath(baseData, range.getCommonAncestorPath(), [
        ...NodeUtils.BLOCK_TEXT_TYPES,
        ...NodeUtils.BLOCK_NON_TEXT_TYPES,
      ]);

      if (closestBlock) {
        if (i === 0) {
          startBlockInfo = closestBlock;
          // FIRST ELEMENT
          // TODO: check for suggestion paragraph markers

          if (NodeUtils.isBlockTextData(closestBlock.data)) {
            if (!range.isCollapsed()) {
              // TEXT BLOCKS
              let removeOp = new RemoveContentOperation(
                baseModel,
                range.start.p,
                range.end.p,
                opts,
              );
              removeOp.apply();
              resultPath = removeOp.getAdjustedPath();
            }
          } else {
            // NON TEXT BLOCKS
            let removeOp = this.getRemoveBlockOperation(
              baseModel,
              closestBlock.data,
              closestBlock.path,
            );
            if (removeOp) {
              removeBlockOps.push(removeOp);
            }
          }
        } else if (i === rangesToRemove.length - 1) {
          // LAST ELEMENT
          if (
            startBlockInfo &&
            NodeUtils.canBlocksBeMerged(startBlockInfo.data, closestBlock.data) &&
            NodeUtils.isBlockTextData(closestBlock.data)
          ) {
            const textElementData = closestBlock.data;

            // join content
            if (!options.useSelectedCells) {
              // let startOffset = startBlockInfo.data.childNodes?.length || 0;
              let pathToMerge: Editor.Selection.Path | undefined;
              if (!resultPath) {
                resultPath = [
                  ...startBlockInfo.path,
                  'childNodes',
                  startBlockInfo.data.childNodes?.length || 0,
                ];
              }
              pathToMerge = resultPath;

              let cloneStartPath: Editor.Selection.Path = range.end.p.slice(
                closestBlock.path.length,
              );
              let cloneEndPath: Editor.Selection.Path = [
                'childNodes',
                textElementData.childNodes?.length || 0,
              ];

              const clonedNodes = NodeUtils.cloneData(
                textElementData,
                cloneStartPath,
                cloneEndPath,
              );

              let preOpPath: Editor.Selection.Path | undefined;
              for (let i = 0; i < clonedNodes.length; i++) {
                if (pathToMerge) {
                  let insertOp: InsertElementOperation = new InsertElementOperation(
                    startModel,
                    pathToMerge,
                    clonedNodes[i],
                  );
                  if (insertOp.hasOpsToApply()) {
                    insertOp.apply();
                    pathToMerge = insertOp.getAdjustedPath();

                    if (!preOpPath) {
                      preOpPath = insertOp.getPreOpPath();
                    }
                  }
                }
              }

              if (!preOpPath) {
                resultPath = preOpPath;
              }
            }

            let removeOp = this.getRemoveBlockOperation(
              baseModel,
              closestBlock.data,
              closestBlock.path,
            );
            if (removeOp) {
              removeBlockOps.push(removeOp);
            }
          } else {
            let removeOp = this.getRemoveBlockOperation(
              baseModel,
              closestBlock.data,
              closestBlock.path,
            );
            if (removeOp) {
              removeBlockOps.push(removeOp);
            }
          }
        } else {
          // MIDDLE ELEMENTS
          let removeOp = this.getRemoveBlockOperation(
            baseModel,
            closestBlock.data,
            closestBlock.path,
          );
          if (removeOp) {
            removeBlockOps.push(removeOp);
          }
        }
      }

      if (resultPath) {
        // update suggestion content if any
        this.updateSuggestionContent(ctx, baseModel, resultPath);
      }
    }

    for (let r = removeBlockOps.length - 1; r >= 0; r--) {
      if (removeBlockOps[r]) {
        removeBlockOps[r].apply();
      }
    }

    if (resultPath) {
      ctx.range.updateRangePositions({
        b: ctx.range.start.b,
        p: resultPath,
      });
    } else {
      ctx.range.collapse(true);
    }

    return true;
  }
}
