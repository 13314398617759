import { Logger } from '_common/services';
import { DefaultCommand } from '../KeyDownCommands';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';

export class CompositionCommand extends DefaultCommand {
  async exec(...args: any[]): Promise<Editor.Edition.ICommand> {
    if (this.context.debug) {
      Logger.trace('CompositionCommand exec', this);
    }

    let dataToInsert = this.getEventData();

    if (dataToInsert) {
      let editorRange = EditorSelectionUtils.getRange();

      if (editorRange) {
        const startContainer = editorRange.startContainer;
        const startOffset = editorRange.startOffset;

        if (startContainer instanceof Text) {
          startContainer.deleteData(startOffset - dataToInsert.length, dataToInsert.length);
        }
      }
    }

    return super.exec();
  }
}
