import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { OnboardingIntegrationCards, Pulse, Box } from 'dodoc-design-system';
import {
  OnBoardingCarBoxProps,
  OnboardingHandler,
  OnboardingIntegrationCardsProps,
} from 'dodoc-design-system/build/types/Components/OnboardingIntegrationCards/OnboardingIntegrationCards';
import ReactDOM from 'react-dom';

import { useDispatch, useSelector } from '_common/hooks';
import { useGetCurrentUserQuery } from '_common/services/api/authority';

import {
  activateOnboarding,
  completeAction,
  completeActionList,
  EditorActions,
  resetAction,
  setInteractions,
  setRenderAbove,
  stopOnboarding,
} from 'App/redux/onboardingSlice';
import { useOnboardingStatusMutation } from 'App/redux/onboardingApi';

import { OnboardingOverlay, WelcomeScreen } from '_common/components';
import useCards, { ExtendedCard } from './useCards';
import { setSidebarPanelTab, setSidebarView } from 'Editor/redux/SidebarSlice';
import { setVersionHistoryValue } from 'Editor/redux/EditorStatusSlice';
import useDocumentParagraph from './useDocumentParagraph';
import EditorManager from 'Editor/services/EditorManager/EditorManager';
import { EditorDOMElements } from 'Editor/services/_Common/DOM';

type Position = {
  top?: string;
  bottom?: string;
  right?: string;
  left?: string;
};

type Rect = {
  top: number;
  left: number;
  height: number;
  width: number;
};

const Standalone = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const getDocumentParagraphNode = useDocumentParagraph();
  const onboardingRef = useRef<OnboardingHandler>(null);
  const boxPollInterval = useRef<NodeJS.Timer | null>(null);

  const initialPhase = useSelector((state) => state.onboarding.initialPhase.editor);
  const initialJourney = useSelector((state) => state.onboarding.initialJourney.editor);
  const originalDocId = useSelector((state) => state.onboarding.currentDocument.editor?.id);
  const actionsCompleted = useSelector((state) => state.onboarding.actionsCompleted);
  const started = useSelector((state) => state.onboarding.started.editor);
  const active = useSelector((state) => state.onboarding.active.editor);
  const currentDocumentId = useSelector((state) => state.editor.status.documentId);
  const sidebar = useSelector((state) => state.editor.sidebar.view);
  const pulseData = useSelector((state) => state.onboarding.pulseData);
  const zoom = useSelector((state) => state.editor.status.zoom);
  const hasSelection = useSelector((state) => !state.editor.status.selection.COLLAPSED);
  const paragraphsAreLoaded = useSelector((state) => state.editor.status.paragraphsLoaded);

  const [sendOnboardingStatus] = useOnboardingStatusMutation();
  const { isSuccess: userLoaded } = useGetCurrentUserQuery();

  const [skipping, setSkipping] = useState<boolean>(false);
  const [initialCardIndex, setInitialCardIndex] = useState<number>();
  const [furthestCard, setFurthestCard] = useState<number>(0);
  const [currentJourney, setCurrentJourney] = useState<EditorOnboardingJourney | undefined>(
    initialPhase === 'beginning' ? undefined : initialJourney,
  );
  const cards = useCards(currentJourney);
  const [activeCard, setActiveCard] = useState<ExtendedCard>(cards[initialCardIndex ?? 0]);
  const [pulsePosition, setPulsePosition] = useState<{
    position?: Position;
    boundary?: HTMLElement | null;
    paragraphId?: string;
  }>();
  const [boxPosition, setBoxPosition] = useState<{
    position: OnBoardingCarBoxProps;
    boundary: HTMLElement | null;
    paragraphId?: string;
  }>();
  const task = useSelector((state) => state.editor.tasks.order[0]);

  useEffect(() => {
    if (pulsePosition?.paragraphId) {
      EditorManager.getInstance().scrollIntoView(pulsePosition.paragraphId);
    }
  }, [pulsePosition]);

  useEffect(() => {
    if (boxPosition?.paragraphId) {
      EditorManager.getInstance().scrollIntoView(boxPosition.paragraphId);
    }
  }, [boxPosition]);

  //#region Paragraph selection actions
  const [selecting, setSelecting] = useState(false);

  const startSelecting = () => setSelecting(true);
  const stopSelecting = () => setSelecting(false);

  useEffect(() => {
    if (!selecting) {
      if (hasSelection) {
        dispatch(completeAction('editor_writing_selectFont'));
        dispatch(completeAction('editor_writing_selectHeading'));
      } else {
        dispatch(resetAction('editor_writing_selectFont'));
        dispatch(resetAction('editor_writing_selectHeading'));
      }
    }
  }, [selecting, hasSelection]);

  useEffect(() => {
    if (started) {
      document.addEventListener('mousedown', startSelecting);
      document.addEventListener('mouseup', stopSelecting);
      return () => {
        document.removeEventListener('mousedown', startSelecting);
        document.removeEventListener('mouseup', stopSelecting);
      };
    }
  }, [started]);
  //#endregion

  useEffect(() => {
    if (started) {
      dispatch(
        setSidebarPanelTab({
          view: 'review',
          tab: currentJourney === 'limitedReviewer' ? 'comments' : 'changes',
        }),
      );
    }
  }, [started]);

  useEffect(() => {
    if (started) {
      dispatch(setSidebarView(null));
      dispatch(setVersionHistoryValue(false));
    }
  }, [started]);

  useEffect(() => {
    setFurthestCard(0);
    dispatch(setRenderAbove(currentJourney === 'general'));
  }, [currentJourney]);

  //Auto next
  useEffect(() => {
    if (activeCard.deps?.length && activeCard.deps.every((dep) => actionsCompleted[dep])) {
      onboardingRef.current?.next();
    }
  }, [actionsCompleted]);

  const phases = useMemo<OnboardingIntegrationCardsProps['phases']>(() => {
    let orderOffset = 0;

    switch (currentJourney) {
      case 'reviewer':
        orderOffset = 1;
        break;
      case 'limitedReviewer':
        orderOffset = 2;
        break;
    }

    const staticPhases: OnboardingIntegrationCardsProps['phases'] = {
      comments: {
        order: 3 - orderOffset,
        label: intl.formatMessage({ id: 'COMMENTS' }),
      },
      tasks: {
        order: 4 - orderOffset,
        label: intl.formatMessage({ id: 'TASKS' }),
      },
    };

    const writing: ValueOf<OnboardingIntegrationCardsProps['phases']> = {
      order: 1 - orderOffset,
      label: intl.formatMessage({ id: 'WRITING' }),
    };
    const suggestions: ValueOf<OnboardingIntegrationCardsProps['phases']> = {
      order: 2 - orderOffset,
      label: intl.formatMessage({ id: 'TRACK_CHANGES' }),
    };

    switch (currentJourney) {
      case 'reviewer':
        return {
          suggestions,
          ...staticPhases,
        };
      case 'limitedReviewer':
        return staticPhases;
      default:
        return { writing, suggestions, ...staticPhases };
    }
  }, [currentJourney]);

  /**
   * Continue previous state after refresh
   * Will auto-complete actions of previous cards
   * Will execute callbacks of previous cards in order to update the view
   */
  useEffect(() => {
    if (!userLoaded) {
      return;
    }

    /**
     * Shouldn't be necessary but added as a safeguard.
     *
     * Ignore initialPhase behaviour in the following states
     */
    const inactiveStates = ['beginning', 'ending'];
    if (initialPhase && !inactiveStates.includes(initialPhase)) {
      const initialCardIndex = cards.findIndex((card) => card.id === initialPhase);
      if (initialCardIndex > 0) {
        setActiveCard(cards[initialCardIndex]);
        setInitialCardIndex(initialCardIndex);

        const completedActions = cards
          .slice(0, initialCardIndex)
          .reduce<EditorActions[]>((allActions, card) => {
            if (card.deps) {
              allActions = [...allActions, ...card.deps];
            }

            return allActions;
          }, []);

        dispatch(completeActionList(completedActions));

        for (let i = initialCardIndex; i >= 0; i--) {
          const card = cards[i];
          if (card.onSkip) {
            card.onSkip();
            break;
          }
        }
      }
    }
  }, [userLoaded]);

  //Clean up local states when initial phase state has been cleaned up
  useEffect(() => {
    if (!initialPhase) {
      setInitialCardIndex(undefined);
      setActiveCard(cards[0]);
    }
  }, [initialPhase]);

  //Enable interactions defined in the new active card
  useEffect(() => {
    dispatch(setInteractions(activeCard.interactions ?? []));
  }, [activeCard]);

  //Inform API about onboarding progress, once all dependencies are fulfilled
  useEffect(() => {
    if (activeCard.deps?.every((dep) => actionsCompleted[dep])) {
      const activeCardIndex = cards.findIndex((card) => card.id === activeCard.id);

      //Validate that the next card exists
      if (activeCardIndex + 1 <= cards.length) {
        if (activeCardIndex + 1 > furthestCard) {
          setFurthestCard(activeCardIndex + 1);
        }
        sendOnboardingStatus({
          target: 'editor',
          step: cards[activeCardIndex + 1].id,
          journey: currentJourney,
        });
      }
    }
  }, [activeCard, actionsCompleted]);

  const getDocumentParagraph = useCallback(
    (paragraphIndex: number) => {
      const paragraphs = document.getElementsByTagName('paragraph-element');
      if (paragraphs) {
        return paragraphs[paragraphIndex] as HTMLElement;
      }
    },
    [paragraphsAreLoaded],
  );

  const addPulseToParagraph = (paragraph: HTMLElement, position: Position) => {
    if (EditorDOMElements.isParagraphElement(paragraph)) {
      EditorManager.getInstance().addWidget('onboardingPulse', paragraph, {
        testId: 'editor',
        pulsePosition: position,
      });
    }
  };

  const removeAllPulsesFromParagraphs = () => {
    EditorManager.getInstance().removeAllWidgetsForType('onboardingPulse');
  };

  const paragraphPulseOffset = useMemo(() => {
    return {
      top: `2px`,
      left: `-25px`,
    };
  }, []);

  useEffect(() => {
    const pointerRadius = 12;
    const pointerDiameter = pointerRadius * 2;

    if (activeCard.pulseTarget) {
      activeCard.pulseTarget?.forEach((target) => {
        switch (target) {
          case 'contextMenu_task': {
            const { contextMenuTaskItemRect } = pulseData;
            if (contextMenuTaskItemRect) {
              setPulsePosition({
                position: {
                  top: `${Math.ceil(
                    contextMenuTaskItemRect.top +
                      contextMenuTaskItemRect.height / 2 -
                      pointerRadius,
                  )}px`,
                  left: `${Math.ceil(contextMenuTaskItemRect.left)}px`,
                },
                boundary: document.getElementById('EditorContextMenu'),
              });
            }
            break;
          }
          case 'contextMenu_comment': {
            const { contextMenuCommentItemRect } = pulseData;
            if (contextMenuCommentItemRect) {
              setPulsePosition({
                position: {
                  top: `${Math.ceil(
                    contextMenuCommentItemRect.top +
                      contextMenuCommentItemRect.height / 2 -
                      pointerRadius,
                  )}px`,
                  left: `${Math.ceil(contextMenuCommentItemRect.left)}px`,
                },
                boundary: document.getElementById('EditorContextMenu'),
              });
            }
            break;
          }
          case 'taskDescription':
          case 'taskAssigned':
          case 'taskDueDate': {
            const temporaryTaskCard = document.getElementById('temporaryTaskCard');

            if (temporaryTaskCard) {
              const descriptionElement = document.getElementById('temporaryTaskDescription');
              const assignedToElement = document.getElementById('temporaryTaskAssignedTo');
              const dueDateElement = document.getElementById('temporaryTaskDueDate');
              const ctaButtonElement = document.getElementById('temporaryTaskCTA');

              if (descriptionElement && assignedToElement && dueDateElement && ctaButtonElement) {
                const { taskHasAssignee, taskHasDescription, taskHasDueDate } = pulseData;
                let rect: Rect = { top: 0, left: 0, height: 0, width: 0 };

                if (!taskHasDescription) {
                  rect = {
                    top: descriptionElement.offsetTop,
                    left: descriptionElement.offsetLeft,
                    height: descriptionElement.offsetHeight,
                    width: descriptionElement.offsetWidth,
                  };
                } else if (!taskHasAssignee) {
                  rect = {
                    top: assignedToElement.offsetTop,
                    left: assignedToElement.offsetLeft,
                    height: assignedToElement.offsetHeight,
                    width: assignedToElement.offsetWidth,
                  };
                } else if (!taskHasDueDate) {
                  rect = {
                    top: dueDateElement.offsetTop,
                    left: dueDateElement.offsetLeft,
                    height: dueDateElement.offsetHeight,
                    width: dueDateElement.offsetWidth,
                  };
                } else {
                  rect = {
                    top: ctaButtonElement.offsetTop,
                    left: ctaButtonElement.offsetLeft,
                    height: ctaButtonElement.offsetHeight,
                    width: ctaButtonElement.offsetWidth,
                  };
                }

                if (!taskHasDescription || !taskHasAssignee || !taskHasDueDate) {
                  setPulsePosition({
                    position: {
                      top: `${Math.ceil(
                        rect.top +
                          rect.height -
                          (pointerDiameter / 3) * 2 /*Two thirds of pointer diameter */,
                      )}px`,
                      left: `${Math.ceil(
                        rect.left + rect.width - pointerDiameter - 4 /*Right padding*/,
                      )}px`,
                    },
                    boundary: document.getElementById(`temporaryTaskCard`),
                  });
                } else {
                  setPulsePosition({
                    position: {
                      top: `${Math.ceil(
                        rect.top + rect.height / 2 + pointerDiameter / 3, //One third of pointer diameter
                      )}px`,
                      left: `${Math.ceil(rect.left - pointerRadius + rect.width / 2)}px`,
                    },
                    boundary: document.getElementById(`temporaryTaskCard`),
                  });
                }
              }
            }

            break;
          }
          case 'commentReply': {
            const { commentId, annotationCardReplyRect } = pulseData;
            if (annotationCardReplyRect) {
              setPulsePosition({
                position: {
                  top: `${Math.ceil(
                    annotationCardReplyRect.top +
                      annotationCardReplyRect.height -
                      pointerDiameter / 3 /*One third of pointer diameter */,
                  )}px`,
                  left: `${Math.ceil(
                    annotationCardReplyRect.left +
                      annotationCardReplyRect.width / 2 -
                      pointerRadius,
                  )}px`,
                },
                boundary: document.getElementById(`Comment#${commentId}`),
              });
            } else {
              setPulsePosition(undefined);
            }
            break;
          }
          case 'document_paragraph_selectFont': {
            if (!actionsCompleted.editor_writing_selectFont) {
              const paragraph = getDocumentParagraph(0);
              if (paragraph) {
                setPulsePosition({
                  paragraphId: getDocumentParagraphNode(0)?.id,
                });
                addPulseToParagraph(paragraph, paragraphPulseOffset);
              }
            } else if (
              !actionsCompleted.editor_writing_font_open &&
              !actionsCompleted.editor_writing_font_select
            ) {
              setPulsePosition({
                position: { top: '0px', left: '50%' },
                boundary: document.getElementById('fontFamilySelect'),
              });
              removeAllPulsesFromParagraphs();
            } else if (!actionsCompleted.editor_writing_font_select) {
              setPulsePosition({
                position: { top: `calc(50% - ${pointerRadius}px)`, left: '65%' },
                boundary: document.getElementById('fontFamilyTargetAction'),
              });
              removeAllPulsesFromParagraphs();
            } else {
              setPulsePosition(undefined);
              removeAllPulsesFromParagraphs();
            }

            break;
          }
          case 'document_paragraph_selectHeading': {
            if (!actionsCompleted.editor_writing_selectHeading) {
              const paragraph = getDocumentParagraph(1);
              if (paragraph) {
                setPulsePosition({
                  paragraphId: getDocumentParagraphNode(1)?.id,
                });
                addPulseToParagraph(paragraph, { ...paragraphPulseOffset, top: '-2px' });
              }
            } else if (
              !actionsCompleted.editor_writing_heading_open &&
              !actionsCompleted.editor_writing_heading_select
            ) {
              setPulsePosition({
                position: { top: '0px', left: '50%' },
                boundary: document.getElementById('headingSelect'),
              });
              removeAllPulsesFromParagraphs();
            } else if (!actionsCompleted.editor_writing_heading_select) {
              setPulsePosition({
                position: { top: `calc(65% - ${pointerRadius}px)`, left: '65%' },
                boundary: document.getElementById('headingTargetAction'),
              });
              removeAllPulsesFromParagraphs();
            } else {
              setPulsePosition(undefined);
              removeAllPulsesFromParagraphs();
            }

            break;
          }
          case 'document_suggestion_add': {
            if (!actionsCompleted.editor_suggestions_addText) {
              const paragraph = getDocumentParagraph(3);
              if (paragraph) {
                setPulsePosition({
                  paragraphId: getDocumentParagraphNode(3)?.id,
                });
                addPulseToParagraph(paragraph, paragraphPulseOffset);
              }
            } else {
              setPulsePosition(undefined);
              removeAllPulsesFromParagraphs();
            }

            break;
          }
          case 'document_suggestion_delete': {
            if (!actionsCompleted.editor_suggestions_deleteText) {
              const paragraph = getDocumentParagraph(4);
              if (paragraph) {
                setPulsePosition({
                  paragraphId: getDocumentParagraphNode(4)?.id,
                });
                addPulseToParagraph(paragraph, { ...paragraphPulseOffset, top: '-2px' });
              }
            } else {
              setPulsePosition(undefined);
              removeAllPulsesFromParagraphs();
            }

            break;
          }
          case 'document_comment': {
            if (
              !actionsCompleted.editor_comments_startCommentCreation &&
              !actionsCompleted.editor_comments_createComment
            ) {
              const paragraph = getDocumentParagraph(1);
              if (paragraph) {
                setPulsePosition({
                  paragraphId: getDocumentParagraphNode(1)?.id,
                });
                addPulseToParagraph(paragraph, paragraphPulseOffset);
              }
            } else {
              setPulsePosition(undefined);
              removeAllPulsesFromParagraphs();
            }

            break;
          }
          case 'document_task': {
            if (!actionsCompleted.editor_tasks_startTaskCreation) {
              const paragraph = getDocumentParagraph(2);
              if (paragraph) {
                setPulsePosition({
                  paragraphId: getDocumentParagraphNode(2)?.id,
                });
                addPulseToParagraph(paragraph, { ...paragraphPulseOffset, top: '-2px' });
              }
            } else {
              setPulsePosition(undefined);
              removeAllPulsesFromParagraphs();
            }

            break;
          }
          default: {
            setPulsePosition(undefined);
          }
        }
      });
    } else {
      setPulsePosition(undefined);
    }
  }, [pulseData, activeCard, zoom, actionsCompleted, getDocumentParagraph, paragraphPulseOffset]);

  useEffect(() => {
    const boxPadding = 8;

    if (activeCard.boxTarget) {
      activeCard.boxTarget?.forEach((target) => {
        switch (target) {
          case 'document_paragraph_editedText':
            {
              const paragraph = getDocumentParagraphNode(3);
              if (paragraph) {
                const deletedText = paragraph?.getElementsByTagName('track-del-element')?.[0];
                const insertedText = paragraph?.getElementsByTagName('track-ins-element')?.[0];

                if (insertedText) {
                  const insertedRect = insertedText.getBoundingClientRect();
                  const paragraphRect = paragraph.getBoundingClientRect();
                  if (!deletedText) {
                    //Added text
                    setBoxPosition({
                      position: {
                        top: `${-boxPadding / zoom}px`,
                        left: `${(insertedRect.left - paragraphRect.left - boxPadding) / zoom}px`,
                        width: `${(insertedRect.width + boxPadding * 2) / zoom}px`,
                        height: `${(insertedRect.height + boxPadding * 2) / zoom}px`,
                      },
                      boundary: paragraph,
                    });
                  } else {
                    //Replaced text
                    const deletedRect = deletedText.getBoundingClientRect();
                    setBoxPosition({
                      position: {
                        top: `${-boxPadding / zoom}px`,
                        left: `${(deletedRect.left - paragraphRect.left - boxPadding) / zoom}px`,
                        width: `${
                          (deletedRect.width + insertedRect.width + boxPadding * 2) / zoom
                        }px`,
                        height: `${
                          (Math.max(deletedRect.height, insertedRect.height) + boxPadding * 2) /
                          zoom
                        }px`,
                      },
                      boundary: paragraph,
                    });
                  }
                }
              }
            }
            break;
          case 'document_paragraph_deletedText':
            {
              const paragraph = getDocumentParagraphNode(4);
              if (paragraph) {
                const deletedText = paragraph?.getElementsByTagName('track-del-element')?.[0];

                if (deletedText) {
                  const deletedRect = deletedText.getBoundingClientRect();
                  const paragraphRect = paragraph.getBoundingClientRect();
                  //Deleted text
                  setBoxPosition({
                    position: {
                      top: `${-boxPadding / zoom}px`,
                      left: `${(deletedRect.left - paragraphRect.left - boxPadding) / zoom}px`,
                      width: `${(deletedRect.width + boxPadding * 2) / zoom}px`,
                      height: `${(deletedRect.height + boxPadding * 2) / zoom}px`,
                    },
                    boundary: paragraph,
                  });
                }
              }
            }
            break;
          case 'sidebar_taskPanel_header':
            {
              const element = document.getElementById('editorTaskPanelHeading');
              if (element) {
                const elementRect = element.getBoundingClientRect();
                const windowWidth = document.body.clientWidth;
                //Deleted text
                const boxWidth = 165;
                const boxHeight = 32;
                setBoxPosition({
                  position: {
                    top: `${elementRect.top + elementRect.height / 2 - boxHeight / 2}px`,
                    right: `${
                      windowWidth - elementRect.left - elementRect.width / 2 - boxWidth / 2
                    }px`,
                    width: `${boxWidth}px`,
                    height: `${boxHeight}px`,
                  },
                  boundary: element,
                });
              }
            }
            break;
          case 'card_comment':
          case 'card_task': {
            const { commentId, taskId } = pulseData;
            const elementId =
              target === 'card_comment'
                ? commentId != null
                  ? `Comment#${commentId}`
                  : undefined
                : (taskId || task) != null
                ? `Task#${taskId ?? task}`
                : undefined;

            if (elementId) {
              clearInterval(boxPollInterval.current!);
              boxPollInterval.current = setInterval(() => {
                const element = document.getElementById(elementId);
                if (element) {
                  const elementRect = element.getBoundingClientRect();

                  setBoxPosition({
                    position: {
                      top: '0px',
                      left: '0px',
                      width: `${elementRect.width}px`,
                      height: `${elementRect.height}px`,
                    },
                    boundary: element,
                  });
                  clearInterval(boxPollInterval.current!);
                }
              }, 100);
            }

            break;
          }

          default: {
            clearInterval(boxPollInterval.current!);
            setBoxPosition(undefined);
          }
        }
      });
    } else {
      clearInterval(boxPollInterval.current!);
      setBoxPosition(undefined);
    }
  }, [
    activeCard,
    zoom,
    actionsCompleted,
    getDocumentParagraphNode,
    paragraphsAreLoaded,
    pulseData,
    task,
  ]);

  const endOnboarding = async (skip?: boolean) => {
    /* [Firefox] DDC-9585 - User is not able to skip onboarding when using Firefox
     * Have to await for request, otherwise the request will be aborted (NS_BINDING_ABORTED), not sure why
     * Maybe the browser does the window.open before the request is finishied and the request gets aborted
     */
    await sendOnboardingStatus({ target: 'editor', step: 'ending', skip: !!skip });
    dispatch(stopOnboarding('editor'));
    setSkipping(false);

    window.open(`/editor/${originalDocId}`, '_self');
  };

  const handleSkip = () => {
    endOnboarding(true);
  };

  const handleEnding = () => {
    endOnboarding();
  };

  const handleNext = (newActiveCard: number) => {
    if (newActiveCard > furthestCard) {
      cards[newActiveCard].onFirstRender?.();
    }
    cards[newActiveCard].before?.();

    if (cards[newActiveCard].id !== 'ending') {
      if (newActiveCard > furthestCard) {
        setFurthestCard(newActiveCard);
      }
      sendOnboardingStatus({
        target: 'editor',
        step: cards[newActiveCard].id,
        journey: currentJourney,
      });
    }

    setActiveCard(cards[newActiveCard]);
  };

  const handleBack = (newActiveCard: number) => {
    cards[newActiveCard].before?.();

    if (activeCard.id === 'beginning') {
      dispatch(activateOnboarding('editor'));
      dispatch(stopOnboarding('editor'));
    } else {
      setActiveCard(cards[newActiveCard]);
    }
  };

  const onCancelSkip = () => {
    setSkipping(false);
  };

  const renderDynamicPulse = () => {
    const nonCompletedPulse =
      activeCard.dynamicPulse?.filter((pulse) => !actionsCompleted[pulse.targetAction]) ?? [];

    return nonCompletedPulse.length > 0 ? (
      <Pulse position={nonCompletedPulse[0].position} testId="editor" />
    ) : null;
  };

  if ((!started && !active) || originalDocId === '' || currentDocumentId === originalDocId) {
    return null;
  }

  return (
    <>
      {(active || (started && skipping)) &&
        ReactDOM.createPortal(
          <WelcomeScreen
            location="editor"
            onSkip={setSkipping}
            labelsId={{
              title: 'WELCOME_TO_THE_DODOC_EDITOR',
              description: 'ONBOARDING_EDITOR_DESCRIPTION',
              cta: 'LEARN_MORE',
            }}
            showCurrentUser={false}
            questionnaire={{
              isOpen:
                active && !!currentJourney && initialPhase !== 'beginning' && initialPhase != null,
              question: { id: 'WHAT_IS_YOUR_OBJECTIVE_WHEN_USING_THE_DODOC_EDITOR' },
              answers: {
                general: { id: 'READ_DOCUMENTS' },
                writer: {
                  id: 'WRITE_AND_REVIEW_DOCUMENTS_BY_INTRODUCING_CHANGES_COMMENTS_AND_TASKS',
                },
                limitedReviewer: { id: 'REVIEW_DOCUMENTS_BY_COMMENTING_AND_CREATING_TASKS' },
                reviewer: {
                  id: 'REVIEW_DOCUMENTS_BY_INTRODUCING_CHANGES_COMMENTS_AND_TASKS',
                },
              },
              defaultAnswer:
                initialPhase !== 'beginning' && initialPhase != null ? currentJourney : undefined,
              setAnswer: setCurrentJourney,
            }}
            hiddenElements={[currentDocumentId]}
            padding="18.5rem 0 5rem"
          />,
          document.getElementById('WelcomeScreen') ?? document.body,
        )}
      <OnboardingOverlay environment="editor">
        <span
          style={{
            visibility: (!started && !skipping) || !userLoaded ? 'hidden' : 'visible',
          }}
        >
          <OnboardingIntegrationCards
            ref={onboardingRef}
            cards={cards}
            initialCard={initialCardIndex}
            onExit={handleSkip}
            onFinalExit={handleEnding}
            phases={phases}
            onNext={handleNext}
            onBack={handleBack}
            skipButtonLabel={intl.formatMessage({ id: 'SKIP_ONBOARDING' })}
            previousLabel={intl.formatMessage({ id: 'BACK' })}
            next={intl.formatMessage({ id: 'NEXT' })}
            skipPreviousLabel={intl.formatMessage({ id: 'NO_CONTINUE_ONBOARDING' })}
            skipFinishLabel={intl.formatMessage({ id: 'YES_SKIP' })}
            skipHeader={intl.formatMessage({ id: 'SKIP_THE_ONBOARDING_QUESTION' })}
            skipContent={intl.formatMessage(
              { id: 'ONBOARDING_EDITOR_SKIP_CONFIRMATION' },
              { br: <br /> },
            )}
            tip={intl.formatMessage({ id: 'TIP_COLON' })}
            title={intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' })}
            nextDisabled={
              !!activeCard.deps && !activeCard.deps.every((dep) => actionsCompleted[dep])
            }
            nextDisabledTooltip={intl.formatMessage({ id: 'ONBOARDING_PENDING_ACTION_TOOLTIP' })}
            skip={skipping}
            cancelSkip={skipping ? onCancelSkip : undefined}
            offset={sidebar ? { right: '55rem' } : undefined}
            testId="editor"
            pulse={
              pulsePosition?.position ? (
                pulsePosition.boundary ? (
                  ReactDOM.createPortal(
                    <Pulse position={pulsePosition.position} testId="editor" />,
                    pulsePosition.boundary,
                  )
                ) : (
                  <Pulse position={pulsePosition.position} testId="editor" />
                )
              ) : (
                renderDynamicPulse()
              )
            }
            box={
              boxPosition?.position &&
              (boxPosition.boundary ? (
                ReactDOM.createPortal(
                  <Box box={boxPosition.position} testId="editor" />,
                  boxPosition.boundary,
                )
              ) : (
                <Box box={boxPosition.position} testId="editor" />
              ))
            }
          />
        </span>
      </OnboardingOverlay>
    </>
  );
};

export default Standalone;
