import { useIntl } from 'react-intl';

export type ExtraAction =
  | 'group_permission_added'
  | 'group_permission_removed'
  | 'group_role_added'
  | 'group_role_removed'
  | 'lock_suggestions'
  | 'unlock_suggestions'
  | 'rename'
  | 'edit_description'
  | 'add_due'
  | 'remove_due'
  | 'edit_due'
  | 'edit_warning'
  | 'convert_file';

const documentActions: (ActionLog['action'] | ExtraAction)[] = [
  'comment_created',
  'comment_resolved',
  'comment_reply',
  'comment_deleted',
  'task_created',
  'task_assignee_updated',
  'task_status_changed',
  'task_replied',
  'task_deleted',
  'suggestion_accepted',
  'suggestion_rejected',
  'suggestion_replied',
  'permission_added',
  'permission_removed',
  'group_permission_added',
  'group_permission_removed',
  'role_added',
  'role_removed',
  'group_role_added',
  'group_role_removed',
  'node_approved',
  'node_reopened',
  'node_permission_added',
  'node_permission_removed',
  'lock_suggestions',
  'unlock_suggestions',
  'saved_version',
  'restored_version',
  'new_document_version',
  'created',
  'downloaded',
  'rename',
  'edit_description',
  'add_due',
  'remove_due',
  'edit_due',
  'edit_warning',
  'status_changed',
  'moved',
  'copied',
  'tag_added',
  'tag_removed',
  'template_changed',
  'exported',
  'imported',
  'convert_file',
];

const pdfActions: (ActionLog['action'] | ExtraAction)[] = [
  'task_created',
  'task_assignee_updated',
  'task_status_changed',
  'task_replied',
  'task_deleted',
  'permission_added',
  'permission_removed',
  'group_permission_added',
  'group_permission_removed',
  'role_added',
  'role_removed',
  'group_role_added',
  'group_role_removed',
  'saved_version',
  'restored_version',
  'new_document_version',
  'created',
  'downloaded',
  'rename',
  'edit_description',
  'add_due',
  'remove_due',
  'edit_due',
  'edit_warning',
  'status_changed',
  'moved',
  'copied',
  'tag_added',
  'tag_removed',
  'exported',
  'annotation_created',
  'annotation_resolved',
  'annotation_replied',
  'annotation_deleted',
  'imported',
  'convert_file',
];

/**
 * create
 * delete
 * export
  move
  permissoes
  roles
  status
  tag
  task actions
  mentions
  comments actions
 */
const presentationActions: (ActionLog['action'] | ExtraAction)[] = [
  'created',
  'exported',
  'task_created',
  'task_assignee_updated',
  'task_status_changed',
  'task_replied',
  'task_deleted',
  'permission_added',
  'permission_removed',
  'group_permission_added',
  'group_permission_removed',
  'role_added',
  'role_removed',
  'group_role_added',
  'group_role_removed',
  'rename',
  'edit_description',
  'add_due',
  'remove_due',
  'edit_due',
  'edit_warning',
  'status_changed',
  'moved',
  'copied',
  'tag_added',
  'tag_removed',
  'comment_created',
  'comment_resolved',
  'comment_reply',
  'comment_deleted',
  'imported',
  'convert_file',
];

const useGetActionOptions = ({ objectType }: { objectType: ObjectType }) => {
  const intl = useIntl();

  const getTranslation = (id: string) => {
    return intl.formatMessage({ id });
  };

  const actions: { value: Exclude<ActionLog['action'], undefined> | ExtraAction; label: string }[] =
    [
      { value: 'comment_created', label: getTranslation('NEW_COMMENT') },
      { value: 'comment_resolved', label: getTranslation('RESOLVE_COMMENT') },
      { value: 'comment_reply', label: getTranslation('REPLY_TO_COMMENT') },
      { value: 'comment_deleted', label: getTranslation('DELETE_COMMENT') },
      { value: 'annotation_created', label: getTranslation('NEW_COMMENT') },
      { value: 'annotation_resolved', label: getTranslation('RESOLVE_COMMENT') },
      { value: 'annotation_replied', label: getTranslation('REPLY_TO_COMMENT') },
      { value: 'annotation_deleted', label: getTranslation('DELETE_COMMENT') },
      { value: 'task_created', label: getTranslation('NEW_TASK') },
      { value: 'task_assignee_updated', label: getTranslation('ASSIGN_TASK') },
      { value: 'task_status_changed', label: getTranslation('CHANGE_TASK_STATUS') },
      { value: 'task_replied', label: getTranslation('REPLY_TO_TASK') },
      { value: 'task_deleted', label: getTranslation('DELETE_TASK') },
      { value: 'suggestion_accepted', label: getTranslation('SPELL_CHECK_ACCEPT_SUGGESTION') },
      { value: 'suggestion_rejected', label: getTranslation('REJECT_SUGGESTION') },
      { value: 'suggestion_replied', label: getTranslation('REPLY_TO_SUGGESTION') },
      { value: 'permission_added', label: getTranslation('ADD_PERMISSION') },
      { value: 'permission_removed', label: getTranslation('global.modals.removeButton') },
      { value: 'group_permission_added', label: getTranslation('ADD_GROUP_PERMISSION') },
      { value: 'group_permission_removed', label: getTranslation('REMOVE_GROUP_PERMISSION') },
      { value: 'role_added', label: getTranslation('ADD_ROLE') },
      { value: 'role_removed', label: getTranslation('REMOVE_ROLE') },
      { value: 'group_role_added', label: getTranslation('ADD_GROUP_ROLE') },
      { value: 'group_role_removed', label: getTranslation('REMOVE_GROUP_ROLE') },
      { value: 'node_approved', label: getTranslation('editor.menu.review.approveContent') },
      { value: 'node_reopened', label: getTranslation('REOPEN_CONTENT') },
      { value: 'node_permission_added', label: getTranslation('ADD_CONTENT_PERMISSIONS') },
      { value: 'node_permission_removed', label: getTranslation('REMOVE_CONTENT_PERMISSIONS') },
      { value: 'lock_suggestions', label: getTranslation('SUGGESTIONS.LOCK_SUGGESTIONS') },
      { value: 'unlock_suggestions', label: getTranslation('SUGGESTIONS.UNLOCK_SUGGESTIONS') },
      { value: 'saved_version', label: getTranslation('SAVE_A_VERSION') },
      { value: 'restored_version', label: getTranslation('RESTORE_A_VERSION') },
      { value: 'new_document_version', label: getTranslation('UPLOAD_A_NEW_VERSION') },
      { value: 'created', label: getTranslation('CREATE_ELEMENT') },
      { value: 'downloaded', label: getTranslation('public.unlockBtn') },
      { value: 'rename', label: getTranslation('RENAME_ELEMENT') },
      { value: 'edit_description', label: getTranslation('EDIT_DESCRIPTION') },
      { value: 'add_due', label: getTranslation('NEW_DUE_DATE') },
      { value: 'remove_due', label: getTranslation('REMOVE_DUE_DATE') },
      { value: 'edit_due', label: getTranslation('EDIT_DUE_DATE') },
      { value: 'edit_warning', label: getTranslation('REMINDER_DATE') },
      { value: 'status_changed', label: getTranslation('CHANGE_ELEMENT_STATUS') },
      { value: 'moved', label: getTranslation('MOVE_ELEMENT') },
      { value: 'copied', label: getTranslation('COPY_ELEMENT') },
      { value: 'tag_added', label: getTranslation('ADD_TAG') },
      { value: 'tag_removed', label: getTranslation('REMOVE_TAG') },
      { value: 'template_changed', label: getTranslation('CHANGE_STYLING_TEMPLATE') },
      { value: 'exported', label: getTranslation('EXPORT_DOCUMENT') },
      { value: 'imported', label: getTranslation('IMPORT_ELEMENT') },
      { value: 'convert_file', label: getTranslation('CONVERT_ELEMENT') },
    ];

  switch (objectType) {
    case 'document':
      return actions.filter((action) => documentActions.includes(action.value));
    case 'dopdf':
      return actions.filter((action) => pdfActions.includes(action.value));
    case 'presentation':
      return actions.filter((action) => presentationActions.includes(action.value));
    default:
      return [];
  }
};

export default useGetActionOptions;
