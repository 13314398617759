import { useChartColor, useChartOutline, useDataLabel, useMarkerProperties } from '../hooks';
import { useBarSeries } from '.';

type Dimension = {
  data: Presentation.Data.StockChart['ser'][number] | undefined;
  properties: Partial<
    Exclude<
      NonNullable<echarts.CandlestickSeriesOption['data']>[number],
      string | number | Date | any[]
    >
  >;
  markerProperties: Partial<
    NonNullable<NonNullable<echarts.CandlestickSeriesOption['markPoint']>['data']>[number]
  >;
};

const useCandlestickSeries = ({
  shape,
  yAxisId,
}: {
  shape: Presentation.Data.ChartShape;
  yAxisId: echarts.CandlestickSeriesOption['yAxisId'];
}): (echarts.CandlestickSeriesOption | echarts.BarSeriesOption)[] => {
  const { chartColor } = useChartColor();
  const { chartOutline } = useChartOutline();
  const { markerProperties } = useMarkerProperties();
  const { dataLabel } = useDataLabel();
  const { barSeries } = useBarSeries();

  const chartTypes = shape.chartSpace.chart.plotArea.chartTypes;
  const stockChart = chartTypes.find((chart) => chart.type === 'stock');
  const barChart = chartTypes.find((chart) => chart.type === 'bar');
  const barYAxisIndex = shape.chartSpace.chart.plotArea.chartAxes.find(
    (axis) => axis.axPos === 'l',
  )?.axId;

  if (!stockChart) {
    return [];
  }

  if (stockChart.type !== 'stock') {
    return [];
  }

  if (stockChart.ser.length < 4) {
    return [];
  }

  const dimensions: {
    high: Dimension;
    open: Dimension;
    close: Dimension;
    low: Dimension;
  } = {
    close: { data: undefined, properties: {}, markerProperties: {} },
    open: { data: undefined, properties: {}, markerProperties: {} },
    low: { data: undefined, properties: {}, markerProperties: {} },
    high: { data: undefined, properties: {}, markerProperties: {} },
  };

  dimensions.high.data = stockChart.ser[1];
  dimensions.open.data = stockChart.ser[0];
  dimensions.close.data = stockChart.ser[3];
  dimensions.low.data = stockChart.ser[2];

  if (
    !dimensions.high.data ||
    !dimensions.open.data ||
    !dimensions.close.data ||
    !dimensions.low.data
  ) {
    return [];
  }

  const xAxisValues =
    stockChart.ser[0].cat?.strRef?.strCache ?? stockChart.ser[0].cat?.numRef?.numCache;

  if (!xAxisValues) {
    return [];
  }

  const hilowLines = stockChart.hilowLines;
  const hilowLineOutline = chartOutline(hilowLines?.properties?.ln);
  const downBarsProperties = stockChart.upDownBars?.downBars?.properties;
  const upBarsProperties = stockChart.upDownBars?.upBars?.properties;

  //#region Proccess markers
  const markers: NonNullable<NonNullable<echarts.CandlestickSeriesOption['markPoint']>['data']> =
    [];

  //Proccess markers' parent properties
  Object.typedKeys(dimensions).forEach((dimension) => {
    const data = dimensions[dimension].data;
    dimensions[dimension].markerProperties = { ...markerProperties({ marker: data?.marker }) };
  });

  xAxisValues.pt.forEach((xValue) => {
    Object.typedKeys(dimensions).forEach((dim) => {
      const dimension = dimensions[dim];

      const yValue = dimension.data?.val?.numRef?.numCache?.pt?.find((pt) => pt.idx === xValue.idx);

      const label = dataLabel({
        dLbls: dimension.data?.dLbls,
        val: yValue?.v,
        cat: xValue.v,
        idx: xValue.idx,
        defaultPosition: 'right',
      });

      //Inherit parent properties
      let properties: typeof dimension.markerProperties = { ...dimension.markerProperties };

      //Proccess own properties of the line
      const childProperties = dimension?.data?.dPt?.find((pt) => pt.idx === xValue.idx);
      if (childProperties) {
        properties = {
          ...properties,
          ...markerProperties({ marker: childProperties.marker }),
        };
      }

      const marker: (typeof markers)[number] = {
        name: `${xValue.v}-${dim}`,
        coord: [xValue.v, yValue?.v ?? 0],
        label,
        symbol: properties.symbol,
        symbolSize: properties.symbolSize,
        itemStyle: properties.itemStyle,
      };
      markers.push(marker);
    });
  });
  //#endregion

  const candlestickSeries: echarts.CandlestickSeriesOption[] = [
    {
      type: 'candlestick',
      yAxisId,
      silent: true,
      markPoint: {
        data: markers,
      },
      barWidth:
        stockChart.upDownBars?.gapWidth != null
          ? stockChart.upDownBars.gapWidth / (xAxisValues.ptCount / 2)
          : undefined,
      //@ts-expect-error CHARTS:LIMITATION color doesnt support picture
      data: xAxisValues.pt.map((xValue) => {
        const value: (number | string)[] = [];

        let closeValue: string | number = 0;
        let openValue: string | number = 0;

        Object.typedKeys(dimensions).forEach((dim) => {
          const dimension = dimensions[dim];
          const yValue = dimension.data?.val?.numRef?.numCache?.pt?.find(
            (pt) => pt.idx === xValue.idx,
          );

          if (dim === 'close') {
            closeValue = yValue?.v ?? 0;
          } else if (dim === 'open') {
            openValue = yValue?.v ?? 0;
          }

          value.push(yValue?.v ?? 0);
        });

        return {
          value,
          label: {
            show: true,
          },
          itemStyle: {
            color: chartColor(
              closeValue > openValue ? upBarsProperties?.fill : downBarsProperties?.fill,
            ),
            ...hilowLineOutline,
          },
        };
      }),
    },
  ];

  return [
    ...candlestickSeries,
    ...(barChart?.type === 'bar'
      ? barSeries({
          chart: barChart,
          yAxisId: `${barYAxisIndex}`,
        })
      : []),
  ];
};

export default useCandlestickSeries;
