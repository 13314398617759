import { Logger } from '_common/services';
import { Command } from '../Command';
import { JsonRange, PathUtils, SelectionFixer } from 'Editor/services/_Common/Selection';
import { ActionContext } from '../../ActionContext';
import { NodeUtils } from 'Editor/services/DataManager';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';
import ReduxInterface from 'Editor/services/ReduxInterface';
import { ELEMENTS } from 'Editor/services/consts';

export class DeleteCommand extends Command {
  private event?: KeyboardEvent | null;
  private deleteOptions: Editor.Edition.DeleteOptions;

  constructor(
    context: Editor.Edition.ICommandArgs,
    event?: KeyboardEvent | null,
    deleteOptions: Editor.Edition.DeleteOptions = {},
  ) {
    super(context);
    this.event = event;
    this.deleteOptions = deleteOptions;
  }

  protected handleDeleteAtEnd(
    ctx: Editor.Edition.ActionContext,
    elementData: Editor.Data.Node.Data,
    elementPath: Editor.Selection.Path,
  ): boolean {
    logger.info('handleDeleteAtEnd', ctx, elementData, elementPath);
    if (!this.context.DataManager || !ctx.baseData) {
      return false;
    }

    let structureModel = this.context.DataManager.structure?.structureModel;

    if (!structureModel) {
      return false;
    }

    let nextData: Editor.Data.Node.Data | null = null;
    let nextPath: Editor.Selection.Path = [];
    let baseId: string | null = null;

    if (ctx.baseData.id && elementData.id === ctx.baseData.id) {
      // get preivous base block element

      const nextModel = this.context.DataManager.nodes.getNextModelById(ctx.baseData.id);
      if (nextModel) {
        nextData = nextModel?.selectedData();
        baseId = nextData?.id || null;
      }
    } else if (elementPath.length > 0) {
      //
      const result = NodeUtils.getNextSibling(ctx.baseData, elementPath);
      if (result) {
        nextData = result.data;
        nextPath = result.path;

        baseId = ctx.baseData.id || null;
      }
    }

    if (baseId && nextData) {
      if (!NodeUtils.isBlockDeletableData(nextData)) {
        // check non deletable

        ctx.range.updateRangePositions(
          {
            b: baseId,
            p: [...nextPath, 'childNodes', 0],
          },
          {
            b: baseId,
            p: [...nextPath, 'childNodes', nextData.childNodes?.length || 1],
          },
        );
        ctx.avoidNextNonCollapsedAction = true;
        return true;
      } else if (
        !NodeUtils.isBlockEditableData(nextData) ||
        NodeUtils.isMultiBlockContainerData(nextData) ||
        NodeUtils.isFigureData(nextData) ||
        NodeUtils.isTableData(nextData)
      ) {
        if (
          ctx.baseData.id &&
          NodeUtils.isBlockTextData(elementData) &&
          NodeUtils.isEmptyData(elementData)
        ) {
          // TODO: remove block paragraph
        } else {
          // check non editable, figures, tables and multiblock containers
          ctx.range.updateRangePositions(
            {
              b: baseId,
              p: [...nextPath, 'childNodes', 0],
            },
            {
              b: baseId,
              p: [...nextPath, 'childNodes', nextData.childNodes?.length || 1],
            },
          );
          ctx.avoidNextNonCollapsedAction = true;
        }
        return true;
      } else if (NodeUtils.isBlockTextData(nextData)) {
        // check text data

        // check mergeables
        if (NodeUtils.isBlockTextData(nextData)) {
          ctx.range.updateEndPosition({
            b: baseId,
            p: [...nextPath, 'childNodes', 0],
          });
        } else {
        }
        return true;
      }
    }

    return false;
  }

  protected handleTextElement(
    ctx: Editor.Edition.ActionContext,
    elementData: Editor.Data.Node.Data,
    elementPath: Editor.Selection.Path,
  ): boolean {
    if (
      !ctx.baseModel ||
      !ctx.baseData ||
      !this.context.selection?.modifiers ||
      !this.context.DataManager
    ) {
      return false;
    }

    let subPath = ctx.range.start.p.slice(elementPath.length);

    if (NodeUtils.isPathAtContentEnd(elementData, subPath)) {
      // SELECTION AT END
      return this.handleDeleteAtEnd(ctx, elementData, elementPath);
    } else {
      // SELECTION START OR MID

      let closestTrackedDelete = NodeUtils.closestOfTypeByPath(ctx.baseData, ctx.range.start.p, [
        'tracked-delete',
      ]);

      let closestNonEditable = NodeUtils.closestSiblingAncestorOfType(
        ctx.baseData,
        ctx.range.start.p,
        [...NodeUtils.INLINE_NON_EDITABLE_TYPES, ELEMENTS.FieldElement.ELEMENT_TYPE],
      );

      // check inline elements
      if (closestTrackedDelete) {
        let subPath = ctx.range.start.p.slice(closestTrackedDelete.path.length);
        if (
          !PathUtils.isPathEqual(closestTrackedDelete.path, ctx.range.start.p) &&
          !NodeUtils.isPathAtContentEnd(closestTrackedDelete.data, subPath)
        ) {
          const offset = Number(closestTrackedDelete.path[closestTrackedDelete.path.length - 1]);
          if (isNaN(offset)) {
            return false;
          }

          let path = [...closestTrackedDelete.path];
          path[path.length - 1] = offset + 1;

          ctx.range.updateRangePositions({
            b: ctx.baseModel.id,
            p: path,
          });
          return true;
        }
      }

      if (
        closestNonEditable &&
        (!NodeUtils.isFieldData(closestNonEditable.data) ||
          NodeUtils.isFieldCaptionData(closestNonEditable.data))
      ) {
        const offset = Number(closestNonEditable.path[closestNonEditable.path.length - 1]);
        if (isNaN(offset)) {
          return false;
        }

        let startPath = [...closestNonEditable.path];
        let endPath = [...closestNonEditable.path];
        endPath[endPath.length - 1] = offset + 1;

        ctx.range.updateRangePositions(
          {
            b: ctx.baseModel.id,
            p: startPath,
          },
          {
            b: ctx.baseModel.id,
            p: endPath,
          },
        );

        ctx.avoidNextNonCollapsedAction = true;

        // trigger confirmation modal
        if (NodeUtils.isFieldCaptionData(closestNonEditable.data)) {
          ReduxInterface.openDeleteCaptionConfirmationModal();
        }
        return true;
      }

      // normalize text selection
      SelectionFixer.collapsedTextSelection(
        ctx.range,
        {
          suggestionMode: this.context.editionMode === 'SUGGESTIONS',
          forceWrapAsText: true,
          isDelete: true,
        },
        this.context.DataManager,
      );

      this.context.selection.modifiers.modify(ctx.range, 'expand', 'character', 'forward');

      return true;
    }
  }

  protected handleNonTextElement(
    ctx: Editor.Edition.ActionContext,
    elementData: Editor.Data.Node.Data,
    elementPath: Editor.Selection.Path,
  ): boolean {
    let subPath = ctx.range.start.p.slice(elementPath.length);

    if (NodeUtils.isPathAtContentEnd(elementData, subPath)) {
      return this.handleDeleteAtEnd(ctx, elementData, elementPath);
    } else {
      let startPath: Editor.Selection.Path = ['childNodes', 0];
      let endPath: Editor.Selection.Path = ['childNodes', elementData.childNodes?.length || 1];
      ctx.range.updateRangePositions(
        { b: ctx.range.start.b, p: startPath },
        { b: ctx.range.start.b, p: endPath },
      );
      ctx.avoidNextNonCollapsedAction = true;

      return true;
    }
  }

  protected handleCollapsedSelection(ctx: Editor.Edition.ActionContext): boolean {
    if (!this.context.DataManager || !this.context.contentManipulator) {
      return false;
    }

    const baseModel = this.context.DataManager.nodes.getNodeModelById(ctx.range.start.b);

    const baseData = baseModel?.selectedData();
    if (!baseModel || !baseData) {
      return false;
    }

    // check if element is editable
    if (!this.context.DataManager.nodes.isNodeEditable(baseModel.id)) {
      return false;
    }

    ctx.setModelAndData(baseModel, baseData);

    const closestTable = NodeUtils.closestOfTypeByPath(
      baseData,
      ctx.range.getCommonAncestorPath(),
      'tbl',
    );

    if (closestTable) {
      const tableElement = EditorDOMUtils.getNode(closestTable.data.id);
      if (EditorDOMElements.isTableElement(tableElement)) {
        const selectedCells = tableElement.getSelectedCellsIds();
        if (selectedCells.length > 1) {
          return this.context.contentManipulator.removeContent(ctx, {
            useSelectedCells: true,
            confirmDeleteCaption: this.deleteOptions.confirmDeleteCaption,
          });
        }
      }
    }

    const result = NodeUtils.closestOfTypeByPath(
      baseData,
      ctx.range.start.p,
      NodeUtils.BLOCK_EDITABLE_TYPES,
    );

    if (result) {
      if (NodeUtils.isBlockTextData(result.data)) {
        return this.handleTextElement(ctx, result.data, result.path);
      } else {
        return this.handleNonTextElement(ctx, result.data, result.path);
      }
    }

    return false;
  }

  protected handleNonCollapsedSelection(ctx: Editor.Edition.ActionContext): boolean {
    if (!this.context.DataManager || !this.context.contentManipulator) {
      return false;
    }

    // normalize text selection
    SelectionFixer.nonCollapsedTextSelection(
      ctx.range,
      {
        suggestionMode: this.context.editionMode === 'SUGGESTIONS',
        // forceWrapAsText: true, // WARN: avoid this prop here
        isDelete: true,
        forceTextAsWrap: true,
      },
      this.context.DataManager,
    );

    return this.context.contentManipulator.removeContent(ctx, {
      selectionDirection: 'forward',
      confirmDeleteCaption: this.deleteOptions.confirmDeleteCaption,
    });
  }

  async exec(): Promise<Editor.Edition.ICommand> {
    if (this.debug) {
      Logger.trace('DeleteCommand exec', this);
    }

    if (!this.context.DataManager || !this.context.DataManager.selection) {
      return this;
    }

    //TODO:
    // fix block selection?

    const rangeData = this.context.DataManager.selection.current;
    const jsonRange = JsonRange.buildFromRangeData(rangeData[0]);

    if (!jsonRange) {
      return this;
    }

    let ctx: Editor.Edition.ActionContext = new ActionContext(jsonRange);

    // handle collapsed selection
    if (ctx.range.collapsed) {
      if (!this.handleCollapsedSelection(ctx)) {
        return this;
      }
    }

    // handle non collapsed selection
    if (!ctx.range.collapsed && !ctx.avoidNextNonCollapsedAction) {
      if (!this.handleNonCollapsedSelection(ctx)) {
        return this;
      }
    }

    // handle create suggestions???
    this.handleSuggestionsUpdate(ctx);

    // apply new selection
    if (this.context.DataManager?.selection) {
      // TEMP: flag last selection
      this.context.DataManager.selection.history.flag('debounce');
      this.context.DataManager.selection.setUserSelection([ctx.range.serializeToRangeData()]);
    }

    // create patch
    this.context.DataManager.history.createPatch();

    return this;
  }
}
