import { FC } from 'react';
import Shape from '../Shape';
import GroupDataProvider from './GroupData';

type GroupShapeProps = {
  shape: Presentation.Data.GroupShape;
};

const GroupShape: FC<GroupShapeProps> = ({ shape }) => {
  return (
    <GroupDataProvider shape={shape}>
      {shape.shapes.map((shape) => {
        return <Shape key={shape.id} shape={shape} />;
      })}
    </GroupDataProvider>
  );
};

export default GroupShape;
