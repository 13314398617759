import GradientDefs from './GrandientDefs';

type GradientBackgroundProps = {
  background: Presentation.Data.Common.GradientFillType;
  size: Presentation.Data.Common.Size;
};

const GradientBackground = ({ background, size }: GradientBackgroundProps) => {
  const fillId = 'myGradient';
  return (
    <>
      <defs>
        <GradientDefs id={fillId} background={background} />
      </defs>
      <rect width={size.width} height={size.height} fill={`url(#${fillId})`} />
    </>
  );
};

export default GradientBackground;
