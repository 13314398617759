import { Path, path } from 'd3';
import { useMemo } from 'react';
import {
  generateStarPath,
  generateFlowchartPath,
  generateActionButtonPath,
  generateBlockArrowPath,
  generateCalloutPath,
} from './Preset';
import { shapeArc } from './Preset/utils';

export const parseGeometry = (
  geometry: Presentation.Shape.Geometry,
  size: Presentation.Data.Common.Size | null,
  position: Presentation.Data.Common.Position | null,
  properties?: Presentation.Data.ShapeProperties | undefined,
): Presentation.Data.ParsedGeometry => {
  const parseArrayAvLst = (
    avLst: {
      fmla: string;
      name: string;
    }[],
  ) => {
    return avLst.reduce<Record<string, string>>((total, current) => {
      total[current.name] = current.fmla.replace(/\D+/g, '');

      return total;
    }, {});
  };

  const avLstIndexed = Array.isArray(geometry.avLst)
    ? parseArrayAvLst(geometry.avLst)
    : geometry.avLst;
  const avLstArray = Array.isArray(geometry.avLst) ? geometry.avLst : undefined;

  //#region Custom shapes
  if (geometry?.type === 'custom' && position) {
    const paths = geometry.pathLst.map((p) => {
      const d = path();
      const smallerLimit = 0.15;
      const scaleValue = 9525;
      for (let i = 0; i < p.actions.length; i++) {
        const action = p.actions[i];
        switch (action.type) {
          case 'mt': {
            const smallerX = action.pt.x < smallerLimit;
            const smallerY = action.pt.y < smallerLimit;
            const xValue = (action.pt.x * scaleValue) / 6.75;
            const yValue = (action.pt.y * scaleValue) / 5.8;
            if (action.pt.x !== 0 && smallerX && action.pt.y !== 0 && smallerY) {
              const x = xValue + position.left;
              const y = yValue + position.top;
              d.moveTo(x, y);
            } else if (action.pt.x !== 0 && smallerX) {
              const x = xValue + position.left;
              const y = action.pt.y + position.top;
              d.moveTo(x, y);
            } else if (action.pt.y !== 0 && smallerY) {
              const x = action.pt.x + position.left;
              const y = yValue + position.top;
              d.moveTo(x, y);
            } else {
              const x = action.pt.x + position.left;
              const y = action.pt.y + position.top;
              d.moveTo(x, y);
            }
            break;
          }
          case 'lt': {
            const smallerX = action.pt.x < smallerLimit;
            const smallerY = action.pt.y < smallerLimit;
            const xValue = (action.pt.x * scaleValue) / 6.75;
            const yValue = (action.pt.y * scaleValue) / 5.8;
            if (action.pt.x !== 0 && smallerX && action.pt.y !== 0 && smallerY) {
              const x = xValue + position.left;
              const y = yValue + position.top;
              d.lineTo(x, y);
            } else if (action.pt.x !== 0 && smallerX) {
              const x = xValue + position.left;
              const y = action.pt.y + position.top;
              d.lineTo(x, y);
            } else if (action.pt.y !== 0 && smallerY) {
              const x = action.pt.x + position.left;
              const y = yValue + position.top;
              d.lineTo(x, y);
            } else {
              const x = action.pt.x + position.left;
              const y = action.pt.y + position.top;
              d.lineTo(x, y);
            }
            break;
          }
          case 'arct': {
            const { hR, wR, stAng, swAng } = action;

            const startAngleRad = (stAng * Math.PI) / 180;
            const endAngleRad = ((stAng + swAng) * Math.PI) / 180;

            // Calculate start and end points of the arc
            const startX = hR * Math.cos(startAngleRad);
            const startY = wR * Math.sin(startAngleRad);
            const endX = hR * Math.cos(endAngleRad);
            const endY = wR * Math.sin(endAngleRad);

            // Calculate the center of the arc
            const centerX = (startX + endX) / 2;
            const centerY = (startY + endY) / 2;

            //TODO: This is possibly wrong, check if stAng and swAng should be radian angles instead of degree
            d.arc(centerX, centerY, hR, stAng, stAng + swAng, stAng + swAng < 0);

            break;
          }
          case 'cbezt': {
            const [cp1, cp2, ep] = action.pts;
            const x1 = cp1.x + position.left;
            const y1 = cp1.y + position.top;
            const x2 = cp2.x + position.left;
            const y2 = cp2.y + position.top;
            const ex = ep.x + position.left;
            const ey = ep.y + position.top;
            d.bezierCurveTo(x1, y1, x2, y2, ex, ey);
            break;
          }
          case 'qbezt': {
            const [cp, ep] = action.pts;
            const x1 = cp.x + position.left;
            const y1 = cp.y + position.top;
            const x2 = ep.x + position.left;
            const y2 = ep.y + position.top;
            d.quadraticCurveTo(x1, y1, x2, y2);
            break;
          }
          case 'cls':
            d.closePath();
        }
      }
      return { d: d.toString() };
    });

    return { paths };
  }
  //#endregion

  if (geometry.type === 'prst' && position && size) {
    const type = geometry?.prst ?? 'rect';

    const x = {
      left: position.left,
      center: position.left + size.width / 2,
      right: position.left + size.width,
    };

    const y = {
      top: position.top,
      center: position.top + size.height / 2,
      bottom: position.top + size.height,
    };

    const drawArrow = (
      d: Path,
      x1: number,
      y1: number,
      x2: number,
      y2: number,
      size: number,
      endArrow: boolean | string | undefined,
      x3?: number,
      y3?: number,
      x4?: number,
      y4?: number,
    ) => {
      if (endArrow) {
        const angle = Math.atan2(y2 - y1, x2 - x1);
        d.moveTo(x2, y2);
        d.lineTo(
          x2 - size * Math.cos(angle - Math.PI / 6),
          y2 - size * Math.sin(angle - Math.PI / 6),
        );
        d.lineTo(
          x2 - size * Math.cos(angle + Math.PI / 6),
          y2 - size * Math.sin(angle + Math.PI / 6),
        );
      } else {
        if (x3 && y3 && x4 && y4) {
          const angle = Math.atan2(y2 - y1, x2 - x1);
          const angle2 = Math.atan2(y4 - y3, x4 - x3);
          d.moveTo(x2, y2);
          d.lineTo(
            x2 - size * Math.cos(angle - Math.PI / 6),
            y2 - size * Math.sin(angle - Math.PI / 6),
          );
          d.lineTo(
            x2 - size * Math.cos(angle + Math.PI / 6),
            y2 - size * Math.sin(angle + Math.PI / 6),
          );
          d.closePath();

          d.moveTo(x3, y3);
          d.lineTo(
            x3 + size * Math.cos(angle2 - Math.PI / 6),
            y3 + size * Math.sin(angle2 - Math.PI / 6),
          );
          d.lineTo(
            x3 + size * Math.cos(angle2 + Math.PI / 6),
            y3 + size * Math.sin(angle2 + Math.PI / 6),
          );
        } else {
          const angle = Math.atan2(y2 - y1, x2 - x1);
          d.moveTo(x2, y2);
          d.lineTo(
            x2 - size * Math.cos(angle - Math.PI / 6),
            y2 - size * Math.sin(angle - Math.PI / 6),
          );
          d.lineTo(
            x2 - size * Math.cos(angle + Math.PI / 6),
            y2 - size * Math.sin(angle + Math.PI / 6),
          );
          d.closePath();

          d.moveTo(x1, y1);
          d.lineTo(
            x1 + size * Math.cos(angle - Math.PI / 6),
            y1 + size * Math.sin(angle - Math.PI / 6),
          );
          d.lineTo(
            x1 + size * Math.cos(angle + Math.PI / 6),
            y1 + size * Math.sin(angle + Math.PI / 6),
          );
        }
      }
    };

    switch (type) {
      case 'ellipse': {
        return {
          paths: [
            {
              d: `M ${x.center} ${y.top} A ${size.width / 2} ${size.height / 2} 0 0 0 ${x.center} ${
                y.bottom
              } A ${size.width / 2} ${size.height / 2} 0 0 0 ${x.center} ${y.top}`,
            },
          ],
        };
      }
      case 'trapezoid': {
        let transform = '';
        const d = path();
        d.moveTo(position.left, position.top + size.height);
        d.lineTo(position.left + size.width, position.top + size.height);
        d.lineTo(position.left + size.width * 0.8, position.top);
        d.lineTo(position.left + size.width * 0.2, position.top);
        d.lineTo(position.left, position.top + size.height);

        return { paths: [{ d: d.toString(), transform }] };
      }
      case 'parallelogram': {
        const d = path();
        d.moveTo(position.left, y.bottom);
        d.lineTo(position.left + size.width * 0.6, position.top + size.height);
        d.lineTo(position.left + size.width, position.top);
        d.lineTo(position.left + size.width * 0.4, position.top);
        d.lineTo(position.left, position.top + size.height);
        return { paths: [{ d: d.toString() }] };
      }
      case 'roundRect': {
        const d = path();
        const adj =
          avLstIndexed?.adj !== undefined
            ? (Math.min(size.height, size.width) * +avLstIndexed.adj) / 100000
            : Math.min(size.height, size.width) * 0.25;
        d.moveTo(position.left + adj, position.top);
        d.lineTo(position.left + size.width - adj, position.top);
        d.arcTo(
          position.left + size.width,
          position.top,
          position.left + size.width,
          position.top + adj,
          adj,
        );
        d.lineTo(position.left + size.width, position.top + size.height - adj);
        d.arcTo(
          position.left + size.width,
          position.top + size.height,
          position.left + size.width - adj,
          position.top + size.height,
          adj,
        );
        d.lineTo(position.left + adj, position.top + size.height);
        d.arcTo(
          position.left,
          position.top + size.height,
          position.left,
          position.top + size.height - adj,
          adj,
        );
        d.lineTo(position.left, position.top + adj);
        d.arcTo(position.left, position.top, position.left + adj, position.top, adj);
        return { paths: [{ d: d.toString() }] };
      }
      case 'snip1Rect': {
        const adj =
          avLstIndexed?.adj !== undefined
            ? (Math.min(size.height, size.width) * +avLstIndexed.adj) / 100000
            : Math.min(size.height, size.width) * 0.25;
        const d = path();
        d.moveTo(x.left, y.top);
        d.lineTo(x.right - adj, y.top);
        d.lineTo(x.right, y.top + adj);
        d.lineTo(x.right, y.bottom);
        d.lineTo(x.left, y.bottom);
        d.closePath();
        return { paths: [{ d: d.toString() }] };
      }
      case 'snip2SameRect': {
        // top left and right snips
        const adj1 =
          avLstIndexed?.adj1 !== undefined
            ? (Math.min(size.height, size.width) * +avLstIndexed.adj1) / 100000
            : Math.min(size.height, size.width) * 0.25;
        // bottom right and bottom left snips
        const adj2 =
          avLstIndexed?.adj2 !== undefined
            ? (Math.min(size.height, size.width) * +avLstIndexed.adj2) / 100000
            : Math.min(size.height, size.width) * 0.25;
        const d = path();
        d.moveTo(x.left, y.top + adj1);
        d.lineTo(x.left + adj1, y.top);
        d.lineTo(x.right - adj1, y.top);
        d.lineTo(x.right, y.top + adj1);
        d.lineTo(x.right, y.bottom);
        d.lineTo(x.right - adj2, y.bottom);
        d.lineTo(x.left, y.bottom);
        d.lineTo(x.left, y.bottom - adj2);
        d.closePath();
        return { paths: [{ d: d.toString() }] };
      }
      case 'snip2DiagRect': {
        // top left and bottom right snips
        const adj1 =
          avLstIndexed?.adj1 !== undefined
            ? (Math.min(size.height, size.width) * +avLstIndexed.adj1) / 100000
            : Math.min(size.height, size.width) * 0.25;
        // top right and bottom left snips
        const adj2 =
          avLstIndexed?.adj2 !== undefined
            ? (Math.min(size.height, size.width) * +avLstIndexed.adj2) / 100000
            : Math.min(size.height, size.width) * 0.25;
        const d = path();

        // top left
        d.moveTo(x.left, y.top);
        d.lineTo(x.left + adj1, y.top);
        // top right
        d.lineTo(x.right - adj2, y.top);
        d.lineTo(x.right, y.top + adj2);
        // bottom right
        d.lineTo(x.right, y.bottom);
        d.lineTo(x.right - adj1, y.bottom);
        // bottom left
        d.lineTo(x.left + adj2, y.bottom);
        d.lineTo(x.left, y.bottom - adj2);

        d.closePath();
        return { paths: [{ d: d.toString() }] };
      }
      case 'snipRoundRect': {
        // top left round snip
        const adj1 =
          avLstIndexed?.adj1 !== undefined
            ? (Math.min(size.height, size.width) * +avLstIndexed.adj1) / 100000
            : Math.min(size.height, size.width) * 0.25;
        // top right rect snip
        const adj2 =
          avLstIndexed?.adj2 !== undefined
            ? (Math.min(size.height, size.width) * +avLstIndexed.adj2) / 100000
            : Math.min(size.height, size.width) * 0.25;
        const d = path();
        d.moveTo(x.left, y.top + adj1);
        d.arcTo(x.left, y.top, x.left + adj1, y.top, adj1);
        d.lineTo(x.right - adj2, y.top);
        d.lineTo(x.right, y.top + adj2);
        d.lineTo(x.right, y.bottom);
        d.lineTo(x.left, y.bottom);
        d.closePath();
        return { paths: [{ d: d.toString() }] };
      }
      case 'round1Rect': {
        const d = path();
        const adj =
          avLstIndexed?.adj !== undefined
            ? (Math.min(size.height, size.width) * +avLstIndexed.adj) / 100000
            : Math.min(size.height, size.width) * 0.25;
        d.moveTo(x.left, y.top);
        d.lineTo(x.right - adj, y.top);
        d.arcTo(x.right, y.top, x.right, y.top + adj, adj);
        d.lineTo(x.right, y.bottom);
        d.lineTo(x.left, y.bottom);
        d.lineTo(x.left, y.top);
        return { paths: [{ d: d.toString() }] };
      }
      case 'round2SameRect': {
        // top left and right snips
        const adj1 =
          avLstIndexed?.adj1 !== undefined
            ? (Math.min(size.height, size.width) * +avLstIndexed.adj1) / 100000
            : Math.min(size.height, size.width) * 0.25;
        // bottom right and bottom left snips
        const adj2 =
          avLstIndexed?.adj2 !== undefined
            ? (Math.min(size.height, size.width) * +avLstIndexed.adj2) / 100000
            : Math.min(size.height, size.width) * 0.25;
        const d = path();
        d.moveTo(x.left + adj1, y.top);
        d.lineTo(x.right - adj1, y.top);
        d.arcTo(x.right, y.top, x.right, y.top + adj1, adj1);
        d.lineTo(x.right, y.bottom - adj2);
        d.arcTo(x.right, y.bottom, x.right, y.bottom, adj2);
        d.lineTo(x.left + adj2, y.bottom);
        d.arcTo(x.left, y.bottom, x.left, y.bottom, adj2);
        d.lineTo(x.left, position.top + adj1);
        d.arcTo(position.left, position.top, position.left + adj1, position.top, adj1);
        return { paths: [{ d: d.toString() }] };
      }
      case 'round2DiagRect': {
        // top left and bottom right snips
        const adj1 =
          avLstIndexed?.adj1 !== undefined
            ? (Math.min(size.height, size.width) * +avLstIndexed.adj1) / 100000
            : Math.min(size.height, size.width) * 0.25;
        // top right and bottom left snips
        const adj2 =
          avLstIndexed?.adj2 !== undefined
            ? (Math.min(size.height, size.width) * +avLstIndexed.adj2) / 100000
            : Math.min(size.height, size.width) * 0.25;
        const d = path();
        d.moveTo(x.left + adj1, y.top);
        d.lineTo(x.right - adj2, y.top);
        d.arcTo(x.right, y.top, x.right, y.top, adj2);
        d.lineTo(x.right, y.bottom - adj1);
        d.arcTo(x.right, y.bottom, x.right - adj1, y.bottom, adj1);
        d.lineTo(x.left + adj2, y.bottom);
        d.arcTo(x.left, y.bottom, x.left, y.bottom, adj2);
        d.lineTo(position.left, position.top + adj1);
        d.arcTo(position.left, position.top, position.left + adj1, position.top, adj1);
        return { paths: [{ d: d.toString() }] };
      }
      case 'triangle': {
        const d = path();
        const adj =
          avLstIndexed?.adj !== undefined
            ? size.width * (+avLstIndexed.adj / 100000)
            : size.width / 2;
        d.moveTo(x.left + adj, y.top);
        d.lineTo(x.right, y.bottom);
        d.lineTo(x.left, y.bottom);
        d.closePath();

        const getRectangleVertices = (
          x1: number,
          y1: number,
          x2: number,
          y2: number,
          x3: number,
          y3: number,
        ) => {
          // Calculate centroid of the triangle
          const xg = (x1 + x2 + x3) / 3;
          const yg = (y1 + y2 + y3) / 3;

          // Calculate midpoints between each vertex and centroid
          const m1x = (x1 + xg) / 2;
          const m1y = (y1 + yg) / 2;
          const m2x = (x2 + xg) / 2;
          const m2y = (y2 + yg) / 2;
          const m3x = (x3 + xg) / 2;
          const m3y = (y3 + yg) / 2;

          // Calculate rectangle vertices
          const px = m1x + m2x - xg;
          const py = m1y + m2y - yg;
          const qx = m1x + m3x - xg;
          const qy = m1y + m3y - yg;
          const rx = m2x + m3x - xg;
          const ry = m2y + m3y - yg;
          const sx = m1x + m2x + m3x - 2 * xg;
          const sy = m1y + m2y + m3y - 2 * yg;

          // Return rectangle vertices as an array
          return [
            { x: px, y: py },
            { x: qx, y: qy },
            { x: rx, y: ry },
            { x: sx, y: sy },
          ];
        };

        const rect = getRectangleVertices(x.left, y.bottom, x.left + adj, y.top, x.right, y.bottom);
        const rectPath = path();
        rectPath.moveTo(rect[0].x, rect[0].y);
        rectPath.lineTo(rect[0].x, rect[1].y);
        rectPath.lineTo(rect[2].x, rect[1].y);
        rectPath.lineTo(rect[2].x, rect[0].y);
        rectPath.closePath();

        return {
          paths: [{ d: d.toString() }, { d: rectPath.toString(), opacity: 0 }],
          textBounds: {
            left: rect[0].x,
            top: rect[0].y,
            width: rect[2].x - rect[0].x,
            height: rect[1].y - rect[0].y,
          },
        };
      }
      case 'rtTriangle': {
        const d = path();
        d.moveTo(x.left, y.top);
        d.lineTo(x.right, y.bottom);
        d.lineTo(x.left, y.bottom);
        d.closePath();
        return { paths: [{ d: d.toString() }] };
      }
      case 'rect': {
        const d = path();
        d.rect(position.left, position.top, size.width, size.height);
        return { paths: [{ d: d.toString() }] };
      }
      case 'diamond': {
        const d = path();
        d.moveTo(x.center, y.top);
        d.lineTo(x.right, y.center);
        d.lineTo(x.center, y.bottom);
        d.lineTo(x.left, y.center);
        d.closePath();
        return { paths: [{ d: d.toString() }] };
      }
      case 'cube': {
        const adj = 25000;
        const val2 = 100000;
        const w = size.width;
        const h = size.height;
        const ss = Math.min(w, h);
        const a = Math.max(Math.min(val2, adj), 0);
        const y1 = (ss * a) / val2;
        const y4 = h - y1;
        const x4 = w - y1;

        const side = path();
        side.moveTo(x4 + x.left, y1 + y.top);
        side.lineTo(w + x.left, 0 + y.top);
        side.lineTo(w + x.left, y4 + y.top);
        side.lineTo(x4 + x.left, h + y.top);
        side.closePath();

        const d_val =
          'M' +
          x.left +
          ',' +
          (y1 + y.top) +
          ' L' +
          (y1 + x.left) +
          ',' +
          y.top +
          ' L' +
          (w + x.left) +
          ',' +
          y.top +
          ' L' +
          (w + x.left) +
          ',' +
          (y4 + y.top) +
          ' L' +
          (x4 + x.left) +
          ',' +
          (h + y.top) +
          ' L' +
          x.left +
          ',' +
          (h + y.top) +
          ' z' +
          'M' +
          x.left +
          ',' +
          (y1 + y.top) +
          ' L' +
          (x4 + x.left) +
          ',' +
          (y1 + y.top) +
          ' M' +
          (x4 + x.left) +
          ',' +
          (y1 + y.top) +
          ' L' +
          (w + x.left) +
          ',' +
          y.top +
          'M' +
          (x4 + x.left) +
          ',' +
          (y1 + y.top) +
          ' L' +
          (x4 + x.left) +
          ',' +
          (h + y.top);

        return {
          paths: [
            { d: d_val, opacity: 0.8 },
            { d: side.toString(), opacity: 1 },
          ],
        };
      }
      case 'pentagon': {
        const d = path();
        d.moveTo(x.center, y.top);
        d.lineTo(x.right, y.top + size.height * 0.4);
        d.lineTo(x.right - size.width * 0.2, y.bottom);
        d.lineTo(x.left + size.width * 0.2, y.bottom);
        d.lineTo(x.left, y.top + size.height * 0.4);
        d.closePath();
        return { paths: [{ d: d.toString() }] };
      }
      case 'hexagon': {
        const adj =
          avLstIndexed?.adj !== undefined
            ? (Math.min(size.height, size.width) * +avLstIndexed.adj) / 100000
            : Math.min(size.height, size.width) * 0.33;
        const d = path();
        d.moveTo(x.left, y.center);
        d.lineTo(x.left + adj, y.top);
        d.lineTo(x.right - adj, y.top);
        d.lineTo(x.right, y.center);
        d.lineTo(x.right - adj, y.bottom);
        d.lineTo(x.left + adj, y.bottom);
        d.closePath();
        return { paths: [{ d: d.toString() }] };
      }
      case 'heptagon': {
        const d = path();
        d.moveTo(x.center, y.top);
        d.lineTo(x.right - size.width * 0.1, y.top + size.height * 0.2);
        d.lineTo(x.right, y.top + size.height * 0.67);
        d.lineTo(x.right - size.width * 0.2, y.bottom);
        d.lineTo(x.left + size.width * 0.2, y.bottom);
        d.lineTo(x.left, y.top + size.height * 0.67);
        d.lineTo(x.left + size.width * 0.1, y.top + size.height * 0.2);
        d.closePath();
        return { paths: [{ d: d.toString() }] };
      }
      case 'octagon': {
        const adj =
          avLstIndexed?.adj !== undefined
            ? (Math.min(size.height, size.width) * +avLstIndexed.adj) / 100000
            : Math.min(size.height, size.width) * 0.33;
        const d = path();

        d.moveTo(x.left + adj, y.top);
        d.lineTo(x.right - adj, y.top);
        d.lineTo(x.right, y.top + adj);
        d.lineTo(x.right, y.bottom - adj);
        d.lineTo(x.right - adj, y.bottom);
        d.lineTo(x.left + adj, y.bottom);
        d.lineTo(x.left, y.bottom - adj);
        d.lineTo(x.left, y.top + adj);
        d.lineTo(x.left + adj, y.top);

        return { paths: [{ d: d.toString() }] };
      }

      case 'decagon': {
        const d = path();
        d.moveTo(x.left, y.center);
        d.lineTo(x.left + size.width * 0.1, y.top + size.height * 0.18);
        d.lineTo(x.left + size.width * 0.35, y.top);
        d.lineTo(x.right - size.width * 0.35, y.top);
        d.lineTo(x.right - size.width * 0.1, y.top + size.height * 0.18);
        d.lineTo(x.right, y.center);
        d.lineTo(x.right - size.width * 0.1, y.bottom - size.height * 0.18);
        d.lineTo(x.right - size.width * 0.35, y.bottom);
        d.lineTo(x.left + size.width * 0.35, y.bottom);
        d.lineTo(x.left + size.width * 0.1, y.bottom - size.height * 0.18);
        d.closePath();
        return { paths: [{ d: d.toString() }] };
      }
      case 'dodecagon': {
        const d = path();
        d.moveTo(x.left, y.top + size.height * 0.37);
        d.lineTo(x.left + size.width * 0.13, y.top + size.height * 0.13);
        d.lineTo(x.left + size.width * 0.37, y.top);
        d.lineTo(x.right - size.width * 0.37, y.top);
        d.lineTo(x.right - size.width * 0.13, y.top + size.height * 0.13);
        d.lineTo(x.right, y.top + size.height * 0.37);
        d.lineTo(x.right, y.bottom - size.height * 0.37);
        d.lineTo(x.right - size.width * 0.13, y.bottom - size.height * 0.13);
        d.lineTo(x.right - size.width * 0.37, y.bottom);
        d.lineTo(x.left + size.width * 0.37, y.bottom);
        d.lineTo(x.left + size.width * 0.13, y.bottom - size.height * 0.13);
        d.lineTo(x.left, y.bottom - size.height * 0.37);
        d.closePath();
        return { paths: [{ d: d.toString() }] };
      }
      case 'pie': {
        const adj1 = avLstIndexed?.adj1 !== undefined ? +avLstIndexed.adj1 / 60000 : 0;
        const adj2 = avLstIndexed?.adj2 !== undefined ? +avLstIndexed.adj2 / 60000 : 270;

        // starting position
        const { x: x1, y: y1 } = getPointOnEllipse(
          x.center,
          y.center,
          size.width,
          size.height,
          adj1,
        );
        const { x: x2, y: y2 } = getPointOnEllipse(
          x.center,
          y.center,
          size.width,
          size.height,
          adj2,
        );
        // radii of the ellipse
        const rx = size.width / 2;
        const ry = size.height / 2;

        const long = adj2 - adj1 > 180 ? 1 : 0;

        const sx = x.center;
        const sy = y.center;

        // "M(cx-rx),cyArx,ry 0 1,0 (cx+rx),cyArx,ry 0 1,0 (cx-rx),cyZ"
        return {
          paths: [
            {
              d: `M${sx},${sy}L${x1},${y1}A${rx},${ry} 0 ${long},1 ${x2},${y2}z`,
            },
          ],
        };
      }
      case 'chord': {
        const adj1 = 45;
        const adj2 = 270;

        const hR = size.height / 2;
        const wR = size.width / 2;
        const d_val = shapeArc(wR, hR, wR, hR, adj1, adj2, true, position);
        return { paths: [{ d: d_val }] };
      }
      case 'teardrop': {
        // Extract common code with pie and chord shape
        const adj = avLstIndexed?.adj !== undefined ? +avLstIndexed.adj / 1000 : 100;

        const adj1 = 0;
        const adj2 = 270;

        const { x: tearX, y: tearY } = getPointFromPercentage(size.width / 2, size.height / 2, adj);

        // starting position
        const { x: x1, y: y1 } = getPointOnEllipse(
          x.center,
          y.center,
          size.width,
          size.height,
          adj1,
        );
        const { x: x2, y: y2 } = getPointOnEllipse(
          x.center,
          y.center,
          size.width,
          size.height,
          adj2,
        );
        // radii of the ellipse
        const rx = size.width / 2;
        const ry = size.height / 2;

        const long = adj2 - adj1 > 180 ? 1 : 0;

        const sx = x.center + tearX;
        const sy = y.center - tearY;

        // "M(cx-rx),cyArx,ry 0 1,0 (cx+rx),cyArx,ry 0 1,0 (cx-rx),cyZ"

        const cp1 = adj > 100 ? { x: x.right, y: y.top } : { x: x.right, y: sy };
        const cp2 = adj > 100 ? { x: x.right, y: y.top } : { x: sx, y: y.top };

        const arc1 = path();
        arc1.moveTo(sx, sy);
        arc1.quadraticCurveTo(cp1.x, cp1.y, x1, y1);
        const arc2 = path();
        arc2.moveTo(x2, y2);
        arc2.quadraticCurveTo(cp2.x, cp2.y, sx, sy);

        return {
          paths: [
            { d: `${arc1.toString()}A${rx},${ry} 1 ${long},1 ${x2},${y2}${arc2.toString()}` },
          ],
        };
      }
      case 'frame': {
        const adj1 = 12500;
        const val1 = 50000;
        const val2 = 100000;
        const a1 = Math.max(Math.min(val1, adj1), 0);
        const w = size.width;
        const h = size.height;
        const x1 = (Math.min(w, h) * a1) / val2;
        const x4 = w - x1;
        const y4 = h - x1;
        const d =
          'M' +
          x.left +
          ',' +
          y.top +
          ' L' +
          (w + x.left) +
          ',' +
          y.top +
          ' L' +
          (w + x.left) +
          ',' +
          (h + y.top) +
          ' L' +
          x.left +
          ',' +
          (h + y.top) +
          ' z' +
          'M' +
          (x1 + x.left) +
          ',' +
          (x1 + y.top) +
          ' L' +
          (x1 + x.left) +
          ',' +
          (y4 + y.top) +
          ' L' +
          (x4 + x.left) +
          ',' +
          (y4 + y.top) +
          ' L' +
          (x4 + x.left) +
          ',' +
          (x1 + y.top) +
          ' z';

        return { paths: [{ d: d.toString() }] };
      }
      case 'halfFrame': {
        let sAdj1_val = 33333;
        let sAdj2_val = 33333;
        let cnsVal = 100000;
        const w = size.width;
        const h = size.height;
        let minWH = Math.min(w, h);
        let maxAdj2 = (cnsVal * w) / minWH;
        let a1, a2;
        if (sAdj2_val < 0) a2 = 0;
        else if (sAdj2_val > maxAdj2) a2 = maxAdj2;
        else a2 = sAdj2_val;
        let x1 = (minWH * a2) / cnsVal;
        let g1 = (h * x1) / w;
        let g2 = h - g1;
        let maxAdj1 = (cnsVal * g2) / minWH;
        if (sAdj1_val < 0) a1 = 0;
        else if (sAdj1_val > maxAdj1) a1 = maxAdj1;
        else a1 = sAdj1_val;
        let y1 = (minWH * a1) / cnsVal;
        let dx2 = (y1 * w) / h;
        let x2 = w - dx2;
        let dy2 = (x1 * h) / w;
        let y2 = h - dy2;
        let d =
          'M' +
          x.left +
          ',' +
          y.top +
          ' L' +
          (w + x.left) +
          ',' +
          y.top +
          ' L' +
          (x2 + x.left) +
          ',' +
          (y1 + y.top) +
          ' L' +
          (x1 + x.left) +
          ',' +
          (y1 + y.top) +
          ' L' +
          (x1 + x.left) +
          ',' +
          (y2 + y.top) +
          ' L' +
          x.left +
          ',' +
          (h + y.top) +
          ' z';

        return { paths: [{ d }] };
      }
      case 'corner': {
        const sAdj1_val = 50000;
        const sAdj2_val = 50000;
        const cnsVal = 100000;
        const w = size.width;
        const h = size.height;
        const minWH = Math.min(w, h);
        const maxAdj1 = (cnsVal * h) / minWH;
        const maxAdj2 = (cnsVal * w) / minWH;
        const a1 = Math.max(Math.min(maxAdj1, sAdj1_val), 0);
        const a2 = Math.max(Math.min(maxAdj2, sAdj2_val), 0);
        const x1 = (minWH * a2) / cnsVal;
        const dy1 = (minWH * a1) / cnsVal;
        const y1 = h - dy1;

        const d =
          'M' +
          x.left +
          ',' +
          y.top +
          ' L' +
          (x1 + x.left) +
          ',' +
          y.top +
          ' L' +
          (x1 + x.left) +
          ',' +
          (y1 + y.top) +
          ' L' +
          (w + x.left) +
          ',' +
          (y1 + y.top) +
          ' L' +
          (w + x.left) +
          ',' +
          y.bottom +
          ' L' +
          x.left +
          ',' +
          y.bottom +
          ' z';

        return { paths: [{ d }] };
      }
      case 'line': {
        const d = path();
        const flipV = properties?.xfrm?.flipV;
        if (flipV) {
          d.moveTo(x.right, y.top);
          d.lineTo(x.left, y.bottom);
        } else {
          d.moveTo(x.left, y.top);
          d.lineTo(position.left + size.width, position.top + size.height);
        }
        d.closePath();
        return { paths: [{ d: d.toString() }] };
      }
      case 'straightConnector1': {
        const d = path();
        const flipV = properties?.xfrm?.flipV;
        const flipH = properties?.xfrm?.flipH;

        if (flipV || flipH) {
          d.moveTo(x.right, y.top);
          d.lineTo(x.left, y.bottom);
        } else {
          d.moveTo(x.left, y.top);
          d.lineTo(x.right, y.bottom);
        }

        let x1Value, y1Value, x2Value, y2Value, arrowSize;
        x1Value = flipH ? x.right : x.left;
        y1Value = flipV ? y.bottom : y.top;
        x2Value = flipH ? x.left : x.right;
        y2Value = flipV ? y.top : y.bottom;
        arrowSize = 8;
        drawArrow(
          d,
          x1Value,
          y1Value,
          x2Value,
          y2Value,
          arrowSize,
          properties?.ln?.tailEnd?.type && !properties.ln.headEnd?.type,
        );

        d.closePath();
        return { paths: [{ d: d.toString() }] };
      }
      case 'bentConnector3': {
        let transform = '';
        const d = path();

        if (properties?.xfrm?.flipH) {
          d.moveTo(x.left, y.bottom);
          d.lineTo(x.left + size.width / 2, y.bottom);
          d.moveTo(x.left + size.width / 2, y.bottom);
          d.lineTo(x.left + size.width / 2, y.top);
          d.moveTo(x.left + size.width / 2, y.top);
          d.lineTo(x.right, y.top);
        } else {
          d.moveTo(x.left, y.top);
          d.lineTo(x.left + size.width / 2, y.top);
          d.moveTo(x.left + size.width / 2, y.top);
          d.lineTo(x.left + size.width / 2, position.top + size.height);
          d.moveTo(x.left + size.width / 2, position.top + size.height);
          d.lineTo(x.right, y.bottom);
        }

        let x1Value, y1Value, x2Value, y2Value, arrowSize, x3Value, y3Value, x4Value, y4Value;
        x1Value = properties?.xfrm?.rot ? x.right : x.left + size.width / 2;
        y1Value = properties?.xfrm?.flipH ? y.bottom : position.top + size.height;
        x2Value = properties?.xfrm?.rot ? x.left : x.right;
        y2Value = properties?.xfrm?.flipH ? y.bottom : y.bottom;
        arrowSize = 8;
        x3Value = properties?.xfrm?.rot ? x.left + size.width : x.left;
        y3Value = properties?.xfrm?.flipH ? y.top : y.top;
        x4Value = properties?.xfrm?.rot ? x.left : x.left + size.width / 2;
        y4Value = properties?.xfrm?.flipH ? y.top : y.top;

        if (properties?.ln?.tailEnd?.type && !properties.ln.headEnd?.type) {
          drawArrow(
            d,
            x1Value,
            y1Value,
            x2Value,
            y2Value,
            arrowSize,
            properties?.ln?.tailEnd?.type && !properties.ln.headEnd?.type,
          );
        } else if (properties?.ln?.tailEnd?.type && properties.ln.headEnd?.type) {
          drawArrow(
            d,
            x1Value,
            y1Value,
            x2Value,
            y2Value,
            arrowSize,
            properties?.ln?.tailEnd?.type && !properties.ln.headEnd?.type,
            x3Value,
            y3Value,
            x4Value,
            y4Value,
          );
        }

        if (properties?.xfrm?.rot) {
          transform = `rotate(${
            properties?.xfrm?.flipH ? properties.xfrm.rot : -properties.xfrm.rot
          } ${x.left + size.width / 2}, ${y.top + size.height / 2})`;
        }

        d.closePath();
        return { paths: [{ d: d.toString(), transform }] };
      }
      case 'curvedConnector3': {
        let transform = '';
        const d = path();
        const handleYValue = () => {
          if (size.width < 30) {
            return 8;
          } else if (size.width > 55 && size.width < 150) {
            return 50;
          } else if (size.width > 150 && size.width < 225) {
            return 70;
          } else if (size.width > 225) {
            return 100;
          } else {
            return 15;
          }
        };

        if (properties?.xfrm?.flipV || properties?.xfrm?.flipH) {
          d.moveTo(x.right, y.top);
          d.bezierCurveTo(x.right, y.top, x.center, y.top, x.center, y.center);
          d.bezierCurveTo(
            x.center,
            y.center,
            x.center,
            y.center + handleYValue(),
            x.left,
            y.bottom,
          );
          d.moveTo(x.left, position.top + size.height);
        } else {
          d.moveTo(x.left, y.top);
          d.bezierCurveTo(x.left, y.top, x.center, y.top, x.center, y.center);
          d.bezierCurveTo(
            x.center,
            y.center,
            x.center,
            y.center + handleYValue(),
            x.right,
            y.bottom,
          );
          d.moveTo(x.left + size.width / 2, position.top + size.height);
        }

        let x1Value, y1Value, x2Value, y2Value, arrowSize, x3Value, y3Value, x4Value, y4Value;
        x1Value = x.center;
        y1Value = properties?.xfrm?.flipV ? y.top : y.center + handleYValue();
        x2Value = properties?.xfrm?.flipH ? x.left : x.right;
        y2Value = properties?.xfrm?.flipV ? y.top : y.bottom;
        arrowSize = 8;
        x3Value = properties?.xfrm?.flipH ? x.right : x.left;
        y3Value = properties?.xfrm?.flipV ? y.bottom : y.top;
        x4Value = x.left;
        y4Value = properties?.xfrm?.flipV ? y.bottom : y.top;

        if (properties?.ln?.tailEnd?.type && !properties.ln.headEnd?.type) {
          drawArrow(
            d,
            x1Value,
            y1Value,
            x2Value,
            y2Value,
            arrowSize,
            properties?.ln?.tailEnd?.type && !properties.ln.headEnd?.type,
          );
        } else if (properties?.ln?.tailEnd?.type && properties.ln.headEnd?.type) {
          drawArrow(
            d,
            x1Value,
            y1Value,
            x2Value,
            y2Value,
            arrowSize,
            properties?.ln?.tailEnd?.type && !properties.ln.headEnd?.type,
            x3Value,
            y3Value,
            x4Value,
            y4Value,
          );
        }

        if (properties?.xfrm?.rot) {
          transform = `rotate(${properties.xfrm.rot} ${x.left + size.width / 2}, ${
            y.top + size.height / 2
          })`;
        }

        d.closePath();
        return { paths: [{ d: d.toString(), transform }] };
      }
      //Star shape
      case 'star4':
      case 'star5':
      case 'star6':
      case 'star7':
      case 'star8':
      case 'star10':
      case 'star12':
      case 'star16':
      case 'star24':
      case 'star32':
      case 'irregularSeal1':
      case 'irregularSeal2':
      case 'ribbon2':
      case 'ribbon':
      case 'doubleWave':
      case 'wave':
      case 'horizontalScroll':
      case 'verticalScroll':
      case 'ellipseRibbon':
      case 'ellipseRibbon2':
        return generateStarPath({ size, strAdjst: avLstIndexed, type });
      //Flowchart shape
      case 'flowChartProcess':
      case 'flowChartAlternateProcess':
      case 'flowChartDecision':
      case 'flowChartInputOutput':
      case 'flowChartPredefinedProcess':
      case 'flowChartInternalStorage':
      case 'flowChartDocument':
      case 'flowChartMultidocument':
      case 'flowChartTerminator':
      case 'flowChartPreparation':
      case 'flowChartManualInput':
      case 'flowChartManualOperation':
      case 'flowChartConnector':
      case 'flowChartOffpageConnector':
      case 'flowChartPunchedCard':
      case 'flowChartPunchedTape':
      case 'flowChartSummingJunction':
      case 'flowChartOr':
      case 'flowChartCollate':
      case 'flowChartSort':
      case 'flowChartExtract':
      case 'flowChartMerge':
      case 'flowChartOnlineStorage':
      case 'flowChartDelay':
      case 'flowChartMagneticTape':
      case 'flowChartMagneticDisk':
      case 'flowChartMagneticDrum':
      case 'flowChartDisplay':
        return generateFlowchartPath({ size, type });
      //BlockArrow shape
      case 'rightArrow':
      case 'leftArrow':
      case 'upArrow':
      case 'downArrow':
      case 'leftRightArrow':
      case 'upDownArrow':
      case 'quadArrow':
      case 'leftRightUpArrow':
      case 'bentArrow':
      case 'uturnArrow':
      case 'leftUpArrow':
      case 'bentUpArrow':
      case 'curvedRightArrow':
      case 'curvedLeftArrow':
      case 'curvedUpArrow':
      case 'curvedDownArrow':
      case 'stripedRightArrow':
      case 'notchedRightArrow':
      case 'homePlate':
      case 'chevron':
      case 'rightArrowCallout':
      case 'downArrowCallout':
      case 'leftArrowCallout':
      case 'upArrowCallout':
      case 'leftRightArrowCallout':
      case 'quadArrowCallout':
      case 'circularArrow':
        return generateBlockArrowPath({ size, type, adjst: avLstIndexed });
      case 'wedgeRectCallout':
      case 'wedgeRoundRectCallout':
      case 'wedgeEllipseCallout':
      case 'cloudCallout':
      case 'borderCallout1':
      case 'borderCallout2':
      case 'borderCallout3':
      case 'accentCallout1':
      case 'accentCallout2':
      case 'accentCallout3':
      case 'callout1':
      case 'callout2':
      case 'callout3':
      case 'accentBorderCallout1':
      case 'accentBorderCallout2':
      case 'accentBorderCallout3':
        return generateCalloutPath({ size, type, adjst: avLstIndexed });
      case 'mathPlus': {
        const strAdjst = avLstArray?.[0]?.fmla.replace(/\D+/g, '');
        const d = path();
        const maxAdjst = 73490;
        const defaultAdjst = 23520;
        const adjst = +(strAdjst ?? defaultAdjst);
        const w = size.width;
        const h = size.height;
        const hc = w / 2 + x.left; //Horizontal center
        const vc = h / 2 + y.top; //Vertical center
        const cnsVal3 = 200000;
        const cnsVal6 = 73490;

        const ss = Math.min(w, h);
        let a1, dx1, dy1, dx2, x1, x2, x3, x4, y1, y2, y3, y4;
        a1 = Math.max(Math.min(maxAdjst, adjst), 0);
        dx1 = (w * cnsVal6) / cnsVal3;
        dy1 = (h * cnsVal6) / cnsVal3;
        dx2 = (ss * a1) / cnsVal3;
        x1 = hc - dx1;
        x2 = hc - dx2;
        x3 = hc + dx2;
        x4 = hc + dx1;
        y1 = vc - dy1;
        y2 = vc - dx2;
        y3 = vc + dx2;
        y4 = vc + dy1;

        d.moveTo(x1, y2);
        d.lineTo(x2, y2);
        d.lineTo(x2, y1);
        d.lineTo(x3, y1);
        d.lineTo(x3, y2);
        d.lineTo(x4, y2);
        d.lineTo(x4, y3);
        d.lineTo(x3, y3);
        d.lineTo(x3, y4);
        d.lineTo(x2, y4);
        d.lineTo(x2, y3);
        d.lineTo(x1, y3);

        d.closePath();
        return { paths: [{ d: d.toString() }] };
      }
      case 'mathMinus': {
        const d = path();
        const strAdjst = avLstArray?.[0]?.fmla.replace(/\D+/g, '');
        const maxAdjst = 100000;
        const defaultAdjst = 23520;
        const adjst = +(strAdjst ?? defaultAdjst);
        const w = size.width;
        const h = size.height;
        const hc = w / 2 + x.left; //Horizontal center
        const vc = h / 2 + y.top; //Vertical center
        const cnsVal3 = 200000;
        const cnsVal6 = 73490;
        let a1, dx1, dy1, x1, x2, y1, y2;
        a1 = Math.max(Math.min(maxAdjst, adjst), 0);
        dx1 = (w * cnsVal6) / cnsVal3;
        dy1 = (h * a1) / cnsVal3;
        x1 = hc - dx1;
        x2 = hc + dx1;
        y1 = vc - dy1;
        y2 = vc + dy1;

        d.moveTo(x1, y1);
        d.lineTo(x2, y1);
        d.lineTo(x2, y2);
        d.lineTo(x1, y2);

        d.closePath();
        return { paths: [{ d: d.toString() }] };
      }
      case 'mathMultiply': {
        const strAdjst = avLstArray?.[0]?.fmla.replace(/\D+/g, '');
        const w = size.width;
        const h = size.height;
        const hc = w / 2; //Horizontal center
        const vc = h / 2; //Vertical center
        const d = path();
        const r = size.width;
        const b = size.height;

        const maxAdjst = 51965;
        const defaultAdjst = 23520;
        const adjst = +(strAdjst ?? defaultAdjst);

        const ss = Math.min(w, h);
        const a1 = Math.max(Math.min(maxAdjst, adjst), 0);
        const th = (ss * a1) / 100000;
        const a = Math.atan(h / w);

        const sa = 1 * Math.sin(a);
        const ca = 1 * Math.cos(a);
        const ta = 1 * Math.tan(a);
        const dl = Math.sqrt(w * w + h * h);
        const rw = (dl * maxAdjst) / 100000;
        const lM = dl - rw;
        const xM = (ca * lM) / 2;
        const yM = (sa * lM) / 2;
        const dxAM = (sa * th) / 2;
        const dyAM = (ca * th) / 2;
        const xA = xM - dxAM;
        const yA = yM + dyAM;
        const xB = xM + dxAM;
        const yB = yM - dyAM;
        const xBC = hc - xB;
        const yBC = xBC * ta;
        const yC = yBC + yB;
        const xD = r - xB;
        const xE = r - xA;
        const yFE = vc - yA;
        const xFE = yFE / ta;
        const xF = xE - xFE;
        const xL = xA + xFE;
        const yG = b - yA;
        const yH = b - yB;
        const yI = b - yC;

        d.moveTo(xA + x.left, yA + y.top);
        d.lineTo(xB + x.left, yB + y.top);
        d.lineTo(hc + x.left, yC + y.top);
        d.lineTo(xD + x.left, yB + y.top);
        d.lineTo(xE + x.left, yA + y.top);
        d.lineTo(xF + x.left, vc + y.top);
        d.lineTo(xE + x.left, yG + y.top);
        d.lineTo(xD + x.left, yH + y.top);
        d.lineTo(hc + x.left, yI + y.top);
        d.lineTo(xB + x.left, yH + y.top);
        d.lineTo(xA + x.left, yG + y.top);
        d.lineTo(xL + x.left, vc + y.top);

        d.closePath();
        return { paths: [{ d: d.toString() }] };
      }
      case 'mathEqual': {
        const d = path();
        const strAdjst = avLstArray?.[0]?.fmla.replace(/\D+/g, '');
        const strAdjst2 = avLstArray?.[1]?.fmla.replace(/\D+/g, '');
        const w = size.width;
        const h = size.height;
        const hc = w / 2; //Horizontal center
        const vc = h / 2; //Vertical center
        const maxAdjst = 51965;
        const defaultAdjst = 23520;
        const adjst = +(strAdjst ?? defaultAdjst);
        const defaultAdjst2 = 11760;
        const adjst2 = +(strAdjst2 ?? defaultAdjst2);

        const a1 = Math.max(Math.min(maxAdjst, adjst), 0);
        const a21 = a1 * 2;
        const mAdj2 = 100000 - a21;
        const a2 = Math.max(Math.min(mAdj2, adjst2), 0);
        const dy1 = (h * a1) / 100000;
        const dy2 = (h * a2) / 200000;
        const dx1 = (w * 73490) / 200000;
        const y2 = vc - dy2;
        const y3 = vc + dy2;
        const y1 = y2 - dy1;
        const y4 = y3 + dy1;

        const x1 = hc - dx1;
        const x2 = hc + dx1;

        d.moveTo(x1 + x.left, y1 + y.top);
        d.lineTo(x2 + x.left, y1 + y.top);
        d.lineTo(x2 + x.left, y2 + y.top);
        d.lineTo(x1 + x.left, y2 + y.top);
        d.closePath();
        d.moveTo(x1 + x.left, y3 + y.top);
        d.lineTo(x2 + x.left, y3 + y.top);
        d.lineTo(x2 + x.left, y4 + y.top);
        d.lineTo(x1 + x.left, y4 + y.top);

        d.closePath();
        return { paths: [{ d: d.toString() }] };
      }
      case 'mathDivide': {
        const w = size.width;
        const h = size.height;
        const hc = w / 2; //Horizontal center
        const vc = h / 2; //Vertical center
        const strAdjst = avLstArray?.[0]?.fmla.replace(/\D+/g, '');
        const strAdjst2 = avLstArray?.[1]?.fmla.replace(/\D+/g, '');
        const strAdjst3 = avLstArray?.[2]?.fmla.replace(/\D+/g, '');
        const maxAdjst = 36745;
        const defaultAdjst = 23520;
        const adjst = +(strAdjst ?? defaultAdjst);
        const defaultAdjst2 = 5880;
        const adjst2 = +(strAdjst2 ?? defaultAdjst2);
        const defaultAdjst3 = 11760;
        const adjst3 = +(strAdjst3 ?? defaultAdjst3);
        const cnstVal2 = 100000;
        const cnstVal3 = 200000;
        const cnstVal5 = 36745;
        const cnstVal6 = 73490;

        const a1 = Math.max(Math.min(maxAdjst, adjst), 1000);
        const ma1 = -a1;
        const ma3h = cnstVal6 + ma1 / 4;
        const ma3w = (cnstVal5 * w) / h;
        const maxAdj3 = ma3h < ma3w ? ma3h : ma3w;
        const a3 = Math.max(Math.min(maxAdj3, adjst3), 1000);
        const m4a3 = -4 * a3;
        const maxAdj2 = cnstVal6 + m4a3 - a1;
        const a2 = Math.max(Math.min(maxAdj2, adjst2), 0);
        const dy1 = (h * a1) / cnstVal3;
        const yg = (h * a2) / cnstVal2;
        const rad = (h * a3) / cnstVal2;
        const dx1 = (w * cnstVal6) / cnstVal3;

        const y3 = vc - dy1;
        const y4 = vc + dy1;
        const a = yg + rad;
        const y2 = y3 - a;
        const y1 = y2 - rad;
        const y5 = h - y1;
        const x1 = hc - dx1;
        const x3 = hc + dx1;
        const cd4 = 90;
        const c3d4 = 270;
        const cX1 = hc - Math.cos((c3d4 * Math.PI) / 180) * rad;
        const cY1 = y1 - Math.sin((c3d4 * Math.PI) / 180) * rad;
        const cX2 = hc - Math.cos(Math.PI / 2) * rad;
        const cY2 = y5 - Math.sin(Math.PI / 2) * rad;

        const d =
          'M' +
          (hc + x.left) +
          ',' +
          (y1 + y.top) +
          shapeArc(cX1, cY1, rad, rad, c3d4, c3d4 + 360, false, position)?.replace('M', 'L') +
          ' z' +
          ' M' +
          (hc + x.left) +
          ',' +
          (y5 + y.top) +
          shapeArc(cX2, cY2, rad, rad, cd4, cd4 + 360, false, position)?.replace('M', 'L') +
          ' z' +
          ' M' +
          (x1 + x.left) +
          ',' +
          (y3 + y.top) +
          ' L' +
          (x3 + x.left) +
          ',' +
          (y3 + y.top) +
          ' L' +
          (x3 + x.left) +
          ',' +
          (y4 + y.top) +
          ' L' +
          (x1 + x.left) +
          ',' +
          (y4 + y.top) +
          ' z';

        return { paths: [{ d }] };
      }
      case 'mathNotEqual': {
        const d = path();
        const w = size.width;
        const h = size.height;
        const hc = w / 2; //Horizontal center
        const vc = h / 2; //Vertical center
        const strAdjst = avLstArray?.[0]?.fmla.replace(/\D+/g, '');
        const strAdjst2 = avLstArray?.[1]?.fmla.replace(/\D+/g, '');
        const strAdjst3 = avLstArray?.[2]?.fmla.replace(/\D+/g, '');
        const maxAdjst = 50000;
        const defaultAdjst = 23520;
        const adjst = +(strAdjst ?? defaultAdjst);
        const defaultAdjst2 = 6600000;
        const adjst2 = +(strAdjst2 ?? defaultAdjst2);
        const defaultAdjst3 = 11760;
        const adjst3 = +(strAdjst3 ?? defaultAdjst3);
        const hd2 = h / 2; //Height / 2

        const a1 = Math.max(Math.min(maxAdjst, adjst), 0);
        let angVal1 = (70 * Math.PI) / 180,
          angVal2 = (110 * Math.PI) / 180;
        const crAng = Math.max(Math.min(angVal2, adjst2), angVal1);
        const a21 = a1 * 2;
        const maxAdj3 = 100000 - a21;
        const a3 = Math.max(Math.min(maxAdj3, adjst3), 0);

        const dy1 = (h * a1) / 100000;
        const dy2 = (h * a3) / 200000;
        const dx1 = (w * 73490) / 200000;

        const x1 = hc - dx1;
        const x8 = hc + dx1;
        const y2 = vc - dy2;
        const y3 = vc + dy2;
        const y1 = y2 - dy1;
        const y4 = y3 + dy1;

        const cadj2 = crAng - Math.PI / 2;
        const xadj2 = hd2 * Math.tan(cadj2);

        const len = Math.sqrt(xadj2 * xadj2 + hd2 * hd2);
        const bhw = (len * dy1) / hd2;
        const bhw2 = bhw / 2;
        const x7 = hc + xadj2 - bhw2;
        const dx67 = (xadj2 * y1) / hd2;
        const x6 = x7 - dx67;
        const dx57 = (xadj2 * y2) / hd2;
        const x5 = x7 - dx57;
        const dx47 = (xadj2 * y3) / hd2;
        const x4 = x7 - dx47;
        const dx37 = (xadj2 * y4) / hd2;
        const x3 = x7 - dx37;
        const rx7 = x7 + bhw;
        const rx6 = x6 + bhw;
        const rx5 = x5 + bhw;
        const rx4 = x4 + bhw;
        const rx3 = x3 + bhw;

        const dx7 = (dy1 * hd2) / len;
        const rxt = x7 + dx7;
        const lxt = rx7 - dx7;
        const rx = cadj2 > 0 ? rxt : rx7;
        const lx = cadj2 > 0 ? x7 : lxt;

        const dy3 = (dy1 * xadj2) / len;
        const dy4 = -dy3;
        const ry = cadj2 > 0 ? dy3 : 0;
        const ly = cadj2 > 0 ? 0 : dy4;

        const dlx = w - rx;
        const drx = w - lx;
        const dly = h - ry;
        const dry = h - ly;

        d.moveTo(x1 + x.left, y1 + y.top);
        d.lineTo(x6 + x.left, y1 + y.top);
        d.lineTo(lx + x.left, ly + y.top);
        d.lineTo(rx + x.left, ry + y.top);
        d.lineTo(rx6 + x.left, y1 + y.top);
        d.lineTo(x8 + x.left, y1 + y.top);
        d.lineTo(x8 + x.left, y2 + y.top);
        d.lineTo(rx5 + x.left, y2 + y.top);
        d.lineTo(rx4 + x.left, y3 + y.top);
        d.lineTo(x8 + x.left, y3 + y.top);
        d.lineTo(x8 + x.left, y4 + y.top);
        d.lineTo(rx3 + x.left, y4 + y.top);
        d.lineTo(drx + x.left, dry + y.top);
        d.lineTo(dlx + x.left, dly + y.top);
        d.lineTo(x3 + x.left, y4 + y.top);
        d.lineTo(x1 + x.left, y4 + y.top);
        d.lineTo(x1 + x.left, y3 + y.top);
        d.lineTo(x4 + x.left, y3 + y.top);
        d.lineTo(x5 + x.left, y2 + y.top);
        d.lineTo(x1 + x.left, y2 + y.top);

        d.closePath();
        return { paths: [{ d: d.toString() }] };
      }
      case 'plaque': {
        const adj = 16667;
        const a = Math.max(Math.min(50000, adj), 0);
        const w = size.width;
        const h = size.height;
        const ss = Math.min(w, h);
        const x1 = (ss * a) / 100000;
        const x2 = x.right - x1;
        const y2 = y.bottom - x1;

        const d_val =
          'M' +
          x.left +
          ',' +
          (x1 + y.top) +
          shapeArc(0, 0, x1, x1, 90, 0, false, position).replace('M', 'L') +
          ' L' +
          x2 +
          ',' +
          y.top +
          shapeArc(w, 0, x1, x1, 180, 90, false, position).replace('M', 'L') +
          ' L' +
          x.right +
          ',' +
          y2 +
          shapeArc(w, h, x1, x1, 270, 180, false, position).replace('M', 'L') +
          ' L' +
          (x1 + x.left) +
          ',' +
          y.bottom +
          shapeArc(0, h, x1, x1, 0, -90, false, position).replace('M', 'L') +
          'z';

        return { paths: [{ d: d_val }] };
      }
      case 'donut': {
        const adj = 25000;
        const val1 = 50000;
        const val2 = 100000;

        const a = Math.max(Math.min(val1, adj), 0);
        const w = size.width;
        const h = size.height;
        const dr = (Math.min(w, h) * a) / val2;
        const iwd2 = w / 2 - dr;
        const ihd2 = h / 2 - dr;

        var d =
          'M' +
          x.left +
          ',' +
          (h / 2 + y.top) +
          shapeArc(w / 2, h / 2, w / 2, h / 2, 180, 270, false, position).replace('M', 'L') +
          shapeArc(w / 2, h / 2, w / 2, h / 2, 270, 360, false, position).replace('M', 'L') +
          shapeArc(w / 2, h / 2, w / 2, h / 2, 0, 90, false, position).replace('M', 'L') +
          shapeArc(w / 2, h / 2, w / 2, h / 2, 90, 180, false, position).replace('M', 'L') +
          ' z' +
          'M' +
          (dr + x.left) +
          ',' +
          (h / 2 + y.top) +
          shapeArc(w / 2, h / 2, iwd2, ihd2, 180, 90, false, position).replace('M', 'L') +
          shapeArc(w / 2, h / 2, iwd2, ihd2, 90, 0, false, position).replace('M', 'L') +
          shapeArc(w / 2, h / 2, iwd2, ihd2, 0, -90, false, position).replace('M', 'L') +
          shapeArc(w / 2, h / 2, iwd2, ihd2, 270, 180, false, position).replace('M', 'L') +
          ' z';

        return { paths: [{ d }] };
      }
      case 'noSmoking': {
        const adj = 18750;
        const val1 = 50000;
        const val2 = 100000;
        const a = Math.max(Math.min(val1, adj), 0);
        const w = size.width;
        const h = size.height;
        const dr = (Math.min(w, h) * a) / val2;
        const hc = w / 2 + x.left; //Horizontal center
        const vc = h / 2 + y.top; //Vertical center
        const iwd2 = w / 2 - dr;
        const ihd2 = h / 2 - dr;
        const ang = Math.atan(h / w);
        const ct = ihd2 * Math.cos(ang);
        const st = iwd2 * Math.sin(ang);
        const m = Math.sqrt(ct * ct + st * st);
        const n = (iwd2 * ihd2) / m;
        const drd2 = dr / 2;
        const dang = Math.atan(drd2 / n);
        const dang2 = dang * 2;
        const swAng = -Math.PI + dang2;
        const stAng1 = ang - dang;
        const stAng2 = stAng1 - Math.PI;
        const ct1 = ihd2 * Math.cos(stAng1);
        const st1 = iwd2 * Math.sin(stAng1);
        const m1 = Math.sqrt(ct1 * ct1 + st1 * st1);
        const n1 = (iwd2 * ihd2) / m1;
        const dx1 = n1 * Math.cos(stAng1);
        const dy1 = n1 * Math.sin(stAng1);
        const x1 = hc + dx1;
        const y1 = vc + dy1;
        const x2 = hc - dx1;
        const y2 = vc - dy1;
        const stAng1deg = (stAng1 * 180) / Math.PI;
        const stAng2deg = (stAng2 * 180) / Math.PI;
        const swAng2deg = (swAng * 180) / Math.PI;

        var d =
          'M' +
          x.left +
          ',' +
          (h / 2 + y.top) +
          shapeArc(w / 2, h / 2, w / 2, h / 2, 180, 270, false, position).replace('M', 'L') +
          shapeArc(w / 2, h / 2, w / 2, h / 2, 270, 360, false, position).replace('M', 'L') +
          shapeArc(w / 2, h / 2, w / 2, h / 2, 0, 90, false, position).replace('M', 'L') +
          shapeArc(w / 2, h / 2, w / 2, h / 2, 90, 180, false, position).replace('M', 'L') +
          ' z' +
          'M' +
          x1 +
          ',' +
          y1 +
          shapeArc(
            w / 2,
            h / 2,
            iwd2,
            ihd2,
            stAng1deg,
            stAng1deg + swAng2deg,
            false,
            position,
          ).replace('M', 'L') +
          ' z' +
          'M' +
          x2 +
          ',' +
          y2 +
          shapeArc(
            w / 2,
            h / 2,
            iwd2,
            ihd2,
            stAng2deg,
            stAng2deg + swAng2deg,
            false,
            position,
          ).replace('M', 'L') +
          ' z';

        return { paths: [{ d }] };
      }
      case 'blockArc': {
        const adj1 = 180;
        const adj2 = 0;
        const adj3 = 25000;

        const stAng = Math.max(Math.min(360, adj1), 0);
        const istAng = Math.max(Math.min(360, adj2), 0);
        const a3 = Math.max(Math.min(50000, adj3), 0);
        const sw11 = istAng - stAng;
        const sw12 = sw11 + 360;
        const swAng = sw11 > 0 ? sw11 : sw12;
        const iswAng = -swAng;

        const stRd = (stAng * Math.PI) / 180;
        const istRd = (istAng * Math.PI) / 180;
        const w = size.width;
        const h = size.height;
        const wd2 = w / 2;
        const hd2 = h / 2;
        const hc = w / 2 + x.left;
        const vc = h / 2 + y.top;
        var endAng = stAng + swAng;
        var iendAng = istAng + iswAng;
        let wt1, ht1, dx1, dy1, x1, y1;
        if (stAng > 90 && stAng < 270) {
          wt1 = wd2 * Math.sin(Math.PI / 2 - stRd);
          ht1 = hd2 * Math.cos(Math.PI / 2 - stRd);

          dx1 = wd2 * Math.cos(Math.atan(ht1 / wt1));
          dy1 = hd2 * Math.sin(Math.atan(ht1 / wt1));

          x1 = hc - dx1;
          y1 = vc - dy1;
        } else {
          wt1 = wd2 * Math.sin(stRd);
          ht1 = hd2 * Math.cos(stRd);

          dx1 = wd2 * Math.cos(Math.atan(wt1 / ht1));
          dy1 = hd2 * Math.sin(Math.atan(wt1 / ht1));

          x1 = hc + dx1;
          y1 = vc + dy1;
        }
        const dr = (Math.min(w, h) * a3) / 100000;
        const iwd2 = wd2 - dr;
        const ihd2 = hd2 - dr;
        var x2, y2;
        if ((endAng <= 450 && endAng > 270) || (endAng >= 630 && endAng < 720)) {
          const wt2 = iwd2 * Math.sin(istRd);
          const ht2 = ihd2 * Math.cos(istRd);
          const dx2 = iwd2 * Math.cos(Math.atan(wt2 / ht2));
          const dy2 = ihd2 * Math.sin(Math.atan(wt2 / ht2));
          x2 = hc + dx2;
          y2 = vc + dy2;
        } else {
          const wt2 = iwd2 * Math.sin(Math.PI / 2 - istRd);
          const ht2 = ihd2 * Math.cos(Math.PI / 2 - istRd);

          const dx2 = iwd2 * Math.cos(Math.atan(ht2 / wt2));
          const dy2 = ihd2 * Math.sin(Math.atan(ht2 / wt2));
          x2 = hc + dx2;
          y2 = vc + dy2;
        }
        var d =
          'M' +
          x1 +
          ',' +
          y1 +
          shapeArc(wd2, hd2, wd2, hd2, stAng, endAng, false, position).replace('M', 'L') +
          ' L' +
          x2 +
          ',' +
          y2 +
          shapeArc(wd2, hd2, iwd2, ihd2, istAng, iendAng, false, position).replace('M', 'L') +
          ' z';

        return { paths: [{ d }] };
      }
      case 'smileyFace': {
        var w = size.width;
        var h = size.height;
        var adj = 4653;

        var d_val;
        var cnstVal1 = 50000;
        var cnstVal2 = 100000;
        var cnstVal3 = 4653;
        var a, x1, x2, x3, x4, y1, y3, dy2, y2, y4, dy3, y5, wR, hR, wd2, hd2;
        wd2 = w / 2;
        hd2 = h / 2;
        a = adj < -cnstVal3 ? -cnstVal3 : adj > cnstVal3 ? cnstVal3 : adj;
        x1 = (w * 4969) / 21699;
        x2 = (w * 6215) / 21600;
        x3 = (w * 13135) / 21600;
        x4 = (w * 16640) / 21600;
        y1 = (h * 7570) / 21600;
        y3 = (h * 16515) / 21600;
        dy2 = (h * a) / cnstVal2;
        y2 = y3 - dy2;
        y4 = y3 + dy2;
        dy3 = (h * a) / cnstVal1;
        y5 = y4 + dy3;
        wR = (w * 1125) / 21600;
        hR = (h * 1125) / 21600;
        var cX1 = x2 - wR * Math.cos(Math.PI);
        var cY1 = y1 - hR * Math.sin(Math.PI);
        var cX2 = x3 - wR * Math.cos(Math.PI);
        const hc = w / 2;

        d_val = //eyes
          shapeArc(cX1, cY1, wR, hR, 180, 540, false, position) +
          shapeArc(cX2, cY1, wR, hR, 180, 540, false, position) +
          //mouth
          ' M' +
          (x1 + x.left) +
          ',' +
          (y2 + y.top) +
          ' Q' +
          (hc + x.left) +
          ',' +
          (y5 + y.top) +
          ' ' +
          (x4 + x.left) +
          ',' +
          (y2 + y.top) +
          //head
          ' M' +
          x.left +
          ',' +
          (hd2 + y.top) +
          shapeArc(wd2, hd2, wd2, hd2, 180, 540, false, position).replace('M', 'L') +
          ' z';
        return { paths: [{ d: d_val }] };
      }
      case 'heart': {
        const w = size.width;
        const h = size.height;
        const hc = w / 2;
        const dx1 = (w * 49) / 48;
        const dx2 = (w * 10) / 48;
        const x1 = hc - dx1;
        const x2 = hc - dx2;
        const x3 = hc + dx2;
        const x4 = hc + dx1;
        const y1 = -h / 3;

        const d_val =
          'M' +
          (hc + x.left) +
          ',' +
          (h / 4 + y.top) +
          'C' +
          (x3 + x.left) +
          ',' +
          (y1 + y.top) +
          ' ' +
          (x4 + x.left) +
          ',' +
          (h / 4 + y.top) +
          ' ' +
          (hc + x.left) +
          ',' +
          y.bottom +
          'C' +
          (x1 + x.left) +
          ',' +
          (h / 4 + y.top) +
          ' ' +
          (x2 + x.left) +
          ',' +
          (y1 + y.top) +
          ' ' +
          (hc + x.left) +
          ',' +
          (h / 4 + y.top) +
          ' z';
        return { paths: [{ d: d_val }] };
      }
      case 'lightningBolt': {
        const w = size.width;
        const h = size.height;
        const x1 = (w * 5022) / 21600;
        const x2 = (w * 11050) / 21600;
        const x3 = (w * 8472) / 21600;
        const x5 = (w * 10012) / 21600;
        const x6 = (w * 14767) / 21600;
        const x7 = (w * 12222) / 21600;
        const x8 = (w * 12860) / 21600;
        const x10 = (w * 7602) / 21600;
        const x11 = (w * 16577) / 21600;
        const y1 = (h * 3890) / 21600;
        const y2 = (h * 6080) / 21600;
        const y3 = (h * 6797) / 21600;
        const y5 = (h * 12877) / 21600;
        const y6 = (h * 9705) / 21600;
        const y7 = (h * 12007) / 21600;
        const y8 = (h * 13987) / 21600;
        const y9 = (h * 8382) / 21600;
        const y11 = (h * 14915) / 21600;

        const d_val =
          'M' +
          (x3 + x.left) +
          ',' +
          y.top +
          ' L' +
          (x8 + x.left) +
          ',' +
          (y2 + y.top) +
          ' L' +
          (x2 + x.left) +
          ',' +
          (y3 + y.top) +
          ' L' +
          (x11 + x.left) +
          ',' +
          (y7 + y.top) +
          ' L' +
          (x6 + x.left) +
          ',' +
          (y5 + y.top) +
          ' L' +
          (w + x.left) +
          ',' +
          (h + y.top) +
          ' L' +
          (x5 + x.left) +
          ',' +
          (y11 + y.top) +
          ' L' +
          (x7 + x.left) +
          ',' +
          (y8 + y.top) +
          ' L' +
          (x1 + x.left) +
          ',' +
          (y6 + y.top) +
          ' L' +
          (x10 + x.left) +
          ',' +
          (y9 + y.top) +
          ' L' +
          x.left +
          ',' +
          (y1 + y.top) +
          ' z';

        return { paths: [{ d: d_val }] };
      }
      case 'sun': {
        const adj = 25000;
        const val1 = 12500;
        const val2 = 46875;
        const a = Math.max(Math.min(val2, adj), val1);
        const val3 = 50000;
        const val4 = 100000;
        const w = size.width;
        const h = size.height;
        const g0 = val3 - a;
        const g1 = (g0 * 30274) / 32768;
        const g2 = (g0 * 12540) / 32768;
        const g5 = val3 - g1;
        const g6 = val3 - g2;
        const g10 = (g5 * 3) / 4;
        const g11 = (g6 * 3) / 4;
        const g12 = g10 + 3662;
        const g13 = g11 + 36620;
        const g14 = g11 + 12500;
        const g15 = val4 - g10;
        const g16 = val4 - g12;
        const g17 = val4 - g13;
        const g18 = val4 - g14;
        const ox1 = (w * 18436) / 21600;
        const oy1 = (h * 3163) / 21600;
        const ox2 = (w * 3163) / 21600;
        const oy2 = (h * 18436) / 21600;
        const x10 = (w * g10) / val4;
        const x12 = (w * g12) / val4;
        const x13 = (w * g13) / val4;
        const x14 = (w * g14) / val4;
        const x15 = (w * g15) / val4;
        const x16 = (w * g16) / val4;
        const x17 = (w * g17) / val4;
        const x18 = (w * g18) / val4;
        const x19 = (w * a) / val4;
        const wR = (w * g0) / val4;
        const hR = (h * g0) / val4;
        const y10 = (h * g10) / val4;
        const y12 = (h * g12) / val4;
        const y13 = (h * g13) / val4;
        const y14 = (h * g14) / val4;
        const y15 = (h * g15) / val4;
        const y16 = (h * g16) / val4;
        const y17 = (h * g17) / val4;
        const y18 = (h * g18) / val4;

        const d_val =
          'M' +
          x.right +
          ',' +
          (h / 2 + y.top) +
          ' L' +
          (x15 + x.left) +
          ',' +
          (y18 + y.top) +
          ' L' +
          (x15 + x.left) +
          ',' +
          (y14 + y.top) +
          'z' +
          ' M' +
          (ox1 + x.left) +
          ',' +
          (oy1 + y.top) +
          ' L' +
          (x16 + x.left) +
          ',' +
          (y17 + y.top) +
          ' L' +
          (x13 + x.left) +
          ',' +
          (y12 + y.top) +
          'z' +
          ' M' +
          (w / 2 + x.left) +
          ',' +
          y.top +
          ' L' +
          (x18 + x.left) +
          ',' +
          (y10 + y.top) +
          ' L' +
          (x14 + x.left) +
          ',' +
          (y10 + y.top) +
          'z' +
          ' M' +
          (ox2 + x.left) +
          ',' +
          (oy1 + y.top) +
          ' L' +
          (x17 + x.left) +
          ',' +
          (y12 + y.top) +
          ' L' +
          (x12 + x.left) +
          ',' +
          (y17 + y.top) +
          'z' +
          ' M' +
          x.left +
          ',' +
          (h / 2 + y.top) +
          ' L' +
          (x10 + x.left) +
          ',' +
          (y14 + y.top) +
          ' L' +
          (x10 + x.left) +
          ',' +
          (y18 + y.top) +
          'z' +
          ' M' +
          (ox2 + x.left) +
          ',' +
          (oy2 + y.top) +
          ' L' +
          (x12 + x.left) +
          ',' +
          (y13 + y.top) +
          ' L' +
          (x17 + x.left) +
          ',' +
          (y16 + y.top) +
          'z' +
          ' M' +
          (w / 2 + x.left) +
          ',' +
          (h + y.top) +
          ' L' +
          (x14 + x.left) +
          ',' +
          (y15 + y.top) +
          ' L' +
          (x18 + x.left) +
          ',' +
          (y15 + y.top) +
          'z' +
          ' M' +
          (ox1 + x.left) +
          ',' +
          (oy2 + y.top) +
          ' L' +
          (x13 + x.left) +
          ',' +
          (y16 + y.top) +
          ' L' +
          (x16 + x.left) +
          ',' +
          (y13 + y.top) +
          ' z' +
          ' M' +
          (x19 + x.left) +
          ',' +
          (h / 2 + y.top) +
          shapeArc(w / 2, h / 2, wR, hR, 180, 540, false, position).replace('M', 'L') +
          ' z';
        return { paths: [{ d: d_val }] };
      }
      case 'moon': {
        const adj = 0.5;
        const w = size.width;
        const h = size.height;
        const hd2 = h / 2;
        const cd2 = 180;
        const cd4 = 90;

        const adj2 = (1 - adj) * w;
        const d =
          'M' +
          x.right +
          ',' +
          y.bottom +
          shapeArc(w, hd2, w, hd2, cd4, cd4 + cd2, false, position).replace('M', 'L') +
          shapeArc(w, hd2, adj2, hd2, cd4 + cd2, cd4, false, position).replace('M', 'L') +
          ' z';

        return { paths: [{ d }] };
      }
      case 'arc': {
        const adj1 = avLstIndexed?.adj1 !== undefined ? +avLstIndexed.adj1 / 60000 : 270;
        const adj2 = avLstIndexed?.adj2 !== undefined ? +avLstIndexed.adj2 / 60000 : 0;

        const { x: x2, y: y2 } = getPointOnEllipse(
          size.width / 2,
          size.height / 2,
          size.width,
          size.height,
          adj2,
        );
        // radii of the ellipse
        const rx = size.width / 2;
        const ry = size.height / 2;

        const long = adj2 - adj1 > 180 ? 1 : 0;

        return {
          paths: [
            {
              d: `M${rx + x.left},${y.top}A${rx},${ry} 0 ${long},1 ${x2 + x.left},${y2 + y.top}`,
            },
          ],
        };
      }
      case 'bracketPair': {
        const adj = 16667;
        const val1 = 50000;
        const val2 = 100000;
        const w = size.width;
        const h = size.height;
        const r = w;
        const b = h;
        const cd2 = 180;
        const cd4 = 90;
        const c3d4 = 270;
        const a = Math.max(Math.min(val1, adj), 0);
        const x1 = (Math.min(w, h) * a) / val2;
        const x2 = r - x1;
        const y2 = b - x1;

        const d =
          shapeArc(x1, x1, x1, x1, c3d4, cd2, false, position) +
          shapeArc(x1, y2, x1, x1, cd2, cd4, false, position).replace('M', 'L') +
          shapeArc(x2, x1, x1, x1, c3d4, c3d4 + cd4, false, position) +
          shapeArc(x2, y2, x1, x1, 0, cd4, false, position).replace('M', 'L');
        return { paths: [{ d }] };
      }
      case 'bracePair': {
        const adj = 8333;
        const w = size.width;
        const h = size.height;
        const val1 = 25000;
        const val2 = 50000;
        const val3 = 100000;
        const a = Math.max(Math.min(val1, adj), 0);
        const vc = h / 2;
        const cd = 360;
        const cd2 = 180;
        const cd4 = 90;
        const c3d4 = 270;

        const minWH = Math.min(w, h);
        const x1 = (minWH * a) / val3;
        const x2 = (minWH * a) / val2;
        const x3 = w - x2;
        const x4 = w - x1;
        const y2 = vc - x1;
        const y3 = vc + x1;
        const y4 = h - x1;

        const d =
          'M' +
          (x2 + x.left) +
          ',' +
          y.bottom +
          shapeArc(x2, y4, x1, x1, cd4, cd2, false, position).replace('M', 'L') +
          ' L' +
          (x1 + x.left) +
          ',' +
          (y3 + y.top) +
          shapeArc(0, y3, x1, x1, 0, -cd4, false, position).replace('M', 'L') +
          shapeArc(0, y2, x1, x1, cd4, x1, false, position).replace('M', 'L') /**/ +
          ' L' +
          (x1 + x.left) +
          ',' +
          (x1 + y.top) +
          shapeArc(x2, x1, x1, x1, cd2, c3d4, false, position).replace('M', 'L') +
          ' M' +
          (x3 + x.left) +
          ',' +
          y.top +
          shapeArc(x3, x1, x1, x1, c3d4, cd, false, position).replace('M', 'L') +
          ' L' +
          (x4 + x.left) +
          ',' +
          (y2 + y.top) +
          shapeArc(w, y2, x1, x1, cd2, cd4, false, position).replace('M', 'L') +
          shapeArc(w, y3, x1, x1, c3d4, cd2, false, position).replace('M', 'L') +
          ' L' +
          (x4 + x.left) +
          ',' +
          (y4 + y.top) +
          shapeArc(x3, y4, x1, x1, 0, cd4, false, position).replace('M', 'L');

        return { paths: [{ d }] };
      }
      case 'rightBracket': {
        const h = size.height;
        const w = size.width;
        const adj = 8333;
        const val1 = 50000;
        const val2 = 100000;
        const maxAdj = (val1 * h) / Math.min(w, h);
        const cd = 360;
        const cd4 = 90;
        const c3d4 = 270;
        const a = Math.max(Math.min(maxAdj, adj), 0);
        const y1 = (Math.min(w, h) * a) / val2;
        const y2 = h - y1;
        const y3 = w - y1;
        var d =
          'M' +
          x.left +
          ',' +
          y.bottom +
          shapeArc(y3, y2, y1, y1, cd4, 0, false, position).replace('M', 'L') +
          ' L' +
          x.right +
          ',' +
          (y2 + y.top) +
          shapeArc(y3, y1, y1, y1, cd, c3d4, false, position).replace('M', 'L') +
          ' L' +
          x.left +
          ',' +
          y.top;
        return { paths: [{ d }] };
      }
      case 'leftBracket': {
        const h = size.height;
        const w = size.width;
        const adj = 8333;
        const val1 = 50000;
        const val2 = 100000;
        const maxAdj = (val1 * h) / Math.min(w, h);
        const cd2 = 180;
        const cd4 = 90;
        const c3d4 = 270;
        const a = Math.max(Math.min(maxAdj, adj), 0);
        let y1 = (Math.min(w, h) * a) / val2;
        if (y1 > w) {
          y1 = w;
        }
        const y2 = h - y1;
        var d =
          ' M' +
          x.right +
          ',' +
          y.bottom +
          shapeArc(y1, y2, y1, y1, cd4, cd2, false, position).replace('M', 'L') +
          ' L' +
          x.left +
          ',' +
          (y1 + y.top) +
          shapeArc(y1, y1, y1, y1, cd2, c3d4, false, position).replace('M', 'L') +
          ' L' +
          x.right +
          ',' +
          y.top;
        return { paths: [{ d }] };
      }
      case 'plus': {
        const adj = 25000;
        const a = Math.max(Math.min(50000, adj), 0);
        const w = size.width;
        const h = size.height;
        const ss = Math.min(w, h);
        const x1 = (ss * a) / 100000;
        const x2 = w - x1;
        const y2 = h - x1;

        const d = path();

        d.moveTo(x.left, x1 + y.top);
        d.lineTo(x1 + x.left, x1 + y.top);
        d.lineTo(x1 + x.left, y.top);
        d.lineTo(x2 + x.left, y.top);
        d.lineTo(x2 + x.left, x1 + y.top);
        d.lineTo(w + x.left, x1 + y.top);
        d.lineTo(w + x.left, y2 + y.top);
        d.lineTo(x2 + x.left, y2 + y.top);
        d.lineTo(x2 + x.left, h + y.top);
        d.lineTo(x1 + x.left, h + y.top);
        d.lineTo(x1 + x.left, y2 + y.top);
        d.lineTo(x.left, y2 + y.top);
        d.closePath();

        return { paths: [{ d: d.toString() }] };
      }
      case 'can': {
        const adj = 25000;
        const val1 = 50000;
        const val2 = 200000;
        const w = size.width;
        const h = size.height;
        const ss = Math.min(w, h);
        const maxAdj = (val1 * h) / ss;
        const a = adj < 0 ? 0 : adj > maxAdj ? maxAdj : adj;
        const y1 = (ss * a) / val2;
        const y3 = h - y1;
        const cd2 = 180;
        const wd2 = w / 2;

        const dVal =
          shapeArc(wd2, y1, wd2, y1, 0, cd2, false, position) +
          shapeArc(wd2, y1, wd2, y1, cd2, cd2 + cd2, false, position).replace('M', 'L') +
          ' L' +
          x.right +
          ',' +
          (y3 + y.top) +
          shapeArc(wd2, y3, wd2, y1, 0, cd2, false, position).replace('M', 'L') +
          ' L' +
          x.left +
          ',' +
          (y1 + y.top);

        return { paths: [{ d: dVal }] };
      }
      case 'diagStripe': {
        const adj1 = 50000;
        const val = 100000;

        const a = Math.max(Math.min(val, adj1), 0);
        const w = size.width;
        const h = size.height;
        const x2 = (w * a) / val;
        const y2 = (h * a) / val;

        const d = path();
        d.moveTo(x.left, y2 + y.top);
        d.lineTo(x2 + x.left, y.top);
        d.lineTo(w + x.left, y.top);
        d.lineTo(x.left, h + y.top);
        d.closePath();

        return { paths: [{ d: d.toString() }] };
      }
      case 'cloud': {
        const w = size.width;
        const h = size.height;
        let x0, y0;
        x0 = (w * 3900) / 43200;
        x1 = (w * 4693) / 43200;
        x2 = (w * 6928) / 43200;
        x3 = (w * 16478) / 43200;
        x4 = (w * 28827) / 43200;
        y0 = (h * 14370) / 43200;
        y1 = (h * 26177) / 43200;
        y2 = (h * 34899) / 43200;
        y3 = (h * 39090) / 43200;
        y4 = (h * 34751) / 43200;
        y5 = (h * 22954) / 43200;

        let rX1 = (w * 6753) / 43200,
          rY1 = (h * 9190) / 43200,
          rX2 = (w * 5333) / 43200,
          rY2 = (h * 7267) / 43200,
          rX3 = (w * 4365) / 43200,
          rY3 = (h * 5945) / 43200,
          rX4 = (w * 4857) / 43200,
          rY4 = (h * 6595) / 43200,
          rY5 = (h * 7273) / 43200,
          rX6 = (w * 6775) / 43200,
          rY6 = (h * 9220) / 43200,
          rX7 = (w * 5785) / 43200,
          rY7 = (h * 7867) / 43200,
          rX8 = (w * 6752) / 43200,
          rY8 = (h * 9215) / 43200,
          rX9 = (w * 7720) / 43200,
          rY9 = (h * 10543) / 43200,
          rX10 = (w * 4360) / 43200,
          rY10 = (h * 5918) / 43200,
          rX11 = (w * 4345) / 43200;
        let sA1 = -11429249 / 60000,
          wA1 = 7426832 / 60000,
          sA2 = -8646143 / 60000,
          wA2 = 5396714 / 60000,
          sA3 = -8748475 / 60000,
          wA3 = 5983381 / 60000,
          sA4 = -7859164 / 60000,
          wA4 = 7034504 / 60000,
          sA5 = -4722533 / 60000,
          wA5 = 6541615 / 60000,
          sA6 = -2776035 / 60000,
          wA6 = 7816140 / 60000,
          sA7 = 37501 / 60000,
          wA7 = 6842000 / 60000,
          sA8 = 1347096 / 60000,
          wA8 = 6910353 / 60000,
          sA9 = 3974558 / 60000,
          wA9 = 4542661 / 60000,
          sA10 = -16496525 / 60000,
          wA10 = 8804134 / 60000,
          sA11 = -14809710 / 60000,
          wA11 = 9151131 / 60000;

        let cX0,
          cX1,
          cX2,
          cX3,
          cX4,
          cX5,
          cX6,
          cX7,
          cX8,
          cX9,
          cX10,
          cY0,
          cY1,
          cY2,
          cY3,
          cY4,
          cY5,
          cY6,
          cY7,
          cY8,
          cY9,
          cY10;
        let arc1, arc2, arc3, arc4, arc5, arc6, arc7, arc8, arc9, arc10, arc11;
        let lxy1, lxy2, lxy3, lxy4, lxy5, lxy6, lxy7, lxy8, lxy9, lxy10;

        cX0 = x0 + x.left - rX1 * Math.cos((sA1 * Math.PI) / 180);
        cY0 = y0 + y.top - rY1 * Math.sin((sA1 * Math.PI) / 180);
        arc1 = shapeArc(cX0, cY0, rX1, rY1, sA1, sA1 + wA1, false).replace('M', 'L');
        lxy1 = arc1.substr(arc1.lastIndexOf('L') + 1).split(' ');
        cX1 = parseInt(lxy1[0]) - rX2 * Math.cos((sA2 * Math.PI) / 180);
        cY1 = parseInt(lxy1[1]) - rY2 * Math.sin((sA2 * Math.PI) / 180);
        arc2 = shapeArc(cX1, cY1, rX2, rY2, sA2, sA2 + wA2, false).replace('M', 'L');
        lxy2 = arc2.substr(arc2.lastIndexOf('L') + 1).split(' ');
        cX2 = parseInt(lxy2[0]) - rX3 * Math.cos((sA3 * Math.PI) / 180);
        cY2 = parseInt(lxy2[1]) - rY3 * Math.sin((sA3 * Math.PI) / 180);
        arc3 = shapeArc(cX2, cY2, rX3, rY3, sA3, sA3 + wA3, false).replace('M', 'L');
        lxy3 = arc3.substr(arc3.lastIndexOf('L') + 1).split(' ');
        cX3 = parseInt(lxy3[0]) - rX4 * Math.cos((sA4 * Math.PI) / 180);
        cY3 = parseInt(lxy3[1]) - rY4 * Math.sin((sA4 * Math.PI) / 180);
        arc4 = shapeArc(cX3, cY3, rX4, rY4, sA4, sA4 + wA4, false).replace('M', 'L');
        lxy4 = arc4.substr(arc4.lastIndexOf('L') + 1).split(' ');
        cX4 = parseInt(lxy4[0]) - rX2 * Math.cos((sA5 * Math.PI) / 180);
        cY4 = parseInt(lxy4[1]) - rY5 * Math.sin((sA5 * Math.PI) / 180);
        arc5 = shapeArc(cX4, cY4, rX2, rY5, sA5, sA5 + wA5, false).replace('M', 'L');
        lxy5 = arc5.substr(arc5.lastIndexOf('L') + 1).split(' ');
        cX5 = parseInt(lxy5[0]) - rX6 * Math.cos((sA6 * Math.PI) / 180);
        cY5 = parseInt(lxy5[1]) - rY6 * Math.sin((sA6 * Math.PI) / 180);
        arc6 = shapeArc(cX5, cY5, rX6, rY6, sA6, sA6 + wA6, false).replace('M', 'L');
        lxy6 = arc6.substr(arc6.lastIndexOf('L') + 1).split(' ');
        cX6 = parseInt(lxy6[0]) - rX7 * Math.cos((sA7 * Math.PI) / 180);
        cY6 = parseInt(lxy6[1]) - rY7 * Math.sin((sA7 * Math.PI) / 180);
        arc7 = shapeArc(cX6, cY6, rX7, rY7, sA7, sA7 + wA7, false).replace('M', 'L');
        lxy7 = arc7.substr(arc7.lastIndexOf('L') + 1).split(' ');
        cX7 = parseInt(lxy7[0]) - rX8 * Math.cos((sA8 * Math.PI) / 180);
        cY7 = parseInt(lxy7[1]) - rY8 * Math.sin((sA8 * Math.PI) / 180);
        arc8 = shapeArc(cX7, cY7, rX8, rY8, sA8, sA8 + wA8, false).replace('M', 'L');
        lxy8 = arc8.substr(arc8.lastIndexOf('L') + 1).split(' ');
        cX8 = parseInt(lxy8[0]) - rX9 * Math.cos((sA9 * Math.PI) / 180);
        cY8 = parseInt(lxy8[1]) - rY9 * Math.sin((sA9 * Math.PI) / 180);
        arc9 = shapeArc(cX8, cY8, rX9, rY9, sA9, sA9 + wA9, false).replace('M', 'L');
        lxy9 = arc9.substr(arc9.lastIndexOf('L') + 1).split(' ');
        cX9 = parseInt(lxy9[0]) - rX10 * Math.cos((sA10 * Math.PI) / 180);
        cY9 = parseInt(lxy9[1]) - rY10 * Math.sin((sA10 * Math.PI) / 180);
        arc10 = shapeArc(cX9, cY9, rX10, rY10, sA10, sA10 + wA10, false).replace('M', 'L');
        lxy10 = arc10.substr(arc10.lastIndexOf('L') + 1).split(' ');
        cX10 = parseInt(lxy10[0]) - rX11 * Math.cos((sA11 * Math.PI) / 180);
        cY10 = parseInt(lxy10[1]) - rY3 * Math.sin((sA11 * Math.PI) / 180);
        arc11 = shapeArc(cX10, cY10, rX11, rY3, sA11, sA11 + wA11, false).replace('M', 'L');

        var d1 =
          'M' +
          (x0 + x.left) +
          ',' +
          (y0 + y.top) +
          arc1 +
          arc2 +
          arc3 +
          arc4 +
          arc5 +
          arc6 +
          arc7 +
          arc8 +
          arc9 +
          arc10 +
          arc11 +
          ' z';

        return { paths: [{ d: d1 }] };
      }
      case 'rightBrace': {
        const adj1 = 8333;
        const adj2 = 50000;
        const val2 = 100000;
        const w = size.width;
        const cd = 360;
        const cd2 = 180;
        const cd4 = 90;
        const c3d4 = 270;
        const a2 = Math.max(Math.min(val2, adj2), 0);
        const minWH = Math.min(size.width, size.height);
        const q1 = val2 - a2;
        let q2;
        if (q1 < a2) q2 = q1;
        else q2 = a2;
        const q3 = q2 / 2;
        const maxAdj1 = (q3 * size.height) / minWH;
        const a1 = Math.max(Math.min(maxAdj1, adj1), 0);
        const y1 = (minWH * a1) / val2;
        const y3 = (size.height * a2) / val2;
        const y2 = y3 - y1;
        const y4 = size.height - y1;
        var d =
          'M' +
          x.left +
          ',' +
          y.top +
          shapeArc(0, y1, w / 2, y1, c3d4, cd, false, position).replace('M', 'L') +
          ' L' +
          (w / 2 + x.left) +
          ',' +
          (y2 + y.top) +
          shapeArc(w, y2, w / 2, y1, cd2, cd4, false, position).replace('M', 'L') +
          shapeArc(w, y3 + y1, w / 2, y1, c3d4, cd2, false, position).replace('M', 'L') +
          ' L' +
          (w / 2 + x.left) +
          ',' +
          (y4 + y.top) +
          shapeArc(0, y4, w / 2, y1, 0, cd4, false, position).replace('M', 'L');

        return { paths: [{ d }] };
      }
      case 'leftBrace': {
        const adj1 = 8333;
        const adj2 = 50000;
        const val2 = 100000;
        const w = size.width;
        const h = size.height;
        const cd2 = 180;
        const cd4 = 90;
        const c3d4 = 270;
        const a2 = Math.max(Math.min(val2, adj2), 0);
        const minWH = Math.min(size.width, size.height);
        const q1 = val2 - a2;
        let q2;
        if (q1 < a2) q2 = q1;
        else q2 = a2;
        const q3 = q2 / 2;
        const maxAdj1 = (q3 * size.height) / minWH;
        const a1 = Math.max(Math.min(maxAdj1, adj1), 0);
        const y1 = (minWH * a1) / val2;
        const y3 = (size.height * a2) / val2;
        const y2 = y3 - y1;
        const y4 = y3 + y1;

        var d =
          'M' +
          x.right +
          ',' +
          y.bottom +
          shapeArc(w, h - y1, w / 2, y1, cd4, cd2, false, position).replace('M', 'L') +
          ' L' +
          (w / 2 + x.left) +
          ',' +
          (y4 + y.top) +
          shapeArc(0, y4, w / 2, y1, 0, -cd4, false, position).replace('M', 'L') +
          shapeArc(0, y2, w / 2, y1, cd4, 0, false, position).replace('M', 'L') +
          ' L' +
          (w / 2 + x.left) +
          ',' +
          (y1 + y.top) +
          shapeArc(w, y1, w / 2, y1, cd2, c3d4, false, position).replace('M', 'L');

        return { paths: [{ d }] };
      }
      case 'bevel': {
        const adj = 12500;
        const w = size.width;
        const h = size.height;
        const cnstVal1 = 50000;
        const cnstVal2 = 100000;
        const ss = Math.min(w, h);
        const a = Math.max(Math.min(cnstVal1, adj), 0);
        const x1 = (ss * a) / cnstVal2;
        const x2 = w - x1;
        const y2 = h - x1;

        const side =
          ' M' +
          (x1 + x.left) +
          ',' +
          (x1 + y.top) +
          ' L' +
          (x2 + x.left) +
          ',' +
          (x1 + y.top) +
          ' L' +
          (x2 + x.left) +
          ',' +
          (y2 + y.top) +
          ' L' +
          (x1 + x.left) +
          ',' +
          (y2 + y.top) +
          ' z';

        const otherSide =
          'M' +
          (0 + x.left) +
          ',' +
          (0 + y.top) +
          ' L' +
          (w + x.left) +
          ',' +
          (0 + y.top) +
          ' L' +
          (w + x.left) +
          ',' +
          (h + y.top) +
          ' L' +
          (0 + x.left) +
          ',' +
          (h + y.top) +
          ' z';

        const d_val =
          'M' +
          (0 + x.left) +
          ',' +
          (0 + y.top) +
          ' L' +
          (w + x.left) +
          ',' +
          (0 + y.top) +
          ' L' +
          (w + x.left) +
          ',' +
          (h + y.top) +
          ' L' +
          (0 + x.left) +
          ',' +
          (h + y.top) +
          ' z' +
          ' M' +
          (x1 + x.left) +
          ',' +
          (x1 + y.top) +
          ' L' +
          (x2 + x.left) +
          ',' +
          (x1 + y.top) +
          ' L' +
          (x2 + x.left) +
          ',' +
          (y2 + y.top) +
          ' L' +
          (x1 + x.left) +
          ',' +
          (y2 + y.top) +
          ' z' +
          ' M' +
          (0 + x.left) +
          ',' +
          (0 + y.top) +
          ' L' +
          (x1 + x.left) +
          ',' +
          (x1 + y.top) +
          ' M' +
          (0 + x.left) +
          ',' +
          (h + y.top) +
          ' L' +
          (x1 + x.left) +
          ',' +
          (y2 + y.top) +
          ' M' +
          (w + x.left) +
          ',' +
          (0 + y.top) +
          ' L' +
          (x2 + x.left) +
          ',' +
          (x1 + y.top) +
          ' M' +
          (w + x.left) +
          ',' +
          (h + y.top) +
          ' L' +
          (x2 + x.left) +
          ',' +
          (y2 + y.top);

        return {
          paths: [
            { d: d_val, opacity: 0.8 },
            { d: side, opacity: 1 },
            { d: otherSide, opacity: 0.3 },
          ],
        };
      }
      case 'foldedCorner': {
        const adj = 16667;
        const val1 = 50000;
        const val2 = 100000;
        const w = size.width;
        const h = size.height;
        const ss = Math.min(w, h);
        const a = Math.max(Math.min(val1, adj), 0);
        const dy2 = (ss * a) / val2;
        const dy1 = dy2 / 5;
        const x1 = x.right - dy2;
        const x2 = x1 + dy1;
        const y2 = y.bottom - dy2;
        const y1 = y2 + dy1;
        const d = path();

        d.moveTo(x1, y.bottom);
        d.lineTo(x2, y1);
        d.lineTo(x.right, y2);
        d.lineTo(x1, y.bottom);
        d.lineTo(x.left, y.bottom);
        d.lineTo(x.left, y.top);
        d.lineTo(x.right, y.top);
        d.lineTo(x.right, y2);
        d.closePath();

        return { paths: [{ d: d.toString() }] };
      }
      case 'actionButtonBackPrevious':
      case 'actionButtonForwardNext':
      case 'actionButtonBeginning':
      case 'actionButtonEnd':
      case 'actionButtonHome':
      case 'actionButtonInformation':
      case 'actionButtonBlank':
      case 'actionButtonDocument':
      case 'actionButtonHelp':
      case 'actionButtonMovie':
      case 'actionButtonReturn':
      case 'actionButtonSound':
        return generateActionButtonPath({ size, type });
      case 'bentConnector2':
      case 'bentConnector4':
      case 'bentConnector5':
      case 'chartPlus':
      case 'chartStar':
      case 'chartX':
      case 'cornerTabs':
      case 'curvedConnector2':
      case 'curvedConnector4':
      case 'curvedConnector5':
      case 'funnel':
      case 'gear6':
      case 'gear9':
      case 'leftCircularArrow':
      case 'leftRightCircularArrow':
      case 'leftRightRibbon':
      case 'lineInv':
      case 'nonIsoscelesTrapezoid':
      case 'pieWedge':
      case 'plaqueTabs':
      case 'squareTabs':
      case 'swooshArrow':
      case 'flowChartOfflineStorage': // Unsure what this shape is (Flowchart: 29th shape and powerpoint only has 28)
      case 'upDownArrowCallout': {
        // console.warn('Shape preset geometry', type, 'TBI');
        const d = path();
        d.rect(position.left, position.top, size.width, size.height);
        return { paths: [{ d: d.toString() }] };
      }
      default:
        console.warn('Shape preset geometry', type, 'TBI');
    }
  }
  return { paths: [] };
};

/**
 * Imagine a line drawn from the origin that goes through the reference point, the distance between these 2 points is 100%, given a new percentage, this function returns the point in the same line at that distance from the origin
 * @param {number} x1 the X coordinate of the reference point
 * @param {number} y1 the Y coordinate of the reference point
 * @param {number} percentage The distance in percentage of the new point to be found
 */
function getPointFromPercentage(
  x1: number,
  y1: number,
  percentage: number,
): { x: number; y: number } {
  // Calculate the distance between the origin and the dynamic point
  const distance = Math.sqrt(x1 ** 2 + y1 ** 2);

  // Calculate the desired distance as a percentage of the total distance
  const desiredDistance = distance * (percentage / 100);

  // Calculate the ratio of x and y coordinates
  const ratio = desiredDistance / distance;

  // Calculate the coordinates of the second point
  const x2 = x1 * ratio;
  const y2 = y1 * ratio;

  // Return the coordinates as an object
  return { x: x2, y: y2 };
}

const getPointOnEllipse = (
  centerX: number,
  centerY: number,
  width: number,
  height: number,
  angle: number,
) => {
  const angleInRadians = (angle * Math.PI) / 180;
  const cosAngle = Math.cos(angleInRadians);
  const sinAngle = Math.sin(angleInRadians);

  const x = centerX + (width / 2) * cosAngle;
  const y = centerY + (height / 2) * sinAngle;

  return { x, y };
};

const useGeometry = (
  geometry: Presentation.Shape.Geometry | undefined,
  size: Presentation.Data.Common.Size,
  position: Presentation.Data.Common.Position,
  properties?: Presentation.Data.ShapeProperties | undefined,
) => {
  return useMemo(() => {
    return parseGeometry(geometry ?? { type: 'prst', prst: 'rect' }, size, position, properties);
  }, [geometry, size, position]);
};

export default useGeometry;
