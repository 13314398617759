import { CSSProperties, ReactNode } from 'react';
import { useShapeData } from './ShapeData';
import useGeometry from './useGeometry';

type ShapeForeignObjectProps = {
  textBounds?: {
    width: string;
    height: string;
  } & Presentation.Data.Common.Position;
  overflow?: CSSProperties['overflow'];
  children: ReactNode;
};

const ShapeForeignObject = ({ textBounds, overflow, children }: ShapeForeignObjectProps) => {
  const { position, size, shape } = useShapeData();
  const geometry = useGeometry(shape.properties.geom, size, position, shape.properties);
  return (
    <foreignObject
      x={textBounds?.left ?? geometry.textBounds?.left ?? position.left}
      y={textBounds?.top ?? geometry.textBounds?.top ?? position.top}
      width={textBounds?.width ?? geometry.textBounds?.width ?? size.width}
      height={textBounds?.height ?? geometry.textBounds?.height ?? size.height}
      overflow={overflow ?? 'visible'}
    >
      {children}
    </foreignObject>
  );
};

export default ShapeForeignObject;
