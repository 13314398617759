import { JsonRange, PathUtils } from 'Editor/services/_Common/Selection';
import { NodeUtils } from 'Editor/services/DataManager/models';
import { Command } from '../Command';
import { UpdateImagePropertiesOperation } from '../../Operations/ImageOperations';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM/EditorDOMUtils';
import { EditorDOMElements } from 'Editor/services/_Common/DOM';
import { ELEMENTS } from 'Editor/services/consts';

export class UpdateImagePropertiesCommand extends Command {
  properties: Editor.Styles.ImageProperties;

  constructor(context: Editor.Edition.ICommandArgs, properties: Editor.Styles.ImageProperties) {
    super(context);

    this.properties = properties;
  }

  async exec(): Promise<Editor.Edition.ICommand> {
    if (
      !this.askUserAboutThis() ||
      !this.context.DataManager ||
      !this.context.DataManager.selection
    ) {
      return this;
    }

    const rangeData = this.context.DataManager.selection.current;
    const jsonRange = JsonRange.buildFromRangeData(rangeData[0]);

    if (!jsonRange) {
      return this;
    }

    const baseModel = this.context.DataManager.nodes.getNodeModelById(jsonRange.start.b);
    const baseData = baseModel?.selectedData();

    if (!baseModel || !baseData) {
      return this;
    }

    const closest = NodeUtils.closestOfTypeByPath(baseData, jsonRange.start.p, [
      'img',
      'image-element',
    ]);

    if (closest) {
      const image = EditorDOMUtils.getNode(closest.data.id);

      if (EditorDOMElements.isImageElement(image)) {
        const closestBlock = EditorDOMUtils.closest(image, [
          ELEMENTS.ParagraphElement.TAG,
          ELEMENTS.FigureElement.TAG,
        ]);
        const offsets = EditorDOMUtils.getOffsets(image, closestBlock);

        if (offsets) {
          if (NodeUtils.isImageData(closest.data) && PathUtils.isValidSelectionPath(closest.path)) {
            this.applyOperations(baseModel, closest.path, offsets, closest.data, this.properties);
          }
        }
      }
    }

    // create patch
    this.context.DataManager?.history.createPatch();

    return this;
  }

  applyOperations(
    baseModel: Editor.Data.Node.Model,
    path: Editor.Selection.Path,
    offsets: Editor.Common.Rect,
    imageData: Editor.Data.Node.ImageData,
    properties: Editor.Styles.ImageProperties,
  ) {
    const operation = new UpdateImagePropertiesOperation(
      baseModel,
      path,
      offsets,
      imageData,
      properties,
    );
    return operation.apply();
  }
}
