import { useTableStyle } from 'Presentation/PresentationData';
import { createContext, memo, ReactNode, useContext, useMemo } from 'react';
import { useShapeData } from '../ShapeData';

type TableContextValue = {
  positions: Presentation.Data.Table.Cell.Position[][];
};

const TableContext = createContext<TableContextValue | undefined>(undefined);

type TableDataProps = {
  children: ReactNode;
  shape: Presentation.Data.TableShape;
};

const TableDataProvider = ({ children, shape }: TableDataProps) => {
  const tableStyle = useTableStyle(shape.tbl);
  const { position } = useShapeData();
  const positions = useMemo(() => {
    const positions: Presentation.Data.Table.Cell.Position[][] = [];

    let top = 0;
    let left = 0;

    let rowIndex = 0;
    let colIndex = 0;

    while (rowIndex < shape.tbl.tr.length) {
      const row = shape.tbl.tr[rowIndex];
      const rowPositions: Presentation.Data.Table.Cell.Position[] = [];
      while (colIndex < row.cells.length) {
        const cell = row.cells[colIndex];

        let placement: keyof Presentation.Data.Table.Style = 'wholeTbl';

        switch (true) {
          // firstRow && firstCol
          case shape.tbl.tblPr.firstRow &&
            rowIndex === 0 &&
            shape.tbl.tblPr.firstCol &&
            colIndex === 0:
            if (tableStyle?.nwCell) {
              placement = 'nwCell';
            } else if (tableStyle?.firstRow) {
              placement = 'firstRow';
            } else if (tableStyle?.firstCol) {
              placement = 'firstCol';
            }
            break;
          // firstRow && lastCol
          case shape.tbl.tblPr.firstRow &&
            rowIndex === 0 &&
            shape.tbl.tblPr.lastCol &&
            colIndex === shape.tbl.tr[rowIndex].cells.length - 1:
            if (tableStyle?.neCell) {
              placement = 'neCell';
            } else if (tableStyle?.firstRow) {
              placement = 'firstRow';
            } else if (tableStyle?.lastCol) {
              placement = 'lastCol';
            }
            break;
          // lastRow && firstCol
          case shape.tbl.tblPr.lastRow &&
            rowIndex === shape.tbl.tr.length - 1 &&
            shape.tbl.tblPr.firstCol &&
            colIndex === 0:
            if (tableStyle?.swCell) {
              placement = 'swCell';
            } else if (tableStyle?.lastRow) {
              placement = 'lastRow';
            } else if (tableStyle?.firstCol) {
              placement = 'firstCol';
            }
            break;
          // lastRow && lastCol
          case shape.tbl.tblPr.lastRow &&
            rowIndex === shape.tbl.tr.length - 1 &&
            shape.tbl.tblPr.lastCol &&
            colIndex === shape.tbl.tr[rowIndex].cells.length - 1:
            if (tableStyle?.seCell) {
              placement = 'seCell';
            } else if (tableStyle?.lastRow) {
              placement = 'lastRow';
            } else if (tableStyle?.lastCol) {
              placement = 'lastCol';
            }
            break;
          case shape.tbl.tblPr.firstRow && rowIndex === 0:
            placement = 'firstRow';
            break;
          case shape.tbl.tblPr.firstCol && colIndex === 0:
            placement = 'firstCol';
            break;
          case shape.tbl.tblPr.lastRow && rowIndex === shape.tbl.tr.length - 1:
            placement = 'lastRow';
            break;
          case shape.tbl.tblPr.lastCol && colIndex === shape.tbl.tr[rowIndex].cells.length - 1:
            placement = 'lastCol';
            break;
          case shape.tbl.tblPr.bandRow || shape.tbl.tblPr.bandCol:
            if (shape.tbl.tblPr.bandRow && !!shape.tbl.tblPr.firstRow === (rowIndex % 2 !== 0)) {
              placement = 'band1H';
            } else if (
              shape.tbl.tblPr.bandCol &&
              !!shape.tbl.tblPr.firstCol === (colIndex % 2 !== 0)
            ) {
              placement = 'band1V';
            } else if (shape.tbl.tblPr.bandRow) {
              placement = 'band2H';
            } else if (shape.tbl.tblPr.bandCol) {
              placement = 'band2V';
            }
            break;
        }

        let height = 0;
        let width = 0;

        const rowSpan = cell.rowSpan ?? 1;
        const gridSpan = cell.gridSpan ?? 1;

        for (let i = rowIndex; i <= rowIndex + rowSpan - 1; i++) {
          height += shape.tbl.tr[rowIndex].h;
        }
        for (let i = colIndex; i <= colIndex + gridSpan - 1; i++) {
          width += shape.tbl.tblGrid[i]?.w ?? 0;
        }

        rowPositions.push({
          left: left + position.left,
          top: top + position.top,
          width,
          height,
          placement: placement,
          rowIndex,
          colIndex,
        });
        left += shape.tbl.tblGrid[colIndex]?.w ?? 0;

        colIndex += 1;
      }
      top += row.h;
      left = 0;
      positions.push(rowPositions);
      rowIndex += 1;
      colIndex = 0;
    }

    return positions;
  }, [shape.tbl]);

  return <TableContext.Provider value={{ positions }}>{children}</TableContext.Provider>;
};

export const useTableData = () => {
  const context = useContext(TableContext);
  if (context === undefined) {
    throw new Error('useShapeData can only be used in a ShapeData');
  }
  return context;
};

export default memo(TableDataProvider);
