import { FC } from 'react';
import * as echarts from 'echarts';

import { useChartAxis, useChartPlotArea } from '../hooks';

import ChartBase from '../ChartBase/ChartBase';
import { useBarSeries } from '../series';

type BarChartProps = {
  shape: Presentation.Data.ChartShape;
};

const useSeries = ({
  shape,
}: {
  shape: Presentation.Data.ChartShape;
}): echarts.BarSeriesOption[] | undefined => {
  const { barSeries } = useBarSeries();

  let barDirection: boolean = false;
  let grouping: boolean = false;
  const series: echarts.BarSeriesOption[] = [];
  shape.chartSpace.chart.plotArea.chartTypes.forEach((chart) => {
    if (chart.type === 'bar') {
      barDirection = chart.barDir === 'bar';
      grouping = chart.grouping === 'clustered';
      const chartSeries = barSeries({ chart, yAxisId: undefined });
      chartSeries.forEach((ser) => {
        series.push(ser);
      });
    }
  });

  return barDirection && grouping ? series.reverse() : series;
};

const BarChart: FC<BarChartProps> = ({ shape }) => {
  const axis = useChartAxis(shape);
  const grid = useChartPlotArea(shape.chartSpace.chart.plotArea);
  const series = useSeries({ shape });

  return (
    <ChartBase
      shape={shape}
      chartOptions={{
        //@ts-expect-error echarts types
        grid: {
          ...grid,
        },
        xAxis: axis.xAxis,
        yAxis: axis.yAxis,
        series,
      }}
    />
  );
};

export default BarChart;
