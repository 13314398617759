import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  Modal,
  Toggle,
  ToggleGroup,
  Checkbox,
  InputField,
  Select,
} from 'dodoc-design-system';
import { SelectOption } from 'dodoc-design-system/build/types/Components/Selects/Select';

import EditorManager from 'Editor/services/EditorManager/EditorManager';
import { closeModal } from 'App/ModalContext/utils';
import { useModalData } from 'App/ModalContext/ModalContext';

import styles from './TOCModal.module.scss';

const TRANSLATIONS = {
  create: {
    header: 'INSERT_TABLE_OF_CONTENTS',
    submit: 'global.insert',
  },
  edit: {
    header: 'EDIT_TABLE_OF_CONTENTS',
    submit: 'global.edit',
  },
};

type OptionsConstructor<T extends string> = Record<
  T,
  { labelConstructor: (text: string) => string; labelId: string }
>;

const TAB_LEADER: OptionsConstructor<Exclude<Editor.Data.TabLeader, 'n'>> = {
  d: {
    labelConstructor: (text) => `.................   (${text})`,
    labelId: 'ROW_OF_DOTS',
  },
  h: {
    labelConstructor: (text) => `----------   (${text})`,
    labelId: 'ROW_OF_DASHED',
  },
  u: {
    labelConstructor: (text) => `__________   (${text})`,
    labelId: 'ROW_OF_UNDERSCORE',
  },
};

const TOCModal = () => {
  const intl = useIntl();
  const data = useModalData('TOCModal');
  const mode = data?.id ? 'edit' : 'create';
  // @ts-expect-error
  const tabLeaderOptions = useMemo<SelectOption<Editor.Data.TabLeader>[]>(
    () => [
      { value: 'n', label: intl.formatMessage({ id: 'NONE' }).toLowerCase() },
      ...Object.typedKeys(TAB_LEADER).map((tabLeader) => ({
        value: tabLeader,
        label: TAB_LEADER[tabLeader].labelConstructor(
          intl.formatMessage({ id: TAB_LEADER[tabLeader].labelId }),
        ),
      })),
    ],
    [],
  );
  const headingOptions = useMemo<SelectOption[]>(() => {
    const options: SelectOption[] = [];

    for (let heading = 1; heading <= 6; heading++) {
      options.push({ value: `${heading}`, label: `${intl.formatMessage({ id: 'P' })} ${heading}` });
    }

    return options;
  }, []);
  const [headings, setHeadings] = useState(headingOptions.slice(0, 3));

  const captionOptions = useMemo<SelectOption[]>(() => {
    const customOptions =
      EditorManager.getInstance()
        .getAvailableCaptions()
        ?.reduce<{ value: string; label: string }[]>((acc, item) => {
          acc.push({ value: item, label: item });
          return acc;
        }, []) ?? [];

    const defaultOptions = customOptions.splice(-3);
    return [...defaultOptions, ...customOptions];
  }, []);
  const [caption, setCaption] = useState<SelectOption | null>(null);

  const [tabLeader, setTabLeader] = useState(tabLeaderOptions[0]);

  const [table, setTable] = useState<'contents' | 'captions'>('contents');

  const [pageNumbers, setPageNumbers] = useState(false);
  const [alignToRight, setAlignToRight] = useState(false);
  const [useLinks, setUseLinks] = useState(false);

  useEffect(() => {
    if (data && mode === 'create') {
      let templateProperties = null;
      if (data.table === 'captions' && caption) {
        templateProperties =
          EditorManager.getInstance().DataManager?.tableOfContents.getDefaultTolDefinition(
            caption.value,
          ) || null;
      }
      if (data.table === 'contents') {
        templateProperties =
          EditorManager.getInstance().DataManager?.tableOfContents.getDefaultTocDefinition() ||
          null;
      }
      setModalProperties(templateProperties);
    }
  }, [caption, mode, data?.table]);

  useEffect(() => {
    if (data?.id) {
      const properties =
        data.table === 'contents'
          ? EditorManager.getInstance().getPropertiesFromTOCSection(data.id)
          : EditorManager.getInstance().getPropertiesFromTOLSection(data.id);
      setModalProperties(properties);
    }
    if (data?.table) {
      setTable(data.table);
    }
  }, [data?.id]);

  const togglePageNumbers = () => setPageNumbers((prev) => !prev);
  const toggleAlignToRight = () => setAlignToRight((prev) => !prev);
  const toggleUseLinks = () => setUseLinks((prev) => !prev);

  const setModalProperties = (
    properties: TableOfContents.TOCSectionProperties | TableOfContents.TOLSectionProperties | null,
  ) => {
    if (properties) {
      setAlignToRight(properties.alignNumberRight ?? false);
      setTabLeader(
        tabLeaderOptions.find(({ value }) => properties.leader === value) ?? tabLeaderOptions[0],
      );
      if ('representingLevels' in properties) {
        setHeadings(
          headingOptions.filter(({ value }) => properties.representingLevels.includes(+value)),
        );
      } else {
        setCaption(captionOptions.find(({ label }) => label === properties.label) ?? null);
      }
      setPageNumbers(properties.showPageNumber);
      setUseLinks(properties.useLink);
    }
  };

  const close = () => {
    closeModal('TOCModal');
  };

  const handleInsert = () => {
    if (table === 'contents') {
      const options = {
        representingLevels: headings.map((value) => +value.value),
        showPageNumber: pageNumbers,
        alignNumberRight: alignToRight,
        useLink: useLinks,
        leader: tabLeader.value,
      };
      if (mode === 'create') {
        EditorManager.getInstance().insertTableOfContents(options);
      } else if (mode === 'edit' && data?.id) {
        EditorManager.getInstance().updateTOCSection(data.id, options);
      }
    } else if (table === 'captions' && caption) {
      const options = {
        label: caption?.value,
        showPageNumber: pageNumbers,
        alignNumberRight: alignToRight,
        useLink: useLinks,
        leader: tabLeader.value,
      };
      if (mode === 'create') {
        EditorManager.getInstance().insertTableOfLabels(options);
      } else if (mode === 'edit' && data?.id) {
        EditorManager.getInstance().updateTableOfLabels(data.id, options);
      }
    }
    close();
  };

  return (
    <Modal open width="67rem" testId="insert-toc-modal" onClose={close}>
      <Modal.Header onClose={close}>
        <FormattedMessage id={TRANSLATIONS[mode].header} />
      </Modal.Header>
      <Modal.Body overflow="visible">
        <ToggleGroup fullWidth>
          <Toggle
            size="medium"
            variant="group"
            testId="toc-toggle"
            isToggled={table === 'contents'}
            fullWidth
            onClick={() => setTable('contents')}
          >
            <FormattedMessage id="editor.menu.insert.tableOfContents" />
          </Toggle>
          <Toggle
            size="medium"
            variant="group"
            testId="captions-toggle"
            isToggled={table === 'captions'}
            fullWidth
            onClick={() => setTable('captions')}
          >
            {/* TODO: Add translation message */}
            <FormattedMessage id="TABLE_OF_CAPTIONS" />
          </Toggle>
        </ToggleGroup>
        <div className={styles.root}>
          {table === 'contents' ? (
            <InputField
              label={intl.formatMessage({ id: 'HEADING_LEVELS_TO_BE_DISPLAYED' })}
              size="large"
              margin="3rem 0 0"
              feedback={false}
              testId="toc-displayed-heading-levels-inputfield"
            >
              <Select
                size="large"
                value={headings}
                options={headingOptions}
                onChange={setHeadings}
                listOptionsGroupLabel={intl.formatMessage({
                  id: 'LIST_OF_HEADING_LEVELS',
                })}
                multiOverflowLabel={intl
                  .formatMessage(
                    {
                      id: 'SELECTED_OPTIONS',
                    },
                    { total: headings.length },
                  )
                  .toLowerCase()}
                selectedOptionsGroupLabel={intl.formatMessage({
                  id: 'SELECTED_HEADING_LEVELS',
                })}
                isMulti
                fullWidth
                testId="toc-displayed-heading-levels-select"
              />
            </InputField>
          ) : (
            <InputField
              label={intl.formatMessage({ id: 'CAPTIONS_TO_INCLUDE' })}
              size="large"
              margin="3rem 0 0"
              feedback={false}
              testId="toc-captions-to-include-inputfield"
            >
              <Select
                size="large"
                value={caption}
                options={captionOptions}
                onChange={setCaption}
                fullWidth
                testId="toc-captions-to-include-select"
              />
            </InputField>
          )}
          <InputField
            label={intl.formatMessage({ id: 'TAB_LEADER' })}
            size="large"
            margin="3rem 0 0"
            feedback={false}
            testId="toc-tab-leader-inputfield"
            disabled={!pageNumbers}
          >
            <Select
              size="large"
              value={tabLeader}
              options={tabLeaderOptions}
              onChange={setTabLeader}
              fullWidth
              clearable={false}
              disabled={!pageNumbers}
              testId="toc-tab-leader-select"
            />
          </InputField>
          <div className={styles.settings}>
            <Checkbox
              size="small"
              checked={pageNumbers ? 'checked' : 'unchecked'}
              onChange={togglePageNumbers}
              testId="toc-show-page-numbers"
            >
              <FormattedMessage id="SHOW_PAGE_NUMBERS" />
            </Checkbox>
            <Checkbox
              size="small"
              checked={alignToRight ? 'checked' : 'unchecked'}
              onChange={toggleAlignToRight}
              testId="toc-align-numbers-right"
              disabled={!pageNumbers}
            >
              <FormattedMessage id="ALIGN_PAGE_NUMBERS_TO_RIGHT" />
            </Checkbox>
            <Checkbox
              size="small"
              checked={useLinks ? 'checked' : 'unchecked'}
              onChange={toggleUseLinks}
              testId="toc-use-links"
            >
              <FormattedMessage id="USE_LINKS_INSTEAD_OF_PAGE_NUMBERS" />
            </Checkbox>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="insert-toc-modal-close-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={handleInsert}
          testId="insert-toc-modal-submit-button"
          disabled={table === 'captions' && !caption}
        >
          <FormattedMessage id={TRANSLATIONS[mode].submit} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TOCModal;
