import cx from 'classnames';

import { RichTextEditor } from '_common/components';

import styles from './MinifiedCard.module.scss';
import SuiteAvatar from '_common/suite/components/SuiteAvatar/SuiteAvatar';

type MinifiedCardProps = {
  status: Card.Task['status'];
  state: Card.Task['state'];
  content: Exclude<Card.Task['content'], undefined>['content'] | undefined;
  assignee?: string;
  order: number;
  onClick: () => void;
  testId: string;
};

const MinifiedCard = ({
  status,
  state,
  content,
  order,
  assignee,
  testId,
  onClick,
}: MinifiedCardProps) => {
  return (
    <div
      onClick={onClick}
      className={cx({
        [styles.root]: true,
        [styles.done]: status === 'd',
        [styles.deleted]: state === 'Cancelled',
      })}
    >
      <div className={styles.order}>{`#${order}`}</div>
      <div
        className={cx({
          [styles.description]: true,
          [styles.assignee]: assignee != null,
        })}
      >
        <RichTextEditor
          initialValue={content ? JSON.stringify(content) : ''}
          readOnly
          expanded={false}
          maxLines={1}
          testId={`${testId}-rich-text-editor`}
        />
      </div>
      {assignee && (
        <div className={styles.assignee}>
          <SuiteAvatar userId={assignee} size="small" />
        </div>
      )}
    </div>
  );
};

export default MinifiedCard;
