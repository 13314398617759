import { JsonRange } from 'Editor/services/_Common/Selection';
import { ActionContext } from '../../ActionContext';
import { InsertInlineCommand } from '../GenericCommands';
import { NodeDataBuilder } from 'Editor/services/DataManager';

export class InsertCrossReferenceCommand extends InsertInlineCommand {
  private options: Editor.Data.CrossReferences.PresentationTextOptionsType;

  constructor(
    context: Editor.Edition.ICommandArgs,
    options: Editor.Data.CrossReferences.PresentationTextOptionsType,
  ) {
    super(context);
    this.options = options;
  }

  async exec(...args: any[]): Promise<Editor.Edition.ICommand> {
    if (
      !this.context.DataManager ||
      !this.context.DataManager.selection ||
      !this.context.contentManipulator
    ) {
      return this;
    }

    const rangeData = this.context.DataManager.selection.current;
    const jsonRange = JsonRange.buildFromRangeData(rangeData[0]);

    if (!jsonRange) {
      return this;
    }

    const baseModel = this.context.DataManager.nodes.getNodeModelById(jsonRange.start.b);
    const baseData = baseModel?.selectedData();

    if (!baseModel || !baseData) {
      return this;
    }

    let ctx: Editor.Edition.ActionContext = new ActionContext(jsonRange, baseModel, baseData);

    this.options.origin = baseModel.id;

    const text =
      await this.context.DataManager.crossReferences.getAsyncCrossReferencePresentationText(
        this.options,
      );

    if (text) {
      const refBuilder = new NodeDataBuilder('f').addProperty('t', 'cr');

      if (this.options.format != null) {
        refBuilder.addProperty('f', this.options.format);
      }

      if (this.options.link) {
        refBuilder.addProperty('l', true);
      }

      if (this.options.target) {
        refBuilder.addProperty('r', this.options.target);
      }

      const textData = new NodeDataBuilder('text').setContent(text).build();
      if (textData) {
        refBuilder.addChildData(textData);
      }

      const refData = refBuilder.build();

      if (refData) {
        super.exec(refData, {}, ctx);
      }

      // create patch
      this.context.DataManager?.history.createPatch();
    }

    return this;
  }
}
