import { path } from 'd3';
import SHAPE_CONST from './consts';
import { arcTo, constantToRad } from './utils';

type CalloutProps = {
  size: Presentation.Data.Common.Size;
  adjst?: Record<`adj${string}`, string>;
  type:
    | 'wedgeRectCallout'
    | 'wedgeRoundRectCallout'
    | 'wedgeEllipseCallout'
    | 'cloudCallout'
    | 'borderCallout1'
    | 'borderCallout2'
    | 'borderCallout3'
    | 'accentCallout1'
    | 'accentCallout2'
    | 'accentCallout3'
    | 'callout1'
    | 'callout2'
    | 'callout3'
    | 'accentBorderCallout1'
    | 'accentBorderCallout2'
    | 'accentBorderCallout3';
};

const getAdjstByType = (type: CalloutProps['type']) => {
  switch (type) {
    case 'wedgeRectCallout':
    case 'wedgeEllipseCallout':
    case 'cloudCallout':
    case 'wedgeRoundRectCallout':
      return {
        adj1: -20833,
        adj2: 62500,
        adj3: 16667,
        adj4: 0,
        adj5: 0,
        adj6: 0,
        adj7: 0,
        adj8: 0,
      };
    case 'borderCallout1':
    case 'accentCallout1':
    case 'callout1':
    case 'accentBorderCallout1':
      return {
        adj1: 18750,
        adj2: -8333,
        adj3: 112500,
        adj4: -38333,
        adj5: 0,
        adj6: 0,
        adj7: 0,
        adj8: 0,
      };
    case 'borderCallout2':
    case 'accentCallout2':
    case 'callout2':
    case 'accentBorderCallout2':
      return {
        adj1: 18750,
        adj2: -8333,
        adj3: 18750,
        adj4: -16667,
        adj5: 112500,
        adj6: -46667,
        adj7: 0,
        adj8: 0,
      };
    case 'borderCallout3':
    case 'accentCallout3':
    case 'callout3':
    case 'accentBorderCallout3':
      return {
        adj1: 18750,
        adj2: -8333,
        adj3: 18750,
        adj4: -16667,
        adj5: 100000,
        adj6: -16667,
        adj7: 112963,
        adj8: -8333,
      };
  }
};

const generateCalloutPath = ({
  size,
  type,
  adjst,
}: CalloutProps): Presentation.Data.ParsedGeometry => {
  const w = size.width;
  const h = size.height;

  const wd2 = w / 2; //Width / 2
  const hd2 = h / 2; //Height / 2

  const t = 0;
  const r = w;
  const b = h;
  const l = 0;
  const hc = wd2; //Horizontal center
  const vc = hd2; //Vertical center

  const ss = Math.min(w, h); //Shortest Side

  const defaultAdjLst = getAdjstByType(type);

  if (adjst) {
    adjst.adj1 = adjst.adj || adjst.adj1;
  }

  const adj1 = adjst?.adj1 ? +adjst.adj1 : defaultAdjLst.adj1;
  const adj2 = adjst?.adj2 ? +adjst.adj2 : defaultAdjLst.adj2;
  const adj3 = adjst?.adj3 ? +adjst.adj3 : defaultAdjLst.adj3;
  const adj4 = adjst?.adj4 ? +adjst.adj4 : defaultAdjLst.adj4;
  const adj5 = adjst?.adj5 ? +adjst.adj5 : defaultAdjLst.adj5;
  const adj6 = adjst?.adj6 ? +adjst.adj6 : defaultAdjLst.adj6;
  const adj7 = adjst?.adj7 ? +adjst.adj7 : defaultAdjLst.adj7;
  const adj8 = adjst?.adj8 ? +adjst.adj8 : defaultAdjLst.adj8;

  switch (type) {
    case 'wedgeRectCallout': {
      const dxPos = (w * adj1) / 100000;
      const dyPos = (h * adj2) / 100000;
      const xPos = hc + dxPos;
      const yPos = vc + dyPos;
      const dq = (dxPos * h) / w;
      const ady = Math.abs(dyPos);
      const adq = Math.abs(dq);
      const dz = ady - adq;
      const xg1 = dxPos > 0 ? 7 : 2;
      const xg2 = dxPos > 0 ? 10 : 5;
      const x1 = (w * xg1) / 12;
      const x2 = (w * xg2) / 12;
      const yg1 = dyPos > 0 ? 7 : 2;
      const yg2 = dyPos > 0 ? 10 : 5;
      const y1 = (h * yg1) / 12;
      const y2 = (h * yg2) / 12;
      const t1 = dxPos > 0 ? l : xPos;
      const xl = dz > 0 ? l : t1;
      const t2 = dyPos > 0 ? x1 : xPos;
      const xt = dz > 0 ? t2 : x1;
      const t3 = dxPos > 0 ? xPos : r;
      const xr = dz > 0 ? r : t3;
      const t4 = dyPos > 0 ? xPos : x1;
      const xb = dz > 0 ? t4 : x1;
      const t5 = dxPos > 0 ? y1 : yPos;
      const yl = dz > 0 ? y1 : t5;
      const t6 = dyPos > 0 ? t : yPos;
      const yt = dz > 0 ? t6 : t;
      const t7 = dxPos > 0 ? yPos : y1;
      const yr = dz > 0 ? y1 : t7;
      const t8 = dyPos > 0 ? yPos : b;
      const yb = dz > 0 ? t8 : b;

      const d = path();
      d.moveTo(l, t);
      d.lineTo(x1, t);
      d.lineTo(xt, yt);
      d.lineTo(x2, t);
      d.lineTo(r, t);
      d.lineTo(r, y1);
      d.lineTo(xr, yr);
      d.lineTo(r, y2);
      d.lineTo(r, b);
      d.lineTo(x2, b);
      d.lineTo(xb, yb);
      d.lineTo(x1, b);
      d.lineTo(l, b);
      d.lineTo(l, y2);
      d.lineTo(xl, yl);
      d.lineTo(l, y1);
      d.closePath();

      return { paths: [{ d: d.toString() }] };
    }
    case 'wedgeRoundRectCallout': {
      const dxPos = (w * adj1) / 100000;
      const dyPos = (h * adj2) / 100000;
      const xPos = hc + dxPos;
      const yPos = vc + dyPos;
      const dq = (dxPos * h) / w;
      const ady = Math.abs(dyPos);
      const adq = Math.abs(dq);
      const dz = ady - adq;
      const xg1 = dxPos > 0 ? 7 : 2;
      const xg2 = dxPos > 0 ? 10 : 5;
      const x1 = (w * xg1) / 12;
      const x2 = (w * xg2) / 12;
      const yg1 = dyPos > 0 ? 7 : 2;
      const yg2 = dyPos > 0 ? 10 : 5;
      const y1 = (h * yg1) / 12;
      const y2 = (h * yg2) / 12;
      const t1 = dxPos > 0 ? l : xPos;
      const xl = dz > 0 ? l : t1;
      const t2 = dyPos > 0 ? x1 : xPos;
      const xt = dz > 0 ? t2 : x1;
      const t3 = dxPos > 0 ? xPos : r;
      const xr = dz > 0 ? r : t3;
      const t4 = dyPos > 0 ? xPos : x1;
      const xb = dz > 0 ? t4 : x1;
      const t5 = dxPos > 0 ? y1 : yPos;
      const yl = dz > 0 ? y1 : t5;
      const t6 = dyPos > 0 ? t : yPos;
      const yt = dz > 0 ? t6 : t;
      const t7 = dxPos > 0 ? yPos : y1;
      const yr = dz > 0 ? y1 : t7;
      const t8 = dyPos > 0 ? yPos : b;
      const yb = dz > 0 ? t8 : b;
      const u1 = (ss * adj3) / 100000;
      const u2 = r - u1;
      const v2 = b - u1;

      const d =
        `M ${l} ${u1}` +
        arcTo(u1, u1, SHAPE_CONST.cd2, SHAPE_CONST.cd4, l, u1).d +
        `L ${x1} ${t}` +
        `L ${xt} ${yt}` +
        `L ${x2} ${t}` +
        `L ${u2} ${t}` +
        arcTo(u1, u1, SHAPE_CONST['3cd4'], SHAPE_CONST.cd4, u2, t).d +
        `L ${r} ${y1}` +
        `L ${xr} ${yr}` +
        `L ${r} ${y2}` +
        `L ${r} ${v2}` +
        arcTo(u1, u1, 0, SHAPE_CONST.cd4, r, v2).d +
        `L ${x2} ${b}` +
        `L ${xb} ${yb}` +
        `L ${x1} ${b}` +
        `L ${u1} ${b}` +
        arcTo(u1, u1, SHAPE_CONST.cd4, SHAPE_CONST.cd4, u1, b).d +
        `L ${l} ${y2}` +
        `L ${xl} ${yl}` +
        `L ${l} ${y1}` +
        `z`;

      return { paths: [{ d }] };
    }
    //TODO:PRESENTATION Properly implement wedgeEllipseCallout & cloudCallout
    case 'wedgeEllipseCallout':
    case 'cloudCallout': {
      const dxPos = (w * adj1) / 100000;
      const dyPos = (h * adj2) / 100000;
      const xPos = hc + dxPos;
      const yPos = vc + dyPos;
      const sdx = dxPos * h;
      const sdy = dyPos * w;
      const pang = Math.atan(sdy / sdx);
      const stAng = pang + constantToRad(660000);
      const enAng = pang - constantToRad(660000);
      const dx1 = Math.cos(stAng) * wd2;
      const dy1 = Math.sin(stAng) * hd2;
      let x1 = hc + dx1;
      let y1 = vc + dy1;
      const dx2 = Math.cos(enAng) * wd2;
      const dy2 = Math.sin(enAng) * hd2;
      let x2 = hc + dx2;
      let y2 = vc + dy2;

      if (dxPos >= 0) {
        x1 = hc + dx1;
        y1 = vc + dy1;
        x2 = hc + dx2;
        y2 = vc + dy2;
      } else {
        x1 = hc - dx1;
        y1 = vc - dy1;
        x2 = hc - dx2;
        y2 = vc - dy2;
      }

      const d = path();
      d.moveTo(x1, y1);
      d.lineTo(x2, y2);
      d.lineTo(xPos, yPos);
      // d.arcTo(xPos, yPos, x2, y2, h * 0.8);
      d.closePath();

      return { paths: [{ d: d.toString() }] };
    }
    case 'borderCallout1': {
      const y1 = (h * adj1) / 100000;
      const x1 = (w * adj2) / 100000;
      const y2 = (h * adj3) / 100000;
      const x2 = (w * adj4) / 100000;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();

      const dLine = path();
      dLine.moveTo(x1, y1);
      dLine.lineTo(x2, y2);

      return { paths: [{ d: dMain.toString() }, { d: dLine.toString(), fill: 'none' }] };
    }
    case 'borderCallout2': {
      const y1 = (h * adj1) / 100000;
      const x1 = (w * adj2) / 100000;
      const y2 = (h * adj3) / 100000;
      const x2 = (w * adj4) / 100000;
      const y3 = (h * adj5) / 100000;
      const x3 = (w * adj6) / 100000;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();

      const dLine = path();
      dLine.moveTo(x1, y1);
      dLine.lineTo(x2, y2);
      dLine.lineTo(x3, y3);

      return { paths: [{ d: dMain.toString() }, { d: dLine.toString(), fill: 'none' }] };
    }
    case 'borderCallout3': {
      const y1 = (h * adj1) / 100000;
      const x1 = (w * adj2) / 100000;
      const y2 = (h * adj3) / 100000;
      const x2 = (w * adj4) / 100000;
      const y3 = (h * adj5) / 100000;
      const x3 = (w * adj6) / 100000;
      const y4 = (h * adj7) / 100000;
      const x4 = (w * adj8) / 100000;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();

      const dLine = path();
      dLine.moveTo(x1, y1);
      dLine.lineTo(x2, y2);
      dLine.lineTo(x3, y3);
      dLine.lineTo(x4, y4);

      return { paths: [{ d: dMain.toString() }, { d: dLine.toString(), fill: 'none' }] };
    }
    case 'accentCallout1': {
      const y1 = (h * adj1) / 100000;
      const x1 = (w * adj2) / 100000;
      const y2 = (h * adj3) / 100000;
      const x2 = (w * adj4) / 100000;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();

      const dLine1 = path();
      dLine1.moveTo(x1, t);
      dLine1.lineTo(x1, b);

      const dLine2 = path();
      dLine2.moveTo(x1, y1);
      dLine2.lineTo(x2, y2);

      return {
        paths: [
          { d: dMain.toString() },
          { d: dLine1.toString(), fill: 'none' },
          { d: dLine2.toString(), fill: 'none' },
        ],
      };
    }
    case 'accentCallout2': {
      const y1 = (h * adj1) / 100000;
      const x1 = (w * adj2) / 100000;
      const y2 = (h * adj3) / 100000;
      const x2 = (w * adj4) / 100000;
      const y3 = (h * adj5) / 100000;
      const x3 = (w * adj6) / 100000;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();

      const dLine1 = path();
      dLine1.moveTo(x1, t);
      dLine1.lineTo(x1, b);

      const dLine2 = path();
      dLine2.moveTo(x1, y1);
      dLine2.lineTo(x2, y2);
      dLine2.lineTo(x3, y3);

      return {
        paths: [
          { d: dMain.toString() },
          { d: dLine1.toString(), fill: 'none' },
          { d: dLine2.toString(), fill: 'none' },
        ],
      };
    }
    case 'accentCallout3': {
      const y1 = (h * adj1) / 100000;
      const x1 = (w * adj2) / 100000;
      const y2 = (h * adj3) / 100000;
      const x2 = (w * adj4) / 100000;
      const y3 = (h * adj5) / 100000;
      const x3 = (w * adj6) / 100000;
      const y4 = (h * adj7) / 100000;
      const x4 = (w * adj8) / 100000;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();

      const dLine1 = path();
      dLine1.moveTo(x1, t);
      dLine1.lineTo(x1, b);

      const dLine2 = path();
      dLine2.moveTo(x1, y1);
      dLine2.lineTo(x2, y2);
      dLine2.lineTo(x3, y3);
      dLine2.lineTo(x4, y4);

      return {
        paths: [
          { d: dMain.toString() },
          { d: dLine1.toString(), fill: 'none' },
          { d: dLine2.toString(), fill: 'none' },
        ],
      };
    }
    case 'callout1': {
      const y1 = (h * adj1) / 100000;
      const x1 = (w * adj2) / 100000;
      const y2 = (h * adj3) / 100000;
      const x2 = (w * adj4) / 100000;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();

      const dLine = path();
      dLine.moveTo(x1, y1);
      dLine.lineTo(x2, y2);

      return { paths: [{ d: dMain.toString() }, { d: dLine.toString(), fill: 'none' }] };
    }
    case 'callout2': {
      const y1 = (h * adj1) / 100000;
      const x1 = (w * adj2) / 100000;
      const y2 = (h * adj3) / 100000;
      const x2 = (w * adj4) / 100000;
      const y3 = (h * adj5) / 100000;
      const x3 = (w * adj6) / 100000;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();

      const dLine = path();
      dLine.moveTo(x1, y1);
      dLine.lineTo(x2, y2);
      dLine.lineTo(x3, y3);

      return { paths: [{ d: dMain.toString() }, { d: dLine.toString(), fill: 'none' }] };
    }
    case 'callout3': {
      const y1 = (h * adj1) / 100000;
      const x1 = (w * adj2) / 100000;
      const y2 = (h * adj3) / 100000;
      const x2 = (w * adj4) / 100000;
      const y3 = (h * adj5) / 100000;
      const x3 = (w * adj6) / 100000;
      const y4 = (h * adj7) / 100000;
      const x4 = (w * adj8) / 100000;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();

      const dLine = path();
      dLine.moveTo(x1, y1);
      dLine.lineTo(x2, y2);
      dLine.lineTo(x3, y3);
      dLine.lineTo(x4, y4);

      return { paths: [{ d: dMain.toString() }, { d: dLine.toString(), fill: 'none' }] };
    }
    case 'accentBorderCallout1': {
      const y1 = (h * adj1) / 100000;
      const x1 = (w * adj2) / 100000;
      const y2 = (h * adj3) / 100000;
      const x2 = (w * adj4) / 100000;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();

      const dLine1 = path();
      dLine1.moveTo(x1, t);
      dLine1.lineTo(x1, b);

      const dLine2 = path();
      dLine2.moveTo(x1, y1);
      dLine2.lineTo(x2, y2);

      return {
        paths: [
          { d: dMain.toString() },
          { d: dLine1.toString(), fill: 'none' },
          { d: dLine2.toString(), fill: 'none' },
        ],
      };
    }
    case 'accentBorderCallout2': {
      const y1 = (h * adj1) / 100000;
      const x1 = (w * adj2) / 100000;
      const y2 = (h * adj3) / 100000;
      const x2 = (w * adj4) / 100000;
      const y3 = (h * adj5) / 100000;
      const x3 = (w * adj6) / 100000;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();

      const dLine1 = path();
      dLine1.moveTo(x1, t);
      dLine1.lineTo(x1, b);

      const dLine2 = path();
      dLine2.moveTo(x1, y1);
      dLine2.lineTo(x2, y2);
      dLine2.lineTo(x3, y3);

      return {
        paths: [
          { d: dMain.toString() },
          { d: dLine1.toString(), fill: 'none' },
          { d: dLine2.toString(), fill: 'none' },
        ],
      };
    }
    case 'accentBorderCallout3': {
      const y1 = (h * adj1) / 100000;
      const x1 = (w * adj2) / 100000;
      const y2 = (h * adj3) / 100000;
      const x2 = (w * adj4) / 100000;
      const y3 = (h * adj5) / 100000;
      const x3 = (w * adj6) / 100000;
      const y4 = (h * adj7) / 100000;
      const x4 = (w * adj8) / 100000;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();

      const dLine1 = path();
      dLine1.moveTo(x1, t);
      dLine1.lineTo(x1, b);

      const dLine2 = path();
      dLine2.moveTo(x1, y1);
      dLine2.lineTo(x2, y2);
      dLine2.lineTo(x3, y3);
      dLine2.lineTo(x4, y4);

      return {
        paths: [
          { d: dMain.toString() },
          { d: dLine1.toString(), fill: 'none' },
          { d: dLine2.toString(), fill: 'none' },
        ],
      };
    }
  }
};

export default generateCalloutPath;
