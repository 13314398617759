import { useComments } from 'Presentation/SyncStore';

const useActiveComments = (slideId?: Presentation.Data.Slide['_id']) => {
  const comments = useComments();
  const commentsData = comments?.data ? Object.values(comments.data) : [];

  const filterComment = (comment: Presentation.Data.Comment) => {
    if (!comment || comment.status === 'REJECTED') {
      return false;
    }

    if (slideId) {
      return comment.anchor[0].id === slideId;
    }

    return true;
  };

  return commentsData.filter(filterComment);
};

export default useActiveComments;
