import { CSSProperties, useMemo } from 'react';

import { useSelector } from '_common/hooks';
import { useSlideData } from '../../SlideData';
import { useShapeData } from '../ShapeData';
import useTextBodyProperties from './../useTextBodyProperties';
import { bulletTypeEquals } from './Bullet/bulletUtils';
import { TEXT_DECORATION_MAP } from 'Presentation/consts';

import TextParagraph from './TextParagraph/TextParagraph';
import Bullet from './Bullet/Bullet';

import styles from './TextBody.module.scss';
type TextBodyProps = {
  text: Presentation.Data.TextBody;
};

const TextBody = ({ text }: TextBodyProps) => {
  const style = useTextBodyProperties(text);
  const { color, getFontFamily, getDefaultFontFamily } = useSlideData();
  const { shape } = useShapeData();

  const fontScale = useMemo(() => {
    return (
      (text.bodyPr?.txAutoFit?.normalAutoFit?.fontScale ?? text.bodyPr?.fontScale ?? 100) / 100
    );
  }, [text]);
  const defaultFontFamily = getDefaultFontFamily({ shape });
  const currentPageNumber = useSelector((state) => state.presentation.general.currentSlide);

  const bulletLevels = useMemo(() => {
    if (!text.childNodes?.length) {
      return undefined;
    }

    const levels: Record<number, { parentLevel: number; childLevel: number; index: number }> = {};

    for (let i = 0; i < text.childNodes?.length; i++) {
      const paragraph = text.childNodes[i];

      let parentLevel = 0;
      let index = 1;
      const childLevel = paragraph.properties.lvl ?? 0;
      const childBullet = paragraph.properties.bullet;

      if (i > 0) {
        const prev = levels[i - 1];

        if (childLevel === 0) {
          parentLevel = 0;
          const prevSameLevel = Object.typedKeys(levels).findLast(
            (level) =>
              levels[level].parentLevel === parentLevel && levels[level].childLevel === childLevel,
          );
          if (prevSameLevel) {
            index = levels[prevSameLevel].index + 1;
          }
        }
        if (childLevel === prev.childLevel) {
          const prevBullet = text.childNodes[i - 1].properties.bullet;

          if (!bulletTypeEquals(childBullet?.symbol, prevBullet?.symbol)) {
            index = Math.max(1, prev.index - 1);
          } else {
            index = prev.index + 1;
          }
          parentLevel = prev.parentLevel;
        } else if (childLevel > prev.childLevel) {
          parentLevel = prev.parentLevel + 1;
          index = 1;
        } else if (childLevel < prev.childLevel) {
          parentLevel = prev.parentLevel - 1;

          const prevSameLevel = Object.typedKeys(levels).findLast((level) => {
            return (
              levels[level].parentLevel === parentLevel && levels[level].childLevel === childLevel
            );
          });

          if (prevSameLevel) {
            const prevSameLevelBullet = text.childNodes[prevSameLevel].properties.bullet;
            if (!bulletTypeEquals(childBullet?.symbol, prevSameLevelBullet?.symbol)) {
              index = levels[prevSameLevel].index;
            } else {
              index = levels[prevSameLevel].index + 1;
            }
          }
        }
      }
      levels[i] = { parentLevel, childLevel, index };
    }

    return levels;
  }, [text]);

  const getChildFontFamily = (p: Presentation.Data.ParagraphShape, childIndex: number) => {
    const child = p.childNodes?.[childIndex];

    if (!child) {
      return undefined;
    }

    return (
      getFontFamily({ font: child.properties.latin?.font }) ??
      getFontFamily({ font: p.properties.inlineProperties?.latin?.font }) ??
      defaultFontFamily
    );
  };

  const getChildFontColor = (p: Presentation.Data.ParagraphShape, childIndex: number) => {
    const child = p.childNodes?.[childIndex];
    if (!child) {
      return undefined;
    }
    return child.properties.fill && child.properties.fill.type === 'solid'
      ? color(child.properties.fill.color)
      : undefined;
  };

  return (
    <div style={style} className={styles.textbody}>
      {text.childNodes?.map((p, pIndex) => (
        <TextParagraph key={p.id} listStyles={text.listStyle} paragraph={p} fontScale={fontScale}>
          <Bullet
            paragraph={p}
            index={bulletLevels?.[pIndex].index}
            level={bulletLevels?.[pIndex].childLevel}
            defaultFontFamily={getChildFontFamily(p, 0)}
            defaultFontColor={getChildFontColor(p, 0)}
            fontScale={fontScale}
            listStyle={text.listStyle}
          />
          {p.childNodes?.map((text, index) => {
            const textColor = getChildFontColor(p, index);
            const textDecorationStyles = text.properties.u
              ? TEXT_DECORATION_MAP[text.properties.u]
              : {};

            const isScript = text.properties.baseline;
            const fontSize = text.properties.size ? text.properties.size * fontScale : undefined;

            const style: CSSProperties = {
              color: textColor,
              fontFamily: getChildFontFamily(p, index),
              fontSize: isScript ? (fontSize ?? 24) / 2 : fontSize,
              fontWeight: text.properties.b ? 'bold' : undefined,
              fontStyle: text.properties.i ? 'italic' : undefined,
              ...textDecorationStyles,
              background: text.properties.highlight ? color(text.properties.highlight) : undefined,
              verticalAlign: isScript ? `${text.properties.baseline}%` : undefined,
            };
            const content =
              //@ts-expect-error types presentation
              text?.tx_field_type === 'slidenum' && !/^[0-9]*$/.test(text.content)
                ? currentPageNumber - 1
                : text.content;

            return (
              <span key={index + text?.content?.substring(0, 5)} style={style} data-type="text">
                {content}
              </span>
            );
          })}
        </TextParagraph>
      ))}
    </div>
  );
};

export default TextBody;

// case 'p': {
//   const style = {
//     textAlign: text.properties.algn ? TEXT_ALIGN_MAP[text.properties.algn] : undefined,
//   };
//   return (
//     <div style={style}>
//       {text.childNodes?.map((t, index) => (
//         <TextBox key={t.content + index} text={t} />
//       ))}
//     </div>
//   );
// }
// case 'text': {
//   const textColor =
//     text.properties.fill && text.properties.fill.type === 'solid'
//       ? color(text.properties.fill.color)
//       : undefined;
//   const textDecorationStyles = text.properties.u ? TEXT_DECORATION_MAP[text.properties.u] : {};
//   const style: CSSProperties = {
//     color: textColor,
//     fontFamily: text.properties.font,
//     fontSize: text.properties.size,
//     fontWeight: text.properties.b ? 'bold' : 'normal',
//     fontStyle: text.properties.i ? 'italic' : 'normal',
//     ...textDecorationStyles,
//     background: text.properties.highlight ? color(text.properties.highlight) : 'transparent',
//   };
//   return <span style={style}>{text.content}</span>;
// }
// }
// return null;
