import { OUTLINE_CAP, OUTLINE_DASHARRAY } from 'Presentation/consts';
import { SVGAttributes, useMemo } from 'react';
import { useSlideData } from '../SlideData';

const dashArray = ({
  dash,
  width,
}: {
  dash: Presentation.Data.Outline['dash'];
  width: number | undefined;
}) => {
  const dashArray = OUTLINE_DASHARRAY[dash ?? 'solid'];
  const strokeWidth = width ?? 1; //px

  if (!Array.isArray(dashArray)) {
    return dashArray;
  }

  return dashArray.map((value) => value * strokeWidth).toString();
};

export const parseOutline = (
  ln: Presentation.Data.Outline | undefined,
  color: (c: Presentation.Data.Common.Color) => string,
) => {
  if (ln) {
    const strokeWidth = ln.w;
    const c = ln?.fill?.type === 'solid' ? ln.fill.color : ln.fill?.stops?.[0]?.color;
    const strokeDasharray = dashArray({ dash: ln.dash, width: ln.w });
    const strokeLinejoin = ln.join?.type;
    const strokeLinecap = ln.cap ? OUTLINE_CAP[ln.cap] : undefined; // for some reason they use flat for the 'butt' stroke-linecap attribute
    const strokeMiterlimit = ln.join?.type === 'miter' ? ln.join.lim : undefined;
    if (c) {
      return {
        strokeWidth,
        stroke: color(c),
        strokeDasharray,
        strokeLinejoin,
        strokeLinecap,
        strokeMiterlimit,
      }; // missing other stroke attributes
    }
  }
  return {};
};

const useOutline = (
  ln?: Presentation.Data.Outline,
): Partial<
  Pick<
    SVGAttributes<SVGPathElement>,
    | 'stroke'
    | 'strokeDasharray'
    | 'strokeDashoffset'
    | 'strokeLinecap'
    | 'strokeLinejoin'
    | 'strokeOpacity'
    | 'strokeWidth'
  >
> => {
  const { color } = useSlideData();
  return useMemo(() => {
    if (ln) {
      return parseOutline(ln, color);
    }
    return {};
  }, [ln]);
};

export default useOutline;
