import { DoDOCRange } from '_common/DoDOCSelection/DoDOCRange';

export class PresentationRange extends DoDOCRange {
  cloneRange(): PresentationRange {
    const newRange = new PresentationRange();
    newRange.setStart(this.startContainer, this.startOffset);
    newRange.setEnd(this.endContainer, this.endOffset);
    return newRange;
  }

  static fromNativeRange(nativeRange: Range) {
    let range = new PresentationRange();
    range.setStart(nativeRange.startContainer, nativeRange.startOffset);
    range.setEnd(nativeRange.endContainer, nativeRange.endOffset);
    return range;
  }

  getClientRects(): DOMRectList {
    return super.getClientRects();
  }

  getPresentationAnchor(): Presentation.Common.PresentationAnchor | null {
    const commonAncestorContainer =
      this.commonAncestorContainer instanceof Text
        ? this.commonAncestorContainer.parentElement
        : this.commonAncestorContainer;

    if (!(commonAncestorContainer instanceof Element)) {
      return null;
    }

    const slide = commonAncestorContainer.closest('[data-type="slide"]');
    if (!slide) {
      return null;
    }

    const shape = commonAncestorContainer.closest('[data-type="shape"]');
    const paragraph = commonAncestorContainer.closest('[data-type="p"]');

    const slideMoniker: Presentation.Common.SlideMoniker = { id: slide.id };
    let shapeMoniker: Presentation.Common.ShapeMoniker | null = null;
    let textMoniker: Presentation.Common.TextMoniker | null = null;
    if (shape) {
      shapeMoniker = { id: shape.id };

      if (paragraph) {
        textMoniker = {
          start: this.parseNodeOffsetToPosition(paragraph, this.startContainer, this.startOffset),
          end: this.parseNodeOffsetToPosition(paragraph, this.endContainer, this.endOffset),
        };
      }
    }

    if (shapeMoniker) {
      //Only return text anchor if there is a non-collapsed selection
      if (textMoniker && !this.collapsed) {
        return [slideMoniker, shapeMoniker, textMoniker];
      }

      return [slideMoniker, shapeMoniker];
    }

    return [slideMoniker];
  }

  private parseNodeOffsetToPosition(
    closest: Element,
    node: Node,
    offset: number,
  ): Presentation.Selection.Position {
    const path: Presentation.Selection.Path = [];

    path.push(offset);

    let workNode: Node | null = node;
    while (workNode != null && workNode !== closest.parentNode) {
      if (workNode.nodeType === Node.TEXT_NODE) {
        path.unshift('content');
      }

      if (workNode.nodeType === Node.ELEMENT_NODE) {
        path.unshift('childNodes');
      }

      if (workNode.parentNode && workNode !== closest) {
        const index = Array.from(workNode.parentNode.childNodes as Iterable<Node>).indexOf(
          workNode,
        );
        path.unshift(index);
      }

      workNode = workNode.parentNode;
    }

    return {
      b: closest.id,
      p: path,
    };
  }
}
