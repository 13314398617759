import { CSSProperties, useMemo } from 'react';

const useTextBodyProperties = (text: Presentation.Data.TextBody) => {
  return useMemo(() => {
    const style: CSSProperties = {};

    if (text?.bodyPr) {
      if (text.bodyPr.anchor) {
        switch (text.bodyPr.anchor) {
          case 't':
            style.justifyContent = 'flex-start';
            break;
          case 'b':
            style.justifyContent = 'flex-end';
            break;
          case 'ctr':
            style.justifyContent = 'center';
            break;
          case 'dist':
          case 'just':
            style.justifyContent = 'space-evenly';
        }
      }
      if (text.bodyPr.anchorCtr) {
        style.alignItems = 'center';
      }
      style.paddingTop = text.bodyPr.tIns;
      style.paddingRight = text.bodyPr.rIns;
      style.paddingBottom = text.bodyPr.bIns;
      style.paddingLeft = text.bodyPr.lIns;

      // TODO: Missing
      // text.bodyPr.fontScale
      // text.bodyPr.lnSpcReduction
    }

    return style;
  }, [text]);
};

export default useTextBodyProperties;
