import { RefObject, useCallback, useMemo, useState } from 'react';
import { useSlideData } from '../SlideData';
import { useSlideId } from 'Presentation/hooks';
import { useDebounce, useSelector } from '_common/hooks';
import { ObjectHighlightRect, getHighlightRects } from './utils';
import CardHighlight from './CardHighlight/CardHighlight';
import { useContainerRef } from 'Presentation/Slides/ContainerRefContext';
import { useSlideComments, useSlideTasks } from 'Presentation/SyncStore';

type SlideInlineCardsProps = {
  slideRef: RefObject<SVGSVGElement>;
};

const SlideInlineCards = ({ slideRef }: SlideInlineCardsProps) => {
  const currentSlideId = useSlideId();
  const { isThumbnailSlide } = useSlideData();
  const { containerRef } = useContainerRef();

  const slideComments = useSlideComments(currentSlideId);
  const slideTasks = useSlideTasks(currentSlideId);

  const zoom = useSelector((state) => state.presentation.general.zoom);
  const creating = useSelector((state) => state.presentation.general.creating);

  const [shapesRendered, setShapesRendered] = useState<boolean>(false);
  /**
   * When zoom changes UI will rerender
   * Debouncing zoom value and using it on the useMemo below will make sure we are getting the updated elements rects
   */
  const debouncedZoom = useDebounce(zoom, 0);

  const slideRect = useMemo(() => {
    if (!shapesRendered) {
      setTimeout(() => setShapesRendered(true), 0);
      return undefined;
    }

    return slideRef.current?.getBoundingClientRect();
  }, [shapesRendered, zoom]);

  const genericRangeReduce = useCallback(
    (
      highlights: ObjectHighlightRect<Presentation.Data.Task | Presentation.Data.Comment>[],
      object: Presentation.Data.Task | Presentation.Data.Comment,
    ) => {
      const paragraphId = object.anchor[2]?.start.b;
      if (!paragraphId) {
        return highlights;
      }

      const paragraphNode = document.getElementById(paragraphId);
      if (!paragraphNode) {
        return highlights;
      }

      highlights.push(
        ...getHighlightRects({
          object,
          anchor: object.anchor,
          negativeOffset: slideRect,
          zoom,
        }),
      );
      return highlights;
    },
    [slideRect, debouncedZoom],
  );

  const genericShapeReduce = useCallback(
    (
      highlights: ObjectHighlightRect<Presentation.Data.Task | Presentation.Data.Comment>[],
      object: Presentation.Data.Task | Presentation.Data.Comment,
    ) => {
      const shapeId = object.anchor[1]?.id;
      if (!shapeId) {
        return highlights;
      }

      const shapeNode = document.getElementById(shapeId);
      if (!shapeNode) {
        return highlights;
      }

      const shapeRect = shapeNode.getBoundingClientRect();

      highlights.push({
        object,
        rect: {
          ...shapeRect,
          top: (shapeRect.top - (slideRect?.top ?? 0)) / zoom,
          width: shapeRect.width / zoom,
          height: shapeRect.height / zoom,
          left: (shapeRect.left - (slideRect?.left ?? 0)) / zoom,
        },
      });
      return highlights;
    },
    [slideRect, debouncedZoom],
  );

  const taskRangeHighlights = useMemo(() => {
    if (!shapesRendered) {
      setTimeout(() => setShapesRendered(true), 0);
      return undefined;
    }

    if (isThumbnailSlide) {
      return undefined;
    }

    return Object.values(slideTasks?.data ?? {})
      .filter((task) => task.anchor[0].id === currentSlideId && task.anchor[2]?.start.b)
      .reduce<ObjectHighlightRect<Presentation.Data.Task | Presentation.Data.Comment>[]>(
        genericRangeReduce,
        [],
      );
  }, [shapesRendered, slideTasks, currentSlideId, isThumbnailSlide, genericRangeReduce]);

  const taskShapeHighlights = useMemo(() => {
    if (!shapesRendered) {
      setTimeout(() => setShapesRendered(true), 0);
      return undefined;
    }

    if (isThumbnailSlide) {
      return undefined;
    }

    return Object.values(slideTasks?.data ?? {})
      .filter((task) => task.anchor[0].id === currentSlideId && task.anchor.length === 2)
      .reduce<ObjectHighlightRect<Presentation.Data.Task | Presentation.Data.Comment>[]>(
        genericShapeReduce,
        [],
      );
  }, [shapesRendered, slideTasks, currentSlideId, isThumbnailSlide, genericShapeReduce]);

  const commentRangeHighlights = useMemo(() => {
    if (!shapesRendered) {
      setTimeout(() => setShapesRendered(true), 0);
      return undefined;
    }

    if (isThumbnailSlide) {
      return undefined;
    }

    return Object.values(slideComments?.data ?? {})
      .filter(
        (comment) =>
          comment.anchor[0].id === currentSlideId &&
          comment.anchor[2]?.start.b &&
          comment.status !== 'REJECTED',
      )
      .reduce<ObjectHighlightRect<Presentation.Data.Task | Presentation.Data.Comment>[]>(
        genericRangeReduce,
        [],
      );
  }, [shapesRendered, slideComments, currentSlideId, isThumbnailSlide, genericRangeReduce]);

  const commentShapeHighlights = useMemo(() => {
    if (!shapesRendered) {
      setTimeout(() => setShapesRendered(true), 0);
      return undefined;
    }

    if (isThumbnailSlide) {
      return undefined;
    }

    return Object.values(slideComments?.data ?? {})
      .filter(
        (comment) =>
          comment.anchor[0].id === currentSlideId &&
          comment.anchor.length === 2 &&
          comment.status !== 'REJECTED',
      )
      .reduce<ObjectHighlightRect<Presentation.Data.Task | Presentation.Data.Comment>[]>(
        genericShapeReduce,
        [],
      );
  }, [shapesRendered, slideComments, currentSlideId, isThumbnailSlide, genericShapeReduce]);

  const creatingHighlight = useMemo(() => {
    if (!shapesRendered) {
      setTimeout(() => setShapesRendered(true), 0);
      return undefined;
    }

    if (isThumbnailSlide || !creating?.anchor) {
      return undefined;
    }

    switch (creating.anchor.length) {
      case 3:
        const paragraphNode = creating.anchor[2]?.start.b
          ? document.getElementById(creating.anchor[2].start.b)
          : null;
        if (!paragraphNode) {
          return undefined;
        }

        return getHighlightRects({
          object: undefined,
          anchor: creating.anchor,
          negativeOffset: slideRect
            ? {
                ...slideRect,
                top: slideRect.top - (containerRef?.current?.scrollTop ?? 0),
                left: slideRect.left - (containerRef?.current?.scrollLeft ?? 0),
              }
            : undefined,
          zoom,
        })?.[0];
      case 2:
        const shapeId = creating.anchor[1]?.id;
        if (!shapeId) {
          return undefined;
        }

        const shapeNode = document.getElementById(shapeId);
        if (!shapeNode) {
          return undefined;
        }

        const shapeRect = shapeNode.getBoundingClientRect();

        return {
          object: undefined,
          rect: {
            ...shapeRect,
            top:
              (shapeRect.top - (slideRect?.top ?? 0) + (containerRef?.current?.scrollTop ?? 0)) /
              zoom,
            width: shapeRect.width / zoom,
            height: shapeRect.height / zoom,
            left:
              (shapeRect.left - (slideRect?.left ?? 0) + (containerRef?.current?.scrollLeft ?? 0)) /
              zoom,
          },
        };
    }

    return undefined;
  }, [shapesRendered, currentSlideId, debouncedZoom, isThumbnailSlide, creating, slideRect]);

  /**
   * Shape and range highlights need to be separated due to mouse event layering
   */
  return (
    <foreignObject overflow="visible">
      {taskShapeHighlights?.map(({ object, rect }, i) => {
        return <CardHighlight key={i} object={object} type="task" rect={rect} />;
      })}
      {commentShapeHighlights?.map(({ object, rect }, i) => {
        return <CardHighlight key={i} object={object} type="comment" rect={rect} />;
      })}
      {taskRangeHighlights?.map(({ object, rect }, i) => {
        return <CardHighlight key={i} object={object} type="task" rect={rect} />;
      })}
      {commentRangeHighlights?.map(({ object, rect }, i) => {
        return <CardHighlight key={i} object={object} type="comment" rect={rect} />;
      })}
      {creating && creatingHighlight ? (
        <CardHighlight rect={creatingHighlight.rect} creating />
      ) : null}
    </foreignObject>
  );
};

export default SlideInlineCards;
