import { Cmapps, Structure, TableOfContents } from '../../models';
import BaseController from '../BaseController';
import MultilevelStructure from '../Numbering/MultilevelStructure';

export class CrossReferencesController extends BaseController {
  private structure?: Structure;
  private cmapps?: Cmapps;
  private toc?: TableOfContents;

  private getContentFromTOCElement(
    element: TableOfContents.TOCElementType | undefined,
    options: Editor.Data.CrossReferences.PresentationTextOptionsType,
  ) {
    if (element) {
      if (options.format) {
        let content = '';
        if (element.label) {
          if (options.format.includes('ln')) {
            content += element.p_content.ln;
          }
          if (options.format.includes('t')) {
            content += element.p_content.t;
          }
        } else {
          if (options.format.includes('hn')) {
            content += element.p_content.hn;
          } else if (options.format.includes('t')) {
            content = element.p_content.t || '';
          }
        }
        if (options.format.includes('ab') && options.origin) {
          const target = options.target;
          const [block] = target.split(':');
          const multilevelStructure = new MultilevelStructure(this.structure, this.cmapps);
          multilevelStructure.start();
          const multilevelIndex = multilevelStructure.index;

          const originIndex = multilevelIndex.indexOf(options.origin);
          const blockIndex = multilevelIndex.indexOf(block);
          if (originIndex > blockIndex) {
            content += 'above';
          } else {
            content += 'below';
          }
        }
        return content;
      }
      return element.content || '';
    }
    return null;
  }

  start(documentId: string): void {
    this.structure = this.Data.models?.get('STRUCTURE', `DS${documentId}`);
    this.cmapps = this.Data.models?.get('CMAPPS', `CMI${documentId}`);
    this.toc = this.Data.models?.get(this.Data?.models.TYPE_NAME.TOC, `TOC${documentId}`);
  }

  getCrossReferenceState(
    target: TableOfContents.TOCElementTypeFocusType,
  ): Editor.Data.CrossReferences.StateType {
    if (!target) {
      return 'BROKEN';
    }
    const [block, child] = target.split(':');
    if (!this.structure?.childNodes.includes(block)) {
      return 'BROKEN';
    }
    if (this.toc?.tocElements[child]?.type === 'redacted') {
      return 'REDACTED';
    }
    return 'OK';
  }

  async getAsyncCrossReferencePresentationText(
    options: Editor.Data.CrossReferences.PresentationTextOptionsType,
  ) {
    const target = options.target;
    if (!target) {
      return null;
    }
    const [blockId, childId] = target.split(':');
    if (this.toc?.tocElements[childId] !== undefined) {
      return this.getCrossReferencePresentationText(options);
    }
    if (!this.structure?.childNodes.includes(blockId)) {
      return null;
    }
    //* Request to server
    // let block = this.Data.models?.get('NODE', blockId);
    // if (!block) {
    //   return null;
    // }
    // await block?.awaitForEvent('LOADED');
    // let tocElements: any = this.Data.tableOfContents?.toc?.getTOCElements(block) || [];
    // let child;
    // for (let index = 0; index < tocElements.length; index++) {
    //   if (tocElements[index].type === 'redacted') {
    //     return null;
    //   }
    //   if (tocElements[index].id === childId) {
    //     child = tocElements[index];
    //     break;
    //   }
    // }
    // return this.getContentFromTOCElement(child, options);
    return null;
  }

  getCrossReferencePresentationText(
    options: Editor.Data.CrossReferences.PresentationTextOptionsType,
  ) {
    if (!options) {
      return null;
    }
    const target = options.target;
    if (this.getCrossReferenceState(target) === 'OK') {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [block, child] = target.split(':');
      const element = this.toc?.tocElements[child];
      return this.getContentFromTOCElement(element, options);
    }
    return null;
  }

  updateAllCrossReferences(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent('REFERENCES:UPDATE:ALL', {}, (response) => {
        if (response.success) {
          resolve();
        } else {
          reject(response.error);
        }
      });
    });
  }

  stop(): void {}

  destroy(): void {}
}
