import { Command } from '../Command';
import { EditorSelectionUtils, JsonRange } from 'Editor/services/_Common/Selection';
import { TableStylesOperations } from '../../Operations/TableOperations';
import { EditorDOMElements } from 'Editor/services/_Common/DOM';

export class UpdateTablePropertiesCommand extends Command {
  propertiesData: Editor.Styles.TablePropertiesData;

  constructor(
    context: Editor.Edition.ICommandArgs,
    propertiesData: Editor.Styles.TablePropertiesData,
  ) {
    super(context);
    this.propertiesData = propertiesData;
  }

  async exec(): Promise<Editor.Edition.ICommand> {
    if (
      !this.askUserAboutThis() ||
      !this.context.DataManager ||
      !this.context.DataManager.selection
    ) {
      return this;
    }

    const rangeData = this.context.DataManager.selection.current;
    const jsonRange = rangeData[0] ? JsonRange.buildFromRangeData(rangeData[0]) : null;

    if (!jsonRange) {
      return this;
    }

    // TODO FIND ANOTHER SOLUTION TO GET NODE MODEL
    const baseModel = this.context.DataManager.nodes.getNodeModelById(jsonRange.start.b);
    const baseData = baseModel?.selectedData();

    if (!baseModel || !baseData || !baseData.id) {
      return this;
    }

    // check if element is editable
    if (!this.context.DataManager.nodes.isNodeEditable(baseModel.id)) {
      return this;
    }

    // TODO FIND ANOTHER SOLUTION TO GET SELECTION
    const elements = EditorSelectionUtils.getSelectedTableElements();

    if (
      !elements ||
      !EditorDOMElements.isTableElement(elements.selectedTable) ||
      !(
        baseData.id === elements.selectedTable.id ||
        baseModel.getChildInfoById(elements.selectedTable.id)
      )
    ) {
      return this;
    }

    const operation = this.applyOperations(
      baseModel,
      elements.selectedTable.id,
      elements.selectedCellsIds,
      elements.rowsIndex,
      elements.columnsIndex,
    );

    if (operation) {
      operation.apply();

      // create patch
      this.context.DataManager?.history.createPatch();
    }
    return this;
  }

  private applyOperations(
    baseModel: Editor.Data.Node.Model,
    tableId: string,
    selectedCellsIds: string[],
    rowsIndex: number[],
    columnsIndex: number[],
  ) {
    return new TableStylesOperations(
      baseModel,
      tableId,
      this.propertiesData,
      selectedCellsIds,
      rowsIndex,
      columnsIndex,
    );
  }
}
