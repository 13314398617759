import { useTasks } from 'Presentation/SyncStore';
import { useSelector } from '_common/hooks';
import { dayjs } from 'utils';

const useFilteredTasks = (slideId?: Presentation.Data.Slide['_id']) => {
  const data = useTasks()?.data;
  const tasks = data ? Object.values(data) : [];
  const filters = useSelector((state) => state.filters.reviewerTaskPanel);

  const filterValues = {
    reviewerTaskStatus: filters.reviewerTaskStatus?.map((filterValue) => filterValue.value),
    assignedUser: filters.assignedUser,
  };

  const filterTask = (task: Presentation.Data.Task) => {
    if (!task || (!filterValues.reviewerTaskStatus?.includes('dlt') && task.deletionDate !== '')) {
      return false;
    }

    //State
    if (
      filterValues.reviewerTaskStatus?.includes('dlt') &&
      task.deletionDate === '' &&
      filterValues.reviewerTaskStatus &&
      filterValues.reviewerTaskStatus.length < 1
    ) {
      return false;
    }

    //Status
    if (
      filterValues.reviewerTaskStatus &&
      filterValues.reviewerTaskStatus.length > 0 &&
      !filterValues.reviewerTaskStatus.includes(task.status) &&
      task.deletionDate === ''
    ) {
      return false;
    }

    //Assigned User
    if (filterValues.assignedUser != null && filterValues.assignedUser.value !== task.assignee) {
      return false;
    }

    //Parameter slide id
    if (slideId) {
      return task.anchor[0].id === slideId;
    }

    return true;
  };

  return tasks
    .filter(filterTask)
    .sort((a, b) => (dayjs(a.creationDate).isAfter(b.creationDate) ? 1 : -1));
};

export default useFilteredTasks;
