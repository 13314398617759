import { CSSProperties, Ref, forwardRef, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { v4 } from 'uuid';

import ShapeDataProvider from '../../../ShapeData';
import Background from '../../../Background';

import Line from './Line';
import Marker from './Marker';
import TextBody from '../../../TextBody/TextBody';
import { defaultColors } from '../../series/useSunburstSeries';

type LegendShapeProps = {
  shape: Presentation.Data.Shape;
  chartShape: Presentation.Data.ChartShape;
};

type LineType = 'line' | 'circle' | 'square' | undefined;

const PADDING = {
  x: 8,
  y: 4,
};

const LegendShape = forwardRef(
  ({ shape, chartShape }: LegendShapeProps, out: Ref<HTMLDivElement>) => {
    const ref = useRef<HTMLDivElement | null>(null);

    const getLineType = ({
      chart,
    }: {
      chart: Presentation.Data.ChartTypesProperties;
    }): LineType => {
      switch (chart.type) {
        case 'scatter':
        case 'line': {
          return 'line';
        }
        case 'bubble': {
          return 'circle';
        }
        case 'radar':
          return chart?.radarStyle === 'filled' ? 'square' : 'line';
        //@ts-expect-error this isn't supported yet
        case 'sunburst':
        case 'area':
        case 'bar':
        case 'pie':
        case 'doughnut': {
          return 'square';
        }

        case 'stock':
        default: {
          return undefined;
        }
      }
    };

    const charts = useMemo(() => {
      //@ts-expect-error this isn't supported yet
      return chartShape.chartSpace.chart.plotArea?.chartTypes ?? chartShape.chartSpace.chart.plotArea.plotAreaRegion.series;
    }, [chartShape]);

    const legend = useMemo(() => {
      return chartShape.chartSpace.chart.legend;
    }, [chartShape]);

    const legends = useMemo<
      {
        line: {
          type: LineType;
          ln: Presentation.Data.Outline | undefined;
          fill: Presentation.Data.Common.FillType | undefined;
        };
        marker: Presentation.Data.Marker | undefined;
        text: Presentation.Data.TextBody;
      }[]
    >(() => {
      return charts.reduce<
        {
          line: {
            type: LineType;
            ln: Presentation.Data.Outline | undefined;
            fill: Presentation.Data.Common.FillType | undefined;
          };
          marker: Presentation.Data.Marker | undefined;
          text: Presentation.Data.TextBody;
        }[]
      >((legends, chart) => {
        const lineType = getLineType({ chart });

        //@ts-expect-error this isn't supported yet
        if (chart?.layoutId === 'sunburst') {
          const dataEntries: any[] = [];
          //@ts-expect-error this isn't supported yet
          const level0FilteredNames = chartShape.chartSpace.chartData.data[0].dim[0].lvl[2].pt.filter((item: { content: any }) => {
            if (!dataEntries.includes(item.content)) {
              dataEntries.push(item.content);
              return true;
            }
            return false;
          });
          level0FilteredNames.forEach((data: any, index: any) => {
            const pId = v4();
            //@ts-expect-error this isn't supported yet
            const colors = charts[0].dataPt?.length > 0 ? charts[0].dataPt.filter(
              (data: { properties: any[] }) => data?.properties?.fill,
            ) : defaultColors;

            //@ts-expect-error this isn't supported yet
            const dataPointProperties = (charts[0]?.properties?.fill?.type === 'solid' || !charts[0]?.properties?.fill) ? colors[index]?.properties : charts[0]?.properties;

            legends.push({
              line: {
                //@ts-expect-error this isn't supported yet
                type: getLineType({ chart: { type: chart?.layoutId } }),
                //@ts-expect-error this isn't supported yet
                ln: charts[0]?.properties?.ln,
                fill: dataPointProperties?.fill,
              },
              marker: undefined,
              text: {
                type: 'text_body',
                id: pId,
                bodyPr: legend?.text?.bodyPr,
                listStyle: legend?.text?.listStyle,
                childNodes: [
                  {
                    type: 'p',
                    id: v4(),
                    parent_id: pId,
                    properties: {},
                    childNodes: [
                      {
                        content: data.content,
                        type: 'text',
                        properties: {
                          ...legend?.text?.childNodes?.[0]?.properties?.inlineProperties,
                        },
                      },
                    ],
                  },
                ],
              },
            });
          })
          return legends;
        }

        if (chart.type === 'pie' || chart.type === 'doughnut') {
          const serie = chart.ser[0];
          const dataEntries = serie.cat?.strRef?.strCache.pt ?? [];

          dataEntries.forEach((data, index) => {
            const dataPointProperties = serie.dPt[index].properties;

            const pId = v4();

            legends.push({
              line: {
                type: lineType,
                ln: dataPointProperties?.ln,
                fill: dataPointProperties?.fill,
              },
              marker: undefined,
              text: {
                type: 'text_body',
                id: pId,
                bodyPr: legend?.text.bodyPr,
                listStyle: legend?.text.listStyle,
                childNodes: [
                  {
                    type: 'p',
                    id: v4(),
                    parent_id: pId,
                    properties: {},
                    childNodes: [
                      {
                        content: data.v,
                        type: 'text',
                        properties: {
                          ...legend?.text?.childNodes?.[0]?.properties.inlineProperties,
                        },
                      },
                    ],
                  },
                ],
              },
            });
          });
          return legends;
        }

        if (chart.type === 'bar' && chart.barDir === 'bar' && chart.grouping === 'clustered') {
          chart.ser.forEach((ser) => {
            const legendEntry = legend?.legendEntry?.find((entry) => entry.idx === ser.idx);

            const pId = legendEntry?.text.id ?? v4();

            legends.push({
              line: {
                type: lineType,
                fill: ser.properties?.fill,
                ln: ser.properties?.ln,
              },

              //@ts-expect-error TODO:CHARTS Not all series have "marker" prop, need to find a better way to define the type
              marker: ser.marker,
              text: {
                type: 'text_body',
                id: pId,
                bodyPr: legendEntry?.text.bodyPr,
                listStyle: legendEntry?.text.listStyle,
                childNodes: [
                  {
                    type: 'p',
                    id: v4(),
                    parent_id: pId,
                    properties: {},
                    childNodes: [
                      {
                        content: ser.tx?.strRef?.strCache.pt[0].v ?? ser.tx?.v ?? '',
                        type: 'text',
                        properties: {
                          ...legend?.text?.childNodes?.[0]?.properties.inlineProperties,
                          ...legendEntry?.text.childNodes?.[0]?.properties.inlineProperties,
                        },
                      },
                    ],
                  },
                ],
              },
            });
          });
          return legends.reverse();
        }

        chart.ser.forEach((ser) => {
          const legendEntry = legend?.legendEntry?.find((entry) => entry.idx === ser.idx);

          const pId = legendEntry?.text.id ?? v4();

          legends.push({
            line: {
              type: lineType,
              fill: ser.properties?.fill,
              ln: ser.properties?.ln,
            },

            //@ts-expect-error TODO:CHARTS Not all series have "marker" prop, need to find a better way to define the type
            marker: ser.marker,
            text: {
              type: 'text_body',
              id: pId,
              bodyPr: legendEntry?.text.bodyPr,
              listStyle: legendEntry?.text.listStyle,
              childNodes: [
                {
                  type: 'p',
                  id: v4(),
                  parent_id: pId,
                  properties: {},
                  childNodes: [
                    {
                      content: ser.tx?.strRef?.strCache.pt[0].v ?? ser.tx?.v ?? '',
                      type: 'text',
                      properties: {
                        ...legend?.text?.childNodes?.[0]?.properties.inlineProperties,
                        ...legendEntry?.text.childNodes?.[0]?.properties.inlineProperties,
                      },
                    },
                  ],
                },
              ],
            },
          });
        });

        return legends;
      }, []);
    }, [charts]);

    const style = useMemo<CSSProperties>(() => {
      const style: CSSProperties = {};

      switch (legend?.legendPos) {
        case 't':
        case 'b': {
          style.flexDirection = 'row';
          style.alignItems = 'center';
          break;
        }

        case 'l':
        case 'r': {
          style.flexDirection = 'column';
          style.justifyContent = 'center';
          break;
        }
      }

      return style;
    }, [legend]);

    const position = useMemo(() => {
      return {
        top: shape.properties.xfrm?.off?.y ?? 0,
        left: shape.properties.xfrm?.off?.x ?? 0,
      };
    }, [shape]);

    const [size, setSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 });

    useLayoutEffect(() => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();

        setSize({ width: rect.width + PADDING.x * 2, height: rect.height + PADDING.y * 2 });
      }
    }, [shape]);

    return (
      <ShapeDataProvider shape={shape}>
        <div
          style={{
            position: 'relative',
            top: position.top,
            left: position.left,
            width: size.width,
            height: size.height,
            zIndex: 2,
          }}
        >
          <svg width="100%" height="100%">
            <Background
              position={{ top: 0, left: 0 }}
              size={size}
              fill={shape.properties.fill}
              outline={shape.properties.ln}
            />
            <foreignObject x={0} y={0} overflow="visible">
              <div
                style={{
                  padding: `${PADDING.y}px ${PADDING.x}px`,
                }}
                ref={out}
              >
                <div
                  style={{
                    width: 'fit-content',
                    height: 'fit-content',
                    display: 'flex',
                    gap: '1rem',
                    ...style,
                  }}
                  ref={ref}
                >
                  {legends.map((legend, i) => (
                    <div
                      key={i}
                      style={{
                        width: 'fit-content',
                        height: 'fit-content',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                      }}
                    >
                      <div style={{ position: 'relative', display: 'flex' }}>
                        {legend.line.type && (
                          <Line
                            ln={legend.line.ln}
                            fill={legend.line.fill}
                            type={legend.line.type}
                          />
                        )}
                        {legend.marker && <Marker marker={legend.marker} />}
                      </div>

                      <div style={{ whiteSpace: 'nowrap' }}>
                        <TextBody text={legend.text} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </foreignObject>
          </svg>
        </div>
      </ShapeDataProvider>
    );
  },
);

export default LegendShape;
