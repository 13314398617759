import { RefObject, createContext, useContext } from 'react';

type ContainerRefContextValue = {
  containerRef: RefObject<HTMLDivElement> | null;
};

const ContainerRefContext = createContext<ContainerRefContextValue>({
  containerRef: null,
});

export const useContainerRef = () => {
  return useContext(ContainerRefContext);
};

export default ContainerRefContext;
