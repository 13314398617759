import { Command } from '../Command';
import { ReduxInterface } from 'Editor/services';
import { NodeUtils } from 'Editor/services/DataManager';
import { JsonRange, PathUtils } from 'Editor/services/_Common/Selection';

export class RemoveTemporaryCommentCommand extends Command {
  async exec(): Promise<Editor.Edition.ICommand> {
    try {
      const tempCommentReference = ReduxInterface.getEditorState().comments.insert.reference;
      if (tempCommentReference) {
        const jsonRange = this.updateJsonRange(tempCommentReference);

        if (this.context.DataManager?.selection && jsonRange) {
          this.context.DataManager.selection.setUserSelection([jsonRange.serializeToRangeData()]);
        }
        await this.context.DataManager?.comments?.cancelTemporaryComment(tempCommentReference);

        const insertingTemp = ReduxInterface.getEditorState().comments.insert.inserting;
        if (insertingTemp) {
          ReduxInterface.cancelTemporaryComment();
        }
      }
    } catch (error) {
      logger.captureException(error);
    }

    return this;
  }

  private updateJsonRange(tempCommentReference: string): false | JsonRange {
    if (!this.context.DataManager) {
      return false;
    }

    const rangeData = this.context.DataManager.selection.current;
    let jsonRange = JsonRange.buildFromRangeData(rangeData[0]);

    const baseModel = this.context.DataManager.nodes.getNodeModelById(jsonRange.start.b);
    const baseData = baseModel?.selectedData();

    if (!baseModel || !baseData) {
      return false;
    }

    const refPath = baseModel.findPath('element_reference', tempCommentReference);

    refPath.pop();
    refPath.pop();

    let finalPath: Editor.Selection.Path = jsonRange.end.p; // user atual path

    if (refPath && PathUtils.isValidSelectionPath(refPath)) {
      const previousSibling = NodeUtils.getPreviousSibling(baseData, refPath);

      // calculate path before temporary comment when selection is on same block
      if (previousSibling && NodeUtils.isTextData(previousSibling.data)) {
        finalPath = [...previousSibling.path, 'content', previousSibling.data.content.length];
      } else if (previousSibling?.data.childNodes) {
        finalPath = [...previousSibling.path, 'childNodes', previousSibling.data.childNodes.length];
      }
    }

    // if selection is in another block, it will keep the same selection (collapsed)
    jsonRange = new JsonRange({ b: jsonRange.end.b, p: finalPath });
    return jsonRange;
  }
}
