import { useChartOutline, useDataLabel, useMarkerProperties } from '../hooks';
import useBarSeries from './useBarSeries';

type Dimension = {
  data: Presentation.Data.StockChart['ser'][number] | undefined;
  properties: Exclude<
    NonNullable<echarts.LineSeriesOption['data']>[number],
    string | number | Date | any[]
  >;
};

const useStockSeries = ({
  shape,
  yAxisId,
}: {
  shape: Presentation.Data.ChartShape;
  yAxisId: echarts.LineSeriesOption['yAxisId'];
}): (echarts.LineSeriesOption | echarts.BarSeriesOption)[] => {
  const { chartOutline } = useChartOutline();
  const { markerProperties } = useMarkerProperties();
  const { dataLabel } = useDataLabel();
  const { barSeries } = useBarSeries();

  const chartTypes = shape.chartSpace.chart.plotArea.chartTypes;
  const stockChart = chartTypes.find((chart) => chart.type === 'stock');
  const barChart = chartTypes.find((chart) => chart.type === 'bar');

  const barYAxisIndex = shape.chartSpace.chart.plotArea.chartAxes.find(
    (axis) => axis.axPos === 'l',
  )?.axId;

  if (!stockChart) {
    return [];
  }

  if (stockChart.type !== 'stock') {
    return [];
  }

  if (stockChart.ser.length !== 3) {
    return [];
  }

  const dimensions: {
    high: Dimension;
    open: Dimension;
    close: Dimension;
    low: Dimension;
  } = {
    high: { data: undefined, properties: {} },
    open: { data: undefined, properties: {} },
    close: { data: undefined, properties: {} },
    low: { data: undefined, properties: {} },
  };

  dimensions.high.data = stockChart.ser[0];
  dimensions.close.data = stockChart.ser[2];
  dimensions.low.data = stockChart.ser[1];

  if (!dimensions.high.data || !dimensions.close.data || !dimensions.low.data) {
    return [];
  }

  const xAxisValues =
    stockChart.ser[0].cat?.strRef?.strCache ?? stockChart.ser[0].cat?.numRef?.numCache;

  if (!xAxisValues) {
    return [];
  }

  const hilowLines = stockChart.hilowLines;
  const hilowLineOutline = chartOutline(hilowLines?.properties?.ln);

  //Proccess dimensions' parent properties
  Object.typedKeys(dimensions).forEach((dimension) => {
    const data = dimensions[dimension].data;
    dimensions[dimension].properties = { ...markerProperties({ marker: data?.marker }) };
  });

  /*
   * Stock chart will be rendered by multiple series of type "line"
   * quantity of lines = quantity of xAxis values
   * quantity of series = quantity of lines
   * Therefore, for each x axis value it will be created a line serie
   * Each line will be constituted by 3 points: High, Close, Low
   */
  const stockSeries: echarts.LineSeriesOption[] | undefined = xAxisValues?.pt.map((xValue) => {
    const data: echarts.LineSeriesOption['data'] = Object.typedKeys(dimensions)
      .filter((dim) => dimensions[dim].data != null)
      .map((dim) => {
        const dimension = dimensions[dim];

        const value = dimension.data?.val?.numRef?.numCache?.pt?.find(
          (pt) => pt.idx === xValue.idx,
        );

        //Inherit parent properties
        let properties: typeof dimension.properties = { ...dimension.properties };

        //Proccess own properties of the line
        const childProperties = dimension?.data?.dPt?.find((pt) => pt.idx === xValue.idx);
        if (childProperties) {
          properties = {
            ...properties,
            ...markerProperties({ marker: childProperties.marker }),
          };
        }

        //Data labels
        const val = dimension.data?.val?.numRef?.numCache?.pt?.find((pt) => pt.idx === xValue.idx);
        const label = dataLabel({
          dLbls: dimension.data?.dLbls,
          val: val?.v,
          cat: xValue.v,
          idx: xValue.idx,
          defaultPosition: 'right',
        });

        return {
          value: [xValue.v, value?.v ?? 0],
          label,
          ...properties,
        };
      });

    const option: echarts.LineSeriesOption = {
      type: 'line',
      yAxisId,
      silent: true,
      lineStyle: {
        //@ts-expect-error CHARTS:LIMITATION color doesnt support picture
        color: hilowLineOutline?.borderColor,
        cap: hilowLineOutline?.borderCap,
        join: hilowLineOutline?.borderJoin,
        miterLimit: hilowLineOutline?.borderMiterLimit,
        type: hilowLineOutline?.borderType,
        width: hilowLineOutline?.borderWidth,
      },
      data,
    };

    return option;
  });

  return [
    ...stockSeries,
    ...(barChart?.type === 'bar'
      ? barSeries({
          chart: barChart,
          yAxisId: `${barYAxisIndex}`,
        })
      : []),
  ];
};

export default useStockSeries;
