import { ContentManipulator } from './ContentManipulator';
import { InsertManipulator } from './Suggestions/Insert';
import { RemoveManipulator } from './Suggestions/Remove';
import { SplitManipulator } from './Suggestions/Split';

export class SuggestionManipulator extends ContentManipulator {
  insert: Editor.Edition.IInsertManipulator;
  remove: Editor.Edition.IRemoveManipulator;
  split: Editor.Edition.ISplitManipulator;

  constructor(ctx: Editor.Edition.Context) {
    super(ctx);

    this.insert = new InsertManipulator(this.editionContext);
    this.remove = new RemoveManipulator(this.editionContext);
    this.split = new SplitManipulator(this.editionContext);
  }
}
