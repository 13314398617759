import { v4 } from 'uuid';
import { useGetImageQuery } from 'Presentation/api';
import { useSuiteObject } from '_common/suite';
import Pattern from '../Background/Pattern';
import { useSlideData } from '../SlideData';
import { useShapeData } from './ShapeData';
import useGeometry from './useGeometry';
import useOutline from './useOutline';
import { useMemo } from 'react';

type ImageProps = {
  position: Presentation.Data.Common.Position;
  fill: Presentation.Data.Common.PictureFillType;
  xfrm?: Presentation.Shape.Xfrm;
};

const Image = ({ position, fill, xfrm }: ImageProps) => {
  const { id } = useSuiteObject();
  const { size } = useShapeData();
  const { data } = useGetImageQuery({
    presentation_id: id,
    reference_id: fill.source,
  });

  const uncropedSize = useMemo(() => {
    const uncropedWidthRelative = 100 - (fill.sr?.l ?? 0) - (fill.sr?.r ?? 0);
    const uncropedWidth = (size.width * 100) / uncropedWidthRelative;

    const uncropedHeightRelative = 100 - (fill.sr?.t ?? 0) - (fill.sr?.b ?? 0);
    const uncropedHeight = (size.height * 100) / uncropedHeightRelative;

    return { width: uncropedWidth, height: uncropedHeight };
  }, [size, fill]);

  const relativeCrop = useMemo<Required<Presentation.Data.Common.PictureFillModCrop>>(() => {
    const sanitized = { t: 0, r: 0, b: 0, l: 0, ...fill.sr };

    const t = (sanitized.t * uncropedSize.height) / 100;
    // const r = (sanitized.r * uncropedSize.width) / 100; //Unnecessary for now
    // const b = (sanitized.b * uncropedSize.height) / 100; //Unnecessary for now
    const l = (sanitized.l * uncropedSize.width) / 100;

    return { ...sanitized, t, l };
  }, [fill, uncropedSize]);

  if (!data) {
    return null;
  }

  return (
    <foreignObject
      x={position.left}
      y={position.top}
      width={fill.tile?.sx ?? size.width}
      height={fill.tile?.sy ?? size.height}
      style={{
        //Image rotation
        transformOrigin: 'center center',
        transform: `rotate(${xfrm?.rot ?? 0}deg)`,
        transformBox: 'fill-box',
      }}
    >
      <img
        src={data}
        alt=""
        width={uncropedSize.width}
        height={uncropedSize.height}
        style={{
          marginLeft: -relativeCrop.l,
          marginTop: -relativeCrop.t,
          pointerEvents: 'none',
        }}
      />
    </foreignObject>
  );
};

type BackgroundProps = {
  fill?: Presentation.Data.Common.FillType;
  geometry?: Presentation.Shape.Geometry;
  xfrm?: Presentation.Shape.Xfrm;
  outline?: Presentation.Data.Outline;
  position: Presentation.Data.Common.Position;
  size: Presentation.Data.Common.Size;
};

const Background = ({ fill, geometry, xfrm, outline, position, size }: BackgroundProps) => {
  const { color } = useSlideData();
  const { shape } = useShapeData();

  const { paths } = useGeometry(geometry, size, position, shape.properties);
  const outlineProps = useOutline(outline);

  const bgId = v4();

  const renderBackgroundDef = (fill: Presentation.Data.Common.FillType | undefined, id: string) => {
    if (fill) {
      switch (fill.type) {
        case 'solid':
          return (
            <pattern id={id} width="1" height="1" patternUnits="userSpaceOnUse">
              <rect width="1" height="1" fill={color(fill.color)} />
            </pattern>
          );
        case 'gradient': {
          const stops = [...fill.stops].sort((a, b) => a.pos - b.pos);
          const deg = fill.angle ?? 0;
          return (
            <linearGradient id={id} gradientTransform={`rotate(${deg})`}>
              {stops.map((stop) => {
                return (
                  <stop key={stop.pos} offset={`${stop.pos}%`} stopColor={color(stop.color)} />
                );
              })}
            </linearGradient>
          );
        }
        case 'pattern':
          return (
            <Pattern
              id={id}
              pattern={fill.preset}
              backgroundColor={color(fill.background)}
              foregroundColor={color(fill.foreground)}
            />
          );
      }
    }
    return null;
  };

  return (
    <>
      <g id={`${shape.id}-background`} transform={`translate(${position.left}, ${position.top})`}>
        <defs>{renderBackgroundDef(fill, bgId)}</defs>
        {paths.map((shapeProps) => {
          return (
            <path
              key={shapeProps.d}
              x={position.left}
              y={position.top}
              fill={`url(#${bgId})`}
              style={{
                //Image rotation
                transformOrigin: 'center center',
                transform: `rotate(${xfrm?.rot ?? 0}deg)`,
                transformBox: 'fill-box',
              }}
              {...outlineProps}
              {...shapeProps}
            />
          );
        })}
      </g>
      {shape.type === 'picture' && (
        <g id={`${shape.id}-picture`}>
          {paths.map(({ d }) => {
            return (
              <>
                <path
                  key={d}
                  x={position.left}
                  y={position.top}
                  d={d}
                  {...outlineProps}
                  fill="transparent"
                  style={{
                    //Image rotation
                    transformOrigin: 'center center',
                    transform: `rotate(${xfrm?.rot ?? 0}deg)`,
                    transformBox: 'fill-box',
                  }}
                />
                {shape.fill.type === 'picture' && (
                  <Image position={position} fill={shape.fill} xfrm={xfrm} />
                )}
              </>
            );
          })}
        </g>
      )}
    </>
  );
};

export default Background;
