import SHAPE_CONST from './consts';
import { arcTo, pin, shapeArc } from './utils';

type BlockArrowProps = {
  size: Presentation.Data.Common.Size;
  adjst?: Record<`adj${string}`, string>;
  type:
    | 'rightArrow'
    | 'leftArrow'
    | 'upArrow'
    | 'downArrow'
    | 'leftRightArrow'
    | 'upDownArrow'
    | 'quadArrow'
    | 'leftRightUpArrow'
    | 'bentArrow'
    | 'uturnArrow'
    | 'leftUpArrow'
    | 'bentUpArrow'
    | 'curvedRightArrow'
    | 'curvedLeftArrow'
    | 'curvedUpArrow'
    | 'curvedDownArrow'
    | 'stripedRightArrow'
    | 'notchedRightArrow'
    | 'homePlate'
    | 'chevron'
    | 'rightArrowCallout'
    | 'downArrowCallout'
    | 'leftArrowCallout'
    | 'upArrowCallout'
    | 'leftRightArrowCallout'
    | 'quadArrowCallout'
    | 'circularArrow';
};

const getAdjstByType = (type: BlockArrowProps['type']) => {
  switch (type) {
    case 'rightArrow':
    case 'leftArrow':
    case 'upArrow':
    case 'downArrow':
    case 'leftRightArrow':
    case 'upDownArrow':
    case 'stripedRightArrow':
    case 'notchedRightArrow':
    case 'homePlate':
    case 'chevron':
      return {
        adj1: 50000,
        adj2: 50000,
        adj3: 0,
        adj4: 0,
        adj5: 0,
      };
    case 'quadArrow':
      return {
        adj1: 22500,
        adj2: 22500,
        adj3: 22500,
        adj4: 0,
        adj5: 0,
      };
    case 'leftRightUpArrow':
    case 'leftUpArrow':
    case 'bentUpArrow':
    case 'bentArrow':
    case 'uturnArrow':
      return {
        adj1: 25000,
        adj2: 25000,
        adj3: 25000,
        adj4: 43750,
        adj5: 75000,
      };
    case 'leftRightArrowCallout':
      return {
        adj1: 25000,
        adj2: 25000,
        adj3: 25000,
        adj4: 48123,
        adj5: 0,
      };
    case 'curvedRightArrow':
    case 'curvedLeftArrow':
    case 'curvedUpArrow':
    case 'curvedDownArrow':
      return {
        adj1: 25000,
        adj2: 50000,
        adj3: 25000,
        adj4: 0,
        adj5: 0,
      };

    case 'rightArrowCallout':
    case 'downArrowCallout':
    case 'leftArrowCallout':
    case 'upArrowCallout':
      return {
        adj1: 25000,
        adj2: 25000,
        adj3: 25000,
        adj4: 64977,
        adj5: 0,
      };
    case 'quadArrowCallout':
      return {
        adj1: 18515,
        adj2: 18515,
        adj3: 18515,
        adj4: 48123,
        adj5: 0,
      };
    case 'circularArrow':
      return {
        adj1: 12500,
        adj2: 1142319,
        adj3: 20457681,
        adj4: 10800000,
        adj5: 12500,
      };
  }
};

const generateBlockArrowPath = ({
  size,
  type,
  adjst,
}: BlockArrowProps): Presentation.Data.ParsedGeometry => {
  const w = size.width;
  const h = size.height;

  const wd2 = w / 2; //Width / 2
  const hd2 = h / 2; //Height / 2

  const t = 0;
  const r = w;
  const b = h;
  const l = 0;
  const hc = wd2; //Horizontal center
  const vc = hd2; //Vertical center

  const ss = Math.min(w, h); //Shortest Side

  const defaultAdjLst = getAdjstByType(type);

  if (adjst) {
    adjst.adj1 = adjst.adj || adjst.adj1;
  }

  const adj1 = adjst?.adj1 ? +adjst.adj1 : defaultAdjLst.adj1;
  const adj2 = adjst?.adj2 ? +adjst.adj2 : defaultAdjLst.adj2;
  const adj3 = adjst?.adj3 ? +adjst.adj3 : defaultAdjLst.adj3;
  const adj4 = adjst?.adj4 ? +adjst.adj4 : defaultAdjLst.adj4;
  const adj5 = adjst?.adj5 ? +adjst.adj5 : defaultAdjLst.adj5;

  switch (type) {
    case 'rightArrow': {
      const maxAdj2 = (100000 * w) / ss;
      const a1 = pin(0, adj1, 100000);
      const a2 = pin(0, adj2, maxAdj2);
      const dx1 = (ss * a2) / 100000;
      const x1 = r - dx1;
      const dy1 = (h * a1) / 200000;
      const y1 = vc - dy1;
      const y2 = vc + dy1;

      const d =
        `M ${l} ${y1}` +
        `L ${x1} ${y1}` +
        `L ${x1} ${t}` +
        `L ${r} ${vc}` +
        `L ${x1} ${b}` +
        `L ${x1} ${y2}` +
        `L ${l} ${y2}` +
        `z`;

      return { paths: [{ d }] };
    }
    case 'leftArrow': {
      const maxAdj2 = (100000 * w) / ss;
      const a1 = pin(0, adj1, 100000);
      const a2 = pin(0, adj2, maxAdj2);
      const dx2 = (ss * a2) / 100000;
      const x2 = l + dx2;
      const dy1 = (h * a1) / 200000;
      const y1 = vc - dy1;
      const y2 = vc + dy1;

      const d =
        `M ${l} ${vc}` +
        `L ${x2} ${t}` +
        `L ${x2} ${y1}` +
        `L ${r} ${y1}` +
        `L ${r} ${y2}` +
        `L ${x2} ${y2}` +
        `L ${x2} ${b}` +
        `z`;

      return { paths: [{ d }] };
    }
    case 'upArrow': {
      const maxAdj2 = (100000 * w) / ss;
      const a1 = pin(0, adj1, 100000);
      const a2 = pin(0, adj2, maxAdj2);
      const dy2 = (ss * a2) / 100000;
      const y2 = t + dy2;
      const dx1 = (w * a1) / 200000;
      const x1 = hc - dx1;
      const x2 = hc + dx1;

      const d =
        `M ${l} ${y2}` +
        `L ${hc} ${t}` +
        `L ${r} ${y2}` +
        `L ${x2} ${y2}` +
        `L ${x2} ${b}` +
        `L ${x1} ${b}` +
        `L ${x1} ${y2}` +
        `z`;

      return { paths: [{ d }] };
    }
    case 'downArrow': {
      const maxAdj2 = (100000 * w) / ss;
      const a1 = pin(0, adj1, 100000);
      const a2 = pin(0, adj2, maxAdj2);
      const dy1 = (ss * a2) / 100000;
      const y1 = b - dy1;
      const dx1 = (w * a1) / 200000;
      const x1 = hc - dx1;
      const x2 = hc + dx1;

      const d =
        `M ${l} ${y1}` +
        `L ${x1} ${y1}` +
        `L ${x1} ${t}` +
        `L ${x2} ${t}` +
        `L ${x2} ${y1}` +
        `L ${r} ${y1}` +
        `L ${hc} ${b}` +
        `z`;

      return { paths: [{ d }] };
    }
    case 'leftRightArrow': {
      const maxAdj2 = (50000 * w) / ss;
      const a1 = pin(0, adj1, 100000);
      const a2 = pin(0, adj2, maxAdj2);
      const x2 = (ss * a2) / 100000;
      const x3 = r - x2;
      const dy = (h * a1) / 200000;
      const y1 = vc - dy;
      const y2 = vc + dy;

      const d =
        `M ${l} ${vc}` +
        `L ${x2} ${t}` +
        `L ${x2} ${y1}` +
        `L ${x3} ${y1}` +
        `L ${x3} ${t}` +
        `L ${r} ${vc}` +
        `L ${x3} ${b}` +
        `L ${x3} ${y2}` +
        `L ${x2} ${y2}` +
        `L ${x2} ${b}` +
        `z`;

      return { paths: [{ d }] };
    }
    case 'upDownArrow': {
      const maxAdj2 = (50000 * h) / ss;
      const a1 = pin(0, adj1, 100000);
      const a2 = pin(0, adj2, maxAdj2);
      const y2 = (ss * a2) / 100000;
      const y3 = b - y2;
      const dx1 = (w * a1) / 200000;
      const x1 = hc - dx1;
      const x2 = hc + dx1;

      const d =
        `M ${l} ${y2}` +
        `L ${hc} ${t}` +
        `L ${r} ${y2}` +
        `L ${x2} ${y2}` +
        `L ${x2} ${y3}` +
        `L ${r} ${y3}` +
        `L ${hc} ${b}` +
        `L ${l} ${y3}` +
        `L ${x1} ${y3}` +
        `L ${x1} ${y2}` +
        `z`;

      return { paths: [{ d }] };
    }
    case 'quadArrow': {
      const a2 = pin(0, adj2, 50000);
      const maxAdj1 = a2 * 2;
      const a1 = pin(0, adj1, maxAdj1);
      const q1 = 100000 - maxAdj1;
      const maxAdj3 = q1 / 2;
      const a3 = pin(0, adj3, maxAdj3);
      const x1 = (ss * a3) / 100000;
      const dx2 = (ss * a2) / 100000;
      const x2 = hc - dx2;
      const x5 = hc + dx2;
      const dx3 = (ss * a1) / 200000;
      const x3 = hc - dx3;
      const x4 = hc + dx3;
      const x6 = r - x1;
      const y2 = vc - dx2;
      const y5 = vc + dx2;
      const y3 = vc - dx3;
      const y4 = vc + dx3;
      const y6 = b - x1;

      const d =
        `M ${l} ${vc}` +
        `L ${x1} ${y2}` +
        `L ${x1} ${y3}` +
        `L ${x3} ${y3}` +
        `L ${x3} ${x1}` +
        `L ${x2} ${x1}` +
        `L ${hc} ${t}` +
        `L ${x5} ${x1}` +
        `L ${x4} ${x1}` +
        `L ${x4} ${y3}` +
        `L ${x6} ${y3}` +
        `L ${x6} ${y2}` +
        `L ${r} ${vc}` +
        `L ${x6} ${y5}` +
        `L ${x6} ${y4}` +
        `L ${x4} ${y4}` +
        `L ${x4} ${y6}` +
        `L ${x5} ${y6}` +
        `L ${hc} ${b}` +
        `L ${x2} ${y6}` +
        `L ${x3} ${y6}` +
        `L ${x3} ${y4}` +
        `L ${x1} ${y4}` +
        `L ${x1} ${y5}` +
        `z`;

      return { paths: [{ d }] };
    }
    case 'leftRightUpArrow': {
      const a2 = pin(0, adj2, 50000);
      const maxAdj1 = a2 * 2;
      const a1 = pin(0, adj1, maxAdj1);
      const q1 = 100000 - maxAdj1;
      const maxAdj3 = q1;
      const a3 = pin(0, adj3, maxAdj3);
      const x1 = (ss * a3) / 100000;
      const dx2 = (ss * a2) / 100000;
      const x2 = hc - dx2;
      const x5 = hc + dx2;
      const dx3 = (ss * a1) / 200000;
      const x3 = hc - dx3;
      const x4 = hc + dx3;
      const x6 = r - x1;
      const dy2 = (ss * a2) / 50000;
      const y2 = b - dy2;
      const y4 = b - dx2;
      const y3 = y4 - dx3;
      const y5 = y4 + dx3;

      const d =
        `M ${l} ${y4}` +
        `L ${x1} ${y2}` +
        `L ${x1} ${y3}` +
        `L ${x3} ${y3}` +
        `L ${x3} ${x1}` +
        `L ${x2} ${x1}` +
        `L ${hc} ${t}` +
        `L ${x5} ${x1}` +
        `L ${x4} ${x1}` +
        `L ${x4} ${y3}` +
        `L ${x6} ${y3}` +
        `L ${x6} ${y2}` +
        `L ${r} ${y4}` +
        `L ${x6} ${b}` +
        `L ${x6} ${y5}` +
        `L ${x1} ${y5}` +
        `L ${x1} ${b}` +
        `z`;

      return { paths: [{ d }] };
    }
    case 'bentArrow': {
      const a2 = pin(0, adj2, 50000);
      const maxAdj1 = a2 * 2;
      const a1 = pin(0, adj1, maxAdj1);
      const a3 = pin(0, adj3, 50000);
      const th = (ss * a1) / 100000;
      const aw2 = (ss * a2) / 100000;
      const th2 = th / 2;
      const dh2 = aw2 - th2;
      const ah = (ss * a3) / 100000;
      const bw = r - ah;
      const bh = b - dh2;
      const bs = Math.min(bw, bh);
      const maxAdj4 = (100000 * bs) / ss;
      const a4 = pin(0, adj4, maxAdj4);
      const bd = (ss * a4) / 100000;
      const bd3 = bd - th;
      const bd2 = Math.max(bd3, 0);
      const x3 = th + bd2;
      const x4 = r - ah;
      const y3 = dh2 + th;
      const y4 = y3 + dh2;
      const y5 = dh2 + bd;

      const d =
        `M ${l} ${b}` +
        `L ${l} ${y5}` +
        arcTo(bd, bd, SHAPE_CONST.cd2, SHAPE_CONST.cd4, l, y5).d +
        `L ${x4} ${dh2}` +
        `L ${x4} ${t}` +
        `L ${r} ${aw2}` +
        `L ${x4} ${y4}` +
        `L ${x4} ${y3}` +
        `L ${x3} ${y3}` +
        arcTo(bd2, bd2, SHAPE_CONST['3cd4'], -90, x3, y3).d +
        `L ${th} ${b}` +
        `z`;

      return { paths: [{ d }] };
    }
    case 'uturnArrow': {
      const a2 = pin(0, adj2, 25000);
      const maxAdj1 = a2 * 2;
      const a1 = pin(0, adj1, maxAdj1);
      const q2 = (a1 * ss) / h;
      const q3 = 100000 - q2;
      const maxAdj3 = (q3 * h) / ss;
      const a3 = pin(0, adj3, maxAdj3);
      const q1 = a3 + a1;
      const minAdj5 = (q1 * ss) / h;
      const a5 = pin(minAdj5, adj5, 100000);
      const th = (ss * a1) / 100000;
      const aw2 = (ss * a2) / 100000;
      const th2 = th / 2;
      const dh2 = aw2 - th2;
      const y5 = (h * a5) / 100000;
      const ah = (ss * a3) / 100000;
      const y4 = y5 - ah;
      const x9 = r - dh2;
      const bw = x9 / 2;
      const bs = Math.min(bw, y4);
      const maxAdj4 = (bs * 100000) / ss;
      const a4 = pin(0, adj4, maxAdj4);
      const bd = (ss * a4) / 100000;
      const bd3 = bd - th;
      const bd2 = Math.max(bd3, 0);
      const x3 = th + bd2;
      const x8 = r - aw2;
      const x6 = x8 - aw2;
      const x7 = x6 + dh2;
      const x4 = x9 - bd;

      const d =
        `M ${l} ${b}` +
        `L ${l} ${bd}` +
        arcTo(bd, bd, SHAPE_CONST.cd2, SHAPE_CONST.cd4, l, bd).d +
        `L ${x4} ${t}` +
        arcTo(bd, bd, SHAPE_CONST['3cd4'], SHAPE_CONST.cd4, x4, t).d +
        `L ${x9} ${y4}` +
        `L ${r} ${y4}` +
        `L ${x8} ${y5}` +
        `L ${x6} ${y4}` +
        `L ${x7} ${y4}` +
        `L ${x7} ${x3}` +
        arcTo(bd2, bd2, 0, -90, x7, x3).d +
        `L ${x3} ${th}` +
        arcTo(bd2, bd2, SHAPE_CONST['3cd4'], -90, x3, th).d +
        `L ${th} ${b}` +
        `z`;

      return { paths: [{ d }] };
    }
    case 'leftUpArrow': {
      const a2 = pin(0, adj2, 50000);
      const maxAdj1 = a2 * 2;
      const a1 = pin(0, adj1, maxAdj1);
      const maxAdj3 = 100000 - maxAdj1;
      const a3 = pin(0, adj3, maxAdj3);
      const x1 = (ss * a3) / 100000;
      const dx2 = (ss * a2) / 50000;
      const x2 = r - dx2;
      const y2 = b - dx2;
      const dx4 = (ss * a2) / 100000;
      const x4 = r - dx4;
      const y4 = b - dx4;
      const dx3 = (ss * a1) / 200000;
      const x3 = x4 - dx3;
      const x5 = x4 + dx3;
      const y3 = y4 - dx3;
      const y5 = y4 + dx3;

      const d =
        `M ${l} ${y4}` +
        `L ${x1} ${y2}` +
        `L ${x1} ${y3}` +
        `L ${x3} ${y3}` +
        `L ${x3} ${x1}` +
        `L ${x2} ${x1}` +
        `L ${x4} ${t}` +
        `L ${r} ${x1}` +
        `L ${x5} ${x1}` +
        `L ${x5} ${y5}` +
        `L ${x1} ${y5}` +
        `L ${x1} ${b}` +
        `z`;

      return { paths: [{ d }] };
    }

    case 'bentUpArrow': {
      const a1 = pin(0, adj1, 50000);
      const a2 = pin(0, adj2, 50000);
      const a3 = pin(0, adj3, 50000);
      const y1 = (ss * a3) / 100000;
      const dx1 = (ss * a2) / 50000;
      const x1 = r - dx1;
      const dx3 = (ss * a2) / 100000;
      const x3 = r - dx3;
      const dx2 = (ss * a1) / 200000;
      const x2 = x3 - dx2;
      const x4 = x3 + dx2;
      const dy2 = (ss * a1) / 100000;
      const y2 = b - dy2;

      const d =
        `M ${l} ${y2}` +
        `L ${x2} ${y2}` +
        `L ${x2} ${y1}` +
        `L ${x1} ${y1}` +
        `L ${x3} ${t}` +
        `L ${r} ${y1}` +
        `L ${x4} ${y1}` +
        `L ${x4} ${b}` +
        `L ${l} ${b}` +
        `z`;

      return { paths: [{ d }] };
    }
    //TODO:PRESENTATION - Improve curvedRightArrow to use arcTo instead of shapeArc
    case 'curvedRightArrow': {
      const maxAdj2 = (50000 * h) / ss;
      const a2 = adj2 < 0 ? 0 : adj2 > maxAdj2 ? maxAdj2 : adj2;
      const a1 = adj1 < 0 ? 0 : adj1 > a2 ? a2 : adj1;
      const th = (ss * a1) / 100000;
      const aw = (ss * a2) / 100000;
      const q1 = (th + aw) / 4;
      const hR = hd2 - q1;
      const q7 = hR * 2;
      const q8 = q7 * q7;
      const q9 = th * th;
      const q10 = q8 - q9;
      const q11 = Math.sqrt(q10);
      const iDx = (q11 * w) / q7;
      const maxAdj3 = (100000 * iDx) / ss;
      const a3 = adj3 < 0 ? 0 : adj3 > maxAdj3 ? maxAdj3 : adj3;
      const ah = (ss * a3) / 100000;
      const y3 = hR + th;
      const q2 = w * w;
      const q3 = ah * ah;
      const q4 = q2 - q3;
      const q5 = Math.sqrt(q4);
      const dy = (q5 * hR) / w;
      const y5 = hR + dy;
      const y7 = y3 + dy;
      const q6 = aw - th;
      const dh = q6 / 2;
      const y4 = y5 - dh;
      const y8 = y7 + dh;
      const aw2 = aw / 2;
      const y6 = b - aw2;
      const x1 = r - ah;
      const swAng = Math.atan(dy / ah);
      const stAng = Math.PI + 0 - swAng;
      const mswAng = -swAng;
      const q12 = th / 2;
      const dang2 = Math.atan(q12 / iDx);
      const swAng2 = dang2 - Math.PI / 2;

      const stAngDg = (stAng * 180) / Math.PI;
      const mswAngDg = (mswAng * 180) / Math.PI;
      const swAngDg = (swAng * 180) / Math.PI;
      const swAng2dg = (swAng2 * 180) / Math.PI;

      var d =
        `M ${l} ${hR}` +
        shapeArc(w, hR, w, hR, SHAPE_CONST.cd2, SHAPE_CONST.cd2 + mswAngDg, false).replace(
          'M',
          'L',
        ) +
        `L ${x1} ${y5}` +
        `L ${x1} ${y4}` +
        `L ${r} ${y6}` +
        `L ${x1} ${y8}` +
        `L ${x1} ${y7}` +
        shapeArc(w, y3, w, hR, stAngDg, stAngDg + swAngDg, false).replace('M', 'L') +
        `L ${l} ${hR}` +
        shapeArc(w, hR, w, hR, SHAPE_CONST.cd2, SHAPE_CONST.cd2 + SHAPE_CONST.cd4, false).replace(
          'M',
          'L',
        ) +
        `L ${r} ${th}` +
        shapeArc(w, y3, w, hR, SHAPE_CONST['3cd4'], SHAPE_CONST['3cd4'] + swAng2dg, false).replace(
          'M',
          'L',
        );

      return { paths: [{ d }] };
    }
    //TODO:PRESENTATION - Improve curvedLeftArrow to use arcTo instead of shapeArc
    case 'curvedLeftArrow': {
      const maxAdj2 = (50000 * h) / ss;
      const a2 = adj2 < 0 ? 0 : adj2 > maxAdj2 ? maxAdj2 : adj2;
      const a1 = adj1 < 0 ? 0 : adj1 > a2 ? a2 : adj1;
      const th = (ss * a1) / 100000;
      const aw = (ss * a2) / 100000;
      const q1 = (th + aw) / 4;
      const hR = hd2 - q1;
      const q7 = hR * 2;
      const q8 = q7 * q7;
      const q9 = th * th;
      const q10 = q8 - q9;
      const q11 = Math.sqrt(q10);
      const iDx = (q11 * w) / q7;
      const maxAdj3 = (100000 * iDx) / ss;
      const a3 = adj3 < 0 ? 0 : adj3 > maxAdj3 ? maxAdj3 : adj3;
      const ah = (ss * a3) / 100000;
      const y3 = hR + th;
      const q2 = w * w;
      const q3 = ah * ah;
      const q4 = q2 - q3;
      const q5 = Math.sqrt(q4);
      const dy = (q5 * hR) / w;
      const y5 = hR + dy;
      const y7 = y3 + dy;
      const q6 = aw - th;
      const dh = q6 / 2;
      const y4 = y5 - dh;
      const y8 = y7 + dh;
      const aw2 = aw / 2;
      const y6 = b - aw2;
      const x1 = l + ah;
      const swAng = Math.atan(dy / ah);
      const q12 = th / 2;
      const dang2 = Math.atan(q12 / iDx);
      const swAng2 = dang2 - swAng;

      const swAngDg = (swAng * 180) / Math.PI;
      const swAng2Dg = (swAng2 * 180) / Math.PI;

      var d =
        `M ${r} ${y3}` +
        shapeArc(l, hR, w, hR, 0, -SHAPE_CONST.cd4, false).replace('M', 'L') +
        `L ${l} ${t}` +
        shapeArc(
          l,
          y3,
          w,
          hR,
          SHAPE_CONST['3cd4'],
          SHAPE_CONST['3cd4'] + SHAPE_CONST.cd4,
          false,
        ).replace('M', 'L') +
        `L ${r} ${y3}` +
        shapeArc(l, y3, w, hR, 0, swAngDg, false).replace('M', 'L') +
        `L ${x1} ${y7}` +
        `L ${x1} ${y8}` +
        `L ${l} ${y6}` +
        `L ${x1} ${y4}` +
        `L ${x1} ${y5}` +
        shapeArc(l, hR, w, hR, swAngDg, swAngDg + swAng2Dg, false).replace('M', 'L') +
        shapeArc(l, hR, w, hR, 0, -SHAPE_CONST.cd4, false).replace('M', 'L') +
        shapeArc(
          l,
          y3,
          w,
          hR,
          SHAPE_CONST['3cd4'],
          SHAPE_CONST['3cd4'] + SHAPE_CONST.cd4,
          false,
        ).replace('M', 'L');

      return { paths: [{ d }] };
    }
    case 'curvedUpArrow': {
      const maxAdj2 = (50000 * w) / ss;
      const a2 = adj2 < 0 ? 0 : adj2 > maxAdj2 ? maxAdj2 : adj2;
      const a1 = adj1 < 0 ? 0 : adj1 > 100000 ? 100000 : adj1;
      const th = (ss * a1) / 100000;
      const aw = (ss * a2) / 100000;
      const q1 = (th + aw) / 4;
      const wR = wd2 - q1;
      const q7 = wR * 2;
      const q8 = q7 * q7;
      const q9 = th * th;
      const q10 = q8 - q9;
      const q11 = Math.sqrt(q10);
      const idy = (q11 * h) / q7;
      const ah = (ss * adj3) / 100000;
      const x3 = wR + th;
      const q2 = h * h;
      const q3 = ah * ah;
      const q4 = q2 - q3;
      const q5 = Math.sqrt(q4);
      const dx = (q5 * wR) / h;
      const x5 = wR + dx;
      const x7 = x3 + dx;
      const q6 = aw - th;
      const dh = q6 / 2;
      const x4 = x5 - dh;
      const x8 = x7 + dh;
      const aw2 = aw / 2;
      const x6 = r - aw2;
      const y1 = t + ah;
      const swAng = Math.atan(dx / ah);
      const q12 = th / 2;
      const dang2 = Math.atan(q12 / idy);
      const swAng2 = dang2 - swAng;
      const stAng3 = Math.PI / 2 - swAng;
      const stAng2 = Math.PI / 2 - dang2;

      const stAng2dg = (stAng2 * 180) / Math.PI;
      const swAng2dg = (swAng2 * 180) / Math.PI;
      const stAng3dg = (stAng3 * 180) / Math.PI;
      const swAngDg = (swAng * 180) / Math.PI;

      var d =
        shapeArc(wR, 0, wR, h, stAng2dg, stAng2dg + swAng2dg, false) +
        `L ${x5} ${y1}` +
        `L ${x4} ${y1}` +
        `L ${x6} ${t}` +
        `L ${x8} ${y1}` +
        `L ${x7} ${y1}` +
        shapeArc(x3, 0, wR, h, stAng3dg, stAng3dg + swAngDg, false).replace('M', 'L') +
        `L ${wR} ${b}` +
        shapeArc(wR, 0, wR, h, SHAPE_CONST.cd4, SHAPE_CONST.cd2, false).replace('M', 'L') +
        `L ${th} ${t}` +
        shapeArc(x3, 0, wR, h, SHAPE_CONST.cd2, SHAPE_CONST.cd4, false).replace('M', 'L') +
        'Z';

      return { paths: [{ d }] };
    }
    case 'curvedDownArrow': {
      const maxAdj2 = (50000 * w) / ss;
      const a2 = adj2 < 0 ? 0 : adj2 > maxAdj2 ? maxAdj2 : adj2;
      const a1 = adj1 < 0 ? 0 : adj1 > 100000 ? 100000 : adj1;
      const th = (ss * a1) / 100000;
      const aw = (ss * a2) / 100000;
      const q1 = (th + aw) / 4;
      const wR = wd2 - q1;
      const q7 = wR * 2;
      const q8 = q7 * q7;
      const q9 = th * th;
      const q10 = q8 - q9;
      const q11 = Math.sqrt(q10);
      const idy = (q11 * h) / q7;
      const ah = (ss * adj3) / 100000;
      const x3 = wR + th;
      const q2 = h * h;
      const q3 = ah * ah;
      const q4 = q2 - q3;
      const q5 = Math.sqrt(q4);
      const dx = (q5 * wR) / h;
      const x5 = wR + dx;
      const x7 = x3 + dx;
      const q6 = aw - th;
      const dh = q6 / 2;
      const x4 = x5 - dh;
      const x8 = x7 + dh;
      const aw2 = aw / 2;
      const x6 = r - aw2;
      const y1 = b - ah;
      const swAng = Math.atan(dx / ah);
      const swAngDeg = (swAng * 180) / Math.PI;
      const mswAng = -swAngDeg;
      const q12 = th / 2;
      const dang2 = Math.atan(q12 / idy);
      const dang2Deg = (dang2 * 180) / Math.PI;
      const stAng = SHAPE_CONST['3cd4'] + swAngDeg;
      const stAng2 = SHAPE_CONST['3cd4'] - dang2Deg;
      const swAng2 = dang2Deg - SHAPE_CONST.cd4;
      const swAng3 = SHAPE_CONST.cd4 + dang2Deg;

      var d =
        `M ${x6} ${b}` +
        `L ${x4} ${y1}` +
        `L ${x5} ${y1}` +
        shapeArc(wR, h, wR, h, stAng, stAng + mswAng, false).replace('M', 'L') +
        `L ${x3} ${t}` +
        shapeArc(x3, h, wR, h, SHAPE_CONST['3cd4'], SHAPE_CONST['3cd4'] + swAngDeg, false).replace(
          'M',
          'L',
        ) +
        `L ${x5 + th} ${y1}` +
        `L ${x8} ${y1}` +
        ' z' +
        `M ${x3} ${t}` +
        shapeArc(x3, h, wR, h, stAng2, stAng2 + swAng2, false).replace('M', 'L') +
        shapeArc(wR, h, wR, h, SHAPE_CONST.cd2, SHAPE_CONST.cd2 + swAng3, false).replace('M', 'L');

      return { paths: [{ d }] };
    }
    case 'stripedRightArrow': {
      const maxAdj2 = (84375 * w) / ss;
      const a1 = pin(0, adj1, 100000);
      const a2 = pin(0, adj2, maxAdj2);
      const x4 = (ss * 5) / 32;
      const dx5 = (ss * a2) / 100000;
      const x5 = r - dx5;
      const dy1 = (h * a1) / 200000;
      const y1 = vc - dy1;
      const y2 = vc + dy1;

      const d =
        `M ${l} ${y1}` +
        `L ${ss / 32} ${y1}` +
        `L ${ss / 32} ${y2}` +
        `L ${l} ${y2}` +
        `Z` +
        `M ${ss / 16} ${y1}` +
        `L ${ss / 8} ${y1}` +
        `L ${ss / 8} ${y2}` +
        `L ${ss / 16} ${y2}` +
        `Z` +
        `M ${x4} ${y1}` +
        `L ${x5} ${y1}` +
        `L ${x5} ${0}` +
        `L ${r} ${vc}` +
        `L ${x5} ${h}` +
        `L ${x5} ${y2}` +
        `L ${x4} ${y2}` +
        `Z`;

      return { paths: [{ d }] };
    }
    case 'notchedRightArrow': {
      const maxAdj2 = (100000 * w) / ss;
      const a1 = pin(0, adj1, 100000);
      const a2 = pin(0, adj2, maxAdj2);
      const dx2 = (ss * a2) / 100000;
      const x2 = r - dx2;
      const dy1 = (h * a1) / 200000;
      const y1 = vc - dy1;
      const y2 = vc + dy1;
      const x1 = (dy1 * dx2) / hd2;

      const d =
        `M ${l} ${y1}` +
        `L ${x2} ${y1}` +
        `L ${x2} ${t}` +
        `L ${r} ${vc}` +
        `L ${x2} ${b}` +
        `L ${x2} ${y2}` +
        `L ${l} ${y2}` +
        `L ${x1} ${vc}` +
        `Z`;

      return { paths: [{ d }] };
    }
    case 'homePlate': {
      const maxAdj = (100000 * w) / ss;
      const a = pin(0, adj1, maxAdj);
      const dx1 = (ss * a) / 100000;
      const x1 = r - dx1;

      const d = `M ${l} ${t} L ${x1} ${t} L ${r} ${vc} L ${x1} ${b} L ${l} ${b} Z`;

      return { paths: [{ d }] };
    }
    case 'chevron': {
      const maxAdj = (100000 * w) / ss;
      const a = pin(0, adj1, maxAdj);
      const x1 = (ss * a) / 100000;
      const x2 = r - x1;

      const d =
        `M ${l} ${t}` +
        `L ${x2} ${t}` +
        `L ${r} ${vc}` +
        `L ${x2} ${b}` +
        `L ${l} ${b}` +
        `L ${x1} ${vc}` +
        `Z`;

      return { paths: [{ d }] };
    }
    case 'rightArrowCallout': {
      const maxAdj2 = (50000 * h) / ss;
      const a2 = pin(0, adj2, maxAdj2);
      const maxAdj1 = a2 * 2;
      const a1 = pin(0, adj1, maxAdj1);
      const maxAdj3 = (100000 * w) / ss;
      const a3 = pin(0, adj3, maxAdj3);
      const q2 = (a3 * ss) / w;
      const maxAdj4 = 100000 - q2;
      const a4 = pin(0, adj4, maxAdj4);
      const dy1 = (ss * a2) / 100000;
      const dy2 = (ss * a1) / 200000;
      const y1 = vc - dy1;
      const y2 = vc - dy2;
      const y3 = vc + dy2;
      const y4 = vc + dy1;
      const dx3 = (ss * a3) / 100000;
      const x3 = r - dx3;
      const x2 = (w * a4) / 100000;

      const d =
        `M ${l} ${t}` +
        `L ${x2} ${t}` +
        `L ${x2} ${y2}` +
        `L ${x3} ${y2}` +
        `L ${x3} ${y1}` +
        `L ${r} ${vc}` +
        `L ${x3} ${y4}` +
        `L ${x3} ${y3}` +
        `L ${x2} ${y3}` +
        `L ${x2} ${b}` +
        `L ${l} ${b}` +
        `Z`;

      return { paths: [{ d }] };
    }
    case 'downArrowCallout': {
      const maxAdj2 = (50000 * w) / ss;
      const a2 = pin(0, adj2, maxAdj2);
      const maxAdj1 = a2 * 2;
      const a1 = pin(0, adj1, maxAdj1);
      const maxAdj3 = (100000 * h) / ss;
      const a3 = pin(0, adj3, maxAdj3);
      const q2 = (a3 * ss) / h;
      const maxAdj4 = 100000 - q2;
      const a4 = pin(0, adj4, maxAdj4);
      const dx1 = (ss * a2) / 100000;
      const dx2 = (ss * a1) / 200000;
      const x1 = hc - dx1;
      const x2 = hc - dx2;
      const x3 = hc + dx2;
      const x4 = hc + dx1;
      const dy3 = (ss * a3) / 100000;
      const y3 = b - dy3;
      const y2 = (h * a4) / 100000;

      const d =
        `M ${l} ${t}` +
        `L ${r} ${t}` +
        `L ${r} ${y2}` +
        `L ${x3} ${y2}` +
        `L ${x3} ${y3}` +
        `L ${x4} ${y3}` +
        `L ${hc} ${b}` +
        `L ${x1} ${y3}` +
        `L ${x2} ${y3}` +
        `L ${x2} ${y2}` +
        `L ${l} ${y2}` +
        `Z`;

      return { paths: [{ d }] };
    }
    case 'leftArrowCallout': {
      const maxAdj2 = (50000 * h) / ss;
      const a2 = pin(0, adj2, maxAdj2);
      const maxAdj1 = a2 * 2;
      const a1 = pin(0, adj1, maxAdj1);
      const maxAdj3 = (100000 * w) / ss;
      const a3 = pin(0, adj3, maxAdj3);
      const q2 = (a3 * ss) / w;
      const maxAdj4 = 100000 - q2;
      const a4 = pin(0, adj4, maxAdj4);
      const dy1 = (ss * a2) / 100000;
      const dy2 = (ss * a1) / 200000;
      const y1 = vc - dy1;
      const y2 = vc - dy2;
      const y3 = vc + dy2;
      const y4 = vc + dy1;
      const x1 = (ss * a3) / 100000;
      const dx2 = (w * a4) / 100000;
      const x2 = r - dx2;

      const d =
        `M ${l} ${vc}` +
        `L ${x1} ${y1}` +
        `L ${x1} ${y2}` +
        `L ${x2} ${y2}` +
        `L ${x2} ${t}` +
        `L ${r} ${t}` +
        `L ${r} ${b}` +
        `L ${x2} ${b}` +
        `L ${x2} ${y3}` +
        `L ${x1} ${y3}` +
        `L ${x1} ${y4}` +
        `Z`;

      return { paths: [{ d }] };
    }
    case 'upArrowCallout': {
      const maxAdj2 = (50000 * w) / ss;
      const a2 = pin(0, adj2, maxAdj2);
      const maxAdj1 = a2 * 2;
      const a1 = pin(0, adj1, maxAdj1);
      const maxAdj3 = (100000 * h) / ss;
      const a3 = pin(0, adj3, maxAdj3);
      const q2 = (a3 * ss) / h;
      const maxAdj4 = 100000 - q2;
      const a4 = pin(0, adj4, maxAdj4);
      const dx1 = (ss * a2) / 100000;
      const dx2 = (ss * a1) / 200000;
      const x1 = hc - dx1;
      const x2 = hc - dx2;
      const x3 = hc + dx2;
      const x4 = hc + dx1;
      const y1 = (ss * a3) / 100000;
      const dy2 = (h * a4) / 100000;
      const y2 = b - dy2;

      const d =
        `M ${l} ${y2}` +
        `L ${x2} ${y2}` +
        `L ${x2} ${y1}` +
        `L ${x1} ${y1}` +
        `L ${hc} ${t}` +
        `L ${x4} ${y1}` +
        `L ${x3} ${y1}` +
        `L ${x3} ${y2}` +
        `L ${r} ${y2}` +
        `L ${r} ${b}` +
        `L ${l} ${b}` +
        `Z`;

      return { paths: [{ d }] };
    }
    case 'leftRightArrowCallout': {
      const maxAdj2 = (50000 * h) / ss;
      const a2 = pin(0, adj2, maxAdj2);
      const maxAdj1 = a2 * 2;
      const a1 = pin(0, adj1, maxAdj1);
      const maxAdj3 = (50000 * w) / ss;
      const a3 = pin(0, adj3, maxAdj3);
      const q2 = (a3 * ss) / wd2;
      const maxAdj4 = 100000 - q2;
      const a4 = pin(0, adj4, maxAdj4);
      const dy1 = (ss * a2) / 100000;
      const dy2 = (ss * a1) / 200000;
      const y1 = vc - dy1;
      const y2 = vc - dy2;
      const y3 = vc + dy2;
      const y4 = vc + dy1;
      const x1 = (ss * a3) / 100000;
      const x4 = r - x1;
      const dx2 = (w * a4) / 200000;
      const x2 = hc - dx2;
      const x3 = hc + dx2;

      const d =
        `M ${l} ${vc}` +
        `L ${x1} ${y1}` +
        `L ${x1} ${y2}` +
        `L ${x2} ${y2}` +
        `L ${x2} ${t}` +
        `L ${x3} ${t}` +
        `L ${x3} ${y2}` +
        `L ${x4} ${y2}` +
        `L ${x4} ${y1}` +
        `L ${r} ${vc}` +
        `L ${x4} ${y4}` +
        `L ${x4} ${y3}` +
        `L ${x3} ${y3}` +
        `L ${x3} ${b}` +
        `L ${x2} ${b}` +
        `L ${x2} ${y3}` +
        `L ${x1} ${y3}` +
        `L ${x1} ${y4}` +
        `Z`;

      return { paths: [{ d }] };
    }
    case 'quadArrowCallout': {
      const a2 = pin(0, adj2, 50000);
      const maxAdj1 = a2 * 2;
      const a1 = pin(0, adj1, maxAdj1);
      const maxAdj3 = 50000 - a2;
      const a3 = pin(0, adj3, maxAdj3);
      const q2 = a3 * 2;
      const maxAdj4 = 100000 - q2;
      const a4 = pin(a1, adj4, maxAdj4);
      const dx2 = (ss * a2) / 100000;
      const dx3 = (ss * a1) / 200000;
      const ah = (ss * a3) / 100000;
      const dx1 = (w * a4) / 200000;
      const dy1 = (h * a4) / 200000;
      const x8 = r - ah;
      const x2 = hc - dx1;
      const x7 = hc + dx1;
      const x3 = hc - dx2;
      const x6 = hc + dx2;
      const x4 = hc - dx3;
      const x5 = hc + dx3;
      const y8 = b - ah;
      const y2 = vc - dy1;
      const y7 = vc + dy1;
      const y3 = vc - dx2;
      const y6 = vc + dx2;
      const y4 = vc - dx3;
      const y5 = vc + dx3;

      const d =
        `M ${l} ${vc}` +
        `L ${ah} ${y3}` +
        `L ${ah} ${y4}` +
        `L ${x2} ${y4}` +
        `L ${x2} ${y2}` +
        `L ${x4} ${y2}` +
        `L ${x4} ${ah}` +
        `L ${x3} ${ah}` +
        `L ${hc} ${t}` +
        `L ${x6} ${ah}` +
        `L ${x5} ${ah}` +
        `L ${x5} ${y2}` +
        `L ${x7} ${y2}` +
        `L ${x7} ${y4}` +
        `L ${x8} ${y4}` +
        `L ${x8} ${y3}` +
        `L ${r} ${vc}` +
        `L ${x8} ${y6}` +
        `L ${x8} ${y5}` +
        `L ${x7} ${y5}` +
        `L ${x7} ${y7}` +
        `L ${x5} ${y7}` +
        `L ${x5} ${y8}` +
        `L ${x6} ${y8}` +
        `L ${hc} ${b}` +
        `L ${x3} ${y8}` +
        `L ${x4} ${y8}` +
        `L ${x4} ${y7}` +
        `L ${x2} ${y7}` +
        `L ${x2} ${y5}` +
        `L ${ah} ${y5}` +
        `L ${ah} ${y6}` +
        `Z`;

      return { paths: [{ d }] };
    }
    //TODO:PRESENTATION - Improve circularArrow to use arcTo instead of shapeArc
    case 'circularArrow': {
      const cnstVal1 = 25000;
      const cnstVal2 = 100000;
      const rdAngVal1 = ((1 / 60000) * Math.PI) / 180;
      const rdAngVal2 = ((21599999 / 60000) * Math.PI) / 180;
      const rdAngVal3 = 2 * Math.PI;
      const a5 = adj5 < 0 ? 0 : adj5 > cnstVal1 ? cnstVal1 : adj5;
      const maxAdj1 = a5 * 2;
      const a1 = adj1 < 0 ? 0 : adj1 > maxAdj1 ? maxAdj1 : adj1;
      const enAng = adj3 < rdAngVal1 ? rdAngVal1 : adj3 > rdAngVal2 ? rdAngVal2 : adj3;
      const stAng = adj4 < 0 ? 0 : adj4 > rdAngVal2 ? rdAngVal2 : adj4;
      const th = (ss * a1) / cnstVal2;
      const thh = (ss * a5) / cnstVal2;
      const th2 = th / 2;
      const rw1 = wd2 + th2 - thh;
      const rh1 = hd2 + th2 - thh;
      const rw2 = rw1 - th;
      const rh2 = rh1 - th;
      const rw3 = rw2 + th2;
      const rh3 = rh2 + th2;
      const wtH = rw3 * Math.sin(enAng);
      const htH = rh3 * Math.cos(enAng);
      const dxH = rw3 * Math.cos(Math.atan(htH / wtH));
      const dyH = rh3 * Math.sin(Math.atan(htH / wtH));
      const xH = hc + dxH;
      const yH = vc + dyH;
      const rI = rw2 < rh2 ? rw2 : rh2;
      const u1 = dxH * dxH;
      const u2 = dyH * dyH;
      const u3 = rI * rI;
      const u4 = u1 - u3;
      const u5 = u2 - u3;
      const u6 = (u4 * u5) / u1;
      const u7 = u6 / u2;
      const u8 = 1 - u7;
      const u9 = Math.sqrt(u8);
      const u10 = u4 / dxH;
      const u11 = u10 / dyH;
      const u12 = (1 + u9) / u11;
      const u13 = Math.atan(1 / u12);
      const u14 = u13 + rdAngVal3;
      const u15 = u13 > 0 ? u13 : u14;
      const u16 = u15 - enAng;
      const u17 = u16 + rdAngVal3;
      const u18 = u16 > 0 ? u16 : u17;
      const u19 = u18 - SHAPE_CONST.cd2;
      const u20 = u18 - rdAngVal3;
      const u21 = u19 > 0 ? u20 : u18;
      const maxAng = Math.abs(u21);
      const aAng = adj2 < 0 ? 0 : adj2 > maxAng ? maxAng : adj2;
      const ptAng = enAng + aAng;
      const wtA = rw3 * Math.sin(ptAng);
      const htA = rh3 * Math.cos(ptAng);
      const dxA = rw3 * Math.cos(Math.atan(htA / wtA));
      const dyA = rh3 * Math.sin(Math.atan(htA / wtA));
      const xA = hc + dxA;
      const yA = vc + dyA;
      const dxG = thh * Math.cos(ptAng);
      const dyG = thh * Math.sin(ptAng);
      const xG = xH + dxG;
      const yG = yH + dyG;
      const dxB = thh * Math.cos(ptAng);
      const dyB = thh * Math.sin(ptAng);
      const xB = xH - dxB;
      const yB = yH - dyB;
      const sx1 = xB - hc;
      const sy1 = yB - vc;
      const sx2 = xG - hc;
      const sy2 = yG - vc;
      const rO = rw1 < rh1 ? rw1 : rh1;
      const x1O = (sx1 * rO) / rw1;
      const y1O = (sy1 * rO) / rh1;
      const x2O = (sx2 * rO) / rw1;
      const y2O = (sy2 * rO) / rh1;
      const dxO = x2O - x1O;
      const dyO = y2O - y1O;
      const dO = Math.sqrt(dxO * dxO + dyO * dyO);
      const q1 = x1O * y2O;
      const q2 = x2O * y1O;
      const DO = q1 - q2;
      const q3 = rO * rO;
      const q4 = dO * dO;
      const q5 = q3 * q4;
      const q6 = DO * DO;
      const q7 = q5 - q6;
      const q8 = q7 > 0 ? q7 : 0;
      const sdelO = Math.sqrt(q8);
      const ndyO = dyO * -1;
      const sdyO = ndyO > 0 ? -1 : 1;
      const q9 = sdyO * dxO;
      const q10 = q9 * sdelO;
      const q11 = DO * dyO;
      const dxF1 = (q11 + q10) / q4;
      const q12 = q11 - q10;
      const dxF2 = q12 / q4;
      const adyO = Math.abs(dyO);
      const q13 = adyO * sdelO;
      const q14 = (DO * dxO) / -1;
      const dyF1 = (q14 + q13) / q4;
      const q15 = q14 - q13;
      const dyF2 = q15 / q4;
      const q16 = x2O - dxF1;
      const q17 = x2O - dxF2;
      const q18 = y2O - dyF1;
      const q19 = y2O - dyF2;
      const q20 = Math.sqrt(q16 * q16 + q18 * q18);
      const q21 = Math.sqrt(q17 * q17 + q19 * q19);
      const q22 = q21 - q20;
      const dxF = q22 > 0 ? dxF1 : dxF2;
      const dyF = q22 > 0 ? dyF1 : dyF2;
      const sdxF = (dxF * rw1) / rO;
      const sdyF = (dyF * rh1) / rO;
      const xF = hc + sdxF;
      const yF = vc + sdyF;
      const x1I = (sx1 * rI) / rw2;
      const y1I = (sy1 * rI) / rh2;
      const x2I = (sx2 * rI) / rw2;
      const y2I = (sy2 * rI) / rh2;
      const dxI = x2I - x1I;
      const dyI = y2I - y1I;
      const dI = Math.sqrt(dxI * dxI + dyI * dyI);
      const v1 = x1I * y2I;
      const v2 = x2I * y1I;
      const DI = v1 - v2;
      const v3 = rI * rI;
      const v4 = dI * dI;
      const v5 = v3 * v4;
      const v6 = DI * DI;
      const v7 = v5 - v6;
      const v8 = v7 > 0 ? v7 : 0;
      const sdelI = Math.sqrt(v8);
      const v9 = sdyO * dxI;
      const v10 = v9 * sdelI;
      const v11 = DI * dyI;
      const dxC1 = (v11 + v10) / v4;
      const v12 = v11 - v10;
      const dxC2 = v12 / v4;
      const adyI = Math.abs(dyI);
      const v13 = adyI * sdelI;
      const v14 = (DI * dxI) / -1;
      const dyC1 = (v14 + v13) / v4;
      const v15 = v14 - v13;
      const dyC2 = v15 / v4;
      const v16 = x1I - dxC1;
      const v17 = x1I - dxC2;
      const v18 = y1I - dyC1;
      const v19 = y1I - dyC2;
      const v20 = Math.sqrt(v16 * v16 + v18 * v18);
      const v21 = Math.sqrt(v17 * v17 + v19 * v19);
      const v22 = v21 - v20;
      const dxC = v22 > 0 ? dxC1 : dxC2;
      const dyC = v22 > 0 ? dyC1 : dyC2;
      const sdxC = (dxC * rw2) / rI;
      const sdyC = (dyC * rh2) / rI;
      const xC = hc + sdxC;
      const yC = vc + sdyC;
      const ist0 = Math.atan(sdxC / sdyC);
      const ist1 = ist0 + rdAngVal3;
      const istAng = ist0 > 0 ? ist0 : ist1;
      const isw1 = stAng - istAng;
      const isw2 = isw1 - rdAngVal3;
      const iswAng = isw1 > 0 ? isw2 : isw1;
      const p1 = xF - xC;
      const p2 = yF - yC;
      const p3 = Math.sqrt(p1 * p1 + p2 * p2);
      const p4 = p3 / 2;
      const p5 = p4 - thh;
      const xGp = p5 > 0 ? xF : xG;
      const yGp = p5 > 0 ? yF : yG;
      const xBp = p5 > 0 ? xC : xB;
      const yBp = p5 > 0 ? yC : yB;
      const en0 = Math.atan(sdxF / sdyF);
      const en1 = en0 + rdAngVal3;
      const en2 = en0 > 0 ? en0 : en1;
      const sw0 = en2 - stAng;
      const sw1 = sw0 + rdAngVal3;
      const swAng = sw0 > 0 ? sw0 : sw1;
      const strtAng = (stAng * 180) / Math.PI;
      const endAng = strtAng + (swAng * 180) / Math.PI;
      const stiAng = (istAng * 180) / Math.PI;
      const swiAng = (iswAng * 180) / Math.PI;
      const ediAng = stiAng + swiAng;

      const d =
        shapeArc(w / 2, h / 2, rw1, rh1, strtAng, endAng, false) +
        `L ${xGp} ${yGp}` +
        `L ${xA} ${yA}` +
        `L ${xBp} ${yBp}` +
        `L ${xC} ${yC}` +
        shapeArc(w / 2, h / 2, rw2, rh2, stiAng, ediAng, false).replace('M', 'L') +
        ' z';

      return { paths: [{ d }] };
    }
  }
};

export default generateBlockArrowPath;
