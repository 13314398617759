import { useMemo } from 'react';
import { useSlideData } from '../../../SlideData';
import { TEXT_DECORATION_MAP } from 'Presentation/consts';
import useChartColor from './useChartColor';
import useOutline, { parseOutline } from '../../useOutline';
import { useChartData } from '../ChartData';
import { convertValueToDate } from '../ChartBase/ChartBase';

const useChartAxis = (shape: Presentation.Data.ChartShape) => {
  const { color, getFontFamily } = useSlideData();
  const { chartShape } = useChartData();
  const { chartColor } = useChartColor();
  const plotArea = shape.chartSpace.chart.plotArea;
  const chartTypeData = plotArea.chartTypes[0];
  //@ts-expect-error
  const stack100Percentage = chartTypeData?.grouping === 'percentStacked';

  const outlineYAxisTitle = useOutline(plotArea.chartAxes[1].title?.properties?.ln);
  const outlineXAxisTitle = useOutline(plotArea.chartAxes[0].title?.properties?.ln);

  const titleFontFamily = useMemo(() => {
    return getFontFamily({ font: chartShape.chartStyle.axisTitle?.fontRef });
  }, [chartShape]);

  const valueFontFamily = useMemo(() => {
    return getFontFamily({ font: chartShape.chartStyle.valueAxis?.fontRef });
  }, [chartShape]);

  const axisPosition = (position: 'b' | 'l' | 'r' | 't') => {
    switch (position) {
      case 'b': {
        return 'bottom';
      }
      case 'l': {
        return 'left';
      }
      case 'r': {
        return 'right';
      }
      case 't': {
        return 'top';
      }
    }
  };

  const axisType = (type: 'cat' | 'val' | 'date' | 'ser') => {
    switch (type) {
      case 'cat': {
        return 'category';
      }
      case 'val': {
        return 'value';
      }
      case 'date': {
        return 'time';
      }
    }
  };

  const nameLocation = (location: 'ctr' | 'b' | 't' | 'dist' | 'just') => {
    switch (location) {
      case 'ctr':
        return 'middle';
      case 'b':
        return 'end';
      case 't':
        return 'start';
      default:
        return 'middle';
    }
  };

  const axisTitleStyles = (
    properties: Presentation.Data.InlineProperties | undefined,
    axis: 'x' | 'y',
  ) => {
    const textColor = chartColor(properties?.fill);
    const textDecorationStyles = properties?.u ? TEXT_DECORATION_MAP[properties.u] : {};
    const axisTitleBorder = (
      outline: Partial<
        Pick<
          React.SVGAttributes<SVGPathElement>,
          | 'stroke'
          | 'strokeDasharray'
          | 'strokeDashoffset'
          | 'strokeLinecap'
          | 'strokeLinejoin'
          | 'strokeOpacity'
          | 'strokeWidth'
          | 'strokeMiterlimit'
        >
      >,
    ) => {
      return {
        borderColor: outline.stroke,
        borderWidth: outline.strokeWidth,
        borderDashOffset: outline.strokeDashoffset,
        //@ts-expect-error
        borderType: outline.strokeDasharray?.split(',').map((value) => +value),
      };
    };

    return {
      fontFamily: getFontFamily({ font: properties?.font }) ?? titleFontFamily,
      fontSize: properties?.size,
      fontWeight: properties?.b ? 'bold' : undefined,
      fontStyle: properties?.i ? 'italic' : undefined,
      //@ts-expect-error
      textBorderColor: properties?.ln?.fill?.color ? color(properties?.ln?.fill?.color) : undefined,
      textBorderWidth: properties?.ln?.w,
      color: textColor,
      ...axisTitleBorder(axis === 'x' ? outlineXAxisTitle : outlineYAxisTitle),
      ...textDecorationStyles,
    };
  };

  const outlineStyles = (outline: Presentation.Data.Outline | undefined) => {
    const values = parseOutline(outline, color);
    if (outline) {
      return {
        color: values.stroke,
        width: values.strokeWidth,
        cap: values.strokeLinecap,
        type: values.strokeDasharray?.split(',').map((value) => +value),
        join: values.strokeLinejoin,
        miterLimit: values.strokeMiterlimit,
      };
    }
  };

  const outlineBorderStyles = (outlineBorder: Presentation.Data.Outline | undefined) => {
    const values = parseOutline(outlineBorder, color);
    if (outlineBorder) {
      return {
        textBorderColor: values.stroke,
        textBorderWidth: values.strokeWidth,
        textBorderType: values.strokeDasharray?.split(',').map((value) => +value),
      };
    }
  };

  const defaultAxisValues = (axis: Presentation.Data.ChartAxesProperties, value: 'x' | 'y') => {
    const showAxisLine = axis?.majorGridlines ? true : false;
    const showAxisMinorGridline = axis?.minorGridlines ? true : false;
    const showAxisTick = axis?.majorTickMark !== 'none';
    const showAxisMinorTick = axis?.minorTickMark !== 'none';
    const showAxisLabel = axis?.delete;
    const axisTitle = axis?.title?.text?.childNodes?.[0]?.childNodes?.map((p) => p.content);
    const nameRotate = axis?.title?.text?.bodyPr?.rot ? -axis.title?.text?.bodyPr?.rot : 0;
    const labelRotate =
      axis?.text?.bodyPr?.rot && axis?.text?.bodyPr?.rot !== -1000 ? -axis?.text?.bodyPr?.rot : 0;
    const location = axis?.text?.bodyPr?.anchor ?? 'ctr';

    const symbol = (type: 'triangle' | 'arrow' | 'diamond' | 'oval') => {
      switch (type) {
        case 'oval':
          return 'circle';
        case 'arrow':
          return 'arrow';
        case 'triangle':
          return 'triangle';
        case 'diamond':
          return 'diamond';
        default:
          return 'none';
      }
    };

    const handleSymbolSize = (ln: Presentation.Data.Outline | undefined) => {
      if (ln && ln?.headEnd) {
        const w = ln?.w ?? 1;
        const smValue = w === 1 ? 5 : 2 * w;
        const medValue = w === 1 ? 7 : 3 * w;
        const lgValue1 = w === 1 ? 12 : 4 * w;
        if (ln?.headEnd?.w === 'sm' && ln?.headEnd?.len === 'sm') {
          return [smValue, smValue];
        } else if (ln?.headEnd?.w === 'med' && ln?.headEnd?.len === 'med') {
          return [medValue, medValue];
        } else if (ln?.headEnd?.w === 'lg' && ln?.headEnd?.len === 'lg') {
          return [lgValue1, lgValue1];
        } else if (ln?.headEnd?.w === 'sm' && ln?.headEnd?.len === 'med') {
          return [smValue, medValue];
        } else if (ln?.headEnd?.w === 'sm' && ln?.headEnd?.len === 'lg') {
          return [smValue, lgValue1];
        } else if (ln?.headEnd?.w === 'med' && ln?.headEnd?.len === 'sm') {
          return [medValue, smValue];
        } else if (ln?.headEnd?.w === 'med' && ln?.headEnd?.len === 'lg') {
          return [medValue, lgValue1];
        } else if (ln?.headEnd?.w === 'lg' && ln?.headEnd?.len === 'sm') {
          return [lgValue1, smValue];
        } else if (ln?.headEnd?.w === 'lg' && ln?.headEnd?.len === 'med') {
          return [lgValue1, medValue];
        }
      } else return [];
    };

    const handleLineSymbol = () => {
      if (
        axis.properties?.ln &&
        axis.properties?.ln?.headEnd?.type &&
        axis.properties?.ln?.tailEnd?.type
      ) {
        return [
          symbol(axis.properties?.ln?.headEnd?.type),
          symbol(axis.properties?.ln?.tailEnd?.type),
        ];
      } else if (axis.properties?.ln && axis.properties?.ln?.headEnd?.type) {
        return symbol(axis.properties?.ln?.headEnd?.type);
      } else if (axis.properties?.ln && axis.properties?.ln?.tailEnd?.type) {
        return symbol(axis.properties?.ln.tailEnd?.type);
      } else {
        return 'none';
      }
    };

    const handleLabelInterval = () => {
      const tickSkip = axis?.tickLblSkip;
      if (tickSkip) {
        return tickSkip > 1 ? tickSkip - 1 : tickSkip;
      }
    };

    const handleFormatter = (value: string) => {
      //@ts-expect-error
      if (shape.chartSpace.chart.plotArea.chartTypes[0].barDir === 'bar') {
        return stack100Percentage && value !== 'y' ? '{value}%' : '{value}'
      } else if (value === 'y') {
        return stack100Percentage ? '{value}%' : '{value}'
      }
    }

    return {
      name: axisTitle?.join(' ') ?? '',
      nameLocation: nameLocation(location),
      nameTextStyle: {
        ...axisTitleStyles(axis?.title?.text?.childNodes?.[0]?.properties?.inlineProperties, value),
        backgroundColor: chartColor(axis?.title?.properties?.fill),
      },
      nameRotate: nameRotate,
      nameGap: 25,
      inverse: axis.scaling.orientation === 'maxMin' ? true : false,
      axisLine: {
        show: chartColor(axis?.properties?.ln?.fill) ? true : false,
        lineStyle: {
          ...outlineStyles(axis?.properties?.ln),
          color: chartColor(axis?.properties?.ln?.fill),
        },
        symbol: handleLineSymbol(),
        symbolSize: handleSymbolSize(axis?.properties?.ln),
        symbolOffset: [0, 2],
        z: value === 'y' ? 20 : 0,
      },
      axisTick: {
        show: showAxisTick,
        inside: axis.majorTickMark === 'in',
        interval: axis.tickMarkSkip,
        lineStyle: {
          ...outlineStyles(axis?.properties?.ln),
        },
      },
      minorTick: {
        show: showAxisMinorTick,
        lineStyle: {
          ...outlineStyles(axis?.properties?.ln),
        },
      },
      axisLabel: {
        show: !showAxisLabel,
        interval: handleLabelInterval(),
        rotate: labelRotate,
        color: chartColor(axis?.text?.childNodes?.[0]?.properties?.inlineProperties?.fill),
        width: value === 'x' ? 60 : 10,
        height: axis?.text?.childNodes?.[0]?.properties?.inlineProperties?.size,
        backgroundColor: chartColor(axis?.properties?.fill),
        formatter: handleFormatter(value),
        ...outlineBorderStyles(axis?.text?.childNodes?.[0]?.properties?.inlineProperties?.ln),
        textBorderColor: chartColor(
          axis?.text?.childNodes?.[0]?.properties?.inlineProperties?.ln?.fill,
        ),
        fontFamily:
          getFontFamily({
            font: axis?.text?.childNodes?.[0]?.properties?.inlineProperties?.latin?.font,
          }) ?? valueFontFamily,
      },
      splitLine: {
        show: showAxisLine,
        lineStyle: {
          ...outlineStyles(axis?.majorGridlines?.properties?.ln),
          color: chartColor(axis?.majorGridlines?.properties?.ln?.fill),
        },
      },
      minorSplitLine: {
        show: showAxisMinorGridline,
        lineStyle: {
          ...outlineStyles(axis?.minorGridlines?.properties?.ln),
          color: chartColor(axis?.minorGridlines?.properties?.ln?.fill),
        },
      },
    };
  };

  const xAxis = useMemo<echarts.EChartsOption['xAxis']>(() => {
    const { chartAxes } = plotArea;
    const axis = chartAxes.find((axis) => axis.axPos === 'b' || axis.axPos === 't');

    if (!axis) {
      return undefined;
    }
    const type = axisType(axis.type);

    //@ts-expect-error
    const xAxis: echarts.EChartsOption['xAxis'] = {
      ...defaultAxisValues(axis, 'x'),
      position: axisPosition(axis.axPos),
      //@ts-expect-error
      type: shape.chartSpace.chart.plotArea.chartTypes[0].barDir === 'bar' ? 'value' : type,
      max: axis.scaling.max,
      min: axis.scaling.min,
    };

    //@ts-expect-error
    if (xAxis?.type === 'category') {
      const data =
        //@ts-expect-error
        chartTypeData.ser[0].cat?.strRef?.strCache ?? chartTypeData.ser[0].cat?.numRef?.numCache;
      //@ts-expect-error
      xAxis.data = data?.pt?.map((el) => el.v); //@ts-expect-error
      xAxis.boundaryGap = chartAxes[1].crossBetween === 'between' ? true : false;
    }
    //@ts-expect-error
    if (xAxis?.type === 'time') {
      const data =
        //@ts-expect-error
        chartTypeData.ser[0].cat?.strRef?.strCache ?? chartTypeData.ser[0].cat?.numRef?.numCache;
      //@ts-expect-error
      xAxis.data = data?.pt?.map((el) => convertValueToDate(el.v, chartTypeData.ser[0].cat?.numRef?.numCache?.formatCode));
    }
    return xAxis;
  }, [plotArea, chartTypeData]);

  const yAxis = useMemo<echarts.EChartsOption['yAxis']>(() => {
    const { chartAxes } = plotArea;

    const yAxes = chartAxes.filter((axis) => axis?.axPos === 'l' || axis?.axPos === 'r');

    const axes: echarts.EChartsOption['yAxis'] = [];

    if (!yAxes?.length) {
      return undefined;
    }

    yAxes.forEach((axis, i) => {
      const percentageFormat = new RegExp(/(0(.)*(%))/);
      const isPercentage = percentageFormat.test(axis.numFmt.formatCode);

      const type = axisType(axis.type);

      const majorUnit = axis?.majorUnit
        ? isPercentage
          ? axis?.majorUnit * 100
          : axis?.majorUnit
        : isPercentage
        ? 10
        : 5;

      const max = axis.scaling.max
        ? isPercentage
          ? axis.scaling.max * 100
          : axis.scaling.max
        : undefined; //TODO:CHARTS Find a way to know the default max value

      const min = axis.scaling.min
        ? isPercentage
          ? axis.scaling.min * 100
          : axis.scaling.min
        : undefined; //TODO:CHARTS Find a way to know the default min value

      const handleValues = () => {
        if (max != null && min != null) {
          const x = max - min;
          const split = Math.round(x / majorUnit);
          const t = Math.round(min + (split + 1) * majorUnit - majorUnit);

          return { min: Math.round(min), max: t, splitNumber: split };
        }

        //TODO:CHARTS If the max and min values are not undefined anymore this return isnt necessary
        return { min, max, splitNumber: majorUnit };
      };

      const values = handleValues();

      const yAxis: echarts.YAXisComponentOption = {
        ...defaultAxisValues(axis, 'y'),
        id: axis.axId,
        position: axisPosition(axis.axPos),
        //@ts-expect-error
        type: shape.chartSpace.chart.plotArea.chartTypes[0].barDir === 'bar' ? 'category' : type,
        max: values.max,
        min: values.min,
        //@ts-expect-error
        splitNumber:
          shape.chartSpace.chart.plotArea.chartTypes[0].type === 'bubble' ? 8 : values.splitNumber,
      };

      if (yAxis?.type === 'category') {
        const data =
          //@ts-expect-error
          chartTypeData.ser[0].cat?.strRef?.strCache ?? chartTypeData.ser[0].cat?.numRef?.numCache;
        //@ts-expect-error
        yAxis.data = data?.pt?.map((el) => el.v); //@ts-expect-error
        yAxis.boundaryGap = chartAxes[1].crossBetween === 'between' ? true : false;
      }
      axes.push(yAxis);
    });

    return axes;
  }, [plotArea, chartTypeData]);

  return { xAxis, yAxis };
};

export default useChartAxis;
