import { useEnvisionTheme, useFavicon, useSelector, useDispatch } from '_common/hooks';
import { SuiteProvider } from '_common/suite';
import Title from './Title';
import Toolbar from './Toolbar';
import Navigation from './Navigation';
import SidebarPanel from './Sidebar/SidebarPanel';
import Slides from './Slides';
import Sidebar from './Sidebar';
import Footer from './Footer';
import PresentationModalConductor from './PresentationModalConductor';
import styles from './Presentation.module.scss';
import { useComments, useTasks } from './SyncStore';
import { notify } from '_common/components/ToastSystem';
import { useEffect, useRef } from 'react';
import { selectCard, setElementToFocus } from './PresentationSlice';
import Onboarding from './Onboarding/Onboarding';
import { InstanceService } from '_common/services';
import { setCurrentDocument } from 'App/redux/onboardingSlice';
import usePresentationPersist from './hooks/usePresentationPersist';

const Presentation = () => {
  useEnvisionTheme();
  useFavicon('/favicon-orange.ico');
  usePresentationPersist();
  const dispatch = useDispatch();

  const timeoutRef = useRef<number>();

  const presentationId = useSelector((state) => state.presentation.general.presentationId);
  const active = useSelector((state) => state.onboarding.active.presentation);
  const started = useSelector((state) => state.onboarding.started.presentation);
  const zoom = useSelector((state) => state.presentation.general.zoom);
  const showNav = useSelector((state) => state.presentation.general.showNav);
  const rightPanel = useSelector((state) => state.presentation.general.rightPanel);
  const elementToFocus = useSelector((state) => state.presentation.general.elementToFocus);
  const comments = useComments();
  const tasks = useTasks();

  useEffect(() => {
    if (elementToFocus && elementToFocus.documentId) {
      const { objectType, objectId } = elementToFocus;
      let objectNotFound = false;
      switch (objectType) {
        case 'comment': {
          if (comments && comments?.list.length > 0) {
            if (comments?.list.includes(objectId)) {
              dispatch(selectCard(objectId));
            } else {
              objectNotFound = true;
            }

            dispatch(setElementToFocus(undefined));
          }
          break;
        }
        case 'task': {
          if (tasks && tasks?.list.length > 0) {
            if (tasks?.list.includes(objectId)) {
              dispatch(selectCard(objectId));
            } else {
              objectNotFound = true;
            }

            dispatch(setElementToFocus(undefined));
          }
          break;
        }
        default: {
          break;
        }
      }

      if (objectNotFound) {
        notify({
          type: 'error',
          title: 'CARD_TYPE_X_NOT_ACCESSIBLE',
          titleValues: { cardType: objectType.charAt(0).toUpperCase() + objectType.slice(1) },
          message: 'LINK_CANNOT_BE_OPENED_BECAUSE_CARD_TYPE_X_DOESNT_EXIST',
          messageValues: { cardType: objectType },
        });
      }
    }
  }, [elementToFocus, comments, tasks]);

  const handleObjectProcessing = ({
    objectId,
    objectType,
  }: {
    objectId: ObjectId;
    objectType: ObjectType;
  }) => {
    timeoutRef.current = window.setTimeout(() => {
      new InstanceService().getObjectStatus(objectType, objectId).then(({ data }) => {
        if (data.status !== 'processing' && presentationId) {
          if (data.id !== presentationId) {
            dispatch(setCurrentDocument({ target: 'presentation', id: presentationId, zoom }));
            window.open(`/presentation/${data.id}`, '_self');
          } else {
            dispatch(setCurrentDocument({ target: 'presentation', zoom, isOnboarding: true }));
          }
        } else {
          handleObjectProcessing({ objectId: data.id, objectType: objectType });
        }
      });
    }, 2500);
  };

  useEffect(() => {
    if ((active || started) && presentationId) {
      new InstanceService().getPresentationOnboarding().then(({ data }) => {
        if (data.status === 'processing') {
          handleObjectProcessing({ objectId: data.id, objectType: 'presentation' });
        }
        if (data.status === 'active') {
          if (data.id !== presentationId) {
            dispatch(setCurrentDocument({ target: 'presentation', id: presentationId, zoom }));
            window.open(`/presentation/${data.id}`, '_self');
          } else {
            dispatch(setCurrentDocument({ target: 'presentation', zoom, isOnboarding: true }));
          }
        }
      });
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [active, started, presentationId]);

  return (
    <SuiteProvider app="presentation">
      <div className={styles.root}>
        <Title />
        <Toolbar />
        {showNav && <Navigation />}
        {rightPanel && <SidebarPanel />}
        <Slides />
        <Sidebar />
        <Footer />
        <PresentationModalConductor />
        <Onboarding />
      </div>
    </SuiteProvider>
  );
};

export default Presentation;
