import { createContext, memo, ReactNode, useContext, useMemo } from 'react';
import { useStructureSync, useTheme } from './SyncStore';

type PresentationContextValue = {
  size: {
    width: number;
    height: number;
  };
  theme: Presentation.Data.Theme;
  structure: Presentation.Data.Structure;
};

const PresentationContext = createContext<PresentationContextValue | undefined>(undefined);

type PresentationContextProps = {
  children: ReactNode;
};

const PresentationDataProvider = ({ children }: PresentationContextProps) => {
  const structure = useStructureSync();
  const theme = useTheme(structure?.theme);

  const size = useMemo(() => {
    const size = { width: 0, height: 0 };
    if (structure) {
      size.width = structure.sldSz.cx;
      size.height = structure.sldSz.cy;
    }
    return size;
  }, [structure]);

  if (structure && theme) {
    return (
      <PresentationContext.Provider value={{ size, theme, structure }}>
        {children}
      </PresentationContext.Provider>
    );
  }
  return null;
};

export const usePresentationData = () => {
  const context = useContext(PresentationContext);
  if (context === undefined) {
    throw new Error('usePresentationData can only be used in a PresentationData');
  }
  return context;
};

export const useTableStyle = (table: Presentation.Data.Table) => {
  const { structure } = usePresentationData();
  const style = Array.isArray(structure.tblStyleLst) ? structure.tblStyleLst.find(
    (tblStyle) => tblStyle.styleId === table.tblPr.tblStyleId,
  ) : structure.tblStyleLst.tblStyle.find(
    (tblStyle) => tblStyle.styleId === table.tblPr.tblStyleId,
  );

  return style ?? null;
};

export default memo(PresentationDataProvider);
