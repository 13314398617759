import { NodeDataBuilder, NodeUtils } from 'Editor/services/DataManager';
import { BaseManipulator } from './Base';
import { InsertElementOperation, RemoveContentOperation } from '../../Operations';

export class CommonManipulator
  extends BaseManipulator
  implements Editor.Edition.ICommonManipulator
{
  wrapContent(
    model: Editor.Data.Node.Model,
    wrapElement: Editor.Data.Node.Data,
    startPath: Editor.Selection.Path,
    endPath: Editor.Selection.Path,
  ): Editor.Selection.Path | undefined {
    const baseData = model.selectedData();

    if (!baseData) {
      return undefined;
    }

    const dataBuilder = new NodeDataBuilder();
    dataBuilder.setData(wrapElement);
    dataBuilder.setChildNodes(NodeUtils.cloneData(baseData, startPath, endPath));

    const removeOp = new RemoveContentOperation(model, startPath, endPath).apply();
    let resultPath = removeOp.getAdjustedPath();
    if (!resultPath) {
      resultPath = startPath;
    }

    const dataToInsert = dataBuilder.build();

    if (dataToInsert) {
      const insertOp = new InsertElementOperation(model, resultPath, dataToInsert).apply();

      return insertOp.getAdjustedPath();
    }

    return undefined;
  }

  // unwrapContent(
  //   model: Editor.Data.Node.Model,
  //   pathToElementToUnwrap: Editor.Selection.Path,
  // ): Editor.Selection.Path | undefined {
  //   // for delete hyperlink !?

  //   const baseData = model.selectedData();

  //   if (!baseData) {
  //     return undefined;
  //   }

  //   const elementToUnwrap = NodeUtils.getChildDataByPath(baseData, pathToElementToUnwrap);

  //   const childNodes: Editor.Data.Node.Data[] = JSON.parse(
  //     JSON.stringify(elementToUnwrap?.childNodes || []),
  //   );

  //   const childOffset = Number(pathToElementToUnwrap[pathToElementToUnwrap.length - 1]);

  //   let resultPath: Editor.Selection.Path | undefined = undefined;

  //   if (!isNaN(childOffset)) {
  //     let endPath: Editor.Selection.Path = [...pathToElementToUnwrap];
  //     endPath[endPath.length - 1] = childOffset + 1;

  //     const removeOp = new RemoveContentOperation(model, pathToElementToUnwrap, endPath, {
  //       mergeText: false,
  //     }).apply();

  //     let resultPath = removeOp.getAdjustedPath();

  //     if (!resultPath) {
  //       resultPath = pathToElementToUnwrap;
  //     }

  //     if (childNodes.length) {
  //       for (let i = 0; i < childNodes.length; i++) {
  //         if (resultPath) {
  //           const insertOp = new InsertElementOperation(model, resultPath, childNodes[i]).apply();
  //           resultPath = insertOp.getAdjustedPath();
  //         }
  //       }
  //     }
  //   }

  //   return resultPath;
  // }

  splitInlineContent(
    model: Editor.Data.Node.Model,
    textData: Editor.Data.Node.Data,
    textDataPath: Editor.Selection.Path,
    pathToSplit: Editor.Selection.Path,
  ) {
    let baseData = model.selectedData();

    if (!baseData) {
      return undefined;
    }

    const startPath = [...pathToSplit];
    const endPath: Editor.Selection.Path = [
      ...textDataPath,
      'childNodes',
      textData.childNodes?.length || 0,
    ];

    const clonedData = NodeUtils.cloneData(baseData, startPath, endPath);

    if (clonedData.length) {
      const removeOp = new RemoveContentOperation(model, startPath, endPath, {
        mergeText: false,
      });
      removeOp.apply();
      // let resultPath = removeOp.getAdjustedPath();
      // if (!resultPath) {
      //   resultPath = startPath;
      // }
    }

    baseData = model.selectedData();
    if (!baseData) {
      return undefined;
    }

    const updateTextData = NodeUtils.getChildDataByPath(baseData, textDataPath);

    let resultPath: Editor.Selection.Path = [
      ...textDataPath,
      'childNodes',
      updateTextData?.childNodes?.length || 0,
    ];

    let pathToInsert = resultPath;
    for (let i = 0; i < clonedData.length; i++) {
      const insertOp = new InsertElementOperation(model, pathToInsert, clonedData[i], {
        mergeText: false,
        pathFix: 'AFTER',
      }).apply();
      const adjustedPath = insertOp.getAdjustedPath();
      if (adjustedPath) {
        pathToInsert = adjustedPath;
      }
    }

    return resultPath;
  }
}
