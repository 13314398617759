import { usePresentationData } from 'Presentation/PresentationData';
import { createContext, memo, ReactNode, useContext, useMemo } from 'react';
import { useSlideData } from '../SlideData';
import usePosition from './usePosition';
import useSize from './useSize';

type ShapeContextValue = {
  position: Presentation.Data.Common.Position;
  size: Presentation.Data.Common.Size;
  shape: Presentation.Data.Shape;
};

const ShapeContext = createContext<ShapeContextValue | undefined>(undefined);

type ShapeDataProps = {
  children: ReactNode;
  shape: Presentation.Data.Shape;
};
const ShapeDataProvider = ({ children, shape }: ShapeDataProps) => {
  const { theme } = usePresentationData();
  const { color } = useSlideData();

  const position = usePosition(shape);
  const size = useSize(shape);

  const ln = useMemo(() => {
    let ln = shape.properties.ln;
    if (shape.style?.lnRef) {
      const lnRef = theme.formatScheme.lineStyleList[shape.style.lnRef.index - 1];
      if (lnRef) {
        ln = { ...lnRef, ...ln };
        if (!shape.properties.ln?.fill) {
          if (ln) {
            const parsedColor = color(shape.style.lnRef.color).substring(1);
            if (ln.fill?.type === 'solid') {
              ln.fill.color = { ...ln.fill.color, base: parsedColor };
            } else if (ln.fill?.type === 'gradient') {
              ln.fill.stops = ln.fill.stops.map((s) => ({
                ...s,
                color: { ...s.color, base: parsedColor },
              }));
            }
          }
        }
      }
    }
    return ln;
  }, [shape]);

  const fill = useMemo(() => {
    if (shape.style?.fillRef) {
      //TODO:PRESENTATION index - 1 giving incorrect values, check if correct in special cases
      const fillRef = { ...theme.formatScheme.fillStyleList[shape.style.fillRef.index /* - 1 */] };
      if (Object.keys(fillRef).length) {
        const parsedColor = color(shape.style.fillRef.color).substring(1);
        switch (fillRef.type) {
          case 'solid':
            if (shape.properties.fill?.type === 'solid') {
              fillRef.color = shape.properties.fill.color;
            } else {
              fillRef.color = { mods: fillRef.color.mods, base: parsedColor };
            }
            break;
          case 'gradient':
            fillRef.stops = fillRef.stops.map((s, index) => ({
              ...s,
              color:
                shape.properties.fill?.type === 'gradient'
                  ? shape.properties.fill.stops[index]?.color
                  : { mods: s.color.mods, base: parsedColor },
            }));
            break;
        }
        return { ...fillRef, ...shape.properties.fill };
      }
    }
    return shape.properties.fill;
  }, [shape]);

  return (
    <ShapeContext.Provider
      value={{ shape: { ...shape, properties: { ...shape.properties, ln, fill } }, position, size }}
    >
      {children}
    </ShapeContext.Provider>
  );
};

export const useShapeData = () => {
  const context = useContext(ShapeContext);
  if (context === undefined) {
    throw new Error('useShapeData can only be used in a ShapeData');
  }
  return context;
};

export const useShapeProperties = () => {
  const { shape } = useShapeData();
  return shape.properties;
};

export default memo(ShapeDataProvider);
