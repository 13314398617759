import { useRef, useMemo } from 'react';
import { useSelector } from '_common/hooks';
import ViewAnnotationCard from './ViewAnnotationCard';
import EditableAnnotationCard from './EditableAnnotationCard';
import TaskCard from 'PDF/RightSidePanel/TasksPanel/TaskCard/TaskCard';

type AnnotationCardProps = {
  annotation: PDF.Annotation;
  sidebar: boolean;
  index?: number;
  order?: number;
  testId: string;
};

const AnnotationCard = ({ annotation, sidebar, index, order, testId }: AnnotationCardProps) => {
  const editing = useSelector((state) => state.pdf.annotations.editing);
  const started = useSelector((state) => state.onboarding.started.dopdf);
  const pulseData = useSelector((state) => state.onboarding.pulseData);
  const actionsCompleted = useSelector((state) => state.onboarding.actionsCompleted);
  const interactions = useSelector((state) => state.onboarding.interactions);

  const cardRef = useRef<HTMLDivElement>(null);

  const remainEditing = useMemo(() => {
    return !!(
      started &&
      interactions.pdf_annotation_save &&
      annotation.id === pulseData.commentId &&
      !actionsCompleted.pdf_comments_createComment
    );
  }, [started, interactions, actionsCompleted, pulseData]);

  const editMode =
    editing === annotation.id ||
    (annotation.subtype === 'Task' && annotation.temporary) ||
    remainEditing;

  if (annotation.subtype === 'Task') {
    return (
      <TaskCard
        task={annotation}
        sidebar={sidebar}
        index={index}
        order={order}
        ref={cardRef}
        testId={testId}
      />
    );
  }

  if (editMode) {
    return (
      <EditableAnnotationCard
        annotation={annotation}
        sidebar={sidebar}
        ref={cardRef}
        testId={testId}
      />
    );
  }

  return (
    <ViewAnnotationCard annotation={annotation} sidebar={sidebar} ref={cardRef} testId={testId} />
  );
};

export default AnnotationCard;
