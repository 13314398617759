import { useSlideData } from '../SlideData';

type GradientDefsProps = {
  id: string;
  background: Presentation.Data.Common.GradientFillType;
};

const GradientDefs = ({ id, background }: GradientDefsProps) => {
  const { color } = useSlideData();
  const stops = [...background.stops].sort((a, b) => a.pos - b.pos);
  const deg = (background?.linear?.angle || background?.angle) ?? 0;

  return (
    <linearGradient id={id} gradientTransform={`rotate(${deg})`}>
      {stops.map((stop) => {
        return <stop offset={`${stop.pos}%`} stopColor={color(stop.color)} />;
      })}
    </linearGradient>
  );
};

export default GradientDefs;
