import { Command } from '../Command';
import { JsonRange } from 'Editor/services/_Common/Selection';
import { UpdateColumnWidthsOperation } from '../../Operations/TableOperations';

export class UpdateColumnWidthsCommand extends Command {
  tableId: string;
  columnWidths: Editor.Edition.ColumnWidths;

  constructor(
    context: Editor.Edition.ICommandArgs,
    tableId: string,
    columnWidths: Editor.Edition.ColumnWidths,
  ) {
    super(context);

    this.tableId = tableId;
    this.columnWidths = columnWidths;
  }

  async exec(): Promise<Editor.Edition.ICommand> {
    if (
      !this.askUserAboutThis() ||
      !this.context.DataManager ||
      !this.context.DataManager.selection
    ) {
      return this;
    }

    const rangeData = this.context.DataManager.selection.current;
    const jsonRange = rangeData[0] ? JsonRange.buildFromRangeData(rangeData[0]) : null;

    if (!jsonRange) {
      return this;
    }

    // TODO FIND ANOTHER SOLUTION TO GET NODE MODEL
    const baseModel = this.context.DataManager.nodes.getNodeModelById(jsonRange.start.b);
    const baseData = baseModel?.selectedData();

    if (
      !baseModel ||
      !baseData ||
      !baseData.id ||
      !(baseData.id === this.tableId || baseModel.getChildInfoById(this.tableId))
    ) {
      return this;
    }

    // check if element is editable
    if (!this.context.DataManager.nodes.isNodeEditable(baseModel.id)) {
      return this;
    }

    const pageWidth = this.context.DataManager?.sections.getPageWidthForBlockId(this.tableId);
    const operation = this.getOperation(baseModel, pageWidth);
    operation.apply();

    // create patch
    this.context.DataManager?.history.createPatch();

    return this;
  }

  private getOperation(baseModel: Editor.Data.Node.Model, pageWidth: number) {
    return new UpdateColumnWidthsOperation(baseModel, this.tableId, this.columnWidths, pageWidth);
  }
}
