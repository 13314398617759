import { JsonRange, PathUtils } from 'Editor/services/_Common/Selection';
import { NodeUtils } from 'Editor/services/DataManager/models';
import { UpdateImageSourceOperation } from '../../Operations/ImageOperations/UpdateImageSourceOperation';
import { Command } from '../Command';
import { Logger } from '_common/services';

export class ChangeImageCommand extends Command {
  image: File;

  constructor(context: Editor.Edition.ICommandArgs, image: File) {
    super(context);

    this.image = image;
  }

  isUserAuthor(data: Editor.Data.Node.TrackedData): boolean {
    try {
      const author = data.properties.author;

      if (author != null && this.context.DataManager?.users.isLoggedUser(author)) {
        return true;
      }
    } catch (error) {
      Logger.captureException(error);
    }
    return false;
  }

  async exec(): Promise<Editor.Edition.ICommand> {
    if (!this.context.DataManager || !this.context.DataManager.selection) {
      return this;
    }

    const rangeData = this.context.DataManager.selection.current;
    const jsonRange = JsonRange.buildFromRangeData(rangeData[0]);

    if (!jsonRange) {
      return this;
    }

    const baseModel = this.context.DataManager.nodes.getNodeModelById(jsonRange.start.b);
    const baseData = baseModel?.selectedData();

    if (!baseModel || !baseData) {
      return this;
    }

    const result = NodeUtils.closestOfTypeByPath(baseData, jsonRange.start.p, ['img', 'figure']);
    const closest = result?.data;

    const closestSuggestion = NodeUtils.closestOfTypeByPath(baseData, jsonRange.start.p, [
      'tracked-insert',
      'tracked-delete',
    ]);

    let sameUserSuggestion: boolean = false;
    const suggestionsData = closestSuggestion?.data;
    sameUserSuggestion =
      closestSuggestion != null &&
      NodeUtils.isTrackedData(suggestionsData) &&
      this.isUserAuthor(suggestionsData);

    if (this.context.editionMode === 'SUGGESTIONS' && !sameUserSuggestion) {
      // track changes
      this.insertImage();
    } else {
      if (closest && closest.id) {
        const imageInfo = baseModel.getChildInfoById(closest.id);

        this.uploadImage(this.image, (img) => {
          if (
            NodeUtils.isImageData(imageInfo.data) &&
            PathUtils.isValidSelectionPath(imageInfo.path)
          ) {
            this.applyOperations(
              baseModel,
              imageInfo.path,
              imageInfo.data,
              img.reference,
              img.dimensions.height,
              img.dimensions.width,
            );

            // create patch
            this.context.DataManager?.history.createPatch();
          }
        });
      }
    }

    return this;
  }

  applyOperations(
    baseModel: Editor.Data.Node.Model,
    path: Editor.Selection.Path,
    imageData: Editor.Data.Node.ImageData,
    source: string,
    height: number, // in points
    width: number, // in points
  ) {
    const operation = new UpdateImageSourceOperation(
      baseModel,
      path,
      imageData,
      source,
      height,
      width,
    );
    return operation.apply();
  }

  insertImage() {
    let command = this.context.commandFactory?.getCommand('INSERT_IMAGE', this.image);
    command?.exec();
  }
}
