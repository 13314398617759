import { ELEMENTS } from 'Editor/services/consts';
import { BaseViewBuilder, TableOfLabelsElement } from '..';

export class TableOfLabelsViewBuilder
  extends BaseViewBuilder
  implements Editor.Visualizer.IViewbuilder
{
  ATTRIBUTE_MAPPER: Editor.Visualizer.ATTRIBUTE_MAPPER_TYPE = {
    // id
    id: this.GENERIC_ATTRIBUTE_MAPPER.id,
    // parent id
    parent_id: this.GENERIC_ATTRIBUTE_MAPPER.parent_id,
    // element_type
    element_type: {
      parse: this.GENERIC_ATTRIBUTE_MAPPER.element_type.parse,
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        node.setAttribute('element_type', ELEMENTS.TableOfLabelsElement.ELEMENT_TYPE);
      },
      remove: this.GENERIC_ATTRIBUTE_MAPPER.element_type.remove,
    },
    // section
    section: this.GENERIC_ATTRIBUTE_MAPPER.section,
    tabs: {
      parse(node, builder) {
        if (node.dataset.tabs != null) {
          builder.addProperty('tabs', JSON.parse(node.dataset.tabs));
        }
      },
      render(json, node) {
        if (json.properties?.tabs != null) {
          node.dataset.tabs = JSON.stringify(json.properties.tabs);
        }
      },
      remove(node) {
        delete node.dataset.tabs;
      },
    },
    lbl: {
      parse: (node, builder) => {
        if (node.hasAttribute('lbl')) {
          builder.addProperty('lbl', node.getAttribute('lbl'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.lbl != null) {
          node.setAttribute('lbl', json.properties.lbl);
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('lbl');
      },
    },
    spn: {
      parse: (node, builder) => {
        if (node.hasAttribute('spn')) {
          builder.addProperty('spn', node.getAttribute('spn'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.spn != null) {
          node.setAttribute('spn', json.properties.spn);
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('spn');
      },
    },
    anr: {
      parse: (node, builder) => {
        if (node.hasAttribute('anr')) {
          builder.addProperty('anr', node.getAttribute('anr'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.anr != null) {
          node.setAttribute('anr', json.properties.anr);
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('anr');
      },
    },
    l: {
      parse: (node, builder) => {
        if (node.hasAttribute('l')) {
          builder.addProperty('l', node.getAttribute('l'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.l != null) {
          node.setAttribute('l', json.properties.l);
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('l');
      },
    },
  };

  get attributeMapper() {
    return this.ATTRIBUTE_MAPPER;
  }

  shouldRenderChildren(json?: Editor.Data.Node.Data | undefined) {
    return true;
  }

  build(json: Editor.Data.Node.Data, model?: Editor.Data.Node.Model) {
    const node = new TableOfLabelsElement(this.Visualizer, this.Data);

    const data = model?.get();

    Object.keys(this.ATTRIBUTE_MAPPER).forEach((prop) => {
      this.ATTRIBUTE_MAPPER[prop].render(json, node, data, prop);
    });

    return node;
  }
}
