import { Logger } from '_common/services';
import { InsertElementOperation } from '../../Operations';
import { NodeDataBuilder, NodeUtils } from 'Editor/services/DataManager';
import { InsertBlockOperation } from '../../Operations/StructureOperations';
import { BaseManipulator } from '../Common/Base';

export class InsertManipulator
  extends BaseManipulator
  implements Editor.Edition.IInsertManipulator
{
  insertContent(
    ctx: Editor.Edition.ActionContext,
    path: Editor.Selection.Path,
    dataToInsert: Editor.Data.Node.Data | string,
    options?: Editor.Edition.InsertContentOptions,
  ): boolean {
    if (this.editionContext.debug) {
      Logger.trace('NormalManipulator insertContent', ctx, ctx.baseModel, dataToInsert);
    }

    if (!this.editionContext.DataManager) {
      return false;
    }

    const baseModel = this.editionContext.DataManager.nodes.getNodeModelById(ctx.range.start.b);
    const baseData = baseModel?.selectedData();

    if (!baseModel || !baseData) {
      return false;
    }

    const result = NodeUtils.getParentChildInfoByPath(baseData, path);

    if (!result?.parentData) {
      return false;
    }

    let type: Editor.Elements.ElementTypesType;
    if (typeof dataToInsert === 'string') {
      type = 'text';
    } else {
      type = dataToInsert.type;
    }

    // is insertion allowed and element restrictions
    if (
      !NodeUtils.isAllowedUnder(result.parentData.type, type) ||
      NodeUtils.isRestrictedUnder(baseData.type, type)
    ) {
      Logger.warn('Element insertion not allowed!!');
      return false;
    }

    // TODO
    // check if path is valid
    // is selection editable
    // check styles to apply

    // insert text or create new text element
    let resultPath;

    const operation = this.getInsertOperation(baseModel, path, dataToInsert, options);

    if (operation) {
      operation.apply();
      resultPath = operation.getAdjustedPath();

      // update suggestion content if any
      if (resultPath) {
        this.updateSuggestionContent(ctx, baseModel, resultPath);
      }
    }

    // adjust selection
    if (ctx.range && resultPath) {
      ctx.range.updateRangePositions({
        b: ctx.range.start.b,
        p: resultPath,
      });
    }

    return true;
  }

  insertBlock(
    ctx: Editor.Edition.ActionContext,
    newBlockData: Editor.Data.Node.Data,
    position: 'BEFORE' | 'AFTER' = 'AFTER',
    options: Editor.Edition.InsertBlockOptions = {},
  ): boolean {
    if (this.editionContext.debug) {
      Logger.trace('NormalManipulator insertBlock', ctx);
    }

    if (!this.editionContext.DataManager) {
      return false;
    }
    const structureModel = this.editionContext.DataManager?.structure.structureModel;

    // IMPORTANT: avoid outdated data

    const baseModel = this.editionContext.DataManager.nodes.getNodeModelById(ctx.range.start.b);
    const baseData = baseModel?.selectedData();

    if (!baseModel || !baseData) {
      return false;
    }

    if (
      !baseModel ||
      !baseData ||
      !this.editionContext.DataManager ||
      !structureModel ||
      !NodeUtils.isBlockTypeData(newBlockData)
    ) {
      return false;
    }

    // is insertion allowed
    if (!NodeUtils.isBlockInsertionAllowed(baseData, ctx.range.start.p, newBlockData)) {
      throw new Error('Element insertion not allowed!!' + newBlockData.type);
    }

    let closestBlock = NodeUtils.closestOfTypeByPath(baseData, ctx.range.start.p, [
      ...NodeUtils.BLOCK_TYPES,
    ]);

    if (!closestBlock) {
      return false;
    }

    const result = NodeUtils.getParentChildInfoByPath(baseData, closestBlock.path);

    let blockData: Editor.Data.Node.Data = closestBlock.data;
    let blockDataPath: Editor.Selection.Path = closestBlock.path;

    let sblingData: Editor.Data.Node.Data | null | undefined;

    if (NodeUtils.isDoubleTypeData(result?.parentData)) {
      options.outsideContainer = true;
    }

    if (blockDataPath.length === 0 || options.outsideContainer) {
      let siblingBlock: Editor.Data.Node.Model | undefined;
      if (position === 'AFTER') {
        // INSERT AFTER
        siblingBlock = this.editionContext.DataManager.nodes.getNextModelById(baseModel.id);
      } else {
        // INSERT BEFORE
        siblingBlock = this.editionContext.DataManager.nodes.getPreviousModelById(baseModel.id);
      }
      sblingData = siblingBlock?.selectedData();
    } else {
      let blockIndex = Number(blockDataPath[blockDataPath.length - 1]);
      if (!isNaN(blockIndex)) {
        if (position === 'AFTER') {
          sblingData = result?.parentData.childNodes?.[blockIndex + 1];
        } else {
          sblingData = result?.parentData.childNodes?.[blockIndex - 1];
        }
      }
    }

    let refId = baseModel.id;

    // copy task
    if (sblingData?.tasks?.[0] != null && sblingData?.tasks?.[0] === blockData.tasks?.[0]) {
      if (newBlockData.tasks) {
        newBlockData.tasks.push(sblingData.tasks[0]);
      } else {
        newBlockData.tasks = [sblingData.tasks[0]];
      }
    }

    if (blockDataPath.length === 0 || options.outsideContainer) {
      // insert block in documentx

      if (NodeUtils.isTableData(newBlockData)) {
        // check if needs to insert a paragraph
        if (NodeUtils.isTableData(blockData)) {
          const paragraphData = NodeDataBuilder.buildParagraph({
            parent_id: baseData.parent_id,
          });

          if (paragraphData?.id) {
            const op = new InsertBlockOperation(
              this.editionContext.DataManager,
              structureModel,
              paragraphData,
              refId,
              position,
              options,
            );

            op.apply();

            refId = paragraphData.id;
          }
        } else if (!sblingData || NodeUtils.isTableData(sblingData)) {
          const paragraphData = NodeDataBuilder.buildParagraph({
            parent_id: baseData.parent_id,
          });

          if (paragraphData?.id) {
            const op = new InsertBlockOperation(
              this.editionContext.DataManager,
              structureModel,
              paragraphData,
              refId,
              position,
              options,
            );

            op.apply();
          }
        }
      }

      const op = new InsertBlockOperation(
        this.editionContext.DataManager,
        structureModel,
        newBlockData,
        refId,
        position,
        options,
      );

      op.apply();

      // update range position
      const resultPath = op.getAdjustedPath();
      if (resultPath && newBlockData.id) {
        ctx.range.updateRangePositions({
          b: newBlockData.id,
          p: resultPath,
        });
      }
    } else {
      // insert block inside container
      let blockIndex = Number(blockDataPath[blockDataPath.length - 1]);
      if (isNaN(blockIndex)) {
        return false;
      }

      if (NodeUtils.isTableData(newBlockData)) {
        // check if needs to insert a paragraph
        if (NodeUtils.isTableData(blockData)) {
          const paragraphData = NodeDataBuilder.buildParagraph({
            parent_id: blockData.parent_id,
          });

          let pathToInsert = [...blockDataPath];
          if (position === 'AFTER') {
            blockIndex = blockIndex + 1;
            pathToInsert[pathToInsert.length - 1] = blockIndex;
          }
          if (paragraphData) {
            const op = new InsertElementOperation(baseModel, pathToInsert, paragraphData);
            op.apply();
          }
        } else if (!sblingData || NodeUtils.isTableData(sblingData)) {
          const paragraphData = NodeDataBuilder.buildParagraph({
            parent_id: blockData.parent_id,
          });

          let pathToInsert = [...blockDataPath];
          if (position === 'AFTER') {
            pathToInsert[pathToInsert.length - 1] = blockIndex + 1;
          }

          if (paragraphData) {
            const op = new InsertElementOperation(baseModel, pathToInsert, paragraphData);
            op.apply();
          }
        }
      }

      let pathToInsert = [...blockDataPath];
      if (position === 'AFTER') {
        pathToInsert[pathToInsert.length - 1] = blockIndex + 1;
      }

      const op = new InsertElementOperation(baseModel, pathToInsert, newBlockData, {
        pathFix: 'TEXT_END',
      });
      op.apply();

      // update range position
      const resultPath = op.getAdjustedPath();
      if (resultPath && newBlockData.id) {
        ctx.range.updateRangePositions({
          b: baseModel.id,
          p: resultPath,
        });
      }
    }

    return true;
  }
}
