import { useSlideData } from 'Presentation/Slides/Slide/SlideData';
import {
  useChartAxis,
  useChartColor,
  useChartOutline,
  useChartPlotArea,
  useDataLabel,
} from '../hooks';
import * as echarts from 'echarts';
import { FC } from 'react';
import ChartBase from '../ChartBase/ChartBase';

type BubbleChartProps = {
  shape: Presentation.Data.ChartShape;
};

const useSeries = ({
  shape,
}: {
  shape: Presentation.Data.ChartShape;
}): echarts.ScatterSeriesOption[] | undefined => {
  const { color } = useSlideData();
  const { chartColor } = useChartColor();
  const { chartOutline } = useChartOutline();
  const { dataLabel } = useDataLabel();
  const chartTypeData = shape.chartSpace.chart.plotArea.chartTypes[0];

  if (chartTypeData.type !== 'bubble') {
    return undefined;
  }

  return chartTypeData.ser.map((serie: Presentation.Data.BubbleSer) => {
    const bubbleOutline = chartOutline(serie.properties?.ln);

    const option: echarts.ScatterSeriesOption = {
      type: 'scatter',
      id: serie.idx,
      symbolSize: (data: string[]) => {
        const bubbleScale = chartTypeData?.bubbleScale ?? 0;
        const calculateScale = bubbleScale * 0.1;
        const value = Number(data[2]) * calculateScale;
        const customValue = chartTypeData.sizeRepresents ? value / 2 : value;
        return customValue > 100 ? bubbleScale : customValue;
      },
      itemStyle: {
        //@ts-expect-error CHARTS:LIMITATION doenst support picture
        color:
          serie.properties?.fill?.type === 'none'
            ? 'transparent'
            : chartColor(serie?.properties?.fill),
        borderType: bubbleOutline?.borderType,
        borderWidth: bubbleOutline?.borderWidth,
        borderMiterLimit: bubbleOutline?.borderMiterLimit,
        borderJoin: bubbleOutline?.borderJoin,
        //@ts-expect-error CHARTS:LIMITATION doenst support picture
        borderColor: bubbleOutline?.borderColor,
        shadowBlur: serie.properties?.effects?.[0]?.rad ?? serie.properties?.effects?.[0]?.blurRad,
        shadowColor: serie.properties?.effects?.[0]?.color
          ? color(serie.properties?.effects?.[0]?.color)
          : undefined,
        opacity: 1,
      },
      silent: true,
      //@ts-expect-error chart types
      data: serie?.xVal?.numRef?.numCache?.pt?.map((numVal, index: number) => {
        const idx = numVal.idx;

        const label = dataLabel({
          dLbls: serie.dLbls,
          cat: numVal.v,
          val: serie?.yVal?.numRef?.numCache?.pt[index].v,
          idx,
          defaultPosition: 'inside',
        });
        const data = {
          value: [
            numVal.v,
            serie?.yVal?.numRef?.numCache?.pt[index].v,
            serie?.bubbleSize?.numRef?.numCache?.pt[index].v,
          ],
          label,
        };

        return data;
      }),
    };

    return option;
  });
};

const BubbleChart: FC<BubbleChartProps> = ({ shape }) => {
  const axis = useChartAxis(shape);
  const grid = useChartPlotArea(shape.chartSpace.chart.plotArea);
  const series = useSeries({ shape });

  return (
    <ChartBase
      shape={shape}
      chartOptions={{
        //@ts-expect-error echarts types
        grid: {
          ...grid,
        },
        xAxis: axis.xAxis,
        yAxis: axis.yAxis,
        series,
      }}
    />
  );
};

export default BubbleChart;
