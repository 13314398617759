import { JsonRange } from 'Editor/services/_Common/Selection';
import { Command } from '../Command';

export class ProcessCommand extends Command {
  private event: KeyboardEvent;

  constructor(context: Editor.Edition.ICommandArgs, event: KeyboardEvent) {
    super(context);
    this.event = event;
  }

  async exec(): Promise<Editor.Edition.ICommand> {
    if (!this.context.DataManager || !this.context.DataManager.selection) {
      return this;
    }

    const rangeData = this.context.DataManager.selection.current;
    const jsonRange = JsonRange.buildFromRangeData(rangeData[0]);

    jsonRange.collapse(true);

    // apply new selection
    if (this.context.DataManager?.selection) {
      this.context.DataManager.selection.history.flag('debounce');
      this.context.DataManager.selection.setUserSelection([jsonRange.serializeToRangeData()]);
    }

    return this;
  }
}
