import { useChartColor, useChartOutline, useDataLabel } from '../hooks';

export const defaultColors = [
  {
    properties: {
      fill: { color: { base: '4472C4' }, type: 'solid' },
    },
  },
  {
    properties: {
      fill: {
        color: { base: 'ED7D31' },
        type: 'solid',
      },
    },
  },
  {
    properties: {
      fill: { color: { base: 'A5A5A5' }, type: 'solid' },
    },
  },
];

const useSunburstSeries = () => {
  const { chartColor } = useChartColor();
  const { chartOutline } = useChartOutline();
  const { dataLabel } = useDataLabel();

  //TO DO: change the type of chart
  const sunburstSeries = ({ chart }: { chart: any }): echarts.SunburstSeriesOption[] => {
    const labelInfo = chart.chart.plotArea.plotAreaRegion.series[0];
    //TO DO: remove the type of serie when we have the types for the Sunburst chart
    return chart.chartData.data[0].dim.map((serie: any) => {
      let data;
      if (serie.type === 'str') {
        const level0 = serie.lvl[2];
        const level1 = serie.lvl[1];
        const level2 = serie.lvl[0];

        const level0UniqueNames: string[] = [];
        const level0FilteredNames = level0.pt.filter((item: { content: any }) => {
          if (!level0UniqueNames.includes(item.content)) {
            level0UniqueNames.push(item.content);
            return true;
          }
          return false;
        });

        const handleColors = () => {
          let colors = defaultColors;
          if (labelInfo.dataPt.length > 0) {
            colors = colors.slice(1);
            return labelInfo.dataPt.concat(colors);
          } else if (labelInfo.dataPt.length > 1) {
            colors = colors.slice(1, 3);
            return labelInfo.dataPt.concat(colors);
          }
        };

        data = level0FilteredNames.map(
          (element0: { content: string; idx: number }, index: number) => {
            const level0Childrens = level0.pt
              .filter((element: { content: string }) => element.content === element0.content)
              .map((element: { idx: number }) => {
                return level1.pt.filter((el1: { idx: number }) => el1.idx === element.idx)[0]
                  ?.content;
              });

            const uniqueNamesArray: { content: string; idx: number }[] = [];
            level0Childrens.forEach((name: any) => {
              if (!uniqueNamesArray.includes(name)) {
                uniqueNamesArray.push(name);
              }
            });
            const colors =
              (labelInfo?.properties?.fill?.type === 'solid' || !labelInfo?.properties?.fill) &&
              (labelInfo.dataPt.length > 0
                ? handleColors().filter(
                    (data: { properties: Presentation.Data.ShapeProperties }) =>
                      data?.properties?.fill,
                  )
                : defaultColors);
            const labelColor = labelInfo.dataLabels.dataLabel.filter(
              (value: { idx: number }) => value.idx === element0.idx,
            );

            if (uniqueNamesArray[0] != null) {
              const label = dataLabel({
                dLbls: {
                  dLblPos: 'outEnd',
                  delete: false,
                  showVal: false,
                  showCatName: labelInfo.dataLabels.visibility.categoryName,
                  showSerName: labelInfo.dataLabels.visibility.seriesName,
                  showLeaderLines: false,
                  showLegendKey: false,
                  leaderLines: {},
                  serieValue: labelInfo.txData.v,
                  dLbl: [],
                },

                idx: element0.idx,
                val: chart.chartData.data[0].dim[1].lvl[0].pt.filter(
                  (value: { idx: number }) => value.idx === element0.idx,
                )[0].content,
                cat: element0.content,
                defaultPosition: 'top',
              });
              return {
                name: element0.content,
                itemStyle: {
                  color: chartColor(colors[index]?.properties?.fill),
                },
                label: {
                  ...label,
                  backgroundColor:
                    labelColor.length > 0
                      ? chartColor(labelColor[0]?.properties?.fill)
                      : chartColor(labelInfo.dataLabels?.properties?.fill),
                },
                children: uniqueNamesArray.map((element1) => {
                  const level1Childrens = level1.pt.filter(
                    (element: { content: any }) => element.content === element1,
                  );
                  const childrens = level1Childrens.map(
                    (el1: { idx: number }) =>
                      level2.pt
                        .filter(
                          (element2: { content: string; idx: number }) =>
                            element2.idx === el1.idx && element2.content !== null,
                        )
                        .map((element2: { idx: number; content: string }) => {
                          const label = dataLabel({
                            dLbls: {
                              dLblPos: 'outEnd',
                              delete: false,
                              showVal: labelInfo.dataLabels.visibility.value,
                              showCatName: labelInfo.dataLabels.visibility.categoryName,
                              showSerName: labelInfo.dataLabels.visibility.seriesName,
                              showLeaderLines: false,
                              showLegendKey: false,
                              leaderLines: {},
                              serieValue: labelInfo.txData.v,
                              dLbl: [],
                            },

                            idx: element2.idx,
                            val: chart.chartData.data[0].dim[1].lvl[0].pt.filter(
                              (value: { idx: number }) => value.idx === element2.idx,
                            )[0].content,
                            cat: element2.content,
                            defaultPosition: 'top',
                          });
                          return {
                            name: element2.content,
                            itemStyle: {
                              color: chartColor(colors[index]?.properties?.fill),
                            },
                            label,
                            value: Number(
                              chart.chartData.data[0].dim[1].lvl[0].pt.filter(
                                (value: { idx: number }) => value.idx === element2.idx,
                              )[0]?.content,
                            ),
                          };
                        })[0] ?? [],
                  );
                  const filteredChildrens = childrens.filter(
                    (children: { name: string }) => children.name,
                  );
                  if (filteredChildrens?.length > 0) {
                    const label = dataLabel({
                      dLbls: {
                        dLblPos: 'outEnd',
                        delete: false,
                        showVal: false,
                        showCatName: labelInfo.dataLabels.visibility.categoryName,
                        showSerName: labelInfo.dataLabels.visibility.seriesName,
                        showLeaderLines: false,
                        showLegendKey: false,
                        leaderLines: {},
                        serieValue: labelInfo.txData.v,
                        dLbl: [],
                      },

                      idx: element1.idx,
                      val:
                        chart.chartData.data[0].dim[1].lvl[0].pt.filter(
                          (value: { idx: number }) => value.idx === element1.idx,
                        )[0]?.content ?? null,
                      //@ts-expect-error can be an object
                      cat: element1,
                      defaultPosition: 'top',
                    });
                    return {
                      name: element1,
                      label,
                      itemStyle: {
                        color: chartColor(colors[index]?.properties?.fill),
                      },
                      children: childrens,
                    };
                  } else {
                    return {
                      name: element1,
                      itemStyle: {
                        color: chartColor(colors[index]?.properties?.fill),
                      },
                      value: Number(
                        chart.chartData.data[0].dim[1].lvl[0].pt.filter(
                          (value: { idx: number }) =>
                            value?.idx ===
                            level1Childrens.filter(
                              (al: { content: { content: string; idx: number } }) =>
                                al?.content === element1,
                            )[0]?.idx,
                        )[0]?.content ?? null,
                      ),
                    };
                  }
                }),
              };
            } else {
              return {
                name: element0.content,
                itemStyle: {
                  color: chartColor(colors[index]?.properties?.fill),
                },
                label: {
                  backgroundColor:
                    labelColor.length > 0
                      ? chartColor(labelColor[0]?.properties.fill)
                      : chartColor(labelInfo.dataLabels.properties?.fill),
                },
                value: Number(
                  chart.chartData.data[0].dim[1].lvl[0].pt.filter(
                    (value: { idx: number }) => value.idx === element0.idx,
                  )[0]?.content,
                ),
              };
            }
          },
        );
      }

      const option: echarts.SunburstSeriesOption = {
        type: 'sunburst',
        id: serie.idx,
        data: data,
        radius:
          data?.filter(
            (values: { children: { [key: string]: string | number | boolean }[] }) =>
              values.children,
          )?.length > 0
            ? [50, '90%']
            : [120, '90%'],
        silent: true,
        label: {
          show:
            labelInfo.dataLabels.visibility.categoryName ||
            labelInfo.dataLabels.visibility.seriesName,
          //@ts-expect-error echarts types
          color: labelInfo.dataLabels.text
            ? chartColor(
                labelInfo.dataLabels.text?.childNodes?.[0]?.properties?.inlineProperties?.fill,
              )
            : '#FFFFFF',
          //@ts-expect-error echarts types
          backgroundColor: chartColor(labelInfo.dataLabels.properties?.fill),
        },
        //@ts-expect-error echarts types
        itemStyle: {
          ...chartOutline(labelInfo.properties?.ln),
          color: labelInfo.properties?.fill && chartColor(labelInfo.properties?.fill),
        },
      };

      return option;
    });
  };

  return { sunburstSeries };
};

export default useSunburstSeries;
