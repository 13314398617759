import { ActionContext } from '../ActionContext';
import { CommonManipulator } from './Common/Common';

export abstract class ContentManipulator implements Editor.Edition.IContentManipulator {
  protected editionContext: Editor.Edition.Context;

  common: Editor.Edition.ICommonManipulator;

  abstract insert: Editor.Edition.IInsertManipulator;
  abstract remove: Editor.Edition.IRemoveManipulator;
  abstract split: Editor.Edition.ISplitManipulator;

  constructor(ctx: Editor.Edition.Context) {
    this.editionContext = ctx;

    this.common = new CommonManipulator(this.editionContext);
  }

  wrapContent(
    model: Editor.Data.Node.Model,
    wrapElement: Editor.Data.Node.Data,
    startPath: Editor.Selection.Path,
    endPath: Editor.Selection.Path,
  ): Editor.Selection.Path | undefined {
    return this.common.wrapContent(model, wrapElement, startPath, endPath);
  }

  // unwrapContent(
  //   model: Editor.Data.Node.Model,
  //   pathToElementToUnwrap: Editor.Selection.Path,
  // ): Editor.Selection.Path | undefined {
  //   return this.common.unwrapContent(model, pathToElementToUnwrap);
  // }

  splitInlineContent(
    model: Editor.Data.Node.Model,
    textData: Editor.Data.Node.Data,
    textDataPath: Editor.Selection.Path,
    pathToSplit: Editor.Selection.Path,
  ) {
    return this.common.splitInlineContent(model, textData, textDataPath, pathToSplit);
  }

  insertContent(
    ctx: Editor.Edition.ActionContext,
    path: Editor.Selection.Path,
    dataToInsert: Editor.Data.Node.Data | string,
    options?: Editor.Edition.InsertContentOptions,
  ): boolean {
    return this.insert.insertContent(ctx, path, dataToInsert, options);
  }
  insertBlock(
    ctx: ActionContext,
    blockData: Editor.Data.Node.Data,
    position: 'BEFORE' | 'AFTER',
    options?: Editor.Edition.InsertBlockOptions,
  ): boolean {
    return this.insert.insertBlock(ctx, blockData, position, options);
  }

  removeContent(
    ctx: Editor.Edition.ActionContext,
    options?: Editor.Edition.RemoveContentOptions,
  ): boolean {
    return this.remove.removeContent(ctx, options);
  }

  splitBlockContent(
    ctx: Editor.Edition.ActionContext,
    pathToSplit: Editor.Selection.Path,
    insertOptions: Editor.Edition.SplitBlockOptions = {},
  ): boolean {
    return this.split.splitBlockContent(ctx, pathToSplit, insertOptions);
  }
}
